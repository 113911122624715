import {AfterViewInit, Component, OnDestroy, OnInit,ViewChild,ElementRef} from '@angular/core';
import {AppService} from "../app.service";
import {Parameters} from "../parameters";
import { Geolocation, GeolocationPosition } from '@capacitor/geolocation';
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from '@angular/material/dialog';
import {MessageDisplayComponent} from '../main/message/message-display.component';
import * as $ from 'jquery';
import * as path from "path";
import {RouteService} from "./route.service";


@Component({
    selector: 'app-routes',
    templateUrl: './routes.component.html',
    styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('addressElement', {static: false})  addressElement: any;
    @ViewChild('searchCntrlElement', {static: false})  searchCntrlElement: any;
    @ViewChild(MatMenuTrigger) contextMenu: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    lat: any = -6.8643365;
    lng: any = 39.2712821;
    zoom: any;
    zoom2: any;
    result: any;
    incomingResult: any;
    position: any;
    incomingRoute: any;
    incomingOrigin: any;
    incomingDestination: any;
    addRouteStatus: any = 0;
    map: any;
    mapClickListener: any;
    searchCntrl: any = '';
    flag: any = 0;
    icon: any;
    googleMapsAutocompleteSelectedText: any = '';
    addressValue: any;
    incomingRouteSelected: any;
    currentLocationLat: any  = 0;
    currentLocationLng: any = 0;
    currentLocationLat1: any  = 0;
    currentLocationLng1: any = 0;
    currentLocationLat2: any  = 0;
    currentLocationLng2: any = 0;
    currentLocationPositionIconUrl: any;
    currentLoationAddress: any = '';
    incomingAddress: any;
    incomingAddress1: any;
    incomingAddress2: any;
    incomingAddress3: any;
    incomingAddress4: any;
    outgoingRequest: any;
    outgoingWaypoint: any;
    incomingWaypoint: any;
    outgoingLocation: any;
    incomingLocation: any;
    positionOptions: any = {};
    // positionOptions: any = {
    //     //timeout: 80000,
    //     //maximumAge: 60000,
    //     //enableHighAccuracy: true,
    // };
    constructor(public service: AppService,
                private routeService: RouteService,
                private dialog: MatDialog) {
        this.incomingRoute = new Parameters();
        this.incomingOrigin = new Parameters();
        this.incomingDestination = new Parameters();
        this.incomingAddress = new Parameters();
        this.incomingAddress1 = new Parameters();
        this.incomingAddress2 = new Parameters();
        this.outgoingWaypoint = new Parameters();
        this.incomingAddress3 = new Parameters();
        this.incomingAddress4 = new Parameters();
        this.outgoingRequest = new Parameters();
        this.outgoingLocation = new Parameters();
        this.incomingWaypoint = new Parameters();
        this.incomingLocation = new Parameters();
        this.incomingRouteSelected = new Parameters();
        this.incomingRoute.setAttributes({});
        this.incomingOrigin.setAttributes({});
        this.incomingDestination.setAttributes({});
        this.incomingRouteSelected.setAttributes({});
        this.addressElement = ElementRef;
        this.searchCntrlElement = ElementRef;
        this.contextMenu = MatMenuTrigger;
    }

    ngOnDestroy(): void {
        if (this.mapClickListener) {
            this.mapClickListener.remove();
        }
    }

    markerUpdate(route: any, waypoint: any){
        this.incomingRoute.setAttributes(waypoint);
        this.incomingWaypoint.setAttributes(waypoint);
        this.result = prompt('', this.incomingWaypoint.getName());
        if (this.result !== null){
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingWaypoint.getAttributes()));
            this.outgoingRequest.setAlias(this.result);
            this.service.httpService('post', this.service.app.routes.waypoints.update,
                this.outgoingRequest.getAttributes(),
                {ignoreLoadingBar: 'true'},
                (response: any) => {
                 this.incomingWaypoint.attributes = Object.assign(this.incomingWaypoint.attributes, response);
                }, (error: any) => {
                });
        }
    }

    markerRemove(route: any, waypoint: any){
        this.incomingRoute.setAttributes(route);
        this.incomingWaypoint.setAttributes(waypoint);
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {
            data : {title: this.incomingWaypoint.getName(), action: 'delete'},
            width : 'auto',
        });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object){
                if (item.hasOwnProperty('response')){
                    if (item.response.toString().includes('successfull')){
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setId(this.incomingWaypoint.getId());
                        this.service.httpService('post', this.service.app.routes.waypoints.delete,
                            this.outgoingRequest.getAttributes(), {}, (response: any) => {
                                this.incomingRoute.attributes = Object.assign(this.incomingRoute.attributes, response);
                                this.incomingRoute.setWaypoints2([]);
                                this.incomingRoute.getWaypoints().map((waypoint: any)=>{
                                    this.outgoingWaypoint.setAttributes(waypoint);
                                    this.incomingRoute.getWaypoints2().push({
                                        location: {
                                            lat: parseFloat(this.outgoingWaypoint.getLat()),
                                            lng: parseFloat(this.outgoingWaypoint.getLng())
                                        }
                                    });
                                });
                            }, (error: any) => {
                            });
                    }
                }
            }
        });
    }

    setWaypoints(route: any){
        this.incomingRoute.setAttributes(route);
        this.incomingRoute.setWaypoints2([]);
        this.incomingRoute.getWaypoints().map((waypoint: any) => {
            this.incomingWaypoint.setAttributes(waypoint);
            this.incomingRoute.getWaypoints2().push({
                location : {
                    lat: parseFloat(this.incomingWaypoint.getLat()),
                    lng: parseFloat(this.incomingWaypoint.getLng()),
                }
            });
        });
    }

    removeRoute(){
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {
            data : {title: this.incomingRouteSelected.getName(), action: 'remove'},
            width : 'auto',
        });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object){
                if (item.hasOwnProperty('response')){
                    if (item.response.toString().includes('successfull')){
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setId(this.incomingRouteSelected.getId());
                        this.service.httpService('post', this.service.app.routes.routes.delete,
                            this.outgoingRequest.getAttributes(), {}, (response: any) => {
                                this.service.app.data.routes.data.splice(
                                    this.service.findIndex(this.service.app.data.routes.data, 'id',
                                        this.incomingRouteSelected.getId()), 1);
                                this.incomingRouteSelected.setAttributes(
                                    this.service.app.data.routes.data.length === 0 ? {} :
                                        this.service.app.data.routes.data[0]);
                                this.setWaypoints(this.incomingRouteSelected.getAttributes());
                            }, (error: any) => {
                            });
                    }
                }
            }
        });
    }
    ngOnInit(): void {
        this.service.app.data = this.service.app.data.hasOwnProperty('routes') ? this.service.app.data.routes : Object.assign(this.service.app.data, {routes: {data: []}});
        this.currentLocationPositionIconUrl = {url: 'http://patten.co.tz/latlng.png', scaledSize: {height: 60, width: 84}};
        this.icon = {url: 'https://i.imgur.com/7teZKiff.png',  scaledSize: {height: 20, width: 20}};
        setTimeout(() => { this.getRoutesByAsc();},this.service.timeout());
        if(navigator){
            navigator.geolocation.getCurrentPosition(pos => {
                try{if(this.currentLocationLat === 0){
                        this.lat = parseFloat(pos.coords.latitude.toString());
                        this.lng = parseFloat(pos.coords.longitude.toString());
                        this.zoom = 8;
                    }this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
                    this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
                }catch(e: any){}
            },(err: any) => {});
        }
        Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
            try{if(this.currentLocationLat === 0){
                    this.lat = parseFloat(pos.coords.latitude.toString());
                    this.lng = parseFloat(pos.coords.longitude.toString());
                    this.zoom = 8;
                }this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
                this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
            }catch(e){}
        });
    }

    openRouteDialog(){
        this.routeService.setSelected(this.incomingRouteSelected.getAttributes());
        this.routeService.openDialog({});
    }

    onContextMenu(event: MouseEvent, itm: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { item: itm };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }


    onMapReady(event: any){
    }


    getRoutesByAsc(){
        const outgoingRequest: any = new Parameters();
        const incomingWaypoint: any = new Parameters();
        const waypoints: any = [];
        outgoingRequest.setAttributes({});
        if(this.service.app.data.routes.data.length !== 0) {
            outgoingRequest.setDescendinglastAt(this.service.app.data.routes.data[this.service.app.data.routes.data.length - 1].id);
            this.incomingRouteSelected.setAttributes(Object.keys(this.incomingRouteSelected.getAttributes()).length === 0 ?
                this.service.app.data.routes.data[0] : this.incomingRouteSelected.getAttributes());
        }
        this.service.httpService('post',
            this.service.app.routes.routes.get,
            outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false},
            (responses: any) => {
            responses.map((response: any)=>{
                const incomingRoute = new Parameters();
                incomingRoute.setAttributes(response);
                incomingRoute.setWaypoints2([]);
                incomingRoute.getWaypoints().map((waypoint: any) => {
                    incomingWaypoint.setAttributes(waypoint);
                    incomingRoute.getWaypoints2().push({
                        location: {
                            lat: parseFloat(incomingWaypoint.getLat()),
                            lng: parseFloat(incomingWaypoint.getLng())
                        }
                    });
                });
                this.position = this.service.findIntegerIndex(this.service.app.data.routes.data,'id', incomingRoute.getId());
                if(this.position === -1) this.service.app.data.routes.data.push(incomingRoute.getAttributes());
            });if(responses.length !==0){
                setTimeout(()=>{
                    this.getRoutesByAsc();
                });

            }
        }, (error: any) => {
        });
    }


    getWaypointsByRoute(waypoints: any){
        const outgoingWaypoints: any = [];
        waypoints.map((waypoint: any) => {
            this.incomingWaypoint.setAttributes(waypoint);
            outgoingWaypoints.push({location: {
                    lat: parseFloat(this.incomingWaypoint.getLat()),
                    lng: parseFloat(this.incomingWaypoint.getLng())
                }});
        });
        return outgoingWaypoints;
    }

    addWaypoint(outgoingRequest: any,callback: any){
        this.service.httpService('post', this.service.app.routes.waypoints.add,
            outgoingRequest.getAttributes(), {}, (result: any) => {
                callback(result);
            }, (error: any) => {
            });
    }

    onInputTextChange(event: any){
    }

    onInputRouteTextChange(event: any){
        this.searchCntrl = event.target.value;
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes({});
        outgoingRequest.setSearchText(this.searchCntrl);
        this.searchCntrlElement.nativeElement.focus();
        this.routeService.search(outgoingRequest.getAttributes());
    }

    onGoogleMapAutocompleteSelected(result: any) {
        this.lat = parseFloat(result.geometry.location.lat());
        this.lng = parseFloat(result.geometry.location.lng());
        this.currentLocationLat1 =  this.lat;
        this.currentLocationLng1 = this.lng;
        this.zoom = parseFloat('10');
        this.googleMapsAutocompleteSelectedText = this.addressElement.nativeElement.value;
        this.addressElement.nativeElement.value = '';
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {
            data : {title: this.googleMapsAutocompleteSelectedText.toLowerCase(), action: 'add'},
            width : 'auto',
        });dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object) {
                if (item.hasOwnProperty('response')) {
                    if (item.response.toString().includes('successfull')) {
                        this.addMarker();
                    }
                }
            }
        });
    }

    onLocationSelected(location: any) {
    }


    getWindow(){
        return{height: window.innerHeight};
    }

    addMarker(){
        if(this.incomingRouteSelected.getWaypoints().length === 0){
            this.incomingOrigin.setLat(this.currentLocationLat1);
            this.incomingOrigin.setLng(this.currentLocationLng1);
            this.incomingDestination.setLat(this.currentLocationLat1);
            this.incomingDestination.setLng(this.currentLocationLng1);
            this.incomingRouteSelected.setOrigin(this.incomingOrigin.getAttributes());
            this.incomingRouteSelected.setDestination(this.incomingDestination.getAttributes());
        }else {
            this.incomingDestination.setLat(this.currentLocationLat1);
            this.incomingDestination.setLng(this.currentLocationLng1);
            this.incomingRouteSelected.setDestination(this.incomingDestination.getAttributes());
        }
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setLat(this.incomingDestination.getLat());
        this.outgoingRequest.setLng(this.incomingDestination.getLng());
        this.service.httpService('post', this.service.app.routes.home.address.get,
            this.outgoingRequest.getAttributes(), {}, (result: any) => {
                this.incomingAddress.setAttributes(result);
                this.incomingAddress1.setAttributes(this.incomingAddress.getAddress1());
                this.incomingAddress2.setAttributes(this.incomingAddress.getAddress2());
                this.incomingAddress3.setAttributes(this.incomingAddress.getAddress3());
                this.incomingAddress4.setAttributes(this.incomingAddress.getAddress4());
                this.incomingWaypoint.setAttributes({});
                this.incomingWaypoint.setCntrlNum(this.service.random());
                this.incomingWaypoint.setName(
                    this.service.isNull(this.googleMapsAutocompleteSelectedText) ?
                    this.incomingAddress.getAddress() :
                        this.googleMapsAutocompleteSelectedText);
                this.googleMapsAutocompleteSelectedText = '';
                this.incomingWaypoint.setTitle(this.incomingWaypoint.getName());
                this.incomingWaypoint.setLat(this.incomingDestination.getLat());
                this.incomingWaypoint.setLng(this.incomingDestination.getLng());
                this.incomingWaypoint.setPlaceId(this.incomingAddress3.getPlaceId());
                this.incomingWaypoint.setRouteId(this.incomingRouteSelected.getId());
                this.incomingRouteSelected.getWaypoints().push(this.incomingWaypoint.getAttributes());
                this.incomingRouteSelected.getWaypoints2().push({
                    location: {
                        lat: parseFloat(this.incomingDestination.getLat()),
                        lng: parseFloat(this.incomingDestination.getLng()),
                    }
                });
                this.addWaypoint(this.incomingWaypoint,(route: any)=> {
                    this.incomingRouteSelected.attributes = Object.assign(this.incomingRouteSelected.attributes, route);
                    this.incomingRouteSelected.setWaypoints2([]);
                    this.incomingRouteSelected.getWaypoints().map((waypoint: any) => {
                        this.incomingWaypoint.setAttributes(waypoint);
                        this.incomingRouteSelected.getWaypoints2().push({
                            location: {
                                lat: parseFloat(this.incomingWaypoint.getLat()),
                                lng: parseFloat(this.incomingWaypoint.getLng())
                            }
                        });
                    });
                });
            }, (error: any) => {
            });
    }

    mapReadyHandler(map: any){
        this.map = map;
        this.mapClickListener = this.map.addListener('contextmenu', (e: google.maps.MouseEvent) => {
            this.currentLocationLat1 = e.latLng.lat();
            this.currentLocationLng1 = e.latLng.lng();
        });
    }


    onClickMap(map:any){
        this.contextMenu.closeMenu();
    }

    addRoute(){
        this.result = prompt('route', '');
        if (this.result !== null){
            this.addRouteStatus = 1;
            this.incomingRoute.setAttributes({});
            this.incomingOrigin.setAttributes({});
            this.incomingOrigin.setLat(this.lat);
            this.incomingOrigin.setLng(this.lng);
            this.incomingDestination.setAttributes({});
            this.incomingDestination.setLat(this.lat);
            this.incomingDestination.setLng(this.lng);
            this.incomingRoute.setWaypoints([]);
            this.incomingRoute.setWaypoints2([]);
            this.incomingRoute.setTitle(this.result);
            this.incomingRoute.setName(this.result);
            this.incomingRoute.setOrigin(this.incomingOrigin.getAttributes());
            this.incomingRoute.setDestination(this.incomingDestination.getAttributes());
            this.incomingRoute.setCntrlNum(this.service.random());
            this.service.httpService('post', this.service.app.routes.routes.add,
                this.incomingRoute.getAttributes(), {}, (result: any) => {
                    this.incomingRoute.attributes = Object.assign(this.incomingRoute.getAttributes(),result);
                    this.service.app.data.routes.data.push(this.incomingRoute.getAttributes());
                    this.incomingRouteSelected.setAttributes(this.incomingRoute.getAttributes());
                }, (error: any) => {
                });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() =>{
            this.zoom = 18;
        });
    }

}
