<div layout="row" class="normal">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div class="row">
                    <table [ngStyle]="{'width': '100%', 'background-color':'#FFFFFF'}"
                           class="table table-striped table-bordered"
                           #element>
                        <thead *ngIf="isheaderShouldDislayed()" style="width: 100%">
                        <th *ngFor="let optional of question.options" style="font-size: 17px; margin: 2px; min-width: 200px">
                            <span class='cursor headline2' [innerHTML]="optional.label"></span>
                        </th>
                        <th *ngIf="!isAllDefault()">Action</th>
                        </thead>
                        <tbody>
                        <tr *ngFor="let row of question.rows; let rowIncremental = index"
                            style="height: 50px !important; width: 100%; min-width: 200px">
                            <td *ngFor="let option of question.options; let optionIncremental = index"
                                style="height: 100%; width: 100%">
<!--                            <div #tdElement-->
<!--                                     contentEditable="{{contentEditable(option, row)}}"-->
<!--                                     *ngIf="option.type_id !== 3"-->
<!--                                     lists="lists"-->
<!--                                     [innerHTML]="row[option.cntrl_num].data"-->
<!--                                     (blur)="onInputTextChange(option, tdElement, row);"-->
<!--                                     [ngStyle]="{'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"-->
<!--                                >-->
<!--                            </div>-->
                                <textarea
                                        type="text"
                                        class="headline2"
                                        autocomplete="off"
                                        #tdElement
                                        rows='3'
                                        [readOnly]="!contentEditable(option, row)"
                                        *ngIf="option.type_id === 1"
                                        [matAutocomplete] = "suggestion"
                                        (blur)="setSuggestion(tdElement.value)"
                                        [formControl] = "suggestionControl"
                                        (input)="onInputTextChange(option, tdElement.value, row); getSuggestions($event)"
                                        [value]="service.upperStartingCharacter(service.removeHtmlTags(row[option.cntrl_num].data), true)"
                                        [ngStyle]="{'max-height':'400px','resize': 'none', 'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"
                                ></textarea>
                                <mat-autocomplete #suggestion = "matAutocomplete"
                                                  (optionSelected)="onSuggestionSelectionChanged($event, option, row)"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let suggestion of filteredSuggestions | async; let suggestionIncresemental = index" [value]= "suggestion" >
                                        {{suggestion.description}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div style="visibility: hidden; position: fixed"
                                     [style.left]="contextMenuPosition.x"
                                     [style.top]="contextMenuPosition.y"
                                     [matMenuTriggerFor]="contextMenu">
                                </div>
                                <mat-menu #contextMenu="matMenu">
                                    <ng-template matMenuContent let-item="inspector">
                                        <button mat-menu-item ></button>
                                    </ng-template>
                                </mat-menu>
                                <datalist id="lists">
                                        <option value="{{suggestion.name}}" *ngFor="let suggestion of this.service.app.data.suggestions">
                                </datalist>
<!--                                <div style="height: 100%; width: 100% ; min-width: 200px"-->
<!--                                     *ngIf="option.type_id === 3">-->
<!--                                    <center>-->
<!--                                        <mat-checkbox-->
<!--                                            #checkboxElement-->
<!--                                            [checked]="isChecked(checkboxElement, row, option)"-->
<!--                                            (change)="onChangecheckbox($event, row, option)"-->
<!--                                            class="cursor"-->
<!--                                        >-->
<!--                                        </mat-checkbox>-->
<!--                                    </center>-->
<!--                                </div>-->
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 10">
                                    <textarea
                                            #tdElement
                                            autocomplete="off"
                                            type="text"
                                            [autofocus]="true"
                                            (blur)="service.decimalPlaces($event, option.hasOwnProperty('conditions') ? option.conditions : {})"
                                            (keyup)="onKeyUp($event, tdElement)"
                                            (keydown)="service.validateNumber($event)"
                                            [readOnly]="!contentEditable(option, row)"
                                            (input)="onInputTextChange(option, tdElement.value, row);"
                                            [value]="service.removeHtmlTags(row[option.cntrl_num].data)"
                                            [ngStyle]="{'max-height':'400px','resize': 'none', 'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"
                                    ></textarea>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 3">
                                    <div>
                                        <div *ngFor="let opt of (option.settings.hasOwnProperty('options') ? option.settings.options : [])">
                                            <mat-checkbox
                                                    class="pull-left"
                                                    (change)="onInputTextChange(option, opt, row)"
                                                    [checked]="isRadioShouldChecked(opt, row[option.cntrl_num])"
                                                    #checkBoxELement>
                                                <span class='headline2 cursor'>
                                                        {{opt.label}}
                                                    </span>
                                            </mat-checkbox><br><br>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 5">
                                    <mat-radio-group
                                            aria-labelledby="example-radio-group-label"
                                            class="example-radio-group">
                                        <mat-radio-button *ngFor="let opt of (option.settings.hasOwnProperty('options') ? option.settings.options : [])"
                                                          class="pull-left"
                                                          (change)="onInputTextChange(option, opt, row)"
                                                          [checked]="isRadioShouldChecked(opt, row[option.cntrl_num])"
                                                          [value]="opt"
                                                          #radioElement>
                                            <span class='headline2 cursor'>
                                                    {{opt.label}}
                                           </span>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 9">
                                    <textarea
                                            type="text"
                                            #tdElement
                                            [readOnly]="!contentEditable(option, row)"
                                            [matAutocomplete] = "autocomplete"
                                            [formControl] = "autocompleteControl"
                                            (input)="getAutocompleteOptions((option.settings.hasOwnProperty('options') ? option.settings.options : [])); onInputTextChange(option, tdElement.value, row)"
                                            [value]="service.removeHtmlTags(row[option.cntrl_num].data)"
                                            [ngStyle]="{'max-height':'400px','resize': 'none', 'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"
                                    ></textarea>
                                    <mat-autocomplete #autocomplete = "matAutocomplete"
                                                      (optionSelected)="onAutocompleteSelectionChanged($event, option, row)"
                                                      [displayWith]="displayFn2">
                                        <mat-option *ngFor="let autocomplete of filteredAutocompletes | async; let autocompleteIncresemental = index" [value]= "autocomplete" >
                                            {{autocomplete.label}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 27">
                                    <app-chip-feedback-controls [deletedOptions]='[]' [question]='option' [item]='row[option.cntrl_num]' [position]='1' [status]="1"></app-chip-feedback-controls>
                                </div>
                            </td>
                            <td class="cursor" (click)="removeTableRow(row)"  *ngIf="isRowCanceled(row)"><i class="material-icons">close</i></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" *ngIf="question.add_others === 1">
                    <center>
                        <a class="defaultColor"
                           style="margin-top: 50px"
                           (click)="addRow()"
                           mat-mini-fab>
                            <i class="material-icons"> add </i>
                        </a>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
<!--(blur)="row[optional.cntrl_num].data = tdElement.innerText; update()"-->
