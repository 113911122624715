import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AppService} from "../../app.service";
import {Parameters} from "../../parameters";
import {DialogMemberComponent} from "./dialog-member/dialog-member.component";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  selected: any = {};
  isDialogOpen: any = false;

  constructor(private dialog: MatDialog,
              private service: AppService) {
  }

  getSelected(): void {
    return this.selected;
  }


  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }

  hasMemberById(memberId: any){
    var members: any = [] = this.service.app.data.members.data.filter((member: any) => {
      return parseFloat(member.id) === parseFloat(memberId);});
    return members.length === 0 ? false : true;
  }

  hasFormByMemberAndFormId(member: any, formId){
    let incomingMember = new Parameters();
    incomingMember.setAttributes(member);
    var forms: any = [] = incomingMember.getForms().data.filter((form: any) => {
      let incomingForm = new Parameters();
      incomingForm.setAttributes(form);
      return parseFloat(incomingForm.getId()) === parseFloat(formId);});
    return forms.length === 0 ? false : true;
  }

  openDialog(member: any) {
    setTimeout(() => {
      let incomingMember = new Parameters();
      incomingMember.setAttributes(member);
      if (this.isDialogOpen === false) {
        this.isDialogOpen = true;
        const dialogRef = this.dialog.open(DialogMemberComponent, {
          data: {member: incomingMember.getAttributes()},
          width: '120%',
          height: '100%',
          disableClose: false,
        });
        dialogRef.afterClosed().subscribe(() => {
          this.isDialogOpen = false;
        });
      }
    });
  }
}
