<mat-form-field  class="example-full-width">
    <mat-label></mat-label>
    <input matInput
               type="text"
               class="sawasdee20"
               #suggestions
               #trigger="matAutocompleteTrigger"
               [ngStyle]="{'text-align': 'center'}"
               [formControl] = "suggestionControl"
               (input)="onInputTextChange($event)"
               [matAutocomplete] = "suggestion" />
                <mat-autocomplete #suggestion = "matAutocomplete"
                                  (optionSelected)="onSuggestionSelectionChanged($event)"
                                  [displayWith]="this.data.flag === 'questions' ? displayFnLabel : displayFn">
                    <mat-option *ngFor="let suggestion of filteredSuggestions | async; let incresemental = index" [value]= "suggestion" >
                        {{data.flag === 'responses' ? suggestion.title : (data.flag === 'questions' ?  suggestion.label : suggestion.description)}}
                    </mat-option>
                </mat-autocomplete>
                <h4 [ngStyle]="{'color': 'red', 'font-weight': 'normal'}"
                    *ngIf="service.isNull(suggestions.value) === false &&
                    ((filteredSuggestions | async) | json).length === 2">
                    **---Not Found *---
                </h4>
</mat-form-field>
<div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <center>
        <a class="btn btn-sm btn-default" (click)="add(suggestions.value)">Done</a>
        <a class="btn btn-sm btn-default" (click)="cancel()">Cancel</a>
    </center>
</div>