import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {AmbassadorsService} from '../../ambassadors/ambassadors.service';
import {DialogAmbassadorsComponent} from '../../ambassadors/dialog-ambassadors/dialog-ambassadors.component';
import {FormService} from '../../forms/form.service';
import {Parameters} from '../../parameters';

@Component({
  selector: 'app-list-invites',
  templateUrl: './list-invites.component.html',
  styleUrls: ['./list-invites.component.scss']
})
export class ListInvitesComponent implements OnInit {
  app: any;
  incomingForm: any;
  constructor(public service: AppService,
              private formService: FormService,
              private dialog: MatDialog,
              private metthods: AmbassadorsService) {
    this.app = service.app;
    this.incomingForm = new Parameters();
    this.incomingForm.setAttributes(formService.getFormById(this.formService.formSelectedId));
    this.app.form.modules  = [{path: '/app/invites', title: 'Ambassadors'}];
    this.app.form.subtitle = 'the dead body of an animal ready for butchery, after removal of skin, visceral organs, head, feet etc..';
    this.app.form.module   = 'ambassadors';
  }

  ngOnInit(): void {
    this.metthods.setData(this.app.data.ambassadors);
  }

  openDialog(){
    this.dialog.open(DialogAmbassadorsComponent, {data  : {}, width : '55%', height: '100%', });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Register').toString().toUpperCase() + ' ,respodents';
      this.service.header = ('Respodent').toString().toUpperCase() + ' (' + this.app.data.ambassadors.data.length + ') ,registration';
    });
  }

}
