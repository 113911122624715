import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Parameters} from "../../parameters";

@Component({
  selector: 'app-header-jobinnv120',
  templateUrl: './header-jobinnv120.component.html',
  styleUrls: ['./header-jobinnv120.component.scss']
})
export class HeaderJobinnv120Component implements OnInit {
  @ViewChild('addressId', {static: false})  addressId: any;
  app: any;
  strings: any;
  constructor(public service: AppService,
              private broadcastChannelService: BroadcastChannelService) {
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
  }
  headerClass = 'header-2';
  ngOnInit(): void {
    this.app = this.service.app;
    this.addressId = ElementRef;
  }

  onGoogleMapAutocompleteSelected(result: any) {
    let geomentry: any = new Parameters();
    geomentry.setAttributes({});
    geomentry.setAddress(this.addressId.nativeElement.value);
    geomentry.setLat(result.geometry.location.lat());
    geomentry.setLng(result.geometry.location.lng());
    this.broadcastChannelService.emitNavChangeEvent({action: this.strings.getLocation(), item: geomentry.getAttributes()});
    this.addressId.nativeElement.value = '';
  }

  onLocationSelected(location: any) {

  }

  onMenuClicked(){
    this.broadcastChannelService.emitNavChangeEvent('menu.toggle');
  }


  openDateMenu(){
    this.broadcastChannelService.emitNavChangeEvent('openDateMenu');
  }

}
