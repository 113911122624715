import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {FormService} from "../../form.service";
import {AmbassadorsService} from "../../../ambassadors/ambassadors.service";
import {TeamService} from "../../../teams/team.service";
import {ClientService} from "../../../clients/client.service";
import {CampaignService} from "../../../campaigns/campaign.service";
import {AppService} from "../../../app.service";
import {Parameters} from "../../../parameters";

@Component({
  selector: 'app-forms-list-partial',
  templateUrl: './forms-list-partial.component.html',
  styleUrls: ['./forms-list-partial.component.scss']
})
export class FormsListPartialComponent implements OnInit {
  campaign: any;
  selectedTabIndex: any = 0;
  incomingCampaign: any;
  subscription: any;
  form: any;
  searchIcon: any = false;
  searchInput: any = true;
  incomingCampaignSelected: any;
  position: any;
  incomingUser: any;
  strings: any;
  incomingForm: any;
  stopFormRequesting: any = false;
  matSpinner: any = false;
  searchCntrl: any = '';
  constructor(private router: Router,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService,
              private ambassadorService: AmbassadorsService,
              private teamService: TeamService,
              private clientService: ClientService,
              private campaignService: CampaignService,
              public service: AppService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.incomingCampaignSelected = new Parameters();
    this.strings = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingCampaignSelected.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingForm.setAttributes({});
    this.incomingCampaign.setAttributes({});
  }

  ngOnInit(): void {
    this.incomingUser.setAttributes(this.service.app.data.user);
    if(parseFloat(this.incomingUser.getAttributes().role.id) === 2){
      if(parseFloat(this.service.app.data.forms.data.length) > 0){
        this.onSelectedForm(this.service.app.data.forms.data[this.service.app.data.forms.data.length - 1]);
      }
    }
    if(this.service.app.data.forms.data.length === 0) this.requestFormAndlimit(10);
    this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campaign') === true) {
              this.campaignService.campaignSelectedId = item.campaign.id;
            }else if(item.hasOwnProperty('form') === true){
              this.form = item.form;
              this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.form.id) + '/dashboard');
            }
          }
        });
  }

  getFormsBySearchText(searchText: any){
    return this.service.app.data.forms.data.filter((form: any) => JSON.stringify(form).toLowerCase().includes(searchText.toLowerCase()));
  }

  search(text){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setSearchText(text);
    this.formService.search(outgoingRequest, (forms: any) => {
      if(forms !== null){
        forms.map((form: any) => {
          let incomingForm: any = new Parameters();
          incomingForm.setAttributes(form);
          if(this.service.findIntegerIndex(this.service.app.data.forms.data, this.strings.getId(), incomingForm.getId()) === -1) {
            incomingForm.setStatus(0);
            this.service.app.data.forms.data.push(incomingForm.getAttributes());
            setTimeout(() => {
              let elementId: any = document.getElementById(incomingForm.getId());
              elementId.scrollIntoView({behavior: 'smooth'});
            });
          }
        });
      }
    });
  }

  requestFormAndlimit(status: any){
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    if(this.service.app.data.forms.data.length !== 0)
      outgoingRequest.setAscendinglastAt(this.service.app.data.forms.data[this.service.app.data.forms.data.length - 1].id);
    this.service.httpService( 'POST',
        this.service.app.routes.forms.list,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (forms: any) => {
          forms.map((form: any) => {
            let incomingForm: any = new Parameters();
            incomingForm.setAttributes(form);
            incomingForm.setAttributes(this.formService.getForm(incomingForm.getAttributes()));
            incomingForm.setStatus(0);
            status -= 1;
            this.service.app.data.forms.data = this.service.orderBy(this.service.app.data.forms.data, 'desc', 'id');
          });setTimeout(() => {
            if(forms.length !== 0 && status > 0)
              this.requestFormAndlimit(status);
            else if(this.service.app.data.forms.data.length === 1) {
              this.onSelectedForm(this.service.app.data.forms.data[0]);
            }else {this.service.matSpinner = false;
              window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            }
          });
        },  (error: any) => {
        });
  }

  ngAfterViewInit(): void {
  }

  requestCampaignById(id: any, call: any){
    if(this.campaignService.hasCampaignById(id)) call(this.campaignService.getCampaignById(id)); else {
      this.campaignService.requestCampaignById(id, (campaign: any) => {
        if(campaign === null) this.requestCampaignById(id, call); else{
          let incomingCampaign: any = new Parameters();
          incomingCampaign.setAttributes(campaign);
          call(incomingCampaign.getAttributes());
        }
      });
    }
  }

  requestClientById(id: any, call: any){
    if(this.clientService.hasClientById(id)) call(this.clientService.getClientById(id)); else {
      this.clientService.requestClientById(id, (client: any) => {
        if(client === null) this.requestClientById(id, call); else{
          let incomingClient: any = new Parameters();
          incomingClient.setAttributes(client);
          call(incomingClient.getAttributes());
        }
      });
    }
  }


  getFormByForm(form: any){
    let campaign: any = this.campaignService.getCampaignById(form.campaign_id);
    let client: any = this.clientService.getClientById(campaign.client_id);
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
  }


  onSelectedForm(form: any){
    this.broadcastChannelService.emitNavChangeEvent({action: 'onSelectForm', item: form});
  }


  add(){
    if(this.selectedTabIndex === 0) {
      this.campaignService.campaignSelectedId = undefined;
      this.formService.openDialog({});
    } else this.campaignService.openDialog({});
  }

}
