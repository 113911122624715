<div id="sidebar-menu" class="main_menu_side hidden-print main_menu" >
    <div class="menu_section">
        <h3>General</h3>
        <ul class="nav side-menu cbtn" *ngIf="getUrl().toString().includes('/forms/')">
            <li *ngFor="let module of app.settings.modules">
                <a class="{{module.title.toString().toLowerCase().includes('create') ? 'btn btn-default' : ''}}"
                   (click)="navigateUrl(module,0)"
                   *ngIf="!module.title.toString().includes('Switch off')">
                    {{getModule(module)}}
                    <ng-container *ngIf="module.hasOwnProperty('id') && module.id === '650177d3d8039';else doc">
                        <span ngClass="pull-right">
                            <ng-container *ngIf="module.hasOwnProperty('id')">
                              <mat-slide-toggle
                                      [ngStyle]="{'color':'#888','cursor':'pointer','margin-left':'0px', 'width':'100%'}"
                                      class="example-margin"
                                      (change) ="onliveToggleChange($event)"
                                      [checked]="this.incomingFormSettings.attributes.hasOwnProperty('live') &&
                                                 this.service.parseFloat(this.incomingFormSettings.getUnknown('live')) === 1">
                                    </mat-slide-toggle>
                            </ng-container>
                        </span>
                    </ng-container>
                    <ng-template #doc>
                        <span class="fa fa-chevron-down"></span>
                    </ng-template>
                    <ul class="nav child_menu">
                        <li *ngFor="let child of module['childs']">
                            <a (click)="navigateUrl(child,1)">{{child.title}}</a>
                            <span class="fa fa-chevron-down"></span>
                        </li>
                    </ul>
                </a>
                <a *ngIf="module.title.toString().includes('Switch off')" title="Switch off">
                    <mat-slide-toggle
                            [ngStyle]="{'color':'#888','cursor':'pointer','margin-left':'0px', 'width':'100%'}"
                            class="example-margin"
                            (change) ="onToggleChange($event)"
                            [checked]="isSwitchOff()">
                    </mat-slide-toggle>
                </a>
            </li>
        </ul>
    </div>
</div>
<input type="file"
       id="file"
       (change)="readFile($event)"
       [ngStyle]="{'position':'absolute','left':'-300px'}"
       #file>
<div id="form"> </div>
<!--accept="application/vnd.ms-excel"-->
