import {Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges} from '@angular/core';
import {AppService} from '../../../../app.service';
import {FormService} from '../../../form.service';
import {Parameters} from '../../../../parameters';
import {ResponseService} from "../../../responses/data/response.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
@Component({
  selector: 'app-respond-email-controls',
  templateUrl: './respond-email-controls.component.html',
  styleUrls: ['./respond-email-controls.component.scss']
})
export class RespondEmailControlsComponent implements OnChanges, AfterViewInit,OnInit {
  @ViewChild('emailElement', {static: false})  emailElement: any;
  @Input() question: any;
  @Input() position: any;
  label: any;
  incomingQuestion: any;
  incomingQuestionSettings: any;
  incomingResponse2: any;
  outgoingRequest: any;
  incomingFormSettings: any;
  incomingForm: any;
  incomingResponse: any;
  isEmailed: any = false;
  constructor(public service: AppService,
              public responseService: ResponseService,
              private broadcastChannel: BroadcastChannelService,
              public formService: FormService) {
     this.emailElement = ElementRef;
     this.incomingQuestion = new Parameters();
    this.incomingQuestionSettings = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingResponse2 = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingResponse2.setAttributes(this.responseService.response2);
    this.incomingResponse.setAttributes(this.responseService.getSelected());
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setReference2(
        this.incomingFormSettings.getAttributes().hasOwnProperty('reference2') ?
        this.incomingFormSettings.getReference2() : '');
  }



  ngOnChanges(changes: any): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
    this.incomingQuestionSettings.setIsScanning(
        this.incomingQuestionSettings.getAttributes().hasOwnProperty('is_scanning') ?
            this.incomingQuestionSettings.getIsScanning() : 0);
    if(parseFloat(this.incomingQuestionSettings.getIsScanning()) === 1){
      this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.button.next.hide});
    }
  }



  checkIsScanning(){
    if(parseFloat(this.incomingQuestionSettings.getIsScanning()) === 1){
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      this.outgoingRequest.setEmail(this.emailElement.nativeElement.value);
      this.service.httpService('post', this.service.app.routes.responses.get.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
        this.incomingResponse2.setAttributes(result);
        this.incomingResponse2.setId(this.incomingResponse2.getAttributes().hasOwnProperty('id') ?
        this.incomingResponse2.getId() : 0);
        this.responseService.response2 = this.incomingResponse2.getAttributes();
      }, (error: any) =>  {
      });
    }
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        if (this.emailElement.nativeElement){
          this.emailElement.nativeElement.focus();
        }
      });
  }

  ngOnInit(): void {
    this.ngOnChanges({});
  }

}
