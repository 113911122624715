import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-registra',
  templateUrl: './registra-header.component.html',
  styleUrls: ['./registra-header.component.scss']
})
export class RegistraHeaderComponent implements OnInit {

  headerClass = 'header-2';
  app: any;
  constructor(public service: AppService) {
    this.app = this.service.app;
  }

  ngOnInit(): void {
  }

}
