<table id="datatable" class="table table-striped table-bordered" style="width:100%">
    <thead>
    <tr>
        <th class="mdl-data-table__cell--non-numeric headline2">Item</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Quantity</th>
        <th class="mdl-data-table__cell--non-numeric headline2">User</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
        <th class="mdl-data-table__cell--non-numeric headline2 text-right">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let inventory of (flag.includes('all') ?
      (inventoryService.inventories.data | jsonFilter:searchCntrl) :
      (inventoryService.inventories.trashed.data | jsonFilter:searchCntrl))">
        <td class="mdl-data-table__cell--non-numeric headline">{{incomingQuestionSelected.attributes.hasOwnProperty('id') ? incomingQuestionSelected.getLabel() : inventory.item.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{inventory.in_out_qty}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{inventory.user.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="inventory.description"></td>
        <td class="mdl-data-table__cell--non-numeric headline">
<!--            <div class="dropdown pull-right">-->
<!--                <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>-->
<!--                    <span class="caret"></span>-->
<!--                </button>-->
<!--                <ul class="dropdown-menu">-->
<!--                    &lt;!&ndash;      <li mat-menu-item  (click)="handleCopy()">Copy</li>&ndash;&gt;-->
<!--                    <li mat-menu-item *ngIf="service.status !== 1" (click)="handleUpdate(selectedCampaign)">Edit</li>-->
<!--                    <li mat-menu-item *ngIf="service.status !== 0" (click)="handleDestroy(selectedCampaign)">Delete</li>-->
<!--                    <li mat-menu-item *ngIf="service.status === 0" (click)="handleRestore(selectedCampaign)">Restore</li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div style="visibility: hidden; position: fixed"-->
<!--                 [style.left]="contextMenuPosition.x"-->
<!--                 [style.top]="contextMenuPosition.y"-->
<!--                 [matMenuTriggerFor]="contextMenu">-->
<!--            </div>-->
<!--            <mat-menu #contextMenu="matMenu">-->
<!--                <ng-template matMenuContent let-item="inspector">-->
<!--                    &lt;!&ndash;      <button mat-menu-item (click)="handleCopy()">Copy</button>&ndash;&gt;-->
<!--                    <button mat-menu-item *ngIf="service.status !== 0" (click)="handleUpdate(selectedCampaign)">Edit</button>-->
<!--                    <button mat-menu-item *ngIf="service.status !== 0" (click)="handleDestroy(selectedCampaign)">Delete</button>-->
<!--                    <button mat-menu-item *ngIf="service.status === 0" (click)="handleRestore(selectedCampaign)">Restore</button>-->
<!--                </ng-template>-->
<!--            </mat-menu>-->
        </td>
    </tr>
    </tbody>
</table>
