<div style="margin-top: 20px">
<!--    <mat-accordion [multi]="true">-->
<!--        <mat-expansion-panel [expanded]="true" *ngIf="controlId === 10">-->
<!--            <div style="margin-top: 10px">-->
<!--                <table>-->
<!--                    <td [ngStyle]="{'width':'100%'}" valign="top">-->
<!--                        Maximum value-->
<!--                    </td>-->
<!--                    <td valign="top">-->
<!--                        <mat-form-field  class="pull-right" appearance="outline">-->
<!--                            <mat-label></mat-label>-->
<!--                            <input type="text"-->
<!--                                   placeholder=""-->
<!--                                   aria-label = "Number"-->
<!--                                   matInput-->
<!--                                   autocomplete="off"-->
<!--                                   [ngStyle]="{'text-align':'center'}"-->
<!--                                   [ngModel]="incomingQuestion.attributes.conditions.hasOwnProperty('maximun_value') ? incomingQuestion.getConditions()['maximun_value'] : ''"-->
<!--                                   (keydown)="service.validateNumber($event)"-->
<!--                                   (input)="assignInputCondition($event, 'maximun_value')"-->
<!--                            />-->
<!--                        </mat-form-field>-->
<!--                    </td>-->
<!--                </table>-->
<!--            </div>-->
<!--        </mat-expansion-panel>-->
<!--    </mat-accordion>-->
</div>
<div id="form"></div>
