import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogProfileComponent} from '../users/dialog-profile/dialog-profile.component';
import {FormService} from '../forms/form.service';
import {Parameters} from "../parameters";
import {DashboardService} from "../dashboard/dashboard.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {CampaignService} from "../campaigns/campaign.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuTrigger') trigger: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  form: any;
  app: any;
  selectedSuggestionBox: any;
  incomingCampaign: any;
  incomingSelectedForm: any;
  keys: any;
  ranges: any  = {
        'Today'       : [moment(), moment()],
        'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month'  : [moment().startOf('month'), moment().endOf('month')],
        'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
// pickerDirective: any;
// @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              private campaignService: CampaignService,
              public formMetthods: FormService,
              public dashboardService: DashboardService,
              private router: Router) {
    this.incomingCampaign = new Parameters();
    this.app = this.service.app;
    this.contextMenu = MatMenuTrigger;
    this.form = this.formMetthods.getSelected();
    this.incomingSelectedForm = new Parameters();
    this.incomingSelectedForm.setAttributes(this.form);
    this.incomingCampaign.setAttributes(this.campaignService.getSelected());
  }

  ngOnInit(): void {
  }

  onSelectCampaign(){
    let args: any = prompt('', this.incomingCampaign.getName());
    if (!this.service.isNull(args)) {
      this.service.header = this.service.header.replaceAll(this.incomingCampaign.getName().toUpperCase(), args.toUpperCase());
      this.incomingCampaign.setName(args);
      this.campaignService.updateByCampaign(this.incomingCampaign.getAttributes(), (output: any) => {
        if(output !== null){
          document.title = this.incomingCampaign.getName();
        }
      });
    }
  }

  getHeader(){return this.service.header;}

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  dateRangeValues(){
    return moment(new Date(this.dashboardService.incomingReport.attributes.starting_at)).format('MMMM D, YYYY') + ' - ' +
        moment(new Date(this.dashboardService.incomingReport.attributes.ending_at)).format('M D, YYYY');
  }


  openProfile(){
    this.dialog.open(DialogProfileComponent, { data:  { }, width  : '55%', height : '100%', });
  }

  setSuggestionBox(){
    this.selectedSuggestionBox = this.formMetthods.getSelected();
    this.keys = Object.keys(this.selectedSuggestionBox).length;
  }

  openDatepicker(event: any){
    this.trigger.closeMenu();
    this.broadcastChannel.emitNavChangeEvent('dashboard');
  }

  openDateMenu(){
 // this.dialog.open(FilterDashboardComponent, { data:  { }, width  : '55%', height : '100%',});
    this.broadcastChannel.emitNavChangeEvent('openDateMenu');
  }

  logout(){
    localStorage.setItem(this.service.app.settings.tokenName, '');
    this.service.setToken('');
    this.router.navigateByUrl('login');
  }
  setUser(user: any){}
}
