import { Component, OnInit, AfterViewInit, NgZone, Inject } from '@angular/core';
import {AppService} from '../../../app.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormService} from '../../../forms/form.service';
import {Parameters} from '../../../parameters';
import {ClientService} from '../../../clients/client.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {ResponseService} from '../../../forms/responses/data/response.service';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-dialog-generate-qrcode',
  templateUrl: './dialog-generate-qrcode.component.html',
  styleUrls: ['./dialog-generate-qrcode.component.scss']
})
export class DialogGenerateQrcodeComponent implements OnInit, AfterViewInit {
  incomingForm: any;
  incomingResponse: any;
  incomingSettingsForm: any;
  incomingNameQuestion: any;
  incomingPhoneQuestion: any;
  incomingQuestionResult: any;
  incomingEmailQuestion: any;
  incomingInvitationCardQuestion: any;
  incomingInvitationQuestion: any;
  incomingQrcodeQuestion: any;
  incomingSettingsQuestion: any;
  outgoingQuestion: any;
  outgoingRequest: any;
  incomingQuestion: any;
  incomingResponseResult: any;
  incomingClient: any;
  incomingCampaign: any;
  logo: any;
  frame: any;
  config: any = {
         width: 300,
         height: 300,
         data: '',
         image: '',
         margin: 0,
         dotsOptions: {
           color: "#000",
           type: "dots"
         },
         backgroundOptions: {
           color: "#ffffff",
         },
         imageOptions: {
           crossOrigin: "anonymous",
           margin: 0
         }
    };
  constructor(public  service: AppService,
              public zone: NgZone,
              private responseService: ResponseService,
              public  broadcastChannelService: BroadcastChannelService,
              public clientService: ClientService,
              private qrcode: NgxQrcodeStylingService,
              private formService : FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogGenerateQrcodeComponent>) {
     this.incomingForm = new Parameters();
     this.outgoingRequest = new Parameters();
     this.incomingResponse = new Parameters();
     this.incomingSettingsForm = new Parameters();
     this.incomingNameQuestion = new Parameters();
     this.incomingPhoneQuestion = new Parameters();
     this.incomingEmailQuestion = new Parameters();
     this.incomingInvitationQuestion = new Parameters();
     this.incomingInvitationCardQuestion = new Parameters();
     this.incomingResponseResult = new Parameters();
     this.incomingQrcodeQuestion = new Parameters();
     this.outgoingQuestion = new Parameters();
     this.incomingSettingsQuestion = new Parameters();
     this.incomingQuestion = new Parameters();
     this.incomingClient = new Parameters();
     this.incomingCampaign = new Parameters();
     this.incomingQuestionResult = new Parameters();
  }

  ngOnInit(): void {
       this.incomingForm.setAttributes(this.formService.getSelected());
       this.incomingClient.setAttributes(this.clientService.getSelected());
       this.incomingResponse.setAttributes(this.responseService.getSelected());
       this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
       this.incomingSettingsForm.setDateOnSet(this.incomingSettingsForm.attributes.hasOwnProperty('date_on_set') ?
        this.incomingSettingsForm.getDateOnSet() : '');
       this.incomingSettingsForm.setVenue(this.incomingSettingsForm.attributes.hasOwnProperty('venue') ?
           this.incomingSettingsForm.getVenue() : '');
       this.incomingSettingsForm.setTime(this.incomingSettingsForm.attributes.hasOwnProperty('time') ?
           this.incomingSettingsForm.getTime() : '');
       this.incomingSettingsForm.setFrameUrl(this.incomingSettingsForm.attributes.hasOwnProperty('frame_url') ?
           this.incomingSettingsForm.getFrameUrl() : '');
       this.incomingSettingsForm.setBanner(this.incomingSettingsForm.attributes.hasOwnProperty('banner') ?
           this.incomingSettingsForm.getBanner() : '');
       this.incomingSettingsForm.setFrameDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('frame_data_url') ?
           this.incomingSettingsForm.getFrameDataUrl() : '');
       this.incomingSettingsForm.setContact(this.incomingSettingsForm.attributes.hasOwnProperty('contact') ?
           this.incomingSettingsForm.getContact() : '');
       this.incomingSettingsForm.setDeclinedLandingPageContents(this.incomingSettingsForm.attributes.hasOwnProperty('declined_landing_page_contents') ?
           this.incomingSettingsForm.getDeclinedLandingPageContents() : '');
        this.incomingSettingsForm.setRSVPDate(this.incomingSettingsForm.attributes.hasOwnProperty('rsvp_date') ?
           this.incomingSettingsForm.getRSVPDate() : '');
        this.incomingSettingsForm.setAttendingDate(this.incomingSettingsForm.attributes.hasOwnProperty('attending_date') ?
           this.incomingSettingsForm.getAttendingDate() : '');
        this.incomingSettingsForm.setJobPosition(this.incomingSettingsForm.attributes.hasOwnProperty('job_position') ?
           this.incomingSettingsForm.getJobPosition() : '');
        this.incomingSettingsForm.setResponseChannel(this.incomingSettingsForm.attributes.hasOwnProperty('response_channel') ?
           this.incomingSettingsForm.getResponseChannel() : '');
        this.incomingSettingsForm.setAdvertUrl(this.incomingSettingsForm.attributes.hasOwnProperty('advert_url') ?
           this.incomingSettingsForm.getAdvertUrl() : '');
        this.incomingSettingsForm.setInvitationCard(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_card') ?
            this.incomingSettingsForm.getInvitationCard() : '');
        this.incomingSettingsForm.setBackgroundColor(this.incomingSettingsForm.attributes.hasOwnProperty('background_color') ?
            this.incomingSettingsForm.getBackgroundColor() : '');
        this.incomingSettingsForm.setContactPersonName(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_name') ?
            this.incomingSettingsForm.getContactPersonName() : '');
        this.incomingSettingsForm.setContactPersonPhone(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_phone') ?
            this.incomingSettingsForm.getContactPersonPhone() : '');
        this.incomingSettingsForm.setInvitationContents(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_contents') ?
            this.incomingSettingsForm.getInvitationContents() : '');
        this.incomingSettingsForm.setContactPersonTitle(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_title') ?
        this.incomingSettingsForm.getContactPersonTitle() : '');
            this.incomingSettingsForm.setCompanyName(this.incomingSettingsForm.attributes.hasOwnProperty('company_name') ?
        this.incomingSettingsForm.getCompanyName() : '');
        this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
            this.incomingSettingsForm.getAttendeeName() : '');
        this.incomingSettingsForm.setAttendeePhone(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_phone') ?
            this.incomingSettingsForm.getAttendeePhone() : '');
        this.incomingSettingsForm.setAttendeeEmail(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_email') ?
            this.incomingSettingsForm.getAttendeeEmail() : '');
        this.incomingSettingsForm.setColor(this.incomingSettingsForm.attributes.hasOwnProperty('color') ?
            this.incomingSettingsForm.getColor() : '');
        this.incomingSettingsForm.setConfirmationContents(this.incomingSettingsForm.attributes.hasOwnProperty('confirmation_contents') ?
            this.incomingSettingsForm.getConfirmationContents() : '');
        this.incomingSettingsForm.setSalutation(this.incomingSettingsForm.attributes.hasOwnProperty('salutation') ?
            this.incomingSettingsForm.getSalutation() : '');
        this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
            this.incomingSettingsForm.getLogoUrl() : '');
        this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
            this.incomingSettingsForm.getLogoDataUrl() : '');
        this.incomingForm.getControls().map((question: any) => {
             this.incomingQuestion.setAttributes(question);
             if(this.incomingResponse.getAttributes().hasOwnProperty('datas')){
                 this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
                  this.incomingResponseResult.setAttributes(responseResult);
                   if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()){
                     this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
                     this.incomingQuestion.setData(this.incomingResponseResult.getData());
                     this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
                     this.incomingQuestion.setPostId(this.incomingResponse.getId());
                   }
                 });
             }else{
              this.incomingQuestion.setData(this.incomingQuestion.getAttributes().hasOwnProperty('data') ?
               this.incomingQuestion.getData():'');
              this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') ?
               this.incomingQuestion.getResult():{});
             }
        });
        this.setControls((result: any)=>{
          this.logo = this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                    !this.service.isNull(this.incomingEmailQuestion.getData()) &&
                    this.incomingEmailQuestion.getData().toString().includes('@gmail.com') &&
                    !this.service.isNull(this.incomingSettingsForm.getLogoUrl()) ?
                    this.incomingSettingsForm.getLogoUrl() :
                    this.incomingSettingsForm.getLogoDataUrl();
          this.frame = this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
                    !this.service.isNull(this.incomingEmailQuestion.getData()) &&
                    this.incomingEmailQuestion.getData().toString().includes('@gmail.com') &&
                    !this.service.isNull(this.incomingSettingsForm.getFrameUrl()) ?
                    this.incomingSettingsForm.getFrameUrl() :
                    this.incomingSettingsForm.getFrameDataUrl();
          this.incomingInvitationCardQuestion.setUnknown('data',
                    this.incomingInvitationCardQuestion.getUnknown('data').
                    replaceAll('*logo',this.logo).
                    replaceAll('*frame',this.frame).
                    replaceAll('*name',this.incomingNameQuestion.getData()).
                    replaceAll('*phone',this.incomingPhoneQuestion.getData()).
                    replaceAll('*event',this.incomingForm.getName()).
                    replaceAll('*time',this.incomingSettingsForm.getTime())
          );
          this.incomingInvitationQuestion.setUnknown('data',
                     this.incomingInvitationQuestion.getUnknown('data').
                     replaceAll('*logo',this.logo).
                     replaceAll('*frame',this.frame).
                     replaceAll('*name',this.incomingNameQuestion.getData()).
                     replaceAll('*email',this.incomingEmailQuestion.getData()).
                     replaceAll('*phone',this.incomingPhoneQuestion.getData()).
                     replaceAll('*event',this.incomingForm.getName()).
                     replaceAll('*time',this.incomingSettingsForm.getTime())
         );
           if(this.service.isNull(this.incomingQrcodeQuestion.getData())){
              this.service.setCrypt(this.incomingResponse.getCntrlNum(),(result: any)=>{
                      this.config.data=window.location.host + this.service.app.routes.invitations.confirmation.toString().replaceAll('xx',result);
                      this.config.data=this.config.data.toString().includes('https://') ? this.config.data : 'https://' + this.config.data;
                      if(!this.service.isNull(this.incomingSettingsForm.getBackgroundColor()))
                      this.config.data=this.config.data +'&extension='+this.incomingSettingsForm.getBackgroundColor();
                      if(!this.service.isNull(this.service.logo))
                      this.config.data= this.config.data +'&access='+this.incomingClient.getUnknown('logo').split('clients/')[1];
                      this.incomingClient.setUnknown('data',this.config.data);
                      this.incomingQrcodeQuestion.setUnknown('data',this.config.data);
                      setTimeout((result)=>{
                       let elementHtml:any = document.getElementById('qrcode');
                       this.qrcode.create(this.config, elementHtml).subscribe((res) => {
                        setTimeout(() => {
                            setTimeout(() => {
                              html2canvas(elementHtml).then(canvas => {
                                this.zone.run(() =>{
                                    this.incomingQrcodeQuestion.getResult().path = canvas.toDataURL("image/png");
                                    this.incomingInvitationCardQuestion.setUnknown('data',
                                       this.incomingInvitationCardQuestion.getUnknown('data').
                                       replaceAll('*qrcode',this.incomingQrcodeQuestion.getResult().path));
                                       this.updateInvitation((result: any)=>{
                                         this.updateInvitationCard((result: any)=>{
                                          this.updateQrcode((result: any)=>{
                                           this.dialogRef.close();
                                           setTimeout(() => {
                                             this.broadcastChannelService.emitNavChangeEvent('qrcodeCloseDialog');
                                           });
                                          });
                                         });
                                       });
                                });
                              });
                            },this.service.timeout());
                       });
                       });
                      });
              });
           }else{
            this.dialogRef.close();
            setTimeout(() => {
              this.broadcastChannelService.emitNavChangeEvent('qrcodeCloseDialog');
            });
           }
        });
  }

  updateInvitation(callback: any){
     this.outgoingRequest.setAttributes({});
     if(this.incomingInvitationQuestion.getAttributes().hasOwnProperty('response_result'))
     this.outgoingRequest.setId(this.incomingInvitationQuestion.getResponseResultId());
     this.outgoingRequest.setData(this.incomingInvitationQuestion.getData());
     this.outgoingRequest.setFormControlId(this.incomingInvitationQuestion.getId());
     this.outgoingRequest.setPostId(this.incomingResponse.getId());
     this.outgoingRequest.setControlId(this.incomingInvitationQuestion.getControlId());
     this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingInvitationQuestion.getResult())));
     this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
     callback(null);
     }, (error: any) =>  {callback(null);});
  }

  updateInvitationCard(callback: any){
       this.outgoingRequest.setAttributes({});
       if(this.incomingInvitationCardQuestion.getAttributes().hasOwnProperty('response_result'))
       this.outgoingRequest.setId(this.incomingInvitationCardQuestion.getResponseResultId());
       this.outgoingRequest.setData(this.incomingInvitationCardQuestion.getData());
       this.outgoingRequest.setFormControlId(this.incomingInvitationCardQuestion.getId());
       this.outgoingRequest.setPostId(this.incomingResponse.getId());
       this.outgoingRequest.setControlId(this.incomingInvitationCardQuestion.getControlId());
       this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingInvitationCardQuestion.getResult())));
       this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
       callback(null);
       }, (error: any) =>  {callback(null);});
  }

  updateQrcode(callback: any){
         this.outgoingRequest.setAttributes({});
         if(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('response_result'))
         this.outgoingRequest.setId(this.incomingQrcodeQuestion.getResponseResultId());
         this.outgoingRequest.setData(this.incomingQrcodeQuestion.getData());
         this.outgoingRequest.setFormControlId(this.incomingQrcodeQuestion.getId());
         this.outgoingRequest.setPostId(this.incomingResponse.getId());
         this.outgoingRequest.setControlId(this.incomingQrcodeQuestion.getControlId());
         this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingQrcodeQuestion.getResult())));
         this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
         callback(null);
         }, (error: any) =>  {callback(null);});
  }

  setControls(callback: any){
       this.incomingInvitationQuestion.setAttributes({});
       this.incomingInvitationCardQuestion.setAttributes({});
       this.incomingNameQuestion.setAttributes({});
       this.incomingPhoneQuestion.setAttributes({});
       this.incomingEmailQuestion.setAttributes({});
       this.incomingQrcodeQuestion.setAttributes({});
       this.incomingForm.getControls().map((question: any)=>{
            this.outgoingQuestion.setAttributes(question);
            this.incomingSettingsQuestion.setAttributes(Object.assign({},this.outgoingQuestion.getSettings()));
            if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationCard()){
             this.incomingInvitationCardQuestion.setAttributes(this.outgoingQuestion.getAttributes());
             this.incomingInvitationCardQuestion.setUnknown('data',this.incomingInvitationCardQuestion.getUnknown('label'));
            }else if(parseFloat(this.outgoingQuestion.getControlId()) === 33){
              this.incomingQrcodeQuestion.setAttributes(this.outgoingQuestion.getAttributes());
              this.incomingQrcodeQuestion.setResult(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('result') ?
                this.incomingQrcodeQuestion.getResult():{});
              this.incomingQrcodeQuestion.setResult(!this.service.isNull(this.incomingQrcodeQuestion.getResult()) ?
                this.incomingQrcodeQuestion.getResult():{});
              this.incomingQuestionResult.setAttributes(this.incomingQrcodeQuestion.getResult());
              this.incomingQuestionResult.setPath(this.incomingQuestionResult.getAttributes().hasOwnProperty('path') ?
                this.incomingQuestionResult.getPath():'');
            }else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationContents()){
              this.incomingInvitationQuestion.setAttributes(this.outgoingQuestion.getAttributes());
              this.incomingInvitationQuestion.setUnknown('data',this.incomingInvitationQuestion.getUnknown('label'));
             }else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeName())
              this.incomingNameQuestion.setAttributes(this.outgoingQuestion.getAttributes());
            else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeePhone())
              this.incomingPhoneQuestion.setAttributes(this.outgoingQuestion.getAttributes());
            else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeEmail())
              this.incomingEmailQuestion.setAttributes(this.outgoingQuestion.getAttributes());
       });
       callback(null);
   }

  ngAfterViewInit(): void {
      setTimeout(()=>{
      });
  }

}
