import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotifierService} from 'angular-notifier';
import {Parameters} from '../../parameters';
import {RegionsService} from '../regions.service';

@Component({
  selector: 'app-dialog-regional',
  templateUrl: './dialog-regional.component.html',
  styleUrls: ['./dialog-regional.component.scss']
})
export class DialogRegionalComponent implements OnInit {

  selectedRegionalStatus: any;
  incomingSelectedRegional: any;
  formValues: FormGroup = new FormGroup({});
  regionals: any;
  configAngularEditor: any;
  constructor(
    public dialogRef: MatDialogRef<DialogRegionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public serviceImpl: AppService,
    public regionalImpl: RegionsService,
    private notifier: NotifierService,
    public formBuilder: FormBuilder) {
    this.regionals = regionalImpl.getData();
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(regionalImpl.getSelected());
    this.selectedRegionalStatus = Object.keys(this.incomingSelectedRegional.getAttributes()).length;
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {this.regionalImpl.setSelected({}); });
    this.configAngularEditor = this.serviceImpl.setAngularEditorConfig();
    this.formValues = this.formBuilder.group({
      name:                ['', Validators.compose([])],
      country_id:          [1, Validators.compose([])],
      description:         ['', Validators.compose([])],
    });

    setTimeout(() => {
      console.log(this.incomingSelectedRegional.getAttributes());
      if(this.selectedRegionalStatus === 0)this.incomingSelectedRegional.setAttributes({}); else{
        for(var key in this.incomingSelectedRegional.getAttributes()){
          if(this.incomingSelectedRegional.attributes[key] instanceof Object || this.incomingSelectedRegional.attributes[key] instanceof Array){} else{

            if(this.formValues.contains(key))
              this.formValues.controls[key].setValue(this.incomingSelectedRegional.attributes[key]);
            else this.formValues.addControl(key, new FormControl(this.incomingSelectedRegional.attributes[key], Validators.compose([])));
          }
        }
      }
    });

  }

  closeDialog(){ this.dialogRef.close(null);}
  onSubmit(regionalForm: any) {
    setTimeout((notifier: any) => {
      this.serviceImpl.httpService(
        'post',
        this.selectedRegionalStatus === 0 ? '/regions' : '/regions/' + parseFloat(this.incomingSelectedRegional.getId()) + '/update',
        regionalForm, {},
        (response: any) => {
          if(this.selectedRegionalStatus === 0) {
            this.regionals.data.unshift(response);
            this.notifier.notify('success', 'successfull added');
            this.formValues.controls['name'].setValue(null);
            this.formValues.controls['description'].setValue(null); } else{
            this.incomingSelectedRegional = Object.assign(this.incomingSelectedRegional.getAttributes(), response);
            this.notifier.notify('success', 'successfull updated');
            this.closeDialog();
          }
        }, (error: any) => {
        });
    });
  }

}
