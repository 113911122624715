<div class="custom">
    <agm-map
        #gm
        (mapReady)="mapReadyHandler($event)"
        (mapClick)="onClickMap(gm)"
        [latitude]="lat"
        [longitude]="lng"
        [fitBounds]="false"
        [zoom]="zoom"
        (contextmenu)="flag=2;onContextMenu($event, {})"
        [disableDefaultUI]="true"
        [styles]="service.app.settings.map.styles.style1"
        [ngStyle]="{'height' : getWindow().height + 'px'}">
        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
        <ng-container *ngIf="service.app.data.routes.data.length > 0 && incomingRouteSelected.getAttributes().hasOwnProperty('id')">
            <ng-container *ngFor="let route of (service.app.data.routes.data | jsonPropertyFilter: incomingRouteSelected.getId().toString():'id')">
                <agm-direction
                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : route.hasOwnProperty('stroke_color') ? route.stroke_color : '#ff8000'}}"
                        [provideRouteAlternatives]="false"
                        [waypoints]="route.waypoints2"
                        [origin]="{ lat: service.parseFloat(route.origin.lat), lng: service.parseFloat(route.origin.lng)}"
                        [destination]="{ lat: service.parseFloat(route.destination.lat), lng: service.parseFloat(route.destination.lng) }"
                >
                </agm-direction>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="incomingRouteSelected.getAttributes().hasOwnProperty('id')">
            <ng-container *ngFor="let route of (service.app.data.routes.data | jsonPropertyFilter: incomingRouteSelected.getId().toString():'id')">
                <ng-container *ngFor="let waypoint of route.waypoints; let incremental = index">
                    <agm-overlay
                            [latitude]="waypoint.lat"
                            [longitude]="waypoint.lng">
                        <ul class="list-inline bubbleStyle2a"
                            [ngStyle]="{'font-family':'Sawasdee','font-size':'14px'}">
                            <li>
                                <i class="cursor material-icons"
                                   (click)="markerRemove(route,waypoint)">cancel</i>
                            </li>
                            <li>
                                <span class="cursor"
                                      (click)="markerUpdate(route,waypoint)">
                                    {{service.upperStartingCharacter(waypoint.name,true)}}
                                </span>
                            </li>
                        </ul>
                    </agm-overlay>
                    <!--                <agm-marker-->
                    <!--                        [ngStyle]="{'background-color':'#000'}"-->
                    <!--                        [latitude]="waypoint.location.lat"-->
                    <!--                        [longitude]="waypoint.location.lng"-->
                    <!--                        [iconUrl]="icon"-->
                    <!--                        [agmFitBounds]="false"-->
                    <!--                        [openInfoWindow]="true">-->
                    <!--                    <agm-info-window-->
                    <!--                            [isOpen]="true"-->
                    <!--                            [disableAutoPan]="false"-->
                    <!--                            #infoWindow>-->
                    <!--                        <span class="bubbleStyle2a"-->
                    <!--                              [ngStyle]="{'font-family':'Sawasdee','font-size':'14px'}">-->
                    <!--                            <span class="cursor"-->
                    <!--                                  (click)="markerUpdate(waypoint)">-->
                    <!--                                <span class="cursor pull-left">{{waypoint.location.title}}</span>-->
                    <!--                                <i class="cursor material-icons pull-left"-->
                    <!--                                   (click)="markerRemove(waypoint)">cancel</i>-->
                    <!--                            </span>-->
                    <!--                        </span>-->
                    <!--                    </agm-info-window>-->
                    <!--                </agm-marker>-->
                </ng-container>
            </ng-container>
        </ng-container>


        <agm-marker
                [latitude]="currentLocationLat"
                [longitude]="currentLocationLng"
                (markerClick)="
                 currentLocationLat1 = currentLocationLat;
                 currentLocationLng1 = currentLocationLng;
                 addMarker()"
                class="blink_me"
                [iconUrl]='currentLocationPositionIconUrl'>
        </agm-marker>
    </agm-map>
</div>
<!--<a mat-mini-fab-->
<!--   class="defaultColor2"-->
<!--   (click)="addRoute()"-->
<!--   [ngStyle]="{'position': 'absolute','top':'15px','right':'15px'}">-->
<!--    <i class="material-icons">add</i>-->
<!--</a>-->


<div [ngStyle]="{'position': 'fixed','top':'15px','left':'300px'}">
    <div [ngStyle]="{'border-radius': '25px','background':'#fff'}" class="mdl-shadow--2dp">
        <div [ngStyle]="{'margin-right': '5px','margin-top':'10px'}">
            <ul class="list-inline">
<!--                <li>-->
<!--                    <i class="material-icons voice">search</i>-->
<!--                </li>-->
                <li class="">
                    <input type="text"
                           matInput
                           #addressElement
                           autocomplete="off"
                           placeholder=""
                           class="searchbar sawasdee"
                           placeholder="."
                           [address]="addressValue"
                           [ngStyle]="{'text-align': 'center'}"
                           (input)="onInputTextChange($event)"
                           (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                           (onLocationSelected)="onLocationSelected($event)"
                           matGoogleMapsAutocomplete>
                </li>
            </ul>
        </div>
    </div>
</div>


<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
    <ng-template matMenuContent let-item="inspector" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <ng-container *ngIf="flag === 1;else addRoute">
            <button mat-menu-item  (click)="$event.stopPropagation();">
                <input type="text"
                       matInput
                       #searchCntrlElement
                       (input)="onInputRouteTextChange($event);"
                       autocomplete="off"
                       placeholder="search for route.."
                       class="sawasdee"
                       [ngStyle]="{'text-align': 'center','font-size':'11px'}">
            </button>
            <button mat-menu-item
                    class="sawasdee"
                    *ngFor="let route of service.app.data.routes.data | jsonFilter:searchCntrl"
                    (click)="incomingRouteSelected.setAttributes(route);
                    setWaypoints(this.incomingRouteSelected.getAttributes())">
                {{service.upperStartingCharacter(route.name,true).toLowerCase()}}</button>
        </ng-container>
        <ng-template #addRoute>
            <ng-container *ngIf="flag === 2; else content">
                <button mat-menu-item
                        class=""
                        (click)="
                        addMarker()">add route station point</button>
            </ng-container>
        </ng-template>

        <ng-template #content>
        </ng-template>

    </ng-template>
</mat-menu>

<div
   [ngStyle]="{'position': 'fixed','top':'15px','left':'15px'}">
    <div [ngStyle]="{'border-radius': '25px'}" class="mdl-shadow--2dp">
        <div [ngStyle]="{'margin': '10px'}">
            <ul class="list-inline">
                <li class="cursor"
                    (click)="removeRoute()">
                    <i class="material-icons">close</i>
                </li>
                <li class="cursor"
                    (click)="addRoute()">
                    <i class="material-icons">add</i>
                </li>
                <li class="cursor"
                    (click)="flag=1; onContextMenu($event, {})">
                    <i class="material-icons">keyboard_arrow_down</i>
                </li>
                <li class="cursor sawasdee14"
                    (click)="openRouteDialog()"
                    *ngIf="incomingRouteSelected.getAttributes().hasOwnProperty('id')">
                    {{incomingRouteSelected.getName().toLowerCase()}}
                </li>
            </ul>
        </div>
    </div>
</div>

