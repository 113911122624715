<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div>
                <mat-list>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Outlet name *</mat-label>
                                <input matInput
                                       type="text"
                                       [(ngModel)]="incomingReference2.attributes.name">
                                  <mat-slide-toggle
                                     class="pull-right"
                                     [checked]="incomingReference2.getAttributes().hasOwnProperty('hideName') && incomingReference2.getAttributes().hideName === 1"
                                     (change)="($event.checked ? incomingReference2.getAttributes().hideName = 1 : incomingReference2.getAttributes().hideName = 0)">
                                  </mat-slide-toggle>
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                        <span class="" mat-line>
                          <mat-form-field  class="example-full-width" appearance="outline">
                            <mat-label>Type *</mat-label>
                            <input matInput
                                   #outletTypeElement
                                   type="text"
                                   [formControl] = "outletTypeControl"
                                   [matAutocomplete] = "user">
                              <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="openPanel($event)">keyboard_arrow_down</i>
                              <mat-autocomplete #user = "matAutocomplete" (optionSelected)="onOutletTypeSelectionChanged($event)" [displayWith]="displayFn">
                                <mat-option *ngFor="let user of filteredUsersOptions | async; let userOf = index" [value]= "user" >
                                  {{user.name}}
                                </mat-option>
                             </mat-autocomplete>
                          </mat-form-field>
                        </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Owner *</mat-label>
                                <input matInput type="text"
                                       [(ngModel)]="incomingReference2.attributes.owner">
                                  <mat-slide-toggle
                                          class="pull-right"
                                          [checked]="incomingReference2.getAttributes().hasOwnProperty('hideOwner') && incomingReference2.getAttributes().hideOwner === 1"
                                          (change)="($event.checked ? incomingReference2.getAttributes().hideOwner = 1 : incomingReference2.getAttributes().hideOwner = 0)">
                                  </mat-slide-toggle>
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Phone *</mat-label>
                                <input matInput type="text"
                                       [(ngModel)]="incomingReference2.attributes.phone">
                                  <mat-slide-toggle
                                          class="pull-right"
                                          [checked]="incomingReference2.getAttributes().hasOwnProperty('hidePhone') && incomingReference2.getAttributes().hidePhone === 1"
                                          (change)="($event.checked ? incomingReference2.getAttributes().hidePhone = 1 : incomingReference2.getAttributes().hidePhone = 0)">
                                  </mat-slide-toggle>
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Shopkeeper *</mat-label>
                                <input matInput type="text"
                                       [(ngModel)]="incomingReference2.attributes.shopkeeper">
                                  <mat-slide-toggle
                                          class="pull-right"
                                          [checked]="incomingReference2.getAttributes().hasOwnProperty('hideShopkeeper') && incomingReference2.getAttributes().hideShopkeeper === 1"
                                          (change)="($event.checked ? incomingReference2.getAttributes().hideShopkeeper = 1 : incomingReference2.getAttributes().hideShopkeeper = 0)">
                                  </mat-slide-toggle>
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Shopkeeper contact *</mat-label>
                                <input matInput type="text"
                                       [(ngModel)]="incomingReference2.attributes.shopkeeper_contact">
                                  <mat-slide-toggle
                                          class="pull-right"
                                          [checked]="incomingReference2.getAttributes().hasOwnProperty('hideShopkeeperContact') && incomingReference2.getAttributes().hideShopkeeperContact === 1"
                                          (change)="($event.checked ? incomingReference2.getAttributes().hideShopkeeperContact = 1 : incomingReference2.getAttributes().hideShopkeeperContact = 0)">
                                  </mat-slide-toggle>
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Opened at *</mat-label>
                                <input matInput type="text"  [(ngModel)]="incomingReference2.attributes.opened_at">
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width">
                                <mat-label>Closed at *</mat-label>
                                <input matInput type="text"  [(ngModel)]="incomingReference2.attributes.closed_at">
                              </mat-form-field>
                            </span>
                    </mat-list-item>
                    <mat-list-item>
                      <span class="" mat-line>
                           <mat-form-field  class="example-full-width">
                                <mat-label>Address *</mat-label>
                                <input type="text"
                                       matInput
                                       formControlName="location"
                                       placeholder=""
                                       [value]=addressValue
                                       [address]="addressValue"
                                       (input)="onChangeAddressText($event, incomingReference2.attributes,'address')"
                                       (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, incomingReference2.attributes,'address')"
                                       matGoogleMapsAutocomplete />
                              </mat-form-field>
                      </span>
                    </mat-list-item>
                    <mat-list-item>
                      <span class="" mat-line>
                           <mat-form-field  class="example-full-width">
                                <mat-label>Landmark</mat-label>
                                <input type="text"
                                       matInput
                                       formControlName="landmark"
                                       placeholder=""
                                       [value]=addressValue
                                       [address]="addressValue"
                                       (input)="onChangeAddressText($event, incomingReference2.attributes,'landmark')"
                                       (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, incomingReference2.attributes,'landmark')"
                                       matGoogleMapsAutocomplete />
                              </mat-form-field>
                      </span>
                    </mat-list-item>
                    <mat-list-item>
                      <span class="" mat-line>
                           <mat-form-field  class="example-full-width">
                                <mat-label>Description</mat-label>
                                <textarea matInput placeholder=""
                                          [(ngModel)]="incomingReference2.attributes.description"
                                          #message rows="1" style="height: 50px"></textarea>
                              </mat-form-field>
                      </span>
                    </mat-list-item>
                </mat-list>
                <div>
                    <mat-chip-list
                            #chipList aria-label="selection">
                        <mat-chip
                                *ngFor="let option of options"
                                [selectable]="selectable"
                                [removable]="removable"
                                (click)="handleOption(option)"
                                (removed)="remove(option)">
                            {{option.label}}
                            <mat-icon matChipRemove
                                      *ngIf="removable">
                                cancel
                            </mat-icon>
                        </mat-chip>
                        <input
                                placeholder=""
                                #fruitInput
                                [formControl]="optionCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete"
                                      (optionSelected)="selected($event)">
                        <mat-option *ngFor="let option of filteredOptions | async"
                                    [value]="option">
                            {{option.label}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
