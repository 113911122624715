import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../app.service";
import {FormService} from "../../../forms/form.service";
import {Parameters} from "../../../parameters";
import {DashboardService} from "../../../dashboard/dashboard.service";
import {CriteriasService} from "../../../criterias/criterias.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-responses-list-partial',
  templateUrl: './responses-list-partial.component.html',
  styleUrls: ['./responses-list-partial.component.scss']
})
export class ResponsesListPartialComponent implements OnInit {
  @Input() responses: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingForm: any;
  incomingFormSettings: any;
  incomingResponseSelected: any;
  constructor(private dashboardService: DashboardService,
              private criteriaService: CriteriasService,
              private broadcastChannel: BroadcastChannelService,
              private formService: FormService,
              public service: AppService) {
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingResponseSelected = new Parameters();
    this.contextMenu = MatMenuTrigger;
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingResponseSelected.setAttributes({});
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


}
