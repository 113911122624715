import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Parameters} from '../../../parameters';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {AppService} from "../../../app.service";
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../../form.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";

@Component({
  selector: 'app-sheet-preview-controls',
  templateUrl: './sheet-preview-controls.component.html',
  styleUrls: ['./sheet-preview-controls.component.scss']
})
export class SheetPreviewControlsComponent implements OnInit {
  @ViewChild('auto') matAutocomplete: any;
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  questionCtrl = new FormControl();
  option: any;
  options: any = [];
  allOptions: any;
  label: any;
  controls: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public formMetthods: FormService,
              public broadcastChannel: BroadcastChannelService) {
  }

  ngOnInit(): void {
    this.question.data = this.service.isNull(this.question.data) === true ? {} : this.question.data;
  }

  onChangeValue(event: any, option: any, column: any){
    const item = {};
    const carr  = {};
    carr[column] = event.target.value;
    item[option.label] = carr;
    this.question.data = Object.assign(this.question.data, item);
  }

  handleOption(option: any){
    this.label = prompt('', option.label);
    if (this.label !== null) option = Object.assign(option, {label: this.label});
  }

  add(event: any): void {
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    if (value) {
      this.option  = new Parameters();
      this.option.setAttributes({});
      this.option.setLabel(value);
      this.option.setSource({});
      this.option.setUnknown('cntrl_num', this.service.random());
      this.options.push(JSON.parse(JSON.stringify(this.option.attributes)));
    }

    // Clear the input value
    // event.chipInput!.clear();
    this.questionCtrl.setValue(null);
  }

  remove(option: any): void {
    const index = this.options.indexOf(option);
    if (index === -1){} else {
      this.options.splice(index, 1);
      if(option.hasOwnProperty('id')) {
        this.deletedOptions.push(option);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.questionCtrl.setValue(null);
  }

  private _filter(value: any): any {
    const filterValue = value.toLowerCase();
    return this.allOptions.filter(fruit => fruit.label.toLowerCase().includes(filterValue));
  }

}
