<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Email</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Photo</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Team</th>
<!--<th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Project</th>-->
<!--<th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Account</th>-->
<!--<th class="mdl-data-table__cell&#45;&#45;non-numeric headline2">Client</th>-->
    <th class="mdl-data-table__cell--non-numeric headline2" *ngIf="window.location.href.includes('/forms')">
      <center>
        <mat-checkbox
                #checked
                class="">
        </mat-checkbox>
      </center>
    </th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="mdl-data-table__cell--non-numeric headline2 text-right">Action</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ambassador of (flag.includes('all') ? (ambassadors.data | jsonFilter:searchCntrl) :
        (this.service.app.data.ambassadors.trashed.data | jsonFilter:searchCntrl))"
        (contextmenu)="incomingSelectedAmbassador.setAttributes(ambassador);onContextMenu($event, ambassador)"
        (mousedown)="incomingSelectedAmbassador.setAttributes(ambassador)">
      <ng-container *ngIf="ambassador.hasOwnProperty('team_id')">
        <td class="mdl-data-table__cell--non-numeric headline2">{{ambassador.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{ambassador.phone}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{ambassador.email}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2"><center><img class="img-thumbnaill avatar" src="{{(ambassador.avatar) ?  ambassador.avatar : 'assets/images/avatar.jpg'}}" /></center></td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{ambassador.team.name}}</td>
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{ambassador.campaign.name}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{ambassador.campany.name}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline2">{{ambassador.client.name}}</td>-->
        <td class="mdl-data-table__cell--non-numeric headline2" *ngIf="window.location.href.includes('/forms')">
          <center>
            <mat-checkbox
                    #checked
                    [checked]="isAssigned(checked, ambassador)"
                    (change)="assignUser(ambassador)"
                    class="">
            </mat-checkbox>
          </center>
        </td>
        <td class="mdl-data-table__cell--non-numeric headline2" [innerHTML]="ambassador.description"></td>
        <td class="mdl-data-table__cell--non-numeric headline2">
          <div class="dropdown pull-right">
            <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
<!--          <li mat-menu-item *ngIf="flag === 'all'"     (click)="getListingReport()">Final Report</li>-->
              <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedAmbassador)">View</li>
              <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedAmbassador)">Delete</li>
              <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedAmbassador)">Restore</li>
            </ul>
          </div>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="inspector">
<!--<button mat-menu-item *ngIf="flag === 'all'"     (click)="routes(selectedAmbassador)">Routes</button>-->
<!--<button mat-menu-item *ngIf="flag === 'all'"     (click)="getListingReport()">Final Report</button>-->
    <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedAmbassador)">View</button>
    <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedAmbassador)">Delete</button>
    <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedAmbassador)">Restore</button>
  </ng-template>
</mat-menu>