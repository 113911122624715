import {Component, Input, OnInit} from '@angular/core';
import {ChartService} from "../../../chart.service";

@Component({
  selector: 'app-echart-minipie',
  templateUrl: './echart-minipie.component.html',
  styleUrls: ['./echart-minipie.component.scss']
})
export class EchartMinipieComponent implements OnInit {
  @Input() element;
  theme: any;
  opts: any;
  constructor(private chartService: ChartService ) { }

  ngOnInit(): void {
    this.theme = this.chartService.theme();
    this.opts = this.options();
  }

  options(){
    const dataStyle: any = {
      normal: {
        label: {
          show: false
        },
        labelLine: {
          show: false
        }
      }
    };

    const placeHolderStyle: any = {
      normal: {
        color: 'rgba(0,0,0,0)',
        label: {
          show: false
        },
        labelLine: {
          show: false
        }
      },
      emphasis: {
        color: 'rgba(0,0,0,0)'
      }
    };

    const opt: any = {
      title: {
        text: 'Chart #2',
        subtext: 'From ExcelHome',
        sublink: 'http://e.weibo.com/1341556070/AhQXtjbqh',
        x: 'center',
        y: 'center',
        itemGap: 20,
        textStyle: {
          color: 'rgba(30,144,255,0.8)',
          fontFamily: '微软雅黑',
          fontSize: 35,
          fontWeight: 'bolder'
        }
      },
      tooltip: {
        show: true,
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        x: 170,
        y: 45,
        itemGap: 12,
        data: ['68%Something #1', '29%Something #2', '3%Something #3'],
      },
      toolbox: {
        show: true,
        feature: {
          mark: {
            show: true
          },
          dataView: {
            show: true,
            title: "Text View",
            lang: [
              "Text View",
              "Close",
              "Refresh",
            ],
            readOnly: false
          },
          restore: {
            show: true,
            title: "Restore"
          },
          saveAsImage: {
            show: true,
            title: "Save Image"
          }
        }
      },
      series: [{
        name: '1',
        type: 'pie',
        clockWise: false,
        radius: [105, 130],
        itemStyle: dataStyle,
        data: [{
          value: 68,
          name: '68%Something #1'
        }, {
          value: 32,
          name: 'invisible',
          itemStyle: placeHolderStyle
        }]
      }, {
        name: '2',
        type: 'pie',
        clockWise: false,
        radius: [80, 105],
        itemStyle: dataStyle,
        data: [{
          value: 29,
          name: '29%Something #2'
        }, {
          value: 71,
          name: 'invisible',
          itemStyle: placeHolderStyle
        }]
      }, {
        name: '3',
        type: 'pie',
        clockWise: false,
        radius: [25, 80],
        itemStyle: dataStyle,
        data: [{
          value: 3,
          name: '3%Something #3'
        }, {
          value: 97,
          name: 'invisible',
          itemStyle: placeHolderStyle
        }]
      }]
    };
    return opt;
  }

}
