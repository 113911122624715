import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import {jsPDF} from 'jspdf';
import {AppService} from '../../../app.service';
import {DashboardService} from '../../../dashboard/dashboard.service';
import {Parameters} from '../../../parameters';
import {CampaignService} from '../../../campaigns/campaign.service';
import {ClientService} from '../../../clients/client.service';
import {FormService} from '../../../forms/form.service';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-engagement-outlet-reports',
  templateUrl: './engagement-outlet-reports.component.html',
  styleUrls: ['./engagement-outlet-reports.component.scss']
})
export class EngagementOutletReportsComponent implements OnInit {

  incomingReport: any;
  incomingCampaign: any;
  incomingClient: any;
  incomingForm: any;
  formId: any;
  incomingFormSettings: any;
  constructor(public  service: AppService,
              private formService: FormService,
              public activateRouter: ActivatedRoute,
              private campaignService: CampaignService,
              private clientService: ClientService,
              public  dashboardService: DashboardService) {
    this.incomingReport = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingForm  = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.formId = this.activateRouter.snapshot.params.id;
    this.incomingReport.setAttributes(this.dashboardService.incomingReport.getAttributes());
    this.incomingForm.setAttributes(this.formService.getFormById(formService.formSelectedId));
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingFormSettings.setOutlet(this.incomingFormSettings.attributes.hasOwnProperty('outlet') ?
        this.incomingFormSettings.getOutlet() : '');
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
  }

  ngOnInit(): void {
    setTimeout(() => {
      const data: any = document.getElementById('pdfTable');
   // html2pdf(data).then(canvas => {
      //   const contentDataURL = canvas.toDataURL('image/png');
      //   const pdf: any = new jsPDF('l', 'cm', 'a4');
      //   pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      //   pdf.save('Filename.pdf');

      html2pdf()
          .from(data)
          .set(this.getOptions())
          .toPdf()
          .save('filename.pdf').then(() => {
      });

    }, this.service.timeout());
  }

  getOptions(){
    const opt: any = {
      margin: 1,
      filename: 'filename.pdf',
      image: {type: 'jpeg', quality: 10},
      html2canvas: {scale: 7, logging: true},
      // jsPDF: {unit: 'in', format: 'a4', orientation: 'l'}
    };
  }

}
