import { Component, OnInit } from '@angular/core';
import {FormService} from "../../../form.service";
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-dialog-responses-form',
  templateUrl: './dialog-responses-form.component.html',
  styleUrls: ['./dialog-responses-form.component.scss']
})
export class DialogResponsesFormComponent implements OnInit {

  constructor(private formService: FormService,
              public service: AppService) {
  }

  ngOnInit(): void {

  }

}
