import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successfull-suggestions-forms',
  templateUrl: './successfull-suggestions-forms.component.html',
  styleUrls: ['./successfull-suggestions-forms.component.scss']
})
export class SuccessfullSuggestionsFormsComponent implements OnInit {

  open: any;
  constructor() { }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ebebeb';
    this.open = ('Back Home').toUpperCase();
  }

  recall(){
    window.history.back();
  }

}
