<div class="row plist">
    <div class="" style="width: 100%; z-index: 99999999">
        <table [hidden]="false"
               [ngStyle]="{'background-color':'transparent','z-index': '999999999','width':'100%'}">
            <tr [ngStyle]="{'width':'100%'}">
                <td valign="top" [ngStyle]="{'width':'100%'}">
                    <div [ngStyle]="{'padding':'0px','margin-top':'0px','border-radius': '20px','background':'#fff'}"
                         class="pull-right col-lg-4 col-md-4 col-sm-4 col-xs-6 mdl-shadow--2dp">
                        <table [ngStyle]="{'width':'100%','margin':'0px'}">
                            <td valign="middle" [ngStyle]="{'width':'100%'}">
                                <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"
                                        autocomplete="off"
                                        ngClass="sawasdee18 searchbar"
                                        #searchId
                                        [(ngModel)]="service.searchText"
                                        (keyup.enter)="search(searchId.value);"
                                        placeholder="">
                            </td>
                            <!--                    <td valign="middle">-->
                            <!--                        <mat-icon matSuffix >settings</mat-icon>-->
                            <!--                    </td>-->
                            <td>
                                <mat-spinner
                                        *ngIf="this.service.matSpinner"
                                        class=""
                                        color='warn'
                                        [diameter]="20">
                                </mat-spinner>
                            </td>
                            <!--                            <td valign="middle" class="cursor">-->
                            <!--                                <mat-icon matSuffix>reorder</mat-icon>-->
                            <!--                            </td>-->
                            <td valign="middle" class="cursor">
                                <mat-icon matSuffix (click)="add()">add</mat-icon>
                            </td>
                            <td >
                                <img class="img-circle mdl-shadow--2dp"
                                     [src]="service.logo"
                                     [ngStyle]="{'width':'30px','height':'30px','margin-top': '0px'}">
                            </td>
                            <!--                            <td valign="middle">-->
                            <!--                                <mat-icon matSuffix >search</mat-icon>-->
                            <!--                            </td>-->
                        </table>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <center>
        <mat-tab-group mat-align-tabs="start">
            <mat-tab>
                <ng-template mat-tab-label>
                    <center>
                        <span class="sawasdeeWithScale">All</span>
                    </center>
                </ng-template>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <table class="table headline2x table-striped sawasdee20 sawasdeeWithScale"
                           [ngStyle]="{'width':'100%'}">
                        <ng-container>
                            <thead>
                                <th class="mdl-typography--text-lowercase">Business name</th>
                                <th class="mdl-typography--text-lowercase">Phone</th>
                                <th class="mdl-typography--text-lowercase">Email</th>
                                <th class="mdl-typography--text-lowercase">Address</th>
                            </thead>
                        </ng-container>
                        <tbody>
                        <ng-container *ngFor="let distributor of this.service.app.data.distributors.data">
                            <tr [ngStyle]="{'background-color': (incomingForm.hasAttribute(strings.getId()) && this.service.parseFloat(incomingForm.getId()) === 1 ? '#5A738E' : ''), 'color': (incomingForm.hasAttribute(strings.getId()) && this.service.parseFloat(incomingForm.getId()) === 1 ? '#000' : '')}">
                                <td class="mdl-data-table__cell--non-numeric">{{distributor.name}}{{distributor.id}}</td>
                                <td class="mdl-data-table__cell--non-numeric">{{distributor.phone}}</td>
                                <td class="mdl-data-table__cell--non-numeric">{{distributor.email}}</td>
                                <td class="mdl-data-table__cell--non-numeric">{{distributor.address}}</td>
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order[strings.getCreatedAt()]}} - ({{order[strings.getTime()]}})</td>-->
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order[strings.getPaymentMethod()]}}</td>-->
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order[strings.getOrderStatus()]}}</td>-->
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order.outlet[strings.getBusiness()]}}</td>-->
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order.outlet[strings.getPhone()]}}</td>-->
<!--                                <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{order.outlet[strings.getAddress()]}}</td>-->
                            </tr>
                            <!--                 </ng-container>-->
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <center>
                        <span class="sawasdeeWithScale">GIS</span>
                    </center>
                </ng-template>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    xc,;xl,cxc
                </div>
            </mat-tab>
        </mat-tab-group>
        <div [ngStyle]="{'margin-top':'5px'}"
             ngClass="cursor">
            <center>
                <i class="material-icons"
                   (click)="pullFormAndlimit(10)">keyboard_arrow_down</i>
            </center>
        </div>
    </center>
</div>
