import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {CampaignService} from '../campaigns/campaign.service';
import {DialogAmbassadorsComponent} from './dialog-ambassadors/dialog-ambassadors.component';
import {AmbassadorsService} from './ambassadors.service';
import {FormService} from "../forms/form.service";
import {Parameters} from "../parameters";

@Component({
  selector: 'app-ambassadors',
  templateUrl: './ambassadors.component.html',
  styleUrls: ['./ambassadors.component.scss']
})
export class AmbassadorsComponent implements OnInit {

  app: any;
  incomingForm: any;
  incomingSettings: any;
  constructor(public service: AppService,
              private dialog: MatDialog,
              private formService: FormService,
              private ambassadorsService: AmbassadorsService) {
    this.incomingForm = new Parameters();
    this.incomingSettings = new Parameters();
    this.app = service.app;
    this.app.form.subtitle = 'the dead body of an animal ready for butchery, after removal of skin, visceral organs, head, feet etc..';
    this.app.form.module   = 'ambassadors';
  }

  ngOnInit(): void {
    this.ambassadorsService.setData(this.app.data.ambassadors);
    this.incomingForm.setAttributes(window.location.href.toString().includes('forms') ? this.formService.getFormById(this.formService.formSelectedId) : {});
    this.incomingSettings.setAttributes(Object.keys(this.incomingForm.getAttributes()).length === 0 ? {} : this.incomingForm.getSettings());
    this.formService.formSelectedId = Object.keys(this.incomingForm.getAttributes()).length === 0 ? 0 : parseFloat(this.incomingForm.getId());
    this.app.form.modules  = [{path: '/app/ambassadors',
      title: Object.keys(this.incomingForm.getAttributes()).length === 0 ? 'Respodent list' :
          this.incomingForm.getRespondentAlias().toString() + ' list'}];
    this.service.modules = this.app.form.modules;
  }

  openDialog(){
    this.dialog.open(DialogAmbassadorsComponent, {data  : {}, width : '55%', height: '100%', });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Register').toString().toUpperCase() + ' ,respodents';
      this.service.header = this.incomingForm.getRespondentAlias().toString().toUpperCase() + ' (' + this.app.data.ambassadors.data.length + ') - registration';
    });
  }
}
