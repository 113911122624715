import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-waypoints-responses',
  templateUrl: './waypoints-responses.component.html',
  styleUrls: ['./waypoints-responses.component.scss']
})
export class WaypointsResponsesComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  constructor() { }

  ngOnInit(): void {
  }

}
