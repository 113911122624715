import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {CampaignService} from "../campaign.service";
import {Router} from "@angular/router";
import {DashboardService} from "../../dashboard/dashboard.service";
import {FormService} from "../../forms/form.service";
import {Parameters} from "../../parameters";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-search-campaigns',
  templateUrl: './search-campaigns.component.html',
  styleUrls: ['./search-campaigns.component.scss']
})
export class SearchCampaignsComponent implements OnInit, OnDestroy {
  campaigns: any;
  campaignControl = new FormControl();
  filteredCampaignsOptions: any;
  campaign: any;
  incomingCampaignSelected: any;
  form: any;
  subscription: any;
  incomingForm: any = new Parameters();
  constructor(public  service: AppService,
              public  campaignService: CampaignService,
              private dashboardservice: DashboardService,
              private notifier: NotifierService,
              private formService: FormService,
              private router: Router,
              private broadcastChannel: BroadcastChannelService) {
    this.campaigns = this.service.app.data.campaigns;
    this.incomingCampaignSelected = new Parameters();
    this.campaignService.categoryId = 5;
    this.campaignService.flag = 'Projects';
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campaign') === true) {
              this.campaignControl.setValue(item.campaign);
            }
          }

        });
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
 // document.getElementsByTagName( 'body')[0].style.backgroundColor = '#263238';
    setTimeout(() => {
      this.filteredCampaignsOptions = this.campaignControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterCampaign(name) : this.campaignService.getCampaignsByCategoryId(this.campaignService.categoryId).slice())
      );
    });
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterCampaign(name: string) {
    const filterValue = name.toLowerCase();
    return this.campaignService.getCampaignsByCategoryId(this.campaignService.categoryId).filter((campaign: any) => JSON.stringify(campaign).toString().toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addCampaign(){
    this.router.navigateByUrl('/app/campaigns');
    setTimeout(() => {
      this.broadcastChannel.emitNavChangeEvent('register');
    });
  }


  onCampaignSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.incomingCampaignSelected.setAttributes(event.option.value);
    this.formService.campaignId = this.incomingCampaignSelected.attributes.id;
    this.campaignService.campaignSelectedId = this.incomingCampaignSelected.attributes.id;
    if(this.incomingCampaignSelected.attributes.forms.data.length === 0){
      this.router.navigateByUrl('/app/forms');
    } else if(this.incomingCampaignSelected.attributes.forms.data.length === 1){
      this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingCampaignSelected.attributes.forms.data[0].id) + '/dashboard');
    }else {
      this.router.navigateByUrl('/forms/search');
    }
  }
}
