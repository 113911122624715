import { Component, OnInit } from '@angular/core';
import {CampaignService} from "../campaign.service";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-open-campaigns',
  templateUrl: './open-campaigns.component.html',
  styleUrls: ['./open-campaigns.component.scss']
})
export class OpenCampaignsComponent implements OnInit {
  subscription: any;
  constructor(private broadcastChannel: BroadcastChannelService,
              private router: Router,
              private campaignService: CampaignService) {
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campaign') === true) {
              this.campaignService.campaignSelectedId = item.campaign.id;
              this.router.navigateByUrl('/app/forms');
            }
          }
        });
  }

  openCampaign(){
    this.campaignService.openDialog({});
  }

}
