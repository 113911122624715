<!--&lt;!&ndash;MAIN START&ndash;&gt;-->
<!--<div id="main">-->
<!--    &lt;!&ndash;SIGNUP SECTION START&ndash;&gt;-->
<!--    <section class="signup-section">-->
<!--        <div class="container">-->
<!--            <div class="holder">-->
<!--                <div class="thumb"><img src="../../assets/images/logo-3.png" alt="img"></div>-->
<!--                <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)" (keydown.enter)="$event.preventDefault()">-->
<!--                    <div class="input-box"> <i class="fa fa-phone"></i>-->
<!--                        <input type="text" name="phone" formControlName="phone" placeholder="Phone"  />-->
<!--                    </div>-->
<!--                    <div class="input-box"> <i class="fa fa-unlock"></i>-->
<!--                        <input type="password" name="password" formControlName="password" placeholder="Password" />-->
<!--                    </div>-->
<!--                    <div class="check-box">-->
<!--                        <div class="checkboxArea" id="myCheckbox0"></div>-->
<!--                        <input id="id3" type="checkbox" class="outtaHere">-->
<!--                        <strong>Remember Me</strong>-->
<!--                    </div>-->
<!--                    <input type="submit" value="Login"  [disabled] = "!formValues.valid" stop-propagation>-->
<!--                    <a class="login">Forgot your Password </a>-->
<!--                </form>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash;SIGNUP SECTION END&ndash;&gt;-->
<!--</div>-->

<div>
    <a class="hiddenanchor" id="signup"></a>
    <a class="hiddenanchor" id="signin"></a>
    <div class="login_wrapper">
        <div class="animate form login_form">
            <section class="login_content">
                <form autocomplete="on" [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
                    <img  class="img-circle" src="assets/images/coat.png" [ngStyle]="{'width':'60px','height':'60px'}" />
                    <h1>&nbsp;</h1>
                    <div [ngStyle]="{'margin-top':'10px'}">
                        <input type="text"
                               class="form-control"
                               placeholder="Email"
                               required=""
                               autocomplete="on"
                               name="email"
                               formControlName="email"
                        />
                    </div>
                    <div>
                        <input type="password"
                               class="form-control"
                               placeholder="Password"
                               name="password"
                               formControlName="password"
                        />
                    </div>
                    <div style="transform:scale(1.16);transform-origin:0;-webkit-transform:scale(1.16);transform:scale(1.16);-webkit-transform-origin:0 0;transform-origin:0 0;">
                      <ngx-recaptcha2 #captchaElem
                                      [siteKey]="siteKey"
                                      (reset)="handleReset()"
                                      (expire)="handleExpire()"
                                      (load)="handleLoad()"
                                      (success)="handleSuccess($event)"
                                      [useGlobalDomain]="false"
                                      formControlName="grecaptcharesponse">
                      </ngx-recaptcha2>
                    </div>
                    <div [ngStyle]="{'margin-top':'40px'}">
                        <input type="submit" class="btn btn-default submit" value="Login" />
                        <a class="reset_pass" href="">Lost your password?</a>
                    </div>
                    <div class="clearfix"></div>
<!--                    <div class="separator">-->
<!--                        <div class="clearfix"></div>-->
<!--                        <br />-->
<!--                        <div>-->
<!--                            <h1>{{app.settings.client.name}}</h1>-->
<!--                            <p>{{app.settings.copyright}}</p>-->
<!--                        </div>-->
<!--                    </div>-->
                </form>
            </section>
        </div>
    </div>
</div>
