import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../../../app.service';
import * as Chart from 'chart.js';
import 'chartjs-plugin-labels';


@Component({
  selector: 'app-piechart-chartjs',
  templateUrl: './piechart-chartjs.component.html',
  styleUrls: ['./piechart-chartjs.component.scss']
})
export class PiechartChartjsComponent implements OnInit, AfterViewInit {


  @Input() element: any;
  @Input() color: any;
  @Input() labels: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() width: any;
  @Input() showLabels: any;

  transparentColor: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
    this.height === undefined ? 350 : this.height;
    this.width === undefined ? 100 : this.width;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const options: any = {
//         response: true,
//         maintainAspectRatio: false,
//         cutoutPercentage: 80,
        tooltips: {
         enabled: false,
         callbacks: {
           label: function(tooltipItem, data){
            return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
           }
          }
        },
        pieceLabel : {
          render   : 'label',
          fontColor: '#000',
          position : 'outside',
          segment  : true
        },
        options: {
          legend: {
            display  : false,
            position : 'right',
            align    : 'start',
            fontColor: '#5f6368',
            font     : 'Google Sans',
          },
          plugins : {
            labels: [
              {
                render    : 'label',
                position  : 'inside',
                fontColor : '#5f6368',
                fontFamily: 'Google Sans',
              },
              {
                render: 'percentage',
              }
            ]
          },
        },
        responsive: true,
        legend   : {
          display: false,
          show   : false
        },
        data : this.getCanvasData(),
        type : this.type ,
      };
      options.options.plugins = this.showLabels ? {
        labels: [
          {
            render: function (args) {
              return args.label + ' \n' + args.percentage + ' %';
           // return args.label + ' ' + args.percentage + ' %' 5f6368;
            },
            display   : true,
            position  : 'inside',
            fontColor : '#fff',
            fontFamily: 'Google Sans',
            segment   : true,
          }
        ]
      } : {};
      const myChart: any = new Chart(this.ctx, options);
    });
  }

  getCanvasData(){
    return {
      datasets: [{
        data: this.data,
        backgroundColor: this.service.pieChartColors(),
        label: 'My dataset' // for legend
      }],
      labels: this.labels.map((label: any) =>{return this.limit === undefined ? label : this.service.limitTo(label, this.limit);})
    };
  }

  ngAfterViewInit(): void {
  }


}
