<div class="row">
  <div class="panel panel-default" [ngStyle]="{'margin-bottom':'40px','overflow':'hidden','width':'100%','max-height':'200px', 'border-left-size':'50px','border-right-color':'red'}">
    <div class="panel-body">
      <div class="row">
          <mat-form-field  class="example-full-width" appearance='fill'>
              <input type="text"
                     placeholder="search for control.."
                     aria-label = "Number"
                     matInput
                     autocomplete="off"
                     [(ngModel)]="search"
              >
          </mat-form-field>
      </div>
      <div>
        <i class="material-icons pull-left" (click)="addQuestion({name:'Text',id:1})" [ngStyle]="{'color':'#888','cursor':'pointer'}" [matTooltip]="'Add Question'">add_circle</i>
          <i class="material-icons pull-left" (click)="setQuestion(control)" [ngStyle]="{'color':'#888','cursor':'pointer'}"  *ngFor="let control of (controls | jsonFilter : search); let controlOf = index" [matTooltip]="control.name" >{{control.icon}}</i>
      </div>
    </div>
  </div>
</div>
