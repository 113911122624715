<a class="pull-left"><h4>{{keys === 0 ? 'Client ' : incomingSelectedClient.getName()}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="text" class="nullInput"/>
<div>
  <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Email *</mat-label>
            <input matInput type="text"  formControlName="email">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Phone *</mat-label>
            <input matInput type="text"  formControlName="phone">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <small>logo</small>
          <div class="upload-box">
              <div class="hold">
                  <a href="">Maximum file size 20 MB <img src="{{logo}}" class="img-circle" width="40" height="40" alt="" *ngIf="logo"></a>
                  <span class="btn-file"> Browse File
                      <input type="file"
                             (change)="handleFileInput($event)"
                      >
                  </span>
              </div>
          </div>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address *</mat-label>
            <input type="text"
                   id="address"
                   matInput
                   formControlName="location"
                   placeholder=""
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onChangeAddressText($event)"
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                   (onLocationSelected)="onLocationSelected($event)"
                   matGoogleMapsAutocomplete />
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
      <span class="" mat-line>
           <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
              <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
      </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Edit'}}"/>
        </div>
      </center>
    </div>
  </div>
</form>
</div>
