import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Parameters} from "../../../parameters";
import {DashboardService} from "../../../dashboard/dashboard.service";
import {AppService} from "../../../app.service";
import {FormService} from "../../../forms/form.service";
import {CriteriasService} from "../../criterias.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import * as $ from "jquery";

@Component({
    selector: 'app-list-criterials',
    templateUrl: './list-criterials.component.html',
    styleUrls: ['./list-criterials.component.scss']
})
export class ListCriterialsComponent implements OnInit, OnChanges, OnDestroy {
    incomingReport: any;
    incomingForm: any;
    incomingFormSettings: any;
    incomingCriteria: any;
    strings: any;
    searchText: any = '';
    subscription: any;
    limit: any = 10;
    constructor(private dashboardService: DashboardService,
                private criteriaService: CriteriasService,
                private broadcastChannel: BroadcastChannelService,
                private formService: FormService,
                public service: AppService) {
        this.incomingReport = new Parameters();
        this.incomingForm = new Parameters();
        this.strings = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.incomingCriteria = new Parameters();
        this.incomingReport.setAttributes(this.dashboardService.incomingReport.getAttributes());
        this.incomingForm.setAttributes(this.formService.getSelected());
        this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingCriteria.setAttributes({});
        this.strings.setAttributes(this.service.app.strings.params);
        this.service.searchText = '';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
        this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
            .subscribe((output: any) => {
                if (output instanceof Object){
                    if (output.hasOwnProperty(this.strings.getCriteria()) ) {
                        this.incomingCriteria.setAttributes(output[this.strings.getCriteria()]);
                        this.onStart(this.limit);
                    }else if(output.hasOwnProperty(this.strings.getSearchText())){
                        this.searchText = output[this.strings.getSearchText()];
                        if(output.hasOwnProperty(this.strings.getStatus()))
                            this.search();
                    }else if(output.hasOwnProperty(this.strings.getAction())){
                        if(output[this.strings.getAction()] === this.strings.getExport()){
                            this.export();
                        }
                    }
                }
            });
    }

    export(){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
        outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
        outgoingRequest.setSection(this.strings.getCriterias());
        outgoingRequest.setAction(this.strings.getExport());
        outgoingRequest.setFlag(this.strings.getResponses());
        delete outgoingRequest.attributes.data;
        let form: any;
        form = document.createElement('form');
        form.action  = this.service.app.settings.routes.server + this.service.app.routes.reports.fetch;
        form.method  = 'get';
        form.enctype = 'multipart/form-data';
        let hiddenInput: any;
        hiddenInput  = document.createElement('input');
        hiddenInput.name  = 'token';
        hiddenInput.type  = 'hidden';
        hiddenInput.value = this.service.getToken();
        form.appendChild(hiddenInput);
        for (const key in outgoingRequest.attributes) {
            hiddenInput       = document.createElement('input');
            hiddenInput.name  = key;
            hiddenInput.type  = 'hidden';
            hiddenInput.value = outgoingRequest.getAttributes()[key];
            form.appendChild(hiddenInput);
        }
        $('#form').append(form);
        form.submit();
        // + '/reports' this.service.app.routes.reports.fetch
    }

    search(){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
        outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
        outgoingRequest.setSection(this.strings.getCriterias());
        outgoingRequest.setFlag(this.strings.getResponses());
        outgoingRequest.setSearchText(this.searchText);
        if(outgoingRequest.hasAttribute(this.strings.getData()))
            delete outgoingRequest.attributes.data;
        this.service.httpService('POST', this.service.app.routes.reports.fetch,
            outgoingRequest.getAttributes(),
            {notify: false},
            (output: any) => {
                let incomingOutputResult: any = new Parameters();
                incomingOutputResult.setAttributes(output);
                if(parseFloat(incomingOutputResult.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
                if(incomingOutputResult.getSection()  !== this.strings.getCriterias()) return;
                if(incomingOutputResult.getDuration() !== outgoingRequest.getDuration()) return;
                if(incomingOutputResult.hasAttribute(this.strings.getCriterias())){
                    let outgoingCriteria: any = new Parameters();
                    outgoingCriteria.setAttributes(Object.assign({}, incomingOutputResult.getCriterias().filter((item: any) => {
                        return item[this.strings.getCntrlNum()] === incomingOutputResult.getCntrlNum();})[0]));
                    outgoingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].map((response: any) => {
                        let incomingResponse: any = new Parameters();
                        incomingResponse.setAttributes(response);
                        let position: any = this.service.findIntegerIndex(this.incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()],
                            this.strings.getId(), incomingResponse.getId());
                        if(position === -1)
                            this.incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].push(incomingResponse.getAttributes());
                    });
                }
            },(error: any) => {
                setTimeout(() => {});
            });
    }

    onStart(limit: any){
        this.service.matSpinner = true;
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
        outgoingRequest.setCntrlNum(this.incomingCriteria.getCntrlNum());
        outgoingRequest.setSection(this.strings.getCriterias());
        outgoingRequest.setFlag(this.strings.getResponses());
        delete outgoingRequest.attributes.data;
        if(this.incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].length !== 0)
            outgoingRequest.setAscendinglastAt(this.incomingCriteria.getUnknown(
                outgoingRequest.getDuration())[this.strings.getData()]
                [this.incomingCriteria.getUnknown(outgoingRequest.getDuration())
                [this.strings.getData()].length - 1][this.strings.getId()]);
        this.service.httpService('POST', this.service.app.routes.reports.fetch,
            outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false},
            (output: any) => {
                let incomingOutputResult: any = new Parameters();
                incomingOutputResult.setAttributes(output);
                if(parseFloat(incomingOutputResult.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
                if(incomingOutputResult.getSection()  !== this.strings.getCriterias()) return;
                if(incomingOutputResult.getDuration() !== outgoingRequest.getDuration()) return;
                if(incomingOutputResult.hasAttribute(this.strings.getCriterias())){
                    let outgoingCriteria: any = new Parameters();
                    outgoingCriteria.setAttributes(Object.assign({}, incomingOutputResult.getCriterias().filter((item: any) => {
                        return parseFloat(item[this.strings.getCntrlNum()]) === parseFloat(incomingOutputResult.getCntrlNum());})[0]));
                    outgoingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].map((response: any) => {
                        let incomingResponse: any = new Parameters();
                        incomingResponse.setAttributes(response);
                        let position: any = this.service.findIntegerIndex(this.incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()],
                            this.strings.getId(), incomingResponse.getId());
                        if(position === -1)
                            this.incomingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].push(incomingResponse.getAttributes());
                    });if(outgoingCriteria.getUnknown(outgoingRequest.getDuration())[this.strings.getData()].length !== 0 && limit > 0) {
                        limit-=1;
                        this.onStart(limit);
                    }else this.service.matSpinner = false;
                }
            },(error: any) => {
                setTimeout(() => {this.onStart(limit);});
            });
    }

}
