import { Injectable } from '@angular/core';
import {InterfaceDistricts} from './interface-districts';

@Injectable({
  providedIn: 'root'
})
export class ImplDistrictsService implements InterfaceDistricts{
  data: any = [];

  selected: any = {};

  getData(): void {
    return this.data;
  }

  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  setSelected(selected: any): void {
    this.selected = selected;
  }
  constructor() { }
}
