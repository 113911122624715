import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {DialogEmployeesComponent} from "./dialog-employees/dialog-employees.component";
import {MatDialog} from "@angular/material/dialog";
import {AppService} from "../app.service";

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  report: any;

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor(private dialog: MatDialog, private service: AppService) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }

  getEmployeesByCampanies(campanies: any){

    return {data: campanies.data.map((campaign: any) => { return campaign.employees.data; }).flat(1), trashed: {data : campanies.data.map((campaign: any) => { return campaign.employees.trashed.data; }).flat(1)}};

  }

  openDialog(){
    this.dialog.open(DialogEmployeesComponent, {data  : {}, width : '55%', height: '100%', });
  }
  hasEmployeeByCompany(company: any){
    let incomingCompany: any = new Parameters();
    incomingCompany.setAttributes(company);
    incomingCompany.getEmployees().data.filter((employee: any) => {

    });
  }

  search(searchText: any, call: any){
    // ignoreLoadingBar : 'true', notify: 'false'
    this.service.httpService('POST', this.service.app.routes.employees.search,
        {searchText: searchText}, {},
        (employee: any) => {
          setTimeout((result: any) =>{
            employee.map((item: any) => {
              result = [];
              result = this.service.app.data.employees.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });if(result.length === 0)
                this.service.app.data.employees.data.push(item);
            });
          });
        }, (error: any) => {
        });
  }



}
