import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {InventoriesService} from '../inventories.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {FormService} from '../../forms/form.service';

@Component({
  selector: 'app-table-inventories',
  templateUrl: './table-inventories.component.html',
  styleUrls: ['./table-inventories.component.scss']
})
export class TableInventoriesComponent implements OnInit, OnDestroy {
  @Input() flag: any;
  incomingResults: any;
  incomingResult: any;
  searchCntrl: any;
  incomingQuestionSelected: any;
  incomingForm: any;
  constructor(public inventoryService: InventoriesService,
   public  service: AppService,
   private formService: FormService,
   private activatedRoute: ActivatedRoute) {
    this.incomingResults = new Parameters();
    this.incomingResult  = new Parameters();
    this.incomingQuestionSelected  = new Parameters();
    this.incomingForm  = new Parameters();
    this.incomingQuestionSelected.setAttributes({});
    this.incomingForm.setAttributes({});
  }

  ngOnInit(): void {
   if(window.location.href.toString().includes('/forms')){
    this.incomingForm.setAttributes(this.formService.getFormById(this.service.decrypt(this.activatedRoute.snapshot.params.formId)));
    if(window.location.href.toString().includes('/questions')){
     this.inventoryService.inventories.data = [];
     this.incomingQuestionSelected.setAttributes(this.getQuestionById(this.service.decrypt(this.activatedRoute.snapshot.params.questionId)));
    }
   }
   if(this.inventoryService.inventories.data.length === 0) this.requestInventories();
  }
  getQuestionById(id: any){return this.incomingForm.getControls().filter((question: any) => {return parseFloat(question.id) === parseFloat(id);})[0];}
  requestInventories(){
      if(this.inventoryService.isDialogOpen === false) {
        this.inventoryService.isDialogOpen = true;
        setTimeout((outgoingRequest: any = {}) => {
              outgoingRequest = Object.assign(outgoingRequest, this.inventoryService.incomingReport.attributes);
              delete outgoingRequest.data;
              if(window.location.href.toString().includes('questions'))
               outgoingRequest.questionCntrlNum = this.incomingQuestionSelected.getCntrlNum();
               if(window.location.href.toString().includes('labels'))
                 outgoingRequest.optionCntrlNum = this.service.decrypt(this.activatedRoute.snapshot.params.label);
              this.service.httpService('post', this.service.app.routes.inventories.get, outgoingRequest, {}, (responses: any) => {
                if(this.inventoryService.incomingReport.attributes.hasOwnProperty('descending_last_at')){
                 responses.map((response: any) => {
                  this.incomingResult.setAttributes(response);
                  this.inventoryService.inventories.data.push(this.incomingResult.getAttributes());
                  delete this.inventoryService.incomingReport.attributes.descending_last_at;
                  delete outgoingRequest.descending_last_at;
                 });
                }else this.inventoryService.inventories.data = responses;
                this.inventoryService.isDialogOpen = false;
              }, (error: any) => {this.inventoryService.isDialogOpen = false;
              });
            });
      }
 }

 ngOnDestroy(): void {
//      this.subscription.unsubscribe();
     if(this.inventoryService.incomingReport.attributes.hasOwnProperty('descending_last_at'))
          delete this.inventoryService.incomingReport.attributes.descending_last_at;
 }

}
