<h1 mat-dialog-title *ngIf="!params.hasOwnProperty(strings.getText())"><center><span class="sawasdee20">{{title}}</span></center></h1>
<div mat-dialog-content [ngStyle]="{'margin-bottom': !params.hasOwnProperty(strings.getText()) ? '100px' : '0px'}">
    <center>
        <p class="sawasdeeWithScale sawasdee20">
            {{params.hasOwnProperty(strings.getText()) ? params[strings.getText()] : 'Do you real want to'+params.action+' this record ?'}}
        </p>
        <p class="sawasdeeWithScale sawasdee18"
           [ngStyle]="{'margin':'0px 40px 0px 20px'}"
           *ngIf="params.hasOwnProperty(this.strings.getCompany()) &&
           !this.service.isNull(params[this.strings.getCompany()])">
            {{params[this.strings.getCompany()]}}
        </p>
    </center>
</div>
<div *ngIf="!params.hasOwnProperty(strings.getText())">
    <center>
        <a class="btn btn-success btn-md"   (click)="onAcceptable()">Yes</a>
        <a class="btn btn-success btn-md"   [mat-dialog-close]="">No</a>
    </center>
</div>
