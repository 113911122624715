<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultl" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label></mat-label>
                        <input matInput
                               class="sawasdee20"
                               #questionElement
                               #matAutocompleteTrigger="matAutocompleteTrigger"
                               type="text"
                               (input)="onChangeOptionalText($event)"
                               [ngStyle]="{'text-align': 'left', 'color': this.formService.color}"
                               [formControl] = "optionalControl"
                               [matAutocomplete] = "optional">
                        <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</i>
                        <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner>
                        <mat-autocomplete #optional = "matAutocomplete"
                                          (optionSelected)="onOptionalSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option class="sawasdee20" *ngFor="let option of filteredOptions | async; let incresement = index"
                                        [value]= "optional">
                                {{service.upperStartingCharacter(option.label, true)}}<span *ngIf="!service.isNull(option.code)"
                                                        style="margin-left: 10px;">({{option.code}})</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
