    <center>
        <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px" />
    </center>
    <div class="vertical-center" style="width: 100% !important;">
             <center>
                 <mat-form-field  class="example-full-width" appearance='fill'>
                     <mat-label> Search for {{campaignService.flag.toString().toLowerCase()}} </mat-label>
                     <input type="text"
                            #campaignElement
                            placeholder=""
                            aria-label = "Number"
                            matInput
                            [formControl] = "campaignControl"
                            [matAutocomplete] = "client"/>
                     <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addCampaign()">add</i>
                     <mat-autocomplete #client = "matAutocomplete"
                                       (optionSelected)="onCampaignSelectionChanged($event)"
                                       [displayWith]="displayFn">
                         <mat-option *ngFor="let campaign of filteredCampaignsOptions | async; let clienteOf = index" [value]= "campaign" >
                             {{campaign.name}}
                         </mat-option>
                     </mat-autocomplete>
                     <h4 style="color: red; font-weight: normal"
                         *ngIf="service.isNull(campaignElement.value) === false &&
                         ((filteredCampaignsOptions | async) | json).length === 2">
                         **---Not Found *---</h4>
                 </mat-form-field>
             </center>
            <!--        <center><h5 style="color: white">Search for campaign..</h5></center>-->
            <!--       <div class="form-group top_search">-->
            <!--                <div class="input-group" style="width:50% !important;">-->
            <!--                    <input type="text"-->
            <!--                           #campaignElement-->
            <!--                           class="form-control"-->
            <!--                           placeholder="Search for campaign..."-->
            <!--                           [formControl] = "campaignControl"-->
            <!--                           [matAutocomplete] = "campaign"-->
            <!--                    />-->
            <!--                    <mat-autocomplete #campaign = "matAutocomplete" (optionSelected)="onCampaignSelectionChanged($event)" [displayWith]="displayFn">-->
            <!--                        <mat-question *ngFor="let campaign of filteredCampaignsOptions | async; let campaignOf = index" [value]= "campaign" >-->
            <!--                            <span><img class='img-thumbnail'-->
            <!--                                       src="{{campaign.client.logo ? campaign.client.logo : 'assets/images/facts-bg.png'}}"-->
            <!--                                       style="width: 40px; height: 40px;margin-right: 5px">{{ campaign.name}}</span>-->
            <!--                        </mat-question>-->
            <!--                    </mat-autocomplete>-->
            <!--                    <span class="input-group-btn">-->
            <!--                        <button class="btn btn-default"-->
            <!--                                (click)="addCampaign()"-->
            <!--                                type ="button">-->
            <!--                          <i class="material-icons">add</i>-->
            <!--                        </button>-->
            <!--                    </span>-->
            <!--                </div>-->
            <!--       </div>-->
<!--            <h4 style="color: red; font-weight: normal"-->
<!--                *ngIf="service.isnull(campaignElement.value) === false &&-->
<!--                     ((filteredCampaignsOptions | async) | json).length === 2">-->
<!--                **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
    </div>
