import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../../app.service";
import * as Chart from "chart.js";

@Component({
  selector: 'app-horizontal-bar-chartjs',
  templateUrl: './horizontal-bar-chartjs.component.html',
  styleUrls: ['./horizontal-bar-chartjs.component.scss']
})
export class HorizontalBarChartjsComponent implements OnInit {

  @Input() element: any;
  @Input() color: any;
  @Input() labels: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() showYAxis: any;

  transparentColor: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
  }

  ngOnInit(): void {

  }

  getColor(){
    if(this.color.toString() === 'red') this.color = '#ec250d';
    if(this.color.toString() === 'blue') this.color = '#1f8ef1';
    this.color = this.color.toString() === 'green' ? '#00d6b4' : this.color;
    return this.color;
  }

  getTransparentColor(){
    if(this.color.toString() === 'red')  this.transparentColor = 'rgba(233,32,16,0.2)';
    if(this.color.toString() === 'blue') this.transparentColor = 'rgba(29,140,248,0.2)';
    this.transparentColor = this.color.toString() === 'green' ? '#96CA59' : this.transparentColor;
    return this.transparentColor;
  }

  getLabels(){
    this.labels = this.labels.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString().toUpperCase(), 3).replace('..', '') :
          this.service.limitTo(label.toString(), this.limit)); });
    return this.labels;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      const gradientBarChartConfiguration: any = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },

        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest'
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false,
              },
              ticks: {
                display: this.showYAxis !== undefined,
                beginAtZero: false,
                fontFamily: 'Google Sans',
                fontColor: "#5f6368",
                fontSize: 11,
                fontWeight: 'bold'
              },
              barPercentage: 1.6,
              categoryPercentage: 0.2
            }
          ],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              beginAtZero: false,
              fontColor: '#5f6368',
              fontFamily: 'Google Sans',
              fontSize: 10,
            }
          }]
        }
      };
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke: any = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, this.getTransparentColor());
      gradientStroke.addColorStop(0.4, this.getTransparentColor());
      gradientStroke.addColorStop(0, this.getTransparentColor()); //blue colors
      const opt: any = {
        type: this.type,
        responsive: true,
        legend: {
          display: false,
          show: false
        },
        data: {
          labels: this.getLabels(),
          datasets: [
            {
              label: this.label,
              data: this.data,
              backgroundColor: "#4c84ff",
              borderColor: this.getColor(),
            }
          ]
        },
        options: gradientBarChartConfiguration
      };

      const myChart: any = new Chart(this.ctx, opt);
    });
  }



  ngAfterContentInit(): void {
  }

}
