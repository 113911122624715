<div>&nbsp;</div>
<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Teams">
        <br><br><app-search ></app-search><br><br>
        <app-table-teams [flag]="'all'"></app-table-teams>
    </mat-tab>
<!--    <mat-tab label="Forms">-->
<!--        <br><br><app-search></app-search><br><br>-->
<!--        <app-table-forms [flag]="'all'"></app-table-forms>-->
<!--    </mat-tab>-->
</mat-tab-group>
