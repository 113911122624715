<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div>
                <a mat-flat-button class="defaultColor lg-fab pull-left" (click)="addOptionalEvent()">Add Option</a>
                <div class="pull-left"><br><br>
                    <mat-chip-list #chipList aria-label="selection">
                        <mat-chip
                                *ngFor="let option of options"
                                [selectable]="selectable"
                                [removable]="removable"
                                (click)="selectedOptional =option; handleOption(option)"
                                (removed)="remove(option)">
                            {{option.label}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input
                                placeholder=""
                                #fruitInput
                                [formControl]="questionCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.label}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
<input type="file" (change)="getImage($event)" [ngStyle]="{'display':'none'}" accept="image/*" #ImageFile>
<!--<div style="visibility: hidden; position: fixed"-->
<!--     [style.left]="contextMenuPosition.x"-->
<!--     [style.top]="contextMenuPosition.y"-->
<!--     [matMenuTriggerFor]="contextMenu">-->
<!--</div>-->
<!--<mat-menu #contextMenu="matMenu">-->
<!--    <ng-template matMenuContent let-item="control">-->
<!--        <button mat-menu-item (click)="handleOption(selectedOptional)">Edit</button>-->
<!--        <button mat-menu-item (click)="handleAddQuestional(selectedOptional)">Add Question</button>-->
<!--    </ng-template>-->
<!--</mat-menu>-->
