import {Component, Input, OnInit} from '@angular/core';
import {Morris} from 'morris.js06';
import {ChartService} from '../../../chart.service';
import {AppService} from '../../../app.service';

@Component({
  selector: 'app-morris-barchart',
  templateUrl: './morris-barchart.component.html',
  styleUrls: ['./morris-barchart.component.scss']
})
export class MorrisBarchartComponent implements OnInit {
  @Input() data: any;
  element: any;
  xKey: any;
  constructor(public chart: ChartService,
              public service: AppService) {
  }

  ngOnInit(): void {
    this.element = this.data.element;
    setTimeout(() => {
      new Morris.Bar(Object.assign(this.chart.morrisJs(this.data.labels, this.data.labels, this.data.xKey), {element: this.element, data: this.data.data, yLabelFormat:  (y: any) => {return y === 0 ? '' : this.service.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
   // new Morris.Bar(Object.assign(this.chart.morrisJs(this.data.labels, this.data.labels, 'name'), {element: this.element, data: this.data.data, yLabelFormat:  (y: any) => {return y === 0 ? '' : this.service.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    }, 2000);
  }

}
