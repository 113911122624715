import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-create-qrcode-controls',
  templateUrl: './create-qrcode-controls.component.html',
  styleUrls: ['./create-qrcode-controls.component.scss']
})
export class CreateQrcodeControlsComponent implements OnInit {
    @Input() question: any;
    @Input() position: any;
    label: any;
    isInput: any = true;
    incomingQuestion: any;
    incomingAncestor: any;
    incomingOption: any;
    incomingSettings: any;
    incomingConditions: any;
  constructor() { }

  ngOnInit(): void {
  }

}
