import {Component, Input, OnInit, ViewChild, OnDestroy, NgZone, AfterViewInit} from '@angular/core';
import {AppService} from '../../app.service';
import {ResponseService} from '../responses/data/response.service';
import {ClientService} from '../../clients/client.service';
import {FilterDashboardComponent} from '../../dashboard/filter-dashboard.component';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FormService} from '../form.service';
import * as $ from 'jquery';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {CampaignService} from "../../campaigns/campaign.service";
import {DashboardService} from "../../dashboard/dashboard.service";
import {DialogViewImageComponent} from '../../images/dialog/dialog-view-image/dialog-view-image.component';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import Pusher from 'pusher-js';
import Echo from "laravel-echo";
@Component({
    selector: 'app-images-form',
    templateUrl: './images-form.component.html',
    styleUrls: ['./images-form.component.scss']
})
export class ImagesFormComponent implements OnInit, OnDestroy, AfterViewInit{
    @Input() flag: any;
    @ViewChild(MatMenuTrigger)
    contextMenu: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    app: any;
    position: any;
    form: any;
    formId: any;
    incomingForm: any;
    loopAndDisplayImgInterval: any;
    subscription: any;
    searchCntrl: any;
    teams: any;
    isDialogOpen: any = false;
    campaign: any;
    incomingClient: any;
    incomingCampaign: any;
    interval: any;
    loopImgInterval: any;
    incomingResponse: any;
    incomingResponseResult: any;
    outgoingRequest: any;
    incomingQuestion: any;
    isImgDialogOpened: any = false;
    dialogRef: any=null;
    incomingFormSettings: any;
    outgoingResponse: any;
    incomingResponseResultSelected: any;
    qrcodeUrl: any;
    strings: any;
    public config: any = {
        width: 270,
        height: 270,
        data: '',
        image: '',
        margin: 1,
        dotsOptions: {
            color: "#000",
            type: "dots"
        },
        backgroundOptions: {
            color: "#ffffff",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 1
        }
    };
    constructor(public service: AppService,
                public  dialog: MatDialog,
                public  zone: NgZone,
                private activatedRoute: ActivatedRoute,
                public  broadcastChannel: BroadcastChannelService,
                private qrcode: NgxQrcodeStylingService,
                private campaignService: CampaignService,
                private clientService: ClientService,
                private dashboardService: DashboardService,
                private router: Router,
                private formService: FormService,
                private responseService: ResponseService) {
        this.app = service.app;
        this.contextMenu = MatMenuTrigger;
        this.formId = this.activatedRoute.snapshot.params.id;
        this.formId = this.service.decrypt(this.formId);
        this.form = this.formService.getFormById(this.formId);
        this.incomingForm = new Parameters();
        this.incomingClient = new Parameters();
        this.strings = new Parameters();
        this.incomingCampaign = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.outgoingResponse = new Parameters();
        this.incomingResponseResultSelected = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingResponse = new Parameters();
        this.incomingResponseResult = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingForm.setAttributes(this.form);
        this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
        this.responseService.reportValues.attributes.form_id = this.incomingForm.attributes.id;
        this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
        this.service.header = this.form.name;
        this.incomingResponseResultSelected.setAttributes({});
        this.incomingCampaign.setAttributes(this.campaign);
        this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
        this.strings.setAttributes(this.service.app.strings.params);
    }

    ngOnInit(): void {
        this.incomingFormSettings.setBackgroundColor(
            this.incomingFormSettings.getAttributes().hasOwnProperty('background_color') ?
                this.incomingFormSettings.getBackgroundColor() : '');
        document.title = this.campaign.name;
        //if(this.form.responses.data.length === 0)
        setTimeout(() => {
            this.emitter();
            if(parseFloat(this.incomingForm.getCategoryId()) ===6){
                this.report();
                const echo: any = new Echo({
                    broadcaster : this.strings.getBroadcasterDriver(),
                    key         : this.strings.getWebsocketKey(),
                    wsHost      : this.strings.getWsHost(),
                    wsPort      : parseFloat(this.strings.getWsPort()),
                    disableStats: false,
                });

                echo.channel(
                    this.strings.getWebsocketChannelResponse()).listen('.'+
                    this.strings.getWebsocketEventResponse(), (e: any ) => {
                    this.zone.run(() => {
                        let incomingResponse = new Parameters();
                        incomingResponse.setAttributes(e);
                        this.hasImg(incomingResponse.getAttributes(), (result: any) => {
                            incomingResponse.setAttributes(result);
                            let position: any = this.service.findIntegerIndex(
                                this.responseService.broadCastedResponses,
                                this.strings.getId(),
                                incomingResponse.getId());
                            if(position === -1) {
                                this.responseService.broadCastedResponses.unshift(Object.assign({}, incomingResponse.getAttributes()));
                                this.iterate();
                            }
                            position = this.service.findIntegerIndex(
                                this.incomingForm.getResponses().data,
                                this.strings.getId(),
                                incomingResponse.getId());
                            if(position === -1)
                                this.incomingForm.getResponses().data.unshift(Object.assign({}, incomingResponse.getAttributes()));
                        });
                    });
                });
                // Pusher.logToConsole = false;
                // const pusher = new Pusher('7338988a96c85bb57c5a', {cluster: 'mt1'});
                // const channel = pusher.subscribe('pusher');
                // channel.bind('post', (data: any) => {
                //    this.zone.run(() => {
                //        let incomingResponse = new Parameters();
                //        incomingResponse.setAttributes(data.message);
                //     // incomingResponse.setFlag(11);
                //        if(this.hasImg(incomingResponse.getAttributes())===1){
                //            let position: any = this.service.findIntegerIndex(
                //                this.responseService.broadCastedResponses,
                //                this.strings.getId(),
                //                data.message.id);
                //            if(position === -1) {
                //                this.responseService.broadCastedResponses.unshift(Object.assign({}, incomingResponse.getAttributes()));
                //                this.iterate();
                //            }
                //            position = this.service.findIntegerIndex(
                //                this.incomingForm.getResponses().data,
                //                this.strings.getId(),
                //                incomingResponse.getId());
                //            if(position === -1)
                //                this.incomingForm.getResponses().data.unshift(Object.assign({}, incomingResponse.getAttributes()));
                //        }
                //    });
                // });
                setTimeout(()=>{
                    this.service.setCrypt(this.incomingForm.getId(),(result: any)=>{
                        this.qrcodeUrl = window.location.host +this.service.app.routes.forms.feedback.toString().replaceAll('xx',result)+'?q=';
                        this.qrcodeUrl = this.config.data.toString().includes('https://') ? this.qrcodeUrl : 'https://' + this.qrcodeUrl;
                        if(!this.service.isNull(this.incomingFormSettings.getBackgroundColor())){
                            this.qrcodeUrl = this.qrcodeUrl +'&extension='+this.incomingFormSettings.getBackgroundColor();
                        }if(!this.service.isNull(this.incomingClient.getUnknown('logo')))
                            this.qrcodeUrl = this.qrcodeUrl +'&access='+this.incomingClient.getUnknown('logo').split('clients/')[1];
                        let element:any = document.getElementById('qrcode');
                        this.config.data = this.qrcodeUrl.replaceAll('www.', '');
                        this.qrcode.create(this.config, element).subscribe((res) => {});
                    });
                });

            }
        });

    }

    iterate(){
        if(parseFloat(this.incomingForm.getCategoryId()) === 6) {
            if(this.responseService.broadCastedResponses.length !== 0){
                this.responseService.broadCastedResponses.map((response: any) => {
                    let incomingResponse: any = new Parameters();
                    incomingResponse.setAttributes(response);
                    incomingResponse.getUnknown(this.strings.getDatas()).map((responseResult: any, incrementalResponseResult: any) => {
                        let incomingResponseResult: any = new Parameters();
                        let incomingQuestion: any = new Parameters();
                        incomingResponseResult.setAttributes(responseResult);
                        incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
                        if (parseFloat(incomingQuestion.getControlId()) === 2) {
                            if(!this.service.isNull(incomingResponseResult.getUnknown(this.strings.getData()))) {
                                this.view(incomingResponseResult.getAttributes(), (responseResult: any) => {
                                    if(parseFloat(responseResult[this.strings.getPostId()]) ===
                                        parseFloat(incomingResponseResult.getPostId())){
                                        this.responseService.broadCastedResponses.splice(
                                            this.service.findIntegerIndex(
                                                this.responseService.broadCastedResponses,
                                                this.strings.getId(),
                                                incomingResponseResult.getPostId()), 1);
                                    }this.iterate();
                                });
                            }
                        }
                    });
                });
            }else{
                let responses: any = this.getUnlooped();
                if(responses.length!==0){
                    let incomingResponse: any = new Parameters();
                    incomingResponse.setAttributes(responses[0]);
                    incomingResponse.setFlag(11);
                    incomingResponse.getDatas().map((responseResult: any) => {
                        let incomingResponseResult: any = new Parameters();
                        let incomingQuestion: any = new Parameters();
                        incomingResponseResult.setAttributes(responseResult);
                        incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
                        if(parseFloat(incomingQuestion.getControlId())===2){
                            if (!this.service.isNull(incomingResponseResult.getUnknown(this.strings.getData()))) {
                                this.view(incomingResponseResult.getAttributes(), (result: any) => {
                                    this.iterate();
                                });
                            }
                        }
                    });
                }else{
                    if(this.incomingForm.getResponses().data.length!==0){
                        this.incomingForm.getResponses().data.map((response: any, increment: any) => {
                            let incomingResponse: any = new Parameters();
                            incomingResponse.setAttributes(response);
                            incomingResponse.setFlag(0);
                            // incomingResponse.getDatas().map((responseResult: any) => {
                            //     let incomingResponseResult: any = new Parameters();
                            //     let incomingQuestion: any = new Parameters();
                            //     incomingResponseResult.setAttributes(responseResult);
                            //     incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
                            //     if(parseFloat(incomingQuestion.getControlId()) === 2 && increment === 0){
                            //         if (!this.service.isNull(incomingResponseResult.getUnknown(this.strings.getData()))) {
                            //             this.view(incomingResponseResult.getAttributes(), (result: any) => {});
                            //         }
                            //     }
                            // });
                        });
                        this.iterate();
                    }
                }
            }
        }
        // if(parseFloat(this.incomingForm.getCategoryId()) === 6) {
        //     this.interval = setTimeout(() => {
        //         if (this.responseService.broadCastedResponses.length !== 0) {
        //             if (!this.isImgDialogOpened) {
        //                 this.isImgDialogOpened = true;
        //                 [this.responseService.broadCastedResponses[0]].map((response: any) => {
        //                     this.incomingResponse.setAttributes(response);
        //                     this.position = this.service.findIntegerIndex(
        //                         this.incomingForm.getResponses().data,
        //                         this.strings.getId(),
        //                         this.incomingResponse.getId());
        //                     if (this.position === -1)
        //                         this.incomingForm.getResponses().data.unshift(this.incomingResponse.getAttributes());
        //                     this.incomingResponse.getUnknown(this.strings.getDatas()).map((responseResult: any, incrementalResponseResult: any) => {
        //                         this.incomingResponseResult.setAttributes(responseResult);
        //                         this.incomingQuestion.setAttributes(this.incomingResponseResult.getUnknown('formControl'));
        //                         if (parseFloat(this.incomingQuestion.getControlId()) === 2 && incrementalResponseResult === 0) {
        //                             if (!this.service.isNull(this.incomingResponseResult.getUnknown(this.strings.getData()))) {
        //                                 this.responseService.id = this.responseService.id + parseFloat('1');
        //                                 this.view(this.incomingResponseResult.getAttributes());
        //                             }
        //                         }
        //                     });
        //                 });
        //             }
        //         } else {
        //             // if (!this.isImgDialogOpened) {
        //             //     this.isImgDialogOpened = true;
        //             //     if (this.incomingForm.getResponses().data.length === 1) {
        //             //         this.view(this.incomingForm.getResponses().data[0].datas[0]);
        //             //         this.responseService.id = 0;
        //             //     } else if (this.responseService.id === this.incomingForm.getResponses().data.length - 1) {
        //             //         this.view(this.incomingForm.getResponses().data[this.incomingForm.getResponses().data.length - 1].datas[0]);
        //             //         this.responseService.id = parseFloat('0');
        //             //     } else if (this.responseService.id < this.incomingForm.getResponses().data.length) {
        //             //         this.view(this.incomingForm.getResponses().data[this.responseService.id].datas[0]);
        //             //         this.responseService.id += parseFloat('1');
        //             //     } else this.isImgDialogOpened = false;
        //             // }
        //         }
        //     }, this.service.timeout() * 3);
        // }
    }

    getUnlooped(){
        return this.incomingForm.getResponses().data.filter((response: any) => {
            let incomingResponse: any = new Parameters();
            incomingResponse.setAttributes(response);
            incomingResponse.setFlag(incomingResponse.getAttributes().hasOwnProperty(this.strings.getFlag()) ?
                incomingResponse.getFlag() :  0);
            return parseFloat(incomingResponse.getFlag()) !== 11;
        });
    }


    view(responseResult: any, call: any){
        let incomingResponseResult : any = new Parameters();
        incomingResponseResult.setAttributes(responseResult);
        if(this.dialogRef===null){
            this.dialogRef  = this.dialog.open(DialogViewImageComponent, {
                data   : {data: incomingResponseResult.getAttributes()},
                width  : '50%',
                height : '100%',
                hasBackdrop : true,
                disableClose: parseFloat(this.incomingForm.getCategoryId()) === 6,
            });
            if(parseFloat(this.incomingForm.getCategoryId()) === 6) {
                this.dialogRef.afterClosed().subscribe(result => {
                    this.dialogRef = null;
                    call(incomingResponseResult.getAttributes());
                });
                this.dialogRef.afterOpened().subscribe(result => {
                    setTimeout(() => { this.dialogRef.close(); }, this.service.timeout()*12);
                });
            }
        }

        // if(parseFloat(this.incomingForm.getCategoryId()) === 6) {
        //     this.dialogRef.afterClosed().subscribe(result => {
        //         this.isImgDialogOpened = false;
        //         call(null);
        //     });
        //     this.dialogRef.afterOpened().subscribe(result => {
        //         this.isImgDialogOpened = true;
        //         this.dialogRef.close();
        //     });
        //     try {
        //         this.responseService.broadCastedResponses.splice(
        //             this.service.findIndex(
        //                 this.responseService.broadCastedResponses,
        //                 this.strings.getId(),
        //                 this.incomingResponseResultSelected.getPostId()), 1);
        //     } catch (e) {
        //     }
        // }
    }


    emitter(){
        this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
            .subscribe((item: any) => {
                if (item instanceof Object) {
                    if (item.hasOwnProperty('searchText') === true)
                        this.searchCntrl = item.searchText;
                } else if (item.includes('extract')) {
                    this.responseService.reportValues.setDisplay('excel');
                    if(this.responseService.isDialogOpen === false) {
                        this.responseService.isDialogOpen = true;
                        this.dialog.open(FilterDashboardComponent, {
                            data: {report: this.responseService.reportValues.getAttributes()},
                            width: '55%',
                            height: '100%'
                        }).beforeClosed().subscribe(() =>{
                            this.responseService.isDialogOpen = false;
                        });
                    }
                } else if (item.includes('search')) {
                    this.handleSearch();
                } else if (item === 'openDateMenu') {
                    this.openMenuDate();
                } else if (item === 'report') {
                    this.report();
                    //this.formService.setDate(this.responseService, this.dashboardService);
                }
            });
    }

    viewImg(responseResult: any){
        this.view(responseResult, (result: any)=> {this.iterate();});
    }

    copy(){
        document.execCommand('copy');
    }


    report(){
        setTimeout((outgoingRequest: any = {}) => {
            outgoingRequest = new Parameters();
            outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.getAttributes()));
            if(parseFloat(this.incomingForm.getCategoryId()) === 6)
                outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
            outgoingRequest.setFormId(this.incomingForm.getId());
            delete outgoingRequest.attributes.data;
            let url: any = this.strings.getSlash() + this.strings.getReports();
            this.service.httpService('post', url, outgoingRequest.getAttributes(), {}, (responses: any) => {
                this.incomingForm.getResponses().data = responses;
                if(this.incomingForm.getResponses().data.length !== 0 && parseFloat(this.incomingForm.getCategoryId()) === 6){
                    this.iterate();
                    this.getResponsesByDescending();
                    this.getResponsesByAscending();
                } // this.responseService.reportValues.attributes.data = [];
            }, (error: any) => {
            });
        });
    }

    getResponsesByDescending(){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.getAttributes()));
        outgoingRequest.setFormId(this.incomingForm.getId());
        outgoingRequest.setCategoryId(this.incomingForm.getCategoryId());
        delete outgoingRequest.data;
        this.incomingForm.getResponses().data = this.service.orderBy(this.incomingForm.getResponses().data, 'desc', this.strings.getId());
        outgoingRequest.setDescendinglastAt(this.incomingForm.getResponses().data[this.incomingForm.getResponses().data.length - 1].id);
        let url: any = this.strings.getSlash() + this.strings.getReports();
        this.service.httpService('post', url,
            outgoingRequest.getAttributes(), {
                ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
                responses.map((response: any) => {
                    let incomingResponse: any = new Parameters();
                    incomingResponse.setAttributes(Object.assign({}, response));
                    let position: any = this.service.findIntegerIndex(
                        this.incomingForm.getResponses().data,
                        this.strings.getId(),
                        incomingResponse.getId());
                    if(position === -1)
                        this.incomingForm.getResponses().data.push(incomingResponse.getAttributes());
                });if(responses.length !==0){
                    this.iterate();
                    this.getResponsesByDescending();
                }
            }, (error: any) => {
            });
    }

    loopAndViewImg(){
        if(parseFloat(this.incomingForm.getCategoryId()) === 6) {
            if (!this.responseService.isImgDialogOpened) {
                this.responseService.isImgDialogOpened = true;
                setTimeout((response: any) => {
                    if (this.responseService.id === 0 && this.incomingForm.getResponses().data.length !== 0) {
                        if (!this.isImgDialogOpened) {
                            this.isImgDialogOpened = true;
                            this.responseService.id += parseFloat('1');
                            // this.view(this.incomingForm.getResponses().data[0].datas[0]);
                        }
                    }
                });
            }
        }
    }

    getResponsesByAscending(){
        // let outgoingRequest: any = new Parameters();
        // outgoingRequest.setAttributes(Object.assign({}, this.responseService.reportValues.getAttributes()));
        // outgoingRequest.setFormId(this.incomingForm.getId());
        // delete outgoingRequest.data;
        // this.incomingForm.getResponses().data = this.service.orderBy(this.incomingForm.getResponses().data, 'desc', this.strings.getId());
        // outgoingRequest.setAscendinglastAt(this.incomingForm.getResponses().data[0].id);
        // this.service.httpService('post',
        //     this.service.app.routes.reports.fetch,
        //     outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (responses: any) => {
        //         responses.map((response: any) => {
        //             let incomingResponse: any = new Parameters();
        //             incomingResponse.setAttributes(Object.assign({}, response));
        //          // incomingResponse.setFlag(11);
        //             if(this.hasImg(incomingResponse.getAttributes()) ===1){
        //                 let position: any = this.service.findIntegerIndex(
        //                     this.responseService.broadCastedResponses,
        //                     this.strings.getId(),
        //                     incomingResponse.getId());
        //                 if(position === -1) {
        //                     this.responseService.broadCastedResponses.unshift(Object.assign({},
        //                         incomingResponse.getAttributes()));
        //                 }position = this.service.findIntegerIndex(
        //                     this.incomingForm.getResponses().data,
        //                     this.strings.getId(),
        //                     incomingResponse.getId());
        //                 if(position === -1)
        //                     this.incomingForm.getResponses().data.unshift(Object.assign({},
        //                     incomingResponse.getAttributes()));
        //             }
        //         });
        //         setTimeout(() => {this.getResponsesByAscending()}, this.service.timeout()*24);
        //     }, (error: any) => {
        //     });
    }


    hasImg(response: any, $call){
        let incomingResponse: any = new Parameters();
        incomingResponse.setAttributes(response);
        incomingResponse.setFlag(0);
        incomingResponse.getDatas().map((responseResult: any) => {
            let incomingResponseResult: any = new Parameters();
            let incomingQuestion = new Parameters();
            incomingResponseResult.setAttributes(responseResult);
            incomingQuestion.setAttributes(incomingResponseResult.getUnknown('formControl'));
            if(parseFloat(incomingQuestion.getControlId()) === 2)
                if(!this.service.isNull(incomingResponseResult.getData()))
                    incomingResponse.setFlag(11);
        });
        if(parseFloat(incomingResponse.getFlag()) === 0){
            setTimeout(() => {
                let outgoingRequest: any = new Parameters();
                outgoingRequest.setAttributes({});
                outgoingRequest.setId(incomingResponse.getId());
                this.service.httpService('post',
                    this.service.app.routes.responses.get, outgoingRequest.getAttributes(), {
                    ignoreLoadingBar: 'true', notify: false
                    }, (result: any) => {
                        incomingResponse.attributes = Object.assign(incomingResponse.attributes, result);
                        this.hasImg(incomingResponse.getAttributes(), $call);
                }, (error: any) => {this.hasImg(incomingResponse.getAttributes(), $call);
                });
            }, this.service.timeout()*4);
        }else $call(incomingResponse.getAttributes());
    }




    openMenuDate(){
        if(this.responseService.isDialogOpen === false) {
            this.responseService.isDialogOpen = true;
            this.responseService.reportValues.setDisplay('dashboard');
            this.dialog.open(FilterDashboardComponent, {data: {report: this.responseService.reportValues.getAttributes()}, width: '55%', height: '100%'}).beforeClosed().subscribe(() => {
                this.responseService.isDialogOpen = false;
            });
        }
    }


    handleSearch(){
        setTimeout((outgoingRequest: any = {}) => {
            this.responseService.reportValues.setDisplay('dashboard');
            outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
            outgoingRequest.searchText = this.searchCntrl;
            delete outgoingRequest.data;
            this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
                this.form.responses.data = response;
            }, (error: any) => {
            });
        });
    }




    onContextMenu(event: MouseEvent, inspector: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'item': inspector };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    handleDestroy(response: any){
        this.incomingForm = new Parameters();
        this.incomingForm.setAttributes(this.form);
        this.dialog.open(DeleteDialogComponent, {
            data    : {
                data  : this.form.responses,
                model : this.form,
                title : this.incomingForm.getName(),
                url   : '/forms/' + parseFloat(this.incomingForm.getId()) + '/delete',
                key   : 'id',
                id    : this.incomingForm.getId(),
            },
            width   : 'auto',
        });
    }

    getCampaignByForm(form: any){
        let campaign: any = this.service.only(this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === form.campaign_id;})[0]);
        let client: any = this.service.only(this.getClientByCampaign(campaign));
        campaign = Object.assign(campaign, {client: client});
        form = Object.assign(form, {campaign: campaign});
        return campaign;
    }


    getClientByCampaign(campaign: any){
        return this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
    }

    handleUpdate(inspector: any){
        this.incomingForm = new Parameters();
        this.incomingForm.setAttributes(this.form);
        this.responseService.setSelected(this.incomingForm.getAttributes());
        // this.formsComponent.openDialog();
    }

    handleRestore(inspector: any){
        this.incomingForm.setAttributes(this.form);
        this.service.restore('/forms/' + parseFloat(this.incomingForm.getId()) + '/restore',
            this.form.responses, 'id', parseFloat(this.incomingForm.getId()), this.form);
    }


    handleResponses(form: any){
        this.subscription.unsubscribe();
        this.incomingForm.setAttributes(this.form);
        this.responseService.setSelected(this.form);
        this.router.navigateByUrl('/app/forms/' + this.form.id + '/responses');
    }

    openDialog(response: any){
        this.responseService.setSelected(this.incomingForm.getAttributes());
        // this.inspectorsComponent.openDialog();
    }

    ngAfterViewInit(): void {
        document.getElementsByTagName( 'body')[0].style.backgroundColor = '#000';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


}
