<ng-container *ngIf="this.orders.length !== 0">
    <table [ngStyle]="{'position':'absolute','top':'5xp','left':'5px','background-color':'transparent','width':'100%','z-index': '999999999'}">
        <tr>
<!--            <td valign="top"-->
<!--                [ngStyle]="{'width':'100%'}">-->
<!--                <a class="pull-left cursor" *ngIf="!drawer.opened"><img src="../../assets/images/option-icon.png" (click)="drawer.toggle()"></a>-->
<!--            </td>-->
            <td valign="top" >
                <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mdl-shadow--2dp">
                    <table [ngStyle]="{'width':'100%','margin':'5px'}">
                        <td valign="middle" class="cursor">
                            <mat-icon matSuffix>reorder</mat-icon>
                        </td>
                        <td valign="middle" [ngStyle]="{'width':'100%'}">
                            <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"
                                    autocomplete="off"
                                    ngClass="sawasdee18 searchbar"
                                    #addressId
                                    placeholder=""
                                    matGoogleMapsAutocomplete>
                        </td>
                        <td valign="middle">
                            <mat-icon matSuffix >search</mat-icon>
                        </td>
                    </table>
<!--                    <br>-->
<!--                    <mat-divider></mat-divider>-->
<!--                    <div class="mdl-shadow&#45;&#45;2dp" [ngStyle]="{'margin-top':'5px'}">-->
<!--                        <ul class="demo-list-item mdl-list">-->
<!--                            <li class="mdl-list__item ">-->
<!--                                <mat-card>-->
<!--                                    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
<!--                                    <mat-card-content>-->
<!--                                        <p>-->
<!--                                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.-->
<!--                                            A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally-->
<!--                                            bred for hunting.-->
<!--                                        </p>-->
<!--                                    </mat-card-content>-->
<!--                                    <mat-card-actions>-->
<!--                                        <button mat-button>LIKE</button>-->
<!--                                        <button mat-button>SHARE</button>-->
<!--                                    </mat-card-actions>-->
<!--                                </mat-card>-->
<!--                            </li>-->
<!--                            <li class="mdl-list__item">-->
<!--                                <mat-card>-->
<!--                                    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">-->
<!--                                    <mat-card-content>-->
<!--                                        <p>-->
<!--                                            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.-->
<!--                                            A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally-->
<!--                                            bred for hunting.-->
<!--                                        </p>-->
<!--                                    </mat-card-content>-->
<!--                                    <mat-card-actions>-->
<!--                                        <button mat-button>LIKE</button>-->
<!--                                        <button mat-button>SHARE</button>-->
<!--                                    </mat-card-actions>-->
<!--                                </mat-card>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
                </div>
            </td>
        </tr>
    </table>
    <agm-map
        #agm
        [latitude]="this.service.parseFloat(this.orders[0].lat)"
        [longitude]="this.service.parseFloat(this.orders[0].lng)"
        [fitBounds]="false"
        [zoom]="zoom"
        [disableDefaultUI]="true"
        [styles]="this.service.app.settings.map.styles.style1"
        (mapClick)="onmapClick()"
        [ngStyle]="{'height': this.service.getHeight() + 'px', 'width':this.service.getWidth() + 'px'}">
        <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
        <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
<!--        <agm-marker-->
<!--                [latitude]="this.service.parseFloat(currentlat)"-->
<!--                [longitude]="this.service.parseFloat(currentlng)"-->
<!--                [agmFitBounds]="true"-->
<!--                [openInfoWindow]="true"-->
<!--                [iconUrl]="agmIcon">-->
<!--        </agm-marker>-->
        <ng-container *ngFor="let order of this.orders">
            <ng-container *ngFor="let route of order.routes">
                <agm-marker
                        [latitude]="service.parseFloat(route.origin.lat)"
                        [longitude]="service.parseFloat(route.origin.lng)"
                        [agmFitBounds]="true"
                        (markerClick)="onMarkerClick(infoWindow)"
                        [openInfoWindow]="true"
                        [iconUrl]="this.pin">
                    <agm-info-window #infoWindow>
                        <div [ngStyle]="{'padding':'10px','font-family':'Sawasdee','-webkit-transform':' scale(1.15,0.77)','color':'rgb(90, 115, 142)'}">
                            <div [innerHTML]="order.reference3.replace('THANK YOU !','') | safeUrl"></div>
                            <div><center><button mat-raised-button
                                                 (click)="addDelivery(order)"
                                                 [ngStyle]="{'color':'rgb(90, 115, 142)'}">I have delivered</button>
                            </center></div>
                        </div>
                    </agm-info-window>
                </agm-marker>
                <agm-circle [latitude]="service.parseFloat(route.origin.lat)"
                            [longitude]="service.parseFloat(route.origin.lng)"
                            [radius]="500"
                            [fillColor]="'red'"
                            [circleDraggable]="false"
                            [editable]="false">
                </agm-circle>
                <agm-marker
                        [latitude]="this.service.parseFloat(currentlat)"
                        [longitude]="this.service.parseFloat(currentlng)"
                        [agmFitBounds]="true"
                        [openInfoWindow]="true"
                        [iconUrl]="this.agmIcon">
                </agm-marker>
                <agm-direction
                        [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : route.hasOwnProperty('stroke_color') ? route.stroke_color : '#ff8000'}}"
                        [provideRouteAlternatives]="false"
                        [waypoints]="[]"
                        [origin]="{ lat: service.parseFloat(route.origin.lat), lng: service.parseFloat(route.origin.lng)}"
                        [destination]="{ lat: this.service.parseFloat(currentlat), lng: this.service.parseFloat(currentlng) }">
                </agm-direction>
            </ng-container>
        </ng-container>
    </agm-map>
</ng-container>

