import {Component, NgZone, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {ActivatedRoute} from "@angular/router";
import {ResponseService} from "../../forms/responses/data/response.service";
import {Parameters} from "../../parameters";
import {FormService} from "../../forms/form.service";
import Echo from 'laravel-echo';
import {WaypointService} from "../../waypoints/waypoint.service";

@Component({
  selector: 'app-shipment-order',
  templateUrl: './shipment-order.component.html',
  styleUrls: ['./shipment-order.component.scss']
})
export class ShipmentOrderComponent implements OnInit {
  orderId: any;
  incomingResponse: any;
  incomingForm: any;
  strings: any;
  deliveryUsers: any = [];
  lat: any = 0;
  lng: any = 0;
  zoom: any = 13;
  originIcon: any = null;
  agmIcon: any = null;
  destinationIcon: any;
  marker3: any = null;
  pin: any = null;
  constructor(public service: AppService,
              private responseService: ResponseService,
              private waypointService: WaypointService,
              private formService: FormService,
              public zone: NgZone,
              private activatedRoute: ActivatedRoute) {
    this.incomingResponse = new Parameters();
    this.incomingForm = new Parameters();
    this.strings = new Parameters();
    this.incomingResponse.setAttributes({});
    this.incomingForm.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
    this.originIcon = {url: this.service.app.routes.app.agm.marker1, scaledSize: {height: 40, width: 40}};
    this.destinationIcon = {url: this.service.app.routes.app.agm.marker2, scaledSize: {height: 40, width: 40}};
    this.agmIcon = {url: this.service.app.routes.app.agm.icon, scaledSize: {width: 70, height: 70}};
    this.marker3 = {url: this.service.app.routes.app.agm.marker3, scaledSize: {width: 70, height: 40}};
    this.pin = {url: this.service.app.routes.app.agm.pin, scaledSize: {width: 70, height: 70}};
    this.orderId = this.service.decrypt(this.activatedRoute.snapshot.params.orderId);
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    outgoingRequest.setId(this.orderId);
    this.responseService.requestResponseById(this.orderId, (response: any) => {
        if(response !== null){
          this.incomingResponse.setAttributes(response);
          this.incomingResponse.setDeliveryUsers(
              this.incomingResponse.hasAttribute(this.strings.getDeliveryUsers()) ?
                  this.incomingResponse.getDeliveryUsers() : {data : []});
          this.deliveryUsers = this.incomingResponse.getDeliveryUsers()[this.strings.getData()];
          this.responseService.setSelected(response);
          if(this.incomingResponse.hasAttribute(this.strings.getForm())){
            this.incomingForm.setAttributes(this.incomingResponse.getForm());
            this.formService.setSelected(this.incomingForm.getAttributes());
            this.requestDeliveryUsers(() => {});
          }
        }
    });
  }

  initDeliveryUserWebsocpketByOrderId(orderId: any){
    let echo: any = new Echo({
      broadcaster : this.strings.getBroadcasterDriver(),
      key         : this.strings.getWebsocketKey(),
      wsHost      : this.strings.getWsHost(),
      wsPort      : parseFloat(this.strings.getWsPort()),
      disableStats: false,
    });
    echo.channel(
        this.strings.getWebsocketChannelDeliveryUser() + this.incomingResponse.getId()).listen('.' +
        this.strings.getWebsocketEventDeliveryUser()   + this.incomingResponse.getId(), (e: any ) => {
        this.zone.run(() => {
          let incomingResult: any = new Parameters();
          incomingResult.setAttributes(JSON.parse(e[0]));
          let outgoingRequest: any = new Parameters();
          outgoingRequest.setAttributes({});
          outgoingRequest.setId(incomingResult.getEntityId());
          this.service.httpService( 'POST',
              this.service.app.routes.orders.deliveryUsers,
              outgoingRequest.getAttributes(),
              {ignoreLoadingBar : 'true', notify: 'false'},
              (users: any) => {
                users.map((user: any) => {
                  let incomingUser: any = new Parameters();
                  incomingUser.setAttributes(user);
                  this.deliveryUsers.push(incomingUser.getAttributes());
                });
              },  (error: any) => {});
        });
    });
  }

  requestUserWaypointsById(id: any, call: any){
    let incomingUser: any = new Parameters();
    incomingUser.setAttributes(this.deliveryUsers.filter((user: any) => {
      let incomingUser: any = new Parameters();
      incomingUser.setAttributes(user);
      return parseFloat(incomingUser.getId()) === parseFloat(id);
    })[0]); incomingUser.setWaypoints(incomingUser.hasAttribute(this.strings.getWaypoints()) ? incomingUser.getWaypoints() : []);
    incomingUser.setWaypoints(this.service.orderBy(incomingUser.getWaypoints(), this.strings.getDesc(), this.strings.getId()));
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    if(incomingUser.getWaypoints().length !== parseFloat('0'))
      outgoingRequest.setAscendinglastAt(incomingUser.getWaypoints()[incomingUser.getWaypoints().length - 1][this.strings.getId()]);
    outgoingRequest.setSample(this.strings.getUser() + this.strings.getDot() + this.strings.getWaypoints());
    outgoingRequest.setEntity1(incomingUser.getId());
    this.service.httpService( 'POST',
        this.service.app.routes.users.waypoints.get,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (waypoints: any) => {
          waypoints.map((waypoint: any) => {
            let incomingWaypoint: any = new Parameters();
            incomingWaypoint.setAttributes(waypoint);
            incomingUser.attributes.waypoints.push({location: incomingWaypoint.getLocation()});
          });if(waypoints.length !== parseFloat('0')) {
            this.requestUserWaypointsById(id, call);
          } else this.service.matSpinner = false;
        },  (error: any) => {});
  }

  getWaypoints(waypoints: any){return waypoints.map((waypoint: any) => {return {location: waypoint.location};});}

  requestDeliveryUsers(call: any){
    this.deliveryUsers = this.service.orderBy(this.deliveryUsers, this.strings.getDesc(), this.strings.getId());
    this.service.matSpinner = true;
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    if(this.deliveryUsers.length !== 0)
      outgoingRequest.setAscendinglastAt(this.deliveryUsers[this.deliveryUsers.length - 1][this.strings.getEntityId()]);
    outgoingRequest.setSample(this.strings.getDelivery() + this.strings.getDot() + this.strings.getUsers());
    outgoingRequest.setEntity1(this.incomingResponse.getId());
    this.service.httpService( 'POST',
        this.service.app.routes.orders.deliveryUsers,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (users: any) => {
          users.map((user: any) => {
            let incomingUser: any = new Parameters();
            incomingUser.setAttributes(user);
            this.deliveryUsers.push(incomingUser.getAttributes());
            this.requestUserWaypointsById(incomingUser.getId(), () => {});
            this.initUserWeypointWebsocpketByUserId(incomingUser.getId());
            if(this.lat === 0) {
              this.lat = parseFloat(this.deliveryUsers[0].route.origin.lat);
              this.lng = parseFloat(this.deliveryUsers[0].route.origin.lng);
              this.initDeliveryUserWebsocpketByOrderId(this.incomingResponse.getId());
            }
          });setTimeout(() => {
            if(users.length !== parseFloat('0')) {
              this.requestDeliveryUsers(call);
            } else this.service.matSpinner = false;
          });
        },  (error: any) => {});
  }

  initUserWeypointWebsocpketByUserId(userId: any){
    let echo: any = new Echo({
      broadcaster : this.strings.getBroadcasterDriver(),
      key         : this.strings.getWebsocketKey(),
      wsHost      : this.strings.getWsHost(),
      wsPort      : parseFloat(this.strings.getWsPort()),
      disableStats: false,
    });
    echo.channel(
        this.strings.getWebsocketChannelUserWaypoint() + userId).listen('.' +
        this.strings.getWebsocketEventUserWaypoint()   + userId, (e: any ) => {
      this.zone.run(() => {
        let incomingResult: any = new Parameters();
        incomingResult.setAttributes(JSON.parse(e[0]));
        this.waypointService.requestWaypointById(incomingResult.getWaypointId(), (waypoint: any) => {
          let incomingWaypoint: any = new Parameters();
          incomingWaypoint.setAttributes(waypoint);
          let incomingUser: any = new Parameters();
          incomingUser.setAttributes(this.deliveryUsers.filter((user: any) => {
            let incomingUser: any = new Parameters();
            incomingUser.setAttributes(user);
            return parseFloat(incomingUser.getId()) === parseFloat(userId);
          })[0]);incomingUser.setWaypoints.push({location: incomingWaypoint.getLocation()});
        });
        // let outgoingRequest: any = new Parameters();
        // outgoingRequest.setAttributes({});
        // outgoingRequest.setId(incomingResult.getEntityId());
        // this.service.httpService( 'POST',
        //     this.service.app.routes.orders.deliveryUsers,
        //     outgoingRequest.getAttributes(),
        //     {ignoreLoadingBar : 'true', notify: 'false'},
        //     (users: any) => {
        //       users.map((user: any) => {
        //         let incomingUser: any = new Parameters();
        //         incomingUser.setAttributes(user);
        //         this.deliveryUsers.push(incomingUser.getAttributes());
        //       });
        //     },  (error: any) => {});
      });
    });
  }

}
