import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {ImplDistrictsService} from './impl-districts.service';
import {RegionsService} from '../regionals/regions.service';
import {Parameters} from '../parameters';
import {DialogDistrictalComponent} from './dialog-districtal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.scss']
})
export class DistrictsComponent implements OnInit {
  app: any;
  incomingSelectedRegional: any;
  constructor(
    public serviceImpl: AppService,
    public dialog: MatDialog,
    public districtalImpl: ImplDistrictsService,
    public regionalImpl: RegionsService) {
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(regionalImpl.getSelected());
    this.app = this.serviceImpl.app;
    this.app.form.modules  = [
      {path: 'app/regionals' , title: this.incomingSelectedRegional.getName()},
      {path: 'app/regionals/' + this.incomingSelectedRegional.getName() + '/districts' , title: 'Districts'}

    ];
    this.app.form.subtitle = this.app.settings.descriptions.species;
    this.app.form.module = 'districtals';
    districtalImpl.setData({data: this.incomingSelectedRegional.getDistricts(), trashed: {data: []}});
  }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialog.open(DialogDistrictalComponent, {data: {}, width: '55%', height: 'auto'});
  }

}
