import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogTeamsComponent} from './dialog/dialog-teams.component';
import {TeamService} from './team.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  app: any;
  constructor(public service: AppService,
              private dialog: MatDialog,
              public metthods: TeamService) {
    this.app = service.app;
    this.app.form.modules  = [{path: '/app/teams', title: 'Teams'}];
    this.app.form.subtitle = 'the dead body of an animal ready for butchery, after removal of skin, visceral organs, head, feet etc..';
    this.app.form.module   = 'teams';
  }

  ngOnInit(): void {
    this.metthods.setData(this.app.data.teams);
  }

  openDialog(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
  }


}
