import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {RegionsService} from '../regionals/regions.service';
import {ImplDistrictsService} from './impl-districts.service';
import {Parameters} from '../parameters';
import {DistrictsComponent} from './districts.component';
import {AppService} from '../app.service';
import {DeleteDialogComponent} from '../main/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../broadcast-channel.service';

@Component({
  selector: 'app-table-districtal',
  templateUrl: './table-districtal.component.html',
  styleUrls: ['./table-districtal.component.scss']
})
export class TableDistrictalComponent implements OnInit {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  districts: any;
  incomingSelectedRegional: any;
  selectedDistrictal: any;
  selectedDistrictStatus: any;
  incomingSelectedDistrictal: any;
  searchCntrl: any;
  subscription: any;
  constructor(public regionalImpl: RegionsService,
              private districtalComponent: DistrictsComponent,
              public broadcastChannel: BroadcastChannelService,
              public serviceImpl: AppService,
              public dialog: MatDialog,
              public districtalImpl: ImplDistrictsService) {
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedDistrictal = new Parameters();
    this.incomingSelectedRegional.setAttributes(regionalImpl.getSelected());
    this.incomingSelectedDistrictal.setAttributes(districtalImpl.getSelected());
    this.districts = this.districtalImpl.getData();
    this.selectedDistrictStatus = Object.keys(this.incomingSelectedDistrictal.getAttributes()).length;
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('searchText') === true) {
            this.searchCntrl = item.searchText;
          }
        }

      });
  }

  onContextMenu(event: MouseEvent, districtal: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': districtal };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleUpdate(districtal: any){
    this.incomingSelectedDistrictal = new Parameters();
    this.incomingSelectedDistrictal.setAttributes(this.selectedDistrictal);
    this.districtalImpl.setSelected(this.incomingSelectedDistrictal.getAttributes());
    this.districtalComponent.openDialog();
  }

  handleRestore(districtal: any){
    this.incomingSelectedDistrictal.setAttributes(this.selectedDistrictal);
    this.serviceImpl.restore('/districts/' + parseFloat(this.incomingSelectedDistrictal.getId()) + '/restore',
    this.districts, 'id', parseFloat(this.incomingSelectedDistrictal.getId()), this.selectedDistrictal);
  }

  handleDestroy(districtal: any){
    this.incomingSelectedDistrictal = new Parameters();
    this.incomingSelectedDistrictal.setAttributes(this.selectedDistrictal);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.districts,
        model: this.selectedDistrictal,
        title: this.incomingSelectedDistrictal.getName(),
        url  : '/districts/' + parseFloat(this.incomingSelectedDistrictal.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedDistrictal.getId(),
      },
      width : 'auto',
    });
  }


}
