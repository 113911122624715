import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';
import {DepartmentsService} from './departments.service';
import {DialogDepartmetsComponent} from './dialog-departmets.component';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  app: any;
  constructor(public service: AppService,
              private broadcastChannel: BroadcastChannelService,
              private dialog: MatDialog,
              public departmentMetthods: DepartmentsService) {
    this.app = service.app;
    this.app.form.modules  = [{path: '/app/departments', title: 'Departmets'}];
    this.app.form.subtitle = ' medical conditions that are associated with specific signs and symptoms';
    this.app.form.module   = 'departments';
  }

  ngOnInit(): void {
      this.departmentMetthods.setData(this.app.data.departments);
  }



  openDialog() {
    this.dialog.open(DialogDepartmetsComponent, {data: {}, width: '55%', height: '100%'});
  }

}
