<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Starting At</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Ending At</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Campany</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Client</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="mdl-data-table__cell--non-numeric headline2 text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let campaign of (flag.includes('all') ?
      getCampaignsBySearchText(searchCntrl) :
      (service.app.data.campaigns.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, campaign)"
      (mousedown)="selectedCampaign = campaign" (dblclick)="onDoubleClickablecampaign(campaign)">
        <td class="mdl-data-table__cell--non-numeric headline">{{campaign.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{campaign.starting_at}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{campaign.ending_at}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">
<!--            {{campanyService.getCampanyById(campaign.campany_id) ? campanyService.getCampanyById(campaign.campany_id).name : 'unknown'}}-->
        </td>
        <td class="mdl-data-table__cell--non-numeric headline">
<!--            {{clientService.getClientById(campaign.client_id).name}}-->
        </td>
        <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="campaign.description"></td>
        <td class="mdl-data-table__cell--non-numeric headline">
          <div class="dropdown pull-right">
            <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
    <!--      <li mat-menu-item  (click)="handleCopy()">Copy</li>-->
              <li mat-menu-item *ngIf="service.status !== 1" (click)="handleUpdate(selectedCampaign)">Edit</li>
              <li mat-menu-item *ngIf="service.status !== 0" (click)="handleDestroy(selectedCampaign)">Delete</li>
              <li mat-menu-item *ngIf="service.status === 0" (click)="handleRestore(selectedCampaign)">Restore</li>
            </ul>
          </div>
          <div style="visibility: hidden; position: fixed"
               [style.left]="contextMenuPosition.x"
               [style.top]="contextMenuPosition.y"
               [matMenuTriggerFor]="contextMenu">
          </div>
          <mat-menu #contextMenu="matMenu">
            <ng-template matMenuContent let-item="inspector">
    <!--      <button mat-menu-item (click)="handleCopy()">Copy</button>-->
              <button mat-menu-item *ngIf="service.status !== 0" (click)="handleUpdate(selectedCampaign)">Edit</button>
              <button mat-menu-item *ngIf="service.status !== 0" (click)="handleDestroy(selectedCampaign)">Delete</button>
              <button mat-menu-item *ngIf="service.status === 0" (click)="handleRestore(selectedCampaign)">Restore</button>
            </ng-template>
          </mat-menu>
        </td>
  </tr>
  </tbody>
</table>
