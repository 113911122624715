import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {MatMenuTrigger} from '@angular/material/menu';
import {DeleteDialogComponent} from "../../../../main/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
@Component({
  selector: 'app-adverts-draft',
  templateUrl: './adverts-draft.component.html',
  styleUrls: ['./adverts-draft.component.scss']
})
export class AdvertsDraftComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingQuestion: any;
  incomingImg: any;
  incomingSettings: any;
  outgoingRequest: any;
  incomingImgSelected: any;
  caption: any;
  incomingResponse: any;
  constructor(private service: AppService,
              public  dialog: MatDialog) {
   this.incomingQuestion = new Parameters();
   this.incomingImg = new Parameters();
   this.incomingSettings = new Parameters();
   this.outgoingRequest = new Parameters();
   this.incomingImgSelected = new Parameters();
   this.incomingResponse = new Parameters();
   this.contextMenu = MatMenuTrigger;
   this.incomingImgSelected.setAttributes({});
  }

  ngOnInit(): void {
     this.incomingQuestion.setAttributes(this.question);
  }

  onContextMenu(event: MouseEvent, item: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': item };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }

  setCaption(){
      this.caption = prompt('', this.incomingImgSelected.getCaption());
      if(this.caption !== null){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
          this.outgoingRequest.setCaption(this.caption);
          this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
          this.service.httpService('post', '/images/xxx/update',
              this.outgoingRequest.getAttributes(), {},(response: any) => {
                  this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
              }, (error: any) => {
              });
      }
  }

  setUrl(){
      let url: any = prompt('', this.incomingImgSelected.getPath());
      if(url !== null){
          this.incomingImgSelected.attributes.settings.src = url;
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
          this.outgoingRequest.setUrl(url);
          this.outgoingRequest.setPath(url);
          this.outgoingRequest.setSrc(url);
          this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
          this.service.httpService('post', '/images/xxx/update',
              this.outgoingRequest.getAttributes(), {},(response: any) => {
                  this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
                  this.outgoingRequest.setSrc(this.incomingImgSelected.getSrc());
              }, (error: any) => {
              });
      }
  }


  getImg(event: any){
        this.incomingQuestion.setAttributes(this.question);
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          if(this.incomingImgSelected.attributes.hasOwnProperty('id')){
              this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
              this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
              this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
              this.service.httpService('post', '/images/' + this.outgoingRequest.getId() + '/update',
                  this.outgoingRequest.getAttributes(), {},(response: any) => {
                   this.incomingResponse.setAttributes(Object.assign({}, response));
                   this.incomingImgSelected.setPath(this.incomingResponse.getPath());
                  }, (error: any) => {
                  });
          }else{
              this.incomingImg.setAttributes({});
              this.incomingImg.setSrc(reader.result);
              this.incomingImg.setFlag(0);
              this.incomingImg.setCaption('');
              this.incomingImg.setOrderBy('');
              this.incomingImg.setStatus(1);
              this.incomingImg.setUrl('');
              this.incomingImg.setPath('');
              this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
              this.incomingSettings.setImages(this.incomingSettings.attributes.hasOwnProperty('images') ? this.incomingSettings.getImages() : []);
              this.incomingSettings.getImages().push(this.incomingImg.getAttributes());
              if(this.incomingQuestion.attributes.hasOwnProperty('id')){
                  this.incomingImg.setAvatar(this.service.dataURItoBlob(reader.result));
                  setTimeout((params: any) => {
                      this.service.httpService('post', '/images',
                          this.incomingImg.getAttributes(), {},(response: any) => {
                              this.incomingImg.attributes = Object.assign(this.incomingImg.attributes, response);
                              this.outgoingRequest.setAttributes({});
                              this.outgoingRequest.setEntity1(params.entity1);
                              this.outgoingRequest.setEntity2(this.incomingImg.getId());
                              this.outgoingRequest.setDescription('');
                              this.outgoingRequest.setSample('question.images');
                              this.setEntity(this.outgoingRequest);
                          }, (error: any) => {
                          });
                  },0, {entity1 : this.incomingQuestion.getId()});
              }
          }

        };
 }



    getVideo(event: any){
        this.incomingQuestion.setAttributes(this.question);
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            if(this.incomingImgSelected.attributes.hasOwnProperty('id')){
                this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
                this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
                this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
                this.service.httpService('post', '/images/' + this.outgoingRequest.getId() + '/update',
                    this.outgoingRequest.getAttributes(), {},(response: any) => {
                        this.incomingResponse.setAttributes(Object.assign({}, response));
                        this.incomingImgSelected.setPath(this.incomingResponse.getPath());
                    }, (error: any) => {
                    });
            }else{
                this.incomingImg.setAttributes({});
                this.incomingImg.setSrc(reader.result);
                this.incomingImg.setFlag(0);
                this.incomingImg.setCaption('');
                this.incomingImg.setOrderBy('');
                this.incomingImg.setStatus(1);
                this.incomingImg.setExtension('.mp4');
                this.incomingImg.setUrl('');
                this.incomingImg.setPath('');
                this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
                this.incomingSettings.setImages(this.incomingSettings.attributes.hasOwnProperty('images') ? this.incomingSettings.getImages() : []);
                this.incomingSettings.getImages().push(this.incomingImg.getAttributes());
                if(this.incomingQuestion.attributes.hasOwnProperty('id')){
                    this.incomingImg.setAvatar(this.service.dataURItoBlob(reader.result));
                    setTimeout((params: any) => {
                        this.service.httpService('post', '/images',
                            this.incomingImg.getAttributes(), {},(response: any) => {
                                this.incomingImg.attributes = Object.assign(this.incomingImg.attributes, response);
                                this.outgoingRequest.setAttributes({});
                                this.outgoingRequest.setEntity1(params.entity1);
                                this.outgoingRequest.setEntity2(this.incomingImg.getId());
                                this.outgoingRequest.setDescription('');
                                this.outgoingRequest.setSample('question.images');
                                this.setEntity(this.outgoingRequest);
                            }, (error: any) => {
                            });
                    },0, {entity1 : this.incomingQuestion.getId()});
                }
            }

        };
  }

  remove(){
        this.dialog.open(DeleteDialogComponent, {
            data  : {
                data : this.incomingQuestion.attributes.settings.images,
                model: this.incomingImgSelected.getAttributes(),
                title: this.incomingImgSelected.getCaption(),
                url  : '/images/' + parseFloat(this.incomingImgSelected.getId()) + '/delete',
                key  : 'id',
                id   : this.incomingImgSelected.getId(),
            },
            width : 'auto',
        });
  }

 setActionCaption(){
     this.incomingImgSelected.setSettings(this.incomingImgSelected.getSettings() instanceof Array ? {} : this.incomingImgSelected.getSettings());
     this.incomingSettings.setAttributes(this.incomingImgSelected.getSettings());
     this.incomingSettings.setActionCaption(this.incomingSettings.attributes.hasOwnProperty('action_caption') ? this.incomingSettings.getActionCaption() : '');
     this.caption = prompt('', this.incomingSettings.getActionCaption());
     if(this.caption !== null){
         this.incomingSettings.setActionCaption(this.caption);
         this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
         this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
         this.service.httpService('post', '/images/xxx/update',
             this.outgoingRequest.getAttributes(), {},(response: any) => {
                 this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
             }, (error: any) => {
             });
     }

 }



 setActionButton1(){
    this.incomingImgSelected.setSettings(this.incomingImgSelected.getSettings() instanceof Array ? {} : this.incomingImgSelected.getSettings());
    this.incomingSettings.setAttributes(this.incomingImgSelected.getSettings());
    this.incomingSettings.setActionButton1(this.incomingSettings.attributes.hasOwnProperty('action_button1') ?
    this.incomingSettings.getActionButton1() : '');
    this.caption = prompt('', this.incomingSettings.getActionButton1());
    if(this.caption !== null){
        this.incomingSettings.setActionButton1(this.caption);
        this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
        this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
        this.service.httpService('post', '/images/xxx/update',
            this.outgoingRequest.getAttributes(), {},(response: any) => {
                this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
            }, (error: any) => {
            });
    }
 }


 setActionButton2(){
     this.incomingImgSelected.setSettings(this.incomingImgSelected.getSettings() instanceof Array ? {} : this.incomingImgSelected.getSettings());
     this.incomingSettings.setAttributes(this.incomingImgSelected.getSettings());
     this.incomingSettings.setActionButton2(this.incomingSettings.attributes.hasOwnProperty('action_button2') ?
     this.incomingSettings.getActionButton2() : '');
     this.caption = prompt('', this.incomingSettings.getActionButton2());
     if(this.caption !== null){
         this.incomingSettings.setActionButton2(this.caption);
         this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
         this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
         this.service.httpService('post', '/images/xxx/update',
             this.outgoingRequest.getAttributes(), {},(response: any) => {
                 this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
             }, (error: any) => {
             });
     }
 }

    setEntity(outgoingRequest: any){
        this.service.httpService('post', '/entities/sampling',
            outgoingRequest.getAttributes(), {},(response: any) => {
            }, (error: any) => {
            });
    }



 setSubmitCaption(){
    this.incomingImgSelected.setSettings(this.incomingImgSelected.getSettings() instanceof Array ? {} : this.incomingImgSelected.getSettings());
    this.incomingSettings.setAttributes(this.incomingImgSelected.getSettings());
    this.incomingSettings.setSubmitCaption(this.incomingSettings.attributes.hasOwnProperty('submit_caption') ?
    this.incomingSettings.getSubmitCaption() : '');
    this.caption = prompt('', this.incomingSettings.getSubmitCaption());
    if(this.caption !== null){
        this.incomingSettings.setSubmitCaption(this.caption);
        this.outgoingRequest.setAttributes(Object.assign({}, this.incomingImgSelected.getAttributes()));
        this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
        this.service.httpService('post', '/images/xxx/update',
            this.outgoingRequest.getAttributes(), {},(response: any) => {
                this.incomingImgSelected.setAttributes(Object.assign(this.incomingImgSelected.getAttributes(), response));
            }, (error: any) => {
            });
    }
 }

}
