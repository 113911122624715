import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../../app.service';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() question;
  controls: any;
  constructor(private service: AppService) {
    this.controls = this.service.app.data.controls;
  }

  ngOnInit(): void {
  }

}
