<div id="main">
    <div class="">
        <center>
            <img  class="img-circle" src="assets/images/coat.png" alt="logo" style="width: 60px; height: 60px; margin-top: 10px">
<!--        <h2>Patten ! Get data you need at finguretips </h2>-->
        </center>
    </div>
    <div class="row" style="margin-top: 5%">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <section class="popular-categories" style="background: transparent !important;">
                <div class="container">
                    <div class="clearfix">
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-xs-12" (click)="onCellClicked(type)" *ngFor="let type of service.app.data.projects">
                            <div class="box"> <img src="assets/images/categories-icon-1.png" alt="img">
                                <h4><a>{{type.name}}</a></h4>
                                <strong>{{service.numberWithCommans((service.app.data.campaigns.data|jsonPropertyFilter:type.id.toString():'category_id').length)}}</strong>
                                <p>Available</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>

<div style="position: fixed; bottom: 5px; width: 100%">
    <center>
        <a href="coderafrica.co.tz">{{service.app.settings.copyright}}</a>
    </center>
</div>
