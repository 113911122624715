<div layout="row">
  <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
    <div class="panel-body">
      <div>
<!--        <mat-form-field  class="example-full-width" appearance="fill">-->
<!--          <textarea-->
<!--                  style="height: 200px"-->
<!--                  matInput-->
<!--                  type="text"-->
<!--                  placeholder="Description.."-->
<!--                  [(ngModel)]="question.label">-->
<!--          </textarea>-->
<!--        </mat-form-field>-->
        <angular-editor [placeholder]="''"
                         [(ngModel)]="question.label"
                         [config]="configAngularEditor">

         </angular-editor>
      </div>
      <div>
        <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
      </div>

    </div>
  </div>
</div>
