import { Component, OnInit } from '@angular/core';
import {AppService} from "../../../app.service";
import {AmbassadorsService} from "../../ambassadors.service";
import {FormService} from "../../../forms/form.service";
import {Parameters} from "../../../parameters";

@Component({
  selector: 'app-map-ambassador-orders',
  templateUrl: './map-ambassador-orders.component.html',
  styleUrls: ['./map-ambassador-orders.component.scss']
})
export class MapAmbassadorOrdersComponent implements OnInit {

  incomingForm: any;
  constructor(public service: AppService,
              private formService: FormService,
              private ambassadorService: AmbassadorsService) {
    this.incomingForm = new Parameters();
  }

  ngOnInit(): void {

  }



}
