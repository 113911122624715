<div layout="row" *ngIf="!incomingResponse2.getAttributes().hasOwnProperty('id')">
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input  matInput
                                #emailElement
                                class="sawasdee26"
                                type="email"
                                autocomplete="off"
                                placeholder="{{question.placeholder}}"
                                [(ngModel)]="service.isNull(question.data) === false && question.hasOwnProperty('default') ? question.default : question.data"
                                [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                                #message />
                        <!--        <i class="material-icons pull-right">insert_emoticon</i>-->
                    </mat-form-field>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                     *ngIf="service.parseFloat(incomingQuestionSettings.getIsScanning())===1">
                    <center>
                        <button mat-button (click)="checkIsScanning()"
                                class="mdl-shadow--2dp"
                                [ngStyle]="{'background':'#fff','margin-top':'50px'}"
                        >Verify your attendance</button>
                    </center>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
                    <center>
                        <app-next-button></app-next-button>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
</div>

<div *ngIf="incomingResponse2.getAttributes().hasOwnProperty('id')">
<!--    <i ngClass="material-icons pull-right" [ngStyle]="{'color':'#fff'}">cancel</i><br><br>-->
    <ng-container *ngIf="service.parseFloat(incomingResponse2.getId()) !== 0">
        <center>
            <img
                    ngClass="img-cirle"
                    src="assets/images/done.png"
                    [ngStyle]="{'width':'200px','height':'200px'}" />
        </center>
    </ng-container>
    <ng-container *ngIf="service.parseFloat(incomingResponse2.getId()) === 0">
        <center>
            <img
                    ngClass="img-circle"
                    src="assets/images/close.jpg"
                    [ngStyle]="{'width':'200px','height':'200px'}"/>
        </center>
    </ng-container>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <center>
            <button mat-mini-fab (click)="
                     incomingResponse2.setAttributes({});
                     responseService.response2 = {};emailElement.value = ''"
                    class=""
                    [ngStyle]="{'color':'#000','background':'#fff','margin-top':'50px'}"
            >
                <i class="material-icons">keyboard_arrow_down</i>
            </button>
        </center>
    </div>
</div>
