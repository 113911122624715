import {AfterContentInit, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {AppService} from "../../../../app.service";
import {Parameters} from "../../../../parameters";


@Component({
  selector: 'app-bar-chartjs',
  templateUrl: './bar-chartjs.component.html',
  styleUrls: ['./bar-chartjs.component.scss']
})
export class BarChartjsComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() element: any;
  @Input() color: any;
  @Input() labels: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() barThickness: any;
  @Input() height: any;
  @Input() grouped: any;
  @Input() fill: any;
  @Input() borderRadius: any;
  @Input() dashed: any;
  @Input() xGridlines: any;
  @Input() xAxis: any;
  @Input() option: any;
  @Input() labelRotation: any;
  @Input() options: any;
  transparentColor: any;
  public canvas: any;
  public ctx;
  incomingOption: any;
  constructor(private service: AppService) {
   this.incomingOption = new Parameters();
  }

  ngOnInit(): void {
   this.barThickness === undefined ? 1 : this.barThickness;
   this.xAxis = this.xAxis === undefined ? true : this.xAxis;
   this.borderRadius = this.borderRadius === undefined ? 0 : this.borderRadius;
   this.fill = this.fill === undefined ? false : this.fill;
   this.dashed = this.dashed === undefined ? 0 : 5;
   this.xGridlines = this.xGridlines === undefined ? true : this.xGridlines;
   this.incomingOption.setAttributes(this.option === undefined ? {} : Object.assign({},this.option));
   this.labelRotation =
     this.incomingOption.attributes.hasOwnProperty('cntrl_num') &&
     this.incomingOption.attributes.hasOwnProperty('text_rotation_angle') &&
     !this.service.isNull(this.incomingOption.getTextRotationAngle()) ?
     parseFloat(this.incomingOption.getTextRotationAngle()) : this.labelRotation;

  }

  getColor(){
    if(this.color.toString() === 'red') this.color = '#ec250d';
    if(this.color.toString() === 'blue') this.color = 'rgb(82, 136, 255)';
    this.color = this.color.toString() === 'green' ? '#00d6b4' : this.color;
    return this.color;
  }
 // '#1f8ef1'
  getTransparentColor(){
    if(this.color.toString() === 'red')  this.transparentColor = 'rgba(233,32,16,0.2)';
    if(this.color.toString() === 'blue') this.transparentColor = this.type === 'line' ? 'rgba(29,140,248,0.2)' : 'rgb(82, 136, 255)';
    this.transparentColor = this.color.toString() === 'green' ? '#96CA59' : this.transparentColor;
    return this.transparentColor;
  }

  getLabels(){
    this.labels = this.labels.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString(), 3).toUpperCase().replace('..', '') :
          this.service.limitTo(label.toString().toUpperCase(), this.limit)); });
    return this.labels;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      const gradientBarChartConfiguration: any = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        plugins : {
           legend: {
              display: false
            },
            labels: []
        },
        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest'
        },
        responsive: true,
        scales: {
          yAxes: [{
            display: false,
            gridLines: {
              display: false,
              drawBorder: false,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              // suggestedMin: 0,
              // suggestedMax: 120,
              padding: 20,
              fontColor: '#9e9e9e'
            }
          }],

          xAxes: [{
            display: this.xAxis,
            gridLines: {
              display: this.xGridlines,
              drawBorder: false,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#5f6368',
              fontFamily: 'Google Sans',
              fontSize: 10,
            }
          }]
        }
      };
      if(this.labelRotation !== undefined && !this.service.isNull(this.labelRotation)){
        gradientBarChartConfiguration.scales.xAxes[0].ticks.autoSkip = false;
        gradientBarChartConfiguration.scales.xAxes[0].ticks.maxRotation = this.labelRotation;
        gradientBarChartConfiguration.scales.xAxes[0].ticks.minRotation = this.labelRotation;
      }

      if(this.barThickness !== undefined){
         gradientBarChartConfiguration.scales.xAxes[0].maxBarThickness = this.barThickness;
      }

      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke: any = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, this.getTransparentColor());
      gradientStroke.addColorStop(0.4, this.getTransparentColor());
      gradientStroke.addColorStop(0, this.getTransparentColor()); //blue colors
      const opt: any = {
        type: this.type,
        responsive: true,
        legend: {
          display: false,
          show: false
        },
        data: {
          labels: this.getLabels(),
          datasets: this.getDatasets(gradientStroke)
        },
        options: gradientBarChartConfiguration
      };

      const myChart: any = new Chart(this.ctx, opt);
    });
  }

  getDatasets(gradientStroke: any){
    let result: any = [];
    if(this.grouped === undefined){
      result = [{
        label: this.label,
        fill: this.fill,
        backgroundColor: gradientStroke,
        hoverBackgroundColor: gradientStroke,
        borderColor: this.getColor(),
        borderWidth: 2,
        borderRadius: this.borderRadius,
        borderDash: [this.dashed],
        borderDashOffset: 0.0,
        data: this.data,
        lineTension: 0.1,
        borderSkipped: false,
        datalabels: {
            display: true
        }
      }];
      if(this.options !== undefined){
       this.options.map((option: any, incremental: any) => {
        this.incomingOption.setAttributes();
        if(this.incomingOption.attributes.hasOwnProperty('chart')){
        }
       });
      }
    }else{
      this.data.map((d: any, key: any) => {
        result.push({
              label: d.label,
              fill: true,
              backgroundColor: gradientStroke,
              hoverBackgroundColor: gradientStroke,
              borderColor: this.getColor(),
              borderWidth: 2,
              borderRadius: this.borderRadius,
              borderDash: [this.dashed],
              borderDashOffset: 0.0,
              data: d.data,
            }
        );
      });
    }
    return result;
  }


  ngAfterContentInit(): void {
  }

}
