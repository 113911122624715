import { Component, OnInit } from '@angular/core';
import {FormService} from "../form.service";

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  constructor(public formService: FormService) { }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ebebeb';
  }

  iframe(width:any, height: any, src: any){
    // return '<iframe width="' + width + '" height="' + height + '" src=""></iframe>';
    return   `
        <iframe
          class="container"
          width="` + width + `"
          height="` + height + `"
          src ="` + src + `"
          >
         
        </iframe>`;
  }

}
