import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
@Component({
    selector: 'app-highchart-piechart',
    templateUrl: './highchart-piechart.component.html',
    styleUrls: ['./highchart-piechart.component.scss']
})
export class HighchartPiechartComponent implements OnInit {

    @Input() data: any;
    @Input() legends: any;
    @Input() type: any;
    Highcharts: typeof Highcharts = Highcharts;
    updateFlag = true;
    chartOptions: any;

    constructor() {
    }

    ngOnInit(): void {
        this.chartOptions = this.options();
    }

    options(){
        const opt: any = {
            chart: {
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
             // plotAreaWidth: 20,
             // plotAreaHeight: 20,
                plotBackgroundColor: 'transparent',
                plotBorderWidth: 0,
                plotShadow: false,
                width: 400,
                height: 400,
                type: 'pie'
            },
            colorAxis: {},
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: this.fill()
            }]
        };
        return opt;
    }

    fill(){
        let results: any = [];
        this.data.map((d: any, key: any) => {
            results.push({
                name: this.legends[key],
                y: parseFloat(this.data[key]),
                sliced: false,
                selected: true,
                color: this.getColors()[key].color
            });
        });
        return results;
    }

    getColors(){
        return [{
            name: 'Chrome',
            y: 61.41,
            sliced: true,
            selected: true,
            color: '#0084ff'
        }, {
            name: 'Internet Explorer',
            y: 11.84,
            color: '#00ca00'
        }, {
            name: 'Firefox',
            y: 10.85,
            color: '#e64141'
        }, {
            name: 'Edge',
            y: 4.67,
            color: '#ffd400'
        }, {
            name: 'Safari',
            y: 4.18,
            color: '#00d0ff'
        }, {
            name: 'Sogou Explorer',
            y: 1.64,
            color: '#374948'
        }, {
            name: 'Opera',
            y: 1.6,
            color: '#E9EDF4'
        }, {
            name: 'QQ',
            y: 1.2,
            color: '#777D74'
        }, {
            name: 'Other',
            y: 2.61,
        }];
    }
}



