import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-dialog-disable-close-display-text',
  templateUrl: './dialog-disable-close-display-text.component.html',
  styleUrls: ['./dialog-disable-close-display-text.component.scss']
})
export class DialogDisableCloseDisplayTextComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogDisableCloseDisplayTextComponent>) {
  }

  ngOnInit(): void {
  }

}
