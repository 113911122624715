import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {CampaignService} from '../campaign.service';
import {CampanyService} from '../../campanies/campany.service';
import {ClientService} from '../../clients/client.service';
import {FormService} from "../../forms/form.service";

@Component({
  selector: 'app-table-campaigns',
  templateUrl: './table-campaigns.component.html',
  styleUrls: ['./table-campaigns.component.scss']
})
export class TableCampaignsComponent implements OnInit, OnDestroy {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedCampaign: any;
  campaigns: any;
  incomingSelectedCampaign: any;
  subscription: any;
  searchCntrl: any;
  clients: any;
  strings: any;
  constructor(public  service: AppService,
              public  dialog: MatDialog,
              public  broadcastChannel: BroadcastChannelService,
              private router: Router,
              private formService: FormService,
              public  campanyService: CampanyService,
              public clientService: ClientService,
              public campaignService: CampaignService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.incomingSelectedCampaign = new Parameters();
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
    this.campaigns = this.app.data.campaigns;
  }

  ngOnInit(): void {
    document.title = this.service.header;
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty(this.strings.getSearchText()) === true) {
            this.searchCntrl = item.searchText;
            if(!(item.hasOwnProperty(this.strings.getStatus()) && item[this.strings.getStatus()] === 0))
            this.campaignService.search(this.searchCntrl, () => {});
          }else if(item.hasOwnProperty('campaign') === true){
            this.campaignService.campaignSelectedId = item.campaign.id;
          }
        }else if(item.toString().includes('register')){
          this.campaignService.setSelected({});
          this.campaignService.openDialog({});
        }

      });
  }

  getCampaignsBySearchText(searchText: any){
    return this.service.app.data.campaigns.data.filter((campaign: any) => JSON.stringify(campaign).toLowerCase().includes(searchText));
  }

  materials(){return this.service.app.data.campaigns.data;}
  onDoubleClickablecampaign(campaign: any){
    this.router.navigateByUrl('/app/' + campaign.toString().toLowerCase() + '/' +
        this.service.encrypt(campaign.id) + '/forms');
    this.formService.campaignId = campaign.id;
    this.service.status = -1;
    this.service.module = 'forms';
    setTimeout(() =>{
      document.title = campaign.name;
      this.service.header = campaign.nmae;
      this.service.modules = [{title: 'Forms', path: window.location.href.toString().split(window.location.host)[1]}];
    });
    // this.router.navigateByUrl('/app/' +
    //     (this.service.header.toString().toLowerCase().includes('project') ? 'projects' : 'campaigns') + '/' +
    //     this.service.ecrypt(campaign.id));
  }

  getClient(campaign: any){
    let client: any = this.clients.filter((client: any) => { return client.id === campaign.client_id; })[0];
    campaign = Object.assign(campaign, {client: {name: client.name, phone: client.phone, email: client.email}});
    return client;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  handleDestroy(inspector: any){
    this.incomingSelectedCampaign = new Parameters();
    this.incomingSelectedCampaign.setAttributes(this.selectedCampaign);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.campaigns,
        model: this.selectedCampaign,
        title: this.incomingSelectedCampaign.getName(),
        url  : '/campaigns/' + parseFloat(this.incomingSelectedCampaign.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedCampaign.getId(),
      },
      width : 'auto',
    });
  }

  handleCopy(){document.execCommand('copy');}

  handleUpdate(inspector: any){
    this.incomingSelectedCampaign = new Parameters();
    this.incomingSelectedCampaign.setAttributes(this.selectedCampaign);
    this.campaignService.setSelected(this.incomingSelectedCampaign.getAttributes());
    this.campaignService.openDialog({});
  }

  handleRestore(inspector: any){
    this.incomingSelectedCampaign.setAttributes(this.selectedCampaign);
    this.service.restore('/campaigns/' + parseFloat(this.incomingSelectedCampaign.getId()) + '/restore',
      this.campaigns, 'id', parseFloat(this.incomingSelectedCampaign.getId()), this.selectedCampaign);
  }

  showMessage(message: any) {
  }

}
