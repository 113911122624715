import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import {AppService} from '../../../app.service';
import {MatDrawer } from '@angular/material/sidenav';
import {Parameters} from '../../../parameters';
import {ClientService} from '../../../clients/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseService} from '../../../forms/responses/data/response.service';
import {FormService} from '../../../forms/form.service';
import {NotifierService} from 'angular-notifier';
import * as html2pdf from 'html2pdf.js';
import {jsPDF} from 'jspdf';
import {MatDialog} from '@angular/material/dialog';
import {ProgressDialogComponent} from '../../../main/progress-dialog/progress-dialog.component';
import {DialogDisableCloseDisplayTextComponent} from '../../../main/dialg/dialog-disable-close-display-text/dialog-disable-close-display-text.component';
import {BroadcastChannelService} from '../../../broadcast-channel.service';

@Component({
  selector: 'app-view-invitation-card',
  templateUrl: './view-invitation-card.component.html',
  styleUrls: ['./view-invitation-card.component.scss']
})
export class ViewInvitationCardComponent implements OnInit {
  @ViewChild('drawer', {static: false})  drawer: any;
//@ViewChild('h1DOCHtml' , {static: false})  h1DOCHtml: ElementRef;
  cardContents: any = '';
  incomingResponse: any;
  incomingForm: any;
  selectedTabIndex: any;
  incomingOption: any;
  incomingIsConfirm: any;
  logo: any;
  incomingEmailQuestion: any;
  responseId: any;
  options: any = [];
  formId: any;
  showDeclinenote: any = true;
  incomingInvitationCardQuestion: any;
  incomingQuestion: any;
  incomingQrcodeQuestion: any;
  dialogRef: any;
  progressShow: any = true;
  incomingSettingsForm; any;
  dateSpliter: any;
  invitationConfirmationUrl: any;
  colWidth: any = 8;
  c: any  = true;
  pdfDirUrl = 'assets/json/';
  outgoingRequest: any;
  incomingResult: any;
  incomingClient: any;
  contentFlag: any = true;
  windowHeight: any;
  incomingParams: any;
  constructor(  public service: AppService,
                public responseService: ResponseService,
                private broadcastChannel: BroadcastChannelService,
                private dialog: MatDialog,
                public zone: NgZone,
                private notifier: NotifierService,
                private formService : FormService,
                private clientService : ClientService,
                public  router: Router,
                private activatedRoute: ActivatedRoute) {
     this.incomingResponse = new Parameters();
     this.incomingForm = new Parameters();
     this.incomingOption = new Parameters();
     this.outgoingRequest = new Parameters();
     this.incomingParams = new Parameters();
     this.incomingEmailQuestion = new Parameters();
     this.incomingInvitationCardQuestion = new Parameters();
     this.incomingQuestion = new Parameters();
     this.incomingIsConfirm = new Parameters();
     this.incomingQrcodeQuestion = new Parameters();
     this.incomingSettingsForm = new Parameters();
     this.incomingResult = new Parameters();
     this.incomingClient = new Parameters();
     document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ffff';
     this.incomingIsConfirm.setAttributes({});
     this.incomingParams.setAttributes({});
  }

  ngOnInit(): void {
//  this.formId = this.service.decrypt(this.activatedRoute.snapshot.params.formId);
//  this.responseId =  this.service.decrypt(this.activatedRoute.snapshot.params.responseId);
    this.drawer = MatDrawer;
    this.incomingOption.setAttributes({});
    this.incomingOption.setLabel('yes');
    this.options.push(this.incomingOption.getAttributes());
    this.incomingOption.setAttributes({});
    this.incomingOption.setLabel('no');
    this.options.push(this.incomingOption.getAttributes());
//  this.setInvitees({});
    this.incomingResponse.setAttributes(this.responseService.getSelected());
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
    this.incomingClient.setAttributes(this.clientService.getSelected());
    this.progressShow = true;
    this.incomingSettingsForm.setDateOnSet(this.incomingSettingsForm.attributes.hasOwnProperty('date_on_set') ?
      this.incomingSettingsForm.getDateOnSet() : '');
    this.incomingSettingsForm.setVenue(this.incomingSettingsForm.attributes.hasOwnProperty('venue') ?
      this.incomingSettingsForm.getVenue() : '');
    this.incomingSettingsForm.setBackgroundColor(this.incomingSettingsForm.attributes.hasOwnProperty('background_color') ?
      this.incomingSettingsForm.getBackgroundColor() : '');
    this.incomingSettingsForm.setJobPosition(this.incomingSettingsForm.attributes.hasOwnProperty('job_position') ?
      this.incomingSettingsForm.getJobPosition() : '');
    this.incomingSettingsForm.setTime(this.incomingSettingsForm.attributes.hasOwnProperty('time') ?
      this.incomingSettingsForm.getTime() : '');
    this.incomingSettingsForm.setBanner(this.incomingSettingsForm.attributes.hasOwnProperty('banner') ?
        this.incomingSettingsForm.getBanner() : '');
    this.incomingSettingsForm.setAttendingDate(this.incomingSettingsForm.attributes.hasOwnProperty('attending_date') ?
      this.incomingSettingsForm.getAttendingDate() : '');
    this.incomingSettingsForm.setContact(this.incomingSettingsForm.attributes.hasOwnProperty('contact') ?
      this.incomingSettingsForm.getContact() : '');
    this.incomingSettingsForm.setAfterAcceptLandingPageContents(this.incomingSettingsForm.attributes.hasOwnProperty('after_accept_landing_page_contents') ?
      this.incomingSettingsForm.getAfterAcceptLandingPageContents() : '');
    this.incomingSettingsForm.setRSVPDate(this.incomingSettingsForm.attributes.hasOwnProperty('rsvp_date') ?
      this.incomingSettingsForm.getRSVPDate() : '');
    this.incomingSettingsForm.setDescription(this.incomingSettingsForm.attributes.hasOwnProperty('description') ?
      this.incomingSettingsForm.getDescription() : '');
    this.incomingSettingsForm.setContactPersonName(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_name') ?
       this.incomingSettingsForm.getContactPersonName() : '');
    this.incomingSettingsForm.setIsConfirm(this.incomingSettingsForm.hasAttribute('is_confirm') ?
       this.incomingSettingsForm.getIsConfirm() : '');
    this.incomingSettingsForm.setContactPersonPhone(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_phone') ?
       this.incomingSettingsForm.getContactPersonPhone() : '');
    this.incomingSettingsForm.setContactPersonTitle(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_title') ?
      this.incomingSettingsForm.getContactPersonTitle() : '');
    this.incomingSettingsForm.setCompanyName(this.incomingSettingsForm.attributes.hasOwnProperty('company_name') ?
      this.incomingSettingsForm.getCompanyName() : '');
    this.incomingSettingsForm.setInvitationCard(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_card') ?
      this.incomingSettingsForm.getInvitationCard() : '');
    this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
       this.incomingSettingsForm.getAttendeeName() : '');
    this.incomingSettingsForm.setSalutation(this.incomingSettingsForm.attributes.hasOwnProperty('salutation') ?
      this.incomingSettingsForm.getSalutation() : '');
    this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
           this.incomingSettingsForm.getLogoUrl() : '');
    this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
        this.incomingSettingsForm.getLogoDataUrl() : '');
    this.setQuestions((result: any)=>{
       this.cardContents = this.incomingInvitationCardQuestion.getLabel();
       this.incomingSettingsForm.setAfterAcceptLandingPageContents(this.incomingSettingsForm.getAfterAcceptLandingPageContents().replaceAll('*name', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data));
       this.incomingSettingsForm.setAfterAcceptLandingPageContents(this.incomingSettingsForm.getAfterAcceptLandingPageContents().toString()
                .replaceAll('*logo', this.incomingClient.getUnknown('logo')));
       document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingSettingsForm.getBackgroundColor();
       document.getElementsByTagName( 'body')[0].style.color = '#fff';
       if(this.incomingIsConfirm.getCntrlNum().toString().includes(this.incomingSettingsForm.getIsConfirm().toString())){
          if(this.incomingIsConfirm.getData().toLowerCase().includes('yes')){
           this.responseService.isConfirmed = true;
          }
       }
        this.download();
       // this.getClientlogo((result: any)=>{
       //  if(!this.responseService.isConfirmed){
       //      this.progressShow = false;
       //   // this.download();
       //  }else window.open('/app/invitations/'+this.service.encrypt(this.incomingResponse.getPostId())+'/confirmation?access='+this.incomingClient.getUnknown('logo')+'&extension='+this.incomingSettingsForm.getBackgroundColor(),'_self')
       // });
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getLabel());
//        this.dateSpliter = this.incomingSettingsForm.getDateOnSet().split('-');
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display1', ''));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display2', 'none'));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display3', 'none'));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*display4', 'none'));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*name', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*date', this.incomingSettingsForm.getAttendingDate()));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*time', this.incomingSettingsForm.getTime()));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*description', this.incomingSettingsForm.getDescription()));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*salutation', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*company', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getCompanyName()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*month', this.dateSpliter[1]));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonName', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonName()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonPhone', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonPhone()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*contactPersonTitle', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getContactPersonTitle()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*event', this.incomingForm.getName()));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*venue', this.incomingSettingsForm.getVenue()));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*logo', this.service.empty(this.incomingSettingsForm.getLogoDataUrl()) ? '*logo' :this.incomingSettingsForm.getLogoDataUrl() ));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*jobPosition', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getJobPosition()).data));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*attendanceConfirmationUrl', this.invitationConfirmationUrl));
//        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getData().toString().replaceAll('*qrcode', this.incomingQrcodeQuestion.getData()));
//     this.getDataUrlByFileUrl(this.incomingQrcodeQuestion.getData(), (result)=>{});
//     setTimeout(() =>{ this.download(); },this.service.timeout());
    });
  }

  getOptions(){
      const opt: any = {
        margin: 1,
        filename: 'invitation-card.pdf',
        image: { type: 'jpeg', quality: 0.20 },
        html2canvas: {scale: 7, logging: true},
//      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
      };
  }

  onRadioButtonChange(option: any){
     this.incomingOption.setAttributes(option);
     if(this.incomingOption.getUnknown('label').toString().toLowerCase().includes('yes')){
//        this.outgoingNameQuestion.setAttributes(Object.assign({},this.incomingNameQuestion.getAttributes()));
//        this.outgoingPhoneQuestion.setAttributes(Object.assign({},this.incomingPhoneQuestion.getAttributes()));
//        this.outgoingEmailQuestion.setAttributes(Object.assign({},this.incomingEmailQuestion.getAttributes()));
//        this.outgoingQrcodeQuestion.setAttributes(Object.assign({},this.incomingQrcodeQuestion.getAttributes()));
//        this.outgoingInvitationQuestion.setAttributes(Object.assign({},this.incomingConfirmationContentsQuestion.getAttributes()));
//        this.outgoingInvitationCardQuestion.setAttributes(Object.assign({},this.incomingInvitationCardQuestion.getAttributes()));
//        this.outgoingNameQuestion.setUnknown('data','');
//        this.outgoingPhoneQuestion.setUnknown('data','');
//        this.outgoingEmailQuestion.setUnknown('data','');
//        this.outgoingQrcodeQuestion.setUnknown('data','');
//        this.outgoingInvitationQuestion.setUnknown('data',this.incomingConfirmationContentsQuestion.getUnknown('label'));
//        this.outgoingInvitationCardQuestion.setUnknown('data',this.incomingInvitationCardQuestion.getUnknown('label'));
//        this.outgoingQrcodeQuestion.setResult({path:'',src:''});
       this.drawer.toggle();
     }else{
      this.showDeclinenote = false;
      this.notifier.notify('success', this.service.app.strings.invitation.decline.note);
     }
  }


  getHeight(){
   setTimeout(() => {
       if(!this.progressShow){
         let h1DOCHtml: any = document.getElementById('h1DOCHtml');
         if(h1DOCHtml)
           console.log(h1DOCHtml.pageYOffset);
       }
   },this.service.timeout());
  }

  getDataUrlByFileUrl(url: any, callback: any){
     setTimeout(() =>{
         const images: any = document.getElementsByClassName('img');
     });
//   for (var i = 0; i < nextSlides.length; i++) {
//              const slide = nextSlides[i];
//              if (slide instanceof HTMLElement) {
//                  slide.addEventListener('click', () => {
//                      this.incomingQuestion.setAttributes(Object.assign({}, this.question));
//                      this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
//                      this.images = this.incomingSettings.getImages();
//                      this.imageCurrentPosition += 1;
//                      this.setSelectedImage(this.incomingSettings.attributes.images[this.imageCurrentPosition]);
//
//                  });
//             }
//    }

//    fetch(url)
//         .then(response => response.blob())
//         .then(imageBlob => {
//             // Then create a local URL for that image and print it
//             const imageObjectURL = URL.createObjectURL(imageBlob);
//       });
  }

    getClientlogo(callback: any){
         if(this.incomingEmailQuestion.getAttributes().hasOwnProperty('id') &&
            !this.service.isNull(this.incomingEmailQuestion.getData()) &&
            this.incomingEmailQuestion.getData().toString().includes('@gmail.comx') &&
            !this.service.isNull(this.incomingClient.getUnknown('logo'))){
           this.logo = this.incomingClient.getUnknown('logo');
           callback(null);
         }else if(!this.service.isNull(this.incomingClient.getUnknown('logo'))){
           this.outgoingRequest.setAttributes({});
           this.outgoingRequest.setUrl(this.incomingClient.getUnknown('logo'));
           this.service.httpService('post', this.service.app.routes.files.base64,
            this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},(result: any) => {
              this.logo = 'data:image/jpg;base64,'+result;
              callback(null);
            }, (error: any) => { callback(null);});
         }else callback(null);
  }

  download(){
       this.colWidth = 12;
       setTimeout(()=>{
           let doc: any = document.getElementById('doc');
                html2pdf()
                    .from(doc)
                    .set(this.getOptions())
                    .toPdf()
                    .output('datauristring')
                    .then((pdfBase64: any) => {
                       let blob: any = this.service.dataURItoBlob(pdfBase64);
                       this.outgoingRequest.setAttributes({});
                       this.outgoingRequest.setSrc(null);
                       this.outgoingRequest.setFlag(0);
                       this.outgoingRequest.setCaption('');
                       this.outgoingRequest.setOrderBy('');
                       this.outgoingRequest.setStatus(1);
                       this.outgoingRequest.setUrl('');
                       this.outgoingRequest.setPath('');
                       this.outgoingRequest.setAvatar(blob);
                       this.outgoingRequest.setExtension('pdf');
                       this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
                          this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
                           this.incomingResult.setAttributes(result);
                           this.outgoingRequest.setAttributes({});
                           this.outgoingRequest.setFlag('whatsapp');
                           this.outgoingRequest.setResponseId(this.incomingResponse.getId());
                           this.outgoingRequest.setFormId(this.incomingForm.getId());
                           this.outgoingRequest.setData(this.service.app.strings.invitation.card.receive.toString());
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*logo','*logo'));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*event',this.incomingForm.getName()));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*venue', this.incomingSettingsForm.getVenue()));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*time', this.incomingSettingsForm.getTime()));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*name', this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*attendingDate', this.incomingSettingsForm.getAttendingDate()));
                           this.outgoingRequest.setData(this.outgoingRequest.getData().toString().replaceAll('*regards', this.incomingSettingsForm.getContact()));
                           this.outgoingRequest.setClientName(this.incomingClient.getName());
                           this.outgoingRequest.setLogoDataUrl(this.logo);
                           this.outgoingRequest.setEventName(this.incomingForm.getName() +': Invitation card');
                           this.outgoingRequest.setRecipientEmail(this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeEmail()).data);
                           this.outgoingRequest.setSenderEmail(this.incomingClient.getEmail());
                           this.outgoingRequest.setRecipientPhone(this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeePhone()).data);
                           this.outgoingRequest.setPdfUrl(this.incomingResult.getSrc());
                           this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
                            this.outgoingRequest.setFlag('email');
                            this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
                               this.zone.run(() =>{
                                   this.progressShow = false;
                                   this.cardContents = this.incomingSettingsForm.getAfterAcceptLandingPageContents();
                               });
                            });
                           });
                         }, (error: any) => {
                         });
                 });

       },this.service.timeout() * parseFloat('12'));
  }

  sendInvitationCard(request: any,callback: any){
      this.outgoingRequest.setAttributes(request);
      this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
      this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true',notify: 'false'},(result: any) => {
       callback();
      }, (error: any) => {
       callback();
      });
  }

  addEntity(){
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setSample('response.invitationCards');
      this.outgoingRequest.setEntity1(this.incomingResponse.getId());
//    this.outgoingRequest.setEntity2(this.incomingPoweredBySelected.getId());
//    this.outgoingRequest.setId(this.incomingPoweredBySelected.getFormImageId());
      this.service.httpService('post', this.service.app.routes.entities.delete, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (response: any) => {
          this.incomingForm.attributes.settings.logo.splice(this.service.findIndex(this.incomingForm.attributes.settings.logo, 'form_image_id', this.outgoingRequest.getId()), 1);
          this.notifier.notify('success', 'Successfull deleted');
      }, (error: any) => {
      });
  }


  setQuestions(callback: any){
     this.incomingInvitationCardQuestion.setAttributes({});
     this.incomingQrcodeQuestion.setAttributes({});
     this.incomingEmailQuestion.setAttributes({});
     this.incomingIsConfirm.setAttributes({});
     this.incomingForm.getControls().map((question: any)=>{
      this.incomingQuestion.setAttributes(Object.assign({},question));
      if(this.incomingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationCard()){
       this.incomingInvitationCardQuestion.setAttributes(this.incomingQuestion.getAttributes());
      }else if(parseFloat(this.incomingQuestion.getControlId()) === 33){
        this.incomingQrcodeQuestion.setAttributes(this.incomingQuestion.getAttributes());
      }else if(this.incomingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeEmail()){
        this.incomingEmailQuestion.setAttributes(this.incomingQuestion.getAttributes());
      }else if(this.incomingSettingsForm.getIsConfirm().toString().includes(this.incomingQuestion.getCntrlNum())){
        this.incomingIsConfirm.setAttributes(this.incomingQuestion.getAttributes());
      }
     });callback(null);
  }

}
