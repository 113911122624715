import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {RegionsService} from './regions.service';
import {DialogRegionalComponent} from './dialog/dialog-regional.component';

@Component({
  selector: 'app-regionals',
  templateUrl: './regionals.component.html',
  styleUrls: ['./regionals.component.scss']
})
export class RegionalsComponent implements OnInit {
  app: any;
  constructor(public serviceImpl: AppService,
              public regionalImpl: RegionsService,
              public dialog: MatDialog) {
    this.app = this.serviceImpl.app;
    this.app.form.modules  = [{path: 'app/regions', title: 'Regions'}];
    this.app.form.subtitle = this.app.settings.descriptions.species;
    this.app.form.module = 'regions';
  }

  ngOnInit(): void {
    this.regionalImpl.setData(this.app.data.regions);
  }

  openDialog() {
    this.dialog.open(DialogRegionalComponent, {data: {}, width: '55%', height: '95%'});
  }

}
