import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {CampaignService} from '../campaigns/campaign.service';
import {DialogTeamsComponent} from "./dialog/dialog-teams.component";
import {AppService} from "../app.service";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  teamSelectedId: any = undefined;
  /**
   * @param mixed 
   */
  report: any;
  /**
   * @param mixed 
   */
  data: any = [];

  /**
   * @param mixed
   */

  selected: any = {};

  /**
   * @param mixed
   */

  isDialogOpen: any = false;
  /**
   * @param mixed
   */

  getData(): void {
    return this.data;
  }

  /**
   * @param mixed
   */


  setData(data: any): void {

    this.data = data;

  }

  getSelected(): void {

    return this.selected;

  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {

    this.selected = selected;

  }


  constructor(public campignService: CampaignService,
              private dialog: MatDialog,
              private systemService: AppService) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('teams');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }

  getTeamsByCampaigns(campaigns: any){
    let teams: any = {};
    teams     = {data: campaigns.data.map((campaign: any) => { return campaign.teams.data; }).flat(1),
      trashed:  {data: campaigns.data.map((campaign: any) => { return campaign.teams.trashed.data; }).flat(1)}};
    return teams;
  }

  getTeamByTeam(team: any){
    var teams: any = [] = this.systemService.app.data.teams.data.filter((item: any) => {
      return parseFloat(item.id) === parseFloat(team.id); });
    return teams.length === 0 ? this.addTeam(team) : teams[0];
  }

  addTeam(team: any){
    this.systemService.app.data.teams.data.unshift(team);
    return team;
  }

  getTeamById(teamId: any){
    var teams: any = [] = this.systemService.app.data.teams.data.filter((team: any) => {
      return parseFloat(team.id) === parseFloat(teamId);});
    return teams[0];
  }
  
  getTeamsByCampaignId(campaignId: any){
      var teams: any = [] = this.systemService.app.data.teams.data.filter((team: any) => {
        return parseFloat(team.campaign_id) === parseFloat(campaignId);});
      return teams;
  }


  search(searchText: any){
    this.systemService.httpService(
        'post',
        '/teams/search',
        {searchText: searchText}, {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            response.map((item: any) => {
              result = [];
              result = this.systemService.app.data.teams.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });

              if(result.length === 0){
                this.systemService.app.data.teams.data.push(item);
              }
            });
          });
        }, (error: any) => {
        });
  }

  openDialog(params: any) {
    setTimeout(() => {
      params = new Parameters();
      params.setAttributes({});
      const dialogRef = this.dialog.open(DialogTeamsComponent, {
        data  : {params: params.getAttributes()},
        width : '55%',
        height: '100%',
        disableClose: true,
      });
    });
  }
}
