<section id="inner-banner" [ngStyle]="{'background-color':'rgb(16,30,81)','color':'#fff'}">
    <div class="container">
        <h1>Enquire About Exhibiting & Sponsoring</h1>
    </div>
</section>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12" [ngStyle]="{'font-size':'16px','padding-top':'20px'}">
<!--        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
<!--            <div>-->
<!--                Showcase your solutions, collaborate and drive growth by exhibiting at the sole event where public sector buyers, decision-makers, and key figures convene to solve the sector’s most pressing technology-related challenges.-->
<!--            </div>-->
<!--            <div>-->
<!--                By taking your place on the exhibition floor, you will have the platform to build valuable relationships, streamline the procurement process and win high-value public sector contracts.-->
<!--            </div>-->
<!--            <div>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                        Connect with buyers-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        Connect with buyers-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        Generate high-quality leads-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        Increase brand exposure-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        Enhance your credibility-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div>-->
<!--                Talk to our team to learn more about our event and our various exhibition and sponsorship packages. Complete our enquiry form and our team will be in touch!-->
<!--            </div>-->
<!--        </div>-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table>
                <tr *ngFor="let question  of questions">
                    <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 12; else choice">
                        <td ngClass="text-uppercase"
                            [colSpan]="2">
                            {{question[strings.getLabel()]}} <br><br><br>
                        </td>
                    </ng-container>
                    <ng-template #choice>
                        <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 5; else checkbox">
                            <td [id]="question[this.strings.getIndex()]">
                                {{question[strings.getLabel()]}} <br>
                                <mat-radio-group
                                        aria-labelledby="example-radio-group-label"
                                        class="example-radio-group">
                                    <mat-radio-button
                                            class="example-radio-buttonn"
                                            #checked
                                            (change)="onChecked(question, option, checked)"
                                            *ngFor="let option of question[strings.getOptions()]"
                                            [value]="option">
                                        <a class="" [innerHTML]="option[strings.getLabel()]"></a>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </td>
                        </ng-container>
                    </ng-template>
                    <ng-template #checkbox>
                        <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 3; else address">
                            <td [id]="question[this.strings.getIndex()]">
                                <span [innerHTML]="question[strings.getLabel()]"></span> <br>
                                <section class="example-section">
                                    <p *ngFor="let option of question[strings.getOptions()]">
                                        <mat-checkbox class="example-margin"
                                                      #checked
                                                      (change)="onChecked(question, option, checked)">
                                            {{option[strings.getLabel()]}}
                                        </mat-checkbox>
                                    </p>
                                </section>
                            </td>
                        </ng-container>
                    </ng-template>
                    <ng-template #address>
                        <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 6; else contents">
                            <td [id]="question[this.strings.getIndex()]">
                                <span [innerHTML]="question[strings.getLabel()]"></span> <br>
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input type="text"
                                           class="sawasdee26 text-center"
                                           matInput
                                           #addressElement
                                           autocomplete="off"
                                           placeholder=""
                                           (input)="onInputTextChange(question, $event)"
                                           [ngStyle]="{'text-align': 'center'}"
                                           matGoogleMapsAutocomplete>
                                </mat-form-field>
                            </td>
                        </ng-container>
                    </ng-template>
                    <ng-template #contents>
                        <td [id]="question[this.strings.getIndex()]">
                            {{question[strings.getLabel()]}} <br>
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <input type="text"
                                       class="text-center"
                                       aria-label = "Number"
                                       (input)="onInputTextChange(question, $event)"
                                       [ngStyle]="{'text-align': 'center'}"
                                       [placeholder]="''"
                                       matInput>
                            </mat-form-field>
                        </td>
                    </ng-template>
                </tr>
                <tr>
                    <td>
                        <div style="transform:scale(1.16);transform-origin:0;-webkit-transform:scale(1.16);transform:scale(1.16);-webkit-transform-origin:0 0;transform-origin:0 0;">
                            <ngx-recaptcha2 #recaptchaId
                                            [siteKey]="siteKey"
                                            (reset)="handleReset()"
                                            (expire)="handleExpire()"
                                            (load)="handleLoad()"
                                            (success)="handleSuccess($event)"
                                            [useGlobalDomain]="false">
                            </ngx-recaptcha2>
                        </div><br><br>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a (click)="ngSubmit({})"
                           class="btn btn-lg"
                           [ngStyle]="{'background-color':'rgb(16,30,81)','color':'#fff'}" >
                            Submit
                        </a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
</div>