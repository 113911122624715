import {Component, Injectable, ElementRef, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, AfterContentInit} from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {CampaignService} from '../campaigns/campaign.service';
import {FormService} from '../forms/form.service';
import {ResponseService} from '../forms/responses/data/response.service';
import {Parameters} from '../parameters';
import {FilterDashboardComponent} from '../dashboard/filter-dashboard.component';
import {DashboardService} from '../dashboard/dashboard.service';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts';
import {FormDashboardService} from '../forms/dashboard/form-dashboard.service';
import {ClientService} from '../clients/client.service';
import {DialogOverallComponent} from '../dashboard/dialog-overall.component';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {TableService} from '../forms/controls/table/table.service';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {AmbassadorsService} from "../ambassadors/ambassadors.service";
import {MemberService} from "../teams/members/member.service";
import {RouteService} from "../routes/route.service";
import {TeamService} from "../teams/team.service";
import {Geolocation} from "@capacitor/geolocation";
import {OrderService} from "../orders/order.service";
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @ViewChild('addressId', {static: false})  addressId: any;
  @ViewChild('drawerElement') drawerElement: any;
  dataSource: any = new MatTreeNestedDataSource<any>();
  treeControl: any = new NestedTreeControl<any>(node => node.children);
  formId: any = null;
  subscription: any;
  report: any;
  incomingForm: any;
  incomingReport: any;
  ambassadors: any;
  kpi: any = 0;
  output: any;
  progress: any;
  responses: any = 0;
  teams: any = [];
  position: any;
  team: any;
  markers: any = [];
  app: any;
  opened: any = false;
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any = 0;
  updateFlag = false;
  Highcharts: typeof Highcharts = Highcharts;
  questions: any;
  config: any;
  target: any;
  incomingCampaign: any;
  incomingOption: any;
  incomingItem: any;
  incomingQuestion: any;
  searchCntrl: any;
  navigation: any;
  icon: any;
  navUser: any = 0;
  currentLocationLat: any = 0;
  currentLocationLng: any = 0;
  positionOptions: any = {
    timeout: 80000,
    maximumAge: 60000,
    enableHighAccuracy: true,
  };
  incomingClient: any;
  navTime: any = 4;
  incomingProducts: any;
  navRegion: any = 0;
  durations: any = [];
  appsettingsUrl: any = '../assets/json/';
  func: any = [];
  incomingSelectedRegion: any;
  incomingSelectedUser: any;
  navTeam: any = 0;
  incomingSelectedTeam: any;
  customOptions: any = {};
  incomingSingleFormReport: any;
  incomingDurations: any;
  radius: any = 5000;
  incomingDuration: any;
  incomingSections: any;
  incomingSection: any;
  allSections: any;
  incomingResponse: any;
  incomingSelectedItem: any;
  incomingItm: any;
  productState: any = 1;
  incomingMarker: any;
  outgoingRequest: any;
  incomingFeedback: any;
  incomingFormSettings: any;
  spinner: any = true;
  incomingFormDurations: any;
  incomingSectionDurations: any;
  items: any = [];
  incomingOptionalQuestion: any;
  incomingControl: any;
  nodes: any = [];
  incomingFilteredQueston: any;
  railwayIcon: any;
  strings: any;
  agmCircle: any;
  latitude: any = 0;
  longitude: any = 0;
  agmIcon: any;
  incomingAmbassador: any;
  constructor(public  dashboardService: DashboardService,
              private dialog: MatDialog,
              public  formService: FormService,
              private orderService: OrderService,
              private teamService: TeamService,
              private campaignService: CampaignService,
              private memberService: MemberService,
              public formDashboardService: FormDashboardService,
              private ambassadorService: AmbassadorsService,
              private clientService: ClientService,
              public tableService: TableService,
              private routeService: RouteService,
              public router: Router,
              public activateRouter: ActivatedRoute,
              public responseService: ResponseService,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService) {
    this.incomingCampaign = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingItem   = new Parameters();
    this.output         = new Parameters();
    this.incomingClient = new Parameters();
    this.agmCircle      = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingProducts = new Parameters();
    this.incomingOptionalQuestion = new Parameters();
    this.incomingSectionDurations = new Parameters();
    this.incomingFormDurations = new Parameters();
    this.incomingSelectedRegion = new Parameters();
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedTeam = new Parameters();
    this.incomingFilteredQueston = new Parameters();
    this.incomingControl = new Parameters();
    this.incomingSingleFormReport = new Parameters();
    this.incomingSections = new Parameters();
    this.incomingSection = new Parameters();
    this.incomingDurations = new Parameters();
    this.incomingDuration = new Parameters();
    this.incomingResponse = new Parameters();
    this.allSections = new Parameters();
    this.incomingSelectedItem = new Parameters();
    this.incomingItm = new Parameters();
    this.incomingMarker = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingFeedback = new Parameters();
    this.incomingAmbassador = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.strings = new Parameters();
    this.output.setAttributes({});
    this.drawerElement = ElementRef;
    this.addressId = ElementRef;
    this.service.module = 'form.view';
    this.navigation = this.router.getCurrentNavigation();
    this.incomingSelectedRegion.setAttributes({});
    this.incomingSelectedUser.setAttributes({});
    this.incomingAmbassador.setAttributes(this.ambassadorService.selected);
    this.incomingSelectedTeam.setAttributes({});
    this.incomingDurations.setAttributes({});
    this.incomingDuration.setAttributes({});
    this.incomingSections.setAttributes({});
    this.incomingSection.setAttributes({});
    this.incomingQuestion.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.agmCircle.setAttributes({});
    this.agmIcon = {url: this.service.app.routes.app.agm.icon, scaledSize: {height: 40, width: 40}};
  }

  ngOnInit(): void {
    this.formId = this.formService.getSelected()[this.strings.getId()];
    this.formService.questions = this.formService.getSelected()[this.strings.getControls()];
    this.allSections.setAttributes({});
    this.allSections.setAll(false);
    this.allSections.setImages(false);
    setTimeout(() => {this.broadcastChannel.emitNavChangeEvent({action: this.strings.getTreenodes()}); }, this.service.timeout());
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((result: any) => {
          if (result instanceof Object){
            if (result.hasOwnProperty(this.strings.getSearchText()) ) {
              this.searchCntrl = result.searchText;
            }else if (result.hasOwnProperty(this.strings.getForm())){
              this.dashboardService.incomingSelectedForm.setAttributes(result.form);
              this.formService.setSelected(result.form);
              this.dashboardService.incomingReport.setFormId(result.form.id);
              this.handleDashboard(this.strings.getStart());
            }else if (result.hasOwnProperty(this.strings.getAction())){
              if (result[this.strings.getAction()] === this.strings.getTreeNode()){
                this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].markers = [];
                this.output.setAttributes(result.item);
                this.getMarkers(null, null);
              }else if (result[this.strings.getAction()] === this.strings.getLocation()){
                this.zoom = 5; setTimeout(() => {
                  this.output.setAttributes(result.item);
                  this.agmCircle.setAddress(this.output.getAddress());
                  this.agmCircle.setLat(this.output.getLat());
                  this.agmCircle.setLng(this.output.getLng());
                  this.lat = parseFloat(this.agmCircle.getLat());
                  this.lng = parseFloat(this.agmCircle.getLng());
                  this.latitude = this.lat;
                  this.longitude = this.lng;
                  this.zoom = 13;
                  this.service.getDistanceBetweenTwoLatLong('DRIVING',
                      this.agmCircle.getAttributes(),
                      { address: 'Mwenge, Dar es Salaam, Tanzania', lat: -6.7695352, lng: 39.2297569 },
                      (result: any) => {
                      });
                }, this.service.timeout());
              }
            }
          }else if (result === 'openDateMenu') {
            this.openMenuDate();
          } else if (result.includes('menu.toggle')) {
            this.drawerElement.nativeElement.click();
          } else if (result === 'report'){
            this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.incomingReport.setDuration('custom');
            this.incomingReport.setSection('markers');
            this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), {});
            this.handleDashboard('starter');
          }
        });
    this.railwayIcon = {url: 'https://cdn.iconscout.com/icon/premium/png-64-thumb/train-station-827029.png', scaledSize: {height: 20, width: 20}};
    this.icon = {url: 'https://i.imgur.com/7teZKif.png', scaledSize: {height: 20, width: 20}};
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
    this.incomingForm   = new Parameters();
    this.incomingReport = new Parameters();
    this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
    this.formService.questions = this.incomingForm.getControls();
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingReport.attributes = this.dashboardService.incomingReport.attributes;
    this.incomingProducts.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingForm.getProducts(), 'cntrl_num'));
    this.incomingSingleFormReport.setAttributes(this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
        this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
    this.report = this.incomingReport.attributes.data;
    this.ambassadors = [];
    this.responses = 0;
    this.progress  = 0;
    this.app = this.service.app;
    this.incomingReport.setSection('markers');
    this.incomingReport.setDuration('custom');
    if (Object.keys(this.incomingSingleFormReport.getAttributes()).length === 0) {
      this.handleDashboard('starter');
    } else this.dashbaord();
// if(this.incomingReport.attributes.form_id === 0 || this.incomingReport.attributes.data === null || this.incomingReport.attributes.form_id !== this.incomingForm.attributes.id){
    //   this.handleDashboard('dashboard');
    // }else this.dashbaord();
    setTimeout(() => {
      document.title = this.incomingCampaign.attributes.name;
      this.service.header = this.incomingForm.attributes.name.toString().toUpperCase() + ' ' + this.service.setDisplayedDate(this.dashboardService.incomingReport) + ' ,dashboard';
      this.zoom = 10;
    }, 1000);
    this.addNodes([]);
    this.config  = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: true,
      decoupleChildFromParent: false,
      maxHeight: window.innerHeight
    });if (this.service.markers.overpassapi.railway.data.features.length === 0)
      this.service.getMarkers();
    Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
      this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
      this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
    });
  }

  getIcon(marker: any){
    let incomingMarker  = new Parameters();
    incomingMarker.setAttributes(marker);
    if (incomingMarker.getAttributes().hasOwnProperty(this.strings.getItems()) && incomingMarker.getItems().length !== 0)
      this.icon = {url: incomingMarker.getItems()[0][this.strings.getAvatar()], scaledSize: {height: 20, width: 20}};
    return this.icon;
  }


  agmCircleChangeRadius($event){
    let promptText = prompt('', this.radius);
    if (promptText !== null) {
      this.radius = parseFloat(promptText);
      this.zoom = 2;
      setTimeout(() => {this.zoom = 13; }, this.timeout());
    }
  }

  onGoogleMapAutocompleteSelected(result: any) {
    const geomentry: any = new Parameters();
    geomentry.setAttributes({});
    geomentry.setAddress(this.addressId.nativeElement.value);
    geomentry.setLat(result.geometry.location.lat());
    geomentry.setLng(result.geometry.location.lng());
    this.broadcastChannel.emitNavChangeEvent({action: this.strings.getLocation(), item: geomentry.getAttributes()});
    this.addressId.nativeElement.value = '';
  }

  onLocationSelected(location: any) {

  }

  onMenuClicked(){
    this.broadcastChannel.emitNavChangeEvent('menu.toggle');
  }


  openDateMenu(){
    this.broadcastChannel.emitNavChangeEvent('openDateMenu');
  }

  handleSearch(){}

  getResponses(){
    this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/responses' );
  }


  onSelectedChange(event: any){
  }

  getNodes(): TreeviewItem[] {
    return [];
  }
  addNodes(nodes: any){
    this.questions = this.formService.setQuestionsVisible(this.incomingForm.getControls());
    this.questions = this.formService.setOptionQuestionsUnvisible(this.questions);
    this.questions = this.formService.getVisibleQuestions(this.questions);
    this.questions.map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      this.incomingControl.setAttributes(this.incomingQuestion.getControl());
      if (parseFloat(this.incomingControl.getId())  === 2   ||
          parseFloat(this.incomingControl.getId()) === 7  ||
          parseFloat(this.incomingControl.getId()) === 8  ||
          parseFloat(this.incomingControl.getId()) === 12 ||
          parseFloat(this.incomingControl.getId()) === 13 ||
          parseFloat(this.incomingControl.getId()) === 14 ||
          parseFloat(this.incomingControl.getId()) === 15 ||
          parseFloat(this.incomingControl.getId()) === 17 ||
          parseFloat(this.incomingControl.getId()) === 20 ||
          parseFloat(this.incomingControl.getId()) === 21 ||
          parseFloat(this.incomingControl.getId()) === 24 ||
          parseFloat(this.incomingControl.getId()) === 25){}else{
        this.incomingItem.setAttributes({});
        this.incomingItem.setText(this.incomingQuestion.getLabel());
        this.incomingItem.setValue(this.incomingQuestion.getCntrlNum());
        this.incomingItem.setCollapsed(true);
        this.incomingItem.setChecked(false);
        this.incomingItem.setChildren([]);
        this.incomingItem.setCntrlNum(this.incomingQuestion.getCntrlNum());
        this.nodes.push(this.incomingItem.getAttributes());
        this.setNode();
        this.addNodeChilds(this.incomingQuestion.getAttributes(), this.incomingItem.getAttributes(), {});
      }
    }); return nodes;
  }

  setNode(){
    setTimeout(() => {
      this.items = [];
      this.nodes.map((node: any) => {
        this.items.push(new TreeviewItem(node));
      });
    });
  }

  addNodeChilds(question: any, node: any, callback: any){
      this.incomingQuestion.setAttributes(question);
      this.incomingQuestion.getOptions().map((option: any) => {
          this.incomingOption.setAttributes(option);
          this.incomingOption.getQuestions().map((optionQuestion: any) => {
            this.incomingOptionalQuestion.setAttributes(optionQuestion);
            this.incomingOptionalQuestion.setAttributes(
                this.formService.getQuestionByQuestionsAndCntrlNum(this.incomingForm.getControls(), this.incomingOptionalQuestion.getCntrlNum()));
            this.incomingItm.setAttributes({});
            this.incomingItm.setText(this.incomingOptionalQuestion.getLabel());
            this.incomingItm.setValue(this.incomingOptionalQuestion.getCntrlNum());
            this.incomingItm.setCollapsed(true);
            this.incomingItm.setChecked(false);
            this.incomingItm.setCntrlNum(this.incomingOptionalQuestion.getCntrlNum());
            this.incomingItm.setChildren([]);
            node.children.push(this.incomingItm.getAttributes());
            this.setNode();
            this.addNodeChilds(this.incomingOptionalQuestion.getAttributes(), this.incomingItm.getAttributes(), {});
          });
      });
  }


  extract(items: any, t: any, column: any){
    let itms: any = [];
    itms = items.map((item: any) => {
      this.incomingItem.setAttributes(item);
      const incomingItm: any = new Parameters();
      incomingItm.setAttributes({});
      incomingItm.attributes = Object.assign(incomingItm.attributes, this.incomingItem.getAttributes());
      incomingItm.attributes[column] = parseFloat(this.incomingItem.attributes[t][column]);
      return incomingItm.getAttributes();
    }); return itms;
  }

  level(users: any, carr: any, time: any, label: any, column: any){
    let items: any = [];
    items = users.map((user: any) => {
      this.incomingItm.setAttributes({});
      let sum: any = 0; user[carr].map((product: any) => {
        this.incomingItem.setAttributes(product);
        this.incomingItem.setCurrency(this.incomingItem.attributes.hasOwnProperty('currency') ? this.incomingItem.getCurrency() : 0);
        if (parseFloat(this.incomingItem.attributes.id) === parseFloat(this.incomingQuestion.attributes.id)){
          sum += parseFloat((this.incomingItem.attributes[time][column]).toString().replaceAll(',', ''));
        }
      });
      this.incomingItm.attributes[column] = sum;
      this.incomingItm.attributes[label] = user[label];
      return this.incomingItm.getAttributes();
    }); return items;
  }

  getTeamsByRegionId($regionId: any ){
    let teams: any = [];
    if (this.incomingSelectedRegion.hasAttribute('name')){
      teams = this.incomingReport.attributes.data.teams.filter((team: any) => {
        this.incomingItem.setAttributes(team);
        return !this.service.isNull(this.incomingItem.getAddress()) && this.incomingItem.getAddress().toString().toLowerCase().includes(this.incomingSelectedRegion.getName().toString().toLowerCase());
      });
    }return teams;
  }

  afterSelectRegion(){
    if (this.incomingSelectedRegion.hasAttribute('name')){
      setTimeout(() => {
        const regionTeams: any = this.incomingReport.attributes.data.teams.filter((team: any) => {
          this.incomingItem.setAttributes(team);
          return this.incomingItem.getAddress().toString().toLowerCase().includes(this.incomingSelectedRegion.getName().toString().toLowerCase());
        }); this.incomingSelectedTeam.setAttributes(regionTeams.length === 0 ? {} : regionTeams[0]);
        this.navTeam = 0;
        if (Object.keys(this.incomingSelectedTeam.getAttributes()).length === 0) {}{
          const teamUsers: any = this.incomingReport.attributes.data.users.filter((user: any) => {
            this.incomingItem.setAttributes(user);
            return parseFloat(this.incomingItem.getTeamId()) === parseFloat(this.incomingSelectedTeam.getId());
          }); this.incomingSelectedUser.setAttributes(teamUsers.length === 0 ? {} : teamUsers[teamUsers.length - 1]);
          this.navUser = teamUsers.length === 0 ? 0 : teamUsers.length - 1;
        }
      });
    }
  }

  onMapClicked(){
    this.opened = false;
  }

  fetchBySection(){
    this.service.httpService( 'get', this.appsettingsUrl + '/sections.json', {}, {}, (result: any) => {
      this.allSections.setAttributes(result);
      let output: any = true;
      this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
      this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
      this.incomingSections.setAttributes(
          this.incomingDuration.attributes.hasOwnProperty('sections') ?
              this.incomingDuration.getSections() : {});
      for (const key in this.allSections.getAttributes()){
        this.incomingSection.setAttributes(
            this.incomingSections.attributes.hasOwnProperty(key) ?
                this.incomingSections.getUnknown(key) : {});
        if ((Object.keys(this.incomingSection.getAttributes()).length === 0) &&
            output === true){
          this.incomingReport.setSection(key);
          this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
          setTimeout((params: any) => {
            params.outgoingRequest = Object.assign(params.outgoingRequest, this.incomingReport.attributes);
            if (params.outgoingRequest.hasOwnProperty('data')) {
              delete params.outgoingRequest.data;
            }
            this.service.httpService('POST', this.service.app.routes.reports.fetch, params.outgoingRequest, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
              this.incomingResponse.setAttributes(response);
              this.incomingDuration.setUnknown(
                  this.incomingReport.getSection(),
                  this.incomingResponse.getUnknown(this.incomingReport.getSection()));
              this.incomingSection.setStatus(1);
              this.incomingSections.setUnknown(
                  params.section.toString(),
                  this.incomingSection.getAttributes());
              this.incomingDuration.setSections(this.incomingSections.getAttributes());
              this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
              this.incomingSingleFormReport.setDurations(
                  this.incomingDurations.getAttributes());
              this.formDashboardService.incomingForms.setUnknown(
                  this.incomingForm.getId(),
                  this.incomingSingleFormReport.getAttributes());
              this.incomingReport.setData(this.incomingDuration.getAttributes());
              if (this.incomingReport.getSection().includes('users')){
                this.incomingDuration.setUnknown('regions', this.incomingResponse.getUnknown('regions'));
                this.incomingDuration.setUnknown('teams', this.incomingResponse.getUnknown('teams'));
                this.incomingDuration.setUnknown('branches', this.incomingResponse.getUnknown('branches'));
                this.setSelected();
              }
              if (Object.keys(this.incomingSections.getAttributes()).length >= 5){
                // this.incomingFormDurations.setAttributes(this.incomingSingleFormReport.attributes.hasOwnProperty('periods') ?
                // this.incomingSingleFormReport.getPeriods() : {});
                // this.incomingFormDurations.setUnknown(this.incomingReport.getDuration(), true);
                // this.incomingSingleFormReport.setPeriods(this.incomingFormDurations.getAttributes());
                // let incremental: any = false;
                // this.durations.map((keyDuration: any) => {
                //     if(!this.incomingSingleFormReport.attributes.periods.hasOwnProperty(keyDuration.tm && !incremental)){
                //       this.incomingReport.setDuration(keyDuration.tm);
                //       this.incomingReport.setSection('all');
                //       incremental = true;
                //       this.handleDashboard((callback: any) => {
                //
                //       });
                //     }
                // });
              }
              // else this.fetchBySection();
              this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
            }, (error: any) => {});
          }, this.timeout(), {section: key.toString(), outgoingRequest: {}});
          output = false;
        }
      }
    },  (error: any) => {});
  }


  onlyProduct(responses: any){
    const results: any = responses.filter((response: any) => {
      this.incomingQuestion.setAttributes(response.formControl);
      return this.incomingProducts.getAttributes().hasOwnProperty(this.incomingQuestion.getCntrlNum()) || parseFloat(this.incomingQuestion.getControlId()) === 26;
    }); return results;
  }

  formShift(form: any){
    if (parseFloat(form.id) === parseFloat(this.incomingForm.getId())){}else{
      this.formService.formSelectedId = form.id;
      this.formService.setSelected(this.formService.getFormById(form.id));
      this.router.navigateByUrl('/wait..');
      setTimeout(() => { this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/dashboard');
      });
    }
  }

  oncreateFormButtonClicked(){
    this.formService.setSelected({});
    this.formService.openDialog({});
  }

  setSelectedQuestionBymarker(marker: any){
    this.incomingMarker.setAttributes(marker);
    this.incomingQuestion.setAttributes(this.incomingMarker.getAttributes().hasOwnProperty(this.strings.getResponse()) ?
        this.incomingMarker.getAttributes().response[0].datas.formControl : {});
    this.formService.question = this.incomingQuestion.getAttributes();
    this.tableService.setQuestion(this.incomingQuestion.getAttributes());
  }

  onClickMarker(event: any, marker: any){
    let incomingResponse: any = new Parameters();
    incomingResponse.setAttributes(marker);
    if (!incomingResponse.attributes.hasOwnProperty(this.strings.getResponse())){
      this.spinner = true;
      this.responseService.requestResponseById(incomingResponse.getId(), (response: any) => {
        if(response === null) this.spinner = false;
        else{incomingResponse.setResponse([response]);
          this.spinner = false;
          // if(incomingResponse.getItems().length !== 0){
          //   alert(incomingResponse.getResponse()[0][this.strings.getDatas()].length);
          //   incomingResponse.getResponse()[0][this.strings.getDatas()].map((responseResult: any) => {
          //     let incomingResponseResult: any = new Parameters();
          //     let incomingQuestion: any = new Parameters();
          //     incomingResponseResult.setAttributes(responseResult);
          //     incomingQuestion.setAttributes(incomingResponseResult.getUnknown(this.strings.getFormControll()));
          //     if(incomingQuestion.getCntrlNum().includes(this.incomingFormSettings.getInvoice())){
          //       alert(incomingResponseResult.getData());
          //     }
          //   });
          // }
        }
      });
    }
  }

  pState(){ this.productState = 0; setTimeout(() => { this.productState = 1; }, this.timeout()); }

  onextractFormButtonClicked(){}

  only(data, label){return data.map((l: any) => l[label]); }

  onInfoWindowClose(gm: any, infoWIndow: any){}

  getHeight(){
    return {height: window.innerHeight};
  }

  seeAllRsp(){ this.router.navigateByUrl('/app/forms/' + this.incomingForm.getId() + '/responses'); }
  getNames(data: any){ return data.map((l: any) => l.name); }
  getlabels(data: any, label: any){return data.map((l: any) => (l[label]).toString()); }


  getValues(data: any, value: string){return data.map((l: any) => ({name: l.name, value: l[value]})); }
  getValuesByLableAndValue(data: any, label: any, value: string){return data.map((l: any) => {
    const spliter: any = value.split(',');
    return {name: l[label], value: spliter.length === 0 ? l[value] : l[spliter[0]][spliter[1]]}; });
  }

  dashbaord(){
    this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
    this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
    this.incomingReport.setData(this.incomingDuration.getAttributes());
    if (this.incomingReport.attributes.data.hasOwnProperty(this.strings.getMarkers())){
      if (this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].hasOwnProperty(this.strings.getMarkers())){
        if (this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].markers.length !== 0){
          this.incomingReport.setAscendinglastAt(this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()]
              .markers[this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].markers.length - 1].id);
          this.getMarkers(this.strings.getAscendinglastAt(), this.incomingReport.getAscendinglastAt());
        }
      }
    }
  }


  filter(questions: any){
    return questions.filter((questional: any) => questional.control.id === 3 || questional.control.id === 5);
  }

  isDeliveryUser(marker: any){
    let incomingResponse: any = new Parameters();
    incomingResponse.setAttributes(marker);
    let incomingUser: any = new Parameters();
    incomingUser.setAttributes(this.getUser());
    let isDeliveryUser: any = false;
    if(incomingResponse.hasAttribute(this.strings.getDeliveryUsers())){
      incomingResponse.getDeliveryUsers().data.map((deliveryUser: any) => {
        let incomigDeliveryUser:any = new Parameters();
        incomigDeliveryUser.setAttributes(deliveryUser);
        if(incomingUser.hasAttribute(this.strings.getId()))
          isDeliveryUser = parseFloat(incomigDeliveryUser.getId()) === parseFloat(incomingUser.getId()) ? true : isDeliveryUser;});
    } return isDeliveryUser;
  }

  infoWindowDetails(){
    let all: any = null;
    this.markers.map((point: any) => {
      all = (all === null) ? '<h4>' + point.postedBy.name + '</h4>' : all + '<br><hr>' + '<h4>' + point.postedBy.name + '</h4>';
    });
    return all;
  }

  getMarkers(status: any, lasting: any){
    if (this.incomingReport.getAttributes().hasOwnProperty(this.strings.getQuestions())) {
      delete this.incomingReport.attributes[this.strings.getQuestions()];
    }if (this.incomingReport.getAttributes().hasOwnProperty(this.strings.getAscendinglastAt())) {
      delete this.incomingReport.attributes[this.strings.getAscendinglastAt()];
    }if (this.incomingReport.getAttributes().hasOwnProperty(this.strings.getDescendinglastAt())) {
      delete this.incomingReport.attributes[this.strings.getDescendinglastAt()];
    }if (status === this.strings.getDescendinglastAt()) {
      this.incomingReport.setDescendinglastAt(lasting);
    }if (status === this.strings.getAscendinglastAt()) {
      this.incomingReport.setAscendinglastAt(lasting);
    }if (Object.keys(this.output.getAttributes()).length !== 0) {
      this.incomingReport.setQuestions(JSON.stringify(this.output.getAttributes()));
    }
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      this.service.httpService('POST', this.service.app.routes.reports.fetch, outgoingRequest,
          {ignoreLoadingBar: 'true', notify: false},
          (response: any) => {
        if (response.markers[this.incomingReport.getDuration()].markers.length !== 0){
          response.markers[this.incomingReport.getDuration()].markers.map((marker: any) => {
            let incomingMarker: any = new Parameters();
            incomingMarker.setAttributes(marker);
            this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].markers.push(incomingMarker.getAttributes()); });
          this.getMarkers(status === null ? this.strings.getAscendinglastAt() : status, this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()]
              .markers[this.incomingReport.attributes.data.markers[this.incomingReport.getDuration()].markers.length - 1].id);
        }
      }, (error: any) => {
      });
    });

  }

  getUser(){
    let incomingUser: any = new Parameters();
    incomingUser.setAttributes(this.memberService.selected);
    if(!incomingUser.hasAttribute(this.strings.getId()) &&
        this.service.app.data.hasOwnProperty(this.strings.getUser()))
      incomingUser.setAttributes(this.service.app.data.user);
    return incomingUser.getAttributes();
  }

  addDeliveryUser(order: any, checkboxId: any){
    this.service.disabled = true;
    setTimeout(() => {
      if(checkboxId.checked){
        if(this.currentLocationLat === 0) {this.addDeliveryUser(order, checkboxId); return;}
        let incomingOrder: any = new Parameters();
        let status: any = 1;
        incomingOrder.setAttributes(order);
        let incomingUser: any = new Parameters();
        incomingUser.setAttributes(this.getUser());
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes({});
        outgoingRequest.setLatLng1(incomingOrder.getLat() + this.strings.getComma() + incomingOrder.getLng());
        outgoingRequest.setLatLng2(parseFloat(this.currentLocationLat)  + this.strings.getComma() + parseFloat(this.currentLocationLng));
        outgoingRequest.setStrokeColor('');
        outgoingRequest.setCntrlNum(this.service.random());
        outgoingRequest.setRegisteredBy(null);
        outgoingRequest.setName('');
        outgoingRequest.setDescription('');
        this.service.geocoder(this.currentLocationLat, this.currentLocationLng, (address: any) => {
          if(address !== null) {
            let incomingAddress = new Parameters();
            incomingAddress.setAttributes(address);
            if(incomingAddress.hasAttribute(this.strings.getAddress())){
              this.getDestinationAddressByOrder(incomingOrder.getAttributes(), (addressOrgin: any) => {
                this.service.getDistanceBetweenTwoLatLong('DRIVING',
                    {address: addressOrgin[this.strings.getAddress()], lat: incomingOrder.getLat(), lng: incomingOrder.getLng()},
                    { address: incomingAddress.getAddress(), lat: this.currentLocationLat, lng: this.currentLocationLng },
                    (result: any) => {
                      let incomingResult: any = new Parameters();
                      incomingResult.setAttributes(result);
                      if(status === 1){
                        outgoingRequest.setDistance(incomingResult.getDistance());
                        outgoingRequest.setDuration(incomingResult.getDuration());
                        let incomingDeliveryUser: any = new Parameters();
                        incomingDeliveryUser.setAttributes({});
                        this.routeService.addRoute(outgoingRequest.getAttributes(),(route: any) => {
                          if(route !== null){
                            let incomingRoute: any = new Parameters();
                            incomingRoute.setAttributes(route);
                            outgoingRequest.setAttributes({});
                            outgoingRequest.setSample(
                                this.strings.getDelivery() +
                                this.strings.getDot() +
                                this.strings.getUsers());
                            outgoingRequest.setEntity1(incomingOrder.getId());
                            outgoingRequest.setEntity2(incomingUser.getId());
                            outgoingRequest.setReference1(incomingRoute.getId());
                            outgoingRequest.setReference2(this.incomingForm.getId());
                            outgoingRequest.setDescription('');
                            this.orderService.assignDeliveryUser(outgoingRequest.getAttributes(), (delivery: any) => {
                              if(delivery !== null){
                                incomingOrder.getDeliveryUsers().data.push(outgoingRequest.getAttributes());
                                this.service.disabled = false;
                                outgoingRequest.setAttributes({});
                                outgoingRequest.setOutletId(incomingOrder.getResponse()[this.strings.getOutletId()]);
                                outgoingRequest.setResponseId(incomingOrder.getId());
                                this.orderService.tracking(outgoingRequest, (output) => {
                                  if(output !== null){
                                    alert(8797);
                                  }
                                });
                              }
                            });
                          }
                        });
                      }
                      status -=1;
                    });
              });
            }
          }
        });
      }else {
        alert(56);
      }
    });
  }

  getDestinationAddressByOrder(order: any, call: any){
    if(order.hasOwnProperty(this.strings.getAddress()) &&
        !this.service.isNull(order[this.strings.getAddress()]))
      call({address: order[this.strings.getAddress()]});
    else{
      this.service.geocoder(order[this.strings.getLat()], order[this.strings.getLng()], (output) => {
        call(output);
      });
    }
  }



  setSelected(){
    // this.teamSelectedMatTab = this.incomingReport.attributes.data.teams.length - 1;
    const regions: any = this.incomingReport.attributes.data.regions.filter((region: any) => Object.keys(region.team).length > 0);
    this.incomingSelectedRegion.setAttributes(regions.length === 0 ? {} : this.incomingReport.attributes.data.regions[this.service.findIndex(this.incomingReport.attributes.data.regions, 'id', regions[regions.length - 1].id)]);
    this.navRegion = regions.length === 0 ? 0 : this.service.findIndex(this.incomingReport.attributes.data.regions, 'id', regions[regions.length - 1].id);
    if (Object.keys(this.incomingSelectedRegion.getAttributes()).length > 0){
      if (Object.keys(this.incomingSelectedRegion.attributes.team).length > 0){
        const teams: any = this.getTeamsByRegionId(this.incomingSelectedRegion.attributes.id);
        this.incomingSelectedTeam.setAttributes(teams.length === 0 ? {} : teams[this.service.findIndex(teams, 'id', this.incomingSelectedRegion.attributes.team.id)]);
        this.navTeam = teams.length === 0 ? 0 : this.service.findIndex(teams, 'id', this.incomingSelectedRegion.attributes.team.id);
        if (Object.keys(this.incomingSelectedTeam.getAttributes()).length > 0){
          const users: any = this.incomingReport.attributes.data.users.filter((user: any) => parseFloat(user.team_id) === parseFloat(this.incomingSelectedTeam.attributes.id));
          this.incomingSelectedUser.setAttributes(users.length === 0 ? {} : users[users.length - 1]);
          this.navUser = users.length === 0 ? 0 : this.service.findIndex(users, 'id', this.incomingSelectedUser.attributes.id);
        }
      }
    }
  }

  handleHomeNavigator(){ this.router.navigateByUrl('/app/forms'); }
  handleNavigator(specie: any){
    this.dialog.open(DialogOverallComponent, {data : {report: this.dashboardService.incomingReport.attributes}, width : '120%', height: '99%', });
  }

  handleDashboard(item: any){
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      if (this.incomingReport.attributes.hasOwnProperty('descending_last_at')) {
        delete outgoingRequest.descending_last_at;
      }
      if (item === 'starter') {
        this.router.navigateByUrl('/wait..');
      }
      this.service.httpService('post', this.service.app.routes.reports.fetch, outgoingRequest, {}, (response: any) => {
        this.incomingSectionDurations.setAttributes({});
        this.incomingSectionDurations.setUnknown(this.incomingReport.getDuration(), true);
        this.incomingSingleFormReport.setAttributes(
            this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
                this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
        this.incomingSingleFormReport.setId(this.incomingForm.getId());
        this.incomingDurations.setAttributes(
            this.incomingSingleFormReport.attributes.hasOwnProperty('durations') ?
                this.incomingSingleFormReport.getDurations() : {});
        this.incomingDuration.setAttributes(
            this.incomingDurations.attributes.hasOwnProperty(this.incomingReport.getDuration()) ?
                this.incomingDurations.getUnknown(this.incomingReport.getDuration()) : {});
        this.incomingDuration.setAttributes(response);
        this.incomingSections.setAttributes(
            this.incomingDuration.attributes.hasOwnProperty('sections') ?
                this.incomingDuration.getSections() : {});
        this.incomingSection.setAttributes(
            this.incomingSections.attributes.hasOwnProperty(this.incomingReport.getSection()) ?
                this.incomingSections.getUnknown(this.incomingReport.getSection()) : {});
        this.incomingSection.setStatus(1);
        this.incomingSection.setDurations(this.incomingSectionDurations.getAttributes());
        this.incomingSections.setUnknown(this.incomingReport.getSection(), this.incomingSection.getAttributes());
        this.incomingDuration.setSections(this.incomingSections.getAttributes());
        this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
        this.incomingSingleFormReport.setDurations(this.incomingDurations.getAttributes());
        this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
        if (item === 'starter') { window.history.back(); } else{
          item(null);
        }
      }, (error: any) => {
      });
    }, this.timeout());
  }

  onChangeDuration(duration: any) {
    this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
    this.incomingDuration.setAttributes(this.incomingDurations.attributes.hasOwnProperty(duration) ? this.incomingDurations.getUnknown(duration) : {});
    this.incomingReport.setDuration(duration);
    this.incomingReport.setSection('markers');
    if (Object.keys(this.incomingDuration.getAttributes()).length === 0) {
      this.handleDashboard((call: any) => {
        this.dashbaord();
      });
    } else { this.dashbaord(); }
  }

  getGroup(options: any, timely: any, num: any, optName: any){
    const result: any = [];
    options.map((option: any) => {
      this.incomingOption.setAttributes(option);
      const responses: any = this.incomingOption.getAttributes()[timely].map((tm: any, key: any) => {
        this.incomingItem.setAttributes(tm);
        return this.incomingItem.getAttributes()[num];
      }); result.push({label: option[optName], data: responses});
    }); return result;
  }

  timeout(){return 94; }

  float(l: any){ return parseFloat(l); }


  openMenuDate(){
    this.dashboardService.incomingReport.setDisplay('dashboard');
    this.dialog.open(FilterDashboardComponent, { data: {report: this.dashboardService.incomingReport.getAttributes()} , width  : '55%', height : '100%'});
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // $.getScript( '../assets/js/easy-chart.js', ( data, textStatus, jqxhr ) => {});
      // window.localStorage.setItem('monthly', JSON.stringify(this.incomingReport.attributes.data.monthly));
      // window.localStorage.setItem('weekly' , JSON.stringify(this.incomingReport.attributes.data.weekly));
      // $.getScript( '../assets/js/plot.js', ( data, textStatus, jqxhr ) => {});
      // $.getScript( '../assets/js/flot.js', ( data, textStatus, jqxhr ) => {});
    });
  }

  ngAfterContentInit(): void {
    $.getScript( '../assets/js/easy-chart.js', ( data, textStatus, jqxhr ) => {});
    this.service.httpService( 'get', this.appsettingsUrl + 'time.json' , {}, {},
        (response: any) => {
          this.durations = response;
        },  (error: any) => {
        });
    this.service.httpService( 'get', this.appsettingsUrl + 'func.json' , {}, {},
        (response: any) => {
          this.func = response;
        },  (error: any) => {
        });
  }
 }
