import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {FormControl} from '@angular/forms';
import {AppService} from '../app.service';
import {FormService} from '../forms/form.service';
import {MatDialog} from '@angular/material/dialog';
import {Parameters} from '../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import * as moment from 'moment';
import {DialogFormsComponent} from '../forms/dialog-forms.component';
import {Router} from '@angular/router';
import {DialogEmployeesComponent} from '../employees/dialog-employees/dialog-employees.component';
import {EmployeesService} from '../employees/employees.service';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {DialogEmailInvitationsComponent} from '../invites/dialog-email-invitations/dialog-email-invitations.component';
import {DialogReplyComponent} from '../replies/dialog-reply/dialog-reply.component';
import {ResponseService} from '../forms/responses/data/response.service';
import Pusher from 'pusher-js';
import {NotifierService} from 'angular-notifier';
import {DialogProfileComponent} from '../users/dialog-profile/dialog-profile.component';
import {DialogPasswordUserComponent} from "../users/dialog-password-user/dialog-password-user.component";
import {UserService} from "../users/user.service";
import {DeleteDialogComponent} from "../main/delete-dialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {DashboardService} from "../dashboard/dashboard.service";
import {FilterDashboardComponent} from '../dashboard/filter-dashboard.component';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any = {data: null};
  parameters: any;
  params: any;
  selected: any;
  startingAt: any;
  endingAt: any;
  incomingReport: any;
  formControl = new FormControl();
  filteredFormsOptions: any;
  initColumns: any = [];
  displayedColumns: string[] = [];
  employeedisplayedColumns: string[] = ['name', 'phone', 'email', 'location'];
  selectedSuggestionBox: any = {};
  keys: any = 0;
  selectedResponse: any;
  searchCntrl: any;
  showFiller: any = false;
  mode: any = new FormControl('over');
  shouldRun: any = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
  dataSource: any = [];
  panelOpenState = false;
  subscription: any;
  employees: any;
  incomingResponse: any;
  employee: any;
  selectedEmployee: any;
  incomingSelectedEmployee: any;
  trustedUrl: any;
  constructor(public  service: AppService,
              private formMetthods: FormService,
              private responseService: ResponseService,
              private dashboardService: DashboardService,
              private employeeMetthods: EmployeesService,
              private userMetthods: UserService,
              public  broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              private sanitizer: DomSanitizer,
              private router: Router,
              public  dialog: MatDialog) {
    this.app = this.service.app;
    this.app.form.modules  = [{path: '/app/forms', title: 'Forms'}];
    this.app.form.subtitle = 'surveys and polls to collect customer feedback, measure employee satisfaction, and organize team events';
    this.app.form.module   = 'forms';
    this.selected = null;
    this.formMetthods.setData(this.app.data.forms);
    this.startingAt = new Date();
    this.endingAt   = new Date();
    this.incomingReport = new Parameters();
    this.incomingReport.setAttributes(this.responseService.reportValues.getAttributes());
    this.contextMenu = MatMenuTrigger;
    this.employees = this.service.app.data.employees;
  }

  ngOnInit(): void {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustScript('javascript:alert("Hi there")');
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object) {
            if (item.hasOwnProperty('form') === true) {
              this.setSuggestionBox(this.app.data.forms.data.filter((form: any) => { return parseFloat(form.id) === parseFloat(item.form.id);})[0]);
            } else if (item.hasOwnProperty('employee') === true)
              this.employeebroadcastChannel(item);
            else if (item.hasOwnProperty('response') === true)
              this.incomingPusherResponses(item);
            else if (item.hasOwnProperty('department') === true){
            }
          }
    });

    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
    if(this.app.data.forms.data.length === 0){} else{
      this.formMetthods.setSelected(this.app.data.forms.data[this.app.data.forms.data.length -  1]);
      this.setSuggestionBox(this.app.data.forms.data[this.app.data.forms.data.length -  1]);
      this.formControl.setValue(this.app.data.forms.data[this.app.data.forms.data.length -  1]);
    }

    this.startingAt = new Date(this.incomingReport.getStartingAt());
    this.endingAt   = new Date(this.incomingReport.getEndingAt());
    this.filteredFormsOptions = this.formControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterForm(name) : this.service.app.data.forms.data.slice())
      );
    this.filter();
  }


  onextractClicked(){
    this.incomingReport.setDisplay('excel');
    this.incomingReport.setType('responses');
    this.service.extract(this.incomingReport);
  }

  handleDeleteUser(user: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.dialog.open(DeleteDialogComponent, {
      data   : {
        data : this.employees,
        model: this.selectedEmployee,
        title: this.incomingSelectedEmployee.getName(),
        url  : '/employees/' + parseFloat(this.incomingSelectedEmployee.getEmployeeId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedEmployee.getId(),
      },
      width : 'auto',
    });
  }

  handleUserPassword(user: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(user);
    this.employeeMetthods.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.userMetthods.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.dialog.open(DialogPasswordUserComponent, {data  : {}, width : '55%', height: '100%', });
  }

  incomingPusherResponses(item: any){
    this.incomingResponse = new Parameters();
    this.incomingResponse.setAttributes(item.response);
    let box: any = this.service.app.data.forms.data.filter((form: any) => { return parseFloat(form.id) === parseFloat(this.incomingResponse.getFormId());})[0];
    box.responses.data.unshift(item.response);
    if(parseFloat(this.selectedSuggestionBox.id) === (parseFloat(this.incomingResponse.getFormId()))){
      this.setDataSource();
      const url: any = '../assets/audio/never.mp3';
      const sound: any = new Audio(url);
      sound.play();
    }
  }

  handleUpdateUser(employee: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.employeeMetthods.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.employeeMetthods.openDialog();
  }


  employeebroadcastChannel(item: any){
    const position = this.service.app.data.employees.data.findIndex((employee: any) => {
      return parseFloat(employee.id) === parseFloat(item.employee.id);});
    if (position === -1) this.service.app.data.employees.data.unshift(item.employee);
  }

  copyUrl(){
    let url = window.location.host + '/forms/' + this.selectedSuggestionBox.id + '/suggestions';
    navigator.clipboard.writeText(url).then(() => {}, (err: any) => {});
    this.notifier.notify('success', 'copyed !!');
  }

  getUrl(){ return window.location.host + '/forms/' + this.selectedSuggestionBox.id + '/suggestions';}

  getManagers(){ return this.employees.data.filter((employee: any) => { return parseFloat(employee.role.id) === 18;});}
  getCampanyAdmins(){ return this.employees.data.filter((employee: any) => { return parseFloat(employee.role.id) === 15;});}

  ngOnDestroy(): void { this.subscription.unsubscribe();}

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': null };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  emailInvitational(){
    this.dialog.open(DialogEmailInvitationsComponent, { disableClose: true, data  : {params: {flag: 0}}, width : '55%', height: '100%',});
  }

  _filterForm(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.forms.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
    return value.name;
  }

  updateSuggestionbox(){
    this.formMetthods.setSelected(this.selectedSuggestionBox);
    this.openDialog();
  }

  addSuggestionbox(){
    this.formMetthods.setSelected({});
    this.openDialog();
  }

  onFormSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.setSuggestionBox(event.option.value);
  }

  onMouseEnterSuggestionBox(event:any){
    event.target.value = '';
  }

  setSuggestionBox(suggestionBox: any){
    setTimeout(() => {
      this.formControl.setValue(suggestionBox);
      this.formMetthods.setSelected(suggestionBox);
      this.selectedSuggestionBox = this.formMetthods.getSelected();
      this.keys = Object.keys(this.selectedSuggestionBox).length;
      this.incomingReport.setFormId(this.selectedSuggestionBox.id);
      document.title = this.selectedSuggestionBox.name;
    },);
  }

  onSelectStartingAt(event: any){
    this.endingAt = moment(event.value).format('MM/DD/YYYY') > moment(this.endingAt).format('MM/DD/YYYY') ? event.value : this.endingAt;
    this.startingAt = event.value;
    this.incomingReport.setStartingAt(moment(event.value).format('MM/DD/YYYY'));
    this.incomingReport.setEndingAt(moment(this.endingAt).format('MM/DD/YYYY'));
  }

  onSelectEndingAt(event: any){
    this.startingAt = moment(event.value).format('MM/DD/YYYY') < moment(this.startingAt).format('MM/DD/YYYY') ? event.value : this.startingAt;
    this.endingAt = event.value;
    this.incomingReport.setEndingAt(moment(event.value).format('MM/DD/YYYY'));
    this.incomingReport.startingAt(moment(this.startingAt).format('MM/DD/YYYY'));
  }

  filter(){
    this.incomingReport.setDisplay('dashboard');
    this.incomingReport.setType('responses');
    this.responses();
  }

  iframeHeight(){
    return window.innerHeight;
  }

  openProfile(){
    this.dialog.open(DialogProfileComponent, { data:  { }, width  : '55%', height : '100%', });
  }

  currentSuggestionBox(){
    this.selectedSuggestionBox = this.formMetthods.getSelected();
    this.keys = Object.keys(this.selectedSuggestionBox).length;
  }

  openDatepicker(event: any){
    // this.trigger.closeMenu();
    // this.inspectionImpl.setStartingAt(event.chosenLabel.split('-')[0]);
    // this.inspectionImpl.setEndingAt(event.chosenLabel.split('-')[1]);
    // this.inspectionImpl.setDisplay('dashboard');
    // this.inspectionImpl.setReport(null);
    // this.broadcastChannel.emitNavChangeEvent('dashboard');
  }

  openDateMenu(){
    // this.dialog.open(FilterDashboardComponent, { data:  { }, width  : '55%', height : '100%',});
    this.broadcastChannel.emitNavChangeEvent('openDateMenu');
  }

  logout(){
    localStorage.setItem(this.service.app.settings.tokenName, '');
    this.service.setToken('');
    this.router.navigateByUrl('login');
  }
  setUser(user: any){}



  responses(){
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      outgoingRequest = Object.assign(outgoingRequest, {type: 'responses'});
      delete outgoingRequest.data;
      if(this.service.app.data.user.role.display_name.toString().toLowerCase().includes('manager')){
        this.employee = this.service.app.data.employees.data.filter((employee: any) => {
          return parseFloat(employee.user_id) === parseFloat(this.service.app.data.user.id);
        })[0];
        outgoingRequest = Object.assign(outgoingRequest, {department_id: this.employee.department_id});
      }this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.selectedSuggestionBox.responses.data = response;
        this.setDataSource();
        this.dashboardService.incomingReport.setStartingAt(this.responseService.reportValues.getStartingAt());
        this.dashboardService.incomingReport.setEndingAt(this.responseService.reportValues.getEndingAt());
        this.dashboardService.handleDashboard((response: any) =>{
          this.getQuestions();
        });
      }, (error: any) => {
      });
    });
  }

  getQuestions(){ return this.dashboardService.incomingReport.getData() === null ? [] :
      this.dashboardService.incomingReport.getData().questions;
  }

  handleSuggestionboxtitle(event: any) {
    setTimeout(() => {
      this.selectedSuggestionBox.name = event.target.value;
      this.update();
      },  1490);
  }

  addUser(){
    this.employeeMetthods.setSelected({});
    this.dialog.open(DialogEmployeesComponent, { disableClose: true, data  : {params: {flag: 0}}, width : '55%', height: '100%',});
  }

  handleSuggestionboxdescription(event: any) {
    setTimeout(() => {
      this.selectedSuggestionBox.description = event.target.value;
      this.update();
    },  1490);
  }

  update(){
    this.parameters = new Parameters();
    this.parameters.setAttributes(this.selectedSuggestionBox);
    this.service.httpService('post', '/forms/' + this.selectedSuggestionBox.id + '/update', this.parameters.getAttributes(),
        {ignoreLoadingBar: 'ignoreLoadingBar', notify: 'errorsIgnore'}, (response: any) => {
          this.selectedSuggestionBox = Object.assign(this.selectedSuggestionBox, response);
        }, (error: any) => {
        });
  }

  reply(response: any){
    this.formMetthods.setSelected(this.selectedSuggestionBox);
    this.responseService.setSelected(response);
    const dialogRef = this.dialog.open(DialogReplyComponent, { disableClose: false, data  : {params: {flag: 0}}, width : '55%', height: '100%',});
  }

  handleStar(l: any){
    const iterator: any = [];
    for (let i = 0; i < (parseFloat('5') - parseFloat(l)); i++) iterator.push({checked: parseFloat('0')});
    for (let i = 0; i < parseFloat(l); i++) iterator.push({checked: parseFloat('1')});
    return iterator;
  }

  setDataSource(){
    this.displayedColumns = [];
    this.initColumns  = [];
    if(this.selectedSuggestionBox.responses.data.length === 0){}else{
      let column: any = {};
      this.selectedSuggestionBox.responses.data[0].datas.map((response: any, responsel: any) => {
      column = {name: 'col' + responsel, label: response.formControl.label, control: response.formControl.control};
      this.displayedColumns.push('col' + responsel);
      this.initColumns.push(column);
      });
      column = {name: 'commenter', label: 'Commenter'};
      this.displayedColumns.push('commenter');
      this.initColumns.push(column);
      column = {name: 'Department', label: 'Department'};
      this.displayedColumns.push('department');
      this.initColumns.push(column);
      column = {name: 'reply', label: 'Reply'};
      this.displayedColumns.push('reply');
      this.initColumns.push(column);
    }
    this.dataSource = [];
    this.selectedSuggestionBox.responses.data.map((response: any) => {
    let source: any = {}; let l: any = 0;
    response.datas.map((feedback: any) => { source[JSON.parse(JSON.stringify(this.displayedColumns[l]))] = feedback.data; l = parseFloat(l) + 1;});
    source = Object.assign(source, {commenter: response.postedBy});
    source = Object.assign(source, response);
    this.dataSource.push(source);
    });
  }



  openDialog() {
    const dialogRef = this.dialog.open(DialogFormsComponent, { disableClose: true, data  : {params: {flag: 0}}, width : '55%', height: '100%',});
  }

}
