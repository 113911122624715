import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Morris} from 'morris.js06';

@Component({
  selector: 'app-morris-linechart',
  templateUrl: './morris-linechart.component.html',
  styleUrls: ['./morris-linechart.component.scss']
})
export class MorrisLinechartComponent implements OnInit, AfterViewInit {
  @Input() element: any;
  constructor() { }

  ngOnInit(): void {
  }

  options(){
    const opt: any = {
      element: this.element,
      xkey: 'year',
      ykeys: ['value'],
      labels: ['Value'],
      hideHover: 'auto',
      lineColors: ['#26B99A', '#34495E', '#ACADAC', '#3498DB'],
      data: [
        {year: '2012', value: 20},
        {year: '2013', value: 10},
        {year: '2014', value: 5},
        {year: '2015', value: 5},
        {year: '2016', value: 20}
      ],
      resize: true
    };
    return opt;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      new Morris.Line(this.options());
    });
  }

}
