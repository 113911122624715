<a class="pull-left"><h4>{{question.label}}</h4></a>
<a class="pull-right" [md-dialog-close]='' (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<mat-tab-group mat-align-tabs="end" (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab [disabled]="false">
        <ng-template mat-tab-label>
            <center>
                <br>
                <div [ngStyle]="{'cursor':'pointer'}">
                    <i class="material-icons">tune</i>
                </div>
            </center>
        </ng-template>
        <div>
            <form [formGroup]=formValues >
                <div class="col-lg-12 col-md-12">
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label> Options *</mat-label>
                        <input type="text"
                               #optionalElement
                               placeholder=""
                               aria-label = "Number"
                               matInput
                               [formControl] = "optionControl"
                               [matAutocomplete] = "option"/>
                        <mat-icon matSuffix [ngStyle]="{'cursor':'pointer'}" class="pull-right">keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #option = "matAutocomplete"
                                          (optionSelected)="onOptionSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptionsOptions | async; let optionOf = index" [value]= "option" >
                                {{option.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="keys() !== 0">
                    <mat-form-field class="example-chip-list">
                        <mat-label>questions</mat-label>
                        <mat-chip-list #chipList aria-label="selection">
                            <mat-chip
                                    *ngFor="let question of currentQuestions"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(question)">
                                <span class='headline2'>{{'(' + question.position + ') ' + question.label}}</span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input
                                    placeholder="Search for question..."
                                    (mouseenter)="questionalOpenDrawer()"
                                    #questionalElement
                                    [formControl]="questionalCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let question of filteredQuestions | async" [value]="question">
                                {{question.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-12 col-sm-12">
                    <center>
                        <input [md-dialog-close]='' (click)="closeDialog()" class="btn btn-xs btn-success" style="width: 50px !important"  value="Done"/>
                    </center>
                </div>
            </form>
        </div>
    </mat-tab>
    <mat-tab [disabled]="false">
        <ng-template mat-tab-label>
            <center>
                Tree view
                <br>
                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">architecture</i>
            </center>
        </ng-template>
        <div>
            <br><br>
            <app-treeview-form  [questions]="questions"></app-treeview-form>
        </div>
    </mat-tab>
</mat-tab-group>
<!--<mat-tab-group mat-align-sections="end" (selectedTabChange)="onSelectedTabChange($event)">-->
<!--    <mat-tab [disabled]="false">-->
<!--        <ng-template mat-tab-label>-->
<!--            <center>-->
<!--                <br>-->
<!--                <div [ngStyle]="{'cursor':'pointer'}">-->
<!--                    <i class="material-icons">tune</i>-->
<!--                </div>-->
<!--            </center>-->
<!--        </ng-template>-->
<!--        <div style="overflow-y: hidden">-->
<!--            <form [formGroup]=formValues >-->
<!--                <mat-list>-->
<!--                    <mat-list-item>-->
<!--                        <span class="" mat-line>-->
<!--                          <mat-form-field  class="example-full-width" appearance="fill">-->
<!--                             <mat-label> Select Question *</mat-label>-->
<!--                             <input type="text"-->
<!--                                    #visibleQuestionalElement-->
<!--                                    placeholder=""-->
<!--                                    aria-label = "Number"                                    (mouseenter)="visibleQuestionalOpenDrawer()"-->

<!--                                    matInput-->
<!--                                    [formControl] = "visibleQuestionalControl"-->
<!--                                    [matAutocomplete] = "visibleQuestional"/>-->
<!--                                        <mat-icon matSuffix [ngStyle]="{'cursor':'pointer'}" class="pull-right">keyboard_arrow_down</mat-icon>-->
<!--                                        <mat-autocomplete #visibleQuestional = "matAutocomplete" (optionSelected)="onOptionSelectionChanged($event)" [displayWith]="displayFn">-->
<!--                                            <mat-option *ngFor="let questional of filteredVisibleQuestions | async; let questionalOf = index" [value]= "questional" >-->
<!--                                                {{questional.label}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-autocomplete>-->
<!--                          </mat-form-field>-->
<!--                        </span>-->
<!--                    </mat-list-item>-->
<!--                    <mat-list-item>-->
<!--                        <span class="" mat-line>-->
<!--                          <mat-form-field  class="example-full-width" appearance="fill">-->
<!--                             <mat-label> Options *</mat-label>-->
<!--                             <input type="text"-->
<!--                                    #optionalElement-->
<!--                                    placeholder=""-->
<!--                                    aria-label = "Number"-->
<!--                                    matInput-->
<!--                                    [formControl] = "optionControl"-->
<!--                                    [matAutocomplete] = "option"/>-->
<!--                                        <mat-icon matSuffix [ngStyle]="{'cursor':'pointer'}" class="pull-right">keyboard_arrow_down</mat-icon>-->
<!--                                        <mat-autocomplete #option = "matAutocomplete" (optionSelected)="onOptionSelectionChanged($event)" [displayWith]="displayFn">-->
<!--                                            <mat-option *ngFor="let option of filteredOptionsOptions | async; let optionOf = index" [value]= "option" >-->
<!--                                                {{option.label}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-autocomplete>-->
<!--                          </mat-form-field>-->
<!--                        </span>-->
<!--                    </mat-list-item>-->
<!--                    <mat-list-item *ngIf="keys() !== 0">-->
<!--                        <span class="" mat-line>-->
<!--                          <mat-form-field class="example-chip-list">-->
<!--                              <mat-label>questions</mat-label>-->
<!--                              <mat-chip-list #chipList aria-label="selection">-->
<!--                                <mat-chip-->
<!--                                        *ngFor="let question of currentQuestions"-->
<!--                                        [selectable]="selectable"-->
<!--                                        [removable]="removable"-->
<!--                                        (removed)="remove(question)">-->
<!--                                    <span class='headline2'>{{'(' + question.position + ') ' + question.label}}</span>-->
<!--                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
<!--                                </mat-chip>-->
<!--                                <input-->
<!--                                        placeholder="Search for question..."-->
<!--                                        (mouseenter)="questionalOpenDrawer()"-->
<!--                                        #questionalElement-->
<!--                                        [formControl]="questionalCtrl"-->
<!--                                        [matAutocomplete]="auto"-->
<!--                                        [matChipInputFor]="chipList"-->
<!--                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--                                        (matChipInputTokenEnd)="add($event)">-->
<!--                              </mat-chip-list>-->
<!--                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">-->
<!--                                <mat-option *ngFor="let question of filteredQuestions | async" [value]="question">-->
<!--                                  {{question.label}}-->
<!--                                </mat-option>-->
<!--                              </mat-autocomplete>-->
<!--                            </mat-form-field>-->
<!--                        </span>-->
<!--                    </mat-list-item>-->
<!--                </mat-list>-->
<!--                <div class="col-md-12 col-sm-12">-->
<!--                    <center>-->
<!--                        <input [md-dialog-close]='' (click)="closeDialog()" class="btn btn-xs btn-success" style="width: 50px !important"  value="Done"/>-->
<!--                    </center>-->
<!--                </div>-->
<!--            </form>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab [disabled]="false">-->
<!--        <ng-template mat-tab-label>-->
<!--            <center>-->
<!--                Tree view-->
<!--                <br>-->
<!--                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">architecture</i>-->
<!--            </center>-->
<!--        </ng-template>-->
<!--        <div>-->
<!--            <br><br>-->
<!--            <app-treeview-form  [questions]="questions"></app-treeview-form>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--</mat-tab-group>-->
