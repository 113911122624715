import {AfterContentInit, AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardService} from '../../dashboard/dashboard.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../form.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../app.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Parameters} from '../../parameters';
import {DialogOverallComponent} from '../../dashboard/dialog-overall.component';
import {FilterDashboardComponent} from '../../dashboard/filter-dashboard.component';
import * as $ from 'jquery';
import * as Highcharts from 'highcharts';
import {ResponseService} from '../responses/data/response.service';
import {ClientService} from '../../clients/client.service';
import {FormDashboardService} from './form-dashboard.service';
import {MatMenuTrigger} from '@angular/material/menu';
import * as html2pdf from 'html2pdf.js';
import {jsPDF} from 'jspdf';
import {Geolocation} from "@capacitor/geolocation";

@Component({
  selector: 'app-dashboard-form',
  templateUrl: './dashboard-form.component.html',
  styleUrls: ['./dashboard-form.component.scss']
})
export class DashboardFormComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @Input() formId: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  subscription: any;
  report: any;
  incomingForm: any;
  incomingReport: any;
  ambassadors: any;
  kpi: any = 0;
  progress: any;
  responses: any = 0;
  teams: any = [];
  position: any;
  team: any;
  markers: any = [];
  app: any;
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any = 0;
  updateFlag = false;
  Highcharts: typeof Highcharts = Highcharts;
  questions: any;
  target: any;
  incomingCampaign: any;
  incomingOption: any;
  incomingItem: any;
  incomingQuestion: any;
  searchCntrl: any;
  navigation: any;
  icon: any;
  navOverall: any = 0;
  teamSelectedMatTab: any;
  teamNavSelectedMatTab: any = 0;
  navUser: any = 0;
  incomingClient: any;
  navCurrencies: any = null;
  navOutlets: any = 0;
  navProducts: any = null;
  incomingSelectedQuestion: any;
  navTime: any = 4;
  charts: any = [];
  navTop5: any = 1;
  navRecent: any = 1;
  incomingProducts: any;
  navAddress: any = 1;
  navRegion: any = 0;
  navRegionSide: any = 2;
  durations: any = [];
  appsettingsUrl: any = '../assets/json/';
  navFunc: any = 1;
  func: any = [];
  incomingSelectedRegion: any;
  incomingSelectedUser: any;
  navTeam: any = 0;
  incomingSelectedTeam: any;
  customOptions: any = {};
  incomingSingleFormReport: any;
  incomingDurations: any;
  incomingDuration: any;
  incomingSections: any;
  incomingSection: any;
  allSections: any;
  incomingResponse: any;
  incomingSelectedItem: any;
  incomingItm: any;
  hiddenFlag: any = 1;
  incomingMarker: any;
  outgoingRequest: any;
  incomingFeedback: any;
  spinner: any = true;
  incomingFormDurations: any;
  incomingSectionDurations: any;
  incomingCustomReport: any;
  incomingSelectedCustomReport: any;
  legends: any = [];
  values: any = [];
  matMenuStatus: any = 0;
  incomingItemExtract: any;
  incomingItemExtractFiltered: any;
  items: any = [];
  result: any;
  incomingSettings: any;
  exportExcel: any = false;
  navSell: any = null;
  opened: any = false;
  reportStatus: any;
  constructor(public dashboardService: DashboardService,
              private dialog: MatDialog,
              private formService: FormService,
              private campaignService: CampaignService,
              public formDashboardService: FormDashboardService,
              private clientService: ClientService,
              public router: Router,
              public activateRouter: ActivatedRoute,
              public responseService: ResponseService,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingCampaign = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingItem   = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingProducts = new Parameters();
    this.incomingCustomReport = new Parameters();
    this.incomingSelectedCustomReport = new Parameters();
    this.incomingSectionDurations = new Parameters();
    this.incomingFormDurations = new Parameters();
    this.incomingSelectedRegion = new Parameters();
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedTeam = new Parameters();
    this.incomingSingleFormReport = new Parameters();
    this.incomingItemExtract = new Parameters();
    this.incomingItemExtractFiltered = new Parameters();
    this.incomingSections = new Parameters();
    this.incomingSection = new Parameters();
    this.incomingDurations = new Parameters();
    this.incomingDuration = new Parameters();
    this.incomingResponse = new Parameters();
    this.allSections = new Parameters();
    this.incomingSelectedItem = new Parameters();
    this.incomingItm = new Parameters();
    this.incomingMarker = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingFeedback = new Parameters();
    this.incomingSettings = new Parameters();
    this.service.module = 'form.view';
    this.incomingSectionDurations.setAttributes({});
    this.navigation = this.router.getCurrentNavigation();
    this.formId = this.activateRouter.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.formService.formSelectedId = this.formId;
    this.incomingSelectedRegion.setAttributes({});
    this.incomingSelectedUser.setAttributes({});
    this.incomingSelectedTeam.setAttributes({});
    this.incomingDurations.setAttributes({});
    this.incomingDuration.setAttributes({});
    this.incomingSections.setAttributes({});
    this.incomingSection.setAttributes({});
  }

  ngOnInit(): void {
    this.allSections.setAttributes({});
    this.allSections.setAll(false);
    this.allSections.setImages(false);
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if (item instanceof Object){
            if (item.hasOwnProperty('searchText') ) {
              this.searchCntrl = item.searchText;
            }else if (item.hasOwnProperty('form')){
              this.dashboardService.incomingSelectedForm.setAttributes(item.form);
              this.formService.setSelected(item.form);
              this.dashboardService.incomingReport.setFormId(item.form.id);
              this.handleDashboard('starter');
            }
          }else if (item === 'openDateMenu') {
           this.openMenuDate();
          } else if (item === 'report'){
            if(this.reportStatus === 3){
              this.requestOutletEngament();
            }if(this.reportStatus === 4){
              this.requestDateRangeReport();
            } else{
              if(!this.exportExcel){
                this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
                this.incomingReport.setDuration('custom');
                this.incomingReport.setSection('all');
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), {});
                this.handleDashboard('starter');
              }
            }


          }
        });
    this.icon = {url: 'https://i.imgur.com/7teZKif.png', scaledSize: {height: 20, width: 20}};
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
    this.incomingForm = new Parameters();
    this.incomingReport = new Parameters();
    this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
    this.incomingForm.setResponseDisplayTitle(this.service.isNull(this.incomingForm.getResponseDisplayTitle()) ? 'Responses' : this.incomingForm.getResponseDisplayTitle());
    this.incomingSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingSettings.setResponsesCounter(this.incomingSettings.attributes.hasOwnProperty('responses_counter') ? this.incomingSettings.getResponsesCounter() : 1);
    this.incomingSettings.setTarget(this.incomingSettings.attributes.hasOwnProperty('target') ? this.incomingSettings.getTarget() : '');
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingReport.attributes = this.dashboardService.incomingReport.attributes;
    this.incomingProducts.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingForm.getProducts(), 'cntrl_num'));
    this.incomingSingleFormReport.setAttributes(this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
    this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
    this.report = this.incomingReport.attributes.data;
    this.ambassadors = [];
    this.responses = 0;
    this.progress = 0;
    this.app = this.service.app;
    if (Object.keys(this.incomingSingleFormReport.getAttributes()).length === 0) {
      this.handleDashboard('starter');
    } else this.dashbaord();

// if(this.incomingReport.attributes.form_id === 0 || this.incomingReport.attributes.data === null || this.incomingReport.attributes.form_id !== this.incomingForm.attributes.id){
    //   this.handleDashboard('dashboard');
    // }else this.dashbaord();
    setTimeout(() => {
      document.title = this.incomingCampaign.attributes.name;
      this.service.header = this.incomingForm.attributes.name.toString().toUpperCase() + ' ' + this.service.setDisplayedDate(this.dashboardService.incomingReport) + ' ,dashboard';
      this.zoom = 10;
    }, 1000);
  }
  getResponses(){
    this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/responses' );
  }

  openDateMenu(){
      this.broadcastChannel.emitNavChangeEvent('openDateMenu');
  }

  getQuestions(questions: any){
    return questions.filter((question: any) => {
      this.incomingQuestion.setAttributes(question);
      return parseFloat(this.incomingQuestion.getControlId()) === 26 ||
          parseFloat(this.incomingQuestion.getControlId()) === 30 ||
          parseFloat(this.incomingQuestion.getControlId()) === 31;});
  }

  extract(items: any, t: any, column: any){
    this.items = [];
    this.items = items.map((item: any) => {
      this.incomingItemExtract.setAttributes(Object.assign({}, item));
      this.incomingItemExtractFiltered.setAttributes({});
      this.incomingItemExtractFiltered.setAttributes(Object.assign(this.incomingItemExtractFiltered.getAttributes(), this.incomingItemExtract.getAttributes()));
      this.incomingItemExtractFiltered.setUnknown(column, parseFloat(this.incomingItemExtract.getUnknown(t)[column]));
      return this.incomingItemExtractFiltered.getAttributes();
    });return this.items;
  }

  level(users: any, carr: any, time: any, label: any, column: any){
    let items: any = [];
    items = users.map((user: any) => {
      this.incomingItm.setAttributes({});
      let sum: any = 0; user[carr].map((product: any) => {
        this.incomingItem.setAttributes(product);
        this.incomingItem.setCurrency(this.incomingItem.attributes.hasOwnProperty('currency') ? this.incomingItem.getCurrency() : 0);
        if (parseFloat(this.incomingItem.attributes.id) === parseFloat(this.incomingQuestion.attributes.id)){
          sum += parseFloat((this.incomingItem.attributes.hasOwnProperty([time][column]) ? this.incomingItem.attributes[time][column] : 0).toString().replaceAll(',', ''));
        }
      });
      this.incomingItm.attributes[column] = sum;
      this.incomingItm.attributes[label] = user[label];
      return this.incomingItm.getAttributes();
    }); return items;
  }

  requestOutletEngament(){
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.incomingReport.setStartingAt(this.incomingItem.getStartingAt());
    this.incomingReport.setEndingAt(this.incomingItem.getEndingAt());
    if(this.outgoingRequest.attributes.hasOwnProperty('data')) delete this.outgoingRequest.attributes.data;
    this.outgoingRequest.setType('outlet.engagement.report');
    this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {}, (response: any) => {
      this.service.responses = response;
      this.router.navigateByUrl('/app/reports/outlets/engagement');
    }, (error: any) => {
    });
    this.reportStatus = 0;
  }

  getTeamsByRegionId($regionId: any ){
    let teams: any = [];
    if (this.incomingSelectedRegion.hasAttribute('name')){
         teams = this.incomingReport.attributes.data.teams.filter((team: any) => {
              this.incomingItem.setAttributes(team);
              return !this.service.isNull(this.incomingItem.getAddress()) &&
                  this.incomingItem.getAddress().toString().toLowerCase()
                      .includes(this.incomingSelectedRegion.getName()
                          .toString()
                          .toLowerCase());
         });
    }return teams;
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  afterSelectRegion(){
    if (this.incomingSelectedRegion.hasAttribute('name')){
      setTimeout(() => {
        const regionTeams: any = this.incomingReport.attributes.data.teams.filter((team: any) => {
          this.incomingItem.setAttributes(team);
          return this.incomingItem.getAddress().toString().toLowerCase().includes(this.incomingSelectedRegion.getName().toString().toLowerCase());
        }); this.incomingSelectedTeam.setAttributes(regionTeams.length === 0 ? {} : regionTeams[0]);
        this.navTeam = 0;
        if (Object.keys(this.incomingSelectedTeam.getAttributes()).length === 0) {}{
          const teamUsers: any = this.incomingReport.attributes.data.users.filter((user: any) => {
            this.incomingItem.setAttributes(user);
            return parseFloat(this.incomingItem.getTeamId()) === parseFloat(this.incomingSelectedTeam.getId());
          }); this.incomingSelectedUser.setAttributes(teamUsers.length === 0 ? {} : teamUsers[teamUsers.length - 1]);
          this.navUser = teamUsers.length === 0 ? 0 : teamUsers.length - 1;
        }
      });
    }
  }

  fetchBySection(){
    setTimeout(() => {
       this.service.httpService( 'get', this.appsettingsUrl + '/sections.json?version=' + this.service.random(), {}, {}, (result: any) => {
          this.allSections.setAttributes(result);
          let output: any = true;
          this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
          this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
          this.incomingSections.setAttributes(
              this.incomingDuration.attributes.hasOwnProperty('sections') ?
                  this.incomingDuration.getSections() : {});
          for (const key in this.allSections.getAttributes()){
            this.incomingSection.setAttributes(
                this.incomingSections.attributes.hasOwnProperty(key) ?
                    this.incomingSections.getUnknown(key) : {});
            if ((Object.keys(this.incomingSection.getAttributes()).length === 0) &&
                output === true){
              this.incomingReport.setSection(key);
              if (!this.incomingReport.getSection().toString().includes('custom_reports')){
                this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
                setTimeout((params: any) => {
                  params.outgoingRequest = Object.assign(params.outgoingRequest, this.incomingReport.attributes);
                  if (params.outgoingRequest.hasOwnProperty('data')) {
                    delete params.outgoingRequest.data;
                  }this.service.httpService('post', '/reports', params.outgoingRequest, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                    this.incomingResponse.setAttributes(response);
                    if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
                    if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
                    if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
                    this.incomingDuration.setUnknown(
                        this.incomingReport.getSection(),
                        this.incomingResponse.getUnknown(this.incomingReport.getSection()));
                    this.incomingSection.setStatus(1);
                    this.incomingSections.setUnknown(
                        params.section.toString(),
                        this.incomingSection.getAttributes());
                    this.incomingDuration.setSections(this.incomingSections.getAttributes());
                    this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                    this.incomingSingleFormReport.setDurations(
                        this.incomingDurations.getAttributes());
                    this.formDashboardService.incomingForms.setUnknown(
                        this.incomingForm.getId(),
                        this.incomingSingleFormReport.getAttributes());
                    this.incomingReport.setData(this.incomingDuration.getAttributes());
                    if (this.incomingReport.getSection().includes('users')){
                      this.incomingDuration.setUnknown('regions', this.incomingResponse.getUnknown('regions'));
                      this.incomingDuration.setUnknown('teams', this.incomingResponse.getUnknown('teams'));
                      this.incomingDuration.setUnknown('branches', this.incomingResponse.getUnknown('branches'));
                      this.setSelected();
                    }
                    if (Object.keys(this.incomingSections.getAttributes()).length >= 7){
                      // this.incomingFormDurations.setAttributes(this.incomingSingleFormReport.attributes.hasOwnProperty('periods') ?
                      // this.incomingSingleFormReport.getPeriods() : {});
                      // this.incomingFormDurations.setUnknown(this.incomingReport.getDuration(), true);
                      // this.incomingSingleFormReport.setPeriods(this.incomingFormDurations.getAttributes());
                      // let incremental: any = false;
                      // this.durations.map((keyDuration: any) => {
                      //     if(!this.incomingSingleFormReport.attributes.periods.hasOwnProperty(keyDuration.tm && !incremental)){
                      //       this.incomingReport.setDuration(keyDuration.tm);
                      //       this.incomingReport.setSection('all');
                      //       incremental = true;
                      //       this.handleDashboard((callback: any) => {
                      //
                      //       });
                      //     }
                      // });
                    }else this.fetchBySection();
                    this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                  }, (error: any) => {});
                }, this.timeout(), {section: key.toString(), outgoingRequest: {}});
                output = false;
              }else{
                this.incomingDuration.setUnknown(
                    this.incomingReport.getSection(),
                    []);
                this.incomingSection.setStatus(1);
                this.incomingSections.setUnknown(
                    this.incomingReport.getSection(),
                    this.incomingSection.getAttributes());
                this.incomingDuration.setSections(this.incomingSections.getAttributes());
                this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
                this.incomingSingleFormReport.setDurations(
                    this.incomingDurations.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(
                    this.incomingForm.getId(),
                    this.incomingSingleFormReport.getAttributes());
                this.incomingReport.setData(this.incomingDuration.getAttributes());
                this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
                this.handleCustomReports();
                output = false;
              }

            }
          }
        },  (error: any) => {});
    });
  }

  exportCustomReport(customReport: any){
    setTimeout((time: any, items: any = []) => {
      time = this.durations.filter((duration: any) => {return parseFloat(duration.id) === parseFloat(this.navTime)})[0];
      if(parseFloat(this.incomingSelectedCustomReport.getResult().status) === 0){
        this.legends = this.only((this.extract(this.incomingSelectedCustomReport.getResult().data[0].options, time.tm, 'responses')), 'label');
        this.values = this.only((this.extract(this.incomingSelectedCustomReport.getResult().data[0].options, time.tm, 'responses')), 'responses');
        this.legends.map((item: any, incremental: any) => {
          this.incomingItem.setAttributes({});
          this.incomingItem.setUnknown('sector', item);
          this.incomingItem.setUnknown(this.incomingForm.getResponseDisplayTitle(), this.values[incremental]);
          items.push(this.incomingItem.getAttributes());
        });this.service.exportCustomReport(items, this.incomingSelectedCustomReport.getTitle() + '-' + this.service.setDisplayedDate(this.dashboardService.incomingReport), true);
      }
    });
  }

 setChart(item: any){
    this.incomingItem.setAttributes(item);
    this.incomingItem.setChart(this.incomingItem.attributes.hasOwnProperty('chart') ?
    this.incomingItem.getChart() : 2);
 }

  handleCustomReports(){
    let output: any = true;
    this.incomingForm.getCustomReports().data.map((customReport: any) => {
      if(output === true){
        this.incomingCustomReport.setAttributes(Object.assign({}, customReport));
        this.position = this.service.findIndex(
            this.incomingDuration.getUnknown(this.incomingReport.getSection()), 'id',
            this.incomingCustomReport.getId());
        if(this.position === -1){
          this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
          this.outgoingRequest.setId(this.incomingCustomReport.getId());
          delete this.outgoingRequest.attributes.data;
          this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
           this.incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingResponse.getFormId())   !== parseFloat(this.incomingForm.getId())) return;
            if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
            if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
            this.incomingItem.setAttributes(Object.assign({}, this.incomingForm.getCustomReports().data.filter((report: any) => {return parseFloat(report.id) === parseFloat(response.id);})[0]));
            this.incomingItem.setResult(response);
            this.incomingDuration.getUnknown(this.incomingReport.getSection()).push(this.incomingItem.getAttributes());
            this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
            this.incomingSingleFormReport.setDurations(
                this.incomingDurations.getAttributes());
            this.formDashboardService.incomingForms.setUnknown(
                this.incomingForm.getId(),
                this.incomingSingleFormReport.getAttributes());
            this.incomingReport.setData(this.incomingDuration.getAttributes());
            this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
            this.handleCustomReports();
          },  (error: any) => {
           this.handleCustomReports();
          }); output = false;
        }
      }
    });
    if(this.incomingReport.attributes.data.custom_reports.length === this.incomingForm.getCustomReports().data.length) this.fetchBySection();
  }

  gallery(){ this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/images' );}


  onlyProduct(responses: any){
    const results: any = responses.filter((response: any) => {
      this.incomingQuestion.setAttributes(response.formControl);
      return this.incomingProducts.getAttributes().hasOwnProperty(this.incomingQuestion.getCntrlNum()) || parseFloat(this.incomingQuestion.getControlId()) === 26;
    }); return results;
  }

  onFormSelected(form: any){
    if(parseFloat(form.id) !== parseFloat(this.formService.formSelectedId)){
      this.subscription.unsubscribe();
      this.formService.formSelectedId = form.id;
      this.formService.setSelected(this.formService.getFormById(form.id));
      this.router.navigateByUrl('/wait..');
      this.incomingReport.setDuration('custom');
      this.incomingReport.setSection('all');
      this.hiddenFlag = 1;
      setTimeout(() => {
        if(parseFloat(this.service.app.data.user.role.id) === 2){
          this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(form.id) + '/' + 'dashboard3');
        }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/' + 'dashboard');
      });
    }
  }

  onMapClicked(){ this.opened = false; }
  oncreateFormButtonClicked(){
    this.formService.setSelected({});
    this.formService.openDialog({});
  }

  onClickMarker(event: any, marker: any){
    this.incomingMarker.setAttributes(marker);
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setUrl('/responses/' + this.incomingMarker.getId());
    this.spinner = true;
    this.service.httpService('post', this.outgoingRequest.getUrl(), {}, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
      this.incomingFeedback.setAttributes(response);
      this.incomingMarker.setResponse([this.incomingFeedback.getAttributes()]);
      this.spinner = false;
    }, (error: any) => {});
  }

  pState(){ this.hiddenFlag = 0; setTimeout(() => { this.hiddenFlag = 1; }, this.timeout()); }

  onextractFormButtonClicked(){}

  only(data, label){return data.map((l: any) => l[label]); }

  onInfoWindowClose(gm: any, infoWIndow: any){}

  getHeight(){ return {height: window.innerHeight}; }
  seeAllRsp(){ this.router.navigateByUrl('/app/forms/' + this.incomingForm.getId() + '/responses'); }
  getNames(data: any){ return data.map((l: any) => l.name); }
  getlabels(data: any, label: any){return data.map((l: any) => (l[label]).toString()); }


  getValues(data: any, value: string){return data.map((l: any) => ({name: l.name, value: l[value]})); }
  getValuesByLableAndValue(data: any, label: any, value: string){return data.map((l: any) => {
    const spliter: any = value.split(',');
    return {name: l[label], value: spliter.length === 0 ? l[value] : l[spliter[0]][spliter[1]]}; });
  }

  dashbaord(){
    this.incomingDurations.setAttributes(this.incomingSingleFormReport.getDurations());
    this.incomingDuration.setAttributes(this.incomingDurations.getUnknown(this.incomingReport.getDuration()));
    this.incomingReport.setData(this.incomingDuration.getAttributes());
    this.fetchBySection();
  }

  filter(questions: any){
    return questions.filter((questional: any) => questional.control.id === 3 || questional.control.id === 5);
  }

  infoWindowDetails(){
    let all: any = null;
    this.markers.map((point: any) => {
      all = (all === null) ? '<h4>' + point.postedBy.name + '</h4>' : all + '<br><hr>' + '<h4>' + point.postedBy.name + '</h4>';
    });
    return all;
  }


  setSelected(){
    // this.teamSelectedMatTab = this.incomingReport.attributes.data.teams.length - 1;
    const regions: any = this.incomingReport.attributes.data.regions.filter((region: any) => Object.keys(region.team).length > 0);
    this.incomingSelectedRegion.setAttributes(regions.length === 0 ? {} : this.incomingReport.attributes.data.regions[this.service.findIndex(this.incomingReport.attributes.data.regions, 'id', regions[regions.length - 1].id)]);
    this.navRegion = regions.length === 0 ? 0 : this.service.findIndex(this.incomingReport.attributes.data.regions, 'id', regions[regions.length - 1].id);
    if (Object.keys(this.incomingSelectedRegion.getAttributes()).length > 0){
      if (Object.keys(this.incomingSelectedRegion.attributes.team).length > 0){
        const teams: any = this.getTeamsByRegionId(this.incomingSelectedRegion.attributes.id);
        this.incomingSelectedTeam.setAttributes(teams.length === 0 ? {} : teams[this.service.findIndex(teams, 'id', this.incomingSelectedRegion.attributes.team.id)]);
        this.navTeam = teams.length === 0 ? 0 : this.service.findIndex(teams, 'id', this.incomingSelectedRegion.attributes.team.id);
        if (Object.keys(this.incomingSelectedTeam.getAttributes()).length > 0){
          const users: any = this.incomingReport.attributes.data.users.filter((user: any) => parseFloat(user.team_id) === parseFloat(this.incomingSelectedTeam.attributes.id));
          this.incomingSelectedUser.setAttributes(users.length === 0 ? {} : users[users.length - 1]);
          this.navUser = users.length === 0 ? 0 : this.service.findIndex(users, 'id', this.incomingSelectedUser.attributes.id);
        }
      }
    }
  }

  handleHomeNavigator(){ this.router.navigateByUrl('/app/forms'); }
  handleNavigator(specie: any){
    this.dialog.open(DialogOverallComponent, {data : {report: this.dashboardService.incomingReport.attributes}, width : '120%', height: '99%', });
  }

  outletEngagementAndSales(){
    this.reportStatus = 3;
    this.incomingItem.setAttributes({});
    this.incomingItem.setStartingAt(this.incomingReport.getStartingAt());
    this.incomingItem.setEndingAt(this.incomingReport.getEndingAt());
    this.openDateMenu();
  }


  requestDateRangeReport(){
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.incomingReport.setStartingAt(this.incomingItem.getStartingAt());
    this.incomingReport.setEndingAt(this.incomingItem.getEndingAt());
    if(this.outgoingRequest.attributes.hasOwnProperty('data')) delete this.outgoingRequest.attributes.data;
    this.outgoingRequest.setType('responses.date.range');
    this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {}, (result: any) => {
      this.service.responses = result;
      this.router.navigateByUrl('/app/supervisors/xxx/responses');
    }, (error: any) => {
    });
    this.reportStatus = 0;
  }

  handleDashboard(item: any){
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      if (item === 'starter') this.router.navigateByUrl('/wait..');
      this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.incomingResponse.setAttributes(Object.assign({}, response));
        if(parseFloat(this.incomingResponse.getFormId()) !== parseFloat(this.incomingForm.getId())) return;
        if(this.incomingResponse.getSection()  !== this.incomingReport.getSection()) return;
        if(this.incomingResponse.getDuration() !== this.incomingReport.getDuration()) return;
        this.incomingSectionDurations.setAttributes(Object.keys(this.incomingSectionDurations.attributes).length === 0 ? {} : this.incomingSectionDurations.getAttributes());
        this.incomingSectionDurations.setUnknown(this.incomingReport.getDuration(), true);
        this.incomingSingleFormReport.setAttributes(
            this.formDashboardService.incomingForms.attributes.hasOwnProperty(this.incomingForm.getId()) ?
            this.formDashboardService.incomingForms.getUnknown(this.incomingForm.getId()) : {});
        this.incomingSingleFormReport.setId(this.incomingForm.getId());
        this.incomingDurations.setAttributes(
            this.incomingSingleFormReport.attributes.hasOwnProperty('durations') ?
                this.incomingSingleFormReport.getDurations() : {});
        this.incomingDuration.setAttributes(
            this.incomingDurations.attributes.hasOwnProperty(this.incomingReport.getDuration()) ?
                this.incomingDurations.getUnknown(this.incomingReport.getDuration()) : {});
        this.incomingDuration.setAttributes(response);
        this.incomingSections.setAttributes(
            this.incomingDuration.attributes.hasOwnProperty('sections') ?
                this.incomingDuration.getSections() : {});
        this.incomingSection.setAttributes(
            this.incomingSections.attributes.hasOwnProperty(this.incomingReport.getSection()) ?
                this.incomingSections.getUnknown(this.incomingReport.getSection()) : {});
        this.incomingSection.setStatus(1);
        this.incomingSection.setDurations(this.incomingSectionDurations.getAttributes());
        this.incomingSections.setUnknown(this.incomingReport.getSection(), this.incomingSection.getAttributes());
        this.incomingDuration.setSections(this.incomingSections.getAttributes());
        this.incomingDurations.setUnknown(this.incomingReport.getDuration(), this.incomingDuration.getAttributes());
        this.incomingSingleFormReport.setDurations(this.incomingDurations.getAttributes());
        this.formDashboardService.incomingForms.setUnknown(this.incomingForm.getId(), this.incomingSingleFormReport.getAttributes());
        if (item === 'starter') {
          window.history.back();
        } else item(null);
      }, (error: any) => {
      });
    }, this.timeout());
  }

  dateRangeReport(){
    this.reportStatus = 4;
    this.incomingItem.setAttributes({});
    this.incomingItem.setStartingAt(this.incomingReport.getStartingAt());
    this.incomingItem.setEndingAt(this.incomingReport.getEndingAt());
    this.openDateMenu();
  }

  onChangeDuration(duration: any) {
    setTimeout(() => {
      this.incomingDurations.setAttributes(Object.assign({}, this.incomingSingleFormReport.getDurations()));
      this.incomingDuration.setAttributes(Object.assign({}, this.incomingDurations.attributes.hasOwnProperty(duration) ? this.incomingDurations.getUnknown(duration) : {}));
      this.incomingReport.setDuration(duration);
      this.incomingReport.setSection('all');
      if (Object.keys(this.incomingDuration.getAttributes()).length === 0) {
        this.handleDashboard((call: any) => {
          this.dashbaord();
        });
      } else this.dashbaord();
    });
  }

  getGroup(options: any, timely: any, num: any, optName: any){
    const result: any = [];
    options.map((option: any) => {
      this.incomingOption.setAttributes(option);
      const responses: any = this.incomingOption.getAttributes()[timely].map((tm: any, key: any) => {
        this.incomingItem.setAttributes(tm);
        return this.incomingItem.getAttributes()[num];
      }); result.push({label: option[optName], data: responses});
    }); return result;
  }

  timeout(){return 94; }
  float(l: any){ return parseFloat(l); }


  openMenuDate(){
    this.outgoingRequest.setAttributes(this.exportExcel ? Object.assign({}, this.dashboardService.incomingReport.getAttributes()) : this.dashboardService.incomingReport.getAttributes());
    this.outgoingRequest.setDisplay(this.exportExcel ? 'excel'     : 'form-dashboard');
    this.outgoingRequest.setType(this.exportExcel    ? 'responses' : 'form-dashboard');
    if(this.outgoingRequest.attributes.hasOwnProperty('data')){
       if(this.exportExcel) delete this.outgoingRequest.attributes.data;
    } this.dialog.open(FilterDashboardComponent, { data: {report: this.outgoingRequest.getAttributes()} , width  : '55%', height : '100%'});
    this.exportExcel = false;
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.service.httpService( 'get', '../assets/json/reports.json?version=' + this.service.random(), {}, {},
          (response: any) => {
            this.charts = response;
          },  (error: any) => {
          });
      $.getScript( '../assets/js/easy-chart.js', ( data, textStatus, jqxhr ) => {});
      // window.localStorage.setItem('monthly', JSON.stringify(this.incomingReport.attributes.data.monthly));
      // window.localStorage.setItem('weekly' , JSON.stringify(this.incomingReport.attributes.data.weekly));
      $.getScript( '../assets/js/plot.js', ( data, textStatus, jqxhr ) => {});
      // $.getScript( '../assets/js/flot.js', ( data, textStatus, jqxhr ) => {});
    });
  }

  ngAfterContentInit(): void {
    $.getScript( '../assets/js/easy-chart.js', ( data, textStatus, jqxhr ) => {});
    this.service.httpService( 'get', this.appsettingsUrl + 'time.json?version=' + this.service.random() , {}, {},
        (response: any) => {
          this.durations = response;
          },  (error: any) => {});
    this.service.httpService( 'get', this.appsettingsUrl + 'func.json?version=' + this.service.random() , {}, {},
        (response: any) => {
          this.func = response;
        },  (error: any) => {
        });
  }

}
