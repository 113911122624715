import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChartService} from '../../chart.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Parameters} from '../../parameters';
import {AppService} from '../../app.service';
import {Options} from 'highcharts';
import * as Highcharts from 'highcharts';
import { Morris } from 'morris.js06';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-overall-report',
  templateUrl: './overall-report.component.html',
  styleUrls: ['./overall-report.component.scss']
})
export class OverallReportComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {
  @ViewChild('regionalCondemnedElement') regionalCondemnedElement: any;
  report: any = {}; industrial: any; carcasses: any; species: any; municipal: any; organs: any;
  theme: any; parameters: any; diseases: any; conditions: any; legends1: any = [];
  carcassDiseases: any; carcassConditions: any; organDiseases: any; organConditions: any;
  incomingResponse: any;
  outgoingRequest: any;
  Highcharts: typeof Highcharts = Highcharts;
  inspected: any; condemned: any; passed: any; regions: any;
  updateFlag = false;
  chartOptions: Options;
  app: any;
  metthods: any;
  charts: any = {
    echarts: {
      piechart: {
        species : {
          inspected: {},
          condemned: {},
          passed: {}
        },
        ante: null,
        industrial: null,
        municipal: null,
        diseases: {condemned: null},
        conditions: {condemned: null},
        carcasses: {
          condemned: null,
          diseases: {condemned: null},
          conditions: {condemned: null}
        },
        organs: {inspected: null, condemned: null, passed: null, diseases: {condemned: null}, conditions: {condemned: null}},
        regions: {condemned: null},
      },
      graph: {
      }
    },
    morries: {
      barchart: {
        carcasses: {options: null, data: null},
        organs: {options: null, data: null},
        industrial: {
          species: {options: null, data: null}
        },
        municipal: {
          species: {options: null, data: null}
        },
        diseases:   {options: null, data: null},
        conditions: {options: null, data: null},
        species:    {options: null, data: null},
      }
    },
    sparkline: {
      inspected: null,
      condemned: null,
      passed: null,
    }
  }


  constructor(
    private router: Router,
    private actveRoute: ActivatedRoute,
    public chart: ChartService,
    public serviceImpl: AppService,
    public broadcastChannel: BroadcastChannelService) {
    this.regionalCondemnedElement = ElementRef;
    this.incomingResponse = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingResponse.setAttributes(this.metthods.dashboard.attributes.data);
    this.outgoingRequest.setAttributes(Object.assign({}, this.metthods.dashboard.attributes));
    this.chartOptions = this.chart.sparkline('area', [], []);
    this.legends1 = ['inspected', 'condemned', 'passed'];
    this.theme  = this.chart.theme();
    this.app = this.serviceImpl.app;
    delete this.outgoingRequest.attributes.data;
    this.inspected = 0;
    this.condemned = 0;
    this.passed    = 0;
  }

  ngAfterContentChecked(): void {
    // throw new Error('Method not implemented.');
  }

  ngAfterViewChecked(): void {
    // throw new Error('Method not implemented.');
  }

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.handle('');
  }



  sparkline(type: string, data: any, xKey: string, legend: any){
    this.chartOptions = this.chart.sparkline(type, data.map((d: any) => {return d[xKey]; }) , data.map((d: any) => {return d[legend]; }));
    return this.chartOptions;
  }

  graph(data: any){
    this.parameters = this.chart.echartGraph(
      ['inspected', 'condemned', 'passed'],
      data.map((legend: any) => { return legend.name; }), {
        y1:  data.map((d: any) => { return d.inspected; }),
        y2:  data.map((d: any) => { return d.condemned; }),
        y3:  data.map((d: any) => { return d.passed; })
      }
    );
    return this.parameters;
  }

  regionalCharts(){
    setTimeout(()=>{
      new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'regional-post', data: this.regions, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
      new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'municipal-post', data: this.municipal.species, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
      new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'industrial-post', data: this.industrial.species, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    });

  }

  morrisGraph(){
    this.parameters =  this.chart.morrisJs(
      ['affected', 'condemned', 'passed'],
      ['affected', 'condemned', 'passed'],
      'name'
    );
    return this.parameters;
  }



  reload(){
    new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'ante', data: this.species, yLabelFormat:  (y: any) => {return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected',  'condemned', 'passed'], ['affected',  'condemned', 'passed'], 'name'), {element: 'post-alljudgmentConditions', data: this.diseases, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected',  'condemned', 'passed'], ['affected',  'condemned', 'passed'], 'name'), {element: 'post-conditions', data: this.conditions, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'pastoralists-post', data: this.organs, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name'), {element: 'alljudgmentConditions-post', data: this.carcasses, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected' , 'condemned', 'passed'], ['affected' , 'condemned', 'passed'], 'name'), {element: 'alljudgmentConditions-alljudgmentConditions', data: this.carcassDiseases, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected' , 'condemned', 'passed'], ['affected' , 'condemned', 'passed'], 'name'), {element: 'alljudgmentConditions-conditions', data: this.carcassConditions, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected' , 'condemned', 'passed'], ['affected' , 'condemned', 'passed'], 'name'), {element: 'pastoralists-alljudgmentConditions', data: this.organDiseases, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    new Morris.Bar(Object.assign(this.chart.morrisJs(['affected' , 'condemned', 'passed'], ['affected' , 'condemned', 'passed'], 'name'), {element: 'pastoralists-conditions', data: this.organConditions, yLabelFormat:  (y: any) => { return y === 0 ? '' : this.serviceImpl.numberWithCommans(y); }})).on('click', (index: any, item: any) => { });
    this.regionalCharts();
    this.charts.sparkline.inspected = this.chart.sparkline('area', this.species.map((specie: any) => { return specie.inspected; }), this.species.map((specie: any) => { return specie.name; }),);
    this.charts.sparkline.condemned = this.chart.sparkline('line', this.species.map((specie: any) => { return specie.condemned; }), this.species.map((specie: any) => { return specie.name; }),);
    this.charts.sparkline.passed    = this.chart.sparkline('line', this.species.map((specie: any) => { return specie.passed; }), this.species.map((specie: any) => { return specie.name; }),);
    setTimeout(()   => {
      this.charts.echarts.piechart.ante = this.chart.echartPie(['inspected', 'condemned', 'passed'], [{name: 'inspected', value: this.incomingResponse.attributes.industrial.inspected + this.incomingResponse.attributes.municipal.inspected}, {name: 'condemned', value: this.incomingResponse.attributes.industrial.condemned + this.incomingResponse.attributes.municipal.condemned}, {name: 'passed'   , value: this.incomingResponse.attributes.industrial.passed + this.incomingResponse.attributes.municipal.passed} ]);
      this.charts.echarts.piechart.species.inspected = this.chart.echartPie( this.species.map((specie: any) => { return specie.name; }), this.species.map((specie: any) => { return {name: specie.name, value: specie.inspected} ; }));
      this.charts.echarts.piechart.species.condemned = this.chart.echartPie(this.species.map((specie: any) => { return specie.name; }), this.species.map((specie: any) => { return {name: specie.name, value: specie.condemned} ; }));
      this.charts.echarts.piechart.species.passed = this.chart.echartPie(this.species.map((specie: any) => { return specie.name; }), this.species.map((specie: any) => { return {name: specie.name, value: specie.passed} ; }));
      this.charts.morries.barchart.carcasses.options = this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name');
      this.charts.morries.barchart.organs.options = this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name');
      this.charts.echarts.piechart.industrial = this.chart.echartPie(['inspected', 'condemned', 'passed'],[{name: 'inspected', value: this.industrial.inspected}, {name: 'condemned', value: this.industrial.condemned}, {name: 'passed'   , value: this.industrial.passed}]);
      this.charts.echarts.piechart.municipal = this.chart.echartPie(['inspected', 'condemned', 'passed'],[{name: 'inspected', value: this.municipal.inspected}, {name: 'condemned', value: this.municipal.condemned}, {name: 'passed'   , value: this.municipal.passed}]);
      this.charts.morries.barchart.industrial.species.options = this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name');
      this.charts.morries.barchart.municipal.species.options = this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name');
      this.charts.morries.barchart.diseases.options = this.chart.morrisJs(['affected', 'condemned', 'passed'], ['affected', 'condemned', 'passed'], 'name');
      this.charts.morries.barchart.conditions.options = this.chart.morrisJs(['affected', 'condemned', 'passed'], ['affected', 'condemned', 'passed'], 'name');
      this.charts.morries.barchart.species.options = this.chart.morrisJs(['inspected', 'condemned', 'passed'], ['inspected', 'condemned', 'passed'], 'name');

      this.charts.echarts.piechart.diseases.condemned = this.chart.echartPie(this.diseases.map((disease: any) => { return disease.name; }), this.diseases.map((disease: any) => { return {name: disease.name, value: disease.condemned}; }));
      this.charts.echarts.piechart.conditions.condemned = this.chart.echartPie(this.conditions.map((condition: any) => { return condition.name; }), this.conditions.map((condition: any) => { return {name: condition.name, value: condition.condemned}; }));
      this.charts.echarts.piechart.carcasses.inspected = this.chart.echartPie(this.carcasses.map((carcass: any) => { return carcass.name; }), this.carcasses.map((carcass: any) => { return {name: carcass.name, value: carcass.inspected}; }));
      this.charts.echarts.piechart.carcasses.condemned = this.chart.echartPie(this.carcasses.map((carcass: any) => { return carcass.name; }), this.carcasses.map((carcass: any) => { return {name: carcass.name, value: carcass.condemned}; }));
      this.charts.echarts.piechart.carcasses.passed = this.chart.echartPie(this.carcasses.map((carcass: any) => { return carcass.name; }), this.carcasses.map((carcass: any) => { return {name: carcass.name, value: carcass.passed}; }));
      this.charts.echarts.piechart.organs.inspected = this.chart.echartPie(this.organs.map((organ: any) => { return organ.name; }), this.organs.map((organ: any) => { return {name: organ.name, value: organ.inspected}; }));
      this.charts.echarts.piechart.organs.condemned = this.chart.echartPie(this.organs.map((organ: any) => { return organ.name; }), this.organs.map((organ: any) => { return {name: organ.name, value: organ.condemned}; }));
      this.charts.echarts.piechart.organs.passed = this.chart.echartPie(this.organs.map((organ: any) => { return organ.name; }), this.organs.map((organ: any) => { return {name: organ.name, value: organ.passed}; }));
      this.charts.echarts.piechart.carcasses.diseases.condemned = this.chart.echartPie(this.carcassDiseases.map((disease: any) => { return disease.name; }), this.carcassDiseases.map((disease: any) => { return {name: disease.name, value: disease.condemned}; }));
      this.charts.echarts.piechart.carcasses.conditions.condemned = this.chart.echartPie(this.carcassConditions.map((condition: any) => { return condition.name; }), this.carcassConditions.map((condition: any) => { return {name: condition.name, value: condition.condemned}; }));
      this.charts.echarts.piechart.regions.condemned = this.chart.echartPie(this.regions.map((region: any) => { return region.name; }), this.regions.map((region: any) => { return {name: region.name, value: region.condemned}; }));
      this.charts.echarts.piechart.organs.diseases.condemned = this.chart.echartPie(this.organDiseases.map((disease: any) => { return disease.name; }), this.organDiseases.map((disease: any) => { return {name: disease.name, value: disease.condemned}; }));
      this.charts.echarts.piechart.organs.conditions.condemned = this.chart.echartPie(this.organConditions.map((condition: any) => { return condition.name; }), this.organConditions.map((condition: any) => { return {name: condition.name, value: condition.condemned}; }));
    }, 1000);
  }



  percentage(numberr: any, over: any){
    this.parameters = this.serviceImpl.percentageOf(numberr, over);
    return this.parameters;
  }

  getNames(data: any){return data.map((row: any) => { return row.name;})}
  getValues(data: any, value: string){return data.map((row: any) => { return {name: row.name, value: row[value]};})}

  handle(item: any){
    setTimeout((regions: any, districts: any, carcassDiseases: any, carcassConditions
      : any,  diseases: any, organDiseases: any, organConditions: any, conditions: any, industrial: any = {}, municipal: any = {}, industrialSpecies: any, municipalSpecies: any, species: any = [], position: any, carcasses: any, organs: any) => {
      districts = this.incomingResponse.attributes.regions.map((region: any) => {return region.districts;});
      districts = districts.flat(1);
      this.regions = this.metthods.dashboard.attributes.data.regions;

      industrialSpecies = districts.map((district: any) => { return district.industrial.species; });
      industrialSpecies = industrialSpecies.flat(1);
      municipalSpecies = districts.map((district: any) => { return district.municipal.species; });
      municipalSpecies = municipalSpecies.flat(1);
      species = Array.prototype.concat(industrialSpecies, municipalSpecies);
      // species = this.metthods.dashboard.attributes.target === 'regions' ? (this.metthods.dashboard.attributes.data.regions.map((region: any) => { return region.species; })).flat(1) : species;
      species = this.metthods.dashboard.attributes.target === 'overall' ? this.metthods.dashboard.attributes.data.species : species;
      species = this.metthods.dashboard.attributes.target === 'regions' ? this.metthods.dashboard.attributes.data.species : species;

      diseases = species.map((specie: any) => { return specie.diseases; });
      diseases = diseases.flat(1);
      diseases = diseases.reduce((outgoing: any, disease: any) => {
        position = outgoing.findIndex((incoming: any) => {
          return incoming.id === disease.id;});
        if (position === -1) outgoing.push(Object.assign({}, disease)); else {
          outgoing[position].affected  += disease.affected;
          outgoing[position].condemned += disease.condemned;
          outgoing[position].passed    += disease.passed;
        }return outgoing;
      }, []);
      this.diseases = diseases;

      conditions = species.map((specie: any) => {return specie.conditions; });
      conditions = conditions.flat(1);
      conditions = conditions.reduce((outgoing: any, condition: any) => {
        position = outgoing.findIndex((incoming: any) => {
          return incoming.id === condition.id; });
        if (position === -1) outgoing.push(Object.assign({}, condition)); else {
          outgoing[position].affected  += condition.affected;
          outgoing[position].condemned += condition.condemned;
          outgoing[position].passed    += condition.passed;
        }return outgoing;
      }, []);
      this.conditions = conditions;

      species = species.reduce((outgoing: any, specie: any) => {
        position = outgoing.findIndex((incomingSpecie: any) => { return incomingSpecie.id === specie.id;});
        if (position === -1) outgoing.push(Object.assign({}, specie)); else {
          outgoing[position].inspected += parseFloat(specie.inspected);
          outgoing[position].condemned += parseFloat(specie.condemned);
          outgoing[position].passed    += parseFloat(specie.passed);
          outgoing[position].diseases = Array.prototype.concat(outgoing[position].diseases, specie.diseases);
          outgoing[position].conditions = Array.prototype.concat(outgoing[position].conditions, specie.conditions);
          outgoing[position].carcasses = Array.prototype.concat(outgoing[position].carcasses, specie.carcasses);
          outgoing[position].organs    = Array.prototype.concat(outgoing[position].organs, specie.organs);
          outgoing[position].diseases  = outgoing[position].diseases.flat(1);
          outgoing[position].conditions = outgoing[position].conditions.flat(1);
          outgoing[position].carcasses = outgoing[position].carcasses.flat(1);
          outgoing[position].organs    = outgoing[position].organs.flat(1);

        } return outgoing;
      }, []);
      this.species = species;


      this.species.map((specie: any) => {
        this.inspected += specie.inspected;
        this.condemned += specie.condemned;
        this.passed    += specie.passed;
      });

      carcasses = species.map((specie: any) => { return specie.carcasses.map((carcass: any) => { return Object.assign(carcass,{specie: {name: specie.name}}); });});
      carcasses = carcasses.flat(1);
      carcasses = carcasses.reduce((outgoing: any, carcass: any) => {
        position = outgoing.findIndex((incomingcarcass: any) => {
          return incomingcarcass.id === carcass.id;});
        if (position === -1) outgoing.push(Object.assign({}, carcass)); else {
          outgoing[position].inspected += carcass.inspected;
          outgoing[position].condemned += carcass.condemned;
          outgoing[position].passed    += carcass.passed;
          outgoing[position].diseases   = Array.prototype.concat(outgoing[position].diseases, carcass.diseases);
          outgoing[position].conditions = Array.prototype.concat(outgoing[position].conditions, carcass.conditions);
          outgoing[position].diseases   = outgoing[position].diseases.flat(1);
          outgoing[position].conditions = outgoing[position].conditions.flat(1);
        }return outgoing;
      }, []);
      this.carcasses = carcasses;

      carcassDiseases = carcasses.map((carcass: any) => { return carcass.diseases; });
      carcassDiseases = carcassDiseases.flat(1);
      carcassDiseases = carcassDiseases.reduce((outgoing: any, disease: any) => {
        position = outgoing.findIndex((incomingdisease: any) => {
          return incomingdisease.id === disease.id;});
        if (position === -1) outgoing.push(Object.assign({}, disease)); else {
          outgoing[position].inspected += disease.inspected;
          outgoing[position].condemned += disease.condemned;
          outgoing[position].passed    += disease.passed;
        } return outgoing;
      }, []);
      this.carcassDiseases = carcassDiseases;

      carcassConditions = carcasses.map((carcass: any) => { return carcass.conditions; });
      carcassConditions = carcassConditions.flat(1);
      carcassConditions = carcassConditions.reduce((outgoing: any, condition: any) => {
        position = outgoing.findIndex((c: any) => {
          return c.id === condition.id; });
        if (position === -1) outgoing.push(Object.assign({}, condition)); else {
          outgoing[position].inspected += condition.inspected;
          outgoing[position].condemned += condition.condemned;
          outgoing[position].passed    += condition.passed;
        } return outgoing;
      }, []);
      this.carcassConditions = carcassConditions;

      organs = species.map((specie: any) => { return specie.organs.map((organ: any) => { return Object.assign(organ,{specie: {name: specie.name}}); });});
      organs = organs.flat(1);
      organs = organs.reduce((outgoing: any, organ: any) => {
        position = outgoing.findIndex((incomingorgan: any) => {
          return incomingorgan.id === organ.id; });
        if (position === -1) outgoing.push(Object.assign({}, organ)); else {
          outgoing[position].inspected += organ.inspected;
          outgoing[position].condemned += organ.condemned;
          outgoing[position].passed += organ.passed ;
          outgoing[position].diseases   = Array.prototype.concat(outgoing[position].diseases, organ.diseases);
          outgoing[position].conditions = Array.prototype.concat(outgoing[position].conditions, organ.conditions);
          outgoing[position].diseases   = outgoing[position].diseases.flat(1);
          outgoing[position].conditions = outgoing[position].conditions.flat(1);
        }
        return outgoing;
      }, []);
      this.organs = organs;

      organDiseases = organs.map((organ: any) => { return organ.diseases; });
      organDiseases = organDiseases.flat(1);
      organDiseases = organDiseases.reduce((outgoing: any, disease: any) => {
        position = outgoing.findIndex((incomingdisease: any) => {return incomingdisease.id === disease.id;});
        if (position === -1) outgoing.push(Object.assign({}, disease)); else {
          outgoing[position].inspected += disease.inspected;
          outgoing[position].condemned += disease.condemned;
          outgoing[position].passed    += disease.passed;
        } return outgoing;
      }, []);
      this.organDiseases = organDiseases;

      organConditions = organs.map((organ: any) => { return organ.conditions; });
      organConditions = organConditions.flat(1);
      organConditions = organConditions.reduce((outgoing: any, condition: any) => {
        position = outgoing.findIndex((incomingCondition: any) => {return incomingCondition.id === condition.id;});
        if (position === -1) outgoing.push(Object.assign({}, condition)); else {
          outgoing[position].inspected += condition.inspected;
          outgoing[position].condemned += condition.condemned;
          outgoing[position].passed    += condition.passed;
        } return outgoing;
      }, []);
      this.organConditions = organConditions;

      industrialSpecies = industrialSpecies.reduce((outgoing: any, specie: any) => {
        position = outgoing.findIndex((incomingSpecie: any) => {
          return incomingSpecie.id === specie.id;});
        if (position === -1) outgoing.push(Object.assign({}, specie)); else {
          outgoing[position].inspected += specie.inspected;
          outgoing[position].condemned += specie.condemned;
          outgoing[position].passed += specie.passed;
        }return outgoing;
      }, []);
      industrial.inspected = 0; industrial.condemned = 0; industrial.passed = 0;
      industrialSpecies.map((specie: any) => {
        industrial.inspected += specie.inspected;
        industrial.condemned += specie.condemned;
        industrial.passed    += specie.passed;
      });
      industrial = Object.assign(industrial, {species: industrialSpecies});
      this.industrial = industrial;

      municipalSpecies = municipalSpecies.reduce((outgoing: any, specie: any) => {
        position = outgoing.findIndex((incomingSpecie: any) => {
          return incomingSpecie.id === specie.id;});
        if (position === -1) outgoing.push(Object.assign({}, specie)); else {
          outgoing[position].inspected += specie.inspected;
          outgoing[position].condemned += specie.condemned;
          outgoing[position].passed += specie.passed;
        }return outgoing;
      }, []);
      municipal.inspected = 0; municipal.condemned = 0; municipal.passed = 0;
      municipalSpecies.map((specie: any) => {
        municipal.inspected += specie.inspected;
        municipal.condemned += specie.condemned;
        municipal.passed    += specie.passed;
      });
      municipal = Object.assign(municipal, {species: municipalSpecies});
      this.municipal = municipal;

      this.reload();
    });
  }

  piechart(legend: any,data: any){
    return  this.chart.echartPie(legend, data);
  }



}
