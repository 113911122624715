<div [hidden]='true'>
    <center>
        <img class="img-circle" src="{{service.app.data.clients.data[0].logo}}" style="width:40px;height:40px;margin-top: 10px" >
    </center>
</div>
<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="row top_tiles">
        <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="tile-stats" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
<!--                <table [ngStyle]="{'width':'100%'}">-->
<!--                    <td [ngStyle]="{'width':'100%'}" valign="top"><fake class='headline selectedMatTab'>{{service.header}}</fake></td>-->
<!--                    <td class='headline selectedMatTab'>cdsbcjdhdfd</td>-->
<!--                </table>-->
                <div class="row plist" [ngStyle]="{'margin-top': '2%'}">
                    <div class="col-md-0"></div>
                    <div class="col-md-12">
                        <app-dashboard-form></app-dashboard-form>
                    </div>
                    <div class="col-md-0"></div>
                </div>
            </div>
        </div>
    </div>
</div>