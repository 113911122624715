import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {FormService} from "../forms/form.service";
import {AppService} from "../app.service";
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  data: any = null;

  incomingReport: any;

  incomingSelectedForm: any;

  formId: any;

  constructor(private formService: FormService, private appService: AppService) {
    /**
     * @param mixed null
     */

    this.incomingSelectedForm = new Parameters();
    /**
     * @param mixed null
     */

    this.incomingSelectedForm.setAttributes({});
    /**
     * @param mixed null
     */

    this.incomingReport = new Parameters();
    /**
     * @param mixed null
     */

    this.incomingReport.setAttributes({});

    /**
     * @param mixed null
     */

    this.incomingReport.setTime('datebetween');
    /**
     * @param mixed null
     */

    this.incomingReport.setSection('all');
    /**
     * @param mixed null
     */

    this.incomingReport.setDuration('custom');
    /**
     * @param mixed null
     */

    this.incomingReport.setTarget('all');

    /**
     * @param mixed null
     */


    this.incomingReport.setFormId(0);

    /**
     * @param mixed null
     */


    this.incomingReport.setType('form-dashboard');
    /**
     * @param mixed null
     */

    this.incomingReport.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed null
     */


    this.incomingReport.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed null
     */

    this.incomingReport.setSearchText('');

    /**
     * @param mixed null
     */


    this.incomingReport.setDisplay('dashbaord');

    /**
     * @param mixed null
     */


    this.incomingReport.setData(null);
  }

  getFormId(){
    return this.formId;
  }

  /**
   * @param mixed null
   */

  setFormId(formId: any){

    this.formId = formId;

  }


  handleDashboard(callback: any){
    this.incomingSelectedForm.setAttributes(this.formService.getSelected());
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingSelectedForm.attributes.id});
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      this.appService.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.incomingReport.attributes.data = response;
        callback({response: 'successfull'});
      }, (error: any) => {
      });
    });
  }
}
