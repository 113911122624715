import {
    AfterViewInit,
    AfterContentInit,
    Component,
    NgZone,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../app.service';
import {Parameters} from '../../../parameters';
import {FormService} from '../../form.service';
import * as $ from 'jquery';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {NotifierService} from 'angular-notifier';
import {orderBy} from 'lodash';
import * as moment from 'moment';
import {MatMenuTrigger} from '@angular/material/menu';
import {PdfFormService} from '../../pdf/pdf-form.service';
import {ResponseService} from '../data/response.service';
import {DeleteDialogComponent} from '../../../main/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {event} from 'jquery';
import {MessageDisplayComponent} from '../../../main/message/message-display.component';
import {StorageMap} from '@ngx-pwa/local-storage';
import {CampaignService} from '../../../campaigns/campaign.service';
import {UserService} from '../../../users/user.service';
import {Geolocation, GeolocationPosition} from '@capacitor/geolocation';

@Component({
    selector: 'app-response-form',
    templateUrl: './respond-form.component.html',
    styleUrls: ['./respond-form.component.scss']
})
export class RespondFormComponent implements OnInit, AfterViewInit, OnChanges, AfterContentInit, OnDestroy {
    @ViewChild('nextButtonElement') nextButtonElement: any;
    @ViewChild('rollbackButtonElement') rollbackButtonElement: any;
    @ViewChild(MatMenuTrigger) contextMenu: any;
    contextMenuPosition = {x: '0px', y: '0px'};
    formId: any;
    publicFormUrl = '/public/forms/';
    appsettingsUrl = '../assets/json/app.json';
    loginForm: any;
    form: any;
    position: any = 0;
    question: any;
    subscription: any;
    incomingTeam: any;
    commandlne: any = false;
    options: any = [];
    startQuestion: any;
    incomingStrings: any;
    panel: any = null;
    mainPanel: any = null;
    outgoingRequest: any;
    posts: any = [];
    questions: any = [];
    email: any = null;
    departmentId: any = 1;
    ancestors: any = [];
    all: any = 0;
    written: any = 0;
    progress: any = 0;
    incomingResponseSelected: any;
    lat: any = 0;
    lng: any = 0;
    altitude: any = 0;
    incomingQuestion: any;
    incomingForm: any;
    incomingOption: any;
    incomingItem: any;
    incomingAncestor: any;
    incomingQ: any;
    outgoingOption: any;
    parentValues: any;
    option: any;
    opt: any;
    files: any = [];
    outgoingImgRequest: any;
    responses: any = [];
    incomingResponse: any;
    incomingFilterdQuestion: any;
    interval: any;
    incomingUser: any;
    incomingQuestions: any;
    incomingQuestionOptions: any;
    finalQuestions: any = [];
    incomingOptions: any;
    incomingOtherOptions: any;
    others: any = [];
    document: any = false;
    nextButtonStatus = true;
    outgoingResponseRequest: any;
    responseSavedStatus: any = true;
    incomingConditions: any;
    outgoingQuestion: any;
    incomingOutstandingQuestion: any;
    icon: any;
    zoom: any = 15;
    waypoints: any = [];
    incomingOrigin: any;
    incomingDestination: any;
    prevWindow: any = null;
    openedWindow: any = null;
    backgroundColor: any = '#ebebeb';
    loc: GeolocationPosition;
    incomingClient: any;
    incomingCampaign: any;
    incomingResult: any;
    incomingOutlet: any;
    result: any;
    incomingFormSettings: any;
    incomingQuestionSettings: any;
    positionOptions: any = {
        // timeout: 80000,
        // maximumAge: 60000,
        enableHighAccuracy: true,
    };
    incomingAddress1: any;
    incomingAddress2: any;
    incomingAddress3: any;
    incomingAddress4: any;
    incomingAddress5: any;
    incomingAddress: any;
    incomingReference4: any;

    constructor(public service: AppService,
                public formService: FormService,
                private responseService: ResponseService,
                public router: Router,
                public zone: NgZone,
                private pdfService: PdfFormService,
                private campaignService: CampaignService,
                private userService: UserService,
                private notifierService: NotifierService,
                private broadcastChannel: BroadcastChannelService,
                private dialog: MatDialog,
                private storage: StorageMap,
                private activatedRoute: ActivatedRoute) {
        this.formId = this.activatedRoute.snapshot.params.id;
        this.formService.others = [];
        this.contextMenu = MatMenuTrigger;
        this.incomingQuestions = new Parameters();
        this.incomingStrings = new Parameters();
        this.incomingItem = new Parameters();
        this.incomingTeam = new Parameters();
        this.incomingReference4 = new Parameters();
        this.incomingOutlet = new Parameters();
        this.incomingResponseSelected = new Parameters();
        this.incomingUser = new Parameters();
        this.incomingQuestionSettings = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingForm = new Parameters();
        this.outgoingQuestion = new Parameters();
        this.incomingOption = new Parameters();
        this.incomingAncestor = new Parameters();
        this.incomingOutstandingQuestion = new Parameters();
        this.incomingResponse = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingOtherOptions = new Parameters();
        this.incomingConditions = new Parameters();
        this.incomingCampaign = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.incomingAddress1 = new Parameters();
        this.incomingAddress2 = new Parameters();
        this.incomingAddress3 = new Parameters();
        this.incomingAddress4 = new Parameters();
        this.incomingAddress5 = new Parameters();
        this.incomingAddress = new Parameters();
        this.incomingClient = new Parameters();
        this.parentValues = new Parameters();
        this.outgoingImgRequest = new Parameters();
        this.incomingQ = new Parameters();
        this.outgoingOption = new Parameters();
        this.incomingOptions = new Parameters();
        this.incomingQuestionOptions = new Parameters();
        this.outgoingResponseRequest = new Parameters();
        this.incomingOrigin = new Parameters();
        this.incomingDestination = new Parameters();
        this.outgoingRequest = new Parameters();
        this.nextButtonElement = ElementRef;
        this.incomingUser.setAttributes({});
        this.incomingForm.setAttributes(Object.keys(this.formService.selected).length === 0 ? {} : this.formService.selected);
        this.incomingStrings.setAttributes(this.service.app.strings.params);
        this.icon = { url: 'https://i.imgur.com/7teZKif.png', labelincomingOrigin: {x: 25, y: 34}, scaledSize: {height: 20, width: 20}};
      // document.getElementsByTagName( 'body')[0].style.backgroundColor = '#263238';F7F7F7
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    setGroupBy(){
        setTimeout(() => {
            this.formService.questions.map((question: any) => {
                const incomingQuestion: any = new Parameters();
                const incomingResult: any = new Parameters();
                const incomingSettings: any = new Parameters();
                incomingQuestion.setAttributes(question);
                incomingQuestion.setResult(
                    incomingQuestion.getAttributes().hasOwnProperty('result') &&
                    !this.service.isNull(incomingQuestion.getResult()) ? incomingQuestion.getResult() : {});
                incomingQuestion.setSettings(
                    incomingQuestion.getAttributes().hasOwnProperty('settings') &&
                    !this.service.isNull(incomingQuestion.getSettings()) ? incomingQuestion.getSettings() : {});
                incomingResult.setAttributes(incomingQuestion.getResult());
                incomingSettings.setAttributes(incomingQuestion.getSettings());
                if (incomingQuestion.getAttributes().hasOwnProperty('ancestor')) {
                    const incomingAncesstor: any = new Parameters();
                    incomingAncesstor.setAttributes(this.formService.getQuestionByCntrlNum(incomingQuestion.getUnknown('ancestor')));
                    if (parseFloat(incomingAncesstor.getControlId()) === 18) {
                        const incomingOption: any = new Parameters();
                        incomingOption.setAttributes(
                            this.service.getJSONObjectByJSONArrayAndColumnAndValue(incomingAncesstor.getOptions(),
                                'cntrl_num',
                                incomingQuestion.getOption().cntrlNum));
                        incomingResult.setGroupBy(incomingOption.getCntrlNum());
                        if (!incomingSettings.getAttributes().hasOwnProperty('group_by')) {
                            incomingSettings.setGroupBy(incomingOption.getCntrlNum());
                            this.updateQuestionSettings(incomingQuestion.getAttributes());
                        }
                    } else {
                        this.getAncesstor(incomingAncesstor.getAttributes(), (result) => {
                            const incomingOption: any = new Parameters();
                            incomingOption.setAttributes(result);
                            incomingResult.setGroupBy(incomingOption.getCntrlNum());
                            if (!incomingSettings.getAttributes().hasOwnProperty('group_by')) {
                                incomingSettings.setGroupBy(incomingOption.getCntrlNum());
                                this.updateQuestionSettings(incomingQuestion.getAttributes());
                            }
                        });
                    }
                }
            });
        }, this.service.timeout() * 6);
    }

    updateQuestionSettings(question: any) {
        const incomingQuestion: any = new Parameters();
        incomingQuestion.setAttributes(question);
        incomingQuestion.setSettings(incomingQuestion.getAttributes().hasOwnProperty('settings') ?
            incomingQuestion.getSettings() : {});
        const ougoingRequest: any = new Parameters();
        ougoingRequest.setAttributes({});
        ougoingRequest.setId(incomingQuestion.getId());
        ougoingRequest.setSettings(JSON.stringify(incomingQuestion.getSettings()));
        this.service.httpService('post', this.service.app.routes.questions.settings.update,
            ougoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
            }, (error: any) => {
            });
    }


    getAncesstor(question: any, callback: any){
        const incomingQuestion: any = new Parameters();
        incomingQuestion.setAttributes(question);
        if (incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
            const  incomingAncesstor: any = new Parameters();
            incomingAncesstor.setAttributes(this.formService.getQuestionByCntrlNum(incomingQuestion.getUnknown('ancestor')));
            if (parseFloat(incomingAncesstor.getControlId()) === 18){
                if (incomingQuestion.getAttributes().hasOwnProperty('option')){
                    const incomingOption: any = new Parameters();
                    incomingOption.setAttributes(
                        this.service.getJSONObjectByJSONArrayAndColumnAndValue(incomingAncesstor.getOptions(),
                            'cntrl_num',
                            incomingQuestion.getOption().cntrlNum));
                    callback(incomingOption.getAttributes());
                }
            }else { this.getAncesstor(incomingAncesstor.getAttributes(), callback); }
        }
    }

    setBackgroundColor(){
        setTimeout(() => {
            this.incomingFormSettings.setAttributes(this.formService.selected.settings);
            this.incomingFormSettings.setHeader(
                this.incomingFormSettings.getAttributes().hasOwnProperty(
                    this.incomingStrings.getHeader()) ?
                    this.incomingFormSettings.getHeader() : 1);
            this.incomingFormSettings.setSubmit(
                this.incomingFormSettings.getAttributes().hasOwnProperty(
                    this.incomingStrings.getSubmit()) ?
                    this.incomingFormSettings.getSubmit() : '');
            this.backgroundColor = this.incomingFormSettings.getAttributes().hasOwnProperty(
                this.incomingStrings.getBackgroundColor()) ?
                this.incomingFormSettings.getBackgroundColor() : this.backgroundColor;
            this.formService.color = this.incomingFormSettings.getAttributes().hasOwnProperty(
                this.incomingStrings.getColor()) ?
                this.incomingFormSettings.getColor() :
                this.formService.color;
            document.getElementsByTagName( this.incomingStrings.getBody())[0].style.backgroundColor = this.backgroundColor;
            this.startGeolocation();
        });
    }

    startGeolocation(){
        this.incomingFormSettings.setIsGPSTracked(this.incomingFormSettings.getAttributes().hasOwnProperty(
            this.incomingStrings.getIsGPSTracked()) ?
            this.incomingFormSettings.getIsGPSTracked() : 0);
        this.incomingFormSettings.setIsGPSShouldCaptured(this.incomingFormSettings.getAttributes().hasOwnProperty(
            this.incomingStrings.getIsGPSShouldCaptured()) ?
            this.incomingFormSettings.getIsGPSShouldCaptured() : 0);
//      this.incomingFormSettings.setAddress(this.incomingFormSettings.hasAttribute('address') ? this.incomingFormSettings.getAddress() : '');
        this.incomingResponse.setLat(this.incomingResponse.getAttributes().hasOwnProperty(
            this.incomingStrings.getLat()) ?
            this.incomingResponse.getLat() : 0);
        if (parseFloat(this.incomingFormSettings.getIsGPSTracked()) === 1){
            Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
                this.zone.run(() => {
                    try{if (parseFloat(this.incomingResponse.getLat()) === 0 && parseFloat(pos.coords.latitude) !== 0){
                            this.lat = parseFloat(pos.coords.latitude.toString());
                            this.lng = parseFloat(pos.coords.longitude.toString());
                            this.altitude = this.service.isNull(pos.coords.altitude) ? 0 : pos.coords.altitude;
                            this.incomingOrigin.setAttributes({});
                            this.incomingOrigin.setLat(pos.coords.latitude);
                            this.incomingOrigin.setLng(pos.coords.longitude);
                            const outgoingRequest: any = new Parameters();
                            outgoingRequest.setAttributes({});
                            outgoingRequest.setLat(pos.coords.latitude.toString());
                            outgoingRequest.setLng(pos.coords.longitude.toString());
                            //          this.service.getDrivingDistanceBetweenTwoLatLong({lat:19.432608, lng:-99.133209}, {lat:40.730610, lng:-73.935242});
                            this.incomingResponse.setLat(outgoingRequest.getLat());
                            this.incomingResponse.setLng(outgoingRequest.getLng());
                            this.service.httpService('post', this.service.app.routes.home.address.get,
                                outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
                                    this.incomingAddress.setAttributes(result);
                                    this.incomingAddress1.setAttributes(this.incomingAddress.getAddress1());
                                    this.incomingAddress2.setAttributes(this.incomingAddress.getAddress2());
                                    this.incomingAddress3.setAttributes(this.incomingAddress.getAddress3());
                                    this.incomingAddress4.setAttributes(this.incomingAddress.getAddress4());
                                    this.incomingResponse.setAddress1(this.incomingAddress1.getPlaceId());
                                    this.incomingResponse.setAddress2(this.incomingAddress2.getPlaceId());
                                    this.incomingResponse.setAddress3(this.incomingAddress3.getPlaceId());
                                    this.incomingResponse.setAddress4(this.incomingAddress4.getPlaceId());
                                    this.incomingResponse.setAddress(this.incomingAddress.getAddress());
                                    this.incomingResponse.setLat(outgoingRequest.getLat());
                                    this.incomingResponse.setLng(outgoingRequest.getLng());
                                }, (error: any) => {
                                });
                        }
                    }catch (e) {}
                });
            });
        }
    }

    ngOnInit(): void {
        this.setSettings(() => {
                this.formId = window.location.href.includes('/register') ? this.formId : this.service.decrypt(this.formId);
                this.publicFormUrl = this.publicFormUrl + this.formId;
                this.loginForm = new Parameters();
                this.loginForm.setAttributes({});
                this.loginForm.setPhone('');
                this.loginForm.setPassword('admin');
                if (this.responseService.selected.hasOwnProperty('id') &&
                    !(window.location.href.toString().includes(this.incomingStrings.getSuggestions()) ||
                        window.location.href.toString().includes(this.incomingStrings.getRegister()) ||
                        window.location.href.toString().includes(this.incomingStrings.getFeedback()))){
                    this.responseSavedStatus = false;
                    this.incomingResponse.setAttributes(this.responseService.getSelected());
                    this.incomingForm.setAttributes(this.incomingResponse.getForm());
                    this.formService.setSelected(this.incomingForm.getAttributes());
                    if (this.incomingResponse.getAttributes().hasOwnProperty(this.incomingStrings.getStatus())){
                        this.storage.get(this.incomingStrings.getResponses()).subscribe((responses: any) => {
                            if (responses) {
                                this.responses = responses;
                                this.incomingResponse.setAttributes(
                                    this.responses.filter((response: any) => {
                                        const incomingFilterResponse: any = new Parameters();
                                        incomingFilterResponse.setAttributes(response);
                                        incomingFilterResponse.getCntrlNum().toString().includes(
                                            this.responseService.responseSelectedCntrlNum.toString()); })[0]);
                                this.incomingResponse.setStatus(this.incomingResponse.getAttributes().hasOwnProperty(
                                    this.incomingStrings.getStatus()) ?
                                    this.incomingResponse.getStatus() : 1);
                                this.incomingForm.setAttributes(this.incomingResponse.getForm());
                                this.formService.setSelected(this.formService.getFormById(this.incomingForm.getId()));
                                this.form = this.incomingResponse.getAttributes().form;
                                this.setSelectedForm(this.incomingResponse.getAttributes().form);
                            }
                        });
                    }else { this.setSelectedForm(this.incomingResponse.getAttributes().form); }
                    this.setBackgroundColor();
                }else if (this.service.hasToken() &&
                    !(window.location.href.toString().includes(this.incomingStrings.getSuggestions()) ||
                        window.location.href.toString().includes(this.incomingStrings.getRegister()) ||
                        window.location.href.toString().includes(this.incomingStrings.getFeedback()))){
                    this.storage.get(this.incomingStrings.getResponses()).subscribe((responses: any) => {
                        if (responses){
                            this.storage.get(this.incomingStrings.getOthers()).subscribe((others: any) => {
                                this.responses = responses;
                                this.incomingResponse.setAttributes(this.responses.filter((response: any) => {
                                    return response.cntrl_num.includes(this.responseService.responseSelectedCntrlNum); })[0]);
                                this.responseService.selected = this.incomingResponse.getAttributes();
                                this.incomingQuestions.setAttributes(
                                    this.service.getJSObjectByArrayAndLabel(
                                        this.incomingResponse.getAttributes().form.controls,
                                        this.incomingStrings.getCntrlNum()));
                                this.formService.setSelected(this.formService.getFormById(this.formId));
                                this.form = this.incomingResponse.getAttributes().form;
                                this.incomingForm.setAttributes(this.form);
                                if(this.incomingForm.getAttributes().hasOwnProperty(this.incomingStrings.getReference4())){
                                    this.incomingReference4.setAttributes(this.incomingForm.getReference4());
                                    if(this.incomingReference4.getAttributes().hasOwnProperty(this.incomingStrings.getTeamId())){
                                        this.incomingTeam.setAttributes(this.incomingReference4.getAttributes());
                                        this.incomingTeam.setId(this.incomingTeam.getTeamId());
                                        this.incomingResponse.setTeamId(this.incomingTeam.getId());
                                    }
                                }
                                this.formService.formSelectedId = this.formId;
                                this.formService.selected.controls.map((question: any) => {
                                    this.incomingQuestion.setAttributes(Object.assign({}, question));
                                    if (this.incomingQuestions.getAttributes().hasOwnProperty(this.incomingQuestion.getCntrlNum())) {
                                        this.incomingQuestionOptions.setAttributes(
                                            this.service.getJSObjectByArrayAndLabel(
                                                this.incomingQuestions.getUnknown(
                                                    this.incomingQuestion.getCntrlNum()).options,
                                                this.incomingStrings.getCntrlNum()));
                                        this.incomingOptions.setAttributes(
                                            this.service.getJSObjectByArrayAndLabel(
                                                this.incomingQuestion.getOptions(),
                                                this.incomingStrings.getCntrlNum()));
                                        if (others){
                                            if (others instanceof Array) {
                                                others.map((option: any) => {
                                                    this.incomingOption.setAttributes(option);
                                                    if (this.incomingOption.getAttributes().hasOwnProperty('questionCntrlNum')) {
                                                        if (this.incomingQuestion.getCntrlNum().toString() === this.incomingOption.getAttributes().questionCntrlNum.toString()) {
                                                            if (this.incomingOptions.getAttributes().hasOwnProperty(this.incomingOption.getCntrlNum())) {
                                                                this.incomingOptions.getAttributes()[this.incomingOption.getCntrlNum()] = Object.assign(
                                                                    this.incomingOptions.getAttributes()[this.incomingOption.getCntrlNum()], this.incomingOption.getAttributes());
                                                            } else { this.incomingQuestion.getOptions().push(this.incomingOption.getAttributes()); }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        this.incomingQuestion.setData(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).data);
                                        this.incomingQuestion.setResult(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).hasOwnProperty('result')  ? Object.assign({}, this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).result) : {});
                                        if (parseFloat(this.incomingQuestion.getControlId())  === 4 ||
                                            parseFloat(this.incomingQuestion.getControlId()) === 9 ||
                                            parseFloat(this.incomingQuestion.getControlId()) === 24 ||
                                            parseFloat(this.incomingQuestion.getControlId()) === 27){
                                            this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).options.map((option: any) => {
                                                this.incomingOption.setAttributes(option);
                                                this.position = this.service.findIndex(
                                                    this.incomingQuestion.getOptions(), this.incomingStrings.getCntrlNum(),
                                                    this.incomingOption.getCntrlNum());
                                                if (this.position !== parseFloat('-1')){
                                                    this.incomingOption.setSettings(this.incomingQuestion.getOptions()[this.position].settings);
                                                    this.incomingOption.setQuestions(this.incomingQuestion.getOptions()[this.position].questions);
                                                }
                                            });
                                            this.incomingQuestion.setOptions(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).options);
                                        }
                                        this.incomingQuestion.setIsNull(this.incomingQuestions.getUnknown(
                                            this.incomingQuestion.getCntrlNum()).hasOwnProperty('is_null') ?
                                            this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).is_null : true);
                                        this.incomingQuestion.setPageYOffset(this.incomingQuestions.getUnknown(
                                            this.incomingQuestion.getCntrlNum()).hasOwnProperty('pageYOffset') ?
                                            this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).pageYOffset : 0);
                                    }
                                    this.finalQuestions.push(Object.assign({}, this.incomingQuestion.getAttributes()));
                                });
                                this.incomingResponse.getAttributes().form = Object.assign(this.incomingResponse.getAttributes().form, {controls: this.finalQuestions});
                                this.setSelectedForm(this.incomingResponse.getAttributes().form);
                            });
                        }
                    });
                }else{
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setId(this.formId);
                        this.service.app = Object.assign(
                            this.service.app, this.service.app.hasOwnProperty(this.incomingStrings.getData()) ?
                                this.service.app.data : {data: {status: 0}});
                        this.service.app.data = Object.assign(this.service.app.data, {status: 0});
                        if(!this.formService.selected.hasOwnProperty(this.incomingStrings.getId())){
                            this.service.httpService('post',
                                this.service.app.routes.forms.get,
                                this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (form: any) => {
                                    this.form = form;
                                    this.incomingForm.setAttributes(form);
                                    this.startForm(() => {});
                                }, (error: any) => {});
                        }else {
                            this.form = this.formService.getSelected();
                            this.incomingForm.setAttributes(this.formService.getSelected());
                            this.startForm(() => {});
                        }
                }
                $('body').keydown((e) => {this.keyDownEventListener(e); });
                // document.getElementsByTagName( 'body')[0].addEventListener('mouseleave', this.mouseleaveEventListener);
                // document.getElementsByTagName( 'body')[0].addEventListener('keydown', this.keyDownEventListener, false);
                document.getElementsByTagName( this.incomingStrings.getBody())[0].onkeydown = this.keyDownEventListener;
                this.subscription =  this.broadcastChannel
                    .getNavChangeEmitter()
                    .pipe()
                    .subscribe((item: any) => {
                        this.nextButtonStatus = true;
                        if (item instanceof Object){
                            if (item.hasOwnProperty('action')) {
                                if (item.action.toString().includes(this.service.app.strings.questions.next) ||
                                    item.action.toString().includes( this.incomingStrings.getTriggerNextButton())) {
                                    this.next();
                                }else if (item.action.toString().includes(this.service.app.strings.questions.rollback)) { this.rollback();
                                }else if (item.action.toString().includes('nextBottonShow') ||
                                    item.action.toString().includes('showNextButton')) { this.nextButtonStatus = true;
                                }else if (item.action.toString().includes('ngSubmit') ) { this.ngSubmit();
                                }else if (item.action.toString().includes('nextButtonHidden') ||
                                    item.action.toString().includes('hideNextButton')) { this.nextButtonStatus = false;
                                }else{
                                    setTimeout((options: any) => {
                                        if (item.action.toString().includes('next')) {
                                            this.opt = this.question.options.filter((option: any) => option.cntrl_num === item.optionalCntrlNum)[0];
                                            this.    outgoingOption.setAttributes(this.opt);
                                            options  = this.outgoingOption.getAttributes().questions.map((question: any) => {
                                                this.incomingQuestion.setAttributes(question);
                                                this.incomingFilterdQuestion = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().cntrl_num);
                                                this.incomingFilterdQuestion = Object.assign(this.incomingFilterdQuestion, {visible: 1});
                                                return this.incomingFilterdQuestion;
                                            }).sort();
                                            options = orderBy(options, 'index', 'asc');
                                            if (options.length === 0) { this.next(); }else {
                                                this.incomingOutstandingQuestion.setAttributes(this.question);
                                                this.options  = options;
                                                this.panel = this.incomingOutstandingQuestion.getCntrlNum();
                                                this.incomingOutstandingQuestion.setAttributes(this.options[0]);
                                                this.question = this.incomingOutstandingQuestion.getAttributes();
                                                this.position = this.service.findIndex(this.options,
                                                    this.incomingStrings.getCntrlNum(),
                                                    this.incomingOutstandingQuestion.getCntrlNum());
                                            }
                                        }else if (item.action.toString().includes('acceptable')) {
                                            this.submit();
                                        }
                                    });
                                }
                            }else if (item.hasOwnProperty(this.incomingStrings.getPosition()))  this.position = item.position;
                        }else{
                            if (item.toString().includes('responseQuestionsValidation')){
                                this.onCheckNullQuestion(this.formService.questions, (result: any) => {
                                    if (result.status){
                                        this.incomingQuestion.setAttributes(this.question);
                                        this.notifierService.notify('success', this.incomingQuestion.getAttributes().label.toString());
                                    }else { this.broadcastChannel.emitNavChangeEvent({requestText: 'responseQuestionsValidation', action: 'ngSubmit'}); }
                                });
                            }
                        }
                    });
                this.interval = setInterval((e) => {
                    this.save();
                }, this.timeoutt());
//    if (navigator){
//         navigator.geolocation.getCurrentPosition(pos => {
//           this.lat = parseFloat(pos.coords.latitude.toString());
//           this.lng = parseFloat(pos.coords.longitude.toString());
//           this.altitude = this.service.empty(pos.coords.altitude) ? 0 : pos.coords.altitude;
//         });
//     }

            });
    }

    startForm(callback: any){
        this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingUser.setAttributes({});
        this.setBackgroundColor();
        this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
        this.incomingClient.setAttributes(this.incomingCampaign.getClient());
        this.service.setLogo(this.incomingClient.getLogo());
        this.campaignService.campaignSelectedId = this.incomingForm.getCampaignId();
        this.formService.setSelected(this.incomingForm.getAttributes());
        this.formService.formSelectedId = this.incomingForm.getId();
        if(!this.responseService.selected.hasOwnProperty(this.incomingStrings.getId())){
            this.formService.create( this.incomingForm.getAttributes(), this.incomingUser, (response: any) => {
                this.incomingResponse.setAttributes(response);
                this.responseService.selected = this.incomingResponse.getAttributes();
                this.responses.unshift(this.incomingResponse.getAttributes());
                this.responseService.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
                this.setSelectedForm(this.incomingResponse.getForm());
                this.service.progress = false;
                this.autoSave();
            });
        }else{
            this.incomingResponse.setAttributes(this.responseService.getSelected());
            this.responses.unshift(this.incomingResponse.getAttributes());
            this.responseService.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
            this.setSelectedForm(this.incomingResponse.getForm());
            this.service.progress = false;
            this.autoSave();
        }
    }

    setQuestion(question: any){
        this.storage.get('others').subscribe((others: any) => {
            this.incomingQuestion.setAttributes(Object.assign({}, question));
            if (this.incomingQuestions.getAttributes().hasOwnProperty(this.incomingQuestion.getCntrlNum())) {
                this.incomingQuestionOptions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).options, 'cntrl_num'));
                this.incomingOptions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getOptions(), 'cntrl_num'));
                if (others){
                    if (others instanceof Array) {
                        others.map((option: any) => {
                            this.incomingOption.setAttributes(option);
                            if (this.incomingOption.getAttributes().hasOwnProperty('questionCntrlNum')) {
                                if (this.incomingQuestion.getCntrlNum().toString() === this.incomingOption.getAttributes().questionCntrlNum.toString()) {
                                    if (this.incomingOptions.getAttributes().hasOwnProperty(this.incomingOption.getCntrlNum())) {
                                        this.incomingOptions.getAttributes()[this.incomingOption.getCntrlNum()] = Object.assign(
                                            this.incomingOptions.getAttributes()[this.incomingOption.getCntrlNum()], this.incomingOption.getAttributes());
                                    } else { this.incomingQuestion.getOptions().push(this.incomingOption.getAttributes()); }
                                }
                            }
                        });
                    }
                }
                this.incomingQuestion.setData(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).data);
                this.incomingQuestion.setResult(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).hasOwnProperty('result')  ? Object.assign({}, this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).result) : {});
                if (parseFloat(this.incomingQuestion.getControlId()) === 4 ||
                    parseFloat(this.incomingQuestion.getControlId()) === 9 ||
                    parseFloat(this.incomingQuestion.getControlId()) === 24 ||
                    parseFloat(this.incomingQuestion.getControlId()) === 27){
                    this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).options.map((option: any) => {
                        this.incomingOption.setAttributes(option);
                        this.position = this.service.findIndex(this.incomingQuestion.getOptions(), 'cntrl_num', this.incomingOption.getCntrlNum());
                        if (this.position !== parseFloat('-1')){
                            this.incomingOption.setSettings(this.incomingQuestion.getOptions()[this.position].settings);
                            this.incomingOption.setQuestions(this.incomingQuestion.getOptions()[this.position].questions);
                        }
                    });
                    this.incomingQuestion.setOptions(this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).options);
                }
                this.incomingQuestion.setIsNull(this.incomingQuestions.getUnknown(
                    this.incomingQuestion.getCntrlNum()).hasOwnProperty('is_null') ?
                    this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).is_null : true);
                this.incomingQuestion.setPageYOffset(this.incomingQuestions.getUnknown(
                    this.incomingQuestion.getCntrlNum()).hasOwnProperty('pageYOffset') ?
                    this.incomingQuestions.getUnknown(this.incomingQuestion.getCntrlNum()).pageYOffset : 0);
            }
            this.finalQuestions.push(Object.assign({}, this.incomingQuestion.getAttributes()));
        });
    }

    onmapClick(map: any){
        if (this.prevWindow !== null ) {
            this.prevWindow.close();
            this.prevWindow = null;
        }
    }

    onmarkerClick(infoWindow: any, map: any){
        if (this.prevWindow === null){ this.prevWindow = infoWindow; }else{
            this.openedWindow = infoWindow;
            this.prevWindow.close();
        } this.prevWindow = infoWindow;
        setTimeout(() => {
            const elements = document.getElementsByClassName('gm-ui-hover-effect');
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element instanceof HTMLElement) {
                    element.addEventListener('click', () => {
                        this.prevWindow = null;
                    });
                }
            }
        });
    }

    displayMainQuestions(){
        if (this.ancestors.length === 0){}else{
            this.ancestors.map((ancestor: any) => {
                this.incomingAncestor.setAttributes(ancestor);
                if (parseFloat(this.incomingAncestor.getControlId()) === 18){
                    this.panel = null;
                    this.question = this.incomingAncestor.getAttributes();
                    this.options  = this.ancestors;
                    this.position = this.service.findIndex(this.ancestors, 'cntrl_num', this.incomingAncestor.getAttributes().cntrl_num);
                    this.service.playSound();
                    this.incomingQuestion.setAttributes(this.question);
                    if (this.incomingQuestion.getAttributes().hasOwnProperty('pageYOffset')){
                        setTimeout((page: any) => {
                            window.scrollTo(0, parseFloat(page.yOffSet));
                        }, 0, {yOffSet: this.incomingQuestion.getUnknown('pageYOffset')});
                    }
                }
            });
        }
    }

    profile(){
        this.userService.setSelected(this.service.app.data.user);
        this.userService.openDialog(this.service.app.data.user);
    }

    countOverAllQuestions(callback: any){
        let l: any = 0;
        try {
            this.formService.questions.map((question: any) => {
                this.incomingQuestion.setAttributes(question);
                if (parseFloat(this.incomingQuestion.getControlId()) === 12 ||
                    parseFloat(this.incomingQuestion.getControlId()) === 18){}else{
                    if (this.incomingQuestion.getAttributes.hasOwnProperty('ancestor')){
                        const ancestor: any = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAncestor());
                        this.incomingAncestor.setAttributes(ancestor);
                        if (parseFloat(this.incomingAncestor.getControlId()) === 5){
                            if (this.service.isNull(this.incomingAncestor.getData()) ||
                                this.incomingAncestor.getData().toString().toLowerCase() === 'null'){}else{
                                const options: any = this.incomingAncestor.getOptions();
                                options.map((optional: any) => {
                                    this.incomingOption.setAttributes(optional);
                                    if (this.incomingOption.getLabel().toString() === this.incomingAncestor.getData()){
                                        const optionQuestions: any = this.service.getJSObjectByArrayAndLabel(this.incomingOption.getQuestions(), 'cntrl_num');
                                        if (optionQuestions.hasOwnProperty(this.incomingQuestion.getCntrlNum())){
                                            l = l + parseFloat('1');
                                        }
                                    }
                                });
                            }
                        }else { l = l + parseFloat('1'); }
                    }else { l = l + parseFloat('1'); }
                }
            });
        }catch (e){}
        return l;
    }


    setSettings(callback: any){
        if (this.service.app.hasOwnProperty('settings')){callback({}); }else{
            this.service.httpService( 'get', this.appsettingsUrl, {}, {},
                (response: any) => {
                    this.service.app = Object.assign(this.service.app, {settings: response});
                    callback({});
                },  (error: any) => {
                });
        }
    }

    logout(){
        this.service.logout();
    }


    autoSave(){
        try{
            // this.responses.filter((response: any) => {return response.cntrl_num.toString() === this.incomingResponse.getCntrlNum(); })[0];
            if (this.responses instanceof Array){
                // window.localStorage.removeItem('responses');
                // window.localStorage.setItem('responses', JSON.stringify(this.responses));
                this.storage.set('responses', this.responses).subscribe(() => {});
            }
        }catch (e) {}
    }

    responseSavedHandling(){
        setTimeout((responses: any) => {
            if (this.responses instanceof Array){
                if (parseFloat(this.incomingResponse.getStatus()) === 0){
                    if (this.responseSavedStatus){
                        responses = this.responses.filter((response: any) => parseFloat(response.status) === 0);
                        this.outgoingRequest.setAttributes({});
                        this.outgoingRequest.setFormId(this.incomingForm.getId());
                        this.outgoingResponseRequest.setAttributes(Object.assign({}, this.incomingResponse.getAttributes()));
//          delete this.outgoingResponse.getAttributes().form;
                        this.outgoingRequest.setResponses(JSON.stringify([this.outgoingResponseRequest.getAttributes()]));
                        this.service.httpService( 'post', this.service.app.settings.routes.responseAddSaved, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {this.responseSavedStatus = true; },  (error: any) => {this.responseSavedStatus = true; });
                        this.responseSavedStatus = false;
                    }
                }
            }
        });
    }

    async getCurrentPosition() {
        this.loc = await Geolocation.getCurrentPosition();
        this.lat = parseFloat(this.loc.coords.latitude.toString());
        this.lng = parseFloat(this.loc.coords.longitude.toString());
        this.altitude = this.service.isNull(this.loc.coords.altitude) ? 0 : this.loc.coords.altitude;
    }

    getProgress(){
        const overallQuestions: any = this.countOverAllQuestions(() => {});
        const overallWrittenQuestions: any = this.countOverAllWrittenQuestions(() => {});
        this.progress =  this.service.percentageOf(overallWrittenQuestions, overallQuestions);
        this.incomingForm.setProgress(this.progress);
        this.incomingResponse.setProgress(this.progress);
        return this.progress;
    }

    countOverAllWrittenQuestions(callback: any){
        let l: any = 0;
        try {
            this.formService.questions.map((question: any) => {
                this.incomingQuestion.setAttributes(question);
                if (parseFloat(this.incomingQuestion.getControlId()) === 12 ||
                    parseFloat(this.incomingQuestion.getControlId()) === 18){}else{
                    if (this.incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
                        const ancestor: any = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAncestor());
                        this.incomingAncestor.setAttributes(ancestor);
                        if (parseFloat(this.incomingAncestor.getControlId()) === 5){
                            if (this.service.isNull(this.incomingAncestor.getData()) ||
                                this.incomingAncestor.getData().toString().toLowerCase() === 'null'){}else{
                                const options: any = this.incomingAncestor.getOptions();
                                options.map((optional: any) => {
                                    this.incomingOption.setAttributes(optional);
                                    if (this.incomingOption.getLabel().toString() === this.incomingAncestor.getData()){
                                        const optionQuestions: any = this.service.getJSObjectByArrayAndLabel(this.incomingOption.getQuestions(), 'cntrl_num');
                                        if (optionQuestions.hasOwnProperty(this.incomingQuestion.getCntrlNum())){
                                            if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                                                if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')){
                                                    if (!Boolean(this.incomingQuestion.getIsNull())){
                                                        l = l + parseFloat('1');
                                                    }
                                                }
                                            }else{
                                                if (this.service.isNull(this.incomingQuestion.getData()) ||
                                                    this.incomingQuestion.getData().toString() === 'null'){}else {
                                                    l = l + parseFloat('1');
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }else {
                            if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                                if (!this.formService.isTableNull(this.incomingQuestion.getAttributes())) {
                                    l = l + parseFloat('1');
                                } }else{
                                if (this.service.isNull(this.incomingQuestion.getData()) ||
                                    this.incomingQuestion.getData().toString() === 'null'){}else {
                                    l = l + parseFloat('1');
                                }
                            }
                        }
                    }else {
                        if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                            if (!this.formService.isTableNull(this.incomingQuestion.getAttributes())) {
                                l = l + parseFloat('1');
                            } }else{
                            if (this.service.isNull(this.incomingQuestion.getData()) ||
                                this.incomingQuestion.getData().toString() === 'null'){}else {
                                l = l + parseFloat('1');
                            }
                        }
                    }
                }
            });
        }catch (e){console.log(e); }
        return l;
    }

// @HostListener("document:keydown", ["$event"]) outClickHandler(e: any) {
// this.keyDownEventListener(e);
// }

    keyDownEventListener(e: any){
        const incomingEventAction = new Parameters();
        incomingEventAction.setAttributes({});
        incomingEventAction.setAction('trigger');
        setTimeout(() => {
            try {
                if (this.incomingForm.getAttributes().hasOwnProperty('id')){
                    if (parseFloat(this.incomingForm.getCategoryId()) !== 6){
                        if (e.keyCode === 39) {
                            this.next();
                        }else if (e.keyCode === 37) {
                            this.previous();
                        }
                    }
                }
            }catch (e) {
            }
        });
    }

    mouseleaveEventListener(){
        this.save();
    }

    publish(){
        this.ngSubmit();
    }

    remove(){
        setTimeout(() => {
            this.incomingResponse.setAttributes(this.incomingResponse.attributes);
            const cntrlNum: any = this.incomingResponse.getCntrlNum();
            const dialog = this.dialog.open(DeleteDialogComponent, {
                data   : {
                    data : this.responses,
                    model: this.incomingResponse.attributes,
                    title: this.incomingResponse.getAttributes().title,
                    id   : this.incomingResponse.getAttributes().cntrl_num,
                    url  : '',
                    key  : 'cntrl_num',
                },
                width : 'auto',
            });
            dialog.afterClosed().subscribe(() => {
                this.autoSave();
                const postion: any = this.service.findIndex(this.responses, 'cntrl_num', this.incomingResponse.getAttributes().cntrl_num);
                if (postion === -1) {
                    this.service.httpService('post', '/responses/' + cntrlNum + '/saved/removerequest', {}, {ignoreLoadingBar: 'true', notify: ''}, (result: any) => {}, (error: any) => {});
                    window.history.back();
                }
            });
        });
    }

    onprogress(){
        this.ancestors.map((questional: any) => {
            try {
                this.resultQuestionalCounting(questional);
                this.countingQuestion(questional, (rs: any) => {
                    this.resultQuestionalCounting(rs.question);
                });
            }catch (e) {}
        });
        // tslint:disable-next-line:radix
        this.incomingForm.setProgress(this.progress);
        return parseInt(this.progress.toString());
    }

    setTitle(){
        setTimeout(() => {
            const text: any = prompt('', this.incomingResponse.getAttributes().title);
            if (!this.service.isNull(text)){
                this.incomingResponse.attributes = Object.assign(this.incomingResponse.attributes, {title: text});
                document.title = this.incomingResponse.getAttributes().title;
                this.autoSave();
            }
        });
    }

    saveResponse(){
        setTimeout(() => {
            this.autoSave();
            window.history.back();
        });
    }

    export(){
        this.formService.setSelected(this.incomingForm.attributes);
        this.pdfService.downloadPdf();
    }

    onContextMenu(event: MouseEvent, form: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { item: form };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    countingQuestion(questional: any, callback){
        try{
            questional.options.map((optional: any) => {
                if (questional.control.id === 5){
                    if (!this.service.isNull(questional.data)){
                        if (questional.data.toString() === optional.label.toString()){
                            if (optional.hasOwnProperty('questions')){
                                optional.questions.map((q: any) => {
                                    callback({question: this.formService.getQuestionByCntrlNum(q.cntrl_num)});
                                    this.countingQuestion(this.formService.getQuestionByCntrlNum(q.cntrl_num), callback);
                                });
                            }
                        }
                    }
                }else {
                    if (optional.hasOwnProperty('questions')){
                        optional.questions.map((q: any) => {
                            callback({question: this.formService.getQuestionByCntrlNum(q.cntrl_num)});
                            this.countingQuestion(this.formService.getQuestionByCntrlNum(q.cntrl_num), callback);
                        });
                    }
                }

            });
        }catch (e) {}
    }

    resultQuestionalCounting(questional: any){
        try{
            if (questional.control.id !== 18 &&
                questional.control.id !== 12) {
                this.all += 1;
                if (!this.service.isNull(questional.data)) { this.written += 1; }
                this.progress = this.formService.progress(this.written, this.all);
                //     tslint:disable-next-line:radix
                this.progress = parseInt('' + this.progress);
            }
        }catch (e) {}
    }

    timeoutt(){
        // tslint:disable-next-line:radix
        return parseInt('9090');
    }


    getWaypoints(question: any){
        this.waypoints = [];
        this.incomingItem.setAttributes(question);
        this.incomingOrigin.setAttributes({});
        this.incomingDestination.setAttributes({});
        this.incomingOrigin.setLat(0);
        this.incomingOrigin.setLng(0);
        this.incomingDestination.setLat(0);
        this.incomingDestination.setLng(0);
        this.incomingItem.setincomingOrigin(this.incomingOrigin);
        this.incomingItem.setincomingDestination(this.incomingDestination);
        this.incomingItem.setWaypoints([]);
        this.incomingItem.getOptions().map((option: any) => {
            this.incomingOption.setAttributes(option);
            this.incomingFormSettings.setAttributes(this.incomingOption.getSettings());
            this.incomingFormSettings.setPosition(this.incomingFormSettings.getAttributes().hasOwnProperty('position') ? this.incomingFormSettings.getPosition() : 0);
            if (this.incomingFormSettings.getAttributes().hasOwnProperty('start') && parseFloat(this.incomingFormSettings.getStart()) === 1){
                this.incomingOrigin.setLat(this.incomingFormSettings.getLat());
                this.incomingOrigin.setLng(this.incomingFormSettings.getLng());
                this.incomingItem.setincomingOrigin(this.incomingOrigin.getAttributes());
            }else if (this.incomingFormSettings.getAttributes().hasOwnProperty('end') && parseFloat(this.incomingFormSettings.getEnd()) === 1){
                this.incomingDestination.setLat(this.incomingFormSettings.getLat());
                this.incomingDestination.setLng(this.incomingFormSettings.getLng());
                this.incomingItem.setincomingDestination(this.incomingDestination.getAttributes());
            }else { this.incomingItem.getWaypoints().push({location: { lat: parseFloat(this.incomingFormSettings.getLat()), lng: parseFloat(this.incomingFormSettings.getLng()) }}); }
        }); return this.incomingItem.getAttributes();
    }

    next(){this.formService.isAdvert = false; this.migrate(); }
    save(){setTimeout(() => {try{ this.autoSave(); }catch (e) {}}); }

    setSelectedForm(form: any){
        this.incomingForm.setAttributes(form);
        this.incomingForm.setProgress(this.incomingForm.getAttributes().hasOwnProperty('progress') ? this.incomingForm.getAttributes().progress : 0);
        this.incomingForm.setPostId(this.incomingForm.hasOwnProperty('post_id') ? this.incomingForm.getAttributes().post_id : this.service.random());
        this.form  = this.incomingForm.attributes;
        this.formService.questions = this.incomingForm.getAttributes().controls;
        this.setFormUser(this.incomingForm.attributes);
        this.incomingResponse.setTitle(
            this.incomingResponse.getAttributes().hasOwnProperty(this.incomingStrings.getTitle()) ?
                this.incomingResponse.getTitle() : '');
        document.title = this.incomingResponse.getTitle() + ', ' + (
            this.incomingForm.getAttributes().hasOwnProperty('campaign') ? this.incomingForm.getAttributes().campaign.name.toString().toUpperCase() : '');
        this.formService.objects = {};
        this.setBackgroundColor();
        this.service.playSound();
        if (parseFloat(this.incomingForm.getCategoryId()) === 5) {
            this.responseService.playFilm((result: any) => {});
        }this.formService.questions.map((question: any) => {
            this.incomingQuestion.setAttributes(question);
            this.formService.objects[this.incomingQuestion.getCntrlNum()] = this.incomingQuestion.getAttributes();
        }); this.showAllQuestions((result: any) => {
            this.hideAllChildQuestions((resultt: any) => {
                this.getAllVisibleQuestionsByAncestor((questions: any) => {
                    this.ancestors = questions;
                    this.setGroupBy();
                    this.main((rs: any) => {
                    });
                });
            });
        });
    }


    setFormUser(form: any){
        const incomingForm: any = new Parameters();
        incomingForm.setAttributes(form);
        if (incomingForm.hasOwnProperty('postedBy')){}else{
            if (this.service.app.hasOwnProperty('data')){
                if (this.service.app.data.status === 1){
                    incomingForm.setPostedBy(this.service.app.data.user.id);
                }
            }
        }
    }



    fillAncestorsByOptions(){
        if (this.ancestors.length === 0) {
            this.ancestors = this.options.map((question: any) => {
                this.incomingQuestion.setAttributes(question);
                return this.incomingQuestion.attributes;
            });
        }
    }

    hasPreviousQuestion() { return this.options[this.position - 1]; }
    isSubmitButtonOpen(){ return this.formService.submit; }
    hideSubmitButton(){ this.formService.submit = false; }
    rollback(){
        this.service.navButton = -1;
        this.nextButtonStatus = true;
        this.fillAncestorsByOptions();
        if (this.isSubmitButtonOpen()) {
            this.hideSubmitButton();
            return;
        }
        this.hideSubmitButton();
        if (this.hasPreviousQuestion()){
            this.position -= 1;
            this.question = this.options[this.position];
            this.service.playSound();
        }else{
            this.incomingQuestion.setAttributes(this.question);
            if (this.incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
                this.incomingAncestor.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().ancestor));
                if (this.incomingAncestor.getAttributes().hasOwnProperty('ancestor')){
                    this.parentValues.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingAncestor.getAttributes().ancestor));
                    const optionalPosition: any = this.service.findIndex(this.parentValues.getAttributes().options, 'cntrl_num', this.incomingAncestor.getAttributes().option.cntrlNum);
                    const opt: any = this.parentValues.getAttributes().options[optionalPosition];
                    this.outgoingOption.setAttributes(opt);
                    this.panel = this.parentValues.getAttributes().cntrl_num;
                    this.question = this.incomingAncestor.attributes;
                    this.options  = [];
                    this.outgoingOption.getAttributes().questions.map((q: any) => {
                        this.incomingQ.setAttributes(q);
                        this.options.push(this.formService.getQuestionByCntrlNum(this.incomingQ.getAttributes().cntrl_num));
                    });
                    this.position = this.service.findIndex(this.options, 'cntrl_num', this.incomingAncestor.getAttributes().cntrl_num);
                    this.service.playSound();
                }else{
                    this.panel = null;
                    this.question = this.incomingAncestor.attributes;
                    this.options  = this.ancestors;
                    this.position = this.service.findIndex(this.ancestors, 'cntrl_num', this.incomingAncestor.getAttributes().cntrl_num);
                    this.service.playSound();
                }
            }else { this.panel = null; }
        }
    }

    previous() {
        this.formService.isAdvert = false;
        this.rollback();
    }

    showAllQuestions(callback: any){
        this.formService.questions.map((question: any) => {
            this.incomingQuestion = new Parameters();
            this.incomingQuestion.setAttributes(question);
            this.incomingQuestion.getAttributes().visible = 1;
        });
        callback({});
    }

    hideAllChildQuestions(callback: any){
        setTimeout(() => {
            this.formService.questions.map((question: any) => {
                this.incomingQuestion.setAttributes(question);
                if (this.incomingQuestion.getAttributes().hasOwnProperty('options')){
                    this.incomingQuestion.getAttributes().options.map((option: any) => {
                        this.incomingOption.setAttributes(option);
                        if (this.incomingOption.getAttributes().hasOwnProperty('questions')){
                            this.incomingOption.getAttributes().questions.map((q: any) => {
                                let questionn: any = this.formService.getQuestionByCntrlNum(q.cntrl_num);
                                questionn = Object.assign(questionn, {visible: 0});
                                questionn = Object.assign(questionn, {ancestor: this.incomingQuestion.getAttributes().cntrl_num});
                                questionn = Object.assign(questionn, {option: {label: this.incomingOption.getAttributes().label, cntrlNum: this.incomingOption.getAttributes().cntrl_num}});
                            });
                        }
                    });
                }
            });
            callback({});
        });
    }

    getAllVisibleQuestions(callback: any){
        const questions: any = this.formService.questions.filter((question: any) => {
            this.incomingQuestion = new Parameters();
            this.incomingQuestion.setAttributes(question);
            return this.incomingQuestion.getAttributes().visible === 1;
        }); callback(questions);
    }

    checkemptyQuestion(callback: any){
        const fields: any = [];
        this.formService.questions.map((question: any) => {
            this.incomingQuestion.setAttributes(question);
            if (!this.incomingQuestion.hasOwnProperty('ancestor')){
                if (this.incomingQuestion.getAttributes().required === 1){
                    if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                        if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')){
                            if (Boolean(this.incomingQuestion.getIsNull())){
                                fields.push(this.incomingQuestion.attributes);
                            }
                        }
                    }else if (this.service.isNull(this.incomingQuestion.getAttributes().data)){
                        fields.push(this.incomingQuestion.attributes);
                    }
                }
            }else {
                const ancestor: any = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().ancestor);
                this.incomingAncestor.setAttributes(ancestor);
                if (this.incomingAncestor.getAttributes().control.id === 5){
                    if (!this.service.isNull(this.incomingAncestor.getAttributes().data)){
                        if (this.incomingQuestion.getAttributes().option.label.toString().includes(this.incomingAncestor.getAttributes().data.toString())){
                            if (parseFloat(this.incomingQuestion.getAttributes().required) === 1){
                                if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                                    if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')){
                                        if (Boolean(this.incomingQuestion.getIsNull())){
                                            fields.push(this.incomingQuestion.attributes);
                                        }
                                    }
                                }else if (this.service.isNull(this.incomingQuestion.getAttributes().data)){
                                    fields.push(this.incomingQuestion.attributes);
                                }
                            }
                        }
                    }
                }else if (parseFloat(this.incomingQuestion.getAttributes().required) === 1){
                    if (parseFloat(this.incomingQuestion.getControlId()) === 24){
                        if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')){
                            if (Boolean(this.incomingQuestion.getIsNull())){
                                fields.push(this.incomingQuestion.attributes);
                            }
                        }
                    } else if (this.service.isNull(this.incomingQuestion.getAttributes().data)){
                        fields.push(this.incomingQuestion.attributes);
                    }
                }
            }
        });
        callback(fields);
    }


    getAllVisibleQuestionsByAncestor(callback: any){
        const questions: any = this.formService.questions.filter((question: any) => {
            this.incomingQuestion = new Parameters();
            this.incomingQuestion.setAttributes(question);
            return !this.incomingQuestion.getAttributes().hasOwnProperty('ancestor');
        }); callback(questions);
    }


    hasNextQuestion(){
        return this.options[this.position + 1];
    }
    showSubmitButton(){
        this.formService.submit = true;
    }

    onComponentClosed(){
    }

    migrate() {
        try{
            this.service.navButton = 1;
            this.incomingItem.setAttributes(this.question);
            this.incomingFormSettings.setAttributes(this.incomingItem.getSettings());
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.incomingConditions.setAttributes(this.incomingItem.getConditions ());
            this.incomingConditions.setDigits(this.incomingConditions.hasAttribute('digits') ? this.incomingConditions.getDigits() : '');
            this.incomingFormSettings.setCanBeSavedBeforePublished(this.incomingFormSettings.hasAttribute('canBeSavedBeforePublished') ? this.incomingFormSettings.getCanBeSavedBeforePublished() : 0);
            this.incomingFormSettings.setRateOptions(this.incomingFormSettings.getAttributes().hasOwnProperty('rate_options') ? this.incomingFormSettings.getRateOptions() : []);
            if (this.service.isNull(this.incomingItem.getData())){
                if (parseFloat(this.incomingItem.getRequired()) === 1){
                    if (parseFloat(this.incomingFormSettings.getCanBeSavedBeforePublished()) === 1){
                        this.notifierService.notify('success', this.incomingItem.getLabel());
                        return;
                    }
                }
            }
            if (!window.location.href.includes('/register')) {
                if (parseFloat(this.incomingItem.getControlId()) === 33) {
                    this.incomingResult.setAttributes(this.incomingItem.getAttributes().hasOwnProperty('result') ?
                        this.incomingItem.getResult() : {});
                    if (!(this.incomingItem.getAttributes().hasOwnProperty('dataUrl') &&
                        !this.service.isNull(this.incomingItem.getUnknown('dataUrl')))) {
                        return;
                    }
                }
            }

            if (parseFloat(this.incomingItem.getControlId()) === 23){
                if (!this.service.isNull(this.incomingItem.getData())){
                    if (!this.service.validateEmail(this.incomingItem.getData())){
                        this.notifierService.notify('success', this.service.app.strings.email.invalid);
                        return;
                    }
                }
            }else if (parseFloat(this.incomingItem.getControlId()) === 6){
                if (!this.service.isNull(this.incomingItem.getData())){
                    if (this.incomingFormSettings.getAttributes().hasOwnProperty('location')){
                        if (!this.service.isNull(this.incomingFormSettings.getLocation())){
                            if (this.incomingFormSettings.getLocation().toString().includes(this.incomingItem.getCntrlNum())){
                                if (!this.incomingItem.getAttributes().hasOwnProperty('lng')){
                                    this.notifierService.notify('success', this.service.app.strings.address.googlePlace.invalid);
                                    this.incomingItem.setData(this.incomingItem.getData()); return;
                                }
                            }
                        }
                    }
                }
            }else if (parseFloat(this.incomingItem.getControlId()) === 32){
                if (!this.service.isNull(this.incomingItem.getData())){
                    this.incomingResult.setAttributes(this.incomingItem.getResult());
                    let position: any = 0;
                    this.incomingFormSettings.getRateOptions().map((rateOption: any) => {
                        this.incomingOption.setAttributes(rateOption);
                        if (this.incomingResult.getAttributes().hasOwnProperty(this.incomingOption.getCntrlNum())){
                            if (this.incomingResult.getUnknown(this.incomingOption.getCntrlNum()).status === 0){
                                if (position === parseFloat('0')) {
                                    this.notifierService.notify('success', 'Rate ' + this.incomingOption.getTitle().toString().toLowerCase());
                                }
                                position = 1;
                            }
                        }
                    });
                    if (position === parseFloat('1')) { return; }
                }
            }else if (parseFloat(this.incomingItem.getControlId()) === 10){
                if (!this.service.isNull(this.incomingItem.getData())){
                    if (!this.service.isNull(this.incomingConditions.getDigits())){
                        if (this.incomingItem.getData().length !== parseFloat(this.incomingConditions.getDigits())){
                            this.notifierService.notify('success', this.service.app.strings.number.invalid.digits + this.incomingConditions.getDigits());
                            return;
                        }
                    }
                }
            }
            this.nextButtonStatus = true;
            this.fillAncestorsByOptions();
            if (this.hasNextQuestion()){
                this.position += parseFloat('1');
                this.question = this.options[this.position];
                this.service.playSound();
            }else {
                this.incomingQuestion.setAttributes(this.question);
                if (this.incomingQuestion.getAttributes().hasOwnProperty('ancestor')) {
                    this.incomingAncestor.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().ancestor));
                    if (this.incomingAncestor.getAttributes().hasOwnProperty('ancestor')) {
                        this.parentValues.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingAncestor.getAttributes().ancestor));
                        const optionalPosition: any = this.service.findIndex(this.parentValues.getAttributes().options, 'cntrl_num', this.incomingAncestor.getAttributes().option.cntrlNum);
                        const opt: any = this.parentValues.getAttributes().options[optionalPosition];
                        this.outgoingOption.setAttributes(opt);
                        this.panel  = this.parentValues.getAttributes().cntrl_num;
                        this.question = this.incomingAncestor.attributes;
                        this.options  = [];
                        this.outgoingOption.getAttributes().questions.map((q: any) => {
                            this.incomingQ.setAttributes(q);
                            this.options.push(this.formService.getQuestionByCntrlNum(this.incomingQ.getAttributes().cntrl_num)); });
                        this.position = this.service.findIndex(this.options, 'cntrl_num', this.incomingAncestor.getAttributes().cntrl_num);
                        if (this.question.control.id === 5) { this.next(); } else { this.service.playSound(); }} else {
                        this.panel    = null;
                        this.question = this.incomingAncestor.attributes;
                        this.options  = this.ancestors;
                        this.position = this.service.findIndex(this.ancestors, 'cntrl_num',
                            this.incomingAncestor.getAttributes().cntrl_num);
                        if (this.question.control.id === 5) {
                            this.next();
                        } else { this.service.playSound(); }
                    }
                } else {
                    this.panel = null;
                    this.hideSubmitButton();
                    this.nextButtonStatus = false;
                    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
                    if (this.incomingFormSettings.getAttributes().hasOwnProperty('lfd')){
                        if (parseFloat(this.incomingFormSettings.getLargeDisplay()) === 1){
                            this.formService.submit = true;
                        }else if (this.getUrl().toString().includes('/feedback') || this.getUrl().toString().includes('/register')) { this.formService.submit = true; }
                    }else if (this.getUrl().toString().includes('/feedback') || this.getUrl().toString().includes('/register')) { this.formService.submit = true; }
                }
            }
        }catch (e) {
        }
    }



    main(callback){
        setTimeout(() => {
            if (this.ancestors.length === 0){
                this.options = []; }else{
                this.position = 0;
                this.question = this.ancestors[0];
                this.incomingQuestion = new Parameters();
                this.incomingQuestion.setAttributes(this.question);
                this.options  = this.ancestors.map((question: any) => {
                    this.incomingQuestion.setAttributes(question);
                    return this.incomingQuestion.attributes;
                });
            }
        });
    }

    getHeight(){return window.innerHeight; }
    getWidth(){return window.innerWidth; }

    ngAfterContentInit(): void {
        setTimeout(() => {
            const elements: any = document.getElementsByClassName('mat-radio-outer-circle');
            for (let i = 0; i < elements.length; i++) {
                const element: any = elements[i];
                if (element instanceof HTMLElement) {
                    element.style.borderColor = '#fff';
                }
            }
        });
    }

    ngAfterViewInit(): void {
        $.getScript( '../assets/js/testimonials.js', ( data, textStatus, jqxhr ) => {});
    }


    ngOnDestroy(): void {
        $('body').off('keydown');
        // document.getElementsByTagName( 'body')[0].removeEventListener('keydown', this.keyDownEventListener);
        // document.getElementsByTagName( 'body')[0].removeEventListener('mouseleave', this.mouseleaveEventListener);
        clearInterval(this.interval);
        this.save();
        this.responseSavedHandling();
        this.subscription.unsubscribe();
        this.formService.submit = false;
    }

    historyback(){window.history.back(); }
    sendInvitation(response: any){
        this.incomingResponseSelected.setAttributes(response);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setFormId(this.incomingForm.getId());
        this.outgoingRequest.setResponseId(this.incomingResponseSelected.getId());
        this.outgoingRequest.setUnknown('backgroundColor', this.incomingFormSettings.getBackgroundColor());
        this.service.httpService( 'post', this.service.app.routes.invitations.email, this.outgoingRequest.getAttributes(), {},
            (result: any) => {
                this.incomingResponseSelected.attributes =
                    Object.assign(this.incomingResponseSelected.attributes, result);
                this.notifierService.notify('success', this.service.app.strings.invitation.email.successfull);
                this.broadcastChannel.emitNavChangeEvent('add');
                // this.sendInvitationThroughtWhatsapp(response);
            },  (error: any) => {
                this.notifierService.notify('success', this.service.app.strings.invitation.email.error);
            });
    }


    sendInvitationCard(response: any, call: any){
        let $incomingResponse: any = new Parameters();
        let $outgoingRequest: any = new Parameters();
        $incomingResponse.setAttributes(response);
        $outgoingRequest.setAttributes({});
        $outgoingRequest.setResponseId($incomingResponse.getId());
        $outgoingRequest.setFlag(this.incomingStrings.getWhatsapp());
        this.responseService.sendInvitationCard($outgoingRequest, (result: any) => {
            $outgoingRequest.setFlag(this.incomingStrings.getEmail());
            this.responseService.sendInvitationCard($outgoingRequest, (result: any) => {});
        });
    }


    appendResponse(response: any){
        if (this.service.hasToken()){
            if (parseFloat(this.service.app.data.status) !== 0){
                if (!this.outgoingResponseRequest.hasAttribute('id')){
                    this.outgoingRequest.setAttributes({});
                    this.outgoingRequest.setCntrlNum(this.outgoingResponseRequest.getPostId());
                    this.outgoingRequest.setFormId(this.incomingForm.getId());
                    this.service.httpService('post', this.service.app.routes.responses.get.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (result: any) => {
                        this.formService.selected.responses.data.unshift(result);
                        if (parseFloat(this.incomingForm.getCategoryId()) === 4) {
                         // this.sendInvitation(result);
                         // this.sendInvitationCard(result, () => {});
                        }
                    }, (error: any) =>  {
                    });
                }
            }
        }
    }

    login(){ this.router.navigateByUrl('/login'); }

    submit(){
        this.outgoingResponseRequest.setAttributes(this.incomingResponse.getAttributes());
        this.posts = [];
        if(this.outgoingResponseRequest.getAttributes().hasOwnProperty(this.incomingStrings.getId()))
            this.service.app.data = Object.assign(this.service.app.data,{user: {role:{id:1}}});
        if (this.outgoingResponseRequest.getStatus() === 0 ||
            (this.outgoingResponseRequest.getAttributes().hasOwnProperty(this.incomingStrings.getId()) &&
                (parseFloat(this.service.app.data.user.role.id) === 14 ||
                    parseFloat(this.service.app.data.user.role.id) === 1))) {
            this.form.controls.map((question: any) => {
                this.incomingQuestion.setAttributes(Object.assign({}, question));
                this.incomingQuestion.setResult(this.incomingQuestion.hasAttribute('result') &&
                !this.service.isNull(this.incomingQuestion.getResult()) ?
                    JSON.stringify(this.incomingQuestion.getResult()) : '');
                this.incomingQuestion.getAttributes().form_control_id = this.incomingQuestion.getAttributes().control.id;
                this.incomingQuestion.attributes = Object.assign(this.incomingQuestion.getAttributes(), {data: this.service.isNull(this.incomingQuestion.getAttributes().data) ? '' : this.incomingQuestion.getAttributes().data});
                delete this.incomingQuestion.getAttributes().options;
                this.questions.push(this.incomingQuestion.attributes);
            });
            this.incomingForm.setAttributes(this.form);
            this.outgoingRequest.setAttributes(this.outgoingResponseRequest.getAttributes());
            this.outgoingRequest.setEndingAt(moment(new Date()).format('HH:mm:ss'));
            this.outgoingRequest.setTitle(this.getTitle(this.outgoingRequest.getAttributes()));
            this.outgoingRequest.setDepartmentId(this.departmentId);
            this.outgoingRequest.setOutletId(this.outgoingRequest.getAttributes().hasOwnProperty('outlet_id') ?
                this.outgoingRequest.getOutletId() :
                this.responseService.outletId);
            this.outgoingRequest.setStatus(parseFloat('0'));
            this.outgoingRequest.setLat(this.outgoingRequest.getAttributes().hasOwnProperty('lat') ? this.outgoingRequest.getLat() : this.lat);
            this.outgoingRequest.setLng(this.outgoingRequest.getAttributes().hasOwnProperty('lng') ? this.outgoingRequest.getLng() : this.lng);
            this.outgoingRequest.setAltitude(this.outgoingRequest.getAttributes().hasOwnProperty('altitude') ? this.outgoingRequest.getAltitude() : this.altitude);
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.incomingFormSettings.setIsGPSShouldCaptured(this.incomingFormSettings.getAttributes().hasOwnProperty('is_GPS_should_captured') ? this.incomingFormSettings.getIsGPSShouldCaptured() : 0);
            if (parseFloat(this.incomingFormSettings.getIsGPSShouldCaptured()) === 1 &&
                parseFloat(this.outgoingRequest.getLat()) === 0){
                this.notifierService.notify('success',
                    // tslint:disable-next-line:align
                    this.service.app.strings.responses.GPS.shouldCaptured); return;
            }if (this.outgoingRequest.getAttributes().hasOwnProperty('datas'))
                delete this.outgoingRequest.getAttributes().datas;
            if (this.outgoingRequest.getAttributes().hasOwnProperty('form'))
                delete this.outgoingRequest.getAttributes().form;
            if (this.outgoingRequest.getAttributes().hasOwnProperty('department'))
                delete this.outgoingRequest.getAttributes().department;
            if (this.outgoingRequest.getAttributes().hasOwnProperty('posted_by'))
                delete this.outgoingRequest.getAttributes().posted_by;
            this.outgoingRequest.setControls(this.questions);
            if (!this.service.isNull(this.email)) { this.outgoingRequest.setEmail(this.email); }
            this.posts.push(this.outgoingRequest.getAttributes());
            this.responseSavedStatus = false;
            let outgoingPosts: any = this.posts;
            outgoingPosts = JSON.stringify(this.posts);
            this.service.httpService('post', '/public/form-posts', {posts: outgoingPosts}, {}, (response: any) => {
                this.responseSavedStatus = true;
                this.getImagesByPost(this.outgoingRequest);
                this.uploadFiles();
                this.notifierService.notify('success', this.incomingStrings.getUnknown('msgrsp'));
                this.appendResponse(this.outgoingResponseRequest.getAttributes());
                this.outgoingResponseRequest.setStatus(1);
                this.autoSave();
                if (this.outgoingResponseRequest.getAttributes().hasOwnProperty('id')){
                    this.service.httpService('post', '/form-posts/' + parseFloat(this.outgoingResponseRequest.getId()) + '/view', {}, {ignoreLoadingBar: 'true', notify: false}, (responseResult: any) => {
                        this.incomingForm.setAttributes(this.service.app.data.forms.data.filter((form: any) => parseFloat(form.id) === parseFloat(this.incomingForm.getId()))[0]);
                        this.incomingResponse.setAttributes(this.incomingForm.getResponses().data.filter((result: any) => parseFloat(result.id) === parseFloat(this.incomingResponse.getId()))[0]);
                        this.incomingResponse.setAttributes(Object.assign(this.incomingResponse.getAttributes(), responseResult));
                        window.history.back();
                    }, (error: any) => {
                       if(this.service.getNavigatedUrl().includes('ans=')){
                           let navigatedUrl: any =  this.incomingStrings.getUnknown('invapp') +
                               this.service.encrypt(this.incomingResponse.getPostId()) + this.incomingStrings.getSlash() +
                               this.incomingStrings.getConfirmation();
                           this.service.setNavigatedUrl(window.location.host + navigatedUrl);
                           this.router.navigateByUrl(navigatedUrl);
                       }
                    });
                }else {
                    if (this.service.hasToken() && parseFloat(this.service.app.data.status) !== 0){
                        if (this.incomingResponse.getAttributes().hasOwnProperty('id')){
                            this.incomingForm.setAttributes(this.service.app.data.forms.data.filter((form: any) => parseFloat(form.id) === parseFloat(this.incomingForm.getId()))[0]);
                            this.incomingResponse.setAttributes(this.incomingForm.getResponses().data().filter((respons: any) => respons.cntrl_num.toString() === this.incomingResponse.getCntrlNum())[0]);
                            this.incomingResponse.setStatus(1);
                        }this.incomingResponse.setAttributes(this.responses.filter((result: any) => result.cntrl_num.toString() === this.incomingResponse.getCntrlNum())[0]);
                        this.incomingResponse.setStatus(1);
                        this.autoSave();
                        window.history.back();
                    }else {
                        this.clear();
                        this.setSelectedForm(this.form);
                        this.question = this.ancestors[0];
                        this.position = parseFloat('0');
                        this.panel = null;
                        this.options  = this.ancestors;
                        this.formService.submit = false;
                        this.outgoingResponseRequest.setStatus(0);
                        this.incomingForm.setProgress(0);
                        this.outgoingResponseRequest.setProgress(0);
                        this.progress = 0;
                        setTimeout(() => {
                            window.history.back();
                        });
                    }
                }
                // this.incomingResponse.setAttributes(Object.assign(this.incomingResponse.getAttributes(), this.outgoingResponseRequest.getAttributes()));
                // this.router.navigateByUrl('/app/respondents/' + this.service.ecrypt('xx') + '/responses');
            }, (error: any) => {
                if (this.service.getNavigatedUrl().toString().includes('/feedback') ||
                    this.service.getNavigatedUrl().toString().includes('/register')) {
                    window.history.back();
                }
            });
        }else {
            window.history.back();
        }
    }

    getUrl(){return window.location.href; }
    getTitle(response: any){
        this.incomingResponse.setAttributes(Object.assign({}, response));
        this.result = '';
        try {this.result =  !this.service.isNull(this.incomingForm.getTitle()) ?
            (!this.service.isNull(this.incomingResponse.getForm().controls.filter((question: any) => question.cntrl_num.toString() === this.incomingForm.getTitle())[0].data) ?
                this.incomingResponse.getForm().controls.filter((question: any) => question.cntrl_num.toString() === this.incomingForm.getTitle())[0].data :
                this.incomingResponse.getTitle()) :
            this.incomingResponse.getTitle();
        }catch (e) {
        }if (this.incomingFormSettings.getAttributes().hasOwnProperty('outlet')){
            this.incomingResponse.getForm().controls.filter((question: any) => {
                this.incomingQuestion.setAttributes(question);
                if (parseFloat(this.incomingQuestion.getControlId()) === 14){
                    this.incomingResult.setAttributes(this.incomingQuestion.getAttributes().hasOwnProperty('result') ? this.incomingQuestion.getResult() : {});
                    this.result = this.incomingResult.getName();
                }
            });
        }
        return this.result;

    }

    ngSubmit(){
        this.service.waitStatus = '!null';
        this.onCheckNullQuestion(this.formService.questions, (response: any) => {
                if (response.status){
                    this.incomingQuestion.setAttributes(this.question);
                    this.formService.submit = false;
                    if (parseFloat(this.incomingQuestion.getControlId()) === 14){
                        this.incomingResult.setAttributes(
                            this.incomingQuestion.getAttributes().hasOwnProperty('result') ?
                                this.incomingQuestion.getResult() : {});
                        this.incomingResult.setCntrlNum(this.service.random());
                        this.service.httpService('post', this.service.app.routes.outlets.store,
                            this.incomingResult.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
                                this.incomingQuestion.setAttributes(this.incomingResponse.getForm().controls.filter((question: any) => question.cntrl_num === this.question.cntrl_num)[0]);
                                this.incomingOutlet.setAttributes(result);
                                this.incomingResult.setName(this.incomingResult.getName());
                                this.incomingQuestion.setData(this.incomingOutlet.getId());
                                this.incomingQuestion.setResult(this.incomingResult.getAttributes());
                                this.ngSubmit();
                            }, () => {
                            });
                    }else { this.notifierService.notify('success', this.incomingQuestion.getAttributes().label.toString()); }
                }else{
                    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
                    if (window.location.href.toString().includes('/feedback') ||
                        window.location.href.toString().includes('/register')){
                        this.router.navigateByUrl('/wait..');
                        this.submit();
                    }else{
                        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {
                            data : {title: this.getTitle(this.incomingResponse.getAttributes()), action: 'publish'},
                            width : 'auto',
                        });
                        dialogRef.afterClosed().subscribe((item: any) => {
                            if (item instanceof Object){
                                if (item.hasOwnProperty('response')){
                                    if (item.response.toString().includes('successfull')){
                                        this.incomingResponse.setStatus(0);
                                        this.submit();
                                    }
                                }
                            }
                        });
                    }
                }
            });
    }

    getImagesByPost(outgoingRequest: any){
        outgoingRequest.getAttributes().controls.map((questional: any) => {
            this.incomingQuestion.setAttributes(questional);
            this.outgoingImgRequest.setAttributes({});
            this.outgoingImgRequest.setPostId(outgoingRequest.getAttributes().post_id);
            this.outgoingImgRequest.setFormControlId(this.incomingQuestion.getAttributes().id);
            this.outgoingImgRequest.setData('');
            this.outgoingImgRequest.setResult(this.incomingQuestion.getResult());
            this.outgoingImgRequest.setControlId(this.incomingQuestion.getAttributes().control_id);
            this.outgoingImgRequest.setStatus(0);
            this.outgoingImgRequest.setCntrlNum(this.incomingQuestion.getAttributes().cntrl_num);
            if (this.hasUploudedImage(this.outgoingImgRequest.attributes)){} else {
                if (this.incomingQuestion.getAttributes().control_id === 2) {
                    this.outgoingImgRequest.setFile(this.incomingQuestion.getAttributes().src);
                    this.files.push(this.outgoingImgRequest.attributes);
                }
            }
        });
    }

    hasUploudedImage(questional: any){
        return this.files.filter((file: any) => file.cntrl_num.toString().includes(questional.cntrl_num) && file.post_id === questional.post_id).length !== 0 ;
    }

    uploadFiles(){
        this.files.map((file: any) => {
            const incomingFile: any = new Parameters();
            incomingFile.setAttributes(file);
            if (parseFloat(incomingFile.getStatus()) === 0) {
                this.fileUpload(incomingFile.getAttributes(), (fileUploaded: any) => {
                    const incomigFileUploaded: any = new Parameters();
                    incomigFileUploaded.setAttributes(fileUploaded);
                    fileUploaded = Object.assign(incomigFileUploaded.getAttributes(), {status: 1});
                    this.position = this.service.findIndex(this.files, 'status', 1);
                    this.files.splice(this.position, 1);
                });
            }
        });
    }

    onCheckNullQuestion(questions: any, callback: any){
        let l: any = false;
        questions.filter((question: any) => {
            this.incomingQuestion.setAttributes(question);
            if (parseFloat(this.incomingQuestion.getRequired()) === 1){
                if (this.incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
                    const ancestor: any = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().ancestor);
                    this.incomingAncestor.setAttributes(ancestor);
                    if (parseFloat(this.incomingAncestor.getControlId()) === 5 ){
                        if (!this.service.isNull( this.incomingAncestor.getData()) &&
                            this.incomingAncestor.getData().toString() === this.incomingQuestion.getOption().label){
                            if (!l){
                                if (parseFloat(this.incomingQuestion.getControlId()) === 24) {
                                    if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')) {
                                        if (Boolean(this.incomingQuestion.getIsNull())) {
                                            this.question = this.incomingQuestion.getAttributes();
                                            this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                            this.panel = this.incomingAncestor.getCntrlNum();
                                            l = true;
                                        }
                                    }
                                }else if (parseFloat(this.incomingQuestion.getControlId()) === 10){
                                    if (this.service.isNull(this.incomingQuestion.getData())){
                                        this.question = this.incomingQuestion.getAttributes();
                                        this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                        this.panel = this.incomingAncestor.getCntrlNum();
                                        l = true;
                                    }else{
                                        this.incomingConditions.setAttributes(this.incomingQuestion.getAttributes().hasOwnProperty('conditions') ? this.incomingQuestion.getConditions() : {});
                                        if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') &&
                                            this.incomingConditions.getAttributes().hasOwnProperty('maximum_value') &&
                                            !this.service.isNull(this.incomingConditions.getMinimumValue()) &&
                                            !this.service.isNull(this.incomingConditions.getMaximumValue())){
                                            if (parseFloat(this.incomingConditions.getMinimumValue()) <= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, '')) &&
                                                parseFloat(this.incomingConditions.getMaximumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                            }else{
                                                this.question = this.incomingQuestion.getAttributes();
                                                this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                                this.panel = this.incomingAncestor.getCntrlNum();
                                                l = true;
                                            }
                                        }else if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') && !this.service.isNull(this.incomingConditions.getMinimumValue())){
                                            if (parseFloat(this.incomingConditions.getMinimumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                                this.question = this.incomingQuestion.getAttributes();
                                                this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                                this.panel = this.incomingAncestor.getCntrlNum();
                                                l = true;
                                            }
                                        }
                                    }
                                }else {
                                    if (this.service.isNull(this.incomingQuestion.getData())){
                                        this.question = this.incomingQuestion.getAttributes();
                                        this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                        this.panel = this.incomingAncestor.getCntrlNum();
                                        l = true;
                                    }
                                }
                            }
                        }
                    }else if (parseFloat(this.incomingAncestor.getControlId()) === 18){
                    }else{
                        if (!l){
                            if (parseFloat(this.incomingQuestion.getControlId()) === 24) {
                                if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')) {
                                    if (Boolean(this.incomingQuestion.getIsNull())) {
                                        this.question = this.incomingQuestion.getAttributes();
                                        this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                        this.panel = this.incomingAncestor.getCntrlNum();
                                        l = true;
                                    }
                                }
                            }else if (parseFloat(this.incomingQuestion.getControlId()) === 10){
                                if (this.service.isNull(this.incomingQuestion.getData())){
                                    this.question = this.incomingQuestion.getAttributes();
                                    this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                    this.panel = this.incomingAncestor.getCntrlNum();
                                    l = true;
                                }else{
                                    this.incomingConditions.setAttributes(this.incomingQuestion.getAttributes().hasOwnProperty('conditions') ? this.incomingQuestion.getConditions() : {});
                                    if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') &&
                                        this.incomingConditions.getAttributes().hasOwnProperty('maximum_value') &&
                                        !this.service.isNull(this.incomingConditions.getMinimumValue()) &&
                                        !this.service.isNull(this.incomingConditions.getMaximumValue())){
                                        if (parseFloat(this.incomingConditions.getMinimumValue()) <= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, '')) &&
                                            parseFloat(this.incomingConditions.getMaximumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                        }else{
                                            this.question = this.incomingQuestion.getAttributes();
                                            this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                            this.panel = this.incomingAncestor.getCntrlNum();
                                            l = true;
                                        }
                                    }else if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') && !this.service.isNull(this.incomingConditions.getMinimumValue())){
                                        if (parseFloat(this.incomingConditions.getMinimumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                            this.question = this.incomingQuestion.getAttributes();
                                            this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                            this.panel = this.incomingAncestor.getCntrlNum();
                                            l = true;
                                        }
                                    }
                                }
                            }else {
                                if (this.service.isNull(this.incomingQuestion.getData())){
                                    this.question = this.incomingQuestion.getAttributes();
                                    this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                    this.panel = this.incomingAncestor.getCntrlNum();
                                    l = true;
                                }
                            }
                        }
                    }
                }else {
                    if (parseFloat(this.incomingQuestion.getControlId()) === 24) {
                        if (this.incomingQuestion.getAttributes().hasOwnProperty('is_null')) {
                            if (Boolean(this.incomingQuestion.getIsNull())) {
                                this.question = this.incomingQuestion.getAttributes();
                                this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                this.panel = null;
                                l = true;
                            }
                        }
                    }else if (parseFloat(this.incomingQuestion.getControlId()) === 10){
                        if (this.service.isNull(this.incomingQuestion.getData())){
                            this.question = this.incomingQuestion.getAttributes();
                            this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                            this.panel = null;
                            l = true;
                        }else{
                            this.incomingConditions.setAttributes(this.incomingQuestion.getAttributes().hasOwnProperty('conditions') ? this.incomingQuestion.getConditions() : {});
                            if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') &&
                                this.incomingConditions.getAttributes().hasOwnProperty('maximum_value') &&
                                !this.service.isNull(this.incomingConditions.getMinimumValue()) &&
                                !this.service.isNull(this.incomingConditions.getMaximumValue())){
                                if (parseFloat(this.incomingConditions.getMinimumValue()) <= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, '')) &&
                                    parseFloat(this.incomingConditions.getMaximumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                }else{
                                    this.question = this.incomingQuestion.getAttributes();
                                    this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                    this.panel = null;
                                    l = true;
                                }
                            }else if (this.incomingConditions.getAttributes().hasOwnProperty('minimum_value') && !this.service.isNull(this.incomingConditions.getMinimumValue())){
                                if (parseFloat(this.incomingConditions.getMinimumValue()) >= parseFloat(this.incomingQuestion.getData().toString().replace(/,/g, ''))){
                                    this.question = this.incomingQuestion.getAttributes();
                                    this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                                    this.panel = null;
                                    l = true;
                                }
                            }
                        }
                    }else {
                        if (this.service.isNull(this.incomingQuestion.getData())){
                            this.question = this.incomingQuestion.getAttributes();
                            this.position = this.service.findIndex(this.formService.questions, 'cntrl_num', this.incomingQuestion.getCntrlNum());
                            this.panel = null;
                            l = true;
                        }
                    }
                }
            }
        });
        callback({status: l});
        return null;
    }

    fileUpload(file: any, callback: any){
            const outgoingRequestFile: any = new Parameters();
            outgoingRequestFile.setAttributes(file);
            this.service.httpService( 'post', this.service.app.routes.files.upload,
                Object.assign({}, outgoingRequestFile.getAttributes()),
                {ignoreLoadingBar: 'true', notify: false},
                (response: any) => {
                callback(outgoingRequestFile.getAttributes());
            }, () => {});
    }

    displayUnwrittenQuestion(questions: any){
        [questions[0]].map((question: any) => {
            this.incomingQuestion.setAttributes(question);
            this.notifierService.notify('success', this.incomingQuestion.getAttributes().label.toString());
            if (this.incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
                const ancestor: any = this.formService.getQuestionByCntrlNum(this.incomingQuestion.getAttributes().ancestor);
                this.incomingAncestor.setAttributes(ancestor);
                const opt: any = this.incomingAncestor.getAttributes().options.filter((option: any) => {
                    this.incomingOption.setAttributes(option);
                    return this.incomingOption.getAttributes().cntrl_num.toString().includes(this.incomingQuestion.getAttributes().option.cntrlNum.toString()); })[0];
                this.outgoingOption.setAttributes(opt);
                if (this.outgoingOption.getAttributes().hasOwnProperty('questions')) {
                    this.formService.submit = false;
                    this.options = [];
                    this.outgoingOption.getAttributes().questions.map((q: any) => {
                        this.incomingQ.setAttributes(q);
                        this.incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQ.getAttributes().cntrl_num));
                        this.options.push(this.incomingQ.getAttributes());
                    }); this.panel = this.incomingAncestor.getAttributes().cntrl_num;
                    this.incomingAncestor.setData(this.outgoingOption.getAttributes().label);
                    this.position = this.service.findIndex(this.options, 'cntrl_num', this.incomingQuestion.getAttributes().cntrl_num);
                    this.question = this.incomingQuestion.attributes;
                }
            }else {
                this.panel = null;
                this.options = this.ancestors;
                this.position = this.service.findIndex(this.ancestors, 'cntrl_num', this.incomingQuestion.getAttributes().cntrl_num);
                this.question = this.incomingQuestion.attributes;
            }
        });
    }
    timeout(){return 620; }
    clear(){
        this.formService.questions.map((question: any, key: any) => {
            this.formService.questions[key] = Object.assign(this.formService.questions[key], {data: '', src: '', pic: ''});
            delete this.form.post_id;
            this.progress = 0;
        });
    }



}
