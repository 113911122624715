import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";
import {ClientService} from "../client.service";
import {FormService} from "../../forms/form.service";

@Component({
  selector: 'app-dashboard-clients',
  templateUrl: './dashboard-clients.component.html',
  styleUrls: ['./dashboard-clients.component.scss']
})
export class DashboardClientsComponent implements OnInit {

  constructor(
    public service: AppService,
    private clientService: ClientService,
    private formService: FormService) {
  }

  ngOnInit(): void {
  }


}
