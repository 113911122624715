import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RegionsService} from '../regions.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {Parameters} from '../../parameters';
import {RegionalsComponent} from '../regionals.component';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppService} from '../../app.service';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';

@Component({
  selector: 'app-table-regional',
  templateUrl: './table-regional.component.html',
  styleUrls: ['./table-regional.component.scss']
})
export class TableRegionalComponent implements OnInit {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  regionals: any;
  incomingSelectedRegional: any;
  searchCntrl: any;
  selectedRegional: any;
  constructor(public regionalImpl: RegionsService,
              public broadcastChannel: BroadcastChannelService,
              private regionalCompoment: RegionalsComponent,
              public dialog: MatDialog,
              private router: Router,
              public metthods: AppService) {
    this.regionals = this.metthods.app.data.regions;
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(regionalImpl.getSelected());
    this.contextMenu = MatMenuTrigger;
  }

  ngOnInit(): void {
    this.broadcastChannel.getNavChangeEmitter()
      .subscribe((item: any) => this.handleSearchCntrl(item));
  }


  onContextMenu(event: MouseEvent, regional: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': regional };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleSearchCntrl(item: any){
    if(item instanceof  Object ){
      if(item.hasOwnProperty('searchText'))
        this.searchCntrl = item.searchText;
    }
  }

  handleDestroy(slaughter: any){
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(this.selectedRegional);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.regionals,
        model: this.selectedRegional,
        title: this.incomingSelectedRegional.getName(),
        url  : '/slaughters/' + parseFloat(this.incomingSelectedRegional.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedRegional.getId(),
      },
      width : 'auto',
    });
  }

  handleDistricts(regional: any){
    this.incomingSelectedRegional.setAttributes(this.selectedRegional);
    this.regionalImpl.setSelected(this.incomingSelectedRegional.getAttributes());
    this.router.navigateByUrl('/app/regions/' + this.incomingSelectedRegional.getName().toLowerCase() + '/districts');
  }


  handleRestore(regional: any){
    this.incomingSelectedRegional.setAttributes(this.selectedRegional);
    this.metthods.restore('/regionals/' + parseFloat(this.incomingSelectedRegional.getId()) + '/restore',
    this.regionals, 'id', parseFloat(this.incomingSelectedRegional.getId()), this.selectedRegional);
  }

  handleUpdate(regional: any){
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(this.selectedRegional);
    this.regionalImpl.setSelected(this.incomingSelectedRegional.getAttributes());
    this.regionalCompoment.openDialog();
  }


}
