import { Injectable } from '@angular/core';
import {AppService} from '../app.service';
import {Parameters} from '../parameters';
@Injectable({
  providedIn: 'root'
})
export class OutletService {
  outlets: any = [];
  isSearched: any = false;
  outgoingRequest: any;
  incomingItemSelected: any;
  selected: any = {};
  constructor(public service: AppService) {
   this.outgoingRequest = new Parameters();
   this.incomingItemSelected = new Parameters();
   this.incomingItemSelected.setAttributes({});
  }

  requestOutletCategories(){
      this.service.httpService('post', '/outlets/categoriesrequest',{}, {ignoreLoadingBar : 'true', notify: 'false'},
          (results: any) => {
             this.service.app.data.outlets.types = results;
              this.service.app.data.outlet_types = {data:results};
          }, (error: any) => {
          });
  }

  getAddress(lat:any,lng:any,callback: any){
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setLat(lat);
        this.outgoingRequest.setLng(lng);
        this.service.httpService('post', this.service.app.routes.home.address.get,
            this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
            callback(result);
          }, (error: any) => {
          });
  }


  search(request: any,callback: any){
        this.outgoingRequest.setAttributes(request);
        this.service.httpService('post', this.service.app.routes.outlets.search,this.outgoingRequest.getAttributes(), {},
            (response: any) => {
            this.getResult(response,callback);
            }, (error: any) => {
            });
  }

  setSelected(Outlet: any){
   this.selected = Outlet;
  }

  getSelected(){
     return this.selected;
  }

  getResult(response: any,callback: any){
   setTimeout((result: any) =>{
       response.map((outlet: any) => {
         result = [];
         result = this.service.app.data.outlets.data.filter((itm: any) => {
           return parseFloat(outlet.id) === parseFloat(itm.id);
         });if(result.length === 0){
           this.service.app.data.outlets.data.push(outlet);
         }
       });
    });
    callback(response);
  }
}
