import {Component, Inject, OnInit} from '@angular/core';
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {NestedTreeControl} from "@angular/cdk/tree";
import {AppService} from "../app.service";
import {FormService} from "../forms/form.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Parameters} from "../parameters";
import {UserService} from "./user.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  dataSource: any = new MatTreeNestedDataSource<any>();
  treeControl: any = new NestedTreeControl<any>(node => node.children);
  incomingUser: any;
  incomingForm: any;
  constructor(public service: AppService,
              public userService: UserService,
              private formService: FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<UserComponent>) {
    this.incomingUser = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingUser.setAttributes({});
    this.incomingForm.setAttributes(this.formService.getSelected());
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(null);
    this.userService.setSelected({});
  }

}
