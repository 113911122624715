<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<a class=""><h4>{{this.incomingForm.getName()}}</h4></a>
<br>
<div class="col-lg-0 col-md-0 col-sm-0 col-xs-0"></div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
     [ngStyle]="{'font-size':'16px'}">
    <div [innerHTML]="incomingForm.getDescription() | safeUrl"></div><br><br>
    <table [ngStyle]="{'width':'100%'}">
        <tr *ngFor="let question  of questions">
            <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 12; else choice">
                <td ngClass="text-uppercase">
                    <!--                            {{question[strings.getLabel()]}} <br><br><br>-->
                </td>
            </ng-container>
            <ng-template #choice>
                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 5; else checkbox">
                    <td [id]="question[this.strings.getIndex()]">
                        <span [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                        <mat-radio-group
                                aria-labelledby="example-radio-group-label"
                                class="example-radio-group">
                            <mat-radio-button
                                    class="example-radio-buttonn"
                                    #checked
                                    (change)="onChecked(question, option, checked)"
                                    *ngFor="let option of question[strings.getOptions()]"
                                    [value]="option">
                                <a class="" [innerHTML]="option[strings.getLabel()]"></a>
                            </mat-radio-button>
                        </mat-radio-group>
                    </td>
                </ng-container>
            </ng-template>
            <ng-template #checkbox>
                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 3; else address">
                    <td [id]="question[this.strings.getIndex()]">
                        <span [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                        <section class="example-section">
                            <p *ngFor="let option of question[strings.getOptions()]">
                                <mat-checkbox class="example-margin"
                                              #checked
                                              (change)="onChecked(question, option, checked)">
                                    {{option[strings.getLabel()]}}
                                </mat-checkbox>
                            </p>
                        </section>
                    </td>
                </ng-container>
            </ng-template>
            <ng-template #address>
                <ng-container *ngIf="this.service.parseFloat(question[this.strings.getControlId()]) === 6; else  contents">
                    <td [id]="question[this.strings.getIndex()]">
                        <span [innerHTML]="question[strings.getLabel()]"></span> <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? '* ' : '') }}</span><br>
                        <mat-form-field  class="example-full-width" appearance="outline">
                            <input type="text"
                                   class="sawasdee26 text-center"
                                   matInput
                                   #addressElement
                                   autocomplete="off"
                                   placeholder=""
                                   (input)="onInputTextChange(question, $event)"
                                   [ngStyle]="{'text-align': 'center'}"
                                   matGoogleMapsAutocomplete>
                        </mat-form-field>
                    </td>
                </ng-container>
            </ng-template>
            <ng-template #card>
                <ng-container *ngIf="this.incomingFormSettings.hasAttribute(this.strings.getInvitationCard()) && this.incomingFormSettings.getInvitationCard() === question[this.strings.getCntrlNum()]; else contents">
                    <!--                            <div [innerHTML]="question[this.strings.getLabel()] | safeUrl"></div>-->
                </ng-container>
            </ng-template>

            <ng-template #contents>
                <td [id]="question[this.strings.getIndex()]">
                    {{question[strings.getLabel()]}} <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(question[strings.getRequired()]) === 1 ? ' * ' : '') }}</span><br>
                    <mat-form-field  class="example-full-width" appearance="outline">
                        <input type="text"
                               class="text-center"
                               aria-label = "Number"
                               [ngStyle]="{'text-align': 'center'}"
                               (input)="onInputTextChange(question, $event)"
                               [placeholder]="''"
                               matInput>
                    </mat-form-field>
                </td>
            </ng-template>
        </tr>
        <tr>
            <td>
                <center>
                    <button (click)="ngSubmit({})"
                       [disabled]="this.disabled"
                       class="btn btn-lg"
                       [ngStyle]="{'background-color':'rgb(16,30,81)','color':'#fff'}" >
                        Submit
                    </button>
                </center>
            </td>
        </tr>
    </table>
</div>
<div class="col-lg-0 col-md-0 col-sm-0 col-xs-0"></div>