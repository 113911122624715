import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {AppService} from "../../app.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {CampaignService} from "../../campaigns/campaign.service";
import {FormService} from "../form.service";
import {ResponseService} from "../responses/data/response.service";
import {Parameters} from "../../parameters";
import {FilterDashboardComponent} from "../../dashboard/filter-dashboard.component";
import {DeleteDialogComponent} from "../../main/delete-dialog.component";
import {DashboardService} from "../../dashboard/dashboard.service";

@Component({
  selector: 'app-files-form',
  templateUrl: './files-form.component.html',
  styleUrls: ['./files-form.component.scss']
})
export class FilesFormComponent implements OnInit {

  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  form: any;
  formId: any;
  formValues: any;
  subscription: any;
  searchCntrl: any;
  teams: any;
  isDialogOpen: any = false;
  campaign: any;
  markers: any = [];
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any = 10;
  constructor(public service: AppService,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              public broadcastChannel: BroadcastChannelService,
              private campaignService: CampaignService,
              private dashboardService: DashboardService,
              private router: Router,
              private formService: FormService,
              private responseService: ResponseService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.formId = this.activatedRoute.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.form = this.formService.getFormById(this.formId);
    this.formValues = new Parameters();
    this.formValues.attributes = this.form;
    this.responseService.reportValues.attributes.form_id = this.formValues.attributes.id;
    this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
    this.service.header = this.form.name;
  }

  ngOnInit(): void {
    document.title = this.campaign.name;
    if(this.form.responses.data === null) {
      this.report();
    }
    setTimeout(() => {
      this.emitter();
    }, 13);

  }

  onInfoWindowClose(gm: any, infoWIndow: any){}
  getHeight(){
    return {height: window.innerHeight};
  }

  emitter(){
    this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if (item instanceof Object) {
            if (item.hasOwnProperty('searchText') === true)
              this.searchCntrl = item.searchText;
          } else if (item.includes('extract')) {
            this.responseService.reportValues.setDisplay('excel');
            if(this.responseService.isDialogOpen === false) {
              this.responseService.isDialogOpen = true;
              this.dialog.open(FilterDashboardComponent, {
                data: {report: this.responseService.reportValues.getAttributes()},
                width: '55%',
                height: '100%'
              }).beforeClosed().subscribe(() =>{
                this.responseService.isDialogOpen = false;
              });
            }
          } else if (item.includes('search')) {
            this.handleSearch();
          } else if (item === 'openDateMenu') {
            this.openMenuDate();
          } else if (item === 'report') {
            this.report();
            this.formService.setDate(this.responseService, this.dashboardService);
          }
        });
  }

  copy(){
    document.execCommand('copy');
  }


  report(){
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
      delete outgoingRequest.data;
      this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.form.responses.data = response;
        this.responseService.reportValues.attributes.data = 'values';
      }, (error: any) => {
      });
    });
  }


  openMenuDate(){
    if(this.responseService.isDialogOpen === false) {
      this.responseService.isDialogOpen = true;
      this.responseService.reportValues.setDisplay('dashboard');
      this.dialog.open(FilterDashboardComponent, {data: {report: this.responseService.reportValues.getAttributes()}, width: '55%', height: '100%'}).beforeClosed().subscribe(() => {
        this.responseService.isDialogOpen = false;
      });
    }
  }


  handleSearch(){
    setTimeout((outgoingRequest: any = {}) => {
      this.responseService.reportValues.setDisplay('dashboard');
      outgoingRequest = Object.assign(outgoingRequest, this.responseService.reportValues.attributes);
      outgoingRequest.searchText = this.searchCntrl;
      delete outgoingRequest.data;
      this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.form.responses.data = response;
        this.responseService.reportValues.attributes.data = 'values';
      }, (error: any) => {
      });
    });
  }




  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleDestroy(response: any){
    this.formValues = new Parameters();
    this.formValues.setAttributes(this.form);
    this.dialog.open(DeleteDialogComponent, {
      data   : {
        data : this.form.responses,
        model: this.form,
        title: this.formValues.getName(),
        url  : '/forms/' + parseFloat(this.formValues.getId()) + '/delete',
        key  : 'id',
        id   : this.formValues.getId(),
      },
      width : 'auto',
    });
  }

  getCampaignByForm(form: any){
    let campaign: any = this.service.only(this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === form.campaign_id;})[0]);
    let client: any = this.service.only(this.getClientByCampaign(campaign));
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
    return campaign;
  }


  getClientByCampaign(campaign: any){
    return this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
  }

  handleUpdate(inspector: any){
    this.formValues = new Parameters();
    this.formValues.setAttributes(this.form);
    this.responseService.setSelected(this.formValues.getAttributes());
    // this.formsComponent.openDialog();
  }

  handleRestore(inspector: any){
    this.formValues.setAttributes(this.form);
    this.service.restore('/forms/' + parseFloat(this.formValues.getId()) + '/restore',
        this.form.responses, 'id', parseFloat(this.formValues.getId()), this.form);
  }


  handleResponses(form: any){
    this.subscription.unsubscribe();
    this.formValues.setAttributes(this.form);
    this.responseService.setSelected(this.form);
    this.router.navigateByUrl('/app/forms/' + this.form.id + '/responses');
  }

  openDialog(response: any){
    this.responseService.setSelected(this.formValues.getAttributes());
    // this.inspectorsComponent.openDialog();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
