import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {Parameters} from '../parameters';
import * as $ from 'jquery';
@Component({
  selector: 'app-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss']
})
export class DateDialogComponent implements OnInit {
  startingAt: any;
  endingAt: any;
  report: any;
  form: any;
  fieldHidden: any;
  constructor(public serviceImpl: AppService) {
    this.report = new Parameters();
    this.report.setAttributes(this.serviceImpl.report);
    this.startingAt = new Date(this.report.getStartingAt());
    this.endingAt = new Date(this.report.getEndingAt());
  }

  ngOnInit(): void {
  }

  onSelectStartingAt(event: any){
    this.endingAt = event > this.endingAt ? event : this.endingAt;
    this.startingAt = event;
    this.report.setStartingAt(this.startingAt);
    this.report.setEndingAt(this.endingAt);
  }

  onSelectEndingAt(event: any){
    this.startingAt = event < this.startingAt ? event : this.startingAt;
    this.endingAt = event;
    this.report.setStartingAt(this.startingAt);
    this.report.setEndingAt(this.endingAt);
  }

  ngSubmit(){
    if(this.report.getDisplay().toString().includes('excel') === true){
      this.form = document.createElement('form');
      this.form.action = this.serviceImpl.app.settings.routes.server + '/reports';
      this.form.method = 'get';
      this.form.enctype = 'multipart/form-data';
      this.fieldHidden  = document.createElement('input');
      this.fieldHidden.name    = 'token';
      this.fieldHidden.type    = 'hidden';
      this.fieldHidden.value   = this.serviceImpl.getToken();
      this.form.appendChild(this.fieldHidden);
      for (const key in this.report.getAttributes()) {
      this.fieldHidden       = document.createElement('input');
      this.fieldHidden.name  = key;
      this.fieldHidden.type  = 'hidden';
      this.fieldHidden.value = this.report.attributes[key];
      this.form.appendChild(this.fieldHidden);
      }
      $('#form').append(this.form);
      this.form.submit();
    }
  }


}
