<div [ngStyle]="{'width':'100%','height':'100%','background-color':this.incomingSettingsForm.getBackgroundColor()}">
<!--    <div matBadge="{{this.invitees.length}}"-->
<!--         [ngStyle]="{'margin-top':'20px','margin-right':'40px'}"-->
<!--         matBadgeColor="primary"-->
<!--         matBadgeOverlap="false"-->
<!--         class="cursor pull-right"-->
<!--         *ngIf="this.invitees.length!==0">-->
<!--    </div>-->
    <!--SIGNUP SECTION START-->
    <section class="signup-section" [ngStyle]="{'background-color':this.incomingSettingsForm.getBackgroundColor()}">
        <div class="container" [ngStyle]="{'background-color':this.incomingSettingsForm.getBackgroundColor()}">
            <div class="holder" [ngStyle]="{'color':'#000'}">
                <div class="thumb"><img src="{{incomingClient.getUnknown('logo')}}" alt="img" style="width: 85px; height: 85px"></div>
                <form (keydown.enter)="$event.preventDefault()"
                      [formGroup]=formValues
                      (ngSubmit)="onSubmit(formValues.value)">
                    <div class="input-box">
                        <i class="fa fa-pencil"></i>
                        <input type="text"
                               #name
                               placeholder="Name"  />
                    </div>
                    <div class="input-box">
                        <i class="fa fa-phone"></i>
                        <input type="text"
                               #phone
                               placeholder="Phone" />
                    </div>
                    <div class="input-box">
                        <i class="fa fa-envelope"></i>
                        <input type="text"
                               #email
                               placeholder="Email" />
                    </div>
                    <div>
                        <center>
                             <a class=""
                                mat-button
                                (click)="register()"
                                [ngStyle]="{'color':this.incomingSettingsForm.getBackgroundColor(),'background-color':this.incomingSettingsForm.getColor()}">
                                 Register
                             </a>
                        </center>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!--SIGNUP SECTION END-->
</div>

<!--<center>-->
<!--    <img class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--         src="{{incomingClient.getUnknown('logo')}}"-->
<!--         [ngStyle]="{'width':'60px','height':'60px'}"/>-->
<!--</center>-->
<!--<br><br>-->
<!--<div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 mdl-shadow&#45;&#45;2dp">-->
<!--    -->
<!--    <mat-list>-->
<!--        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width">-->
<!--                                <mat-label class="mitra18x">{{placeholderFullName}}<span class="red">*</span></mat-label>-->
<!--                                <input matInput-->
<!--                                       #name-->
<!--                                       type="text"-->
<!--                                       class="sawasdee20"-->
<!--                                       [(ngModel)]="outgoingNameQuestion.attributes.data">-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width">-->
<!--                                <mat-label class="mitra18x">Phone <span class="red">*</span></mat-label>-->
<!--                                <input matInput-->
<!--                                       type="text"-->
<!--                                       class="sawasdee20"-->
<!--                                       [(ngModel)]="outgoingPhoneQuestion.attributes.data">-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width">-->
<!--                                <mat-label class="mitra18x">Email <span class="red">*</span></mat-label>-->
<!--                                <input matInput-->
<!--                                       #email-->
<!--                                       type="text"-->
<!--                                       class="sawasdee20"-->
<!--                                       [(ngModel)]="outgoingEmailQuestion.attributes.data">-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                             <center>-->
<!--                                 <a class=""-->
<!--                                    mat-button-->
<!--                                    (click)="register()"-->
<!--                                    [ngStyle]="{'color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">-->
<!--                                     Register-->
<!--                                 </a>-->
<!--                             </center>-->
<!--                            </span>-->
<!--        </mat-list-item>-->
<!--    </mat-list>-->
<!--    [ngStyle]="{'position':'absolute','left':'-3000px'}"-->
    <div  [ngStyle]="{'position':'absolute','left':'-3000px'}">
        <center>
            <div id="qrcode" [ngStyle]="{'width':'300px','height':'300px'}"></div>
        </center>
        <center>
            <div id="htmlDOC" [ngStyle]="{'background-color':'#fff','color':'#000'}">
                <div [innerHTML]="this.outgoingInvitationCardQuestion.getUnknown('data') | safeUrl"></div>
            </div>
        </center>
    </div>
<!--</div>-->
<!--<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mdl-shadow&#45;&#45;2dp">-->
<!--    <mat-list>-->
<!--        <mat-list-item  *ngFor="let response of invitees">-->
<!--                        <span class="" mat-line>-->
<!--                            <table class="mitra15X2x">-->
<!--                                <td>-->
<!--                                    <span><img src="assets/images/avatar.jpg"-->
<!--                                               class="img-circle mdl-shadow&#45;&#45;2dp"-->
<!--                                               [ngStyle]="{'width':'30px','height':'30px'}"/></span>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <span  *ngFor="let responseResult of response.controls">-->
<!--                                     {{this.service.upperStartingCharacter(responseResult.data, true)}},-->
<!--                                   </span>-->
<!--                                </td><br>-->
<!--                            </table>-->
<!--                        </span>-->
<!--        </mat-list-item>-->
<!--    </mat-list>-->
<!--</div>-->