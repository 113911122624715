<div class="row">
  <div class="col-md-6 col-sm-6">
    <mat-calendar [selected]="startingAt" (selectedChange)="onSelectStartingAt($event)" id="startingAt"></mat-calendar>
  </div>
  <div class="col-md-6 col-sm-6">
    <mat-calendar [selected]="endingAt" (selectedChange)="onSelectEndingAt($event)"></mat-calendar>
  </div>
</div>
<div class="row">
  <br><br><br>
  <center>
    <a class="btn btn-primary" (click)="ngSubmit()" #applyElement >Apply</a>
    <a class="btn btn-primary" [mat-dialog-close]="">Cancel</a>
  </center>
</div>
