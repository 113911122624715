<div layout="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <ng-container *ngIf="incomingFormSettings.getInvitationCard().includes(incomingQuestion.getCntrlNum());else description">
            <mat-card class="example-card" [ngStyle]="{'max-width':'auto'}">
                <mat-card-content>
                    <p [innerHTML]="incomingQuestion.getLabel() | safeUrl"></p>
                    <p [innerHTML]="incomingFormSettings.getArrivingInvitationCardContents() | safeUrl"></p>
                </mat-card-content>
                <mat-card-actions>
                    <center>
                        <span>
                            <button  mat-button
                                     class="mdl-shadow--2dp"
                                     [disabled]="false">
                                {{service.upperStartingCharacter(strings.getDownload(), true)}}
                            </button>
                        </span>
                    </center>
                </mat-card-actions>
            </mat-card>
        </ng-container>
        <ng-template #description>
            <ng-container>
                <span>{{incomingQuestion.getLabel()|safeUrl}}</span>
            </ng-container>
        </ng-template>
<!--        <div class="sawasdee20">-->
<!--            <div id="doc" *ngIf="flag">-->
<!--                <div-->
<!--                     [ngStyle]="{'color': formService.color}"-->
<!--                     (contextmenu)="onContextMenu($event, {})"-->
<!--                     [innerHTML]="incomingQuestion.getUnknown('content')|safeUrl"></div>-->
<!--            </div>-->
<!--            <div *ngIf="!flag">-->
<!--                <center>-->
<!--                    <div class="font11" [ngStyle]="{'color': formService.color}">-->
<!--                        <mat-progress-bar-->
<!--                                color="warn"-->
<!--                                mode="query" [ngStyle]="{'width':'100%'}"></mat-progress-bar>-->
<!--                        {{this.service.upperStartingCharacter(this.service.app.strings.invitation.card.send, true)}}-->
<!--                    </div>-->
<!--                </center>-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
</div>

<input type="file"
       id="logoInputFile"
       (change)="getLogo($event)"
       accept="image/*"
       [hidden]="true"
       #logoElement />

<input type="file"
       id="frame"
       (change)="onFrameFileChange($event)"
       accept="image/*"
       [hidden]="true"
       #frame />

<input type="file"
       id="banner"
       (change)="onBannerFileChange($event)"
       accept="image/*"
       [hidden]="true"
       #banner />

<input type="file"
       (change)="getQrcode($event)"
       accept="image/*"
       [hidden]="true"
       #elmQrcode />

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="logoElement.click()">logo</button>
        <button mat-menu-item (click)="elmQrcode.click()">Qr code</button>
        <button mat-menu-item (click)="frame.click()">Frame</button>
        <button mat-menu-item (click)="banner.click()">Banner</button>
        <button mat-menu-item (click)="download()">Download Pdf</button>
        <button mat-menu-item (click)="downloadImg()">Download Image</button>
    </ng-template>
</mat-menu>



