import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {AppService} from "../app.service";
import {ProfileComponent} from '../users/profile/profile.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogUserComponent} from "./dialog-user/dialog-user.component";
import {DialogPasswordUserComponent} from './dialog-password-user/dialog-password-user.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  report: any;

  /**
   * @param mixed Data
   */

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor(private service: AppService,
              private dialog: MatDialog) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }

  getUserByUser(user: any){
    var users: any = [] = this.service.app.data.users.data.filter((item: any) => {
      return parseFloat(item.id) === parseFloat(user.id); });
    return users.length === 0 ? this.addUser(user) : users[0];
  }

  openUserProfileDialog(user: any){
    this.dialog.open(
      ProfileComponent, {
        data  : {user: user},
        width : '100%',
        height: '100%',
        disableClose: false,
      },
    );
  }

  addUser(user: any){
    this.service.app.data.users.data.unshift(user);
    return user;
  }

  search_(outgoingRequest: Parameters, call: any){
    this.service.httpService('POST', this.service.app.routes.users.search,
        outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
        (users: any) => {
          call(users);
        }, (error: any) => {
          call(null);
        });
  }

  getUserById(userId: any){
    var users: any = [] = this.service.app.data.users.data.filter((user: any) => {
      return parseFloat(user.id) === parseFloat(userId);});
    return users[0];
  }

  openDialog(user: any){
    this.dialog.open(
        DialogUserComponent, {
          data  : {user: user},
          width : '100%',
          height: '100%',
          disableClose: false,
        },
    );
  }

  openChangePasswordDialog(params: any){
    this.dialog.open(DialogPasswordUserComponent, {data  : {}, width : '55%', height: '100%', });
  }


  search(params: any){
    this.service.httpService('post', '/users/search', params, {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            response.map((item: any) => {
              result = [];
              result = this.service.app.data.users.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });if(result.length === 0){
                this.service.app.data.users.data.push(item);
              }
            });
          });
        }, (error: any) => {
        });
  }
}
