import {Component, OnInit, Input, OnChanges, ViewChild,NgZone } from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from '../../../../parameters';
import {FormService} from '../../../../forms/form.service';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';
import {MatMenuTrigger} from '@angular/material/menu';
import {ResponseService} from '../../../responses/data/response.service';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {NotifierService} from 'angular-notifier';
import html2canvas from 'html2canvas';
import System from "@zxing/library/es2015/core/util/System";
@Component({
  selector: 'app-view-qrcode-controls',
  templateUrl: './view-qrcode-controls.component.html',
  styleUrls: ['./view-qrcode-controls.component.scss']
})
export class ViewQrcodeControlsComponent implements OnChanges {
  @ViewChild(MatMenuTrigger) contextMenu: any;
  @ViewChild('qrcode', { static: false }) public qrcode2!: NgxQrcodeStylingComponent;
  contextMenuPosition = { x: '0px', y: '0px' };
  @Input() question: any;
  @Input() position: any;
  incomingQuestion: any;
  incomingCampaign: any;
  incomingClient: any;
  incomingResult: any;
  incomingForm: any;
  incomingSettingsForm: any;
  incomingResponse: any;
  qrcodeScanContent: any;
  public config: any = {
     width: 300,
     height: 300,
     data: '',
     image: '',
     margin: 5,
     dotsOptions: {
       color: "#000",
       type: "dots"
     },
     backgroundOptions: {
       color: "#ffffff",
     },
     imageOptions: {
       crossOrigin: "anonymous",
       margin: 0
     }
  };
  constructor(public service: AppService,
              private responseService: ResponseService,
              private notifierService: NotifierService,
              public zone: NgZone,
              private qrcode: NgxQrcodeStylingService,
              public  broadcastChannel: BroadcastChannelService,
              private formService: FormService) {
   this.incomingQuestion = new Parameters();
   this.incomingForm = new Parameters();
   this.incomingResult= new Parameters();
   this.incomingSettingsForm = new Parameters();
   this.incomingResponse = new Parameters();
   this.incomingCampaign = new Parameters();
   this.incomingClient = new Parameters();
   this.contextMenu = MatMenuTrigger;
  }

  onContextMenu(event: MouseEvent, inspector: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': inspector };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }

  download(){
   this.qrcode2.download(
    !this.service.isNull(this.incomingSettingsForm.getAttendeeName()) &&
    !this.service.isNull(this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data) ?
     this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data : this.incomingResponse.getCntrlNum()+ ".png").subscribe((res) => {
     this.notifierService.notify('success', 'successfull downloaded');
     this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
   });
  }

  setCrypt(ciphertext: any, callback: any){
       try{var result: any = this.service.encrypt(ciphertext);
          if(this.service.isNull(this.service.decrypt(result))) this.setCrypt(ciphertext,callback); else callback(result);
       }catch(e){ this.setCrypt(ciphertext,callback);}
  }


  ngOnChanges(changes: any): void {
   this.broadcastChannel.emitNavChangeEvent({action : 'hideNextButton'});
   this.incomingResponse.setAttributes(this.responseService.getSelected());
   this.incomingQuestion.setAttributes(this.question);
   this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') && this.incomingQuestion.getResult() instanceof Object ? this.incomingQuestion.getResult() : {path:'',src:''});
   this.incomingResult.setAttributes(this.incomingQuestion.getResult());
   this.incomingResult.setSrc(this.incomingResult.getAttributes().hasOwnProperty('src')?this.incomingResult.getSrc(): '');
   this.incomingForm.setAttributes(this.incomingResponse.getForm());
   this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
   this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
     this.incomingSettingsForm.getAttendeeName() : '');
   this.incomingSettingsForm.setBackgroundColor(this.incomingSettingsForm.attributes.hasOwnProperty('background_color') ?
     this.incomingSettingsForm.getBackgroundColor() : '');
   this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
     this.incomingSettingsForm.getLogoUrl() : '');
   this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
     this.incomingSettingsForm.getLogoDataUrl() : '');
   if(this.incomingForm.getAttributes().hasOwnProperty('campaign')){
    this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
    if(this.incomingCampaign.getAttributes().hasOwnProperty('client')){
     this.incomingClient.setAttributes(this.incomingCampaign.getClient());
     this.service.logo = this.incomingClient.getUnknown('logo');
    }
   }


   this.setCrypt(this.incomingResponse.getCntrlNum(),(result: any)=>{
       this.config.data=window.location.host + '/app/invitations/' + result +'/confirmation?q=';
       this.config.data=this.config.data.toString().includes('https://') ? this.config.data : 'https://' + this.config.data;
       if(!this.service.isNull(this.incomingSettingsForm.getBackgroundColor()))
       this.config.data=this.config.data +'&extension='+this.incomingSettingsForm.getBackgroundColor();
       if(!this.service.isNull(this.service.logo))
       this.config.data= this.config.data +'&access='+this.incomingClient.getLogo().split('clients/')[1];
       this.incomingResult.setData(this.config.data);
       this.incomingQuestion.setData(this.config.data);
       setTimeout(()=>{
         let element:any = document.getElementById('qrcode');
         this.qrcode.create(this.config, element).subscribe((res) => {
              setTimeout(() => {
                html2canvas(element).then(canvas => {
                     this.zone.run(() =>{
                       this.incomingQuestion.setUnknown('dataUrl', canvas.toDataURL("image/png"));
                       this.broadcastChannel.emitNavChangeEvent({action : 'showNextButton'});
//                     this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
                     });
                });
              },this.service.timeout());
         });
       });
   });
  }

}
