import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";
import {FormService} from "../../../form.service";

@Component({
  selector: 'app-text-create-control',
  templateUrl: './text-create-control.component.html',
  styleUrls: ['./text-create-control.component.scss']
})
export class TextCreateControlComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  label: any;
  constructor(
      public service: AppService,
      public formService: FormService) {
     }

  ngOnInit(): void {
  }

  getQuestion(){return this.question;}

}
