import { Component, OnInit } from '@angular/core';
import {FormService} from '../form.service';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-tabs-forms',
  templateUrl: './tabs-forms.component.html',
  styleUrls: ['./tabs-forms.component.scss']
})
export class TabsFormsComponent implements OnInit {

  forms: any;
  constructor(
      public service: AppService,
      public formService: FormService) {
  }

  ngOnInit(): void {
  }

}
