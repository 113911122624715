import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {FormService} from "../form.service";

@Component({
  selector: 'app-untitled-form',
  templateUrl: './untitled-form.component.html',
  styleUrls: ['./untitled-form.component.scss']
})
export class UntitledFormComponent implements OnInit {
  constructor(public service: AppService, public formService: FormService) { }

  ngOnInit(): void {
  }


}
