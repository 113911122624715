<div class="page">
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div>
                <div class="fixed">
                    <div class="roww">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="row top_tiles">
                                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div class="tile-stats" style="min-height: 375px">
                                        <span class="iconn pull-right" style="margin-right: 5px"><i class="fa fa-checkk"></i> <h3>{{formValues.getResponseDisplayTitle()}}</h3></span>
                                        <div class="count">{{service.numberWithCommans(reportValues.attributes.data.responses)}}
                                            <span style="font-size: 15px" class="{{reportValues.attributes.data.progress >= 100 ? 'text-success' : 'text-danger'}}">
                                                                    <i class="{{reportValues.attributes.data.progress >= 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                {{reportValues.attributes.data.progress >= 100 ? '+' + (reportValues.attributes.data.responses - reportValues.attributes.data.target) :
                                                (reportValues.attributes.data.responses - reportValues.attributes.data.target) + ' ' +
                                                reportValues.attributes.data.progress + '%'
                                                }}
                                            </span>
                                        </div>
                                        <div>
                                            <center>
                                                 <span class="chart" data-percent="100">
                                                     <span class="percent">{{reportValues.attributes.data.progress}}</span>
                                                 </span>
                                            </center>
                                        </div>
                                        <div>
                                            <br><br>
                                            <mat-divider></mat-divider>
                                            <center>
                                                <table>
                                                    <tr>
                                                        <td valign="top" style="border-right:  1px solid rgba(151, 151, 151, 0.3) !important; padding-right: 5px">
                                                            <h4>{{service.numberWithCommans(reportValues.attributes.data.weekly.responses)}}
                                                                <span class="{{reportValues.attributes.data.weekly.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                                    <i class="{{reportValues.attributes.data.weekly.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                                    {{(reportValues.attributes.data.weekly.progress > 0 ? '+' +
                                                                    (reportValues.attributes.data.weekly.responses - reportValues.attributes.data.weekly.target) :
                                                                    (reportValues.attributes.data.weekly.responses - reportValues.attributes.data.weekly.target) + ' ' +
                                                                    reportValues.attributes.data.weekly.progress) + '%'
                                                                    }}
                                                                </span>
                                                            </h4>
                                                            <center><span style="color:#BAB8B8">Weekly</span></center>
                                                        </td>
                                                        <td valign="top" style="border-right:  1px solid rgba(151, 151, 151, 0.3) !important; padding-right: 5px">
                                                            <h4>{{service.numberWithCommans(reportValues.attributes.data.monthly.responses)}}
                                                                <span class="{{reportValues.attributes.data.monthly.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                                    <i class="{{reportValues.attributes.data.monthly.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                                    {{(reportValues.attributes.data.monthly.progress > 0 ? '+' +
                                                                    (reportValues.attributes.data.monthly.responses - reportValues.attributes.data.monthly.target) :
                                                                    (reportValues.attributes.data.monthly.responses - reportValues.attributes.data.monthly.target) + ' ' +
                                                                    reportValues.attributes.data.monthly.progress) + '%'
                                                                    }}
                                                                </span>
                                                            </h4>
                                                            <center><span style="color:#BAB8B8">Monthly</span></center>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                    <div class="tile-stats" style="min-height: 375px">
                                        <span class="iconn pull-right" style="margin-right: 5px"><i class="fa fa-checkk"></i> <h3>{{formValues.getResponseDisplayTitle()}}, Monthly Trending</h3></span>
                                        <div class="count">
                                            {{service.numberWithCommans(reportValues.attributes.data.monthly.responses)}},
                                            <span class="{{reportValues.attributes.data.monthly.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                <i class="{{reportValues.attributes.data.monthly.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                {{(reportValues.attributes.data.monthly.progress > 0 ? '+' +
                                                (reportValues.attributes.data.monthly.responses - reportValues.attributes.data.monthly.target) :
                                                (reportValues.attributes.data.monthly.responses - reportValues.attributes.data.monthly.target) + ' ' +
                                                reportValues.attributes.data.monthly.progress) + '%'
                                                }}
                                                                </span>
                                        </div>
                                        <p>
                                            <app-bar-chartjs [labels]="only(reportValues.attributes.data.monthly.days, 'label')"
                                                             [label]="formValues.getResponseDisplayTitle()"
                                                             [type]="'line'"
                                                             [limit]="1000"
                                                             [data]="only(reportValues.attributes.data.monthly.days, 'responses')"
                                                             [element]="'monthlyResponsesElement'"
                                                             [color]="'red'"></app-bar-chartjs>
                                        </p>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                    <div class="tile-stats" style="min-height: 375px">
                                        <span class="iconn pull-right" style="margin-right: 5px"><i class="fa fa-checkk"></i> <h3>Teams, {{formValues.getResponseDisplayTitle()}} Summary</h3></span>
                                        <div class="count"> {{service.numberWithCommans(reportValues.attributes.data.teams.length)}}, <small>Teams</small></div>
                                        <p>
                                            <app-bar-chartjs [labels]="only(reportValues.attributes.data.teams, 'name')"
                                                             [label]="formValues.getResponseDisplayTitle()"
                                                             [type]="'line'"
                                                             [limit]="1000"
                                                             [data]="only(reportValues.attributes.data.teams, 'responses')"
                                                             [element]="'teamsResponsesElement'"
                                                             [color]="'red'"></app-bar-chartjs>
                                        </p>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngFor="let team of reportValues.attributes.data.teams">
                                        <div class="tile-stats" style="min-height: 375px;">
                                            <h3><small>{{service.limitTo(team.name, 20)}}</small></h3>
                                            <div>
                                                <center>
                                                    <div class="product_social">
                                                        <ul class="list-inline">
                                                            <li>
                                                                <center>
                                                                    <table>
                                                                        <tr>
                                                                            <td valign="top" style="border-right:  0px solid rgba(151, 151, 151, 0.3) !important; padding-right: 0px">
                                                                                <h4>{{service.numberWithCommans(team.responses)}},
                                                                                    <span class="{{team.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                                                    <i class="{{team.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                                                        {{team.progress > 100 ? '+' +
                                                                                        (team.responses - team.target) :
                                                                                        (team.responses - team.target) + ' ' +
                                                                                        team.progress + '%'
                                                                                        }}
                                                                                </span>
                                                                                </h4>
                                                                                <center>
                                                                                    <i class="fa fa-long-arrow-up"></i><br>
                                                                                    <span style="">{{formValues.getResponseDisplayTitle()}}</span>
                                                                                </center>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </center>
                                                                <center>
                                                                    <mat-divider></mat-divider>
                                                                    <table>
                                                                        <tr>
                                                                            <td valign="top" style="border-right:  1px solid rgba(151, 151, 151, 0.3) !important; padding-right: 5px">
                                                                                <h4>{{service.numberWithCommans(team.weekly.responses)}},
                                                                                    <span class="{{team.weekly.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                                    <i class="{{team.weekly.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                                                        {{team.weekly.progress > 100 ? '+' +
                                                                                        (team.weekly.responses - team.weekly.target) :
                                                                                        (team.weekly.responses - team.weekly.target) + ' ' +
                                                                                        team.weekly.progress + '%'
                                                                                        }}
                                                                </span></h4>
                                                                                <center>
                                                                                    <i class="fa fa-long-arrow-up"></i><br>
                                                                                    <span style="color:#BAB8B8">Weekly</span>
                                                                                </center>
                                                                            </td>
                                                                            <td valign="top" style="padding-left: 5px">
                                                                                <h4>{{service.numberWithCommans(team.monthly.responses)}},
                                                                                    <span class="{{team.monthly.progress > 100 ? 'text-success' : 'text-danger'}}">
                                                                    <i class="{{team.monthly.progress > 100 ? 'fa fa-long-arrow-up' : 'fa fa-long-arrow-down'}}"></i>
                                                                                        {{team.monthly.progress > 100 ? '+' +
                                                                                        (team.monthly.responses - team.monthly.target) :
                                                                                        (team.monthly.responses - team.monthly.target) + ' ' +
                                                                                        team.monthly.progress + '%'
                                                                                        }}
                                                                </span></h4>
                                                                                <center>
                                                                                    <i class="fa fa-long-arrow-up"></i><br>
                                                                                    <span style="color:#BAB8B8">Monthly</span>
                                                                                </center>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </center>
                                                            </li>
                                                            <!--                                                        <li style="margin-left: 50px" *ngFor="let question of team.questions">-->
                                                            <!--                                                            <center>-->
                                                            <!--                                                                <table>-->
                                                            <!--                                                                    <tr>-->
                                                            <!--                                                                        <td valign="top" style="border-right:  0px solid rgba(151, 151, 151, 0.3) !important; padding-right: 5px">-->
                                                            <!--                                                                            <center>-->
                                                            <!--                                                                                <h4>{{service.numberWithCommans(question.currency)}}</h4>-->
                                                            <!--                                                                                <i class="fa fa-long-arrow-up"></i><br>-->
                                                            <!--                                                                                <span style="color:#BAB8B8">{{question.label}}</span>-->
                                                            <!--                                                                            </center>-->
                                                            <!--                                                                        </td>-->
                                                            <!--                                                                    </tr>-->
                                                            <!--                                                                </table>-->
                                                            <!--                                                            </center>-->

                                                            <!--                                                            <center>-->
                                                            <!--                                                                <mat-divider></mat-divider>-->
                                                            <!--                                                                <table>-->
                                                            <!--                                                                    <tr>-->
                                                            <!--                                                                        <td valign="top" style="border-right:  1px solid rgba(151, 151, 151, 0.3) !important; padding-right: 5px">-->
                                                            <!--                                                                            <center>-->
                                                            <!--                                                                                <h4>{{service.numberWithCommans(question.weekly.currency)}}</h4>-->
                                                            <!--                                                                                <i class="fa fa-long-arrow-up"></i><br>-->
                                                            <!--                                                                                <span style="color:#BAB8B8">Weekly</span>-->
                                                            <!--                                                                            </center>-->
                                                            <!--                                                                        </td>-->
                                                            <!--                                                                        <td valign="top" style="border-right:  0px solid rgba(151, 151, 151, 0.3) !important; padding-left: 5px">-->
                                                            <!--                                                                            <h4>{{service.numberWithCommans(question.monthly.currency)}}</h4>-->
                                                            <!--                                                                            <center>-->
                                                            <!--                                                                                <i class="fa fa-long-arrow-up"></i><br>-->
                                                            <!--                                                                                <span style="color:#BAB8B8">Monthly</span>-->
                                                            <!--                                                                            </center>-->
                                                            <!--                                                                        </td>-->
                                                            <!--                                                                    </tr>-->
                                                            <!--                                                                </table>-->
                                                            <!--                                                            </center>-->
                                                            <!--                                                        </li>-->
                                                        </ul>
                                                    </div>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


