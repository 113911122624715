<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field class="example-full-width"
                                    appearance="fill">
                        <textarea  class="sawasdee26"
                                   placeholder="{{question.placeholder}}"
                                   autocomplete="off"
                                   matInput
                                   [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                                   [(ngModel)]="question.data"
                                   (input)="onInputTextChange($event)"
                                   (keydown)='validateTextLength($event)'
                                   [autofocus]="true"
                                   #element>
                        </textarea>
                    </mat-form-field>
                </div>
                <div class="row">
                    <center>
                        <app-next-button></app-next-button>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
