import { Injectable } from '@angular/core';
import {Parameters} from "../parameters";
import {AppService} from "../app.service";

@Injectable({
    providedIn: 'root'
})
export class WaypointService {

    constructor(private service: AppService) {
    }

    add(params: JSON, call: any){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(params);
        this.service.httpService('POST', this.service.app.routes.routes.waypoints.add,
            outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
            (waypoint: any) => {
                call(waypoint);
            }, (error: any) => {call(null);});
    }

    requestWaypointById(id: any, call: any){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes({});
        outgoingRequest.setId(id);
        this.service.httpService('POST', this.service.app.routes.routes.waypoints.requests.get,
            outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
            (waypoint: any) => {
                call(waypoint);
            }, (error: any) => {call(null);});
    }
}
