<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'red'}">
            <div class="panel-body">
                <div>
                    <h4 id="example-radio-group-label">{{question.label}}</h4>
                </div>
                <div>
                <div>
                    <fieldset>
                        <legend></legend>
                        <div class="col-lg-12 col-md-12" [ngStyle]="{'margin-top':'50px', 'margin-bottom':'50px','overflow':'scroll'}">
                            <table class="table table-striped" style="width:100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th *ngFor="let option of question.options">{{option.label}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Price</td>
                                        <td *ngFor="let option of question.options">
                                            <mat-form-field  class="example-full-width" appearance="outline">
                                                <input matInput
                                                       type="number"
                                                       style="text-align: center"
                                                       (input)="onChangeValue($event, option, 'price')"
                                                       [(ngModel)]="option.source.price" >
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Packt</td>
                                        <td *ngFor="let option of question.options">
                                            <mat-form-field  class="example-full-width" appearance="outline">
                                                <input
                                                        matInput
                                                        type="number"
                                                        style="text-align: center"
                                                        (input)="onChangeValue($event, option, 'packt')"
                                                        [(ngModel)]="option.source.packt">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Bundle</td>
                                        <td *ngFor="let option of question.options">
                                            <mat-form-field  class="example-full-width" appearance="outline">
                                                <input
                                                        matInput
                                                        type="number"
                                                        style="text-align: center"
                                                        (input)="onChangeValue($event, option, 'bundle')"
                                                        [(ngModel)]="option.source.bundle">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
