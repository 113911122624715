import { Component, OnInit } from '@angular/core';
import {FormService} from '../../forms/form.service';
import {ResponseService} from '../../forms/responses/data/response.service';
import {Parameters} from '../../parameters';
import * as moment from 'moment';
import {AppService} from '../../app.service';
import {BroadcastChannelService} from "../../broadcast-channel.service";

@Component({
  selector: 'app-dialog-reply',
  templateUrl: './dialog-reply.component.html',
  styleUrls: ['./dialog-reply.component.scss']
})
export class DialogReplyComponent implements OnInit {
  outgoingRequestParams: any;
  incomingRecipient: any;
  incomingRespondent: any;
  incomingResponse: any;
  response: any;
  subscription: any;
  constructor(
      private formMetthods: FormService,
      private service: AppService,
      private broadcastChannel: BroadcastChannelService,
      private responseMetthods: ResponseService) {
  }

  ngOnInit(): void {
    this.outgoingRequestParams = new Parameters();
    this.incomingRecipient = new Parameters();
    this.incomingRespondent = new Parameters();
    this.incomingResponse = new Parameters();
    this.outgoingRequestParams.setAttributes({});
    this.incomingRespondent.setAttributes(this.service.app.data.user);
    this.incomingRecipient.setAttributes(JSON.parse(JSON.stringify(this.responseMetthods.getSelected())).postedBy);
    this.incomingResponse.setAttributes(this.responseMetthods.getSelected());
    this.response = this.responseMetthods.getSelected();
  }

  reply(text: any){
    this.outgoingRequestParams.setAttributes({});
    this.outgoingRequestParams.setReply(text);
    this.outgoingRequestParams.setDate(moment(new Date()).format('MM/DD/YYYY'));
    this.outgoingRequestParams.setCntrlNum(this.service.random());
    this.outgoingRequestParams.setRespondent(this.incomingRespondent.getId());
    this.outgoingRequestParams.setRecipient(this.incomingRecipient.getId());
    this.outgoingRequestParams.setResponse(this.incomingResponse.getId());
    this.service.httpService('post', '/response-replies', this.outgoingRequestParams.getAttributes(), {}, (response: any) => {
    this.incomingResponse.attributes.replies.push(response);
    window.scrollTo(0, window.innerHeight);
    const url: any = '../assets/audio/never.mp3';
    const sound: any = new Audio(url);
    sound.play();
    // let elementany = document.getElementById('scrollElement');
    // element.scrollTo(0, 50000);
    }, (error: any) => {
    });
  }

}
