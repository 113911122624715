<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <mat-list>
                <mat-list-item>
                  <span class="" mat-line>
                    <app-label [question]="question"></app-label>
                  </span>
                </mat-list-item>
            </mat-list>
            <div style="margin-bottom: 50px">
                <center>
                    <ngx-mat-timepicker-field
                            color="warn"
                            [(ngModel)]="question.data"
                            [defaultTime]="'11:11 AM'">
                    </ngx-mat-timepicker-field>
                </center>
            </div>
            <div>
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>


<!--<ngx-mat-timepicker-field-->
<!--        color="warn"-->
<!--        [(ngModel)]="question.data"-->
<!--        [defaultTime]="'11:11 AM'">-->
<!--</ngx-mat-timepicker-field>-->
<!--<mat-form-field>-->
<!--    <input matInput-->
<!--           name="selected_time_A"-->
<!--           [(ngModel)]="question.data"-->
<!--           [ngxMatTimepicker]="pickerA"-->
<!--           placeholder="4:00 PM"-->
<!--           readonly >-->
<!--    <mat-icon matSuffix-->
<!--              (click)="pickerA.open()">-->
<!--        watch_later-->
<!--    </mat-icon>-->
<!--</mat-form-field>-->
<!--<ngx-mat-timepicker #pickerA></ngx-mat-timepicker>-->
