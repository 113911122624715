import { Component, OnInit } from '@angular/core';
import {CampaignService} from "../campaigns/campaign.service";
import {Router} from "@angular/router";
import {AppService} from "../app.service";
import {FormService} from "../forms/form.service";

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent implements OnInit {

  campaign: any;
  constructor(private router: Router,
              public service: AppService,
              private formService: FormService,
              public campaignService: CampaignService) {
  }

  ngOnInit(): void {
 // document.getElementsByTagName( 'body')[0].style.backgroundColor = '#888';
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
    document.title = 'Patten! Get data you need at your finguretips';
 // this.campaign = this.campaignService.getCampaignById(this.campaignService.campaignSelectedId);
  }


  onCellClicked(module: any){
    this.campaignService.categoryId = module.id;
    this.campaignService.flag = (module.name.toString().includes('campaign') ? 'Campaigns' : 'Projects');
    this.service.status = -1;
    this.formService.categoryId = module.id;
    this.service.module = 'Projects';
    this.router.navigateByUrl('/' + this.campaignService.flag.toString().toLowerCase() + '/search');
    setTimeout(() => {
        document.title = module.name.toString();
        this.service.header = this.campaignService.flag.toString();
        this.service.modules = [{title: this.campaignService.flag.toString(), path: ''}];
    });
    // this.formService.categoryId = module.id;
    // this.formService.status = -1;
    // this.router.navigateByUrl('/app/forms');
    // setTimeout(() =>{
    //   if(this.campaignService.hasUnkownCampaign()){
    //     this.formService.campaignId = this.campaignService.getCampaignByCntrlNum(this.service.app.settings.unknownCampaignCntrlNum);
    //   }else{
    //     this.campaignService.setUnknown((response: any) => {
    //       this.service.app.data.campaigns.data.push(response);
    //       this.formService.campaignId = response.id;
    //     });
    //   }
    //   // this.service.header = module.name.toString().toLowerCase() === 'surveys' ? 'Surveys' : this.service.header;
    //   this.service.header = module.name.toString();
    //   document.title = this.service.header;
    // });

  }

}
