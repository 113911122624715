import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {

    navchange: EventEmitter<any> = new EventEmitter();
    constructor() {}
    emitNavChangeEvent(numberr: any) {
        this.navchange.emit(numberr);
    }
    getNavChangeEmitter() {
        return this.navchange;
    }

}
