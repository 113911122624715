import { Component, OnInit } from '@angular/core';
import {TeamService} from '../../teams/team.service';
import {EmployeesService} from '../employees.service';
import {AppService} from '../../app.service';
@Component({
  selector: 'app-tabs-employees',
  templateUrl: './tabs-employees.component.html',
  styleUrls: ['./tabs-employees.component.scss']
})
export class TabsEmployeesComponent implements OnInit {

  employees: any;
  app: any;
  constructor(public service: AppService,
              private employeeService: EmployeesService) {
    this.app = service.app;
  }

  ngOnInit(): void {
    this.employees = this.app.data.employees;
  }

}
