<h1 mat-dialog-title><center>{{title}}</center></h1>
<div mat-dialog-content [ngStyle]="{'margin-bottom':'100px'}">
    <center><p>Do you real want to delete this record ?</p></center>
</div>
<div>
    <center>
        <a class="btn btn-success btn-md"   (click)="handleAccept()">Yes</a>
        <a class="btn btn-success btn-md"   [mat-dialog-close]="">No</a>
    </center>
</div>
