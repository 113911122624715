<div id="main">
        <div class="caption" [ngStyle]="{'background-color':'transparent'}">
            <div class="holder">
                <form>
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8">
                            <section class="post-section blog-post" [ngStyle]="{'padding':'0px','background-color':'transparent'}">
                                <div class="container">
                                    <div class="row">
                                        <h4 class="pull-right" [ngStyle]="{'padding':'15px'}"><a>Get your tickets for our next upcoming.. events !</a><br><br></h4>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <table>
                                            <tr>
                                                <td [vAlign]="'bottom'" [ngStyle]="{'padding':'0px','margin':'0px'}">
                                                    <img src="assets/images/361/361.png" class="pull-left" [ngStyle]="{'max-width':'100px','max-height':'150px','margin-left':'10px'}"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td [vAlign]="'top'" [ngStyle]="{'padding':'0px','margin':'0px'}">
                                                    <div class="col-md-4 col-sm-6 cursor"
                                                         *ngFor="let form of this.service.app.data.forms.data">
                                                        <div class="post-box">
                                                            <div class="text-box">
                                                                <h4><a>{{form.name}}</a></h4>
                                                                <p [innerHTML]="form.description | safeUrl"></p>
                                                                <p>Procurement of Goods under public financed projects is carried out in accordance with policies and procedures laid down in the Public Procurement Act No. 7 of 2011 (hereinafter called PPA 2011) and the Public Procurement Regulations– Government Notice No. 446 of 2013 (hereinafter called Public Procurement Regulations) as amended in 2016.

                                                                    This Standard Invitation for Quotation (SIFQ) for Procurement of Goods has been prepared by the Public Procurement Regulatory Authority (PPRA) in collaboration with Attorney General Chamber and other professional bodies for use by Procuring Entities (PEs) in the procurement of Goods.
                                                                </p>
                                                                <p>
                                                                    <img src="{{logo.path}}"
                                                                         class="pull-left mdl-shadow--2dp"
                                                                         *ngFor="let logo of form.settings.logo"
                                                                         [ngStyle]="{'width':'30px', 'height':'30px', 'margin-left': '10px'}"
                                                                         alt="{{logo.caption}}">
                                                                </p>
                                                            </div>
                                                            <div class="frame"  style="margin-left: 10px; margin-right: 10px">
                                                            </div>
                                                        </div>
                                                        <center>
                                                            <a class="btn-style-2">Create Survey Form</a>
                                                        </center>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-2">
                        </div>
                    </div>
                </form>
            </div>
        </div>
</div>

