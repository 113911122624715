<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <mat-form-field  class="example-full-width">
                    <input matInput type="text" [(ngModel)]="question.label" placeholder="{{question.label.length === 0 ? 'Untitled Question' : ''}}">
                    <span *ngFor="let control of controls; let controlOf = index" class="pull-right">
                  <i class="material-icons pull-left" [ngStyle]="{'color':'#888','cursor':'pointer'}" *ngIf="control.id === question.control.id">{{control.icon}}</i>
                </span>
                </mat-form-field>
            </div>
            <div>
                <mat-chip-list #chipList aria-label="selection">
                    <mat-chip
                            *ngFor="let option of options"
                            [selectable]="selectable"
                            [removable]="removable"
                            (click)="handleOption(option)"
                            (removed)="remove(option)">
                        {{option.label}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input
                            placeholder="New option..."
                            #optionalInput
                            [formControl]="questionCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.label}}
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="col-lg-12 col-md-12" [ngStyle]="{'margin-top':'50px', 'margin-bottom':'50px','overflow':'scroll'}">
                <table class="table table-striped" style="width:100%">
                    <thead>
                      <tr>
                          <th></th>
                          <th *ngFor="let option of question.options"><center>{{option.label}}</center></th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Price</td>
                            <td *ngFor="let option of question.options">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input matInput type="number" style="text-align: center" [(ngModel)]="option.source.price">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Packt</td>
                            <td *ngFor="let option of question.options">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input matInput type="number" style="text-align: center" [(ngModel)]="option.source.packt">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Bundle</td>
                            <td *ngFor="let option of question.options">
                                <mat-form-field  class="example-full-width" appearance="outline">
                                    <input matInput type="number" style="text-align: center" [(ngModel)]="option.source.bundle">
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
