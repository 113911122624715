import {Component, Input, OnInit} from '@angular/core';
import {OutletService} from "../../outlet.service";
import {AppService} from "../../../app.service";
import {Parameters} from "../../../parameters";

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {
  @Input() outlet: any;
  incomingOutlet: any;
  constructor(private outletService: OutletService,
              private service: AppService) {
    this.incomingOutlet = new Parameters();
    this.incomingOutlet.setAttributes(this.outlet);
  }

  ngOnInit(): void {
  }

}
