import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {DialogTeamsComponent} from '../../teams/dialog/dialog-teams.component';
import {ItemService} from '../../items/item.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {TeamService} from '../../teams/team.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {RoleService} from '../../roles/role.service';
import {CampanyService} from '../../campanies/campany.service';
import {UserService} from '../../users/user.service';
import {ClientService} from '../../clients/client.service';

@Component({
  selector: 'app-dialog-item',
  templateUrl: './dialog-item.component.html',
  styleUrls: ['./dialog-item.component.scss']
})
export class DialogItemComponent implements OnInit {
  @ViewChild('address')  addressElement: any;
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  userControl = new FormControl();
  filteredTeamsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedItem: any;
  keys: any;
  selectedAmbassador: any;
  addressValue: any;
  photo: any;
  filteredUsersOptions: any;
  users: any;
  user: any;
  submitFormValues: any;
  incomingTeam: any;
  teams: any = [];
  filteredRoles: any;
  roleControl = new FormControl();
  incomingRole = new Parameters();
  incomingCampaign: any;
  incomingCampany: any;
  incomingClient: any;
  constructor(public  service: AppService,
              private notifier: NotifierService,
              public  formBuilder: FormBuilder,
              private dialog: MatDialog,
              private userService: UserService,
              private campaignService: CampaignService,
              private campanyService: CampanyService,
              private clientService: ClientService,
              private teamService: TeamService,
              private roleService: RoleService,
              public broadcastChannel: BroadcastChannelService,
              public ambassadorService: ItemService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogItemComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.addressElement = ElementRef;
    this.parameters = new Parameters();
    this.submitFormValues = new Parameters();
    this.incomingTeam = new Parameters();
    this.incomingRole = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedItem = new Parameters();
    this.incomingSelectedItem.setAttributes(ambassadorService.selected);
    this.keys = Object.keys(this.incomingSelectedItem.getAttributes()).length;
    this.selectedAmbassador = ambassadorService.selected;
    this.users = [];
    this.teams =  this.service.app.data.teams.data;
  }

  onUserNameChanged(event: any){
    this.formValues.controls['name'].setValue(event.target.value);
    // setTimeout(() => { this.userService.search({searchText: event.target.value, role_id: 8}); }, this.timeout());
    this.formValues.removeControl('user_id');
  }

  getTeam(team: any){
    this.incomingTeam.setAttributes(team);
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingTeam.getCampaignId()));
    this.incomingCampany.setAttributes(this.campanyService.getCampanyById(this.incomingCampaign.getCampanyId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingCampaign.setClient(this.incomingClient.getAttributes());
    this.incomingCampaign.setCampany(this.incomingCampany.getAttributes());
    this.incomingTeam.setCampaign(this.incomingCampaign.getAttributes());
    return this.incomingTeam.getAttributes();
  }


  setUsers(){
    this.app.data.ambassadors.data.map((ambassador: any) => {
      this.users.unshift(ambassador);
    });
  }
  timeout(){return parseFloat('900');}
  ngOnInit(): void {
    this.setUsers();
    this.dialogRef.afterClosed().subscribe(result => {this.ambassadorService.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:          ['', Validators.compose([])],
      description:   ['', Validators.compose([])],
      avatar:        ['', Validators.compose([])],
      item_type_id:  ['1', Validators.compose([])],
      selling_price: ['0', Validators.compose([])],
      cntrl_num:     [this.service.random(), Validators.compose([])],
    });
    if(this.incomingSelectedItem.getAttributes().hasOwnProperty('id')){
      for(var key in this.incomingSelectedItem.getAttributes()) {
        if(this.incomingSelectedItem.attributes[key] instanceof Object || this.incomingSelectedItem.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedItem.attributes[key]);
          }else {
            if(this.incomingSelectedItem.attributes[key] instanceof Object || this.incomingSelectedItem.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingSelectedItem.attributes[key], Validators.compose([])));
            }
          }
        }
      }
      this.photo = this.formValues.controls['avatar'].value;
      this.formValues.controls['item_type_id'].setValue(1);
    }



    this.filteredRoles = this.roleControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(name  => name ? this._filterRole(name) : this.roleService.getRoles().slice())
    );

    this.filteredUsersOptions = this.userControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterUser(name) : this.service.app.data.users.data.slice())
    );

    this.filteredTeamsOptions = this.teamControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterTeam(name) : this.service.app.data.items.types.slice())
    );

    setTimeout(() => {

    });
  }

  public handleAddressChange(address: Address) {
  }


  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }

  private _filterRole(label: string) {
    const args = label.toString().toLowerCase();
    return this.roleService.getRoles().filter((role: any) => JSON.stringify(role).toString().toLowerCase().includes(args));
  }

  getTeamByAmbassador(ambassador: any){
    let team: any = this.teams.filter((team: any) => { return team.id === ambassador.team_id;})[0];
    return team;
  }

  addTeam(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
  }


  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){}

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['avatar'].setValue(this.service.dataURItoBlob(reader.result));
      this.photo = reader.result;
    };
  }



  private _filterTeam(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.items.types.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
    this.teamService.teamSelectedId = undefined;
  }

  onAddressTextChange(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
  }

  private _filterUser(name: string) {
    const filterValue = name.toLowerCase();
    return this.users.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }


  onTeamSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['team_id'].setValue(event.option.value.id);
  }

  onUserSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.user = this.users.filter((user: any) => {return user.id === event.option.value.id; })[0];
    for(var key in this.user) {
      if(this.user[key] instanceof Object || this.user[key] instanceof Array){} else{
        if(key.includes('id')){
          this.formValues.addControl('user_id', new FormControl(event.option.value.id, Validators.compose([])));
        }else if(this.formValues.contains(key)) {
          this.formValues.controls[key].setValue(this.user[key]);
        } else this.formValues.addControl(key, new FormControl(this.user[key], Validators.compose([])));
      }
    }

  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(ambassadorForm: any) {
    this.submitFormValues.setAttributes(ambassadorForm);
    this.service.httpService(
      'post', this.incomingSelectedItem.getAttributes().hasOwnProperty('id') ?
        this.service.app.routes.items.update.toString() :
        this.service.app.routes.items.add,
      ambassadorForm, {},
      (response: any) => {
        if(!this.incomingSelectedItem.getAttributes().hasOwnProperty('id')) {
          this.service.app.data.items.data.unshift(response);
        } else {
          this.incomingSelectedItem.attributes = Object.assign(this.incomingSelectedItem.attributes, response);
        }
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (!this.incomingSelectedItem.getAttributes().hasOwnProperty('id') ? 'added' : 'updated' ));
        this.incomingSelectedItem.setAttributes({});
        this.broadcastChannel.emitNavChangeEvent({item : response});
      }, (error: any) => {
      });
  }

}
