<a class="pull-left"><h4>{{keys === 0 ? 'Personnel ' : incomingRouteSelected.attributes.name}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Name *</mat-label>
                <input matInput type="text"  formControlName="name" [autocomplete]="''">
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Stroke color </mat-label>
                <input matInput type="text"  formControlName="stroke_color">
              </mat-form-field>
            </span>
        </mat-list-item>
    </mat-list>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <center>
                <div class="example-button-row">
                    <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Done'}}"/>
                </div>
            </center>
        </div>
    </div>
</form>
