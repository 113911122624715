<center>
    <table>
        <td>
            <img src="{{incomingCampany.getLogo()}}"
                 class="img-circle profile_img"
                 [ngStyle]="{'width':'80px', 'height':'80px'}"/>
        </td>
        <td>
            <img src="{{incomingClient.getLogo()}}"
                 class="img-circle profile_img"
                 [ngStyle]="{'width':'80px', 'height':'80px'}"/>
        </td>
    </table>
</center>
<center>
    <div id="pdfTable" #pdfTable style="color: #0f0f0f !important; font-family: 'Google Sans' !important;">
        <div><center>{{incomingUser.getName().toString().toUpperCase()}}</center></div><br><br>
        <table>
            <thead>
            <tr style="" *ngIf="service.responses.days.length > 0">
                <ng-container *ngFor="let day of [service.responses.days[0]]">
                    <ng-container *ngFor="let key of object.keys(day); let incremental = index">
                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}"
                            *ngIf="key === 'date'">{{key.toString().toUpperCase()}}</td>
                        <td *ngIf="key === 'date'"
                            [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                            {{('outlets visited').toString().toUpperCase()}}
                        </td>
                        <td *ngIf="key === 'date'"
                            [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                            {{('outlets that sell').toString().toUpperCase()}}
                        </td>
                        <ng-container *ngIf="key === 'questions'">
                            <td *ngFor="let suggestion of day[key]"
                                [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                                {{suggestion.label}}
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let day of service.responses.days">
                <ng-container *ngFor="let key of object.keys(day); let incremental = index">
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                        *ngIf="key === 'date'">{{day[key]}}
                    </td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                        *ngIf="key === 'date'">
                        {{day.outlets}}
                    </td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                        *ngIf="key === 'date'">
                        {{day.outlets_that_sell}}
                    </td>
                    <ng-container *ngIf="key === 'questions'">
                        <td *ngFor="let suggestion of day[key]; let incremental = index"
                            [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': (incremental===(day[key].length -1) ? '' : '1px solid #5f6368')}">
                            {{suggestion.sum}}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr style="" *ngIf="service.responses.days.length > 0">
                <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}"></td>
                <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}">
                    {{service.responses.outlets}}
                </td>
                <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}">
                    {{service.responses.outlets_that_sell}}
                </td>
                <ng-container>
                    <td *ngFor="let question of service.responses.questions"
                        [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}">
                        {{question.sum}}
                    </td>
                </ng-container>
            </tr>
            </tbody>

        </table>
    </div>

<!--    <div id="pdfTable" #pdfTable style="color: #0f0f0f !important; font-family: 'Google Sans' !important;">-->
<!--        <table>-->
<!--            <thead>-->
<!--                <tr style="" *ngIf="service.responses.days.length > 0">-->
<!--                    <ng-container *ngFor="let response of [service.responses.days[0]]">-->
<!--                        <ng-container *ngFor="let key of object.keys(response)">-->
<!--                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}"-->
<!--                                *ngIf="key !== 'questions'">{{key.toString().toUpperCase()}}</td>-->
<!--                            <ng-container *ngIf="key === 'questions'">-->
<!--                                <td *ngFor="let suggestion of response[key]"-->
<!--                                    [ngStyle]="{'border-bottom': '1px solid #5f6368'}">-->
<!--                                    {{suggestion.label}}-->
<!--                                </td>-->
<!--                            </ng-container>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--                <tr *ngFor="let response of service.responses.days">-->
<!--                    <ng-container *ngFor="let key of object.keys(response)">-->
<!--                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"-->
<!--                            *ngIf="key !== 'questions'">{{response[key]}}-->
<!--                        </td>-->
<!--                        <ng-container *ngIf="key === 'questions'">-->
<!--                            <td *ngFor="let suggestion of response[key]; let incremental = index"-->
<!--                                [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': (incremental===(response[key].length -1) ? '' : '1px solid #5f6368')}">-->
<!--                                {{suggestion.data}}-->
<!--                            </td>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                </tr>-->
<!--                <tr style="" *ngIf="service.responses.days.length > 0">-->
<!--                    <ng-container *ngFor="let response of [service.responses.days[0]]">-->
<!--                        <ng-container *ngFor="let key of object.keys(response)">-->
<!--                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}"-->
<!--                                *ngIf="key !== 'questions'"></td>-->
<!--                            <ng-container *ngIf="key === 'questions'">-->
<!--                                <td *ngFor="let question of service.responses.questions"-->
<!--                                    [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}">-->
<!--                                    {{question.sum}}-->
<!--                                </td>-->
<!--                            </ng-container>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                </tr>-->
<!--            </tbody>-->

<!--        </table>-->
<!--    </div>-->
</center>
