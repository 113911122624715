import { Component, OnInit, OnDestroy } from '@angular/core';
import {FormService} from '../../forms/form.service';
import {Parameters} from '../../parameters';
import {ActivatedRoute} from '@angular/router';
import {AppService} from '../../app.service';
import {CampaignService} from '../../campaigns/campaign.service';

@Component({
  selector: 'app-routes-invitee',
  templateUrl: './routes-invitee.component.html',
  styleUrls: ['./routes-invitee.component.scss']
})
export class RoutesInviteeComponent implements OnInit, OnDestroy {

  incomingFom: any;
  incomingCampaign: any;
  map: any;
  mapClickListener: any;
  constructor(private formService: FormService,
              private campaignService: CampaignService,
              public service: AppService,
              private activatedRoute: ActivatedRoute) {
    this.incomingFom = new Parameters();
    this.incomingFom.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
  }

  ngOnInit(): void {
  }

  getHeight(){ return {height: window.innerHeight}; }

  mapReadyHandler(map: any){
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
        alert(e.latLng.lng());
    });
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

}
