<div id="main">
    <!--<highcharts-chart-->
            <!--[Highcharts]="Highcharts"-->
            <!--[options]="chartOptions"-->
            <!--[(update)]="updateFlag"-->
    <!--&gt;-->
    <!--</highcharts-chart>-->

<!--    <jqxChart #myChart-->
<!--              [width]="getWidth()"-->
<!--              [height]="500"-->
<!--              [title]="'Mobile browsers share'"-->
<!--              [description]="'(source: wikipedia.org)'"-->
<!--              [showLegend]="true"-->
<!--              [enableAnimations]="true"-->
<!--              [padding]="pa.padding"-->
<!--              [titlePadding]="pa.titlePadding"-->
<!--              [source]="pa.dataAdapter"-->
<!--              [showBorderLine]="true"-->
<!--              [seriesGroups]="pa.seriesGroups"-->
<!--              [colorScheme]="'scheme03'"-->
<!--              [legendLayout]="pa.legendLayout">-->
<!--    </jqxChart>-->
</div>
