import {Component, OnInit, AfterViewInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AppService} from './app.service';
import {Parameters} from './parameters';
import {CampaignService} from './campaigns/campaign.service';
import {OutletService} from './outlets/outlet.service';
import {TeamService} from './teams/team.service';
import {AmbassadorsService} from './ambassadors/ambassadors.service';
import {FormService} from './forms/form.service';
import {EmployeesService} from './employees/employees.service';
import {MethodsInviteFormsService} from './forms/invitees/methods-invite-forms.service';
import * as CryptoJS from 'crypto-js';
import {MatDialog} from '@angular/material/dialog';
import {StorageMap } from '@ngx-pwa/local-storage';
import {RegionsService} from "./regionals/regions.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
    title = 'Pattern';
    appsettingsUrl = '../assets/json/app.json';
    routesUrl = '../assets/json/routes.json';
    stringsUrl = '../assets/json/strings.json';
    hostUrl = '../assets/json/hosts.json';
    app: any = {settings: null};
    outgoingRequest: any = {};
    formId: any;
    publicFormUrl = '/public/forms/';
    loginForm: any;
    form: any = {};
    modules: any = [];
    incomingUser: any;
    incomingForm: any;
    splits: any;
    appRootImg: any;
    campaignId: any = 0;
    strings: any;
    constructor(
                private router: Router,
                public campaignService: CampaignService,
                public outletService: OutletService,
                public teamService: TeamService,
                public dialog: MatDialog,
                private regionService: RegionsService,
                public ambassadorService: AmbassadorsService,
                public formService: FormService,
                public inviteeService: MethodsInviteFormsService,
                public employeesService: EmployeesService,
                public service: AppService,
                private storage: StorageMap) {
        this.incomingUser = new Parameters();
        this.incomingForm = new Parameters();
        this.strings = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingUser.setAttributes({});
        this.incomingForm.setAttributes({});
        this.service.setNavigatedUrl(window.location.href);
        this.splits = this.service.getNavigatedUrl().toString().split('?');
        this.appRootImg = document.getElementById( 'appRootImg');
    }
    ngOnInit(): void {
      this.service.setLogo(null);
      this.routesUrl = this.routesUrl + '?version=' + this.service.random();
      this.appsettingsUrl = this.appsettingsUrl + '?version=' + this.service.random();
      this.hostUrl = this.hostUrl + '?version=' + this.service.random();
      this.stringsUrl = this.stringsUrl + '?version=' + this.service.random();
      this.router.events.subscribe((event: any) => {
        document.title  = '';
        if(event instanceof NavigationStart){}
        if(event instanceof NavigationEnd){
            setTimeout(() => {
                window.scrollTo(0, 0);
                document.getElementsByTagName( 'body')[0].scrollTo(0, 0);
            });
        }
        if(event instanceof NavigationError){}
      });

      this.router.navigateByUrl('/wait..');
//    setInterval(() => {this.storage.set('data', this.service.app).subscribe(() => {});}, 20017);
      window.localStorage.setItem('routeName', 'null');
      window.localStorage.setItem('formId'   , 'null');
      window.scrollTo(0, 0);
      this.service.httpService( 'get', this.appsettingsUrl, {},
          {ignoreLoadingBar : 'true', notify: 'false'},
            (response: any) => {
               this.app.settings = response;
               this.service.app = Object.assign(this.service.app, {settings: response});
               this.service.httpService( 'get', this.hostUrl, {}, {ignoreLoadingBar: 'true'},(hosts: any) => {
                   hosts.map((host) => {
                       if(this.service.getNavigatedUrl().includes(host.name) && this.service.isNull(this.service.logo)){
                           document.title = host.company;
                           this.service.logo = host.logo;
                           this.service.host = host;
                       }
                   });
               },(error: any) => {});
               this.service.httpService( 'get', this.routesUrl, {}, {ignoreLoadingBar: 'true'},(routes: any) => {
                this.service.app = Object.assign(this.service.app, {routes: routes});
                   this.service.httpService( 'get', this.stringsUrl, {}, {ignoreLoadingBar: 'true'},(strings: any) => {
                       this.service.app = Object.assign(this.service.app, {strings: strings});
                       this.strings.setAttributes(this.service.app.strings.params);
                       this.service.setNavigatedUrl(this.service.getNavigatedUrl().replaceAll(this.strings.getEqualSlashConfirmation(), this.strings.getSlashConfirmation()));
                       this.service.setNavigatedUrl(this.service.getNavigatedUrl().replaceAll(this.strings.getEqualSlashChatbot(), this.strings.getSlashChatbot()));
                       this.main();
                   },(error: any) => {
                   });
               },  (error: any) => {
               });
            },  (error: any) => {
            });
       //if(this.service.app.hasOwnProperty('data')) delete this.service.app.data;
      //console.log(this.activatedRoute.snapshot.data.routeName);
      //console.log(this.router.routerState.snapshot.data);
      this.router.events.subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            // this.activatedRoute.data.subscribe(data => alert(data.routeName));
          }
        }
      );
    }

    ngAfterViewInit(){
       var pass: any  = null;
       if(this.splits.length !== 1){
            this.splits = this.splits[1].toString().split('&');
            this.splits.map((split: any) => {
             if(split.toString().toLowerCase().includes('extension=')){
               document.getElementsByTagName( 'body')[0].style.backgroundColor = split.toString().split('=')[1];
               pass = undefined;
             }else if(split.toString().toLowerCase().includes('access=')){
               this.service.logo = 'logo';
               pass = undefined;
               if(!this.service.app.hasOwnProperty('settings')){
                  this.service.logo = this.service.clientImgUrl() + split.toString().split('=')[1];
                  this.getSettings((result: any) => { this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];});
               }else this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];

             }
            });
       }
       if(pass = null){
         document.getElementsByTagName( 'body')[0].style.backgroundColor = '#04acec';
         this.service.ngxLoadingBarColor = '#fff';
         this.service.logo = this.service.getClientImgPart()  + '642ac4fe6f7a719';
       }
    }

    getSettings(callback: any){
             this.service.httpService( 'get', this.appsettingsUrl, {}, {ignoreLoadingBar: 'true'},
              (response: any) => {
                  this.service.app = Object.assign(this.service.app, {settings: response});
                  callback(null);
              },  (error: any) => {
              });
    }

    hasToken(){
        return !(localStorage.getItem(this.app.settings.tokenName) === null ||
            this.service.isNull(window.localStorage.getItem(this.app.settings.tokenName)));
    }

    getFormsByCampaignId(campaignId: any, callback: any) {
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setCampaignId(this.campaignId);
        this.service.httpService('post', this.service.app.routes.campaigns.forms.app.get,
            this.outgoingRequest.getAttributes(),
            {ignoreLoadingBar: 'true', notify: false},
            (result: any) => {
                callback(result);
            },  (error: any) => { });
    }

    main(){
        this.service.setNavigatedUrl(this.service.getNavigatedUrl().replaceAll(this.strings.getEqualSlash(), this.strings.getSlash()));
        if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getInvitations()) &&
            this.service.getNavigatedUrl().toString().includes('/qrcode') &&
            this.service.getNavigatedUrl().toString().includes('scanning')){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        } else if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getForms()) &&
            this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getChatbot())){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        }else if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getEvents()) &&
            this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getRaffles())){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        }else if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getEvents()) &&
            this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getAnnoucement())){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        }else if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getInvitations()) &&
            this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getChatbot())){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        } else if(this.service.getNavigatedUrl().toString().includes('/invitations') &&
            this.service.getNavigatedUrl().toString().includes('/confirmation')){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        }else if(this.service.getNavigatedUrl().toString().includes('/customers') &&
            this.service.getNavigatedUrl().toString().includes('/orders')){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        } else if(((this.service.getNavigatedUrl().toString().includes('suggestions') ||
            this.service.getNavigatedUrl().toString().includes('/register') ||
                this.service.getNavigatedUrl().toString().includes('/feedback')) &&
            !(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getInvitees())) &&
            (this.service.getNavigatedUrl().toString().includes('/forms') || this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getEvents()+this.strings.getSlash())))){
                document.getElementsByTagName( 'body')[0].style.color = '#000';
                this.publicFormUrl = this.publicFormUrl + window.localStorage.getItem('formId');
                this.loginForm = new Parameters();
                this.loginForm.setAttributes({});
                this.loginForm.setPhone('');
                this.loginForm.setPassword('admin');
                this.service.httpService( 'get', this.appsettingsUrl, {}, {ignoreLoadingBar : 'true', notify: 'false'},
                    (response: any) => {
                        this.app.settings = response;
                        this.service.app = Object.assign(this.service.app, {settings: response});
                        this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
                    },  (error: any) => {
                    });

        } else if(this.service.getNavigatedUrl().toString().includes('forms/createx')){
            this.service.httpService( 'get', this.appsettingsUrl, {}, {ignoreLoadingBar: 'true'},
                (response: any) => {
                    this.app.settings = response;
                    this.service.app = Object.assign(this.service.app, {settings: response});
                    this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
                },  (error: any) => {
                });
        } else if(this.campaignId !== 0){
            this.getFormsByCampaignId(this.campaignId,(forms: any)=>{
                this.service.app = Object.assign(this.service.app, {data: {}});
                this.service.app.data = Object.assign(this.service.app.data, {forms: {data: forms}});
                this.router.navigateByUrl(this.service.app.routes.app.register.home);
            });
        }else if(this.service.getNavigatedUrl().toString().includes(this.strings.getEvents()+this.strings.getSlash()) &&
            !(this.service.getNavigatedUrl().toString().includes(this.strings.getInvitees() + this.strings.getSlash()))){
            this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        } else {
            this.service.httpService( 'get', this.appsettingsUrl, {}, {ignoreLoadingBar: 'true'},
                (response: any) => {
                    this.app.settings = response;
                    this.service.app = Object.assign(this.service.app, {settings: response});
                    if (localStorage.getItem(this.app.settings.tokenName) === null ||
                        this.service.isNull(localStorage.getItem(this.app.settings.tokenName))) {
                        if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getOrders()) &&
                            this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getShipment())){
                            this.loginByAdminCredentials();
                        }else this.router.navigateByUrl(this.service.app.routes.app.login);
                    } else{
                        this.service.setToken('' + localStorage.getItem(this.app.settings.tokenName));
                        this.service.authenticateUser((result: any) => {
                            if(Object.keys(result).length === 0){
                                if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getOrders()) &&
                                    this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getShipment())){
                                    this.loginByAdminCredentials();
                                }else this.router.navigateByUrl(this.service.app.routes.app.login);
                            }else{
                                this.service.app = Object.assign(this.service.app, {data: result});
                                this.service.app.data = Object.assign(this.service.app.data, {suggestions: []});
                                this.service.app.data = Object.assign(this.service.app.data, {clients: {data: [], trashed:{data: []}}});
                                this.service.app.data = Object.assign(this.service.app.data, {companies: {data: []}});
                                this.service.app.data = Object.assign(this.service.app.data, {campaigns: {data: []}});
                                this.service.app.data = Object.assign(this.service.app.data, {forms: {data: [], status: 0}});
                                this.load();
                            }
                        });
                    }
                },  (error: any) => {
                    if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getOrders()) &&
                        this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+ this.strings.getShipment())){
                        this.loginByAdminCredentials();
                    }else this.router.navigateByUrl(this.service.app.routes.app.login);

                });
        }
    }

    loginByAdminCredentials(){
        // this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
        let ougoingRequest: any = new Parameters();
        ougoingRequest.setAttributes({});
        ougoingRequest.setEmail('admin@mashindano.uza');
        ougoingRequest.setPassword('admin');
        this.service.httpService(
            'post',
            this.app.settings.routes.authenticate,
            ougoingRequest.getAttributes(), {},
            (response: any) => {
                localStorage.setItem(JSON.parse(JSON.stringify(this.service.app)).settings.tokenName, response.token);
                this.service.setToken(response.token);
                this.service.httpService( 'get', this.appsettingsUrl, {}, {},
                    (result: any) => {
                        this.app.settings = result;
                        this.service.app = Object.assign(this.service.app, {settings: result});
                        if(this.service.app.hasOwnProperty('data')) delete this.service.app.data;
                        this.service.setNavigatedUrl(window.location.href);
                        window.localStorage.setItem('routeName',  'null');
                        window.localStorage.setItem('formId'   ,  'null');
                        this.main();
                    },  (error: any) => {
                    });
            }, (error: any) =>  {
            });
    }


    load(){
        this.incomingUser.setAttributes(this.service.app.data.user);
        // if(this.service.app.data.user.role.id === 8){
        //     let bytes: any = CryptoJS.AES.encrypt(this.incomingUser.getId().toString(), this.app.settings.encryptKey);
        //     this.router.navigateByUrl('/app/ambassadors/' +
        //         encodeURIComponent(bytes.toString()) +
        //         '/forms');return;
        // }
        this.set();
        if(this.service.app.data.campaigns.data.length === 0){
            // this.router.navigateByUrl('/app/projects/create');
            if(this.service.getNavigatedUrl().toString().includes(this.strings.getSlash() + this.strings.getOrders()) &&
                this.service.getNavigatedUrl().toString().includes(this.strings.getSlash()+this.strings.getShipment())){
                this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
            }else this.router.navigateByUrl(this.service.app.routes.app.forms.list);
        }else if(this.service.app.data.campaigns.data.length === 1){
            this.campaignService.campaignSelectedId = this.service.app.data.campaigns.data[0].id;
            if(this.service.app.data.forms.data.length === 1){
                    this.formService.formSelectedId = this.service.app.data.forms.data[0].id;
                    if(parseFloat(this.service.app.data.user.role.id) === 2){
                      this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard3');
                    }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard');
            }else this.router.navigateByUrl('/app/forms/list');
        }else{
            this.campaignService.campaignSelectedId = this.service.app.data.campaigns.data[0].id;
            this.router.navigateByUrl('/app/forms/list');
        }
    }

    set(){
        this.service.app.data = Object.assign(this.service.app.data, {users: {data: [], trashed: {data: []}}});
        this.service.app.data = Object.assign(this.service.app.data, {campaigns: this.campaignService.getCampaigns()});
        this.service.app.data = Object.assign(this.service.app.data, {teams: this.teamService.getTeamsByCampaigns(this.service.app.data.campaigns)});
        this.service.app.data = Object.assign(this.service.app.data, {ambassadors: this.ambassadorService.getAmbassadorsByTeams(this.service.app.data.teams)});
     // this.service.app.data = Object.assign(this.service.app.data, {forms: this.formService.getFormsByCampaigns(this.service.app.data.campaigns)});
        this.service.app.data = Object.assign(this.service.app.data, {invitees: this.inviteeService.getInviteesByForms(this.service.app.data.forms)});
        this.service.app.data = Object.assign(this.service.app.data, {responses: {data: [], trashed: {data: []}}});
        this.service.app.data = Object.assign(this.service.app.data, {employees: !this.service.app.data.hasOwnProperty('campanies') ? {data: [], trashed: {data: []}} : this.employeesService.getEmployeesByCampanies(this.service.app.data.campanies)});
        this.service.app.data = Object.assign(this.service.app.data, {brunches: {data: []}});
        this.service.app.data = Object.assign(this.service.app.data, {distributors: {data: [], trashed: {data:[]}}});
        this.service.app.data = Object.assign(this.service.app.data, {items: {data: [],types: []}});
        this.service.app.data = Object.assign(this.service.app.data, {inventories: {data: []}});
        this.service.app.data = Object.assign(this.service.app.data, {suggestions: []});
        this.service.app.data = Object.assign(this.service.app.data, {outlets: {data: [], types: []}});
        this.service.app.data = Object.assign(this.service.app.data, {routes: {data: [], trashed: {data:[]}}});
        this.service.app.data = Object.assign(this.service.app.data, {members: {data: [], trashed: {data:[]}}});
        this.service.app.data = Object.assign(this.service.app.data, {campanies: {data: [], trashed: {data:[]}}});
        this.regionService.requestRegionsByCountryId(1);
        this.outletService.requestOutletCategories();
        this.service.app.settings.modules.map((module: any) => {
            if(this.service.app.data.user.role.id === 2){ this.clientadmin(module);
            }else if(this.service.app.data.user.role.id === 15){
              // this.campanyadmin(module);
            }else if(this.service.app.data.user.role.id === 18){
              this.generalManager(module);
            }
            else this.modules.push(module);
        });
        this.service.app.settings.modules = this.modules;
    }



    campanyadmin(module: any){
        this.service.app.settings.client.name = this.service.app.data.campanies.data[0].name;
        this.service.app.settings.logo = this.service.app.data.campanies.data[0].logo;
        if(module.title.toLowerCase().toString().includes('logs') ||
            module.title.toLowerCase().toString().includes('settings') ||
            module.title.toLowerCase().toString().includes('accounts') ||
            module.title.toLowerCase().toString().includes('campanies')){} else {
            this.modules.push(module);
        }
    }

    generalManager(module: any){
        this.service.app.settings.client.name = this.service.app.data.campanies.data[0].name;
        this.service.app.settings.logo = this.service.app.data.campanies.data[0].logo;
        if(module.title.toLowerCase().toString().includes('logs') ||
            module.title.toLowerCase().toString().includes('settings')  ||
            module.title.toLowerCase().toString().includes('teams')  ||
            module.title.toLowerCase().toString().includes('accounts')  ||
            module.title.toLowerCase().toString().includes('campanies')){} else {
            this.modules.push(module);
        }
    }

    isUrlSuggestions(){
        return (this.service.getNavigatedUrl().toString().includes('forms') &&  this.service.getNavigatedUrl().toString().includes('navigations')) ? true : false;
    }


    clientadmin(module: any){
        this.service.app.settings.client.name = this.service.app.data.campanies.data[0].name;
        this.service.app.settings.logo = this.service.app.data.campanies.data[0].logo;
        if(module.title.toLowerCase().toString().includes('employees') ||
            module.title.toLowerCase().toString().includes('clients')  ||
            module.title.toLowerCase().toString().includes('logs')     ||
            module.title.toLowerCase().toString().includes('settings') ||
            module.title.toLowerCase().toString().includes('campanies')){} else {
            this.modules.push(module);
        }
      }




}
