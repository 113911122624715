import {Component, OnDestroy, OnInit} from '@angular/core';
import {CampaignService} from '../campaign.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Router} from '@angular/router';
import {AppComponent} from '../../app.component';
import {AppService} from '../../app.service';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  subscription: any;
  constructor(private campaignService: CampaignService,
              private router: Router,
              private appService: AppService,
              private appComponent: AppComponent,
              private broadcastChannel: BroadcastChannelService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
  }

  ngOnInit(): void {
    this.appService.checkNetworkStatus((status: any) => {});
    this.campaignService.setSelected({});
    this.campaignService.openDialog({});
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if(item.hasOwnProperty('campaign')){
              this.appComponent.load();
            }
          }

        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
