import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {ClientService} from './client.service';

@Component({
  selector: 'app-tabs-clients',
  templateUrl: './tabs-clients.component.html',
  styleUrls: ['./tabs-clients.component.scss']
})
export class TabsClientsComponent implements OnInit {

  active: any;
  clients: any;
  constructor(
    public  metthods: ClientService,
    public service: AppService) {
    this.active = 1;
    this.clients = metthods.getData();
  }

  ngOnInit(): void {
  }


}
