<div layout="row">
    <div class="panel panel-default btext" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div class="row">
                <a mat-flat-button  class="defaultColor pull-right" (click)="addOptional()"><small>Add Image</small></a>
                <div>
                    <div class="defaultColor" style="margin-left: 40px; margin-right: 40px; background-color: #F7F7F7 !important;">
                        <div>
                            <section class="testimonials" style="background:transparent;margin:0px;">
                                <section class="testimonials-style-3">
                                    <div class="container">
                                        <div class="row">
                                            <div>
                                                <div class="item pull-left" *ngFor="let option of options" style="max-width: 300px; margin-right: 10px !important">
                                                    <div class="col">
                                                        <p style="cursor: pointer;" (click)="handleOption(option)">{{option.label}}</p>
                                                    </div>
                                                    <div class="thumb" (click)="selectedOptional = option; ImageFile.click()" style="cursor: pointer">
                                                        <img class="img-circlex" src="{{formServce.optionalFiles.hasOwnProperty(option.cntrl_num.toString()) &&
                                                         formServce.optionalFiles[option.cntrl_num.toString()].pic ?
                                                         formServce.optionalFiles[option.cntrl_num.toString()].pic : 'assets/images/avatar.jpg'}}"
                                                         alt="img"
                                                         style="width: 100% !important; height: auto !important">
                                                    </div>
                                                    <strong class="name">
                                                        <i class="material-icons" style="color: #000000; cursor: pointer;" (click)="remove(option)">close</i>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
<input type="file" (change)="getImage($event)" [ngStyle]="{'display':'none'}" accept="image/*" #ImageFile>
