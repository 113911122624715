<!--<center>-->
<!--    <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px">-->
<!--</center>-->
<!--<div class="vertical-center" style="width: 100% !important;">-->
<!--    <center>-->
<!--        <mat-form-field  class="example-full-width" appearance='fill'>-->
<!--            <mat-label> Search your form from {{campaign.name}} </mat-label>-->
<!--            <input type="text"-->
<!--                   #formElement-->
<!--                   matInput-->
<!--                   placeholder=""-->
<!--                   [formControl] = "formControl"-->
<!--                   [matAutocomplete] = "form">-->
<!--            <mat-autocomplete #form = "matAutocomplete" (optionSelected)="onFormSelectionChanged($event)" [displayWith]="displayFn">-->
<!--                <mat-question *ngFor="let form of filteredFormsOptions | async; let formOf = index" [value]= "form" >-->
<!--                    <i *ngIf="form.id === 0" style="color: #822424">{{form.name}}</i>-->
<!--                    <span *ngIf="form.id !== 0">{{form.campaign}} <i style="color: #888888"> {{(campaignService.getCampaignById(form.campaign_id)).name}}</i></span>-->
<!--                </mat-question>-->
<!--            </mat-autocomplete>-->
<!--            <h4 style="color: red; font-weight: normal"-->
<!--                *ngIf="service.empty(formElement.value) === false &&-->
<!--                     ((filteredFormsOptions | async) | json).length === 2">-->
<!--                **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
<!--        </mat-form-field>-->
<!--    </center>-->
<!--</div>-->

<center>
    <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px" />
</center>
<section class="call-action-section">
    <div class="containerr">
        <div class="text-box" style="margin-left: 10px;margin-right: 10px">
            <h2 class="scolor">My Forms</h2>
            <p class="scolor">start creating a form for market research surveys, customer feedback and more</p>
        </div>
    </div>
</section>
<div class="row" style="margin-top: 5%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <section class="post-section blog-post" style="background-color: transparent !important;">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6" (click)="onFormSelected(form)" *ngFor="let form of (service.app.data.forms.data | jsonPropertyFilter: formService.campaignId.toString() : 'campaign_id')" style="cursor: pointer">
                        <div class="post-box">
                            <div class="text-box">
                                <h4>
                                    <a>{{form.name}}</a>
                                </h4>
                                <p [innerHTML]="form.description"></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
    <div class="col-md-2"></div>
</div>
