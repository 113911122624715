import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {ResponseService} from '../../forms/responses/data/response.service';

@Component({
  selector: 'app-get-cntrl-num',
  templateUrl: './get-cntrl-num.component.html',
  styleUrls: ['./get-cntrl-num.component.scss']
})
export class GetCntrlNumComponent implements OnInit {
  cntrlNum: any;
  subscription: any;
  constructor(public  service: AppService,
              public  router: Router,
              public responseService: ResponseService,
              private  broadcastChannel: BroadcastChannelService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
   this.cntrlNum =  this.service.decrypt(this.activatedRoute.snapshot.params.cntrlnum);
   this.responseService.responseSelectedCntrlNum = this.cntrlNum;
   window.history.back();
  }

}
