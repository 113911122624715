import {AfterContentInit, Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {Parameters} from '../parameters';
import {DialogClientsComponent} from './dialog-clients.component';
import {ClientService} from './client.service';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, AfterContentInit {

  app: any = {data: null};
  parameters: any;
  params: any;
  selected: any;
  constructor(private service: AppService,
              public  metthods: ClientService,
              public dialog: MatDialog) {
    this.app = this.service.app;
    this.app.form.modules  = [{path: '/app/clients', title: 'Clients'}];
    this.app.form.subtitle = 'a person or organization using the services of a lawyer or other professional person or company.';
    this.app.form.module   = 'clients';
    this.selected = null;
    this.metthods.setData(this.app.data.clients);
  }

  ngOnInit(): void {
  }

  openDialog(){
    this.dialog.open(DialogClientsComponent, {data  : {}, width : '55%', height: '100%', });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Registration').toString().toUpperCase() + ' ,clients';
      this.service.header = ('Client').toString().toUpperCase() + ' (' + this.app.data.clients.data.length + ') ,registration';
    });
  }



}
