import {Component, Inject, OnInit} from '@angular/core';
import {CriteriasService} from "../../criterias.service";
import {Parameters} from "../../../parameters";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-list-dialog-criterias',
  templateUrl: './list-dialog-criterias.component.html',
  styleUrls: ['./list-dialog-criterias.component.scss']
})
export class ListDialogCriteriasComponent implements OnInit {

  incomingCriteria: any;
  strings: any;
  constructor(private criteriaService: CriteriasService,
              public service: AppService,
              private broadcastChannelService: BroadcastChannelService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ListDialogCriteriasComponent>) {
    this.incomingCriteria = new Parameters();
    this.strings = new Parameters();
    this.incomingCriteria.setAttributes(this.criteriaService.getSelected());
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
  }

  onInputTextChanged(searchCntrl: any){
    this.service.searchText = searchCntrl;
    this.broadcastChannelService.emitNavChangeEvent({searchText: searchCntrl});
  }

  onSearchButtonClicked(searchCntrl: any){
    this.broadcastChannelService.emitNavChangeEvent({searchText: searchCntrl, status: 0});
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  export(){
    this.broadcastChannelService.emitNavChangeEvent({action: this.strings.getExport(), item: ''});
  }

}
