<a class="pull-left"><h4>Invite people to provide feedback</h4></a>
<a class="pull-right" [md-dialog-close]='' (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="text" class="nullInput"/>
<p> <i>The people you send this link to will remain anonymous when providing feedback.</i></p>
<form [formGroup]=formValues  (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field class="example-full-width example-chip-list">
                  <mat-label>emails</mat-label>
                  <mat-chip-list #chipList aria-label="selection">
                    <mat-chip
                            *ngFor="let user of currentUsers"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="remove(user)">
                        {{user.email}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input
                            placeholder="Search for invitee..."
                            #userInput
                            [formControl]="userCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                      {{user.email}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
                <mat-form-field  class="example-full-width" appearance="outline">
                    <mat-label>Optional message</mat-label>
                    <textarea type="text"  matInput placeholder="" formControlName="message" #message [ngStyle]="{'height':'200px'}"></textarea>
<!--                <mat-hint align="end">{{message.value.length}}</mat-hint>-->
                </mat-form-field>
            </span>
        </mat-list-item>
    </mat-list>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <center>
                <div class="example-button-row">
                    <input type="submit" class="btn btn-success btn-xs submit-btn" value="Send now"/>
                </div>
            </center>
        </div>
    </div>
</form>
