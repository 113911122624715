import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
import {AppService} from '../../../app.service';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Parameters} from '../../../parameters';
import {UserService} from '../../../users/user.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {FormService} from '../../../forms/form.service';
import {CampaignService} from "../../../campaigns/campaign.service";
import {AmbassadorsService} from "../../../ambassadors/ambassadors.service";
import {TeamService} from "../../team.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {NotifierService} from 'angular-notifier';
import {ClientService} from '../../../clients/client.service';
import {CampanyService} from '../../../campanies/campany.service';
import {MemberService} from "../member.service";

@Component({
  selector: 'app-manage-team-members',
  templateUrl: './manage-team-members.component.html',
  styleUrls: ['./manage-team-members.component.scss']
})
export class ManageTeamMembersComponent implements OnInit {
  @ViewChild('userAutocompleteTrigger', {static: false}) userAutocompleteTrigger: any;
  @ViewChild('userId')  userId: any;
  @Input() team;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  searchCntrl: any;
  userControl = new FormControl();
  filteredUsersOptions: any;
  incomingTeam: any;
  incomingMemberSelected: any;
  outgoingRequest: any;
  incomingForm: any;
  incomingMember: any;
  position: any;
  incomingInvitee: any;
  selectedUser: any;
  label: any;
  incomingUser: any = {};
  incomingCampany: any;
  incomingClient: any;
  incomingCampaign: any;
  results: any;
  strings: any;
  constructor(public  service: AppService,
              private formService: FormService,
              private teamService: TeamService,
              public memberService: MemberService,
              public ambassadorService: AmbassadorsService,
              private notifier: NotifierService,
              private campaignService: CampaignService,
              private campanyService: CampanyService,
              private clientService: ClientService,
              private broadcastChannelService: BroadcastChannelService,
              public  userService: UserService) {
    this.contextMenu = MatMenuTrigger;
    this.incomingTeam = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingMember = new Parameters();
    this.incomingInvitee = new Parameters();
    this.strings = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingMemberSelected = new Parameters();
    this.userAutocompleteTrigger = MatAutocompleteTrigger;
    this.outgoingRequest.setAttributes({});
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingMember.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.userId = ElementRef;
    this.incomingTeam.setAttributes({});
    this.incomingMemberSelected.setAttributes(this.selectedUser);
  }
  
  ngOnInit(): void {
    this.incomingTeam.setAttributes(this.team);
    this.userControl.setValue({});
    setTimeout(() => {
      this.filteredUsersOptions = this.userControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterUser(name) : this.service.app.data.users.data.slice())
      );
    });
  }

  openMemberDialog(member: any){
    this.ambassadorService.setSelected(this.selectedUser);
    this.memberService.setSelected(this.selectedUser);
    this.memberService.openDialog(this.incomingMemberSelected.getAttributes());
  }

  onContextMenu(event: MouseEvent, user: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': user };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openUserDialog(member: any){
    this.incomingTeam.setAttributes(this.team);
    this.teamService.teamSelectedId = this.incomingTeam.getId();
    this.ambassadorService.setSelected(member);
    this.ambassadorService.openDialog(member);
  }

  getMembersByTeamId(teamId: any){
   return this.service.app.data.ambassadors.data.filter((ambassador: any) => {return parseFloat(ambassador.team_id) === teamId;});
  }

  setTarget(member: any){
    setTimeout(() => {
      let form: any = this.formService.selected;
      if(Object.keys(form).length === 0){}else{
        this.label = prompt('', '');
        if (this.label !== null) {
          this.incomingForm.setAttributes(form);
          this.position = this.incomingForm.getInvitees().data.findIndex((invitee: any) => { return parseFloat(invitee.member_id) === parseFloat(member.team_member_id);});
          this.incomingMember.setAttributes(member);
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setUserId(this.incomingMember.getId());
          this.outgoingRequest.setFormId(this.incomingForm.getId());
          this.outgoingRequest.setMemberId(this.incomingMember.getTeamMemberId());
          this.outgoingRequest.setTeamId(this.incomingTeam.getId());
          this.outgoingRequest.setTarget(this.label);
          if(this.position !== -1){
            this.service.httpService(
                'post', '/form/user/assignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                  this.notifier.notify('success', 'successfull on set');
                }, (error: any) => {});
          }
        }
      }
    });
  }


  onUserNameTextChanged(event: any){
    this.userService.search({searchText: event.target.value, role_id: 8});
  }

  private _filterUser(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.users.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  handleChangePassword(member: any){
    this.incomingMember.setAttributes(member);
    this.userService.setSelected(this.incomingMember.getAttributes());
    this.userService.openChangePasswordDialog(this.incomingMember.getAttributes());
  }

  getUserByMember(ambassador: any){
    let user = this.userService.getUserByUser(ambassador);
    this.incomingUser.setAttributes(ambassador);
//  this.incomingTeam.setAttributes(this.teamService.getTeamById(this.incomingUser.getTeamId()));
//  this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingTeam.getCampaignId()));
//  this.incomingCampany.setAttributes(this.campanyService.getCampanyById(this.incomingCampaign.getCampanyId()));
//  this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
//  this.incomingCampaign.setClient(this.incomingClient.getAttributes());
//  this.incomingCampaign.setCampany(this.incomingCampany.getAttributes());
//  this.incomingTeam.setCampaign(this.incomingCampaign.getAttributes());
//  this.incomingUser.setTeam(this.incomingTeam.getAttributes());
    return this.incomingUser.getAttributes();
  }
  displayFn(value: any){
    return value ? value.name : null;
  }
  
  addUser(){
    this.ambassadorService.setSelected({});
    this.ambassadorService.openDialog({teamId: this.incomingTeam.getId()});
    setTimeout(() =>{
      this.userAutocompleteTrigger.closePanel();
    });
  }

  assignUser(member: any, checkbox: any){
    setTimeout(() => {
      let incomingMember = new Parameters();
      incomingMember.setAttributes(member);
      incomingMember.setDisabled(1);
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      this.outgoingRequest.setUserId(incomingMember.getUserId());
      this.outgoingRequest.setTeamId(incomingMember.getTeamId());
      this.service.httpService('post', this.service.app.routes.forms.ambassadors.assign, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
        try{
          if(checkbox.checked){
            incomingMember.getForms().data.push(this.incomingForm.getAttributes());
          }else incomingMember.getForms().data.splice(
              this.service.findIndex(incomingMember.getForms().data,
                  this.strings.getId(),
                  this.incomingForm.getId()), 1);
        }catch (e: any) {}
        incomingMember.setDisabled(0);
      }, (error: any) => {
        checkbox.checked = !checkbox.checked;
        incomingMember.setDisabled(0);
      });
    });

    // this.outgoingRequest.setFormId(this.incomingForm.getId());
    // this.outgoingRequest.setFormId(this.incomingForm.getId());
    // if(this.incomingInvitee.attributes.hasOwnProperty('team_id')){
    //   this.outgoingRequest.setTeamId(this.incomingInvitee.getTeamId());
    // } if(this.incomingInvitee.attributes.hasOwnProperty('target'))
    //   this.outgoingRequest.setTarget(this.incomingInvitee.getTarget());
    // this.service.httpService('post', this.service.app.routes.forms.ambassadors.assign, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
    //   this.incomingResult.setAttributes(result);
    //   this.incomingInvitee.setActive(this.incomingResult.getActive());
    //   this.incomingInvitee.setAttributes(Object.assign(this.incomingInvitee.getAttributes(), result));
    //   this.position = this.service.findIntegerIndex(this.incomingForm.getInvitees().data, 'user_id',this.incomingInvitee.getId());
    //   if(this.position !== -1){
    //     this.incomingAmbassador.setAttributes(this.incomingForm.getInvitees().data[this.position]);
    //     this.incomingAmbassador.setActive(this.incomingInvitee.getActive());
    //   }else {
    //     this.incomingForm.getInvitees().data.unshift(Object.assign({},this.incomingInvitee.getAttributes()));
    //     this.service.app.data.invitees.data.unshift(Object.assign({},this.incomingInvitee.getAttributes()));
    //   }
    // }, (error: any) => {
    // });
  }

  removeUser(user: any){}

  onUserChecked(member: any, checkboxElement: any){
    setTimeout(() => {
      let form: any = this.formService.getSelected();
      if(Object.keys(form).length === 0){}else{
        this.incomingForm.setAttributes(form);
        this.position = this.incomingForm.getInvitees().data.findIndex((invitee: any) => {
          return parseFloat(invitee.member_id) === parseFloat(member.team_member_id);
          });
        this.incomingMember.setAttributes(member);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setUserId(this.incomingMember.getId());
        this.outgoingRequest.setFormId(this.incomingForm.getId());
        this.outgoingRequest.setMemberId(this.incomingMember.getTeamMemberId());
        this.outgoingRequest.setTeamId(this.incomingTeam.getId());
        if(this.position === -1){
          this.service.httpService(
              'post', '/form/user/assignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                this.incomingForm.getInvitees().data.unshift(response);
                }, (error: any) => {
                checkboxElement.checked = false;
              });
        }else{
          if(checkboxElement.checked){
            this.service.httpService(
                'post', '/form/user/assignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                  this.incomingForm.getInvitees().data.unshift(response);
                  }, (error: any) => {
                  checkboxElement.checked = false;
                });
          }else{
            this.incomingInvitee.setAttributes(this.incomingForm.getInvitees().data[this.position]);
            this.outgoingRequest.setFormUserId(this.incomingInvitee.getFormUserId());
            this.service.httpService(
                'post', '/form/user/unassignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                  this.incomingForm.getInvitees().data.splice(this.position, 1);
                  }, (error: any) => {
                  checkboxElement.checked = false;
                });
          }
        }
      }
    });
  }

  onloadChecked(teamId: any, member: any, checkedElement: any){
    let form: any = this.formService.selected;
    this.incomingMemberSelected.setAttributes(member);
    this.results = false;
    if(Object.keys(form).length === 0){}else{
      this.incomingForm.setAttributes(form);
      this.position = this.incomingForm.getInvitees().data.findIndex((invitee: any) => {
        this.incomingInvitee.setAttributes(invitee);
        return parseFloat(this.incomingInvitee.getUserId()) === parseFloat(this.incomingMemberSelected.getUserId()) &&
               this.incomingInvitee.attributes.hasOwnProperty('team_id') &&
               parseFloat(this.incomingInvitee.getTeamId()) === parseFloat(teamId);
        });
      this.results =  this.position === -1 ? false : true;
    }return this.results;
//  this.broadcastChannelService.emitNavChangeEvent({checkedUserElement: checkedElement, checkedUser: member});
  }

  onUserSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.outgoingRequest.setAttributes(event.option.value);
    this.outgoingRequest.setTeamId(this.incomingTeam.getId());
    this.outgoingRequest.setUserId(this.outgoingRequest.getId());
    delete this.outgoingRequest.attributes.id;
    this.service.httpService('post', '/team/member/addrequest',
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            this.userId.nativeElement.value = '';
            this.searchCntrl = '';
            if(this.incomingTeam.getAttributes().hasOwnProperty('members')){
              const position = this.incomingTeam.getMembers().data.findIndex((member: any) => { return parseFloat(member.team_member_id) === parseFloat(event.option.value.team_member_id);});
              if(position === -1) {
                this.incomingTeam.getMembers().data.unshift(event.option.value);
                this.service.app.data.ambassadors.data.unshift(event.option.value);
              } else this.incomingTeam.getMembers().data[position] = Object.assign(this.incomingTeam.getMembers().data[position], event.option.value);
            }
          });
        }, (error: any) => {
        });
  }


}
