import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-draft-time-controls',
  templateUrl: './draft-time-controls.component.html',
  styleUrls: ['./draft-time-controls.component.scss']
})
export class DraftTimeControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  label: any;
  controls: any;
  constructor(private service: AppService) { }
  ngOnInit(): void {
    this.controls = this.service.app.data.controls;
  }
}
