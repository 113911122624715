import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from '../../forms/form.service';
@Component({
  selector: 'app-live-photo-wall',
  templateUrl: './live-photo-wall.component.html',
  styleUrls: ['./live-photo-wall.component.scss']
})
export class LivePhotoWallComponent implements OnInit {

  constructor(public service: AppService,
              private formService: FormService,) { }

  ngOnInit(): void {
  }

}
