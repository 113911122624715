<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric">Name</th>
    <th class="mdl-data-table__cell--non-numeric">Kpi</th>
    <th class="mdl-data-table__cell--non-numeric">Campaign</th>
    <th class="mdl-data-table__cell--non-numeric">Client</th>
    <th class="mdl-data-table__cell--non-numeric">Responses</th>
    <th class="mdl-data-table__cell--non-numeric">Description</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let form of (flag.includes('all') ? (
      service.app.data.forms.data |
      jsonPropertyFilter: service.status.toString() : 'status' |
      jsonPropertyFilter: formService.categoryId.toString() : 'category_id' |
      jsonPropertyFilter: formService.campaignId.toString() : 'campaign_id' |
      jsonFilter:searchCntrl) : (forms.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, form)"
      (mousedown)="selectedForm = form">
    <td class="mdl-data-table__cell--non-numeric">{{form.name}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{form.target}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{getCampaignByForm(form).name}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{form.campaign.client.name}}</td>
    <td class="mdl-data-table__cell--non-numeric"><i class=""[ngStyle]="{'cursor':'pointer'}" style="color: #761c19">Responses</i></td>
    <td class="mdl-data-table__cell--non-numeric" [innerHTML]="form.description"></td>
    <td>
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li mat-menu-item (click)="handleDashboard(selectedForm)"   *ngIf="flag === 'all'" >Dashboard</li>
          <li mat-menu-item (click)="handleUpdate(selectedForm)"      *ngIf="flag === 'all'" >View</li>
          <li mat-menu-item (click)="handleResponses(selectedForm)"   *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('collection') || form.type.name.toString().toLowerCase().includes('survey'))" >Responses</li>
          <li mat-menu-item (click)="handleInvitees(selectedForm)"    *ngIf="flag === 'all'" >Invitees</li>
          <li mat-menu-item (click)="handleSuggestions(selectedForm)" *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('suggestion'))">Suggestions</li>
<!--      <li mat-menu-item (click)="handleNavigations(selectedForm)" *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('navigation'))">Navigations</li>-->
          <li mat-menu-item (click)="handleRespondents(selectedForm)" *ngIf="flag === 'all'" >Assign respondents</li>
          <li mat-menu-item (click)="handleDestroy(selectedForm)"     *ngIf="flag === 'all'" >Delete</li>
          <li mat-menu-item (click)="handleRestore(selectedForm)"     *ngIf="flag === 'restore'" >Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
          <button mat-menu-item (click)="handleDashboard(selectedForm)"   *ngIf="flag === 'all'"  >Dashboard</button>
          <button mat-menu-item (click)="handleUpdate(selectedForm)"      *ngIf="flag === 'all'"  >View</button>
          <button mat-menu-item (click)="handleSuggestions(selectedForm)" *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('suggestion'))" >Suggestions</button>
          <button mat-menu-item (click)="handleSuggestions(selectedForm)" *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('collection'))" >Respond</button>
          <!--      <button mat-menu-item (click)="handleNavigations(selectedForm)" *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('navigation'))" >Navigations</button>-->
          <button mat-menu-item (click)="handleResponses(selectedForm)"   *ngIf="flag === 'all' && (form.type.name.toString().toLowerCase().includes('collection') || form.type.name.toString().toLowerCase().includes('survey'))" >Responses</button>
          <button mat-menu-item (click)="handleRespondents(selectedForm)" *ngIf="flag === 'all'" >Assign respondents</button>
          <button mat-menu-item (click)="handleDestroy(selectedForm)"     *ngIf="flag === 'all'" >Delete</button>
          <button mat-menu-item (click)="handleRestore(selectedForm)"     *ngIf="flag === 'restore'" >Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
