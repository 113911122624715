<div class="row">
    <a class="pull-right"
       [matDialogClose]="true"
       [ngStyle] = "{'cursor':'pointer'}">
        <i class="material-icons">close</i></a><br><br>
</div>
<div [ngStyle]="{'margin-top': '20px'}">
    <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Default container width
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes.default_container_width"
                                   #defaultContainerWidth
                                   (blur)="incomingCriteria.setDefaultContainerWidth(defaultContainerWidth.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Default container height
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes.default_container_height"
                                   #defaultContainerHeight
                                   (blur)="incomingCriteria.setDefaultContainerHeight(defaultContainerHeight.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Canvas Width
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes[strings.getCanvasWidth()]"
                                   #canvasWidth
                                   (blur)="incomingCriteria.setCanvasWidth(canvasWidth.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Canvas height
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes[strings.getCanvasHeight()]"
                                   #canvasHeight
                                   (blur)="incomingCriteria.setCanvasHeight(canvasHeight.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Text length
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes.text_size"
                                   #textSize
                                   (blur)="incomingCriteria.setTextSize(textSize.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <tr>
                        <td valign="top">
                            Default Chart
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" [ngStyle]="{'width':'100%'}">
                            <mat-grid-list cols="2" rowHeight="2:1">
                                <mat-grid-tile *ngFor="let chart of charts; let chartIncremental = index"
                                  class="cursor"
                                  [title]="chart.title"
                                  (click)="incomingCriteria.setChart(chart.id); sendNotification()">
                                    <img class="cursor"
                                         src="{{chart.icon}}"
                                         [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}"
                                    />
                                    <mat-checkbox [checked]="true" class="pull-right" *ngIf="chart.id === incomingCriteria.getChart()"></mat-checkbox>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class=""> Fill</span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="">
                <mat-slide-toggle
                        class="pull-right"
                        [checked]="incomingCriteria.getAttributes().hasOwnProperty('fill') && incomingCriteria.getAttributes().fill === 1"
                        (change)="($event.checked ? incomingCriteria.getAttributes().fill = 1 : incomingCriteria.getAttributes().fill = 0); sendNotification()"
                >
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
            <div style="margin-top: 10px">
                <table>
                    <td [ngStyle]="{'width':'100%'}" valign="top">
                        Text rotation angle
                    </td>
                    <td valign="top">
                        <mat-form-field
                                class="pull-right example-full-width"
                                appearance="outline">
                            <mat-label></mat-label>
                            <input matInput
                                   type="text"
                                   [(ngModel)]="incomingCriteria.attributes.text_rotation_angle"
                                   #textRotationAngle
                                   (blur)="incomingCriteria.setTextRotationAngle(textRotationAngle.value);
                           sendNotification()">
                        </mat-form-field>
                    </td>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
