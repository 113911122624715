import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
@Pipe({
  name: 'sortBy'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], order: any, column: any): any[] {
    return orderBy(value, column, order);
  }
}
