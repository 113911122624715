<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div class="row">
                    <mat-radio-group
                            aria-labelledby="example-radio-group-label"
                            class="example-radio-group"
                    >
                        <mat-radio-button
                            #checked
                            [checked]="isChecked(checked, option)"
                            (click)="radioChange($event, option); selectedOptonal= option; onContextMenu($event, option)"
                            class="example-radio-buttonn helvetica"
                            *ngFor="let option of question.options"
                            [value]="option">
                            <span style="">{{option.label}}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="row">
                    <div style="visibility: hidden; position: fixed"
                         [style.left]="contextMenuPosition.x"
                         [style.top]="contextMenuPosition.y"
                         [matMenuTriggerFor]="contextMenu">
                    </div>
                    <mat-menu #contextMenu="matMenu">
                        <ng-template matMenuContent let-item="choice">
                            <button mat-menu-item (click)="addOptionalNextQuestional(selectedOptonal)">Add Next Question</button>
                            <button mat-menu-item (click)="viewNextQuestions(selectedOptonal)" *ngIf="selectedOptonal.questions.length !==0">Go to Next Questions</button>
                            <button mat-menu-item (click)="updateOptionalTitle(selectedOptonal)">Update title</button>
                            <button mat-menu-item (click)="deleteOptional(selectedOptonal)">Delete</button>
                        </ng-template>
                    </mat-menu>
                </div>
                <div class="row" style="margin-top: 50px">
                    <center><a  mat-mini-fab class="defaultColor" (click)="addOptional()"> add </a></center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
