<div>
<!--    <mat-tab-group>-->
<!--        <mat-tab label="Forms({{service.app.data.forms.data.length}})">-->
<!--            <br><br><app-search ></app-search><br><br>-->
<!--            <app-tabs-forms></app-tabs-forms>-->
<!--        </mat-tab>-->
<!--        <mat-tab label="Campaigns({{service.app.data.campaigns.data.length}})">-->
<!--            <br><br><app-search></app-search><br><br>-->
<!--            <app-tabs-campaigns></app-tabs-campaigns>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
</div>
