import { Component, Input, OnInit } from '@angular/core';
import {ChartService} from '../../../chart.service';

@Component({
  selector: 'app-echart',
  templateUrl: './echart.component.html',
  styleUrls: ['./echart.component.scss']
})
export class EchartComponent implements OnInit {
  @Input() data: any;
  theme: any;
  element: any;
  constructor(private chart: ChartService) {
    this.theme  = this.chart.theme();
  }

  ngOnInit(): void {
   setTimeout(() =>{
        this.element = this.echartBarLine(this.data.title, this.data.legends, this.data.yAxis, this.data.xValues,this.data.series);
   }, 1000);
  }


  echartBarLine(title: any, legends: any, yAxis: any, xValues: any, series: any){
      const options = {
              title: {
                x: 'center',
                y: 'top',
                padding: [0, 0, 20, 0],
                text: title,
                textStyle: {
                  fontSize: 15,
                  fontWeight: 'normal'
                }
              },
              tooltip: {
                trigger: 'axis'
              },
              toolbox: {
                show: true,
                feature: {
                  dataView: {
                    show: true,
                    readOnly: false,
                    title: "Text View",
                    lang: [
                      "Text View",
                      "Close",
                      "Refresh",
                    ],
                  },
                  restore: {
                    show: true,
                    title: 'Restore'
                  },
                  saveAsImage: {
                    show: true,
                    title: 'Save'
                  }
                }
              },
              calculable: true,
              legend: {
                data: legends,
                y: 'bottom'
              },
              xAxis: [{
                type: 'category',
                data: xValues
              }],
              yAxis: yAxis,
              series: series
            };
            return options;
      }


}
