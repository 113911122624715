import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {CampanyService} from './campany.service';
import {ClientService} from '../clients/client.service';
import {BroadcastChannelService} from '../broadcast-channel.service';

@Component({
  selector: 'app-dialog-campanies',
  templateUrl: './dialog-campanies.component.html',
  styleUrls: ['./dialog-campanies.component.scss']
})
export class DialogCampaniesComponent implements OnInit {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  roleControl = new FormControl();
  filteredRolesOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedCampany: any;
  keys: any;
  selectedCampany: any;
  addressValue: any;
  logo: any;
  constructor(public provider: AppService,
              private notifier: NotifierService,
              private clientService: ClientService,
              public formBuilder: FormBuilder,
              public broadcastChannel: BroadcastChannelService,
              public metthods: CampanyService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogCampaniesComponent>) {
    this.app = this.provider.app;
    this.configAngularEditor = this.provider.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.parameters.setRoleId(20);
    this.parameters.setLocation('');
    this.incomingSelectedCampany = new Parameters();
    this.incomingSelectedCampany.setAttributes(metthods.getSelected());
    this.keys = Object.keys(this.incomingSelectedCampany.getAttributes()).length;
    this.selectedCampany = metthods.getSelected();
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {this.metthods.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      logo:        ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
      address:     ['', Validators.compose([])],
      cntrl_num:   [this.provider.random(), Validators.compose([])],
    });

    //  this.formValues.get('location').valueChanges.subscribe(value => console.log('value changed', ''));
    if(this.keys === 0) this.selectedCampany = {}; else{
      this.addressValue = this.incomingSelectedCampany.getAddress();
      for(var key in this.incomingSelectedCampany.getAttributes()) {
        if(this.incomingSelectedCampany.attributes[key] instanceof Object || this.incomingSelectedCampany.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedCampany.attributes[key]);
          }else this.formValues.addControl(key, new FormControl(this.incomingSelectedCampany.attributes[key], Validators.compose([])));
        }
      }
    }
    setTimeout(() => {
      if(this.provider.isNull(this.formValues.controls['cntrl_num'].value)){
        this.formValues.controls['cntrl_num'].setValue(this.provider.random());
      }
    });
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }



  onGoogleMapAutocompleteSelected(result: PlaceResult, address: any) {
    this.formValues.controls['address'].setValue(address);
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//  onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//  }

  handlEditorFocus(){
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.provider.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }



  private _filterRole(name: string) {
    const filterValue = name.toLowerCase();
    return this.data.roles.filter((option: any) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  onChangeAddressText(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
    this.formValues.controls['address'].setValue(event.target.value);
  }

//  handleAddressChange(result: PlaceResult){
//  }


  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(campanyForm: any) {
    this.provider.httpService(
      'post', this.keys === 0 ? '/campanies' : '/campanies/' + this.incomingSelectedCampany.getId() + '/update',
      campanyForm, {},
      (response: any) => {
        if(this.keys === 0){
          this.app.data.campanies.data.unshift(response);
          this.clientService.search(campanyForm.name, (result) => {});
        }else this.selectedCampany = Object.assign(this.selectedCampany, response);
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        this.broadcastChannel.emitNavChangeEvent({campany: response});
        this.selectedCampany = {};
      }, (error: any) => {
      });

  }
}
