<a class="pull-left"><h4>{{keys === 0 ? (flag === 0 ? 'Suggestion box' : 'Form, create') : incomingFormSelected.getName()}}</h4></a>
<a  class="btn pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}">
    <i class="material-icons">close</i>
</a>
<!--<a mat-mini-fab class="defaultColor pull-right" (click)="formValues.controls['status'].setValue('0'); submitElement.click()" [ngStyle] = "{'cursor':'pointer', 'margin-right':'10px'}">Save</a>-->
<br><br>
<hr />
<p [ngStyle]="{'margin':'30px'}"></p>
<mat-tab-group mat-align-tabs="end"  (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab [disabled]="false">
        <ng-template mat-tab-label>
            <center>
                <br>
                <div [ngStyle]="{'cursor':'pointer'}">
                    <i class="material-icons">tune</i>
                </div>
            </center>
        </ng-template>
        <div style="overflow-y: hidden">
            <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
                <mat-list>
                    <mat-list-item>
                    <span class="" mat-line>
                      <mat-form-field  class="example-full-width" appearance="standard">
                        <mat-label>Untitled {{flag === 3 ? 'Form' : 'Form'}} *</mat-label>
                        <input matInput type="text"  formControlName="name">
                      </mat-form-field>
                    </span>
                    </mat-list-item>

                    <mat-list-item *ngIf="service.app.data.user.role.hasOwnProperty(strings.getDisplayName()) && service.app.data.user.role.display_name.toString().toLowerCase().includes('admin')">
                        <span class="" mat-line>
                          <mat-form-field  class="example-full-width" appearance="fill">
                             <mat-label> Project *</mat-label>
                             <input type="text"
                                    #campaignId
                                    placeholder=""
                                    aria-label = "Number"
                                    matInput
                                    [formControl] = "campaignControl"
                                    (input)="onChangeCampaignText($event)"
                                    [matAutocomplete] = "campaign">
                                        <mat-autocomplete #campaign = "matAutocomplete" (optionSelected)="onCampaignSelectionChanged($event)" [displayWith]="displayFn">
                                            <mat-option *ngFor="let campaign of filteredCampaignsOptions | async; let campaignOf = index" [value]= "campaign" >
                                                {{campaign.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addCampaign()">add</i>
                                        <h4 style="color: red; font-weight: normal"
                                            *ngIf="service.isNull(campaignId.value) === false &&
                                              ((filteredCampaignsOptions | async) | json).length === 2">
                                              **---Not Found *---
                                        </h4>
                          </mat-form-field>
                        </span>
                    </mat-list-item>

                    <mat-list-item *ngIf="service.app.data.user.role.id === 1">
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width" appearance="fill">
                                 <mat-label> Category *</mat-label>
                                 <input type="text"
                                        placeholder=""
                                        aria-label = "Number"
                                        matInput
                                        [formControl] = "typeControl"
                                        [matAutocomplete] = "type">
                                            <mat-autocomplete #type = "matAutocomplete"
                                                              (optionSelected)="onTypeSelectionChanged($event)"
                                                              [displayWith]="displayFn">
                                                <mat-option *ngFor="let type of filteredTypesOptions | async; let typeOf = index" [value]= "type" >
                                                    {{type.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                              </mat-form-field>
                            </span>
                    </mat-list-item>

                    <mat-list-item>
                        <span class="" mat-line>
                          <mat-form-field  class="example-full-width">
                            <mat-label>key performance indicator</mat-label>
                            <input matInput type="text" placeholder="" formControlName="target">
                          </mat-form-field>
                        </span>
                    </mat-list-item>

                    <mat-list-item>
                        <span class="" mat-line>
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Description</mat-label>
                            <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
                            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                          </mat-form-field>
                        </span>
                    </mat-list-item>
                </mat-list>
                <div *ngIf="service.parseFloat(formValues.controls['category_id'].value) !== 2">
                    <div *ngFor="let question of questions; let questionOf=index">
                        <div>
                            <div class="{{questionOf === pos ? 'col-lg-9 col-md-9 col-sm-9 col-xs-9' : 'col-lg-12 col-md-12 col-sm-12 col-xs-12'}}" (mouseenter)="pos = questionOf">
                                <app-text-controls              [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 1 || question.control.id === 6"></app-text-controls>
                                <app-image-controls             [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 2"></app-image-controls>
                                <app-choice-controls            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 3 || question.control.id === 4 || question.control.id === 5 || question.control.id === 9  || question.control.id === 21" [deletedOptions] = 'removedOptions'></app-choice-controls>
                                <app-video-controls             [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 7"></app-video-controls>
                                <app-create-file-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 8"></app-create-file-controls>
                                <app-number-controls            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 10"></app-number-controls>
                                <app-date-controls              [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 11"></app-date-controls>
                                <app-description-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 12"></app-description-controls>
                                <app-rate-controls              [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 13"></app-rate-controls>
                                <app-outlet-controls            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 14"></app-outlet-controls>
                                <app-create-order-control       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 15" [deletedOptions] = 'removedOptions'></app-create-order-control>
                                <app-textarea-controls          [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 16"></app-textarea-controls>
                                <app-draft-group-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 18" [deletedOptions] = 'removedOptions'></app-draft-group-controls>
                                <app-draft-time-controls        [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 19"></app-draft-time-controls>
                                <app-image-choice-draft-control [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 20"></app-image-choice-draft-control>
                                <app-coordinate-draft-controls  [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 22"></app-coordinate-draft-controls>
                                <app-draft-email-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 23"></app-draft-email-controls>
                                <app-draft-table-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 24" [deletedOptions] = 'removedOptions'></app-draft-table-controls>
                                <app-draft-inputlists-controls  [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 25"></app-draft-inputlists-controls>
                                <app-number-controls            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 26"></app-number-controls>
                                <app-chip-default-controls      [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 27" [deletedOptions] = 'removedOptions'></app-chip-default-controls>
                                <app-waypoints-draft            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 28" [deletedOptions] = 'removedOptions'></app-waypoints-draft>
                                <app-adverts-draft              [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 29" [deletedOptions] = 'removedOptions'></app-adverts-draft>
                                <app-number-controls            [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 30"></app-number-controls>
                                <app-draft-ranks                [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 31"></app-draft-ranks>
                                <app-description-controls       [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 32"></app-description-controls>
                                <app-create-qrcode-controls     [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 33"></app-create-qrcode-controls>
                                <app-create-submit-control      [position] = 'questionOf' [question]="question" *ngIf="question.control.id === 35"></app-create-submit-control>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" [hidden]="questionOf !== pos">
                                <app-side-controls [position]='questionOf' [questions]="questions" [controls]="controls" [question]="question"></app-side-controls>
                            </div>
                        </div>
                    </div>
                    <div style="position: fixed; top:30%;right: 16%">
                        <a mat-mini-fab class="defaultColor" (click)="scrollUp()"><i class="material-icons">keyboard_arrow_up</i></a><br><br>
                        <a mat-mini-fab class="defaultColor" (click)="scrollDown()"><i class="material-icons">keyboard_arrow_down</i></a>
                    </div>
                </div>
                <div *ngIf="service.parseFloat(formValues.controls['category_id'].value) === 2">
                    <app-waypoints-draft [deletedOptions] = 'removedOptions'></app-waypoints-draft>
                </div>
                <div [ngStyle]="{'position':'fixed','bottom':'5px','right':'50%'}">
                    <div class="">
                        <center>
                            <input #submitElement type="submit" class="defaultColor submit-btn" value="{{keys === 0 ? 'Publish' : 'Done'}}"/>
                        </center>
                    </div>
                </div>
            </form>
        </div>
    </mat-tab>
    <mat-tab [disabled]="false">
        <ng-template mat-tab-label>
            <center>
                <span class='headline2'>Review</span>
                <br>
                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">remove_red_eye</i>
            </center>
        </ng-template>
        <dv>
            <table class='table table-striped table-bordered headline2'>
                <thead>
                    <th>Title</th>
                    <th>Position</th>
                    <th>Settings</th>
                    <th>Skips</th>
                    <th>Hidden</th>
                </thead>
                <tbody>
                 <tr *ngFor='let question of questions'>
                     <td class='headline2' title='{{question.label}}'>{{service.limitTo(service.removeHtmlTags(question.label), 20)}}</td>
                     <td #positionElement
                         contenteditable="true"
                         [innerHTML]="question.position"
                         (blur)='question.position = service.removeHtmlTags(positionElement.innerText)'>
                     </td>
                     <td> <app-bottom-button-controls [question]='question' [position]='question.index'></app-bottom-button-controls></td>
                     <td>
                         <span *ngFor='let option of question.options'>
                             <span class='coder-afr-chip headline2' *ngFor='let q of option.questions'>
                                 ({{ q.position }}){{q.label}}
                                 <i class="material-icons pull-right cursor" (click)='removeSkip(option.questions, q)'>close</i>
                             </span>
                         </span>
                     </td>
                     <td>
                         <mat-slide-toggle
                                 [checked]="question.hasOwnProperty('settings') && question.settings.hasOwnProperty('hidden') && question.settings.hidden === 1"
                                 (change)="($event.checked ? (question.settings.hidden = 1): (question.settings.hidden = 0))"
                         >
                         </mat-slide-toggle>
                     </td>
                 </tr>
                </tbody>
            </table>
        </dv>
    </mat-tab>
<!--    <mat-tab [disabled]="false">-->
<!--        <ng-template mat-tab-label>-->
<!--            <center>-->
<!--                Preview-->
<!--                <br>-->
<!--                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">remove_red_eye</i>-->
<!--            </center>-->
<!--        </ng-template>-->
<!--        <dv><app-preview-form [form]="{name: 'Jr'}" [questions]="questions"></app-preview-form></dv>-->
<!--    </mat-tab>-->
    <mat-tab [disabled]="false">
        <ng-template mat-tab-label>
            <center>
                Tree view
                <br>
                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">architecture</i>
            </center>
        </ng-template>
        <div>
            <br><br>
            <app-treeview-form [dataSource]="dataSource" [treeControl]="treeControl"  [questions]="questions"></app-treeview-form>
        </div>
    </mat-tab>
<!--    <mat-tab [disabled]="false" *ngIf="keys > 0">-->
<!--        <ng-template mat-tab-label>-->
<!--            <center>-->
<!--                Settings-->
<!--                <br>-->
<!--                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}">settings</i>-->
<!--            </center>-->
<!--        </ng-template>-->
<!--        <div><app-settings-form [form]="selectedForm"></app-settings-form></div>-->
<!--    </mat-tab>-->
</mat-tab-group>
