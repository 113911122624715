


<!--<img  class="logo img-circle"-->
<!--      *ngIf="isloadingComplete"-->
<!--      (load)="doSomething($event)"-->
<!--      src="{{logoDisplayed}}"-->
<!--      alt="logo"/>-->

<!--<mat-progress-bar-->
<!--        *ngIf="service.progress"-->
<!--        class="progressCSS"-->
<!--        mode="query">-->
<!--</mat-progress-bar>-->

<div class="container">
    <div>
        <div [ngStyle]="{'position':'absolute','top':'0%','height':'100%','width':'100%','margin-top':'10px','margin-bottom':'10px'}">
            <div class="vertical-center"
                 [ngStyle]="{'padding-left':'5px','padding-top':'5px','padding-bottom':'5px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'transparent'}">
                <center>
                    <div>
                        <center>
<!--                            <ng-container *ngIf="service.isNull(service.logo)">-->
<!--                                <img  class="logox img-circle"-->
<!--                                      src="../../assets/images/coat.png"-->
<!--                                      (error)="onErrorHandle($event)"-->
<!--                                      alt=""/>-->
<!--                            </ng-container>-->
                            <ng-container *ngIf="!service.isNull(service.logo)">
                                <img  class="logox img-circle"
                                      src="{{service.logo}}"
                                      (error)="onErrorHandle($event)"
                                      alt=""/>
                            </ng-container>
                        </center>
                    </div>

                </center>
            </div>
        </div>
    </div>
</div>
