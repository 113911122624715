<div style="margin-top: 20px">
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedIndex">
        <mat-tab [disabled]="true">
            <ng-template mat-tab-label>
                <div (click)="onteamAddClicked()">
                    <center>
                        <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
                    </center>
                </div>
            </ng-template>
            <div>
            </div>
        </mat-tab>
        <mat-tab [disabled]="false" *ngFor="let team of teams">
            <ng-template mat-tab-label>
                <center>
                    {{team.name}}
                </center>
            </ng-template>
            <div>
                <app-manage-team-members [team]="team"></app-manage-team-members>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
