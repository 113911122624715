import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-invites',
  templateUrl: './tab-invites.component.html',
  styleUrls: ['./tab-invites.component.scss']
})
export class TabInvitesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
