import {Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {AppService} from '../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {FormService} from '../../form.service';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import PlaceResult = google.maps.places.PlaceResult;
import {FormControl, Validators} from "@angular/forms";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/internal/operators";
import {Parameters} from "../../../parameters";
@Component({
  selector: 'app-outlet-controls',
  templateUrl: './outlet-controls.component.html',
  styleUrls: ['./outlet-controls.component.scss']
})
export class OutletControlsComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  @ViewChild('fruitInput') fruitInput: any;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) outletTypeElement: any;
  outletTypeControl = new FormControl();
  controls: any;
  addressValue: any;
  filteredUsersOptions: any;
  incomingResult: any;
  incomingQuestion: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredOptions: Observable<any>;
  allOptions: any;
  optionCtrl = new FormControl();
  label: any;
  option: any;
  options: any = [];
  incomingOption: any;
  incomingSettings: any;
  incomingReference2: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public formService: FormService,
              public broadcastChannel: BroadcastChannelService) {
    this.outletTypeElement = MatAutocompleteTrigger;
    this.incomingResult = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingReference2 = new Parameters();
    this.fruitInput = ElementRef;
    this.incomingResult.setAttributes({});
  }

  ngOnInit(): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingResult.setAttributes(
     this.incomingQuestion.attributes.hasOwnProperty('result') ?
     this.incomingQuestion.getResult() : {});
    this.incomingSettings.setAttributes(
      this.incomingQuestion.attributes.hasOwnProperty('settings') ?
      this.incomingQuestion.getSettings() : {});
    this.incomingReference2.setAttributes(
      this.incomingQuestion.attributes.hasOwnProperty('reference2') ?
      this.incomingQuestion.getReference2() : {});
    this.incomingQuestion.setResult(this.incomingResult.getAttributes());
    this.incomingQuestion.setSettings(this.incomingSettings.getAttributes());
    this.incomingQuestion.setReference2(this.incomingReference2.getAttributes());
    this.incomingQuestion.setControls(this.incomingQuestion.attributes.hasOwnProperty('controls') ? this.incomingQuestion.getControls() : []);
    this.allOptions = this.incomingQuestion.getControls();
    this.controls = this.service.app.data.controls;
    this.addressValue = '';
    this.filteredUsersOptions = this.outletTypeControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterOutletType(name) : this.service.app.data.outlet_types.data.slice())
    );

    this.outletTypeControl.setValue(this.service.app.data.outlet_types.data[0]);
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
            startWith(null),
            map((optional: string | null) => optional ? this._filterOptions(optional) : this.allOptions.slice()));
  }

  handleOption(option: any){
    this.label = prompt('', option.label);
    if (this.label !== null) option = Object.assign(option, {label: this.label});
  }

  remove(option: any): void {
      const index = this.incomingQuestion.getControls().indexOf(option);
      if (index !== -1){
        this.incomingQuestion.getControls().splice(index, 1);
      }
  }

  openPanel(event: any){
    event.stopPropagation();
    this.outletTypeElement.openPanel();
  }

  private _filterOptions(value: any): any {
      const filterValue = value.toLowerCase();
      return this.allOptions.filter(fruit => fruit.label.toString().toLowerCase().includes(filterValue));
  }

  onOutletTypeSelectionChanged(event: MatAutocompleteSelectedEvent){

  }

  selected(event: MatAutocompleteSelectedEvent): void {
      this.options.push(event.option.viewValue);
      this.fruitInput.nativeElement.value = '';
      this.optionCtrl.setValue(null);
  }

  add(event: any): void {
      const value = (event.value || '').trim();
      // Add our filteredJudgmentCondition
      if (value) {
        this.incomingOption.setAttributes({});
        this.incomingOption.setLabel(value);
        this.incomingOption.setUnknown('cntrl_num', this.service.random());
        this.incomingOption.setQuestions([]);
        this.options.push(this.incomingOption.getAttributes());
      }

      // Clear the input value
      // event.chipInput!.clear();
      this.fruitInput.nativeElement.value = '';
      this.optionCtrl.setValue(null);
  }

  private _filterOutletType(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.outlet_types.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
    return value.name;
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult, label, attr) {
    label[attr] = result.formatted_address;
  }

  onChangeAddressText(event: any, label: any, name){
    label[name] = event.target.value;
  }

}
