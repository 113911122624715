<div [ngStyle]="{'margin-left':'20px'}">
  <div class="row">
    <div class="row tile_count" *ngIf="outgoingRequest.attributes.target.includes('regions') === false">
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Inspected animals </span>
        <div class="count">
          <fake>{{incomingResponse.getInspected() | number}}</fake>
          <span  style="height: 160px;">
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="charts.sparkline.inspected"
                      [(update)]="updateFlag">
                    </highcharts-chart>
                </span>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Condemned</span>
        <div class="count">
          <fake>{{incomingResponse.getCondemned() | number }}</fake>
        </div>
        <span >
                <highcharts-chart
                  [Highcharts]="Highcharts"
                  [options]="charts.sparkline.condemned"
                  [(update)]="updateFlag">
                </highcharts-chart>
            </span>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Passed</span>
        <div class="count">
          <fake>{{incomingResponse.getPassed() | number}}</fake>
        </div>
        <span>
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="charts.sparkline.passed"
                      [(update)]="updateFlag">
                    </highcharts-chart>
            </span>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Species </span>
        <div class="count">
          <fake>{{(app.data.species.data.length | number)}}</fake>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Slaughters</span>
        <div class="count">
          <fake>{{app.data.slaughters.data.length | number}}</fake>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
        <span class="count_top"><i class="fa fa-clock-o"></i> Skins</span>
        <div class="count">
          <fake>{{incomingResponse.getCondemned() | number }}</fake>
        </div>
        <span >
                <highcharts-chart
                  [Highcharts]="Highcharts"
                  [options]="charts.sparkline.condemned"
                  [(update)]="updateFlag">
                </highcharts-chart>
            </span>
      </div>

    </div>


    <div class="row" *ngIf="outgoingRequest.attributes.target.includes('regions') === false">
      <div class="col-md-12 col-sm-12">
        <div class="x_panel">
          <div class="x_title">
            <h2> <small> species overview </small></h2>
            <ul class="nav navbar-right panel_toolbox">
              <li><a class="collapse-link"><i class="fa fa-chevron-up"></i></a></li>
              <li><a class="close-link"><i class="fa fa-close"></i></a>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="x_content1">
            <div class="col-md-12 col-sm-12">
              <div id="ante"></div>
              <!-- <div mkMorrisJs="Bar" [options]="charts.morries.barchart.species.options" [data]='species'></div> -->
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="col-md-4 col-sm-4">
                <center><small>Inspected overall</small></center>
                <div class="demo-chart" echarts [options]="charts.echarts.piechart.species.inspected" [theme]="theme"></div>
              </div>
              <div class="col-md-4 col-sm-4">
                <center><small>Condemned overall</small></center>
                <div class="demo-chart" echarts [options]="charts.echarts.piechart.species.condemned" [theme]="theme"></div>
              </div>
              <div class="col-md-4 col-sm-4">
                <center><small>Passed overall</small></center>
                <div class="demo-chart" echarts [options]="charts.echarts.piechart.species.passed" [theme]="theme"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-8">
                <small>Rejected animals per diseases overall</small>
                <div id="ante-diseases"></div>
              </div>
              <div class="col-md-4 col-sm-4">
                <small>Condemned animals per diseases</small>
                <div echarts [options]="charts.echarts.piechart.diseases.condemned" [theme]="theme" class="demo-chart"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4">
                <small>Condemned animals per conditions</small>
                <div echarts [options]="charts.echarts.piechart.conditions.condemned" [theme]="theme" class="demo-chart"></div>
              </div>
              <div class="col-md-8 col-sm-8">
                <small>Conditions animals inspections results overall summary</small>
                <div id="ante-conditions"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <small>Carcasses overall</small>
                <div id="carcasses-post"></div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <center><small>Inspected carcasses overall</small></center>
                  <div echarts [options]="charts.echarts.piechart.carcasses.inspected" [theme]="theme" class="demo-chart"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <center><small>Condemned carcasses overall</small></center>
                  <div echarts [options]="charts.echarts.piechart.carcasses.condemned" [theme]="theme" class="demo-chart"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <center><small>Passed carcasses overall</small></center>
                  <div echarts [options]="charts.echarts.piechart.carcasses.passed" [theme]="theme" class="demo-chart"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-8">
                <small>Rejected carcasses per diseases overall</small>
                <div id="carcasses-diseases"></div>
              </div>
              <div class="col-md-4 col-sm-4">
                <small>Carcasses condemned per diseases</small>
                <div echarts [options]="charts.echarts.piechart.carcasses.diseases.condemned" [theme]="theme" class="demo-chart"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4">
                <small>Carcasses condemned per conditions overall</small>
                <div echarts [options]="charts.echarts.piechart.carcasses.conditions.condemned" [theme]="theme" class="demo-chart"></div>
              </div>
              <div class="col-md-8 col-sm-8">
                <small>Rejected carcasses per conditions overall</small>
                <div id="carcasses-conditions"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <small>Organs overall</small>
                <div id="organs-ante"></div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <center>Inspected organs overall</center>
                  <div echarts [options]="charts.echarts.piechart.organs.inspected" [theme]="theme" class="demo-chart"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <center>Condemned organs overall</center>
                  <div echarts [options]="charts.echarts.piechart.organs.condemned" [theme]="theme" class="demo-chart"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <center>Passed organs overall</center>
                  <div echarts [options]="charts.echarts.piechart.organs.passed" [theme]="theme" class="demo-chart"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 col-sm-8">
                  <small>Rejected organs per diseases overall </small>
                  <div id="organs-diseases"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <small>Organs condemned per diseases</small>
                  <div echarts [options]="charts.echarts.piechart.organs.diseases.condemned" [theme]="theme" class="demo-chart"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 col-sm-8">
                  <small>Rejected organs per conditions overall </small>
                  <div id="organs-conditions"></div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <small>Organs condemned per conditions</small>
                  <div echarts [options]="charts.echarts.piechart.organs.conditions.condemned" [theme]="theme" class="demo-chart"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="outgoingRequest.attributes.target.includes('regions') === true">
      <div class="x_panel"  [ngStyle]="{'background-color': '#ccffcc'}" *ngFor="let specie of species">
        <div class="x_titlex">
          <h2> <smallx> {{specie.name}} </smallx></h2>
          <ul class="nav navbar-right panel_toolbox">
            <li><img class="specieIcon" src="{{specie.icon}}" style="width:auto;height:60px"/></li>
          </ul>
        </div>
        <div class="x_content">
          <div class="row">
            <div class="col-md-6">
              <center>Ante mortem</center>
              <div class="col-md-12">
                <app-piechart [data]="{legend:['inspected','condemned','passed'],data: [{name:'inspected', value:specie.inspected},{name:'condemned', value:specie.condemned},{name:'passed', value:specie.passed}]}"></app-piechart>
<!--                <div echarts [options]="piechart(['inspected','condemned','passed'],[{name:'inspected', value:specie.inspected},{name:'condemned', value:specie.condemned},{name:'passed', value:specie.passed}])" [theme]="theme" class="demo-chart"></div>-->
              </div>
              <div class="col-md-12">
<!--                <div echarts [options]="piechart(getNames(alljudgmentConditions),getValues(alljudgmentConditions,'condemned'))" [theme]="theme" class="demo-chart"></div>-->
                <app-piechart [data]="{legend:getNames(diseases),data: getValues(diseases,'condemned')}"></app-piechart>
                <center>Diseases</center>
              </div>
              <div class="col-md-12">
                <app-piechart [data]="{legend:getNames(conditions),data: getValues(conditions,'condemned')}"></app-piechart>
                <!--                <div echarts [options]="piechart(getNames(conditions),getValues(conditions,'condemned'))" [theme]="theme" class="demo-chart"></div>-->
                <center>Conditions</center>
              </div>
            </div>
            <div class="col-md-6">
              <center>Post mortem</center>
              <div class="col-md-12">
                <div class="col-md-12">
                  <app-morris-barchart [data]="{labels: ['inspected','condemned','passed'], data: organs,element:'pastoralists'+specie.id}"></app-morris-barchart>
                </div>
                <div class="col-md-12">
                  <app-piechart [data]="{legend:getNames(organDiseases),data: getValues(organDiseases,'condemned')}"></app-piechart>
                  <center>Diseases</center>
                </div>
                <div class="col-md-12">
                  <app-piechart [data]="{legend:getNames(organConditions),data: getValues(organConditions,'condemned')}"></app-piechart>
                  <center>Conditions</center>
                </div>
                <div class="col-md-12">
                  <app-morris-barchart [data]="{labels: ['inspected','condemned','passed'], data: carcasses,element:'alljudgmentConditions'+specie.id}"></app-morris-barchart>
                </div>
                <div class="col-md-12">
                  <app-piechart [data]="{legend:getNames(carcassDiseases),data: getValues(carcassDiseases,'condemned')}"></app-piechart>
                  <center>Diseases</center>
                </div>
                <div class="col-md-12">
                  <app-piechart [data]="{legend:getNames(carcassConditions),data: getValues(carcassConditions,'condemned')}"></app-piechart>
                  <center>Conditions</center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--    <div class="row" *ngIf="outgoingRequest.attributes.target.includes('regions') === true">-->
  <!--      <div class="x_panel"  [ngStyle]="{'background-color': '#ccffcc'}">-->
  <!--        <div class="x_tittle">-->
  <!--          <div class="row tile_count">-->
  <!--            <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 " *ngFor="let specie of species">-->
  <!--              <div class="tile-stats">-->
  <!--                <div class="count">-->
  <!--                  {{specie.passed}}-->
  <!--                  <img class="specieIcon pull-right" src="{{specie.icon}}" style="width:auto;height:60px"/></div>-->
  <!--                <h3>{{specie.name}}</h3>-->
  <!--                <p>90% condemned</p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

</div>
