<div class="row" [hidden]="hideAddOthers()">
    <center>
        <!-- <div class="bubbleStyle sawasdee18" [ngStyle]="{'color':'#fff','max-width':'250px'}">
            <table>
                 <td>add other option as you know</td>
                 <td>
                     <i 
                      (click)="addOptional()"
                      [ngStyle]="{'margin-left':'10px'}"
                      class="material-icons">add_circle_outline</i>
                 </td>
            </table>
            
            
        </div> -->
        <a  mat-mini-fab
            class="sawasdee20"
            [ngStyle]="{'background-color':'#fff', 'color':'#5A738E'}"
            (click)="addOptional()">
            <!-- other -->
            <i class="material-icons"> add </i>
        </a>
    </center>
</div>
