<a class=""><center><h4>Add Question</h4></center></a>
<div class="container">
    <div class="row" style="margin-top: 10%">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-left-size':'50px','border-right-color':'red'}">
            <div class="panel-bodyy">
                <div class="row">
                    <mat-form-field  class="example-full-width" appearance='fill'>
                        <input type="text"
                               #controlElement
                               placeholder="Search for control e.g image, text, number.."
                               aria-label = "Number"
                               [(ngModel)]="search"
                               style="text-align: center; font-size: 20px"
                               matInput>
        <!--                   <mat-autocomplete #control = "matAutocomplete" (optionSelected)="onControlSelectionChanged($event)" [displayWith]="displayFn">-->
        <!--                        <mat-question *ngFor="let control of filteredControlsOptions | async; let controlOf = index" [value]= "control" >-->
        <!--                            <i class="material-icons" style="margin-right: 3px">{{control.icon}}</i>{{control.name}}-->
        <!--                        </mat-question>-->
        <!--                   </mat-autocomplete>-->
                        <h4 style="color: red; font-weight: normal"
                            *ngIf="service.isNull(controlElement.value) === false &&
                              ((filteredControlsOptions | async) | json).length === 2">
                            **---Not Found *---</h4>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="container">
                        <div class="col-md-2 col-sm-2"></div>
                        <div class="col-md-8 col-sm-8">
                            <section class="post-section blog-post" style="background-color: transparent !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-3 cursor" *ngFor="let control of (controls | jsonFilter: search)"
                                             style="cursor: pointer"
                                             (click)="onSelectControl(control)"
                                             title="{{control.name}}">
                                            <div class="post-box">
                                                <div class="text-box">
<!--                                                <h4>-->
<!--                                                  <a>{{campaign.name}}</a>-->
<!--                                                </h4>-->
                                                    <p>
                                                        <i class="fa fa-bell cursor" title="{{control.name}}">
                                                          <!--{{control.icon}}-->
                                                        </i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-2 col-sm-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
    <!--    <span *ngFor="let question of selectedOptional.questions" class="btn btn-xs defaultColor" [innerHTML]="question.label"></span>-->
    </div>
</div>
