import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";
import {Parameters} from "../../parameters";
import {FormService} from "../../forms/form.service";

@Component({
  selector: 'app-download-qrcode',
  templateUrl: './download-qrcode.component.html',
  styleUrls: ['./download-qrcode.component.scss']
})
export class DownloadQrcodeComponent implements OnInit {
  responses: any = [];
  outgoingRequest: any;
  incomingForm: any;
  constructor(public service: AppService,
              public formService: FormService) {
   this.outgoingRequest = new Parameters();
   this.incomingForm = new Parameters();
   this.incomingForm.setAttributes(this.formService.getSelected());
  }

  ngOnInit(): void {
   if(this.responses.length === 0){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setFormId(this.incomingForm.getId());

   }
  }

}
