import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-textarea-controls',
  templateUrl: './textarea-controls.component.html',
  styleUrls: ['./textarea-controls.component.scss']
})
export class TextareaControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  label: any;
  controls: any;
  constructor(private metthodsService: AppService) { }
  ngOnInit(): void {
    this.controls = this.metthodsService.app.data.controls;
  }

}
