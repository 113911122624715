<table id="datatable"
       class="table table-striped table-bordered"
       style="width:100%"
       [hidden]="(flag==='all' && responses.data.length === 0) ||
                 (flag==='restore' && responses.trashed.data.length === 0) ">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2"></th>
    <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">
      <th class="mdl-data-table__cell--non-numeric headline2">Outlet</th>
      <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
      <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
      <th class="mdl-data-table__cell--non-numeric headline2">Category</th>
    </ng-container>
    <th class="mdl-data-table__cell--non-numeric headline2"
        *ngFor="let responseResult of (responses.data.length === 0 ? [] : responses.data[0].datas)">
      <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">
        <span>Invitation card</span>
      </ng-container>
      <ng-template #qrcode>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else advert">
          <span>Qrcode</span>
        </ng-container>
      </ng-template>
      <ng-template #advert>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else description">
          <span></span>
        </ng-container>
      </ng-template>
      <ng-template #description>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else content">
          <span></span>
        </ng-container>
      </ng-template>
      <ng-template #content>
        <span title="{{service.removeHtmlTags(responseResult.formControl.label)}}"
              [innerHTML]="service.limitTo((responseResult.formControl.label.toString()), 600000)">
        </span>
      </ng-template>
    </th>
    <th class="mdl-data-table__cell--non-numeric headline2">Headline</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Progress</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Posted by</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Team</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Lat</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Lng</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Altitude</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Date</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Starting At</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Ending At</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Published</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let response of (flag.includes('all') ? (responses.data | jsonFilter:searchCntrl) : (responses.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="contextmenuFlag = 0;incomingResponseSelected.setAttributes(response);responseSelected = response;onContextMenu($event, response)"
      (mousedown)="responseSelected = response">
    <td class="mdl-data-table__cell--non-numeric headline2">{{response.reference2}}</td>
    <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">
      <ng-container *ngIf="response.hasOwnProperty('outlet')">
        <td class="mdl-data-table__cell--non-numeric headline2">{{response.outlet.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{response.outlet.address}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{response.outlet.phone}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">{{response.outlet.category.name}}</td>
      </ng-container>
      <ng-container *ngIf="!response.hasOwnProperty('outlet')">
        <td class="mdl-data-table__cell--non-numeric headline2"></td>
        <td class="mdl-data-table__cell--non-numeric headline2"></td>
        <td class="mdl-data-table__cell--non-numeric headline2"></td>
        <td class="mdl-data-table__cell--non-numeric headline2"></td>
      </ng-container>
    </ng-container>
    <td title="{{service.removeHtmlTags(responseResult.formControl.label)}}"
        class="mdl-data-table__cell--non-numeric headline2"
        *ngFor="let responseResult of response.datas; let responseResultIncrease = index">
      <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">
        <div [innerHTML]="responseResult.data|safeUrl"></div>
      </ng-container>
      <ng-template #qrcode>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else photo">
          <ng-container>
            <div id="qrcode{{response.id}}"
                 [ngStyle]="{'width':'255px','height':'255px'}"
                 (click)="incomingResponseResultSelected.setAttributes(responseResult);
                  incomingResponseSelected.setAttributes(response);
                  contentId = ('qrcode' + response.id);
                  contextmenuFlag = 1;
                  onContextMenu($event, {})">
              <ngx-qrcode-styling
                      #qrcode
                      [type]="'canvas'"
                      [shape]="'square'"
                      [width]="250"
                      [height]="250"
                      [margin]="0"
                      [data]="getQrcodeUrlByResponseResult(response, responseResult)"
              >
              </ngx-qrcode-styling>
            </div>

          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #photo>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 2; else description">
          <img [src]="responseResult.data"
               [ngStyle]="{'max-width':'100px','max-height':'100px'}"/>
        </ng-container>
      </ng-template>
      <ng-template #description>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else checkbox">
          <mat-spinner color="warn" class="pull-left" *ngIf="responseResult.formControl.label.includes('*qrcode') || (responseResult.hasOwnProperty('matSpinner'))"
                       [diameter]="20"></mat-spinner>
          <div id = "{{responseResult.hasOwnProperty('cntrl_num') ? responseResult.cntrl_num : responseResult.id}}"
               [innerHTML]="getQuestionlabel(response, responseResult)|safeUrl"
               (click)="incomingResponseResultSelected.setAttributes(responseResult);incomingResponseSelected.setAttributes(response);contentId = (responseResult.hasOwnProperty('cntrl_num') ? responseResult.cntrl_num : responseResult.id); contextmenuFlag = 1; onContextMenu($event, {})"></div>
        </ng-container>
      </ng-template>
      <ng-template #checkbox>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 3; else advert">
          <div>
            <center>
              <table>
                <tr *ngFor="let option of responseResult.formControl.options">
                  <td>
                    <mat-checkbox
                            #checked
                            [checked]="!service.isNull(responseResult.result) && responseResult.result.toString().includes(option.cntrl_num)"
                            [value]="option"
                    >
                        <span class="headline2">
                          {{service.upperStartingCharacter(option.label, true)}}
                        </span>
                    </mat-checkbox>
                  </td>
                </tr>
              </table>
            </center>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #advert>
        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else content">
          <div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #content>
          <div [innerHTML]="responseResult.data|safeUrl"
               #htmlContents
               (keyup.enter)="responseResult.data = htmlContents.innerText;onContentEditableTextChange(responseResult,htmlContents.innerText)"
               [contentEditable]="(
               this.service.parseFloat(responseResult.formControl[strings.getControlId()]) === 1 ||
               this.service.parseFloat(responseResult.formControl[strings.getControlId()]) === 10 ||
               this.service.parseFloat(responseResult.formControl[strings.getControlId()]) === 23)  ? true : false"></div>
      </ng-template>
    </td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.title)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.progress)}}%</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{response.postedBy.name ? response.postedBy.name : ''}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{response.postedBy.team ? response.postedBy.team.name : ''}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.address)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.lat)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.lng)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.altitude)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{response.date}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.starting_at)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{(response.ending_at)}}</td>
    <td class="mdl-data-table__cell--non-numeric headline2">{{response.created_at}}</td>
    <td>
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs"
                type="button"
                data-toggle="dropdown">
            <i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="print(form)">Print</li>
          <ng-container *ngIf='service.parseFloat(incomingForm.getCategoryId()) === 4'>
            <li mat-menu-item *ngIf="flag === 'all'"   (click)="sendInvitationThroughtEmail(response)">Send invitation</li>
            <li mat-menu-item *ngIf="flag === 'all'"       (click)="sendInvitationThroughtWhatsapp(response)">Whatsapp</li>
          </ng-container>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(response)">Edit</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(response)">Delete</li>
          <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(response)">Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu" class='sawasdee20'>
        <ng-template matMenuContent let-item="inspector" >
          <ng-container *ngIf="contextmenuFlag === 0">
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="print(form)">Print</button>
                        <ng-container *ngIf='service.parseFloat(incomingForm.getCategoryId()) === 4'>
                          <buton  mat-menu-item *ngIf="flag === 'all'"   (click)="sendInvitationThroughtEmail(response)">Email invitation </buton>
                          <buton  mat-menu-item *ngIf="flag === 'all'"   (click)="sendInvitationThroughtWhatsapp(response)">Send Invitation through Whatsapp</buton>
                        </ng-container>
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(response)">View</button>
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="sendInvitationCard(response)">Send invitation card</button>
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="sendInvitation()">Send Invitation</button>
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="chatbot()">Chatbot</button>
                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(response)">Delete</button>
                        <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(response)">Restore</button>
          </ng-container>
          <ng-container *ngIf="contextmenuFlag === 1">
            <button mat-menu-item  (click)="downloadByContentId()">Download</button>
            <button mat-menu-item  (click)="sendInvitation2()">Send invitation</button>
          </ng-container>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>

<input type="file"
       id="file"
       (change)="readFile($event)"
       [ngStyle]="{'position':'absolute','left':'-300px'}"
       #file>
<div id="form"> </div>

