<!--<mat-tab-group>-->
<!--  <mat-tab label="All({{-->
<!--  (service.app.data.forms.data |-->
<!--      jsonPropertyFilter: service.status.toString() : 'status' |-->
<!--      jsonPropertyFilter: formService.categoryId.toString() : 'category_id' |-->
<!--      jsonPropertyFilter: formService.campaignId.toString() : 'campaign_id'-->
<!--  ).length-->
<!--}})">-->
<!--    <br><br><app-search ></app-search><br><br>-->
<!--    <app-table-forms [flag]="'all'"></app-table-forms>-->
<!--  </mat-tab>-->
<!--&lt;!&ndash;  <mat-tab label="Deleted({{forms.trashed.data.length}})">&ndash;&gt;-->
<!--&lt;!&ndash;    <br><br><app-search></app-search><br><br>&ndash;&gt;-->
<!--&lt;!&ndash;    <app-table-forms [flag]="'restore'"></app-table-forms>&ndash;&gt;-->
<!--&lt;!&ndash;  </mat-tab>&ndash;&gt;-->
<!--</mat-tab-group>-->

xscjksnskdfnskjfndskfjdsnfkjds