<mat-tab-group [(selectedIndex)]="selectedTabIndex"
               [ngStyle]="{'position':'fixed','top':'-100px'}">
    <mat-tab>
<!--        <div *ngIf="progressShow">-->
<!--            <dv [ngStyle]="{'width':'100%'}" *ngIf="this.responseService.isConfirmed===false">-->
<!--            </dv>-->
<!--        </div>-->
        <div [ngStyle]="{'z-index':'999999999999','position':'fixed','width': service.getWidth()+'px', 'height': service.getHeight()+'px', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}"></div>
        <img [ngStyle]="{'position':'fixed','top':'0px','width': service.getWidth()+'px', 'height': service.getHeight()+'px','display':'block', 'object-fit': 'cover'}"
             src="{{incomingSettingsForm.getBanner()}}"/>
        <div ngClass="sawasdee"
             [ngStyle]="{'position':'absolute','top':'0%','height':this.service.getWidth()+'px','width':this.service.getHeight()+'px','margin-top':'10px','margin-bottom':'10px'}">
            <div class="vertical-center"
                 [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}">
                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div ngClass="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <center>
                        <table>
                            <ng-container *ngIf="progressShow">
                                <tr>
                                    <td class="sawasdee16">
                                        <center>
                                            <div class="mitra">
                                                <mat-progress-bar
                                                        color="warn"
                                                        mode="query" [ngStyle]="{'width':'100%'}"></mat-progress-bar>
                                                {{this.service.getNavigatedUrl().toString().includes('whatsapp') ?
                                                this.service.upperStartingCharacter(this.service.app.strings.invitation.card.whatsapp.send, true) :
                                                this.service.upperStartingCharacter(this.service.app.strings.invitation.card.send, true)}}
                                            </div>
                                        </center>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="sawasdee26">
                                        <center>
                                            <div id="doc" [ngStyle]="{'position':'fixed','left':-service.getWidth()+'px'}">
                                                <div [innerHTML]="cardContents | safeUrl"></div>
                                            </div>
                                        </center>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!progressShow">
                                <tr>
                                    <td>
                                        <div class="sawasdee16">
                                            {{this.service.getNavigatedUrl().toString().includes('whatsapp') ?
                                            this.service.upperStartingCharacter(this.service.app.strings.invitation.card.whatsapp.delivery, true) :
                                            this.service.app.strings.invitation.card.delivery }}
<!--                                        <i class="material-icons">done</i>-->
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <center>
                                            <div class="sawasdee18">
                                                <div [innerHTML]="cardContents | safeUrl"></div>
                                            </div>
                                        </center>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <center>
                                            <img src="{{logo.path}}"
                                                 class="img-thumbnail mdl-shadow--2dp"
                                                 *ngFor="let logo of incomingForm.getAttributes().settings.logo"
                                                 [ngStyle]="{'width':'100px', 'height':'100px', 'margin-left': '10px'}"
                                                 alt="{{logo.caption}}">
                                        </center>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </center>
                </div>
                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
            </div>
        </div>
    </mat-tab>
    <mat-tab>

    </mat-tab>
</mat-tab-group>

<!--<mat-drawer-container ngClass="example-container"-->
<!--                      [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">-->
<!--    <mat-drawer #drawer ngClass="example-sidenav" mode="side"-->
<!--                [ngStyle]="{'width':'100%','height':'100%'}">-->
<!--    </mat-drawer>-->
<!--    <div ngClass="example-sidenav-content"-->
<!--         [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">-->
<!--        -->
<!--    </div>-->
<!--</mat-drawer-container>-->
