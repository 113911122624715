<a class="pull-left"><h4>{{selectedOptional.label}}</h4></a>

<div class="row">
    <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-left-size':'50px','border-right-color':'red'}">
        <div class="panel-bodyy">
            <div>
                <mat-form-field  class="example-full-width" appearance='fill'>
                    <mat-label>Add Question</mat-label>
                    <input type="text"
                           #controlElement
                           placeholder=""
                           aria-label = "Number"
                           [(ngModel)]="search"
                           matInput
                           [formControl] = "controlControl"
                           [matAutocomplete] = "control">
                    <mat-autocomplete #control = "matAutocomplete" (optionSelected)="onControlSelectionChanged($event)" [displayWith]="displayFn">
                        <mat-option *ngFor="let control of filteredControlsOptions | async; let controlOf = index" [value]= "control" >
                            <i class="material-icons" style="margin-right: 3px">{{control.icon}}</i>{{control.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.isNull(controlElement.value) === false &&
                          ((filteredControlsOptions | async) | json).length === 2">
                        **---Not Found *---</h4>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <span *ngFor="let question of selectedOptional.questions" class="btn btn-xs defaultColor" [innerHTML]="question.label"></span>
</div>
