<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <center>
            <app-label-create-control [question]="question"></app-label-create-control>
        </center>
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','margin-top':'30px'}">
            <div class="panel-body">
                <div>
                    <i class="material-icons {{l.checked === 0 || question.data.toString() === '0' ? '' : 'star'}}"
                       [ngStyle]="{'cursor':'pointer'}"
                       (click)="handleStar(l)"
                       *ngFor="let l of iterator">
                        grade
                    </i>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
