<div class="row">
  <center>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="addControl()">add</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="upstairs()">arrow_upward</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="downstairs()">arrow_downward</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="close()">close</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="settings()">settings</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (click)="lock()" *ngIf="question.control.id === 5 || question.control.id === 18">lock</i></span>
    <span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}"><mat-checkbox [checked]="question.visible !== 1"></mat-checkbox></i></span>
    <!--<span><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}" (contextmenu)="onContextMenu($event, question)">settings</i></span>-->
    <span title="Required"><i class="material-icons" [ngStyle]="{'color':'#888','cursor':'pointer'}"><mat-slide-toggle [ngStyle]="{'color':'#888','cursor':'pointer','margin-left':'10px'}" class="example-margin" (change) ="onToggleChange($event, checked)" [checked]="checked"> </mat-slide-toggle></i></span>
    <span>
      <mat-form-field  class="example-full-widthh">
          <input matInput type="text" [(ngModel)]="question.position" placeholder="">
      </mat-form-field>
    </span>
  </center>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="inspector">
<!--    <mat-accordion [multi]="true">-->
<!--      <mat-expansion-panel [expanded]="true">-->
<!--        <mat-expansion-panel-header>-->
<!--          <mat-panel-title>-->
<!--            <h4>Allow Mutiple Choice</h4>-->
<!--          </mat-panel-title>-->
<!--          <mat-panel-description>-->
<!--          </mat-panel-description>-->
<!--        </mat-expansion-panel-header>-->
<!--        <div>-->
<!--          <br><br>-->
<!--          <mat-radio-group-->
<!--                  aria-labelledby="example-radio-group-label"-->
<!--                  class="example-radio-group"-->
<!--                  >-->
<!--            <mat-radio-button-->
<!--                    #checked-->
<!--                    (change)="radioChange($event, question)"-->
<!--                    class="example-radio-buttonn helvetica"-->
<!--                    [value]="1">-->
<!--              <span style="">Yes</span>-->
<!--            </mat-radio-button>-->
<!--            <mat-radio-button-->
<!--                    #checked-->
<!--                    (change)="radioChange($event, question)"-->
<!--                    class="example-radio-buttonn helvetica"-->
<!--                    [value]="1">-->
<!--              <span style="">Yes</span>-->
<!--            </mat-radio-button>-->
<!--          </mat-radio-group>-->
<!--        </div>-->
<!--      </mat-expansion-panel>-->
<!--    </mat-accordion>-->
  </ng-template>
</mat-menu>
