<mat-tab-group>
  <mat-tab label="All ({{(regionals.data | jsonFilter: searchCntrl).length}})">
    <br><br><app-search ></app-search><br><br>
    <app-table-regional [flag] ="'all'"></app-table-regional>
  </mat-tab>
  <mat-tab label="Deleted ({{(regionals.trashed.data | jsonFilter: searchCntrl).length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-regional  [flag]="'restore'"></app-table-regional>
  </mat-tab>
</mat-tab-group>


