import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import * as moment from 'moment';
import {DialogClientsComponent} from '../../../clients/dialog-clients.component';
import {CampaignService} from '../../../campaigns/campaign.service';
import {OutletService} from '../../../outlets/outlet.service';
import {CampanyService} from "../../../campanies/campany.service";
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {ClientService} from "../../../clients/client.service";
@Component({
  selector: 'app-dialog-view-outlets',
  templateUrl: './dialog-view-outlets.component.html',
  styleUrls: ['./dialog-view-outlets.component.scss']
})
export class DialogViewOutletsComponent implements OnInit, OnDestroy {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  clientControl = new FormControl();
  campanyControl = new FormControl();
  filteredClientsOptions: any;
  filteredCampaniesOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedCampaign: any;
  keys: any;
  selectedCampaign: any;
  addressValue: any;
  logo: any;
  startingAt: any;
  endingAt: any;
  clients: any;
  position: any;
  subscription: any;
  incomingCampany: any;
  incomingClient: any;
  incomingOutletSelected: any;
  incomingAddress: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              private campanyService: CampanyService,
              public clienService: ClientService,
              public broadcastChannel: BroadcastChannelService,
              public outletService: OutletService,
              public campaignService: CampaignService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogViewOutletsComponent>) {
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingAddress = new Parameters();
    this.incomingOutletSelected = new Parameters();
    this.incomingCampany.setAttributes({});
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.incomingOutletSelected.setAttributes(outletService.selected);
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedCampaign = new Parameters();
    this.incomingSelectedCampaign.setAttributes(campaignService.selected);
    this.selectedCampaign = campaignService.selected;
    this.keys = Object.keys(this.selectedCampaign).length;
    this.startingAt = new Date();
    this.endingAt = new Date();
    this.clients = this.app.data.clients.data;
    this.incomingClient.setAttributes({});
    this.incomingAddress.setAttributes({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('client')) {
            this.clientControl.setValue(item.client);
            this.formValues.controls['client_id'].setValue(item.client.id);
          }else if (item.hasOwnProperty('campany')) {
            this.campanyControl.setValue(item.campany);
            this.formValues.controls['campany_id'].setValue(item.campany.id);
          }
        }

      });

    this.dialogRef.afterClosed().subscribe(result => {
      this.campaignService.setSelected({});
      this.subscription.unsubscribe();
    });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:             ['' , Validators.compose([])],
      outlet_type_id:   ['', Validators.compose([])],
      address:          ['', Validators.compose([])],
      address1:         ['', Validators.compose([])],
      address2:         ['', Validators.compose([])],
      address3:         ['', Validators.compose([])],
      address4:         ['', Validators.compose([])],
      address5:         ['', Validators.compose([])],
      lat:              ['', Validators.compose([])],
      lng:              ['', Validators.compose([])],
      landmark:         ['', Validators.compose([])],
      owner:            ['', Validators.compose([])],
      email:            ['', Validators.compose([])],
      opened_at:        ['', Validators.compose([])],
      closed_at:        ['', Validators.compose([])],
      phoone:           ['', Validators.compose([])],
      description:      ['', Validators.compose([])],
      cntrl_num:   [this.service.random(), Validators.compose([])],
    });

    if(this.incomingOutletSelected.getAttributes().hasOwnProperty('id')) {
      for(var key in this.incomingOutletSelected.getAttributes()) {
        if(this.incomingOutletSelected.attributes[key] instanceof Object || this.incomingOutletSelected.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingOutletSelected.attributes[key]);
          }else {
            if(this.incomingOutletSelected.attributes[key] instanceof Object || this.incomingOutletSelected.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingOutletSelected.attributes[key], Validators.compose([])));
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.filteredClientsOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterClient(name) : this.app.data.outlets.types.slice())
      );

      this.filteredCampaniesOptions = this.campanyControl.valueChanges.pipe(
          startWith(),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterCampany(name) : this.app.data.campanies.data.slice())
      );

      if(this.incomingOutletSelected.getAttributes().hasOwnProperty('id')){
        this.addressValue = this.incomingOutletSelected.getAddress();
        this.clientControl.setValue(this.incomingOutletSelected.getAttributes());
      }
    });
  }

  onAddressTextChange(event: any){
    this.formValues.controls['address'].setValue(event.target.value);
//     this.formValues.controls['lat'].setValue(event.target.value);
//     this.formValues.controls['lat'].setValue(event.target.value);
  }


  public handleAddressChange(address: Address) {
    console.log(address);
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['address'].setValue(result.formatted_address);
  }


  onLocationSelected(location: any) {
    this.formValues.controls['lat'].setValue(location.latitude);
    this.formValues.controls['lng'].setValue(location.longitude);
    let latDoc: any = document.getElementById('lat');
    let lngDoc: any = document.getElementById('lng');
    latDoc.value = location.latitude;
    lngDoc.value = location.longitude;
    this.outletService.getAddress(location.latitude,location.longitude,(result: any) =>{
     this.incomingAddress.setAttributes(result);
     this.formValues.controls['address1'].setValue(this.incomingAddress.getAttributes().address1.place_id);
     this.formValues.controls['address2'].setValue(this.incomingAddress.getAttributes().address2.place_id);
     this.formValues.controls['address3'].setValue(this.incomingAddress.getAttributes().address3.place_id);
     this.formValues.controls['address4'].setValue(this.incomingAddress.getAttributes().address4.place_id);
    });
  }

//  onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//  }

  handlEditorFocus(){
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.service.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }


  onSelectStartingAt(event: any){
    this.endingAt = event > this.endingAt ? event : this.endingAt;
    this.startingAt = event;
    this.formValues.controls['starting_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  onSelectEndingAt(event: any){
    this.startingAt = event < this.startingAt ? new Date(event) : this.startingAt;
    this.endingAt = event;
    this.formValues.controls['ending_at'].setValue(moment(new Date(event)).format('MM/DD/YYYY'));
  }

  onChangeClientText(event){
    this.formValues.controls['outlet_type_id'].setValue('');
  }




  private _filterClient(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.app.data.outlets.types.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  handleAddressChanger(result: any){
  }

//handleAddressChange(result: PlaceResult){
//}


  onClientSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['outlet_type_id'].setValue(event.option.value.id);
  }

  private _filterCampany(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.app.data.campanies.data.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  displayFn(value: any){
    return value.name;
  }

  onCampanySelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['campany_id'].setValue(event.option.value.id);
  }

  onSubmit(campanyForm: any) {
    this.service.httpService(
      'post',
      this.keys === 0 ? '/campaigns' : '/campaigns/' + this.incomingSelectedCampaign.getId() + '/update',
      campanyForm, {},
      (response: any) => {
        this.service.status = 1;
        if(this.keys === 0)  this.service.app.data.campaigns.data.unshift(response);
        else this.selectedCampaign = Object.assign(this.selectedCampaign, response);
        this.selectedCampaign = {};
        this.broadcastChannel.emitNavChangeEvent({campaign: response});
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'opened' : 'updated' ));
        this.closeDialog();
        campanyForm = Object.assign(campanyForm, {campaign_id: response.id});
        this.service.httpService('post', '/campany-campaigns', campanyForm, {ignoreLoadingBar: 'true', notify: 'ignore'}, (resp: any) => {
        }, (error: any) => {});
        }, (error: any) => {
      });
  }

}
