import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-radar-chartjs',
  templateUrl: './radar-chartjs.component.html',
  styleUrls: ['./radar-chartjs.component.scss']
})
export class RadarChartjsComponent implements OnInit {
  @Input() element: any;
  @Input() color: any;
  @Input() labels: any;
  @Input() d1: any;
  @Input() d2: any;
  @Input() label1: any;
  @Input() label2: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() grouped: any;
  @Input() fill: any;


  transparentColor: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
  }

  ngOnInit(): void {

  }

  getColor(){
    if(this.color.toString() === 'red') this.color = '#ec250d';
    if(this.color.toString() === 'blue') this.color = 'rgb(82, 136, 255)';
    this.color = this.color.toString() === 'green' ? '#00d6b4' : this.color;
    return this.color;
  }
  // '#1f8ef1'
  getTransparentColor(){
    if(this.color.toString() === 'red')  this.transparentColor = 'rgba(233,32,16,0.2)';
    if(this.color.toString() === 'blue') this.transparentColor = this.type === 'line' ? 'rgba(29,140,248,0.2)' : 'rgb(82, 136, 255)';
    this.transparentColor = this.color.toString() === 'green' ? '#96CA59' : this.transparentColor;
    return this.transparentColor;
  }

  getLabels(){
    this.labels = this.labels.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString(), 3).toUpperCase().replace('..', '') :
          this.service.limitTo(label.toString().toUpperCase(), this.limit)); });
    return this.labels;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      const gradientBarChartConfiguration: any = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },

        tooltips: {
          backgroundColor: '#f5f5f5',
          titleFontColor: '#333',
          bodyFontColor: '#666',
          bodySpacing: 4,
          xPadding: 12,
          mode: 'nearest',
          intersect: 0,
          position: 'nearest'
        },
        responsive: true,
        scales: {
          yAxes: [{
            display: false,
            gridLines: {
              drawBorder: true,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 120,
              padding: 20,
              fontColor: '#9e9e9e'
            }
          }],

          xAxes: [{
            gridLines: {
              drawBorder: false,
              color: this.getTransparentColor(),
              zeroLineColor: 'transparent',
            },
            ticks: {
              padding: 20,
              fontColor: '#5f6368',
              fontFamily: 'Google Sans',
              fontSize: 10,
            }
          }]
        }
      };
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke: any = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, this.getTransparentColor());
      gradientStroke.addColorStop(0.4, this.getTransparentColor());
      gradientStroke.addColorStop(0, this.getTransparentColor()); //blue colors
      const opt: any = {
        type: this.type,
        responsive: true,
        legend: {
          display: false,
          show: false
        },
        data: {
          labels: this.getLabels(),
          datasets: this.getDatasets()
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          title: {
            display: false,
            text: ''
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              right: 10,
              fontFamily: 'Google Sans',
              left: 10
            }
          },
          scale: {
            ticks: {
              beginAtZero: true,
              fontColor: '#5f6368',
              fontSize: 12,
              fontFamily: 'Google Sans',
              // stepSize: 10,
              // max: 60
            }
          },
          tooltips: {
            titleFontColor: '#888',
            bodyFontColor: '#555',
            titleFontSize: 12,
            bodyFontSize: 14,
            backgroundColor: 'rgba(256,256,256,0.95)',
            displayColors: true,
            borderColor: 'rgba(220, 220, 220, 0.9)',
            borderWidth: 2
          }
        }
      };

      const myChart: any = new Chart(this.ctx, opt);
    });
  }

  getDatasets(){
    let result: any = [];
    if(this.d1 === undefined){}else{
      result.push( {
        label: this.label1,
        backgroundColor: 'rgba(76,132,255,0.2)',
        borderColor: 'rgb(82, 136, 255)',
        pointBorderWidth: 0,
        pointRadius: 0,
        pointBorderColor: 'rgb(82, 136, 255)',
        pointBackgroundColor: '#ffffff',
        data: this.d1
      });
    }

    if(this.d2 === undefined){}else{
      result.push({
        label: this.label2,
        backgroundColor: 'rgba(41, 204, 151, 0.2)',
        borderColor: '#29cc97',
        pointBorderWidth: 0,
        pointRadius: 0,
        pointBorderColor: '#29cc97',
        pointBackgroundColor: '#ffffff',
        data: this.d2
      });
    }
    return result;
  }


  ngAfterContentInit(): void {
  }


}
