<div class="row plist">
    <div class="" style="width: 100%; z-index: 99999999">
        <table [hidden]="false"
               [ngStyle]="{'background-color':'transparent','z-index': '999999999','width':'100%'}">
            <tr>
                <td valign="top" >
                    <div [ngStyle]="{'padding':'0px','margin-top':'0px','border-radius': '20px','background':'#fff'}"
                         class="pull-right col-lg-2 col-md-2 col-sm-12 col-xs-12 mdl-shadow--2dp">
                        <table [ngStyle]="{'width':'100%','margin':'0px'}">
                            <td valign="middle" [ngStyle]="{'width':'100%'}">
                                <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"
                                        autocomplete="off"
                                        ngClass="sawasdee18 searchbar"
                                        #searchId
                                        [(ngModel)]="service.searchText"
                                        (keyup.enter)="search(searchId.value);"
                                        placeholder="">
                            </td>
                            <!--                    <td valign="middle">-->
                            <!--                        <mat-icon matSuffix >settings</mat-icon>-->
                            <!--                    </td>-->
                            <td>
                                <mat-spinner
                                        *ngIf="this.service.matSpinner"
                                        class=""
                                        color='warn'
                                        [diameter]="20">
                                </mat-spinner>
                            </td>
<!--                            <td valign="middle" class="cursor">-->
<!--                                <mat-icon matSuffix>reorder</mat-icon>-->
<!--                            </td>-->
                            <td >
                                <img class="img-circle mdl-shadow--2dp"
                                     [src]="service.logo"
                                     [ngStyle]="{'width':'30px','height':'30px','margin-top': '0px'}">
                            </td>
                            <!--                            <td valign="middle">-->
                            <!--                                <mat-icon matSuffix >search</mat-icon>-->
                            <!--                            </td>-->
                        </table>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <center>
        <section class="post-section blog-post" [ngStyle]="{'background-color': 'transparent'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 cursor "
                         [id]="form.id"
                         (click)="onSelectedForm(form)"
                         *ngFor="let form of getFormsBySearchText(service.searchText)">
                        <div class="post-box" [ngStyle]="{'height':'auto', 'background-color': (this.service.parseFloat(form.status) === 0 ? 'transparent' : 'transparent')}">
<!--                            <div class="frame" *ngIf="form.settings.logo.length !== 0">-->
<!--                                <a>-->
<!--                                    <img ngClass=""-->
<!--                                         [src]="form.settings.logo[0].src" alt="img">-->
<!--                                </a>-->
<!--                            </div>-->
                            <div class="text-box">
                                <p>
                                    <mat-spinner color="warn"
                                                 ngClass="pull-right"
                                                 *ngIf="this.service.parseFloat(form.status) === 1"
                                                 [diameter]="20">
                                    </mat-spinner>
                                </p>
                                <h4>
                                    <a>
                                        <ul class="list-inline">
<!--                                            <li *ngIf="form.settings.logo.length !== 0"-->
<!--                                                style="vertical-align: top">-->
<!--                                                <img [ngStyle]="{'max-width':'40px', 'max-height':'40px'}"-->
<!--                                                     class="img-thumbnailx"-->
<!--                                                     [src]="form.settings.logo[0].src" alt="img">-->
<!--                                            </li>-->
                                            <li style="vertical-align: top">{{form.name}}</li>
                                        </ul>
                                    </a>
                                </h4>
                                <p class="text-left">
                                    <img [ngStyle]="{'max-width':'20px', 'max-height':'20px','float':'left'}"
                                         *ngIf="form.settings.logo.length !== 0 && !this.service.isNull(form.description)"
                                         class="img-circle"
                                         [src]="form.settings.logo[0].src" alt="img">
                                    {{service.limitTo(service.removeHtmlTags(form.description), 200)}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div [ngStyle]="{'margin-top':'5px'}"
             ngClass="cursor">
            <center>
                <i class="material-icons"
                   (click)="requestFormAndlimit(10)">keyboard_arrow_down</i>
            </center>
        </div>
    </center>
</div>
