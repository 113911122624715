import { Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {AppService} from "../../app.service";
import {Router} from "@angular/router";
import {CampaignService} from "../campaign.service";
import {FormService} from "../../forms/form.service";
import {ClientService} from "../../clients/client.service";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {Parameters} from '../../parameters';
@Component({
  selector: 'app-list-campaign',
  templateUrl: './list-campaign.component.html',
  styleUrls: ['./list-campaign.component.scss']
})
export class ListCampaignComponent implements OnInit, AfterViewInit {
 @ViewChild('searchElement', {static: false})  searchElement: any;
  search: any;
  campaign: any;
  selectedTabIndex: any = 0;
  subscription: any;
  form: any;
  searchIcon: any = false;
  searchInput: any = true;
  incomingCampaignSelected: any;
  position: any;
  incomingUser: any;
  constructor(private router: Router,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService,
              private clientService: ClientService,
              private campaignService: CampaignService,
              public service: AppService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.incomingCampaignSelected = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingCampaignSelected.setAttributes({});
    this.searchElement = ElementRef;
  }

  ngOnInit(): void {
      this.incomingUser.setAttributes(this.service.app.data.user);
      if(parseFloat(this.incomingUser.getAttributes().role.id) === 2){
          if(parseFloat(this.service.app.data.forms.data.length) > 0){
              this.onSelectedForm(this.service.app.data.forms.data[this.service.app.data.forms.data.length - 1]);
          }
      }
      this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
          .subscribe((item: any) => {
              if(item instanceof Object){
                  if (item.hasOwnProperty('campaign') === true) {
                      this.campaignService.campaignSelectedId = item.campaign.id;
                  }else if(item.hasOwnProperty('form') === true){
                      this.form = item.form;
                      this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.form.id) + '/dashboard');
                  }
              }
          });
  }

  ngAfterViewInit(): void {
  }

  getFormByForm(form: any){
      let campaign: any = this.campaignService.getCampaignById(form.campaign_id);
      let client: any = this.clientService.getClientById(campaign.client_id);
      campaign = Object.assign(campaign, {client: client});
      form = Object.assign(form, {campaign: campaign});
  }

  onSearchIconClicked(){
    setTimeout(() => {
       if(this.searchElement.nativeElement){
          this.searchElement.nativeElement.focus();
       }
    });
  }

  onSelectedForm(form: any){
    this.formService.formSelectedId = form.id;
    this.campaign = this.campaignService.getCampaignById(form.campaign_id);
    this.incomingCampaignSelected.setAttributes(this.campaign);
    this.campaignService.campaignSelectedId = this.campaign.id;
    this.campaignService.setSelected(this.incomingCampaignSelected.getAttributes());
    if(parseFloat(this.incomingUser.getAttributes().role.id) === 2){
        this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(form.id) + '/dashboard3', {state:{id: this.service.encrypt(form.id)}});
    }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/dashboard', {state:{id: this.service.encrypt(form.id)}});
  }

  navigateDashboardCampaignSingleForm(){
    if(this.service.app.data.campaigns.data.length === 1){
      this.campaignService.campaignSelectedId = this.service.app.data.campaigns.data[0].id;
      if(this.service.app.data.forms.data.length === 1){
        this.formService.formSelectedId = this.service.app.data.forms.data[0].id;
        if(this.service.app.data.user.role.id === 2) {
          this.router.navigateByUrl('/us/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard3');
        }else this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard');
      }
    }
  }

  onSelectedCampaign(campaign: any){
      this.incomingCampaignSelected.setAttributes(campaign);
      this.campaignService.campaignSelectedId = this.campaign.id;
  }

  add(){
    if(this.selectedTabIndex === 0) {
      this.campaignService.campaignSelectedId = undefined;
      this.formService.openDialog({});
    } else this.campaignService.openDialog({});
  }

}
