import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonFilter'
})
export class JsonFilterPipe implements PipeTransform {
    transform(value: any, args?: any): any {

        if(!value)return null;
        if(!args)return value;

        args = args.toLowerCase();
        return value.filter((item: any) => {
            return JSON.stringify(item).toLowerCase().includes(args);

        });
    }
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

}
