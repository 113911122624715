import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {AppService} from '../../../../app.service';
import {Parameters} from "../../../../parameters";
import {FormService} from "../../../form.service";
import {NotifierService} from 'angular-notifier';
@Component({
  selector: 'app-respond-ranks',
  templateUrl: './respond-ranks.component.html',
  styleUrls: ['./respond-ranks.component.scss']
})
export class RespondRanksComponent implements  OnChanges {

  @Input() question: any;
  @Input() position: any;
  data: any;
  incomingQuestion: any;
  incomingForm: any;
  incomingQuestionResult: any;
  incomingSettings: any;
  incomingRateOption: any;
  value: any = 50;
  incomingRateOptionResult: any;
  constructor(public service: AppService,
              private notifierService: NotifierService,
              public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingRateOption = new Parameters();
    this.incomingQuestionResult = new Parameters();
    this.incomingRateOptionResult = new Parameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.incomingForm.setAttributes(Object.assign({}, this.formService.getSelected()));
      this.incomingSettings.setAttributes(this.incomingForm.getSettings());
      this.incomingSettings.setRateOptions(this.incomingSettings.attributes.hasOwnProperty('rate_options') ? this.incomingSettings.getRateOptions() : []);
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setResult(this.incomingQuestion.attributes.hasOwnProperty('result') &&
       !this.service.isNull(this.incomingQuestion.getResult()) ? Object.assign({}, this.incomingQuestion.getResult()) : {});
      this.incomingQuestionResult.setAttributes(this.incomingQuestion.getResult());
      this.setRateOptions();
  }

  formatLabel(value: number): string {
      return `${value}`;
   }

  onSliderChange(event: any, rateOption: any){
   this.incomingRateOption.setAttributes(rateOption);
   this.incomingRateOptionResult.setAttributes(this.incomingQuestionResult.getUnknown(this.incomingRateOption.getCntrlNum()));
   this.incomingRateOptionResult.setPercentage(event);
   this.incomingRateOptionResult.setStatus(1);
   this.incomingQuestion.setData(JSON.stringify(this.incomingQuestionResult.getAttributes()));
  }

  setRateOptions(){
   this.incomingQuestion.getOptions().map((rateOption: any) => {
    this.incomingRateOption.setAttributes(rateOption);
    if(!this.incomingQuestionResult.attributes.hasOwnProperty(this.incomingRateOption.getCntrlNum())){
     this.incomingRateOptionResult.setAttributes({});
     this.incomingRateOptionResult.setPercentage(1);
     this.incomingRateOptionResult.setStatus(0);
     this.incomingQuestionResult.setUnknown(this.incomingRateOption.getCntrlNum(), this.incomingRateOptionResult.getAttributes());
    }
   });
   this.incomingQuestion.setData(JSON.stringify(this.incomingRateOptionResult.getAttributes()));
  }


}
