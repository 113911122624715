<div class="pull-right">
    <table>
        <td>Search &nbsp;:</td>
        <td>
          <input  (keyup.enter)="enterKeyUp(searchCntrl)"
                  (keyup)="onSearchTextChange(searchCntrl)"
                  [(ngModel)]="searchCntrl"
                  class="form-control"
                  type="text"
                  [ngStyle]="{'height': '35px','text-align': 'center'}" />
        </td>
    </table>
</div>
<div class="pull-left"> Shows   <select><option>5</option>10</select> entries</div>

