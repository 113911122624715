<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric">Name</th>
    <th class="mdl-data-table__cell--non-numeric">Description</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let districtal of (flag === 'all' ? (districts.data | jsonFilter : searchCntrl) : (districts.trashed.data | jsonFilter: searchCntrl))"
      (contextmenu)="onContextMenu($event, districtal)"
      (mousedown)="selectedDistrictal = districtal"
  >
    <td class="mdl-data-table__cell--non-numeric">{{districtal.name}}</td>
    <td class="mdl-data-table__cell--non-numeric" [innerHTML]="districtal.description"></td>
    <td>
      <div class="dropdown pull-right">
        <button  class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
      </div>

      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item='districtal'>
          <button mat-menu-item (click)="handleUpdate(selectedDistrictal)"  *ngIf="flag === 'all'">Edit</button>
          <button mat-menu-item (click)="handleDestroy(selectedDistrictal)" *ngIf="flag === 'all'">Delete</button>
          <button mat-menu-item (click)="handleRestore(selectedDistrictal)" *ngIf="flag === 'restore'">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
