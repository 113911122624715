<a class="pull-left"><h4>{{keys === 0 ? 'Employee, registration ' : incomingSelectedEmployee.getName() + ', employee'}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<hr />
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width" appearance='fill'>
             <mat-label> Campany *</mat-label>
             <input type="text"
                    #employeeElement
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "campanyControl"
                    [matAutocomplete] = "campany"/>
                        <mat-autocomplete #campany = "matAutocomplete" (optionSelected)="onCampanySelectionChanged($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let campany of filteredCampaniesOptions | async; let campanyeOf = index" [value]= "campany" >
                                {{campany.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <h4 style="color: red; font-weight: normal"
                               *ngIf="service.isNull(employeeElement.value) === false &&
                                 ((filteredCampaniesOptions | async) | json).length === 2">
                            **---Not Found *---</h4>
            <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addCampany()" *ngIf="service.app.data.user.role.id === 1">add</i>
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width" appearance='fill'>
             <mat-label> Role *</mat-label>
             <input type="text"
                    #roleElement
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "roleControl"
                    [matAutocomplete] = "role"/>
                        <mat-autocomplete #role = "matAutocomplete" (optionSelected)="onRoleSelectionChanged($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let role of filteredRolesOptions | async; let roleeOf = index" [value]= "role" >
                                {{role.display_name}}
                            </mat-option>
                        </mat-autocomplete>
                        <h4 style="color: red; font-weight: normal"
                              *ngIf="service.isNull(roleElement.value) === false &&
                                 ((filteredRolesOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
          </mat-form-field>
        </span>
    </mat-list-item>
      <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width" appearance="fill">
             <mat-label> Department *</mat-label>
             <input type="text"
                    #departmentElement
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "departmentControl"
                    [matAutocomplete] = "department"/>
                        <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addDepartment()">add</i>
                        <mat-autocomplete #department = "matAutocomplete" (optionSelected)="onDepartmentSelectionChanged($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let department of filteredDepartmentsOptions | async; let departmentOf = index" [value]= "department" >
                                {{department.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <h4 style="color: red; font-weight: normal"
                              *ngIf="service.isNull(departmentElement.value) === false &&
                                     ((filteredDepartmentsOptions | async) | json).length === 2">
                                **---Not Found *---</h4>
          </mat-form-field>
        </span>
      </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Phone *</mat-label>
            <input matInput type="text"  formControlName="phone">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Email *</mat-label>
            <input matInput type="text"  formControlName="email">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <small>logo</small>
          <div class="upload-box">
              <div class="hold">
                  <a href="">Maximum file size 20 MB <img src="{{avatar}}" class="img-circle" width="40" height="40" alt="" *ngIf="avatar"></a>
                  <span class="btn-file"> Browse File
                      <input type="file"
                             (change)="handleFileInput($event)"
                      >
                  </span>
              </div>
          </div>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address</mat-label>
            <input type="text"
                   matInput
                   formControlName="location"
                   placeholder=""
                   #addressElement
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onChangeAddressText($event)"
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, addressElement.value)"
                   (onLocationSelected)="onlocationSelected($event)"
                   matGoogleMapsAutocomplete />
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
      <span class="" mat-line>
           <mat-form-field class="example-full-width" appearance='fill'>
              <mat-label>Description</mat-label>
              <textarea matInput placeholder="" formControlName="description" #message rows="1" style='height:200px'></textarea>
              <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
      </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Edit'}}"/>
        </div>
      </center>
    </div>
  </div>
</form>
