import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-create-file-controls',
  templateUrl: './create-file-controls.component.html',
  styleUrls: ['./create-file-controls.component.scss']
})
export class CreateFileControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  controls: any;

  constructor(private service: AppService) {
  }

  ngOnInit(): void {
    this.controls = this.service.app.data.controls;
  }


  getImage(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.question.pic = reader.result;
      this.question.data = this.service.dataURItoBlob(reader.result);
    };
  }

}
