<div id="example-radio-group-label"
    class="cursor"
     [ngStyle]="{'color': this.color}"
    #element>
    <center>
        <ng-container *ngIf="!service.isNull(incomingQuestionSettings.getSrc())">
            <img [src]="incomingQuestionSettings.getSrc()"
                 [alt]="'img'"
                 [class]="'mdl-shadow--2dp'"
                 [ngStyle]="{'width':'100%','max-height':'450px'}"/>
        </ng-container>
    </center>
    <h4>
        <span [ngStyle]="{'color':'red'}">{{(this.service.parseFloat(incomingQuestion.getRequired()) === 1 ? '* ' : '') }}</span>
        <span [ngStyle]="{'color': this.color}">
            <span class="cursor sawasdee20" *ngIf="service.parseFloat(incomingForm.getCategoryId())===4" [innerHTML]="incomingQuestion.getLabel()"></span>
            <span class="cursor sawasdee20" *ngIf="service.parseFloat(incomingForm.getCategoryId())!==4">{{service.upperStartingCharacter(service.removeHtmlTags(incomingQuestion.getLabel()), true)}}</span>
        </span>
    </h4>
</div>
