<iframe src="{{formService.pdf | safeUrl}}" [ngStyle]="{'width':'100%','height':'100%','position':'absolute','right':'0px','top':'0px'}"></iframe>
<div [innerHTML]="iframe(860,484, this.formService.pdf) | safeUrl"></div>
<div #tableElement>
    <table style="width: 100%">
        <tr *ngFor="let question of formService.questions" style="width: 100%">
            <td style="width: 100%">
                <center>
                    <h4>{{question.label}}</h4>
                    <div>{{question.data}}</div>
                </center>
            </td>
        </tr>
    </table>
</div>

