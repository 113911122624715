import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../app.service";
import {FormService} from "../../forms/form.service";
import {Parameters} from "../../parameters";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {MatDrawer} from "@angular/material/sidenav";
import {OrderService} from "../../orders/order.service";

@Component({
  selector: 'app-dashboard-distributors',
  templateUrl: './dashboard-distributors.component.html',
  styleUrls: ['./dashboard-distributors.component.scss']
})
export class DashboardDistributorsComponent implements OnInit, OnDestroy {
  @ViewChild('drawer', {static: false})  drawer: any;
  incomingForm: any;
  subscription: any;
  incomingOrder: any;
  strings: any;
  constructor(public service: AppService,
              private orderService: OrderService,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService) {
    this.incomingForm = new Parameters();
    this.incomingOrder = new Parameters();
    this.strings = new Parameters();
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingOrder.setAttributes(this.orderService.getSelected());
    this.strings.setAttributes(this.service.app.strings.params);
    this.drawer = MatDrawer;
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
        .subscribe((channel: any) => {
          if(channel instanceof Object){
            if(channel.hasOwnProperty(this.strings.getAction())){
              if(channel[this.strings.getAction()] === 'onSelect'){
                if (!this.orderService.isDialogOpened){
                  this.drawer.opened = true;
                  this.incomingOrder.setAttributes(channel[this.strings.getItem()]);
                }
              }
            }
          }
        });
  }

  ngOnDestroy(): void {
   this.subscription.unsubscribe();
  }

}
