import { Injectable } from '@angular/core';
import {Parameters} from '../../parameters';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MethodsInviteFormsService {

  report: any;

  /**
   * @param mixed Data
   */


  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor() {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }


  getInviteesByForms(forms: any){
    let invitees: any = {}; invitees = {data: forms.data.map((form: any) => { return form.invitees.data; }).flat(1), trashed:  {data: forms.data.map((form: any) => { return form.invitees.trashed.data; }).flat(1)}}; return invitees;
  }
}
