import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from "../../forms/form.service";
import {Parameters} from "../../parameters";
import {Router} from "@angular/router";
import {MatMenuTrigger} from "@angular/material/menu";
import {FilterDashboardComponent} from "../../dashboard/filter-dashboard.component";
import {MatDialog} from "@angular/material/dialog";
import {DashboardService} from "../../dashboard/dashboard.service";
import {BroadcastChannelService} from "../../broadcast-channel.service";

@Component({
  selector: 'app-list',
  templateUrl: './ambassadors-list.component.html',
  styleUrls: ['./ambassadors-list.component.scss']
})
export class AmbassadorsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  incomingForm: any;
  incomingUser: any;
  incomingAmbassador: any;
  teamId: any = 0;
  incomingSelectedAmbassador: any;
  incomingReport: any;
  outgoingRequest: any;
  subscription: any;
  flag: any = 0;
  constructor(public appService: AppService,
              private router: Router,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public dashboardService: DashboardService,
              private formService: FormService) {
    this.incomingForm = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingAmbassador = new Parameters();
    this.incomingSelectedAmbassador = new Parameters();
    this.incomingReport = new Parameters();
    this.outgoingRequest = new Parameters();
    this.contextMenu = MatMenuTrigger;
    this.incomingReport.setAttributes(this.dashboardService.incomingReport.getAttributes());
  }

  ngOnInit(): void {
    this.outgoingRequest.setAttributes(Object.assign({}, this.incomingReport.getAttributes()));
    this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
    this.incomingUser.setAttributes(this.appService.app.data.user);
    this.incomingSelectedAmbassador.setAttributes({});
    if(this.incomingUser.attributes.hasOwnProperty('id')){
      if(this.incomingForm.attributes.hasOwnProperty('invitees')){
        var ambassadors: any = this.incomingForm.getInvitees().data.filter((ambassador: any) => {return parseFloat(ambassador.user_id) === parseFloat(this.incomingUser.getId()); });
        if(ambassadors.length !== 0){
          this.incomingAmbassador.setAttributes(ambassadors[0]);
          if(this.incomingAmbassador.attributes.hasOwnProperty('team_id')){
            this.teamId = parseFloat(this.incomingAmbassador.getTeamId());
          }
        }
      }
    }
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if (item instanceof Object){
          }else if (item === 'report'){
            if(this.flag === 1){
              this.requestAmbassadorReport();
            }
          }
        });
  }

  requestAmbassadorReport(){
    this.outgoingRequest.setUserId(this.incomingSelectedAmbassador.getId());
    this.outgoingRequest.setType('responses.date.range');
    this.appService.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {}, (result: any) => {
      this.appService.responses = result;
      this.appService.user = this.incomingSelectedAmbassador.getAttributes();
      this.router.navigateByUrl('/app/supervisors/xxx/responses');
    }, (error: any) => {
    });

  }

  getListingReport(){
    this.flag = 1;
    this.appService.responses = [];
    this.appService.user = {};
    this.dialog.open(FilterDashboardComponent, { data: {report: this.outgoingRequest.getAttributes()} , width  : '55%', height : '100%'});
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  getAmbassadorsByTeamId(teamId: any){
    return this.incomingForm.getInvitees().data.filter((ambassador: any) => {return parseFloat(ambassador.team_id) === teamId;});
  }


  selectUser(user: any){
    this.incomingUser.setAttributes(user);
    this.appService.app.data.user = Object.assign(this.appService.app.data.user, user);
    this.router.navigateByUrl('/app/respondents/' +  this.appService.encrypt(this.appService.app.data.user.id) + '/responses');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
