<center>
  <div class="col-md-1 col-sm-1"></div>
  <div class="col-md-10 col-sm-10">
    <section class="popular-categories">
      <div class="col-md-3 col-sm-6" *ngFor="let region of regions">
        <div class="box">
          <h4><a >{{region.name}}</a></h4>
          <strong>6,213 Jobs</strong>
          <p>Available in Design &amp; Multimedia</p>
        </div>
      </div>
    </section>
  </div>
  <div class="col-md-1 col-sm-1"></div>
</center>
