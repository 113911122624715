<mat-drawer-container class="example-container"
                      [ngStyle]="{'height':this.service.getHeight()+'px','background-color':this.incomingFormSettings.getBackgroundColor()}"
                      [hasBackdrop]="true">
    <mat-drawer #drawer
                [position]="'end'"
                [ngStyle.lg]="{'width':this.service.getWidth()/2+'px'}"
                [ngStyle.md]="{'width':this.service.getWidth()/2+'px'}"
                [ngStyle.sm]="{'width':this.service.getWidth()+'px'}"
                [ngStyle.xs]="{'width':this.service.getWidth()+'px'}"
                [mode]="'over'">
        <mat-icon (click)="drawer.opened = false" class="pull-right cursor" [ngStyle]="{'padding':'20px 50px 20 20px'}">close</mat-icon>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="{{question[strings.getLabel()]}}"
                         class="sawasdee12"
                         (click)="onSelect(question);drawer.toggle()"
                         *ngFor="let question of incomingForm.getControls()">
                    <br><br>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div [ngStyle]="{'padding':'0px','margin-top':'0px','border-radius': '20px','background':'#fff'}"
                             class="pull-right col-lg-2 col-md-2 col-sm-12 col-xs-12 mdl-shadow--2dp">
                            <table [ngStyle]="{'width':'100%','margin':'0px'}">
                                <td valign="middle" [ngStyle]="{'width':'100%'}">
                                    <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"
                                            autocomplete="off"
                                            ngClass="sawasdee18 searchbar"
                                            #searchId
                                            [(ngModel)]="service.searchText"
                                            placeholder="">
                                </td>
                                <td valign="middle"
                                    (click)="addOption(question)"
                                    class="cursor">
                                    <mat-icon matSuffix>add</mat-icon>
                                </td>
                            </table>
                        </div>
                    </div>
                    <div>
                        <section class="post-section blog-post"
                                 [ngStyle]="{'background-color': 'transparent'}">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 cursor"
                                         [id]="option[strings.getId()]"
                                         (click)="onOptionSelected(option)"
                                         *ngFor="let option of (question[strings.getOptions()] | jsonFilter: this.service.searchText | slice:0:2)">
                                        <div class="post-box"
                                             [ngStyle]="{'height':'auto', 'background-color': (option.hasOwnProperty(strings.getStatus()) && this.service.parseFloat(option[strings.getStatus()]) === 0 ? 'transparent' : 'transparent')}">
                                            <div class="text-box">
                                                <h4>
                                                    <a>
                                                        <ul class="list-inline">
                                                            <li style="vertical-align: top">{{option[strings.getLabel()]}}</li>
                                                        </ul>
                                                    </a>
                                                </h4>
                                                <p class="text-left"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-drawer>
    <mat-drawer-content
            [ngStyle]="{'overflow':'hidden','background-size': 'cover','background-repeat':'no repeat','display':'block', 'object-fit': 'cover','background-image':'url(assets/images/tpsf/awards.jpgx)'}">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="overflow: hidden">
<!--            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" #header>-->
<!--                <ul class="list-inline pull-right">-->
<!--                    <li>-->
<!--                        <mat-spinner-->
<!--                                *ngIf="this.service.matSpinner"-->
<!--                                class=""-->
<!--                                color='accent'disabledButton-->
<!--                                [diameter]="100">-->
<!--                        </mat-spinner>-->
<!--                    </li>-->
<!--                    <li class="cursor">-->
<!--                        <mat-icon class=""-->
<!--                                  [ngStyle]="{'color':'#fff'}"-->
<!--                                  (click)="drawer.toggle()">drag_indicator</mat-icon>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <img [src]="this.service.logo"-->
<!--                     ngClass="img-circle pull-left"-->
<!--                     [ngStyle]="{'max-width':'70px','height':'70px','margin-top':'5px'}">-->
<!--            </div>-->
            <div ngClass=""
                 *ngIf="items.length !== 0 && flag === 0"
                 [ngStyle]="{'padding-top':'0px', 'width':this.service.getWidth()+'px'}">
                <a mat-fab
                   ngClass="pull-right"
                   (click)="start()"
                   [ngStyle]="{'position':'fixed','right':'10%','top':'100px','color':'#fff', 'background-color':this.incomingFormSettings.hasAttribute(strings.getBackgroundColor()) ? this.incomingFormSettings.getBackgroundColor() : '#000'}">
                    <mat-icon
                            class="material-icons"
                            [ngStyle]="{'font-size': 'x-large', 'color':'#fff', 'background-color':this.incomingFormSettings.hasAttribute(strings.getBackgroundColor()) ? this.incomingFormSettings.getBackgroundColor() : '#000'}">
                        play_arrow
                    </mat-icon>
                </a>
                <center>
                    <ngx-wheel
                        #wheel
                        [width]="getWidth()"
                        [height]="getHeight()"
                        [spinDuration]="18"
                        [disableSpinOnClick]='true'
                        [items]='items'
                        [innerRadius]='50'
                        [spinAmount]='10'
                        [textOrientation]='textOrientation'
                        [textAlignment]="textAlignment"
                        [pointerStrokeColor]="'transparent'"
                        [pointerFillColor]="'#fff'"
                        [idToLandOn]='this.idToLandOn'
                        (onSpinStart)='before()'
                        (onSpinComplete)='after()'>
                    </ngx-wheel>
                </center>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>


<!--<mat-drawer-container-->
<!--        class="example-container"-->
<!--        autosize-->
<!--        [hasBackdrop]="false">-->
<!--    <mat-drawer-->
<!--        #drawer-->
<!--        [disableClose]="true"-->
<!--        position="end"-->
<!--        [ngStyle]="{'background-color':'red'}"-->
<!--        [mode]="'over'">-->

<!--        <mat-grid-list cols="2" rowHeight="2:1">-->
<!--            <mat-grid-tile>1</mat-grid-tile>-->
<!--            <mat-grid-tile>2</mat-grid-tile>-->
<!--            <mat-grid-tile>3</mat-grid-tile>-->
<!--            <mat-grid-tile>4</mat-grid-tile>-->
<!--        </mat-grid-list>-->
<!--        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
<!--        </div>-->
<!--    </mat-drawer>-->
<!--    <mat-drawer-content >-->
<!--    </mat-drawer-content>-->
<!--</mat-drawer-container>-->