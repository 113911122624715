<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div class="row">
                <a mat-flat-button class="defaultColor lg-fab pull-left" (click)="addOptionalEvent()">Add Input</a>
            </div>
            <div class="row">
                <table style="width: 100%">
                    <tbody>
                        <tr *ngFor="let optional of question.options" style="width: 100%">
                            <td  valign="bottom" style="width: auto">
                                {{optional.label}}
                            </td>
                            <td valign="bottom">
                                <mat-form-field  class="example-full-width" appearance="standard">
                                    <input matInput
                                              type="text"
                                              placeholder=""
                                              [(ngModel)]="optional.label">
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>

