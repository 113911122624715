import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../../../app.service";
import {NotifierService} from "angular-notifier";
import {CampaignService} from "../../../campaigns/campaign.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TeamService} from "../../team.service";
import {Parameters} from "../../../parameters";
import {map, startWith} from "rxjs/internal/operators";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {DialogCampaignsComponent} from "../../../campaigns/dialog/dialog-campaigns.component";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import PlaceResult = google.maps.places.PlaceResult;
import {BrunchesService} from "../brunches.service";
import {DialogTeamsComponent} from "../../dialog/dialog-teams.component";

@Component({
  selector: 'app-dialog-brunch-teams',
  templateUrl: './dialog-brunch-teams.component.html',
  styleUrls: ['./dialog-brunch-teams.component.scss']
})
export class DialogBrunchTeamsComponent implements OnInit {

  @ViewChild('addressElement')  addressElement: any;
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  filteredTeamsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedBrunch: any;
  keys: any;
  selectedBrunch: any;
  addressValue: any;
  logo: any;
  subscription: any;
  incomingTeam: any;
  incomingBrunch: any;
  incomingCampaign: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              public campaignService: CampaignService,
              private brunchService: BrunchesService,
              private broadcastChannel: BroadcastChannelService,
              private dialog: MatDialog,
              public teamService: TeamService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogBrunchTeamsComponent>) {
    this.incomingTeam = new Parameters();
    this.incomingBrunch = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingTeam.setAttributes(this.data.hasOwnProperty('teamId') ? this.teamService.getTeamById(this.data.teamId) : {});
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.addressElement = ElementRef;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedBrunch = new Parameters();
    this.incomingSelectedBrunch.setAttributes(brunchService.selected);
    this.keys = Object.keys(this.incomingSelectedBrunch.getAttributes()).length;
    this.selectedBrunch = brunchService.selected;
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.campaignService.campaignSelectedId));
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
          }
        });

    this.dialogRef.afterClosed().subscribe(result => {this.brunchService.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      team_id:     ['', Validators.compose([])],
      address:     ['', Validators.compose([])],
      lat:         ['', Validators.compose([])],
      lng:         ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      cntrl_num:   [this.service.random(), Validators.compose([])],
    });

  //this.formValues.get('location').valueChanges.subscribe(value => console.log('value changed', ''));
    if(this.keys === 0) this.selectedBrunch = {}; else{
      this.formValues.controls['name'].setValue(this.incomingSelectedBrunch.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedBrunch.getDescription());
      this.addressValue = this.incomingSelectedBrunch.getAddress();
      for(var key in this.incomingSelectedBrunch.getAttributes()) {
        if(this.formValues.contains(key)) {
          this.formValues.controls[key].setValue(this.incomingSelectedBrunch.attributes[key]);
        }else {
          if(this.incomingSelectedBrunch.attributes[key] instanceof Object || this.incomingSelectedBrunch.attributes[key] instanceof Array){} else{
            this.formValues.addControl(key, new FormControl(this.incomingSelectedBrunch.attributes[key], Validators.compose([])));
          }
        }
      }
    }

    setTimeout(() => {
      this.filteredTeamsOptions = this.teamControl.valueChanges.pipe(
          startWith(),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterTeam(name) : this.teamService.getTeamsByCampaignId(this.incomingCampaign.getId()).slice())
      );
      if(Object.keys(this.incomingTeam.getAttributes()).length === 0){}else{
        this.teamControl.setValue(this.incomingTeam.getAttributes());
        this.formValues.controls['team_id'].setValue(this.incomingTeam.getId());
      }
    });
  }

  public handleAddressChange(address: Address) {}

  onAddressTextChange(event: any){
    this.formValues.controls['address'].setValue(event.target.value);
    this.formValues.controls['location'].setValue(event.target.value);
  }



  onGoogleMapAutocompleteSelected(result: PlaceResult, address: any) {
    this.formValues.controls['address'].setValue(address);
    //this.formValues.controls['address'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
    this.formValues.controls['lat'].setValue(location.latitude);
    this.formValues.controls['lng'].setValue(location.longitude);
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.service.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }

  addTeam(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
    this.notifier.notify('success', 'Add new Team');
  }




  private _filterTeam(name: string) {
    const filterValue = name.toLowerCase();
    return this.teamService.getTeamsByCampaignId(this.incomingCampaign.getId()).filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  handleAddressChanger(result: any){
  }

//handleAddressChange(result: PlaceResult){
//}


  onTeamSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['team_id'].setValue(event.option.value.id);
    this.incomingTeam.setAttributes(this.teamService.getTeamById(event.option.value.id));
    this.teamService.setSelected(this.incomingTeam.getAttributes());
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(brunchFormValues: any) {
    this.incomingBrunch.setAttributes(brunchFormValues);
    this.service.httpService(
        'post', this.keys === 0 ? '/brunches' : '/brunches/' + this.incomingSelectedBrunch.getId() + '/update',
        brunchFormValues, {},
        (response: any) => {
          if(this.keys === 0) {
            this.app.data.brunches.data.unshift(response);
            this.incomingTeam.getBrunch
          } else this.selectedBrunch = Object.assign(this.selectedBrunch, response);
          this.closeDialog();
          this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'registered' : 'updated' ));
          this.broadcastChannel.emitNavChangeEvent({brunch: response});
          this.selectedBrunch = {};
        }, (error: any) => {
        });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
