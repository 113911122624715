import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CampaignService} from '../../campaigns/campaign.service';
import {Parameters} from '../../parameters';
import * as moment from 'moment';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {TeamService} from '../team.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {DialogCampaignsComponent} from '../../campaigns/dialog/dialog-campaigns.component';
@Component({
  selector: 'app-dialog-teams',
  templateUrl: './dialog-teams.component.html',
  styleUrls: ['./dialog-teams.component.scss']
})
export class DialogTeamsComponent implements OnInit, OnDestroy {
  @ViewChild('addressElement')  addressElement: any;
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  campaignControl = new FormControl();
  filteredCampaignsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedTeam: any;
  keys: any;
  selectedTeam: any;
  addressValue: any;
  logo: any;
  subscription: any;
  incomingCampaign: any;
  incomingTeam: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              public campaignService: CampaignService,
              private broadcastChannel: BroadcastChannelService,
              private dialog: MatDialog,
              public teamService: TeamService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogTeamsComponent>) {
    this.incomingCampaign = new Parameters();
    this.incomingTeam = new Parameters();
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.campaignService.campaignSelectedId));
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.addressElement = ElementRef;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedTeam = new Parameters();
    this.incomingSelectedTeam.setAttributes(teamService.selected);
    this.keys = Object.keys(this.incomingSelectedTeam.getAttributes()).length;
    this.selectedTeam = teamService.selected;
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campaign') === true) {
              this.campaignControl.setValue(item.campaign);
              this.formValues.controls['campaign_id'].setValue(item.campaign.id);
            }
          }
   });

    this.dialogRef.afterClosed().subscribe(result => {this.teamService.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['' , Validators.compose([])],
      campaign_id: ['', Validators.compose([])],
      address:     ['', Validators.compose([])],
      lat:         ['', Validators.compose([])],
      lng:         ['', Validators.compose([])],
      description: ['', Validators.compose([])],
    });

    //  this.formValues.get('location').valueChanges.subscribe(value => console.log('value changed', ''));
    if(this.keys === 0) this.selectedTeam = {}; else{
      this.formValues.controls['name'].setValue(this.incomingSelectedTeam.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedTeam.getDescription());
      this.addressValue = this.incomingSelectedTeam.getAddress();
      for(var key in this.incomingSelectedTeam.getAttributes()) {
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedTeam.attributes[key]);
          }else {
            if(this.incomingSelectedTeam.attributes[key] instanceof Object || this.incomingSelectedTeam.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingSelectedTeam.attributes[key], Validators.compose([])));
            }
          }
      }
    }

    setTimeout(() => {
      this.filteredCampaignsOptions = this.campaignControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterCampaign(name) : this.app.data.campaigns.data.slice())
      );
      if(Object.keys(this.incomingCampaign.getAttributes()).length === 0){}else{
        this.campaignControl.setValue(this.incomingCampaign.getAttributes());
        this.formValues.controls['campaign_id'].setValue(this.incomingCampaign.getId());
      }
    });
  }

  public handleAddressChange(address: Address) {}

  onAddressTextChange(event: any){
    this.formValues.controls['address'].setValue(event.target.value);
    this.formValues.controls['location'].setValue(event.target.value);
  }



  onGoogleMapAutocompleteSelected(result: PlaceResult, address: any) {
    this.formValues.controls['address'].setValue(address);
  //this.formValues.controls['address'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
    this.formValues.controls['lat'].setValue(location.latitude);
    this.formValues.controls['lng'].setValue(location.longitude);
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['logo'].setValue(this.service.dataURItoBlob(reader.result));
      this.logo = reader.result;
    };
  }

  addCampaign(){
    this.campaignService.setData(this.service.app.data.campaigns);
    this.dialog.open(DialogCampaignsComponent, {data  : {}, width : '55%', height: '100%', });
    this.notifier.notify('success', 'Add new Campaign');
  }




  private _filterCampaign(name: string) {
    const filterValue = name.toLowerCase();
    return this.app.data.campaigns.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  handleAddressChanger(result: any){
  }

//handleAddressChange(result: PlaceResult){
//}


  onCampaignSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['campaign_id'].setValue(event.option.value.id);
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(event.option.value.id));
    this.campaignService.setSelected(this.incomingCampaign.getAttributes());
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(teamForm: any) {
    this.incomingTeam.setAttributes(teamForm);
    this.service.httpService(
      'post', this.keys === 0 ? '/teams' : '/teams/' + this.incomingSelectedTeam.getId() + '/update',
      teamForm, {},
      (response: any) => {
        if(this.keys === 0) {
          this.app.data.teams.data.unshift(response);
        } else this.selectedTeam = Object.assign(this.selectedTeam, response);
        if(this.incomingTeam.getAttributes().hasOwnProperty('campaign_id')){
          const position = this.incomingCampaign.getTeams().data.findIndex((team: any) => { return parseFloat(team.id) === parseFloat(response.id);});
          if(position === -1) this.incomingCampaign.getTeams().data.unshift(response); else{
            this.incomingCampaign.getTeams().data[position] = Object.assign(this.incomingCampaign.getTeams().data[position], response);
          }
        }
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        this.broadcastChannel.emitNavChangeEvent({team: response});
        this.selectedTeam = {};
      }, (error: any) => {
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
