import {Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {DialogTeamsComponent} from '../../teams/dialog/dialog-teams.component';
import {AmbassadorsService} from '../ambassadors.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {TeamService} from '../../teams/team.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {RoleService} from '../../roles/role.service';
import {CampanyService} from '../../campanies/campany.service';
import {UserService} from '../../users/user.service';
import {ClientService} from '../../clients/client.service';
import {FormService} from "../../forms/form.service";

@Component({
  selector: 'app-dialog-ambassadors',
  templateUrl: './dialog-ambassadors.component.html',
  styleUrls: ['./dialog-ambassadors.component.scss']
})
export class DialogAmbassadorsComponent implements OnInit, OnDestroy {
  @ViewChild('address')  addressElement: any;
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  userControl = new FormControl();
  filteredTeamsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedAmbassador: any;
  keys: any;
  selectedAmbassador: any;
  addressValue: any;
  photo: any;
  filteredUsersOptions: any;
  users: any;
  user: any;
  submitFormValues: any;
  incomingTeam: any;
  teams: any = [];
  filteredRoles: any;
  roleControl = new FormControl();
  incomingRole = new Parameters();
  incomingCampaign: any;
  incomingCampany: any;
  incomingClient: any;
  subscription: any;
  position: any;
  strings: any;
  constructor(public  service: AppService,
              private notifier: NotifierService,
              public  formBuilder: FormBuilder,
              private dialog: MatDialog,
              private formService: FormService,
              private userService: UserService,
              private campaignService: CampaignService,
              private campanyService: CampanyService,
              private clientService: ClientService,
              private teamService: TeamService,
              private roleService: RoleService,
              public broadcastChannel: BroadcastChannelService,
              public ambassadorService: AmbassadorsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogAmbassadorsComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.addressElement = ElementRef;
    this.parameters = new Parameters();
    this.submitFormValues = new Parameters();
    this.strings = new Parameters();
    this.incomingTeam = new Parameters();
    this.incomingRole = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedAmbassador = new Parameters();
    this.incomingSelectedAmbassador.setAttributes(ambassadorService.selected);
    this.incomingCampaign.setAttributes(this.campaignService.selected);
    this.keys = Object.keys(this.incomingSelectedAmbassador.getAttributes()).length;
    this.selectedAmbassador = ambassadorService.selected;
    this.users = [];
    this.teams =  this.service.app.data.teams.data;
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingTeam.setAttributes(
        this.teamService.selected.hasOwnProperty(this.strings.getId()) ?
        this.teamService.getSelected() : {});
  }

  ngOnDestroy(): void {
   this.subscription.unsubscribe();
  }

  onUserNameChanged(event: any){
    this.formValues.controls['name'].setValue(event.target.value);
    // setTimeout(() => { this.userService.search({searchText: event.target.value, role_id: 8}); }, this.timeout());
    this.formValues.removeControl('user_id');
  }




  setUsers(){
    this.app.data.ambassadors.data.map((ambassador: any) => {
      this.users.unshift(ambassador);
    });
  }
  timeout(){return parseFloat('900');}
  ngOnInit(): void {
    // if(this.campaignService.selected.hasOwnProperty(this.strings.getId())) {
    //   this.incomingCampaign.setAttributes(this.campaignService.getSelected());
    //   this.teams = this.incomingCampaign.getTeams().data;
    // }
    this.setUsers();
    this.dialogRef.afterClosed().subscribe(result => {this.ambassadorService.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      avatar:      ['', Validators.compose([])],
      password:    ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
      role_id:     ['8', Validators.compose([])],
      role_play:   ['8', Validators.compose([])],
      team_id:     [this.teamService.teamSelectedId, Validators.compose([])],
    });
    // if(!this.selectedAmbassador.hasAttribute(this.strings.getId())) this.selectedAmbassador = {}; else{
    //   this.formValues.controls['name'].setValue(this.incomingSelectedAmbassador.getName());
    //   this.formValues.controls['description'].setValue(this.incomingSelectedAmbassador.getDescription());
    //   this.formValues.controls['phone'].setValue(this.incomingSelectedAmbassador.getPhone());
    //   this.formValues.controls['email'].setValue(this.incomingSelectedAmbassador.getEmail());
    //   this.formValues.controls['location'].setValue(this.incomingSelectedAmbassador.getLocation());
    //   this.formValues.controls['role_id'].setValue(this.incomingSelectedAmbassador.getRoleId());
    //   this.formValues.addControl('id', new FormControl(this.incomingSelectedAmbassador.getId(), Validators.compose([])));
    //   this.addressValue = this.incomingSelectedAmbassador.getLocation();
    //   for(var key in this.incomingSelectedAmbassador.getAttributes()) {
    //     if(this.incomingSelectedAmbassador.attributes[key] instanceof Object || this.incomingSelectedAmbassador.attributes[key] instanceof Array){} else{
    //       if(this.formValues.contains(key)) {
    //         this.formValues.controls[key].setValue(this.incomingSelectedAmbassador.attributes[key]);}else {
    //         if(this.incomingSelectedAmbassador.attributes[key] instanceof Object || this.incomingSelectedAmbassador.attributes[key] instanceof Array){} else{
    //           this.formValues.addControl(key, new FormControl(this.incomingSelectedAmbassador.attributes[key], Validators.compose([])));
    //         }
    //       }
    //     }
    //   }
    //   this.formValues.removeControl('password');
    //   this.photo = this.formValues.controls['avatar'].value;
    // }
    //
    //
    //


    this.filteredRoles = this.roleControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(name  => name ? this._filterRole(name) : this.roleService.getRoles().slice())
    );

    this.filteredUsersOptions = this.userControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterUser(name) : this.service.app.data.users.data.slice())
    );

    this.filteredTeamsOptions = this.teamControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterTeam(name) : this.incomingCampaign.getTeams().data.slice())
    );


    setTimeout(() => {
      this.incomingRole.setAttributes(this.roleService.getRoleById(this.formValues.controls[this.strings.getRoleId()].value));
      this.roleControl.setValue(this.incomingRole.getAttributes());

      if(this.incomingTeam.hasAttribute(this.strings.getId()))
        this.teamControl.setValue(this.incomingTeam.getAttributes());
    },this.timeout());

    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
          .subscribe((item: any) => {
            if(item instanceof Object){
            }
          });
  }

  public handleAddressChange(address: Address) {
  }

  onChangeRoleText(event: any){
    this.formValues.controls['role_id'].setValue('');
  }

  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }

  private _filterRole(label: string) {
    const args = label.toString().toLowerCase();
    return this.roleService.getRoles().filter((role: any) => JSON.stringify(role).toString().toLowerCase().includes(args));
  }

  getTeamByAmbassador(ambassador: any){
    let team: any = this.teams.filter((team: any) => { return team.id === ambassador.team_id;})[0];
    return team;
  }

  addTeam(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
  }


  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){}

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['avatar'].setValue(this.service.dataURItoBlob(reader.result));
      this.photo = reader.result;
    };
  }



  private _filterTeam(args: string) {
    return this.incomingCampaign.getTeams().data.filter((team: any) => team.name.toLowerCase().includes(args.toLowerCase()));
  }

  closeDialog(){
    this.dialogRef.close(null);
    this.teamService.teamSelectedId = undefined;
  }

  onAddressTextChange(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
  }

  private _filterUser(name: string) {
    const filterValue = name.toLowerCase();
    return this.users.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }


  onTeamSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['team_id'].setValue(event.option.value.id);
  }

  onUserSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.user = this.users.filter((user: any) => {return user.id === event.option.value.id; })[0];
    for(var key in this.user) {
      if(this.user[key] instanceof Object || this.user[key] instanceof Array){} else{
        if(key.includes('id')){
          this.formValues.addControl('user_id', new FormControl(event.option.value.id, Validators.compose([])));
        }else if(this.formValues.contains(key)) {
          this.formValues.controls[key].setValue(this.user[key]);
        } else this.formValues.addControl(key, new FormControl(this.user[key], Validators.compose([])));
      }
    }

  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(ambassadorForm: any) {
    this.submitFormValues.setAttributes(ambassadorForm);
    this.service.httpService(
      'post', this.keys === 0 ? '/teams/' +
            this.submitFormValues.attributes.team_id + '/members/add' : '/members/' +
            this.incomingSelectedAmbassador.getTeamMemberId() + '/update',
      ambassadorForm, {},
      (ambassador: any) => {
        if(!this.incomingSelectedAmbassador.getAttributes().hasOwnProperty(this.strings.getId())) {
//        this.incomingTeam.getAttributes().members.data.unshift(response);
          this.service.app.data.ambassadors.data.unshift(ambassador);
          this.incomingTeam.getMembers()[this.strings.getData()].unshift(ambassador);
        } else {
          this.selectedAmbassador = Object.assign(this.selectedAmbassador, ambassador);
          this.selectedAmbassador = Object.assign(this.selectedAmbassador, this.userService.getUserByUser(ambassador));
        }
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        this.selectedAmbassador = {};
        this.broadcastChannel.emitNavChangeEvent({ambassador : ambassador});
      }, (error: any) => {
      });
  }

}
