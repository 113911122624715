<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Email</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
    <th class="mdl-data-table__cell--non-numeric headline2" width="40">Photo</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="dl-data-table__cell--non-numeric headline2 text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let campany of (flag.includes('all') ? getCompaniesBySearchText(searchCntrl) :
      (service.app.data.campanies.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, campany)"
      (mousedown)="selectedCampany = campany">
    <td class="mdl-data-table__cell--non-numeric headline">{{campany.name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{campany.email}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{campany.phone}}</td>
    <td class="mdl-data-table__cell--non-numeric headline"><img class="img-thumbnail avatar" src="{{campany.logo}}"></td>
    <td class="mdl-data-table__cell--non-numeric headline">{{campany.address}}</td>
    <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="campany.description"></td>
    <td class="mdl-data-table__cell--non-numeric headline">
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedCampany)">Edit</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedCampany)">Delete</li>
          <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedCampany)">Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedCampany)">Edit</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedCampany)">Delete</button>
          <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedCampany)">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
