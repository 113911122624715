import {AfterContentChecked, AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {map, startWith} from 'rxjs/internal/operators';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import * as moment from 'moment';
import * as $ from 'jquery';
import {MatCheckbox} from '@angular/material/checkbox';
import {Parameters} from '../parameters';
import {DashboardService} from './dashboard.service';
import {FormService} from '../forms/form.service';
@Component({
  selector: 'app-filter-dashboard',
  templateUrl: './filter-dashboard.component.html',
  styleUrls: ['./filter-dashboard.component.scss']
})
export class FilterDashboardComponent implements OnInit, AfterContentChecked, AfterViewChecked, OnDestroy {
  @ViewChild('regionalElement') regionalElement: any;
  @ViewChild('formElement') formElement: any;
  @ViewChild('applyElement') applyElement: any;
  startingAt: any;
  endingAt: any;
  formChecked: any = false;
  regionalChecked: any = false;
  regions: any;
  filteredRegionsOptions: any;
  filteredFormsOptions: any;
  incomingForm: any;
  formValues: FormGroup = new FormGroup({});
  regionControl = new FormControl();
  formControl = new FormControl();
  regionalSelected: any = null;
  formSelected: any = null;
  incomingReport: any;
  form: any;
  fieldHidden: any;
  subscription: any;
  forms: any;
  constructor(private broadcastChannel: BroadcastChannelService,
              public serviceImpl: AppService,
              public formService: FormService,
              public dashboardMetthods: DashboardService,
              public formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FilterDashboardComponent>) {
    this.incomingReport = new Parameters();
    this.incomingReport.setAttributes(this.data.report);
    this.forms = serviceImpl.app.data.forms.data;
    this.regionalElement = ElementRef;
    this.formElement = ElementRef;
    this.applyElement = ElementRef;
    this.startingAt = new Date();
    this.endingAt = new Date();
    this.incomingForm = new Parameters();
  }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(result => {
      this.formValues = this.formBuilder.group({ });
      this.startingAt = new Date(this.incomingReport.getStartingAt());
      this.endingAt   = new Date(this.incomingReport.getEndingAt());
      this.filteredRegionsOptions = this.regionControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterRegion(name) : this.regions.slice())
        );
      this.filteredFormsOptions = this.formControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterForm(name) : this.forms.slice())
        );
      setTimeout(() => {
        if(this.incomingReport.attributes.hasOwnProperty('form_id')){
          this.formChecked = true;
          this.formControl.setValue(this.forms.filter((form: any) => form.id === this.incomingReport.getFormId())[0]);
        }


        this.applyElement.nativeElement.focus();
      });
    });
    this.dialogRef.afterClosed().subscribe( result => {
      const elements = document.getElementsByClassName('cdk-overlay-pane'); let l = 0;
      for (l = 0; l < elements.length; l++) {
        //elements[l].style.pointerEvents = "none";
      }
    });

  }

  ngSubmit(){
    if(this.incomingReport.getDisplay() === 'excel') this.extract();
    else {
      this.dashboardMetthods.data = null;
      this.subscription = this.broadcastChannel.emitNavChangeEvent('report');
    }this.dialogRef.close();
  }



  onFormChecked(event: any){
    if(this.incomingReport.attributes.hasOwnProperty('form_id'))
      delete this.incomingReport.attributes.form_id;
    if(event.checked === false){
      if(this.formSelected !== null) {
        this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, {form_id: this.formSelected.id});
      }
    }
  }

  onSelectStartingAt(event: any){
    this.endingAt = event > this.endingAt ? event : this.endingAt;
    this.startingAt = event;
    this.incomingReport.setStartingAt(moment(event).format('MM/DD/YYYY'));
  }

  onSelectEndingAt(event: any){
    this.startingAt = event < this.startingAt ? new Date(event) : this.startingAt;
    this.endingAt = event;
    this.incomingReport.setEndingAt(moment(new Date(event)).format('MM/DD/YYYY'));
  }



  private _filterRegion(name: string) {
    const filterValue = name.toLowerCase();
    return this.regions.filter((option: any) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterForm(name: string) {
    const filterValue = name.toLowerCase();
    return this.forms.filter((option: any) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }



  onFormTextChanged(event: any){
    this.formSelected = null;
    this.formChecked = false;
    if(this.incomingReport.attributes.hasOwnProperty('form_id'))
    delete this.incomingReport.attributes.form_id;
  }


  onFormSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formSelected = event.option.value;
    this.incomingForm.setAttributes(event.option.value);
    this.formChecked = true;
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes,{ form_id: this.incomingForm.getId()});
    this.formService.formSelectedId = this.incomingForm.attributes.id;
  }




  extract(){
    this.form = document.createElement('form');
    this.form.action  = this.serviceImpl.app.settings.routes.server + '/reports';
    this.form.method  = 'get';
    this.form.enctype = 'multipart/form-data';
    this.fieldHidden  = document.createElement('input');
    this.fieldHidden.name  = 'token';
    this.fieldHidden.type  = 'hidden';
    this.fieldHidden.value = this.serviceImpl.getToken();
    this.form.appendChild(this.fieldHidden);
    for (const key in this.incomingReport.attributes) {
    this.fieldHidden       = document.createElement('input');
    this.fieldHidden.name  = key;
    this.fieldHidden.type  = 'hidden';
    this.fieldHidden.value = this.incomingReport.getAttributes()[key];
    this.form.appendChild(this.fieldHidden);
    }
    $('#form').append(this.form);
    this.form.submit();
  }


  displayFn(value: any){
    return value.name;
  }

  openDateMenu(event: any){
  }

  ngAfterContentChecked(): void {

  }

  onSubmit(event: any) {
  }


  ngAfterViewChecked(): void {
  }

  ngOnDestroy() {
  //this.subscription.unsubscribe();
 // this.broadcastChannel.emitNavChangeEvent('resubscribe');
  }

}
