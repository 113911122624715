<table [ngStyle]="{'width':'100%','margin-bottom':'10px'}">
    <tr>
        <td [ngStyle]="{'width':'100%'}"><h4>{{incomingCriteria.getTitle()}}</h4></td>
        <td title="export"><a class="pull-right cursor"  (click)="export()"><i class="material-icons">description</i></a></td>
        <td><a class="pull-right cursor" (click)="closeDialog()" ><i class="material-icons">close</i></a></td>
    </tr>
    <tr>
        <td colSpan="2">
            <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="mdl-shadow--2dp">
                <table [ngStyle]="{'min-width':'350px','margin':'5px'}">
                    <td [ngStyle]="{'width':'100%'}">
                        <input  [ngStyle]="{'-webkit-transform':'scaleX(1.37)','color':'#5A738E','text-align':'center','border-width':'0px','width':'100%'}"
                                autocomplete="off"
                                ngClass="sawasdee18 searchbar"
                                (input)="onInputTextChanged(searchText.value)"
                                #searchText
                                placeholder="">
                    </td>
                    <td>
                        <mat-spinner
                                *ngIf="this.service.matSpinner"
                                class=""
                                color='warn'
                                [diameter]="20">
                        </mat-spinner>
                    </td>
                    <td>
                        <mat-icon ngClass="cursor" (click)="onSearchButtonClicked(searchText.value)">search</mat-icon>
                    </td>
                </table>
            </div>
        </td>
    </tr>
</table>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <app-list-criterials></app-list-criterials>
</div>