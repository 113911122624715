<app-teams-form [form]="form"></app-teams-form>
<!--<a class="pull-left"><h4>{{form.name}}</h4></a>-->
<!--<a class="pull-right"  [mat-dialog-close]="" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>-->
<!--<hr />-->
<!--<mat-dialog-content>-->
<!--    <form [formGroup]=formValues>-->
<!--        <div class="row">-->
<!--            <div>-->
<!--                <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                    <input matInput-->
<!--                           #userElement-->
<!--                           type="text"-->
<!--                           placeholder="search for invitees .."-->
<!--                           [(ngModel)]="searchCntrl"-->
<!--                           [formControl] = "userControl"-->
<!--                           [matAutocomplete] = "ambassador">-->
<!--                    <mat-autocomplete #ambassador = "matAutocomplete" (optionSelected)="onAmbassadorSelectionChanged($event, userElement.value)" [displayWith]="displayFn">-->
<!--                        <mat-question *ngFor="let ambassador of filteredUsersOptions | async; let ambassadorOf = index" [value]= "ambassador" >-->
<!--                            {{ambassador.name}} <i>{{ ambassador.phone}}</i>-->
<!--                        </mat-question>-->
<!--                    </mat-autocomplete>-->
<!--                    <i class="material-icons pull-right" [ngStyle]="{'cursor': 'pointer'}" (click)="addInvitee()">add</i>-->
<!--                </mat-form-field>-->
<!--            </div>-->
<!--            <br><br><br>-->
<!--            <div class="col-md-12 col-sm-12">-->
<!--                <mat-list>-->
<!--                    <mat-list-item *ngFor="let invitee of formInvitees() | jsonFilter : searchCntrl ; let inviteeOf = index">-->
<!--                        <span class="" mat-line>{{invitee.name}} <i>{{ invitee.phone}}</i></span>-->
<!--                        <i class="material-icons pull-right" [ngStyle]="{'cursor': 'pointer'}" (click)="removeInvitee(invitee)">close</i>-->
<!--                    </mat-list-item>-->
<!--                </mat-list>-->
<!--            </div>-->
<!--        </div>-->
<!--    </form>-->
<!--</mat-dialog-content>-->
