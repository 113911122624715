<div class="container body sdbar">
    <div class="main_container">
        <!-- top navigation -->
        <div class="top_nav">
            <div class="nav_menu">
                <nav class="" role="navigation">
                    <ul class="nav navbar-nav navbar-rightx">
                        <li role="presentation"
                            [ngStyle]="{'cursor':'pointer'}"
                            (click)="openDateMenu()"
                            class="nav-item dropdown open">
                            <a class="dropdown-toggle info-number"  data-toggle="dropdown" aria-expanded="false">
                                <h5>{{service.header}}</h5>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li class="nav-item dropdown">
                            <a  class="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                <img src="{{(app.data.user.avatar) ? app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="{{app.data.user.name}}" width="29" height="29">
                                {{app.data.user.name|slice:0:7}}
                                <span class=" fa fa-angle-down"></span>
                            </a>
                            <ul class="dropdown-menu dropdown-usermenu pull-right">
                                <li (click)="setUser(app.data.user);openProfile()"><a > Profile</a></li>
                                <li>
                                    <a href="javascript:;">
                                        <span class="badge bg-red pull-right">50%</span>
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li><a href="javascript:;">Help</a></li>
                                <li (click)="logout()"><a href=""><i class="fa fa-sign-out pull-right"></i> Log Out</a></li>
                            </ul>
                        </li>
                        <li role="presentation"
                            [ngStyle]="{'cursor':'pointer'}"
                            (click)="openDateMenu()"
                            class="nav-item dropdown open">
                            <a class="dropdown-toggle info-number"  data-toggle="dropdown" aria-expanded="false">
                                <i class="material-icons">keyboard_arrow_down</i>
<!--                            <i class="material-icons">access_time</i>-->
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <!-- /top navigation -->
        <!-- page content -->
        <div class="right_col" role="main">
            <router-outlet></router-outlet>
        </div>
        <!-- /page content -->

        <!-- footer content -->
        <footer>
<!--            <div class="pull-right">-->
<!--                Design and Developed By  <a href="am.mashindano.co">mashindanogear@gmail.com</a>-->
<!--            </div>-->
            <div class="clearfix"></div>
        </footer>
        <!-- /footer content -->
    </div>
</div>

