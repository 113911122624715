import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slicePipe'
})
export class SlicePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if(!value)return null;
    if(!args)return value;

    args = args.toLowerCase();

    let results: any = value.filter((item: any) =>{
      return JSON.stringify(item).toLowerCase().includes(args);
    });
    return results.slice(0, 2);
  }

}
