import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class InventoriesService {
  incomingReport: any;
  inventories: any = {data: [], tashed: {data: []}};
  isDialogOpen: any = false;
  selected: any = {};

  getSelected(): void {
        return this.selected;
  }

  /**
   * @param mixed Selected
   */


  setSelected(Selected: any): void {
     this.selected = Selected;
  }

  constructor() {
    this.incomingReport = new Parameters();
    /**
     * @param mixed incomingReport
     */
    this.incomingReport.setAttributes({});

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setTime('datebetween');

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setTarget('all');

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setType('inventories');

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setDisplay('dashbaord');

    /**
     * @param mixed incomingReport
     */

    this.incomingReport.setData(null);
  }

  getInventories(): void {
      return this.inventories;
    }

    /**
     * @param mixed inventories
     */


    setInventories(Inventories: any): void {
      this.inventories = Inventories;
    }
    

}
