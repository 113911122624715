import { Component, OnInit } from '@angular/core';
import {DepartmentsService} from "./departments.service";

@Component({
  selector: 'app-tabs-departmets',
  templateUrl: './tabs-departmets.component.html',
  styleUrls: ['./tabs-departmets.component.scss']
})
export class TabsDepartmetsComponent implements OnInit {

  departments: any;
  constructor(public departmentMetthods: DepartmentsService) {
    this.departments = this.departmentMetthods.getData();
  }

  ngOnInit(): void {
  }

}
