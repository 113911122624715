import { Injectable } from '@angular/core';
import {AppService} from "../app.service";
import {Parameters} from "../parameters";

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(private service: AppService) {

  }

  add(params: JSON, call: any){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes(params);
    this.service.httpService('POST', this.service.app.routes.entities.add,
        outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (entity: any) => {
          call(entity);
        }, (error: any) => {call(null);});
  }
}
