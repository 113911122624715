<center>
    <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px" >
</center>
<div class="row plist" style="margin-top: 5%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <section class="post-section blog-post" style="background-color: transparent !important;">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 col-xs-12"
                         (click)="selectUser(ambassador)"
                         *ngFor="let ambassador of (appService.parseFloat(incomingUser.getRoleId()) === 2 || appService.parseFloat(incomingUser.getRoleId()) === 1 ? incomingForm.getInvitees().data : (incomingForm.getInvitees().data | jsonPropertyFilter: teamId.toString():'team_id'))" style="cursor: pointer">
                        <div class="post-box">
                            <div class="text-box">
                                <i class="material-icons pull-right"
                                   (mousedown)="incomingSelectedAmbassador.setAttributes(ambassador)"
                                   (contextmenu)="outgoingRequest.setUserId(ambassador.id);incomingSelectedAmbassador.setAttributes(ambassador); onContextMenu($event, ambassador)">
                                    drag_indicator
                                </i>
                                <center>
                                    <h4>
                                        <a>{{ambassador.name}}</a>
                                    </h4>
                                    <p [innerHTML]="ambassador.description"></p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
    <div class="col-md-2"></div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="getListingReport()">Listing report</button>
    </ng-template>
</mat-menu>




