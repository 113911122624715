import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {NotifierService} from 'angular-notifier';
import {Parameters} from '../parameters';
import {ImplDistrictsService} from './impl-districts.service';
import {RegionsService} from '../regionals/regions.service';

@Component({
  selector: 'app-dialog-districtal',
  templateUrl: './dialog-districtal.component.html',
  styleUrls: ['./dialog-districtal.component.scss']
})
export class DialogDistrictalComponent implements OnInit {

  selectedDistrictalStatus: any;
  incomingSelectedDistrictal: any;
  incomingSelectedRegional: any;
  formValues: FormGroup = new FormGroup({});
  districtals: any;
  configAngularEditor: any;
  constructor(
    public dialogRef: MatDialogRef<DialogDistrictalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public serviceImpl: AppService,
    public regionalImpl: RegionsService,
    public districtalImpl: ImplDistrictsService,
    private notifier: NotifierService,
    public formBuilder: FormBuilder) {
    this.districtals = districtalImpl.getData();
    this.incomingSelectedDistrictal = new Parameters();
    this.incomingSelectedRegional = new Parameters();
    this.incomingSelectedRegional.setAttributes(regionalImpl.getSelected());
    this.incomingSelectedDistrictal.setAttributes(districtalImpl.getSelected());
    this.selectedDistrictalStatus = Object.keys(this.incomingSelectedDistrictal.getAttributes()).length;
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {
      this.districtalImpl.setSelected({});
    });
    this.configAngularEditor = this.serviceImpl.setAngularEditorConfig();
    this.formValues = this.formBuilder.group({
      name: ['', Validators.compose([])],
      region_id: ['', Validators.compose([])],
      description: ['', Validators.compose([])],
    });

    this.formValues.controls['region_id'].setValue(this.incomingSelectedRegional.getId());
    if (this.selectedDistrictalStatus === 0) this.incomingSelectedDistrictal.setAttributes({}); else {
      for (var key in this.incomingSelectedDistrictal.getAttributes()) {
        if (this.incomingSelectedDistrictal.attributes[key] instanceof Object || this.incomingSelectedDistrictal.attributes[key] instanceof Array) {
        } else {
          if (this.formValues.contains(key))
            this.formValues.controls[key].setValue(this.incomingSelectedDistrictal.attributes[key]);
          else this.formValues.addControl(key, new FormControl(parseFloat(this.incomingSelectedDistrictal.getId()), Validators.compose([])));
        }
      }
    }
  }

  closeDialog(){ this.dialogRef.close(null);}
  onSubmit(districtalForm: any) {
    setTimeout((notifier: any) => {
      this.serviceImpl.httpService(
        'post',
        this.selectedDistrictalStatus === 0 ? '/districts' : '/districts/' + parseFloat(this.incomingSelectedDistrictal.getId()) + '/update',
        districtalForm, {},
        (response: any) => {
          if(this.selectedDistrictalStatus === 0) {
            this.districtals.data.unshift(response);
            this.notifier.notify('success', 'successfull added');
            this.formValues.controls['name'].setValue(null);
            this.formValues.controls['description'].setValue(null); } else{
            this.incomingSelectedDistrictal = Object.assign(this.incomingSelectedDistrictal.getAttributes(), response);
            this.notifier.notify('success', 'successfull updated');
            this.closeDialog();
          }
        }, (error: any) => {
        });
    });
  }

}
