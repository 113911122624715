import {AfterContentInit, Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogCampaignsComponent} from './dialog/dialog-campaigns.component';
import {CampaignService} from './campaign.service';
import * as moment from "moment";
import {Parameters} from "../parameters";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, AfterContentInit {
  app: any;
  campaigns: any;
  clients: any;
  data: any;
  trashed: any;
  constructor(public service: AppService,
              private dialog: MatDialog,
              private campaignService: CampaignService) {
    this.app = service.app;
    this.app.form.modules  = [{path: '/app/campaigns', title: 'Campaigns'}];
    this.app.form.subtitle = 'planned sequence of activities and processes which promote an individual product, service, or resources. A multitude of channels are used and coordinated to deliver effective results. ... Successful marketing campaigns cannot be disjointed and haphazard.';
    this.app.form.module   = 'campaigns';
  }

  ngOnInit(): void {
    this.campaignService.setData(this.app.data.campaigns);
  }

  openDialog(){
    this.campaignService.setSelected({});
    this.campaignService.openDialog({});
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Open').toString().toUpperCase() + ' ,campaigns';
      this.service.header = ('Campaign').toString().toUpperCase() + ' (' + this.app.data.campaigns.data.length + ') ,registration';
    });
  }



}
