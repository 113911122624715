import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {TeamsComponent} from '../../teams/teams.component';
import {Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {EmployeesService} from '../employees.service';
import {DialogEmployeesComponent} from '../dialog-employees/dialog-employees.component';
import {UserService} from '../../users/user.service';
import {DialogPasswordUserComponent} from '../../users/dialog-password-user/dialog-password-user.component';

@Component({
  selector: 'app-table-employees',
  templateUrl: './table-employees.component.html',
  styleUrls: ['./table-employees.component.scss']
})
export class TableEmployeesComponent implements OnInit,  OnDestroy{
  @Input() flag: any;
  @Input() role: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedEmployee: any;
  employees: any;
  incomingSelectedEmployee: any;
  subscription: any;
  searchCntrl: any;
  user: any;
  strings: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              private userService: UserService,
              private router: Router,
              private employeeService: EmployeesService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.employees = this.app.data.employees;
    this.incomingSelectedEmployee = new Parameters();
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty(this.strings.getSearchText())) {
            this.searchCntrl = item.searchText;
            if(!(item.hasOwnProperty(this.strings.getStatus()) && item[this.strings.getStatus()] === 0))
              this.employeeService.search(this.searchCntrl, (output: any) => {
              });
          }
        }

      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  handleDestroy(inspector: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.employees,
        model: this.selectedEmployee,
        title: this.incomingSelectedEmployee.getName(),
        url  : '/employees/' + parseFloat(this.incomingSelectedEmployee.getEmployeeId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedEmployee.getId(),
      },
      width : 'auto',
    });
  }

  getUser(){
   return this.user;
  }

  setUser(user: any){
   this.user = this.userService.getUserByUser(user);
  }

  getCampanyByEmployee(employee: any){
    const campany:any = this.app.data.campanies.data.filter((campany: any) => { return campany.id === employee.campany_id;})[0];
    employee = Object.assign(employee, {campany: this.service.only(campany)});
    return campany;
  }

  handlePassword(employee: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.employeeService.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.userService.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.dialog.open(DialogPasswordUserComponent, {data  : {}, width : '55%', height: '100%', });
  }

  handleUpdate(employee: any){
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.employeeService.setSelected(this.incomingSelectedEmployee.getAttributes());
    this.employeeService.openDialog();
  }

  getEmployeesByemployees(employees: any){
   return this.role === undefined ? employees : employees.filter((employee) => { return employee.role.display_name.toString().toLowerCase().includes(this.role);});
  }

  onViewSelected(employee:any){
   this.incomingSelectedEmployee = new Parameters();
   this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
   this.employeeService.setSelected(this.incomingSelectedEmployee.getAttributes());
   this.userService.openUserProfileDialog(this.incomingSelectedEmployee.getAttributes());
  }

  handleRestore(inspector: any){
    this.incomingSelectedEmployee.setAttributes(this.selectedEmployee);
    this.service.restore('/employees/' + parseFloat(this.incomingSelectedEmployee.getEmployeeId()) + '/restore',
      this.employees, 'id', parseFloat(this.incomingSelectedEmployee.getId()), this.selectedEmployee);
  }




  showMessage(message: any) {
  }

}
