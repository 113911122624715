<center>
    <div id="pdfTable" #pdfTable style="color: #0f0f0f !important; font-family: 'Google Sans' !important;">
        <table>
        <tr>
                <td>{{incomingCampaign.getName().toString()}}</td>
                <td></td>
                <td></td>
        </tr>
            <thead>
                <tr style="">
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'font-weight':'bold'}"></td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{("visted").toString().toUpperCase()}} <br>{{("outlets").toString().toUpperCase()}}</td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{("outlets").toString().toUpperCase()}} <br> {{("that").toString().toUpperCase()}} <br> {{("sell").toString().toUpperCase()}}</td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}" *ngFor="let question of service.responses[0].questions; let incresement = index">
                        {{question.label.toString().toUpperCase()}}
                    </td>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor let-team [ngForOf]="service.responses" let-incremental="index">
                    <tr>
                        <td [ngStyle]="{'font-weight':'bold'}" [colSpan]="(2 + service.responses.length)">{{team.name.toString().toUpperCase()}}</td>
                        <td *ngIf="!service.isNull(incomingFormSettings.getOutlet())"></td>
                        <td *ngIf="!service.isNull(incomingFormSettings.getOutlet())"></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let user of team.members">
                        <ng-container *ngIf="!service.isNull(user.name)">
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-right': '1px solid #5f6368','font-weight':'bold'}">{{user.name.toString().toUpperCase()}}</td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-right': '1px solid #5f6368','font-weight':'bold'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{user.outlets}}</td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-right': '1px solid #5f6368','font-weight':'bold'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{user.outlets_that_sell}}</td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}" *ngFor="let question of user.questions">{{question.sum}}</td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}"></td>
                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{team.outlets}}</td>
                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}" *ngIf="!service.isNull(incomingFormSettings.getOutlet())">{{team.outlets_that_sell}}</td>
                        <td [ngStyle]="{'border-bottom': '1px solid #5f6368','font-weight':'bold'}" *ngFor="let question of team.questions">{{question.sum}}</td>
                    </tr>
                </ng-template>
            </tbody>

        </table>
    </div>
</center>
