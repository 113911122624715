import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from "../app.service";
import {Parameters} from "../parameters";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy{
  formValues: FormGroup = new FormGroup({});
  app: any;
  incomingUser: any;
  constructor(private appService: AppService,
              private router: Router,
              public formBuilder: FormBuilder) {
    this.app = this.appService.app;
    this.incomingUser = new Parameters();
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    this.formValues = this.formBuilder.group({
      name:     ['', Validators.compose([])],
      phone:    ['', Validators.compose([])],
      email:    ['', Validators.compose([])],
      password: ['', Validators.compose([])],
      role_id:  ['23', Validators.compose([])],
    });
  }


  onSubmit(formValues: any) {
    if(formValues.hasOwnProperty('email')){
      setTimeout((notifier: any) => {
        this.appService.httpService('post', this.appService.app.routes.auth.register, formValues,
            {}, (response: any) => {
              localStorage.setItem(JSON.parse(JSON.stringify(this.appService.app)).settings.tokenName, response.token);
              this.appService.setToken(response.token);
              this.incomingUser.setAttributes(response.user);
              this.appService.app.data.user = this.incomingUser.getAttributes();
              this.appService.app.data.status = 1;
              this.router.navigateByUrl(this.appService.app.routes.app.payments.channels);
            }, (error: any) => {
            });
      });
    }
  }

  ngOnDestroy(): void {
  }

}
