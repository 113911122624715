import { Injectable } from '@angular/core';
import {Parameters} from "../parameters";
import {MatDialog} from "@angular/material/dialog";
import {ListDialogCriteriasComponent} from "./dialog/list-dialog-criterias/list-dialog-criterias.component";
import {BroadcastChannelService} from "../broadcast-channel.service";
import {AppService} from "../app.service";

@Injectable({
  providedIn: 'root'
})
export class CriteriasService {

  selected: any = {};
  isDialogOpen: any = false;
  constructor(private dialog: MatDialog,
              private service: AppService,
              private broadcastChannel: BroadcastChannelService) {
  }

  getSelected(){
    return this.selected;
  }

  setSelected(criteria: any){
    this.selected = criteria;
  }

  openDialoglist() {
    setTimeout(() => {
      if (this.isDialogOpen === false) {
        this.isDialogOpen = true;
        let dialogRef = this.dialog.open(ListDialogCriteriasComponent, {
          data: {params: this.selected},
          width: '120%',
          height: '100%',
          disableClose: false,
        });
        dialogRef.afterOpened().subscribe(() => {
          setTimeout(() => {
            this.broadcastChannel.emitNavChangeEvent({criteria: this.selected});
          },this.service.timeout());
        });
        dialogRef.afterClosed().subscribe(() => {
          this.isDialogOpen = false;
        });
      }
    });
  }
}
