<div layout="row">
  <div class="col-lg-0 col-md-0 col-sm-0 col-xs-0"></div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mdl-shadow--2dpx" [ngStyle]="{'padding':'0px','margin':'0px'}">
    <div class="panell panel-defaultt cChoice" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
      <div class="panel-bodyy">
        <div>
          <app-label-create-control [question]="question"></app-label-create-control>
        </div>
        <div class="row">
          <ng-container *ngIf="!(service.parseFloat(incomingFormSettings.getUnknown('lfd')) === 1 || service.parseFloat(incomingQuestionSettings.getIsMenu()) === 1);
                         else largeScreenDisplay">
            <center>
              <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group">
                <mat-radio-button
                        class="example-radio-buttonn"
                        #checked
                        [checked]="isChecked(checked, option)"
                        (contextmenu)="onContextMenu($event, option)"
                        (mousedown)="incomingSelectedOption.setAttributes(option);"
                        (click)="radioChange($event, option)"
                        *ngFor="let option of question.options"
                        [value]="option">
                  <a class="sawasdee20" [ngStyle]="{'color': formService.color}">{{service.setSmallCharacters(option.label.toString())}}</a>
                </mat-radio-button>
              </mat-radio-group>
            </center>
          </ng-container>

          <ng-template #largeScreenDisplay>
            <ng-container>
              <div [ngStyle]="{'margin-top':'20px'}">
                <div class="pg">
                  <center>
                    <div class="hidden-md hidden-lg">
                      <mat-radio-group
                              aria-labelledby="example-radio-group-label"
                              class="example-radio-group">
                        <mat-radio-button
                                class="example-radio-buttonn"
                                #checked
                                [checked]="isChecked(checked, option)"
                                (contextmenu)="onContextMenu($event, option)"
                                (mousedown)="incomingSelectedOption.setAttributes(option);"
                                (click)="radioChange($event, option)"
                                *ngFor="let option of question.options"
                                [value]="option">
                          <a class="sawasdee20" [ngStyle]="{'color': formService.color}" [innerHTML]="option.label"></a>
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div class="hidden-sm hidden-xs">
                      <section class="post-section blog-post" style="background-color: transparent !important;scale: 1 1;animation: deg45 5s infinite">
                        <div class="container">
                          <div class="row">
                              <div class="{{question.options.length === 1 ? '': 'col-lg-4 col-md-6'}} cursor"
                                   (click)='radioChange({}, option)'
                                   *ngFor="let option of question.options">
                                  <div class="post-box" [ngStyle]="{'background-color':(question.result.hasOwnProperty(option.cntrl_num) ? 'gold' : ''),'width': 'fit-content','float':question.options.length === 1 ? 'none': 'left'}">
                                      <div class="text-box">
                                          <h4>
                                              <ng-container *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 5;else labelText">
                                                  <a class="sawasdee20" [ngStyle]="{'color':'#5A738E','font-size':'28px'}">
                                                      {{service.upperStartingCharacter(option.label, true)}}
                                                  </a>
                                              </ng-container>
                                              <ng-template #labelText>
                                                  <ng-container>
                                                      <a class="sawasdee20" [ngStyle]="{'color':'#5A738E'}"
                                                      [innerHTML]="option.label | safeUrl">
<!--                                                          {{service.upperStartingCharacter(option.label, true)}}-->
                                                      </a>
                                                  </ng-container>
                                              </ng-template>
                                          </h4>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </center>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
        <div class="row" >
          <app-other-option-questions [question]="question" [position]="position"></app-other-option-questions>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-0 col-md-0 col-sm-0 col-xs-0"></div>
</div >
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="inspector">
    <button mat-menu-item (click)="handleUpdate(incomingSelectedOption.getAttributes())">Edit</button>
    <button mat-menu-item (click)="removeOption(incomingSelectedOption.getAttributes())">Delete</button>
  </ng-template>
</mat-menu>
