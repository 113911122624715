<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<a class=""><h4>{{!incomingOutletSelected.attributes.hasOwnProperty('id') ? 'Outlet': incomingOutletSelected.getName()}}</h4></a>
<br>
<div>
    <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
        <mat-list>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Name *</mat-label>
                    <input matInput type="text"  formControlName="name">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width" appearance='fill'>
                     <mat-label> Channel *</mat-label>
                     <input type="text"
                            #clientElement
                            placeholder=""
                            aria-label = "Number"
                            matInput
                            (input)="onChangeClientText($event)"
                            [formControl] = "clientControl"
                            [matAutocomplete] = "client">
                                <mat-autocomplete #client = "matAutocomplete"
                                                  (optionSelected)="onClientSelectionChanged($event)"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let client of filteredClientsOptions | async; let clienteOf = index" [value]= "client" >
                                        {{client.name}}
                                    </mat-option>
                                </mat-autocomplete>
                    <h4 style="color: red; font-weight: normal"
                        *ngIf="service.isNull(clientElement.value) === false &&
                          ((filteredClientsOptions | async) | json).length === 2">
                          **---Not Found *---</h4>
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Owner *</mat-label>
                    <input matInput type="text"  formControlName="owner">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Phone *</mat-label>
                    <input matInput type="text"  formControlName="phone">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Email *</mat-label>
                    <input matInput type="text"  formControlName="email">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Opened at *</mat-label>
                    <input matInput type="text"  formControlName="open_at">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Closed at *</mat-label>
                    <input matInput type="text"  formControlName="closed_at">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Address *</mat-label>
                    <input type="text"
                           id="address"
                           matInput
                           formControlName="location"
                           placeholder=""
                           [value]=addressValue
                           [address]="addressValue"
                           (input)="onAddressTextChange($event)"
                           (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                           (onLocationSelected)="onLocationSelected($event)"
                           matGoogleMapsAutocomplete />
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Landmark *</mat-label>
                    <input  matInput type="text"
                           formControlName="landmark">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Latitude *</mat-label>
                    <input matInput
                           id="lat"
                           type="text"
                           formControlName="lat">
                  </mat-form-field>
                </span>
            </mat-list-item>
            <mat-list-item>
                <span class="" mat-line>
                  <mat-form-field  class="example-full-width">
                    <mat-label>Longitude *</mat-label>
                    <input
                            matInput
                            type="text"
                            id="lng"
                            formControlName="lng">
                  </mat-form-field>
                </span>
            </mat-list-item>
        </mat-list>
        <div class="row">
            <mat-form-field
                    class="example-full-width"
                    appearance='fill'>
                <mat-label>Description</mat-label>
                <textarea matInput placeholder=""
                          formControlName="description"
                          [ngStyle]="{'height':'50px'}"
                          #message
                          ></textarea>
                <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <center>
                    <div class="example-button-row">
                        <input type="submit" class="btn btn-md submit-btn defaultColor" value="{{keys === 0 ? 'Done' : 'Edit'}}" >
                    </div>
                </center>
            </div>
        </div>
    </form>
</div>

