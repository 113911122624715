import { Component, OnInit } from '@angular/core';
import {TeamService} from '../team.service';

@Component({
  selector: 'app-tabs-teams',
  templateUrl: './nav-teams.component.html',
  styleUrls: ['./nav-teams.component.scss']
})
export class NavTeamsComponent implements OnInit {

  teams: any;
  constructor(private metthods: TeamService) {
  }

  ngOnInit(): void {
    this.teams = this.metthods.getData();
  }



}
