import {Component, Input, OnInit} from '@angular/core';
import {ChartService} from '../../../chart.service';

@Component({
  selector: 'app-echart-pyramid',
  templateUrl: './echart-pyramid.component.html',
  styleUrls: ['./echart-pyramid.component.scss']
})
export class EchartPyramidComponent implements OnInit {
  @Input() element;
  theme: any;
  opts: any;
  constructor(private chartService: ChartService ) { }

  ngOnInit(): void {
    this.theme = this.chartService.theme();
    this.opts = this.options();
  }

  options(){
    const opt: any = {
      title: {
        text: 'Echart Pyramid Graph',
        subtext: 'Subtitle'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c}%'
      },
      toolbox: {
        show: true,
        feature: {
          restore: {
            show: true,
            title: 'Restore'
          },
          saveAsImage: {
            show: true,
            title: 'Save Image'
          }
        }
      },
      legend: {
        data: ['Something #1', 'Something #2', 'Something #3', 'Something #4', 'Something #5'],
        orient: 'vertical',
        x: 'left',
        y: 'bottom'
      },
      calculable: true,
      series: [{
        name: '漏斗图',
        type: 'funnel',
        width: '40%',
        data: [{
          value: 70,
          name: 'Something #1'
        }, {
          value: 40,
          name: 'Something #2'
        }, {
          value: 25,
          name: 'Something #3'
        }, {
          value: 80,
          name: 'Something #4'
        }, {
          value: 100,
          name: 'Something #5'
        }]
      }]
    };
    return opt;
  }

}
