import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {Router} from '@angular/router';
import {MethodsService} from '../../methods.service';
import {HttpClient} from '@angular/common/http';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-ui',
  templateUrl: './login-ui.component.html',
  styleUrls: ['./login-ui.component.scss']
})
export class LoginUiComponent implements OnInit {

  parameters: FormGroup = new FormGroup({});
  app: any;
  socialUser: SocialUser = new SocialUser();
  loggedIn: any = false;
  appsettingsUrl = '../assets/json/app.json';
  constructor(
      private router: Router,
      private socialAuthService: SocialAuthService,
      private appComponent: AppComponent,
      public httpClient: HttpClient,
      public formBuilder: FormBuilder,
      public appService: AppService ) {
  }

  ngOnInit(): void {
    this.appsettingsUrl = this.appsettingsUrl + '?version=' + this.appService.random();
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
    this.app = this.appService.app;
    this.parameters = this.formBuilder.group({
      email:    ['', Validators.compose([ Validators.required])],
      password: ['', Validators.compose([ Validators.required, Validators.minLength(5)
      ])]
    });
    this.socialAuthService.authState.subscribe((socialUser) => {
      this.socialUser = socialUser;
      const incomingSocialUser: Parameters = new Parameters();
      incomingSocialUser.setAttributes(socialUser);
      this.router.navigateByUrl('/app/services/add');
    });
  }

  onSubmit(loginForm: NgForm){
    this.appService.httpService(
        'post',
        this.app.settings.routes.authenticate,
        loginForm, {},
        (response: any) => {
          localStorage.setItem(JSON.parse(JSON.stringify(this.appService.app)).settings.tokenName, response.token);
          this.appService.setToken(response.token);
          this.appService.httpService( 'get', this.appsettingsUrl, {}, {},
              (result: any) => {
                this.app.settings = result;
                this.appService.app = Object.assign(this.appService.app, {settings: result});
                if(this.appService.app.hasOwnProperty('data')) delete this.appService.app.data;
                this.appService.setNavigatedUrl(window.location.href);
                window.localStorage.setItem('routeName',  'null');
                window.localStorage.setItem('formId'   ,  'null');
                this.appComponent.main();
              },  (error: any) => {
              });
        }, (error: any) =>  {
        });
  }

  authenticateUser(params: any){
    this.appService.httpService(
        'post',
        this.app.settings.routes.authenticateUser,
        params, {},
        (response: any) => {
          this.appService.app = Object.assign(this.appService.app, {data: response});
          if(this.appService.getNavigatedUrl().includes('services/add'))
            this.router.navigateByUrl('/app/services/add');
        }, (error: any) =>  {
        });
  }

  register(url: any){
    // this.router.navigateByUrl(url);
  }

  signInWithGoogle(): void {
    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut() {
    // this.socialAuthService.signOut();
  }
}
