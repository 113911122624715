import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {AppService} from "../../../../../app.service";

@Component({
  selector: 'app-donut-piechat-chatjs',
  templateUrl: './donut-piechat-chatjs.component.html',
  styleUrls: ['./donut-piechat-chatjs.component.scss']
})
export class DonutPiechatChatjsComponent implements OnInit, AfterViewInit {

  @Input() element: any;
  @Input() color: any;
  @Input() labels: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;

  transparentColor: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
  }

  ngOnInit(): void {
  }
  
  options(){
    let opt: any = {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.labels,
            data: this.data,
            backgroundColor: ['#4c84ff', '#29cc97', '#8061ef', '#fec402'],
            borderWidth: 1
            // borderColor: ['#4c84ff','#29cc97','#8061ef','#fec402']
            // hoverBorderColor: ['#4c84ff', '#29cc97', '#8061ef', '#fec402']
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          show: false
        },
        cutoutPercentage: 75,
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return 'Order : ' + data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']];
            }
          },
          titleFontColor: '#888',
          bodyFontColor: '#555',
          titleFontSize: 12,
          bodyFontSize: 14,
          backgroundColor: 'rgba(256,256,256,0.95)',
          displayColors: true,
          borderColor: 'rgba(220, 220, 220, 0.9)',
          borderWidth: 2
        }
      }
    };
    
    return opt;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const myChart: any = new Chart(this.ctx, this.options());
    });
  }


}
