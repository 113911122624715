<a class="pull-left"><h4>{{incomingTeam.getName().toUpperCase() + ''}} {{keys === 0 ? 'Brunch ' : incomingSelectedBrunch.getName()}}, registration</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width" appearance="fill">
             <mat-label> Teams *</mat-label>
             <input type="text"
                    #teamElement
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "teamControl"
                    [matAutocomplete] = "team">
                        <mat-autocomplete #team = "matAutocomplete"
                                          (optionSelected)="onTeamSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option *ngFor="let team of filteredTeamsOptions | async; let teamOf = index" [value]= "team" >
                                {{team.name}}
                            </mat-option>
                        </mat-autocomplete>
<!--          <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addTeam()">add</i>-->
              <h4 style="color: red !important; font-weight: normal"
                  *ngIf="service.isNull(teamElement.value) === false &&
                          ((filteredTeamsOptions | async) | json).length === 2">
                          **---Not Found *---</h4>
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address *</mat-label>
            <input type="text"
                   matInput
                   formControlName="location"
                   placeholder=""
                   #teamElement
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onAddressTextChange($event)"
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, teamElement.value)"
                   (onLocationSelected)="onLocationSelected($event)"
                   matGoogleMapsAutocomplete >
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item>
          <span class="" mat-line>
               <mat-form-field class="example-full-width">
                  <mat-label>Description</mat-label>
                  <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
                  <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
          </span>
        </mat-list-item>
        </mat-list>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <center>
                    <div class="example-button-row">
                        <input type="submit" class="btn btn-success btn-xs submit-btn headline" value="{{keys === 0 ? 'Register' : 'Edit'}}"/>
                    </div>
                </center>
            </div>
        </div>
</form>
