import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from '../../forms/form.service';
import {ActivatedRoute} from '@angular/router';
import {CampaignService} from '../../campaigns/campaign.service';
import {CampanyService} from '../../campanies/campany.service';
import {ClientService} from '../../clients/client.service';
import {DashboardService} from '../../dashboard/dashboard.service';
import {Parameters} from '../../parameters';
import * as html2pdf from 'html2pdf.js';
import * as moment from "moment/moment";

@Component({
  selector: 'app-ambassadors-final-report',
  templateUrl: './ambassadors-final-report.component.html',
  styleUrls: ['./ambassadors-final-report.component.scss']
})
export class AmbassadorsFinalReportComponent implements OnInit, AfterViewInit, AfterContentInit {

  incomingReport: any;
  incomingCampaign: any;
  incomingClient: any;
  incomingForm: any;
  formId: any;
  incomingSettings: any;
  object: any;
  incomingCampany: any;
  incomingUser: any;
  constructor(public  service: AppService,
              private formService: FormService,
              public activateRouter: ActivatedRoute,
              private campaignService: CampaignService,
              private campanyService: CampanyService,
              private clientService: ClientService,
              public  dashboardService: DashboardService) {
    this.incomingReport = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingForm  = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingCampany = new Parameters();
    this.incomingUser = new Parameters();
    this.formId = this.activateRouter.snapshot.params.id;
    this.incomingReport.setAttributes(this.dashboardService.incomingReport.getAttributes());
    this.incomingForm.setAttributes(this.formService.getFormById(formService.formSelectedId));
    this.incomingSettings.setAttributes(this.incomingForm.getSettings());
    this.incomingSettings.setOutlet(this.incomingSettings.attributes.hasOwnProperty('outlet') ? this.incomingSettings.getOutlet() : '');
    this.object = Object;
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingCampany.setAttributes(this.campanyService.getCampanyById(this.incomingCampaign.getCampanyId()));
    this.incomingUser.setAttributes(this.service.user);
  }

  ngOnInit(): void {
  }

  getDateFormat(){
    return this.incomingReport.getStartingAt().toString().includes(this.incomingReport.getEndingAt()) ?
        moment(new Date(this.incomingReport.getStartingAt())).format('MMMM D, YYYY') :
        moment(new Date(this.incomingReport.getStartingAt())).format('MMMM D, YYYY') + ' - ' +
        moment(new Date(this.incomingReport.getEndingAt())).format('MMMM D, YYYY');
  }

  getKey(item: any, position: any): string {
    return Object.keys(item)[position];
  }

  getOptions(){
    const opt: any = {
      margin: 1,
      filename: 'filename.pdf',
      image: {type: 'jpeg', quality: 10},
      html2canvas: {scale: 7, logging: true},
    //jsPDF: {unit: 'in', format: 'a4', orientation: 'l'}
    };
  }

  ngAfterViewInit(): void {
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      const data: any = document.getElementById('pdfTable');
      // html2pdf(data).then(canvas => {
      //   const contentDataURL = canvas.toDataURL('image/png');
      //   const pdf: any = new jsPDF('l', 'cm', 'a4');
      //   pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      //   pdf.save('Filename.pdf');
      html2pdf()
          .from(data)
          .set(this.getOptions())
          .toPdf()
          .save('filename.pdf').then(() => {
      });

    }, this.service.timeout());
  }

}
