<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input  matInput
                                [(ngModel)]="formService.selected.name"
                                placeholder="Untitled Form"
                                style="text-align: center; font-size: 30px"
                                #message >
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
