import { Injectable } from '@angular/core';
import {AppService} from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private service: AppService) {
  }

  getRoleById(roleId: any){
    var roles: any = [] = this.service.app.data.roles.filter((role: any) => {
      return parseFloat(role.id) === parseFloat(roleId);});
    return roles[0];
  }

  getRoles(){
    return this.service.app.data.roles.filter((role: any) => {
      return parseFloat(role.id) === 8 ||
          parseFloat(role.id) === 14;
    });
  }

}
