import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-selection-campaign',
  templateUrl: './selection-campaign.component.html',
  styleUrls: ['./selection-campaign.component.scss']
})
export class SelectionCampaignComponent implements OnInit {

  constructor(public service: AppService) { }

  ngOnInit(): void {
  }

}
