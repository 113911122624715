<div class="" style="width: 100%;height: {{getHeight() + 'px'}}">
    <center>
        <div class="row">
<!--            <img class="img-circle profile_img" src="{{formService.onSelectProduct.campaign.client.logo}}" [ngStyle]="{'width':'auto','max-height':'60px','margin':'5px'}"><br>-->
        </div>
        <div class="row" style="margin-left: 20px; margin-right: 20px">
            <div class="container">
                <h2>{{formValues.name}}</h2>
                <p>{{formValues.description}}</p>
            </div>
            <div *ngFor="let q of options">{{q.index}}//{{q.label}}</div>
        </div>
    </center>
    <div style="width: 100%; margin-top: 10%">
        <center>
            <div class="row">
                <div class="col-md-2 col-sm-12 x-12"></div>
                <div class="col-md-8 col-sm-12 xs-12" *ngIf="formService.start">
                    <div class="row"> <app-untitled-form></app-untitled-form> </div>
                    <div class="row"> <app-description-form></app-description-form> </div>
                </div>
                <div class="col-md-8 col-sm-8" >
                    <div class="row" *ngIf="!formService.start">
                        <app-text-preview-controls        [question]="question"  *ngIf="question.control.id === 1"></app-text-preview-controls>
                        <app-image-preview-controls       [question]="question"  *ngIf="question.control.id === 2"></app-image-preview-controls>
                        <app-check-preview-controls       [question]="question"  *ngIf="question.control.id === 3"></app-check-preview-controls>
                        <app-select-preview-controls      [question]="question"  *ngIf="question.control.id === 4"></app-select-preview-controls>
                        <app-create-choice-control        [question]="question"  *ngIf="question.control.id === 5"></app-create-choice-control>
                        <app-address-preview-controls     [question]="question"  *ngIf="question.control.id === 6"></app-address-preview-controls>
                        <app-video-preview-controls       [question]="question"  *ngIf="question.control.id === 7"></app-video-preview-controls>
                        <app-number-preview-controls      [question]="question"  *ngIf="question.control.id === 10"></app-number-preview-controls>
                        <app-date-preview-controls        [question]="question"  *ngIf="question.control.id === 11"></app-date-preview-controls>
                        <app-description-preview-controls [question]="question"  *ngIf="question.control.id === 12"></app-description-preview-controls>
                        <app-rate-preview-controls        [question]="question"  *ngIf="question.control.id === 13"></app-rate-preview-controls>
                        <app-outlet-preview-controls      [question]="question"  *ngIf="question.control.id === 14"></app-outlet-preview-controls>
                        <app-sheet-preview-controls       [question]="question"  *ngIf="question.control.id === 15"></app-sheet-preview-controls>
                        <app-textarea-preview-controls    [question]="question"  *ngIf="question.control.id === 16"></app-textarea-preview-controls>
                        <app-groups-preview-controls      [question]="question"  *ngIf="question.control.id === 18"></app-groups-preview-controls>
                        <app-image-choice-respond-control [question]="question"  *ngIf="question.control.id === 20"></app-image-choice-respond-control>
                        <app-coordinate-respond-controls  [question]="question"  *ngIf="question.control.id === 22"></app-coordinate-respond-controls>
                        <app-respond-time-controls        [question]="question"  *ngIf="question.control.id === 19"></app-respond-time-controls>
                        <app-respond-email-controls       [question]="question"  *ngIf="question.control.id === 23"></app-respond-email-controls>
                        <app-respond-table-controls       [question]="question"  *ngIf="question.control.id === 24"></app-respond-table-controls>
                    </div>
<!--                <div *ngIf="formService.submit"><app-submit-controls></app-submit-controls></div>-->
                    <div class="row" style="position: fixed;top: 40%;right: 20px">
                        <a mat-mini-fab class="defaultColor"
                           *ngIf="!formService.start"
                           (click)="rollback()"
                           title="Back Previous Question">
                           <i class="material-icons">chevron_left</i>
                        </a>
                        <a mat-mini-fab class="defaultColor"
                           (click)="next()" title="Go Next Question">
                           <i class="material-icons">chevron_right</i>
                        </a>
                    </div>
<!--                </div>-->
                </div>
                <div class="col-md-2 col-sm-12 xs-12"></div>
            </div>
        </center>
    </div>
    <div style="margin-top: 100px" [hidden]="formService.questions.length === 0">
        <center><a mat-flat-button class="defaultColor" (click)="addQuestion()">Add Next Question</a></center>
    </div>
</div>

