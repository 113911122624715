<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-body">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>

                <div>
                    <center>
<!--                        <div [ngStyle]="{'position': 'fixed','top':'15px','right':'30px','z-index':'9999999999'}">-->
<!--                            <div [ngStyle]="{'border-radius': '25px','background':'#fff'}" class="mdl-shadow&#45;&#45;2dp">-->
<!--                                <div [ngStyle]="{'margin': '2px'}">-->
<!--                                    <ul class="list-inline">-->
<!--                                        &lt;!&ndash;                                        <li>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                            <i class="material-icons voice">search</i>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                        </li>&ndash;&gt;-->
<!--                                        <li class="">-->
<!--                                            <input type="text"-->
<!--                                                   #finder-->
<!--                                                   matInput-->
<!--                                                   autocomplete="off"-->
<!--                                                   placeholder=""-->
<!--                                                   ngClass="searchbar mitra"-->
<!--                                                   placeholder=""-->
<!--                                                   [ngStyle]="{'text-align': 'center'}">-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </center>
                    <section class="post-section blog-post" style="background-color: transparent !important;">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 cursor"
                                     (click)="onPanelClicked(option)"
                                     *ngFor="let option of (question.options)">
                                    <div class="post-box {{!this.service.isNull(question.data) && question.data.toString() === option.label.toString() ? 'gbox' : ''}}">
                                        <div class="text-box">
                                            <h4>
                                                <a class="pull-left sawasdee20">{{service.upperStartingCharacter(service.removeHtmlTags(option.label), true)}}</a>
                                                <a class="pull-right">{{optionalProgress(option)}} %</a>
                                            </h4>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <!--                    <div *ngFor="let option of question.options"-->
                    <!--                         (click)="onPanelClicked(option)"-->
                    <!--                         class="cursor panel panel-default {{!this.service.empty(question.data) && question.data.toString() === option.label.toString() ? 'gbox' : ''}}"-->
                    <!--                         [ngStyle]="{'cursor':'pointer','width':'100%','border-top-size':'50px','border-top-color':'transparent'}">-->
                    <!--                        <div class="panel-body mdl-shadow&#45;&#45;2dp">-->
                    <!--                            <div style="padding: 20px">-->
                    <!--                                <h2 class="pull-left sawasdee20l">{{option.label.toString().toUpperCase()}}</h2>-->
                    <!--                                <h4 class="pull-right sawasdee20">{{optionalProgress(option)}} %</h4>-->
                    <!--                            </div>-->
                    <!--                         </div>-->
                    <!--                    </div>-->
                    <!--                <mat-radio-group-->
                    <!--                            aria-labelledby="example-radio-group-label"-->
                    <!--                            class="example-radio-group"-->
                    <!--                            [(ngModel)]="data">-->
                    <!--                        <mat-radio-button-->
                    <!--                            #checked-->
                    <!--                            (change)="radioChange($event, question)"-->
                    <!--                            class="example-radio-buttonn helvetica"-->
                    <!--                            *ngFor="let question of question.options"-->
                    <!--                            [value]="question">-->
                    <!--                            <span style="">{{question.label}}{{reset(checked)}}</span>-->
                    <!--                        </mat-radio-button>-->
                    <!--                    </mat-radio-group>-->
                    <!--                    <section class="recent-row">-->
                    <!--                        <div class="container">-->
                    <!--                            <div class="row">-->
                    <!--                                <div class="col-md-12 col-sm-12">-->
                    <!--                                    <div id="content-area">-->
                    <!--                                        <ul id="myList">-->
                    <!--                                            <li style="display: list-item;">-->
                    <!--                                                <div class="box">-->
                    <!--                                                    <div class="text-col">-->
                    <!--                                                        <h4><a href="#">Marketing Graphic Design / 2D Artist</a></h4>-->
                    <!--                                                    </div>-->
                    <!--                                                </div>-->
                    <!--                                            </li>-->
                    <!--                                            <li style="display: list-item;">-->
                    <!--                                                <div class="box">-->
                    <!--                                                    <div class="text-col">-->
                    <!--                                                        <h4><a href="#">Marketing Graphic Design / 2D Artist</a></h4>-->
                    <!--                                                    </div>-->
                    <!--                                                </div>-->
                    <!--                                            </li>-->
                    <!--                                        </ul>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </section>-->

                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
