import {Component, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import * as moment from 'moment';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {DialogClientsComponent} from '../../clients/dialog-clients.component';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {EmployeesService} from '../employees.service';
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {CampanyService} from "../../campanies/campany.service";
import {UserService} from '../../users/user.service';

@Component({
  selector: 'app-dialog-employees',
  templateUrl: './dialog-employees.component.html',
  styleUrls: ['./dialog-employees.component.scss']
})
export class DialogEmployeesComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) campanyAutocompleteTrigger: any;
  @ViewChild(MatAutocompleteTrigger) roletAutocompleteTrigger: any;
  app: any;
  flag: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  campanyControl = new FormControl();
  departmentControl = new FormControl();
  roleControl = new FormControl();
  filteredCampaniesOptions: any;
  filteredDepartmentsOptions: any;
  filteredRolesOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedEmployee: any;
  keys: any;
  selectedEmployee: any;
  addressValue: any;
  avatar: any;
  startingAt: any;
  endingAt: any;
  clients: any;
  subscription: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public metthods: EmployeesService,
              public campanyService: CampanyService,
              private userService: UserService,
              public broadcastChannel: BroadcastChannelService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogEmployeesComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedEmployee = new Parameters();
    this.incomingSelectedEmployee.setAttributes(metthods.getSelected());
    this.keys = Object.keys(this.incomingSelectedEmployee.getAttributes()).length;
    this.selectedEmployee = metthods.getSelected();
    this.clients = this.app.data.clients.data;
    // this.flag = this.data.params.hasOwnProperty('flag') ? this.data.params.flag : 3;
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('campany') === true) {
              this.campanyControl.setValue(item.campany);
              this.formValues.controls['campany_id'].setValue(item.campany.id);
            }
          }

    });

    this.dialogRef.afterClosed().subscribe(result => {this.metthods.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['' , Validators.compose([])],
      role_id:     ['', Validators.compose([])],
      campany_id:  ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
      address:     ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      department_id: ['1', Validators.compose([])],
    });

 // this.formValues.get('location').valueChanges.subscribe(value => console.log('value changed', ''));
    if(this.keys === 0) this.selectedEmployee = {}; else{
      this.formValues.controls['name'].setValue(this.incomingSelectedEmployee.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedEmployee.getDescription());
      this.formValues.controls['location'].setValue(this.incomingSelectedEmployee.getLocation());
      this.formValues.controls['address'].setValue(this.incomingSelectedEmployee.getLocation());
      this.addressValue = this.incomingSelectedEmployee.getLocation();
      for(var key in this.incomingSelectedEmployee.getAttributes()) {
        if(this.incomingSelectedEmployee.attributes[key] instanceof Object || this.incomingSelectedEmployee.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedEmployee.attributes[key]);
          }else this.formValues.addControl(key, new FormControl(this.incomingSelectedEmployee.attributes[key], Validators.compose([])));
        }
      }
    }

    setTimeout(() => {
      this.filteredCampaniesOptions = this.campanyControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterCampany(name) : this.app.data.campanies.data.slice())
      );

      this.filteredDepartmentsOptions = this.departmentControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterDepartment(name) : this.app.data.departments.data.slice())
      );

      this.filteredRolesOptions = this.roleControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterRole(name) : this.getRoles().slice())
      );


      if(this.keys === 0){
        this.departmentControl.setValue(this.app.data.departments.data[0]);
        this.campanyControl.setValue(this.app.data.campanies.data[0]);
        this.formValues.controls['campany_id'].setValue(this.app.data.campanies.data[0].id); } else{
        this.campanyControl.setValue(this.getCampanyById(this.selectedEmployee.campany_id));
        this.roleControl.setValue(this.app.data.roles.filter((role: any) => { return parseFloat(role.id) === parseFloat(this.selectedEmployee.role_id);})[0]);
        this.departmentControl.setValue(this.app.data.departments.data.filter((department: any) => { return parseFloat(department.id) === parseFloat(this.selectedEmployee.department_id);})[0]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addDepartment(){
    setTimeout((department) => {
      department = prompt('', '');
      if (department === null){} else {
        this.service.httpService(
            'post', '/departments', {name: department}, {},
            (response: any) => {
              this.service.app.data.departments.data.unshift(response);
              this.broadcastChannel.emitNavChangeEvent({department: response});
              this.departmentControl.setValue(response);
              this.formValues.controls['department_id'].setValue(response.id);
              }, (error: any) => {
            });
      }
    });


  }


  oncampanyfocusMatAutocomplete(){
    this.campanyAutocompleteTrigger._onChange('');
    this.campanyAutocompleteTrigger.openPanel();
  }

  getRoles(){return this.app.data.roles.filter((role: any) => { return parseFloat(role.id) === 2 || parseFloat(role.id) === 18 || parseFloat(role.id) === 15;});}

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  onrolefocusMatAutocomplete(){
    this.roletAutocompleteTrigger._onChange('');
    this.roletAutocompleteTrigger.openPanel();
  }

  getClient(campaign: any){ return this.clients.filter((client: any) => { return client.id === campaign.client_id; })[0]; }
  getCampanyById(id: any) { return this.app.data.campanies.data.filter((campany: any) => { return parseFloat(id) === parseFloat(campany.id); })[0];}

  onGoogleMapAutocompleteSelected(result: PlaceResult, address: any) {
    this.formValues.controls['location'].setValue(address);
  }

  onlocationSelected(location: any) {
  }

//onLocationSelected(location: Location) {
//   console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){
  }

  addCampany(){
    this.campanyService.setSelected({});
    this.campanyService.openDialog({});
    this.notifier.notify('success', 'add campany where by employee is working there');
  }

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['avatar'].setValue(this.service.dataURItoBlob(reader.result));
      this.avatar = reader.result;
    };
  }

  addClient(){
    this.dialog.open(DialogClientsComponent, {data  : {}, width : '55%', height: '100%', });
  }



  private _filterCampany(name: string) {
    const filterValue = name.toLowerCase();
    return this.app.data.campanies.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  private _filterRole(name: string) {
    const filterValue = name.toLowerCase();
    return this.getRoles().filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  onChangeAddressText(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
  }

  private _filterDepartment(name: string) {
    const filterValue = name.toLowerCase();
    return this.app.data.departments.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }


//handleAddressChange(result: PlaceResult){
//}

  onCampanySelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['campany_id'].setValue(event.option.value.id);
  }

  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(employeeForm: any) {
    if(employeeForm.hasOwnProperty('password')) delete employeeForm.password;
    this.service.httpService(
      'post',
      this.keys === 0 ? '/employees' : '/employees/' + this.incomingSelectedEmployee.getEmployeeId() + '/update',
      employeeForm, {},
      (response: any) => {
        if(this.keys === 0) {
          this.service.app.data.employees.data.unshift(response);
        } else {
          this.selectedEmployee = Object.assign(this.selectedEmployee, response);
          let user: any = this.userService.getUserByUser(this.selectedEmployee);
        }
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        this.selectedEmployee = {};
        this.broadcastChannel.emitNavChangeEvent({employee: response});
      }, (error: any) => {
      });

  }

  onDepartmentSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['department_id'].setValue(event.option.value.id);
  }

}
