import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";
import {FormService} from "../form.service";

@Component({
  selector: 'app-description-form',
  templateUrl: './description-form.component.html',
  styleUrls: ['./description-form.component.scss']
})
export class DescriptionFormComponent implements OnInit {

  constructor(public service: AppService, public formService: FormService) { }
  ngOnInit(): void {
  }

}
