import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';

@Component({
  selector: 'app-dashbaord-regional',
  templateUrl: './dashbaord-regional.component.html',
  styleUrls: ['./dashbaord-regional.component.scss']
})
export class DashbaordRegionalComponent implements OnInit {
  regions: any;
  constructor(public impl: AppService) {
    this.regions = impl.app.data.regions.data;
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
  }

}
