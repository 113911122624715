<div layout="row"  *ngIf='status === undefined'>
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <h4 id="example-radio-group-label"> {{question.required === 1 ? ' * ' : ''}} {{question.label}}</h4>
                </div>
                <div>
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label></mat-label>
                    <!--<i class="material-icons pull-right">location_on</i>-->
                        <input type="text"
                               matInput
                               #addressElement
                               formControlName="location"
                               placeholder=""
                               style="font-size: 30px; text-align: center"
                               [value]=addressValue
                               [address]="addressValue"
                               (input)="onAddressTextChange($event)"
                               (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                               (onLocationSelected)="onLocationSelected($event)"
                               matGoogleMapsAutocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>


<div *ngIf='status !== undefined'>
    <textarea
            autocomplete="off"
            placeholder=""
            #addressElement
            class='headline2'
            type="text"
            [autofocus]="true"
            [address]="addressValue"
            (input)="onAddressTextChange($event)"
            (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
            (onLocationSelected)="onLocationSelected($event)"
            [ngStyle]="{'max-height':'400px','resize': 'none', 'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"
            matGoogleMapsAutocomplete></textarea>
</div>