<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 cChoice">
        <div class="panell panel-defaultt"
             [ngStyle]="{'width':'100%','border-top-width':'0px','border-top-color':'#263238'}">
            <div class="panel-bodyy">
                <div [ngStyle]="{'margin-bottom': '50px'}">
                    <span class="pull-left">
                      <app-label-create-control [question]="question"></app-label-create-control>
                    </span>
                    <!--                <a mat-mini-fab class="defaultColor pull-right cursor"-->
                    <!--                       [ngStyle]="{'position':'absolute','top':'10px','right':'20px'}">-->
                    <!--                        <i class="material-icons " (click)="add()">add </i>-->
                    <!--                </a>-->
                    <br>
                </div>
                <br>
                <div>
                    <mat-list>
                        <mat-list-item>
                            <span class="" mat-line>
                              <mat-form-field  class="example-full-width" appearance='fill'>
                                <mat-label [ngStyle]="{'color':'#fff'}">search for business   or /outlet name </mat-label>
                                <input matInput
                                       type="text"
                                       class="sawasdee14Wht"
                                       [formControl] = "outletControl"
                                       [matAutocomplete] = "outlett"
                                       (input)="onInputOutletText($event);searchCntrl=outlet.value"
                                       #outlet>
                                  <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner>
                                  <mat-icon matSuffix
                                            [ngStyle]="{'color':'#fff'}"
                                            (click)="addOutlet()"
                                            class='pull-right'>
                                      add
                                  </mat-icon>
                                  <mat-autocomplete #outlett = "matAutocomplete"
                                                    (optionSelected)="onOutletSelectionChanged($event)" [displayWith]="displayFn">
                                        <ng-container *ngFor="let outlet of filteredOutletOptions | async; let outletOf = index">
                                           <mat-option class="sawasdee16"
                                                       [value]= "outlet"
                                                       *ngIf="isRoutePlan(outlet)">
                                            {{outlet.name}}, <span class="">{{outlet.address}}, {{outlet.landmark}}</span>
                                           </mat-option>
                                        </ng-container>
                                  </mat-autocomplete>
                              </mat-form-field>
                            </span>
                        </mat-list-item>
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Outlet Segment *</mat-label>-->
<!--                                <input matInput-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       #outletTypeElement-->
<!--                                       type="text"-->
<!--                                       (input)="onChangeOutletSegmentText($event)"-->
<!--                                       [formControl] = "outletSegmentControl"-->
<!--                                       [matAutocomplete] = "outletSegment">-->
<!--                                  <mat-icon class="material-icons pull-right"-->
<!--                                            matSuffix-->
<!--                                            [ngStyle]="{'cursor':'pointer'}"-->
<!--                                            (click)="openPanel($event)">-->
<!--                                      keyboard_arrow_down-->
<!--                                  </mat-icon>-->
<!--                                  <mat-autocomplete #outletSegment = "matAutocomplete"-->
<!--                                                    (optionSelected)="onOutletSegmentSelectionChanged($event)"-->
<!--                                                    [displayWith]="displayFn">-->
<!--                                    <mat-option *ngFor="let outletSegment of filteredOutletSegmentOptions | async; let outletSegmentOf = index" [value]= "outletSegment" >-->
<!--                                      {{outletSegment.name}}-->
<!--                                    </mat-option>-->
<!--                                 </mat-autocomplete>-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Outlet Type *</mat-label>-->
<!--                                <input matInput-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       #outletTypeElement-->
<!--                                       type="text"-->
<!--                                       (input)="onInputCategory($event)"-->
<!--                                       [formControl] = "outletTypeControl"-->
<!--                                       [matAutocomplete] = "outletTypee">-->
<!--                                  <mat-icon class="material-icons pull-right"-->
<!--                                            matSuffix-->
<!--                                            [ngStyle]="{'cursor':'pointer'}"-->
<!--                                            (click)="openPanel($event)">-->
<!--                                      keyboard_arrow_down-->
<!--                                  </mat-icon>-->
<!--                                  <mat-autocomplete #outletTypee = "matAutocomplete"-->
<!--                                                    (optionSelected)="onOutletTypeSelectionChanged($event)"-->
<!--                                                    [displayWith]="displayFn">-->
<!--                                    <mat-option *ngFor="let outletType of filteredOutletTypeOptions | async; let outletTypeOf = index" [value]= "outletType" >-->
<!--                                      {{outletType.name}}-->
<!--                                    </mat-option>-->
<!--                                 </mat-autocomplete>-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Owner's full name  *</mat-label>-->
<!--                                <input matInput-->
<!--                                       #owner-->
<!--                                       type="text"-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       [(ngModel)]="incomingResult.attributes.owner">-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Owner's mobile number *</mat-label>-->
<!--                                <input matInput-->
<!--                                       #phone-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       type="text"-->
<!--                                       [(ngModel)]="incomingResult.attributes.phone" />-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Counter sales person name *</mat-label>-->
<!--                                <input matInput-->
<!--                                       #phone-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       type="text"-->
<!--                                       [(ngModel)]="incomingResult.attributes.shopkeeper" />-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                              <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                <mat-label class="sawasdee20Wht">Counter sales person number *</mat-label>-->
<!--                                <input matInput-->
<!--                                       #phone-->
<!--                                       class="sawasdee20Wht"-->
<!--                                       type="text"-->
<!--                                       [(ngModel)]="incomingResult.attributes.shopkeeper_contact" />-->
<!--                              </mat-form-field>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.upperStartingCharacter('Outlet Stock Condom ?',true)}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checkedOutletStockCondom-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_condom') && !this.service.empty(incomingResult.getUnknown('outlet_stock_condom')) &&incomingResult.getUnknown('outlet_stock_condom').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_condom',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.upperStartingCharacter('Outlet Stock Oral Contraceptives ?',true)}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_oral_contraceptives') && !this.service.empty(incomingResult.getUnknown('outlet_stock_oral_contraceptives')) && incomingResult.getUnknown('outlet_stock_oral_contraceptives').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_oral_contraceptives',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.upperStartingCharacter('Outlet Stock Emergency Contraceptives?',true)}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_emergency_contraceptives') && !this.service.empty(incomingResult.getUnknown('outlet_stock_emergency_contraceptives')) && incomingResult.getUnknown('outlet_stock_emergency_contraceptives').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_emergency_contraceptives',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.upperStartingCharacter('Outlet Stock Sanitary Pads?',true)}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_sanitary_pads') && !this.service.empty(incomingResult.getUnknown('outlet_stock_sanitary_pads')) && incomingResult.getUnknown('outlet_stock_sanitary_pads').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_sanitary_pads',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.upperStartingCharacter('Outlet Stock Baby diapers ?',true)}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_baby_diapers') && !this.service.empty(incomingResult.getUnknown('outlet_stock_baby_diapers')) && incomingResult.getUnknown('outlet_stock_baby_diapers').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_baby_diapers',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                            <span class="" mat-line>-->
<!--                               <center>-->
<!--                                  <div class="sawasdee20Wht">{{service.setSmallCharacters('Outlet Stock Adult diapers ?')}}</div>-->
<!--                                  <mat-radio-group-->
<!--                                          aria-labelledby="example-radio-group-label"-->
<!--                                          class="example-radio-group">-->
<!--                                    <mat-radio-button-->
<!--                                            class="example-radio-buttonn"-->
<!--                                            #checked-->
<!--                                            [checked]="incomingResult.getAttributes().hasOwnProperty('outlet_stock_adult_diapers') && !this.service.empty(incomingResult.getUnknown('outlet_stock_adult_diapers')) && incomingResult.getUnknown('outlet_stock_adult_diapers').includes(option.label)"-->
<!--                                            (click)="incomingResult.setUnknown('outlet_stock_adult_diapers',option.label)"-->
<!--                                            *ngFor="let option of options"-->
<!--                                            [value]="option">-->
<!--                                      <a class="sawasdee20Wht"-->
<!--                                         [ngStyle]="{'color': formService.color}">-->
<!--                                          {{service.setSmallCharacters(option.label.toString())}}-->
<!--                                      </a>-->
<!--                                    </mat-radio-button>-->
<!--                                  </mat-radio-group>-->
<!--                                </center>-->
<!--                            </span>-->
<!--                        </mat-list-item>-->
<!--                        &lt;!&ndash;                    <mat-list-item>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <span class="" mat-line>&ndash;&gt;-->
<!--                        &lt;!&ndash;                              <mat-form-field  class="example-full-width">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                <mat-label>Opened at *</mat-label>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                <input matInput type="text"  [(ngModel)]="incomingResult.attributes.opened_at">&ndash;&gt;-->
<!--                        &lt;!&ndash;                              </mat-form-field>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </mat-list-item>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <mat-list-item>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <span class="" mat-line>&ndash;&gt;-->
<!--                        &lt;!&ndash;                              <mat-form-field  class="example-full-width">&ndash;&gt;-->
<!--                        &lt;!&ndash;                                <mat-label>Closed at *</mat-label>&ndash;&gt;-->
<!--                        &lt;!&ndash;                                <input matInput type="text"  [(ngModel)]="incomingResult.attributes.closed_at">&ndash;&gt;-->
<!--                        &lt;!&ndash;                              </mat-form-field>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            </span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </mat-list-item>&ndash;&gt;-->
<!--                        <mat-list-item>-->
<!--                          <span class="" mat-line>-->
<!--                               <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                    <mat-label class="sawasdee20Wht">Address *</mat-label>-->
<!--                                    <textarea-->
<!--                                            #address-->
<!--                                            type="text"-->
<!--                                            class="sawasdee20Wht"-->
<!--                                            matInput-->
<!--                                            formControlName="location"-->
<!--                                            placeholder=""-->
<!--                                            [value]=addressValue-->
<!--                                            [address]="addressValue"-->
<!--                                            (input)="onInputAddressText($event)"-->
<!--                                            (onChange)="onPlaceChange($event)"-->
<!--                                            (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, address.value)"-->
<!--                                            matGoogleMapsAutocomplete>-->
<!--                                   </textarea>-->
<!--                                  </mat-form-field>-->
<!--                          </span>-->
<!--                        </mat-list-item>-->
<!--                        <mat-list-item>-->
<!--                              <span class="" mat-line>-->
<!--                                   <mat-form-field  class="example-full-width" appearance="outline">-->
<!--                                        <mat-label class="sawasdee20Wht">Landmark</mat-label>-->
<!--                                        <input type="text"-->
<!--                                               matInput-->
<!--                                               formControlName="landmark"-->
<!--                                               placeholder=""-->
<!--                                               class="sawasdee20Wht"-->
<!--                                               [(ngModel)]="incomingResult.attributes.landmark"-->
<!--                                        />-->
<!--                                      </mat-form-field>-->
<!--                              </span>-->
<!--                        </mat-list-item>-->
                        <!--                    <mat-list-item>-->
                        <!--                          <span class="" mat-line>-->
                        <!--                               <mat-form-field  class="example-full-width">-->
                        <!--                                    <mat-label>Description</mat-label>-->
                        <!--                                    <textarea matInput placeholder=""-->
                        <!--                                              [(ngModel)]="incomingResult.attributes.description"-->
                        <!--                                              #message rows="1"></textarea>-->
                        <!--                                  </mat-form-field>-->
                        <!--                          </span>-->
                        <!--                    </mat-list-item>-->
                    </mat-list>
                </div>
                <ng-container *ngIf="service.app.data.hasOwnProperty('routes')">
                    <ng-container *ngIf="service.app.data.routes.hasOwnProperty('plans')">
                        <section class="post-section blog-post"
                                 [ngStyle]="{'background-color': 'transparent'}">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-4 col-sm-6 cursor"
                                         (click)="onRoutePlanSelected(routePlan)"
                                         *ngFor="let routePlan of (service.app.data.routes.plans.data | jsonFilter: searchCntrl)">
                                        <div class="post-box"
                                             [ngStyle]="{'background-color': getColor(routePlan,'red',0)}">
                                            <div class="text-box">
                                                <h4>
                                                    <a class="mitrax"
                                                       [ngStyle]="{'color': getColor(routePlan,'red',0) === 'gold' || getColor(routePlan,'red',0) === 'red' ? '#fff' : ''}">
                                                        {{service.upperStartingCharacter(routePlan.outlet.name,true)}}</a>
                                                </h4>
                                                <p [ngStyle]="{'color': getColor(routePlan,'red',0) === 'gold' || getColor(routePlan,'red',0) === 'red' ? '#fff' : ''}">
                                                    {{service.limitTo(service.upperStartingCharacter(routePlan.outlet.landmark,true), 200)}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
