import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-respond-time-controls',
  templateUrl: './respond-time-controls.component.html',
  styleUrls: ['./respond-time-controls.component.scss']
})
export class RespondTimeControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  label: any;
  constructor(public service: AppService) { }

  ngOnInit(): void {
  }

}
