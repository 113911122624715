import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from './dashboard.service';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {FilterDashboardComponent} from './filter-dashboard.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../app.service';
import {DialogOverallComponent} from './dialog-overall.component';
import {FormService} from '../forms/form.service';
import {Parameters} from '../parameters';
import * as Highcharts from 'highcharts';
import * as $ from 'jquery';
import {CampaignService} from "../campaigns/campaign.service";
import * as High from 'jspdf';

@Component({
  selector: 'app-overall',
  templateUrl: './overall.component.html',
  styleUrls: ['./overall.component.scss']
})
export class OverallComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: any;
  report: any;
  formId: any;
  incomingForm: any;
  incomingReport: any;
  ambassadors: any;
  kpi: any = 0;
  progress: any;
  responses: any = 0;
  teams: any = [];
  position: any;
  team: any;
  markers: any = [];
  app: any;
  lat: any = -6.8643365;
  lng: any = 39.2712821;
  zoom: any = 0;
  updateFlag = false;
  Highcharts: typeof Highcharts = Highcharts;
  questions: any;
  target: any;
  incomingCampaign: any;
  searchCntrl: any;
  constructor(public dashboardService: DashboardService,
              private dialog: MatDialog,
              private formService: FormService,
              private campaignService: CampaignService,
              public router: Router,
              public activateRouter: ActivatedRoute,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService) {
    this.incomingCampaign = new Parameters();
  }

  ngOnInit(): void {

    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#f5f5f5';
    this.incomingForm = new Parameters();
    this.incomingReport = new Parameters();
    this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
    this.incomingReport.attributes = this.dashboardService.incomingReport.attributes;
    this.report = this.incomingReport.attributes.data;
    this.ambassadors = [];
    this.responses = 0;
    this.progress = 0;
    this.app = this.service.app;
    if(this.incomingReport.attributes.form_id === 0 ||
        this.incomingReport.attributes.data === null ||
        this.incomingReport.attributes.form_id !== this.incomingForm.attributes.id){
      this.handleDashboard('dashboard');
    }else this.dashbaord();
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('searchText') === true) {
            this.searchCntrl = item.searchText;
          }else if(item.hasOwnProperty('form')){
            this.dashboardService.incomingSelectedForm.setAttributes(item.form);
            this.formService.setSelected(item.form);
            this.dashboardService.incomingReport.setFormId(item.form.id);
            this.handleDashboard(item);
          }
        }else if (item === 'openDateMenu') {
          this.openMenuDate();
        } else if(item === 'report'){
          this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
          this.handleDashboard(item);
        }
      });

    setTimeout(() => {
      document.title = this.incomingForm.attributes.type.name.toString().toLowerCase().includes('data collection') ? this.incomingCampaign.getName() : this.incomingForm.getName();
      this.service.header = this.incomingForm.getName();
      this.zoom = 10;
    }, 1000);
  }

  getResponses(){
   this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/responses' );
  }

  oncreateFormButtonClicked(){
    this.formService.setSelected({});
    this.formService.openDialog({});
  }

  onextractFormButtonClicked(){

  }

  only(data, label){return data.map((l: any) => { return l[label]; });
  }

  onInfoWindowClose(gm: any, infoWIndow: any){}

  getHeight(){
    return {height: window.innerHeight};
  }

  seeAllRsp(){ this.router.navigateByUrl('/app/forms/' + this.incomingForm.getId() + '/responses');}
  getNames(data: any){ return data.map((l: any) => { return l.name;}); }
  getlabels(data: any, label: any){return data.map((l: any) => { return (l[label]).toString();});}


  getValues(data: any, value: string){return data.map((l: any) => { return {name: l.name, value: l[value]};});}
  getValuesByLableAndValue(data: any, label: any, value: string){return data.map((l: any) => { return {name: l[label], value: l[value]};});}
  dashbaord(){ this.ambassadorss();}
  filter(questions: any){
    return questions.filter((questional: any) => { return questional.control.id === 3 || questional.control.id === 5; });
  }

  infoWindowDetails(){
    let all: any = null;
    this.markers.map((point: any) =>{
      all = (all === null) ? '<h4>' + point.postedBy.name + '</h4>' : all + '<br><hr>' + '<h4>' + point.postedBy.name + '</h4>';
    });
    return all;
  }

  handleHomeNavigator(){ this.router.navigateByUrl('/app/forms');}

  handleNavigator(specie: any){
    this.dialog.open(DialogOverallComponent, {data : {report: this.dashboardService.incomingReport.attributes,  specie: specie}, width : '120%', height: '99%', });
  }

  handleDashboard(item: any){
    this.incomingReport.attributes = Object.assign(this.incomingReport.attributes, { form_id: this.incomingForm.attributes.id});
    setTimeout((outgoingRequest: any = {}) => {
      outgoingRequest = Object.assign(outgoingRequest, this.incomingReport.attributes);
      delete outgoingRequest.data;
      delete outgoingRequest.business_id;
      this.router.navigateByUrl('/wait..');
      this.service.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
        this.dashboardService.incomingReport.attributes.data = response;
        window.history.back();
      }, (error: any) => {
      });
    });
  }

  ambassadorss(){
    this.responses = this.dashboardService.incomingReport.attributes.data.responses;
    this.questions = this.dashboardService.incomingReport.attributes.data.questions;
    this.dashboardService.incomingReport.attributes.data.users.map((ambassador: any) => {
      ambassador  =  Object.assign(ambassador, { progress: this.service.percentageOf(ambassador.responses, ambassador.target)});
      this.kpi = this.kpi + parseFloat(ambassador.target);
   // this.responses = parseFloat(this.responses.toString()) + parseFloat(ambassador.responses);
      this.position = this.service.app.data.ambassadors.trashed.data.findIndex((ambassadorr: any) =>{ return ambassadorr.id === ambassador.id; });
      if(this.position === -1)
      this.ambassadors.push(ambassador);
      return ambassador;
    });
    this.progress = this.service.percentageOf(this.responses, this.kpi);
    // this.teams = this.metthods.report.attributes.data.users.map((ambassador: any) => {
    // this.team = this.service.app.data.teams.data.filter((team: any) => { return team.id === ambassador.team_id; })[0];
    // this.team = Object.assign(this.team, {progress: ambassador.progress, responses: ambassador.responses});
    // return this.team;
    // });
    this.teams = this.service.app.data.teams.data.filter((team: any) => { return team.campaign_id === this.incomingForm.attributes.campaign_id;});
    this.teams = this.teams.map((team: any) => {
      team  = Object.assign(team, {responses: 0});
      this.ambassadors.filter((ambassador: any) => {
        if(ambassador.team_id === team.id){
          team = Object.assign(team, {responses: (parseFloat(team.responses) + parseFloat(ambassador.responses)) });
        }
      });
      return team;
    });
    this.markers = this.dashboardService.incomingReport.attributes.data.markers;
  }

  float(l: any){ return parseFloat(l);}


  openMenuDate(){
    this.dashboardService.incomingReport.setDisplay('dashboard');
    this.dialog.open(FilterDashboardComponent, { data: {report: this.dashboardService.incomingReport.getAttributes()} , width  : '55%', height : '100%'});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    window.localStorage.setItem('monthly', JSON.stringify(this.incomingReport.attributes.data.monthly));
    window.localStorage.setItem('weekly' , JSON.stringify(this.incomingReport.attributes.data.weekly));
    $.getScript( '../assets/js/plot.js', ( data, textStatus, jqxhr ) => {});
 // $.getScript( '../assets/js/flot.js', ( data, textStatus, jqxhr ) => {});
  }


}
