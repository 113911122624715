<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div><app-label-create-control [question]="question"></app-label-create-control></div>
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-body">
                <div class="row">
                    <mat-calendar class="sawasdee20"
                                  [startAt]="date"
                                  [selected]="date"
                                  (selectedChange)="onSelectDate($event)"></mat-calendar>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
