import { Component, OnInit } from '@angular/core';
import {AppService} from '../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {Parameters} from '../../../../parameters';
import {ResponseService} from '../response.service';
import {FormService} from '../../../form.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-responses-form',
  templateUrl: './responses-form.component.html',
  styleUrls: ['./responses-form.component.scss']
})
export class ResponsesFormComponent implements OnInit {
  app: any = {data: null};
  parameters: any;
  params: any;
  selected: any;
  incomingForm: any;
  selectedForm: any;
  formId: any;
  form: any;
  incomingUser: any;
  constructor(private service: AppService,
              private responseService: ResponseService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private formService: FormService,
              public dialog: MatDialog) {
    this.incomingForm = new Parameters();
    this.incomingUser = new Parameters();
    this.app = this.service.app;
    this.service.module = 'responses';
    this.formId = activatedRoute.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.form = this.formService.getFormById(this.formId);
    this.formService.formSelectedId = this.form.id;
    this.incomingForm.setAttributes(this.form);
    this.formService.setSelected(this.form);
    this.app.form.module = 'responses';
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#263238';
  }

  ngOnInit(): void {
    this.service.modules = [{title: this.incomingForm.getResponseDisplayTitle(), path: ''}];
    this.responseService.setData(this.incomingForm.attributes.responses);
  }

  openDialog() {
    if(parseFloat(this.service.app.data.role.id) === 14){
      this.incomingUser.setAttributes(this.service.app.data.user);
      this.incomingForm.setCampaign({name: '', id: this.incomingForm.getCampaignId(), client: {id: 0, name: '', logo: ''}});
      let bytes: any = CryptoJS.AES.encrypt(this.incomingUser.getId().toString(), this.app.settings.encryptKey);
      this.router.navigateByUrl('/app/respondents/' +  this.service.encrypt(this.service.app.data.user.id) + '/responses');
    }
  }

}
