import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {DashboardService} from '../../dashboard/dashboard.service';
import {NotifierService} from 'angular-notifier';
import {FormService} from '../form.service';
import {Router} from '@angular/router';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {map, startWith} from 'rxjs/internal/operators';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Parameters} from "../../parameters";
import {ClientService} from "../../clients/client.service";

@Component({
  selector: 'app-search-forms',
  templateUrl: './search-forms.component.html',
  styleUrls: ['./search-forms.component.scss']
})
export class SearchFormsComponent implements OnInit {
  formControl = new FormControl();
  filteredFormsOptions: any;
  incomingForm: any;
  forms: any;
  campaign: any;
  constructor(public  service: AppService,
              public  campaignService: CampaignService,
              public  clientService: ClientService,
              private dashboardservice: DashboardService,
              private notifier: NotifierService,
              public  formService: FormService,
              private router: Router,
              private broadcastChannelService: BroadcastChannelService) {
    this.incomingForm = new Parameters();
    this.forms = this.service.app.data.forms.data;
    this.campaign = campaignService.getCampaignById(this.campaignService.campaignSelectedId);
 // this.forms = this.service.app.data.forms.data.filter((form: any) => { return form.campaign_id === this.campaignService.campaignSelectedId;});
  }

  ngOnInit(): void {
  //document.getElementsByTagName( 'body')[0].style.backgroundColor = '#263238';
    setTimeout(() => {
      this.filteredFormsOptions = this.formControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterForm(name) : this.forms.slice())
      );
    });
  }

  getCampaignById(id: any){
    return this.campaignService.getCampaignById(id);
  }

  displayFn(value: any){
    return value.name;
  }

  onFormSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.incomingForm.setAttributes(event.option.value);
    this.formService.setSelected(this.incomingForm.attributes);
    this.formService.formSelectedId = this.incomingForm.getId();
    if(this.service.getNavigatedUrl().toString().toLowerCase().includes('assign respondent')){
      this.router.navigateByUrl('/app/forms');
      setTimeout(() => {
        this.broadcastChannelService.emitNavChangeEvent(this.service.getNavigatedUrl());
      }, this.service.timeout());
    }
  }

  onFormSelected(form: any){
    this.incomingForm.setAttributes(form);
    this.formService.formSelectedId = this.incomingForm.getId();
    this.service.module = 'form.view';
    this.router.navigateByUrl('/app/forms/' + this.service.encrypt(form.id) + '/dashboard');
  }

  private _filterForm(name: string) {
    const filterValue = name.toLowerCase();
    return this.forms.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }


}
