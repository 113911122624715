<div class="container body sugg">
  <div class="main_container">
    <!-- top navigation -->
    <div class="top_nav">
      <div class="nav_menu">
        <nav class="" role="navigation">
          <ul class="nav navbar-nav navbar-rightx">
            <li role="presentation"
                [ngStyle]="{'cursor':'pointer'}"
                (click)="openDateMenu()"
                class="nav-item dropdown open">
              <a class="dropdown-toggle info-number"  data-toggle="dropdown" aria-expanded="false">
                <h5>{{keys.length === 0 ? '' : selectedSuggestionBox.name}}</h5>
              </a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li class="nav-item dropdown">
              <a  class="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <img src="{{(app.data.user.avatar) ? app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="{{app.data.user.name}}" width="29" height="29">
                {{app.data.user.name|slice:0:7}}
                <span class=" fa fa-angle-down"></span>
              </a>
              <ul class="dropdown-menu dropdown-usermenu pull-right">
                <li (click)="setUser(app.data.user);openProfile()"><a > Profile</a></li>
                <li>
                  <a href="javascript:;">
                    <span class="badge bg-red pull-right">50%</span>
                    <span>Settings</span>
                  </a>
                </li>
                <li><a href="javascript:;">Help</a></li>
                <li (click)="logout()"><a href=""><i class="fa fa-sign-out pull-right"></i> Log Out</a></li>
              </ul>
            </li>
            <li role="presentation"
                [ngStyle]="{'cursor':'pointer'}"
                (click)="openDateMenu()"
                class="nav-item dropdown open">
              <a class="dropdown-toggle info-number"  data-toggle="dropdown" aria-expanded="false">
                <i class="material-icons">keyboard_arrow_down</i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- /top navigation -->
    <!-- page content -->
    <div class="right_col" role="main" >
      <div class="page">
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-tab-group mat-align-tabs="end" [selectedIndex]="1">
              <mat-tab [disabled]="true">
                <ng-template mat-tab-label>
                  <div (click)="addSuggestionbox()">
                    <center>
                      <br>
                      <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
                      <div [ngStyle]="{'cursor':'pointer'}">Add box</div>
                    </center>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <center>
                    <br>
                    <i class="material-icons">messenger</i><br>
                    <div>Feedback</div>
                  </center>
                </ng-template>
                <br><br>
                <div [hidden]="keys.length === 0">
                  <div class="">
                    <div class="pull-left sugg-input">
                      <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label> Suggestion boxes *</mat-label>
                        <input matInput
                               #suggestionboxElement
                               [formControl] = "formControl"
                               [matAutocomplete] = "box"/>
                        <mat-icon matSuffix [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</mat-icon>
                        <mat-autocomplete #box = "matAutocomplete" (optionSelected)="onFormSelectionChanged($event)" [displayWith]="displayFn">
                          <mat-option *ngFor="let form of filteredFormsOptions | async; let campaignOf = index" [value]= "form" >
                            {{form.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="pull-left sugg-input" style="margin-left: 10px">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>from</mat-label>
                        <input matInput [matDatepicker]="starting_at" [value] = "startingAt" (dateChange)="onSelectStartingAt($event)">
                        <mat-datepicker-toggle matSuffix [for]="starting_at"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #starting_at></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="pull-left sugg-input" style="margin-left: 10px">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>to</mat-label>
                        <input matInput [matDatepicker]="ending_at" [value] = "endingAt" (dateChange)="onSelectEndingAt($event)">
                        <mat-datepicker-toggle matSuffix [for]="ending_at"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #ending_at></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="pull-left" [ngStyle]="{'margin':'4px'}">
                      <a mat-fab class="btn btn-default btn-lg default-color"  (click)="filter()"> Filter </a>
                    </div>
                    <div class="pull-left" [ngStyle]="{'margin':'4px'}">
                      <a mat-fab class="default-color" (click)='onextractClicked()'> Extract
                      </a>
                    </div>
                  </div>
                  <div class="">
                    <div class="pull-right form-group top_search">
                      <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="searchCntrl" placeholder="Search for..."/>
                        <span class="input-group-btn">
                          <button class="btn btn-default"
                                  type ="button"
                          >
                            Go!
                          </button>
                        </span>
                      </div>
                    </div>
                    <div class="pull-right">
                      <a class='btn btn-xs' style="color:#fff"><i class="fa fa-chevron-left"></i></a>
                      <small style="color: #ffffff7d">{{app.settings.client.name.toString() | slice:0:0}}</small>
                      <a class='btn btn-xs' style="color:#fff"><i class="fa fa-chevron-right"></i></a>
                    </div>
                  </div>
                  <div class="row col-lg-12 col-md-12">
                    <br><br>
<!--                <p class="text-muted font-13 m-b-30" style="color:floralwhite">-->
<!--                      Data collection is defined as the procedure of collecting, measuring and analyzing accurate insights for research using standard validated techniques. ... The approach of data collection is different for different fields of study, depending on the required information.-->
<!--                </p>-->
                  </div>
                </div>
                <br>
                <div class="row sugg-table col-lg-12 col-md-12 col-sm-12 col-xs-12" style="height: 300px !mportant">
                  <div >
                    <table mat-table [dataSource]="(dataSource | jsonFilter : searchCntrl)" class="mat-elevation-z8">
                    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let columnIndex = index">
                      <th mat-header-cell *matHeaderCellDef> <span [innerHTML]="initColumns[columnIndex].label"></span> </th>
                      <td mat-cell *matCellDef="let element">
                            <span *ngIf="initColumns[columnIndex].label.toString().toLowerCase().includes('commenter') === false &&
                              initColumns[columnIndex].label.toString().toLowerCase().includes('department') === false &&
                              initColumns[columnIndex].label.toString().toLowerCase().includes('reply') === false">
                              <span *ngIf="initColumns[columnIndex].control.id !== 13">
                                 {{element[column]}}
                              </span>
                              <span *ngIf="initColumns[columnIndex].control.id === 13">
                                 <i class="material-icons {{l.checked === 0 ? '' : 'star'}}"
                                    [ngStyle]="{'cursor':'pointer'}"
                                    *ngFor="let l of handleStar(element[column])">grade</i>
                              </span>
                            </span>
                            <span *ngIf="initColumns[columnIndex].label.toString().toLowerCase().includes('commenter')">
                                  {{element['commenter'].name ?  element['commenter'].name : ''}}
                            </span>
                            <span *ngIf="initColumns[columnIndex].label.toString().toLowerCase().includes('reply')">
                                  <i class="material-icons" (click)="reply(element)" [ngStyle]="{'cursor':'pointer'}" *ngIf="element['commenter'].name">reply</i>
                            </span>
                            <span *ngIf="initColumns[columnIndex].label.toString().toLowerCase().includes('department')">
                              {{element['department'].name}}
                            </span>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  </div>


                  <!--            <div class="x_panel">-->
                  <!--              <div class="x_content" relink-event="datatablex">-->
                  <!--                <div class="table-responsive">-->
                  <!--                  <table class="table table-striped" style="width:100%">-->
                  <!--                    <thead>-->
                  <!--                    <tr>-->
                  <!--                      <th class="mdl-data-table__cell&#45;&#45;non-numeric" *ngFor="let response of (selectedSuggestionBox.responses.data.length === 0 ? [] : selectedSuggestionBox.responses.data[0].datas)"><span [innerHTML]="response.formControl.label"></span></th>-->
                  <!--                      <th class="mdl-data-table__cell&#45;&#45;non-numeric">Posted by</th>-->
                  <!--                      <th class="mdl-data-table__cell&#45;&#45;non-numeric">Address</th>-->
                  <!--                      <th class="mdl-data-table__cell&#45;&#45;non-numeric">Date</th>-->
                  <!--                      <th class="mdl-data-table__cell&#45;&#45;non-numeric">Published</th>-->
                  <!--                      <th class="text-right">Action</th>-->
                  <!--                    </tr>-->
                  <!--                    </thead>-->
                  <!--                    <tbody>-->
                  <!--                    <tr *ngFor="let response of (selectedSuggestionBox.responses.data | jsonFilter:searchCntrl)"-->
                  <!--                        (contextmenu)="onContextMenu($event, response)"-->
                  <!--                        (mousedown)="selectedResponse = response"-->
                  <!--                    >-->
                  <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric" *ngFor="let feedback of response.datas"><center> <div *ngIf="feedback.formControl.control.id !== 2 && feedback.formControl.control.id !== 7"><span [innerHTML]="feedback.data"></span></div></center></td>-->
                  <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.postedBy.name ? response.postedBy.name : ''}}</td>-->
                  <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{(response.address)}}</td>-->
                  <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.date}}</td>-->
                  <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.created_at}}</td>-->
                  <!--                      <td>-->
                  <!--                        <div class="dropdown pull-right">-->
                  <!--                          <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>-->
                  <!--                            <span class="caret"></span>-->
                  <!--                          </button>-->
                  <!--                          <ul class="dropdown-menu">-->
                  <!--                            &lt;!&ndash;                        <li mat-menu-item (click)="handleUpdate(selectedForm)">Edit</li>&ndash;&gt;-->
                  <!--                            &lt;!&ndash;                        <li mat-menu-item (click)="handleDestroy(selectedForm)">Delete</li>&ndash;&gt;-->
                  <!--                            &lt;!&ndash;                        <li mat-menu-item (click)="handleRestore(selectedForm)">Restore</li>&ndash;&gt;-->
                  <!--                          </ul>-->
                  <!--                        </div>-->
                  <!--                        &lt;!&ndash;                    <div style="visibility: hidden; position: fixed"&ndash;&gt;-->
                  <!--                        &lt;!&ndash;                         [style.left]="contextMenuPosition.x"&ndash;&gt;-->
                  <!--                        &lt;!&ndash;                         [style.top]="contextMenuPosition.y"&ndash;&gt;-->
                  <!--                        &lt;!&ndash;                         [matMenuTriggerFor]="contextMenu">&ndash;&gt;-->
                  <!--                        &lt;!&ndash;                    </div>&ndash;&gt;-->
                  <!--                        <mat-menu #contextMenu="matMenu">-->
                  <!--                          <ng-template matMenuContent let-item="inspector">-->
                  <!--                            &lt;!&ndash;                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedForm)">Edit</button>&ndash;&gt;-->
                  <!--                            &lt;!&ndash;                        <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedForm)">Delete</button>&ndash;&gt;-->
                  <!--                            &lt;!&ndash;                        <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedForm)">Restore</button>&ndash;&gt;-->
                  <!--                          </ng-template>-->
                  <!--                        </mat-menu>-->
                  <!--                      </td>-->
                  <!--                    </tr>-->
                  <!--                    </tbody>-->
                  <!--                  </table>-->
                  <!--                  <mat-paginator [length]="100">-->
                  <!--                    [pageSize]="10"-->
                  <!--                    [pageSizeOptions]="[5, 10, 25, 100]"-->
                  <!--                    aria-label="Select page">-->
                  <!--                  </mat-paginator>-->

                  <!--                </div>-->
                  <!--              </div>-->
                  <!--            </div>-->
                </div>
                <div class='row sugg-table col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  <center>
                    <mat-paginator [length]="dataSource.length"
                                   [pageSize]="5"
                                   [pageSizeOptions]="[5, 10, 25, 100]"
                                   aria-label="Select page of feedback search results">
                    </mat-paginator>
                  </center>
                </div>
                <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                  <app-report-question [questions]="getQuestions()"></app-report-question>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <center>
                    <br>
                    <i class="material-icons">settings</i><br>
                    <div>Manage Box</div>
                  </center>
                </ng-template>
                <div [ngStyle]="{'margin-top':'50px'}">
                  <div class="col-md-2 col-sm-2 col-xs-2"></div>
                  <div class="col-md-8 col-sm-8 col-xs-8">
                    <mat-accordion [multi]="true">
                      <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4>Box Title</h4>
                          </mat-panel-title>
                          <mat-panel-description>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                          <br><br>
                          <mat-list>
                            <mat-list-item>
                              <span class="" mat-line>
                                <mat-form-field  class="example-full-width" appearance="fill">
                                  <mat-label>&nbsp;</mat-label>
                                  <input matInput type="text"  (input)="handleSuggestionboxtitle($event)" [(ngModel)]="selectedSuggestionBox.name">
                                  <i class="material-icons pull-right">insert_emoticon</i>
                                </mat-form-field>
                              </span>
                            </mat-list-item>
                          </mat-list>
                        </div>
                      </mat-expansion-panel>
                      <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4> Box Introduction </h4>
                          </mat-panel-title>
                          <mat-panel-description>
                            Use this area to describe the purpose of your box or ask for specific information.
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                          <br><br>
                          <mat-list>
                            <mat-list-item>
                                <span class="" mat-line>
                                  <mat-form-field  class="example-full-width" appearance="fill">
                                    <mat-label></mat-label>
                                    <textarea matInput type="text"
                                              (input)="handleSuggestionboxdescription($event)"
                                              [ngStyle]="{'height':'150px'}"
                                              [(ngModel)]="selectedSuggestionBox.description">
                                    </textarea>
                                  </mat-form-field>
                                </span>
                            </mat-list-item>
                          </mat-list>
                        </div>
                      </mat-expansion-panel>
                      <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4>Box fields</h4>
                          </mat-panel-title>
                          <mat-panel-description>
                            You can request additional information such as department, category or project...
                            <span [ngStyle]="{'padding-left':'50px', 'margin-top':'5px'}" ><a class="label label-md defaultColor" (click)="updateSuggestionbox()"> Edit fields </a></span>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                      </mat-expansion-panel>
                      <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <h4>Box Launch</h4>
                          </mat-panel-title>
                          <mat-panel-description>
                            There are many ways to share your feedback box in order to maximize feedback collection, Choose the metthod or methods that are right for you.
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div> <br><br>
                          <dl>
                            <dd>Share box Url</dd>
                            <dd>
                              <span class="" [ngStyle]="{'padding-right':'50px'}"> {{getUrl()}}</span> <a class="label label-xs defaultColor" (click)="copyUrl()"> Copy </a>
                            </dd>
                            <dd><a (click)="emailInvitational()" [ngStyle]="{'cursor':'pointer'}"><u>Invite via email</u></a></dd>
                          </dl>
                        </div>
                      </mat-expansion-panel>
                      <mat-expansion-panel (opened)="panelOpenState = true"
                                           (closed)="panelOpenState = false"
                                           [expanded]="true"
                                           *ngIf="service.app.data.user.role.display_name.toLowerCase().toString().includes('admin')"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            Assign Box Managers and Box Admins
                          </mat-panel-title>
                          <mat-panel-description>
                            Box managers can only view and reply to responses. They cannot change box settings. Box admins have full control over the box. To reset a box manager or admin's password, simply delete and re-add them.
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class='og'>
                          <a  mat-raised-button class="defaultColor pull-left" (click)="addUser()"> Add User </a><br><br><br>
                          <mat-dialog-content>
                            <mat-tab-group mat-align-tabs="start" [selectedIndex]="0">
                              <mat-tab [disabled]="false">
                                <ng-template mat-tab-label>
                                  <center>
                                    <br> <span [ngStyle]="{'cursor':'pointer'}">Managers</span>
                                  </center>
                                </ng-template>
                                <table mat-table [dataSource]="getManagers()" class="mat-elevation-z8">
                                  <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef> Email </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="location">
                                    <th mat-header-cell *matHeaderCellDef> Address </th>
                                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                                  </ng-container>
                                  <tr mat-header-row *matHeaderRowDef="employeedisplayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: employeedisplayedColumns;"
                                      (mousedown)="selectedEmployee = row"
                                      (contextmenu)="onContextMenu($event)"></tr>
                                </table>
                                <mat-paginator [length]="dataSource.length"
                                               [pageSize]="5"
                                               [pageSizeOptions]="[5, 10, 25, 100]"
                                               aria-label="Select page of feedback search results">
                                </mat-paginator>
                              </mat-tab>
                              <mat-tab [disabled]="false">
                                <ng-template mat-tab-label>
                                  <center>
                                    <br> <span [ngStyle]="{'cursor':'pointer'}">Admins</span>
                                  </center>
                                </ng-template>
                                <table mat-table [dataSource]="getCampanyAdmins()" class="mat-elevation-z8">
                                  <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef> Email </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                  </ng-container>
                                  <ng-container matColumnDef="location">
                                    <th mat-header-cell *matHeaderCellDef> Address </th>
                                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                                  </ng-container>
                                  <tr mat-header-row *matHeaderRowDef="employeedisplayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: employeedisplayedColumns;"
                                      (mousedown)="selectedEmployee = row"
                                      (contextmenu)="onContextMenu($event)"></tr>
                                </table>
                                <mat-paginator [length]="dataSource.length"
                                               [pageSize]="5"
                                               [pageSizeOptions]="[5, 10, 25, 100]"
                                               aria-label="Select page of feedback search results">
                                </mat-paginator>
                              </mat-tab>
                            </mat-tab-group>
                            <div style="visibility: hidden; position: fixed"
                                 [style.left]="contextMenuPosition.x"
                                 [style.top]="contextMenuPosition.y"
                                 [matMenuTriggerFor]="contextMenu">
                            </div>
                            <mat-menu #contextMenu="matMenu">
                              <button mat-menu-item (click)="handleUserPassword(selectedEmployee)">Change Password</button>
                              <button mat-menu-item (click)="handleUpdateUser(selectedEmployee)">Edit</button>
                              <button mat-menu-item (click)="handleDeleteUser(selectedEmployee)">Delete</button>
                            </mat-menu>
                          </mat-dialog-content>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2"></div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <div id="form"> </div>
      <div [ngStyle]='{"height":iframeHeight() + "px"}'></div>

      <mat-drawer #drawer class="example-sidenav" mode="side" autosize>
        <div>Auto-resizing sidenav</div>
        <div *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</div>
        <button (click)="showFiller = !showFiller" mat-raised-button>
          Toggle extra text
        </button>
      </mat-drawer>


      <!--<a (click)="drawer.toggle()"> znvdjhdvsjbsj</a>-->


    </div>
    <!-- /page content -->

    <!-- footer content -->
    <footer>
      <div class="pull-right">
        Design and Developed By  <a href="am.mashindano.co">mashindanogear@gmail.com</a>
      </div>
      <div class="clearfix"></div>
    </footer>
    <!-- /footer content -->
  </div>
</div>

