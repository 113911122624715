<header id="header" class="{{headerClass}}">
    <!--BURGER NAVIGATION SECTION START-->
    <div class="cp-burger-nav">
        <!--BURGER NAV BUTTON-->
        <div id="cp_side-menu-btn" class="cp_side-menu">
            <a hrefx="" class=""> <img src="../../assets/images/menu-icon-2.png" alt="img"></a>
        </div>
        <!--BURGER NAV BUTTON-->
        <!--SIDEBAR MENU START-->
        <div id="cp_side-menu"> <a hrefx="#" id="cp-close-btn" class="crose"><i class="fa fa-close"></i></a>
            <!--<div class="cp-top-bar">-->
            <!--<h4>For any Queries: +800 123 4567</h4>-->
            <!--<div class="login-section"> <a hrefx="login.html" class="btn-login">Log in</a> <a hrefx="signup.html" class="btn-login">Signup</a> </div>-->
            <!--</div>-->
            <!--        <strong class="logo-2"><a hrefx="index.html"><img src="../../assets/images/sidebar-logo.png" alt="img"></a></strong>-->
            <div class="content mCustomScrollbar">
                <div id="content-1" class="content">
                    <div class="cp_side-navigation">
                        <nav>
                            <!--<ul class="navbar-nav">-->
                            <!--<li class="active"><a hrefx="index.html" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Home<span class="caret"></span></a>-->
                            <!--<ul class="dropdown-menu" role="menu">-->
                            <!--<li><a hrefx="index-1.html">Home 1</a></li>-->
                            <!--<li><a hrefx="index-2.html">Home 2</a></li>-->
                            <!--<li><a hrefx="index-3.html">Home 3</a></li>-->
                            <!--<li><a hrefx="index-4.html">Home 4</a></li>-->
                            <!--</ul>-->
                            <!--</li>-->
                            <!--</ul>-->
                        </nav>
                    </div>
                </div>
            </div>
            <div class="cp-sidebar-social">
                <ul>
                    <!--<li><a hrefx="#"><i class="fa fa-google-plus"></i></a></li>-->
                    <!--<li><a hrefx="#"><i class="fa fa-twitter"></i></a></li>-->
                    <!--<li><a hrefx="#"><i class="fa fa-linkedin"></i></a></li>-->
                    <!--<li><a hrefx="#"><i class="fa fa-facebook-f"></i></a></li>-->
                </ul>
            </div>
            <strong class="copy">mashindano &copy; 2020, All Rights Reserved</strong> </div>
        <!--SIDEBAR MENU END-->

    </div>
    <!--BURGER NAVIGATION SECTION END-->
    <div class="container">
        <!--NAVIGATION START-->
        <div class="navigation-col">
            <nav class="navbar navbar-inverse">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>
                </div>
                <div id="navbar" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav" id="nav">
                        <!--                        <li *ngFor="let module of app.settings.modules" ><a [routerLink]="module.path">{{module.title}}</a>-->
                        <!--                            &lt;!&ndash;<ul>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;<li><a hrefx="index.html">Home 1</a></li>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;<li><a hrefx="index-2.html">Home 2</a></li>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;<li><a hrefx="index-3.html">Home 3</a></li>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;<li><a hrefx="index-4.html">Home 4</a></li>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;</ul>&ndash;&gt;-->
                        <!--                        </li>-->

                    </ul>
                </div>
            </nav>
        </div>
        <!--NAVIGATION END-->
    </div>

    <!--USER OPTION COLUMN START-->
    <div class="user-option-col">
        <div class="thumb">
            <div class="dropdown">
                <button  class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <img class="img-circle" src="{{app.data.user.avatar ? app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="img" height="25" width="25" style="margin-top: 15px" *ngIf="app.data.status > 0">
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li><a hrefx="#">Manage Account</a></li>
                    <li><a hrefx="#">Change Password</a></li>
                    <li><a hrefx="#">Edit Profile</a></li>
                    <li><a hrefx="#">Log off</a></li>
                </ul>
            </div>
        </div>
        <div class="dropdown-box">
            <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <img src="../../assets/images/option-icon-2.png" alt="img">
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li> <a hrefx="#" class="login-round"><i class="fa fa-sign-in"></i></a> <a hrefx="#" class="btn-login">Log in with Jobinn</a> </li>
                    <li> <a hrefx="#" class="login-round"><i class="fa fa-user-plus"></i></a> <a hrefx="#" class="btn-login">Log in with Jobinn</a> </li>
                </ul>
            </div>
        </div>
    </div>
    <!--USER OPTION COLUMN END-->

</header>
<router-outlet></router-outlet>
