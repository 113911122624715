import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../app.service";
import {Parameters} from "../../parameters";
import {AppComponent} from "../../app.component";
import {FormService} from "../form.service";
import * as CryptoJS from "crypto-js";
import {last, skipLast, take, takeLast} from "rxjs/operators";

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {
  email: any;
  pass: any;
  constructor(public service: AppService,
              private formService: FormService,
              private router: Router,
              private appComponent: AppComponent,
              private activatedRoute: ActivatedRoute) {
    this.email = this.activatedRoute.snapshot.params.email;
    this.pass = this.activatedRoute.snapshot.params.pass;
  }

  ngOnInit(): void {
    this.pass = this.service.decrypt(this.pass);
    this.email = this.service.decrypt(this.email);
    let formValues: any = new Parameters();
    formValues.setAttributes({});
    formValues.attributes.email = this.email;
    formValues.attributes.password = this.pass;
    this.service.httpService(
        'post',
        this.service.app.settings.routes.authenticate,
        formValues.attributes, {},
        (response: any) => {
          localStorage.setItem(JSON.parse(JSON.stringify(this.service.app)).settings.tokenName, response.token);
          this.service.setToken(response.token);
            this.service.authenticateUser((response: any) => {
                this.appComponent.set();
                this.formService.openDialog({});
                this.formService.dialog.afterAllClosed.pipe(skipLast(1)).subscribe(() => {
                    this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.service.app.data.forms.data[0].id) + '/dashboard');
                });
            });
        }, (error: any) =>  {
        });
  }

  setActivateRoute(){
      setTimeout(() =>{
          this.formService.formSelectedId = this.activatedRoute.snapshot.params.id;
      });
  }

}
