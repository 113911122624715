import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {DialogClientsComponent} from "../clients/dialog-clients.component";
import {AppService} from '../app.service';
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }

  report: any;

  constructor(public service: AppService,
              private dialog: MatDialog) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }



  getClientByClient(client: any){
    var clients: any = [] = this.service.app.data.clients.data.filter((item: any) => {
      return parseFloat(item.id) === parseFloat(client.id); });
       return clients.length === 0 ? this.addClient(client) : clients[0];
  }

  addClient(client: any){
    this.service.app.data.clients.data.unshift(client);
    return client;
  }

  getClientById(clientId: any){
    var clients: any = [] = this.service.app.data.clients.data.filter((client: any) => {
      return parseFloat(client.id) === parseFloat(clientId);});
       return clients[0];
  }

  hasClientById(clientId: any){
    var clients: any = [] = this.service.app.data.clients.data.filter((client: any) => {
      return parseFloat(client.id) === parseFloat(clientId);});
    return clients.length === 0 ? false : true;
  }

  requestClientById(clientId: any, call){
    let outgoingRequestParams: any = new Parameters();
    outgoingRequestParams.setAttributes({});
    outgoingRequestParams.setId(clientId);
    this.service.httpService('POST', this.service.app.routes.clients.get,
        outgoingRequestParams.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (client: any) => {
          this.service.app.data.clients.data.unshift(client);
          call(client);
        }, (error: any) => {call(null);});
  }



  search(searchText: any, call: any){
 // ignoreLoadingBar : 'true', notify: 'false'
    this.service.httpService( 'post', '/clients/search',
        {searchText: searchText}, {},
        (response: any) => {
          setTimeout((result: any) =>{
            response.map((item: any) => {
              result = [];
              result = this.service.app.data.clients.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });if(result.length === 0)
                this.service.app.data.clients.data.push(item);
            });
          });
          call(null);
        }, (error: any) => {
          call(null);
        });
  }

  openDialog(params: any) {
    setTimeout(() => {
      params = new Parameters();
      params.setAttributes({});
      const dialogRef = this.dialog.open(DialogClientsComponent, {
        data  : {params: params.getAttributes()},
        width : '55%',
        height: '100%',
        disableClose: true,
      });
    });
  }

}
