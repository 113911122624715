<div [ngStyle]="{'background-color':'#ebebeb'}">
  <center>
    <img class="img-circlex" src="{{form.campaign.client.logo}}" [ngStyle]="{'width':'auto','height':'100px','margin':'50px'}"/>
  </center>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'background-color':'#ebebeb'}">
    <div class="row" *ngFor="let question of form.controls;let questionOf=index" [hidden]="question.visible === 0">
      <app-text-preview-controls        [question]="question" [position] ="questionOf" *ngIf="question.control.id === 1"></app-text-preview-controls>
      <app-image-preview-controls       [question]="question" [position] ="questionOf" *ngIf="question.control.id === 2"></app-image-preview-controls>
      <app-check-preview-controls       [question]="question" [position] ="questionOf" *ngIf="question.control.id === 3"></app-check-preview-controls>
      <app-select-preview-controls      [question]="question" [position] ="questionOf" *ngIf="question.control.id === 4"></app-select-preview-controls>
      <app-choice-preview-controls      [question]="question" [position] ="questionOf" *ngIf="question.control.id === 5"></app-choice-preview-controls>
      <app-address-preview-controls     [question]="question" [position] ="questionOf" *ngIf="question.control.id === 6"></app-address-preview-controls>
      <app-video-preview-controls       [question]="question" [position] ="questionOf" *ngIf="question.control.id === 7"></app-video-preview-controls>
      <app-number-preview-controls      [question]="question" [position] ="questionOf" *ngIf="question.control.id === 10"></app-number-preview-controls>
      <app-date-preview-controls        [question]="question" [position] ="questionOf" *ngIf="question.control.id === 11"></app-date-preview-controls>
      <app-description-preview-controls [question]="question" [position] ="questionOf" *ngIf="question.control.id === 12"></app-description-preview-controls>
      <app-rate-preview-controls        [question]="question" [position] ="questionOf" *ngIf="question.control.id === 13"></app-rate-preview-controls>
      <app-outlet-preview-controls              [question]="question" [position] ="questionOf" *ngIf="question.control.id === 14"></app-outlet-preview-controls>
      <app-sheet-preview-controls       [question]="question" [position] ="questionOf" *ngIf="question.control.id === 15"></app-sheet-preview-controls>
      <app-textarea-preview-controls    [question]="question" [position] ="questionOf" *ngIf="question.control.id === 16"></app-textarea-preview-controls>
      <app-groups-preview-controls      [question]="question" [position] ="questionOf" *ngIf="question.control.id === 18"></app-groups-preview-controls>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2"></div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
          <div class="panel-body">
            <mat-form-field  class="example-full-width" appearance="outline">
              <mat-label> Department *</mat-label>
              <input type="text"
                     #departmentElement
                     placeholder=""
                     aria-label = "Number"
                     matInput
                     [formControl] = "departmentControl"
                     [matAutocomplete] = "department"/>
              <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" >keyboard_arrow_down</i>
              <mat-autocomplete #department = "matAutocomplete" (optionSelected)="onDepartmentSelectionChanged($event)" [displayWith]="displayFn">
                <mat-option *ngFor="let department of filteredDepartmentsOptions | async; let departmentOf = index" [value]= "department" >
                  {{department.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2"></div>
    </div>
    <div layout="row">
      <div class="col-lg-2 col-md-2"></div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
          <div class="panel-body">
            <div>
              <mat-form-field  class="example-full-width"  appearance="outline">
                <input matInput type="text" placeholder="Your Email or Phone number (Optional if you want a reply)" [(ngModel)]="email">
                <i class="material-icons pull-right">email</i>
              </mat-form-field>
            </div>
            <div *ngIf="form.category_id !== 3">
              <center>
                <h4> Only your Organization can see your email address. </h4>
                <h4> keep it secret and relay messages between you </h4>
                <h4> and your organization. Learn more </h4>
              </center>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2"></div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <center>
      <div class="row">
        <center>
          <a (click)="ngSubmit()" class="btn btn-primary btn-lg" *ngIf="keys() > 0"> {{form.category_id === 3 ? 'Send' : 'Send Anonymous feedback'}} </a>
          <p *ngIf="form.category_id !== 3">By clicking Send, you agree to our User Agreement and Privacy Policy.</p>
          <p [ngStyle]="{'paddig-bottom': '100px'}">{{service.app.settings.copyright}}</p>
        </center>
      </div>
    </center>
  </div>
</div>
