import { Component, OnInit, Input } from '@angular/core';
import {AppService} from "../../../../app.service";
import {FormService} from "../../../form.service";
@Component({
  selector: 'app-create-submit-control',
  templateUrl: './create-submit-control.component.html',
  styleUrls: ['./create-submit-control.component.scss']
})
export class CreateSubmitControlComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  controls: any;
  constructor(
    public service: AppService,
    public formService: FormService) {
  }


  ngOnInit(): void {
  }

}
