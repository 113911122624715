<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Email</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
    <th class="mdl-data-table__cell--non-numeric headline2" width="40">Logo</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="mdl-data-table__cell--non-numeric headline2 text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let client of (flag.includes('all') ?  getClientsBySearchText(searchCntrl) :
      (service.app.data.clients.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, client)"
      (mousedown)="selectedClient = client">
    <td class="mdl-data-table__cell--non-numeric headline">{{flag.includes('all') ? getClient(client).name : client.name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{client.email}}</td>
    <td class="mdl-data-table__cell--non-numeric headline z">{{client.phone}}</td>
    <td class="mdl-data-table__cell--non-numeric headline"><img class="img-thumbnail avatar" src="{{client.logo}}" *ngIf="client.logo" /></td>
    <td class="mdl-data-table__cell--non-numeric headline">{{client.location}}</td>
    <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="client.description"></td>
    <td class="mdl-data-table__cell--non-numeric headline">
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
<!--      <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleCopy()">Copy</li>-->
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedClient)">Edit</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedClient)">Delete</li>
          <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedClient)">Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
<!--      <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleCopy()">Copy</button>-->
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedClient)">Edit</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedClient)">Delete</button>
          <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedClient)">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
