import { Component, OnInit } from '@angular/core';
import {FormService} from '../../forms/form.service';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {ResponseService} from '../../forms/responses/data/response.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageMap } from '@ngx-pwa/local-storage';
@Component({
  selector: 'app-outlet-orders',
  templateUrl: './outlet-orders.component.html',
  styleUrls: ['./outlet-orders.component.scss']
})
export class OutletOrdersComponent implements OnInit {
  incomingForm: any;
  incomingFormSettings: any;
  outletId: any;
  formId: any;
  outgoingRequest: any;
  incomingResult: any;
  incomingResponse: any;
  incomingUser: any;
  incomingPost: any;
  incomingQuestion: any;
  appsettingsUrl = '../assets/json/app.json';
  constructor(
    private formService : FormService,
    public  service: AppService,
    private responseService: ResponseService,
    private storage: StorageMap,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
       this.incomingForm = new Parameters();
       this.outgoingRequest = new Parameters();
       this.incomingFormSettings = new Parameters();
       this.incomingPost = new Parameters();
       this.incomingResponse = new Parameters();
       this.incomingResult = new Parameters();
       this.incomingQuestion = new Parameters();
       this.incomingUser = new Parameters();
       this.outletId =  this.activatedRoute.snapshot.params.outletId;
       this.formId =  this.activatedRoute.snapshot.params.formId;
       this.outletId = this.service.decrypt(this.outletId);
       this.formId = this.service.decrypt(this.formId);
  }

  ngOnInit(): void {
    this.incomingForm.setAttributes(this.formService.getSelected());
    if(!this.incomingForm.getAttributes().hasOwnProperty('id')){
     this.router.navigateByUrl('/wait..');
     setTimeout(()=>{
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setId(this.formId);
          this.service.httpService('post', this.service.app.routes.forms.get.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true',notify: false}, (result: any) => {
//         this.incomingResult.setAttributes(result);
           this.incomingForm.setAttributes(result);
           this.formService.setSelected(this.incomingForm.getAttributes());
           window.history.back();
          }, (error: any) =>  {
          });
     });
    }else{
     this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
     this.incomingUser.setAttributes({});
     this.formService.setSelected({});
     this.setSettings((result: any)=>{
       this.service.setToken('');
       localStorage.setItem(this.service.app.settings.tokenName, '');
       this.responseService.outletId = this.outletId;
       this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/feedback');
     });
//   this.service.app = Object.assign(this.service.app,{data:{}});
//    this.service.app.data = Object.assign(this.service.app.data,{status:0});
//      this.responseService.setPost(this.incomingForm.getAttributes(), (response: any)=>{
//         let responses: any = [];
//         this.formService.setSelected(this.incomingForm.getAttributes());
//         this.incomingForm.getControls().map((question: any) => {
//           this.incomingQuestion.setAttributes(question);
//           this.incomingQuestion.setData('');
//           this.incomingQuestion.setResult({});
//         });
//         this.incomingPost.setAttributes(response);
//         this.incomingPost.setForm(this.incomingForm.getAttributes());
//         this.responseService.setSelected(this.incomingPost.getAttributes());
//         responses.push(this.incomingPost.getAttributes());
//         this.storage.set('responses', responses).subscribe(() => {});
//         this.storage.set('others', []).subscribe(() => {});
//         this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/feedback');
//         this.responseService.isDialogOpen = false;
//      });
    }
  }

  setSettings(callback: any){
   this.appsettingsUrl = this.appsettingsUrl + '?version=' + this.service.random();
   if(!this.service.app.hasOwnProperty('settings')){
    this.service.httpService( 'get', this.appsettingsUrl, {}, {},
      (result: any) => {
       this.service.app = Object.assign(this.service.app, {settings: result});
       callback(null);
      },  (error: any) => {
      });
   }else callback(null);
  }

}
