import { Component, OnInit } from '@angular/core';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {AppService} from '../app.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchCntrl: any;
  app: any;
  constructor(
    public serviceImpl: AppService,
    public broadcastChannel: BroadcastChannelService) {
    this.app = serviceImpl.app;
  }

  ngOnInit(): void {
  }

  onSearchTextChange(s: any){
    this.broadcastChannel.emitNavChangeEvent({searchText: s});
  }

  enterKeyUp(s: any){
    this.broadcastChannel.emitNavChangeEvent('search');
  }

}
