<table class="sawasdeeWithScale">
    <td (click)="tabs=0" class="cursor headline2x"><span class="{{tabs === 0 ? 'selectedMatTab2' : ''}}">list</span></td>
    <td (click)="tabs=1" class="cursor headline2x"><span class="{{tabs === 1 ? 'selectedMatTab2' : ''}}">GIS</span></td>
    <td (click)="tabs=2" class="cursor headline2x"><span class="{{tabs === 2 ? 'selectedMatTab2' : ''}}">payments</span></td>
    <td (click)="tabs=1" class="cursor headline2x">
        <mat-spinner
                *ngIf="this.service.matSpinner"
                class=""
                color='warn'
                [diameter]="20">
        </mat-spinner>
    </td>
</table>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="tabs === 0">
    <table class="table headline2x table-striped sawasdee16" [ngStyle]="{'-webkit-transform': 'scale(1.15,0.77)','color':'rgb(90, 115, 142)'}">
        <ng-container *ngIf="orders.length !== 0">
            <thead>
                <th class="mdl-typography--text-lowercase">The order number is</th>
                <th class="mdl-typography--text-lowercase">Issued on the day</th>
                <th class="mdl-typography--text-lowercase">Payment Channel</th>
                <th class="mdl-typography--text-lowercase">Status</th>
                <th class="mdl-typography--text-lowercase">Delivery to</th>
                <th class="mdl-typography--text-lowercase">Phone</th>
                <th class="mdl-typography--text-lowercase">Address</th>
            </thead>
        </ng-container>
        <tbody>
             <ng-container *ngFor="let order of orders">
<!--                 <ng-container *ngIf="order.hasOwnProperty(strings.getOutlet())">-->
                     <tr (click)="onSelect(order)" [ngStyle]="{'background-color': (incomingOrderSelected.hasAttribute(strings.getId()) && this.service.parseFloat(incomingOrderSelected.getId()) === order.id ? '#5A738E' : ''), 'color': (incomingOrderSelected.hasAttribute(strings.getId()) && this.service.parseFloat(incomingOrderSelected.getId()) === order.id ? '#fff' : '')}">
                         <td class="mdl-data-table__cell--non-numeric">{{order[strings.getCntrlNum()]}}</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order[strings.getCreatedAt()]}} - ({{order[strings.getTime()]}})</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order[strings.getPaymentMethod()]}}</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order[strings.getOrderStatus()]}}</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order.outlet[strings.getBusiness()]}}</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order.outlet[strings.getPhone()]}}</td>
                         <td class="mdl-data-table__cell--non-numeric">{{order.outlet[strings.getAddress()]}}</td>
                     </tr>
<!--                 </ng-container>-->
             </ng-container>
        </tbody>
    </table>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="this.orders.length !== 0">
        <center>
            <i class="material-icons cursor"
               (click)="this.pull(10, null)">keyboard_arrow_down</i>
        </center>
    </div>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="tabs === 2">
   zxczxcxzcmxzcmxzlcxzczxcxzc
</div>