<mat-tab-group>
  <mat-tab label="All({{districts.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-districtal [flag]="'all'"></app-table-districtal>
  </mat-tab>
  <mat-tab label="Deleted({{districts.trashed.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-districtal [flag]="'trashed'"></app-table-districtal>
  </mat-tab>
</mat-tab-group>

