import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AppService} from "../../../../app.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {FormService} from "../../../form.service";
import {Parameters} from "../../../../parameters";

@Component({
  selector: 'app-groups-preview-controls',
  templateUrl: './groups-preview-controls.component.html',
  styleUrls: ['./groups-preview-controls.component.scss']
})
export class GroupsPreviewControlsComponent implements  AfterViewInit, AfterContentChecked, OnChanges, AfterContentInit, OnDestroy {
  @Input() question: any;
  @Input() position: any;
  data: any;
  questions: any;
  form: any;
  incomingOption: any;
  incomingQuestion: any;
  subscription: any;
  incomingResults: any;
  incomingResult: any;
  constructor(
      public service: AppService,
      public broadcastChannel: BroadcastChannelService,
      public formService: FormService) {
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingOption = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingResult = new Parameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngStart();
  }

  ngStart(){
    this.scrollY();
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('scroll')) {
              this.scrollY();
            }
          }
        });
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
  }


  optionalProgress(optional: any){
    const incomingOption: any = new Parameters();
    incomingOption.setAttributes(optional);
    incomingOption.setUnknown('written',0);
    incomingOption.setUnknown('all',0);
    this.formService.getOptionalProgress(optional, (rs: any) => {
      const incomingResult: any = new Parameters();
      incomingResult.setAttributes(rs);
      incomingOption.setUnknown('all', parseFloat(incomingOption.getUnknown('all')) +
          (incomingResult.getAttributes().hasOwnProperty('all')     ? parseFloat(incomingResult.getUnknown('all')) : 0));
      incomingOption.setUnknown('written', parseFloat(incomingOption.getUnknown('written')) +
          (incomingResult.getAttributes().hasOwnProperty('written')     ? parseFloat(incomingResult.getUnknown('written')) : 0));
      incomingOption.setProgress(this.formService.progress(parseFloat(incomingOption.getUnknown('written')),
          parseFloat(incomingOption.getUnknown('all'))));
    });
    // tslint:disable-next-line:radi
    return parseInt(incomingOption.getProgress());
  }

  getAncesstor(question: any,callback: any){
    const incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    if(incomingQuestion.getAttributes().hasOwnProperty('ancestor')){
      const  incomingAncesstor: any = new Parameters();
      incomingAncesstor.setAttributes(this.formService.getQuestionByCntrlNum(incomingQuestion.getUnknown('ancestor')));
      if(parseFloat(incomingAncesstor.getControlId()) === 18){
        if(incomingQuestion.getAttributes().hasOwnProperty('option')){
          const incomingOption: any = new Parameters();
          incomingOption.setAttributes(
              this.service.getJSONObjectByJSONArrayAndColumnAndValue(incomingAncesstor.getOptions(),
                  'cntrl_num',
                  incomingQuestion.getOption().cntrlNum));
          callback(incomingOption.getAttributes());
        }
      }else this.getAncesstor(incomingAncesstor.getAttributes(), callback);
    }
  }

  setGroupBy(){
    setTimeout(() => {
      this.formService.questions.map((question: any)=>{
        const incomingQuestion: any = new Parameters();
        const incomingResult: any = new Parameters();
        incomingQuestion.setAttributes(question);
        incomingQuestion.setResult(
            incomingQuestion.getAttributes().hasOwnProperty('result') &&
            !this.service.isNull(incomingQuestion.getResult()) ? incomingQuestion.getResult() : {});
        incomingResult.setAttributes(incomingQuestion.getResult());
        if (incomingQuestion.getAttributes().hasOwnProperty('ancestor')) {
          const incomingAncesstor: any = new Parameters();
          incomingAncesstor.setAttributes(this.formService.getQuestionByCntrlNum(incomingQuestion.getUnknown('ancestor')));
          if (parseFloat(incomingAncesstor.getControlId()) === 18) {
            const incomingOption: any = new Parameters();
            incomingOption.setAttributes(
                this.service.getJSONObjectByJSONArrayAndColumnAndValue(incomingAncesstor.getOptions(),
                    'cntrl_num',
                    incomingQuestion.getOption().cntrlNum));
            incomingResult.setGroupBy(incomingOption.getCntrlNum());
          } else {
            this.getAncesstor(incomingAncesstor.getAttributes(), (result) => {
              const incomingOption: any = new Parameters();
              incomingOption.setAttributes(result);
              incomingResult.setGroupBy(incomingOption.getCntrlNum());
            });
          }
        }
      });
    },this.service.timeout());
  }


  onPanelClicked(optional: any){
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') &&
        !this.service.isNull(this.incomingQuestion.getResult())  ?
        this.incomingQuestion.getResult() : {});
    this.incomingOption.setAttributes(optional);
    this.question.data = optional.label;
    this.incomingResults.setAttributes({});
    this.incomingResult.setAttributes({});
    this.incomingResult.setLabel(this.incomingOption.getLabel());
    this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
    this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
    this.incomingQuestion.setResult(Object.assign(this.incomingQuestion.getResult(), this.incomingResults.getAttributes()));
    this.broadcastChannel.emitNavChangeEvent({action: 'next', controlId: this.question.id, optionalId: optional.id, optionalCntrlNum: optional.cntrl_num});
    this.incomingQuestion.setUnknown('pageYOffset',  window.scrollY);
  }

  scrollY(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      if(this.incomingQuestion.getAttributes().hasOwnProperty('pageYOffset')){
        setTimeout((page: any) => {
          window.scrollTo(0, parseFloat(page.yOffSet));
        }, 0, {yOffSet: this.incomingQuestion.getUnknown('pageYOffset')});
      }
    });
  }


  reset(radionButton: any) {
    this.incomingQuestion.setAttributes(this.question);
    if (this.service.isNull(this.incomingQuestion.getUnknown('data')))
      radionButton.checked = false;
  }

  ngAfterViewInit(): void {
    this.scrollY();
  }

  ngAfterContentInit(): void {
  }
}
