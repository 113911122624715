import { Injectable } from '@angular/core';
import {AppService} from "../app.service";
import {Parameters} from "../parameters";
import {FormService} from "../forms/form.service";

@Injectable({
  providedIn: 'root'
})
export class DistributorService {

  constructor(private service: AppService,
              private formService: FormService) {
  }

  search(searchText: any, call: any){
    let ougoingRequest: any = new Parameters();
    ougoingRequest.setAttributes({});
    ougoingRequest.setSearchText(searchText);
    ougoingRequest.setFormId(this.formService.getSelected().id);
    this.service.httpService('POST', this.service.app.routes.distributors.search,
        ougoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (distributors: any) => {
          call(distributors);
        }, (error: any) => {call(null);});
  }
}
