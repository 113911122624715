import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Parameters} from "../../../../parameters";

@Component({
  selector: 'app-option-settings',
  templateUrl: './option-settings.component.html',
  styleUrls: ['./option-settings.component.scss']
})
export class OptionSettingsComponent implements OnInit {
  incomingOption: any;
  incomingSettings: any;
  controlId: any;
  constructor(public  service: AppService,
       @Inject(MAT_DIALOG_DATA) public data: any) {
       this.incomingOption = new Parameters();
       this.incomingSettings = new Parameters();
       this.incomingOption.setAttributes(this.data.option);
       this.incomingSettings.setAttributes(this.incomingOption.getSettings());
       this.controlId = this.incomingOption.attributes.hasOwnProperty('control_id') ? this.incomingOption.getControlId() : this.incomingOption.getTypeId();
  }

  ngOnInit(): void {
  }

}
