<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Districts</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let regional of (flag === 'all' ? (regionals.data | jsonFilter : searchCntrl) : (regionals.trashed.data | jsonFilter: searchCntrl))"
      (contextmenu)="onContextMenu($event, regional)"
      (mousedown)="selectedRegional = regional">
    <td class="mdl-data-table__cell--non-numeric headline">{{regional.name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline"><span *ngFor="let district of regional.districts"> {{district.name}},</span></td>
    <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="regional.description"></td>
    <td>
      <div class="dropdown pull-right">
        <button  class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
      </div>

      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="regional">
          <button mat-menu-item (click)="handleDistricts(selectedRegional)" *ngIf="flag === 'all'">Districts</button>
          <button mat-menu-item (click)="handleUpdate(selectedRegional)"    *ngIf="flag === 'all'">Edit</button>
          <button mat-menu-item (click)="handleDestroy(selectedRegional)"   *ngIf="flag === 'all'">Delete</button>
          <button mat-menu-item (click)="handleRestore(selectedRegional)"   *ngIf="flag === 'restore'">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
