import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-respond-currency-control',
  templateUrl: './respond-currency-control.component.html',
  styleUrls: ['./respond-currency-control.component.scss']
})
export class RespondCurrencyControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
