<div class="mdl-layout mdl-js-layout mdl-layout--fixed-header" *ngIf="sys.hasOwnProperty('sponsors')">
    <div class="android-header mdl-layout__header mdl-layout__header--waterfall"
         [ngStyle]="{'position':'fixed','top':'0px','background-color': headerChangeColorStatus === 0 ? 'rgba(16,30,81,0.13)' : 'rgba(255,255,255,0.94)'}">
        <div class="mdl-layout__header-row">
          <span class="android-title mdl-layout-title" >
              <table>
                  <tr>
                      <td>
                         <img class="android-logo-imagex"
                              [ngStyle]="{'width':'60px','height':'60px'}"
                              src="assets/tpsf/images/logo.png">
                      </td>
                  </tr>
              </table>
          </span>
            <!-- Add spacer, to align navigation to the right in desktop -->
            <div class="android-header-spacer mdl-layout-spacer"></div>
            <div class="android-search-box mdl-textfield mdl-js-textfield mdl-textfield--expandable mdl-textfield--floating-label mdl-textfield--align-right mdl-textfield--full-width">
                <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field">
                    <i class="material-icons">search</i>
                </label>
                <div class="mdl-textfield__expandable-holder">
                    <input class="mdl-textfield__input" type="text" id="search-field">
                </div>
            </div>
            <!-- Navigation -->
            <div class="android-navigation-container">
                <nav class="android-navigation mdl-navigation">
                    <a *ngFor="let menu of sys.menus"
                       class="mdl-navigation__link mdl-typography--text-uppercase cursor"
                       style="color:{{headerChangeColorStatus === 0 ? 'rgba(255,255,255,0.68)' : ''}}"
                       (click)="scrollIntoView(menu.id)">{{menu.title}} <i class="glyphicon glyphicon-chevron-right"></i></a>
                </nav>
            </div>

            <span class="android-mobile-title mdl-layout-title">
            <img class="android-logo-image" src="images/android-logo.png">
          </span>
            <button class="android-more-button mdl-button mdl-js-button mdl-button--icon mdl-js-ripple-effect" id="more-button">
                <i class="material-icons">more_vert</i>
            </button>
            <ul class="mdl-menu mdl-js-menu mdl-menu--bottom-right mdl-js-ripple-effect" for="more-button">
                <li class="mdl-menu__item">5.0 Lollipop</li>
                <li class="mdl-menu__item">4.4 KitKat</li>
                <li disabled class="mdl-menu__item">4.3 Jelly Bean</li>
                <li class="mdl-menu__item">Android History</li>
            </ul>
        </div>
    </div>

    <div class="android-drawer mdl-layout__drawer">
        <span class="mdl-layout-title">
          <img class="android-logo-image" src="images/android-logo-white.png">
        </span>
        <nav class="mdl-navigation">
            <a class="mdl-navigation__link" href="">Phones</a>
            <a class="mdl-navigation__link" href="">Tablets</a>
            <a class="mdl-navigation__link" href="">Wear</a>
            <a class="mdl-navigation__link" href="">TV</a>
            <a class="mdl-navigation__link" href="">Auto</a>
            <a class="mdl-navigation__link" href="">One</a>
            <a class="mdl-navigation__link" href="">Play</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">Versions</span>
            <a class="mdl-navigation__link" href="">Lollipop 5.0</a>
            <a class="mdl-navigation__link" href="">KitKat 4.4</a>
            <a class="mdl-navigation__link" href="">Jelly Bean 4.3</a>
            <a class="mdl-navigation__link" href="">Android history</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">Resources</span>
            <a class="mdl-navigation__link" href="">Official blog</a>
            <a class="mdl-navigation__link" href="">Android on Google+</a>
            <a class="mdl-navigation__link" href="">Android on Twitter</a>
            <div class="android-drawer-separator"></div>
            <span class="mdl-navigation__link" href="">For developers</span>
            <a class="mdl-navigation__link" href="">App developer resources</a>
            <a class="mdl-navigation__link" href="">Android Open Source Project</a>
            <a class="mdl-navigation__link" href="">Android SDK</a>
        </nav>
    </div>


    <div class="android-content mdl-layout__content">
        <a name="top"></a>
        <carousel [cellsToScroll]="1"
                  [cellsToShow]="1"
                  [autoplay]="false"
                  [ngStyle]="{'height':this.height+'px'}"
                  [ngStyle.xl]="{'height':this.height+'px'}"
                  [ngStyle.lg]="{'height':this.height+'px'}"
                  [ngStyle.md]="{'height':this.height+'px'}"
                  [ngStyle.xs]="{'height':'1000px'}"
                  [arrows]="false"
                  [dots]="true"
                  [loop]="true"
                  *ngIf="sys.hasOwnProperty('banners')">
                <ng-container>
                    <div class="carousel-cell android-be-together-section" #bnr>
                        <img src="assets/tpsf/images/cover.jpg"
                             [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"/>
                        <!--                    <video [autoplay]="true"-->
                        <!--                           [height]="service.getHeight()"-->
                        <!--                           [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
                        <!--                           [width]="service.getWidth()"-->
                        <!--                           [muted]="true"-->
                        <!--                           [playbackRate]="0.5"-->
                        <!--                           [volume]="3.01"-->
                        <!--                           [id]="'videoPlayer'"-->
                        <!--                           [loop]="true"-->
                        <!--                           ngClass="">-->
                        <!--                        <source src="assets/tpsf/video/2022.mp4" >-->
                        <!--                    </video>-->
                        <div [ngStyle]="{'z-index':'99999999999999999999','position':'fixed','top':'0px','left':'0px','background':'rgba(30,18,18,0.39)','height':service.getHeight()+'px','width':service.getWidth()+'px'}"></div>
                        <div [ngStyle]="{'z-index':'99999999999999999999','position':'fixed','top':'0px','left':'0px','background':'url('+'assets/images/parrten-bg.png'+') repeat','height':service.getHeight()+400+'px','width':service.getWidth()+'px'}"></div>
                        <div  [ngStyle]="{'z-index':'999999999999','position':'fixed','top':'0px','width':service.getWidth()+'px'}" id="homeId">
                            <div class="android-be-together-section mdl-typography--text-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;margin:0px">
                                    <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                                    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 logo-font android-slogan">
                                        <h1><i class="material-icons txtWhite" style="font-size: x-large">date_range</i> 23 - 24 April 2024, <i class="material-icons" style="font-size: x-large">location_on</i> JNICC, Dar es salaam, Tanzania</h1>
                                        <div [ngStyle]="{'color':'#007aff'}"> 25th Anniversary of TPSF-Celebrating 25 Years of Pioneering Private Sector-led Growth.</div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;margin:0px">
                                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 logo-font android-sub-slogan txtWhite">
                                        TPSW 2024 encapsulates the enduring commitment of TPSF and the wider private sector to steer economic development through innovation, entrepreneurship, and sustainable practices. The theme echoes the narrative of resilience, adaptation, and progress that defines the Tanzanian private sector's evolution
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;margin:0px">
                                    <br><br>
                                    <ul class="list-inline txtWhite counter">
                                        <li>
                                            <div class="dashedbox">
                                                <span>{{getDays()}}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="colom">
                                                <span>:</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="dashedbox">
                                                <span>{{remainingTime().hours}}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="colom">
                                                <span>:</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="dashedbox">
                                                <span>{{getMinutess()}}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="colom">
                                                <span>:</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="dashedbox">
                                                <cd-timer format="user">[seconds]</cd-timer>
                                            </div>
                                        </li>
                                    </ul>
                                    <div [ngStyle]="{'margin-top': '10px'}">
                                        <ul class="list-inline">
                                            <li>
                                                <a  (click)="registerAttendee()"
                                                    [ngStyle]="{'border':'0px','background-color':'#fff','color':'#000'}"
                                                    class="btn btn-lg btn-default text-uppercase mdl-shadow--2dp">
                                                    Register for free
                                                </a>
                                            </li>
                                            <li>
                                                <a  (click)="registerExhibitor()"
                                                    [ngStyle]="{'border':'0px','background-color':'#fff','color':'#000'}"
                                                    class="btn btn-lg btn-default text-uppercase">Exhibit with us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="logo-font android-create-character">
                                    <img
                                            [ngStyle]="{'width':'120px','height':'120px'}"
                                            src="assets/tpsf/images/logo.png"
                                            class="mdl-shadow--3dp img-circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
        </carousel>
        <div class="android-customized-section mdl-layout" [ngStyle]="{'background-color':'#fff'}">
            <div class="android-customized-section-text">
                <div>
                    <img src="assets/images/tpsf/leaders/jafo.png" class="img-circle"
                         [ngStyle]="{'width':'350px','height':'350px'}"/>
                </div>
                <!--                <div class="mdl-typography&#45;&#45;font-light  text-uppercase mdl-typography&#45;&#45;body-2-color-contrast">-->
                <!--                    Annoucing, our chief guest-->
                <!--                </div>-->
            </div>
            <div class="mdl-typography--font-light  mdl-typography--display-1-color-contrast text-uppercase">
                Hon. Dr Selemani Saidi Jafo
            </div>
            <h2 class="mdl-typography--font-light">
                Minister Of State, Vice President’s Office Union and Environment.
            </h2>
            <!--            <div class="android-card-container mdl-grid">-->
            <!--                <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp"-->
            <!--                     *ngFor="let leader of sys.leaders">-->
            <!--                    <div class="mdl-card__media">-->
            <!--                        <img src="{{leader.img}}">-->
            <!--                    </div>-->
            <!--                    <div class="mdl-card__title">-->
            <!--                        <h4 class="mdl-card__title-text">{{leader.name}}</h4>-->
            <!--                    </div>-->
            <!--                    <div class="mdl-card__supporting-text">-->
            <!--                            <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
            <!--                                {{leader.company}}-->
            <!--                            </span>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <div class="mdl-layout" [ngStyle]="{'background-color':'#fff'}" id="sponsorsId">
            <div class="android-customized-section">
                <div class="android-customized-section-text">
                    <div class="mdl-typography--font-light mdl-typography--display-1-color-contrast"> Organised and Sponsored by</div>
                    <div class="mdl-typography--font-light">
                        <center>
                            <carousel [cellsToScroll]="1"
                                      [cellsToShow]="5"
                                      [height]="140"
                                      [cellWidth]="service.getWidth()"
                                      [autoplay]="true"
                                      [arrows]="false"
                                      [dots]="false"
                                      [loop]="true">
                                <div class="carousel-cell" *ngFor="let sponsor of sys.sponsors">
                                    <img
                                            [ngStyle]="{'width':'90px','height':'120px'}"
                                            [src]="sponsor.logo"
                                            class="img-thumbnail" />
                                </div>
                            </carousel>
                        </center>
                    </div>
                </div>
                <div class="android-customized-section-image">
                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <br><br>
                        <div class="bottomSpaceX50">
                            <center>
                                <div class="bottomSpaceX20 text-left"
                                     [ngStyle.lg]="{'max-width':'20%'}"
                                     [ngStyle.md]="{'max-width':'20%'}">
                                    <h2>Be part of the TPSF Private Sector Week Industry Opportunity in Tanzania</h2>
                                    The TPSF Private Sector Week serves as a critical platform to engage directly with key local stakeholders, gain access into critical insights pertaining to the latest policy and regulatory frameworks, and forge new partnerships and cooperation in this important emerging market.
                                </div>
                                <div>
                                    <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white"
                                       (click)="registerExhibitor()">Enguire about Sponsorship</a>
                                </div>
                            </center>
                        </div>
                        <div class="" id="benefitOfSponsoringId">
                            <h1>Why Sponsor?<hr></h1>
                            <h2>Benefits of Sponsoring</h2>
                        </div>
                        <ul class="list-inline">
                            <li *ngFor="let sponsor of sys.sponsoring.benefits"
                                [ngStyle.lg]="{'max-width':'200px'}"
                                [ngStyle.md]="{'max-width':'200px'}"
                                style="text-align: left;vertical-align: top;">
                                <i class="material-icons" style="font-size: small">check</i> {{sponsor}}
                            </li>
                        </ul>
                        <br><br>
                        <ul class="list-inline">
                            <li *ngFor="let sponsor of sys.sponsoring.tips"
                                [ngStyle.lg]="{'max-width':'200px'}"
                                [ngStyle.md]="{'max-width':'200px'}"
                                style="text-align: left;vertical-align: top;">
                                <center>
                                    <div><img [src]="sponsor.path"
                                              class="img-circle"
                                              [ngStyle]="{'width':'60px','height':'60px'}" /><br></div>
                                    <h2>{{sponsor.title}}</h2>
                                    <div class="text-leftx">{{sponsor.description}}</div>
                                </center>
                            </li>
                        </ul>
                        <br><br>

                        <div class="col-lg-8  col-md-8 col-sm-12 col-xs-12">
                            <h2>Attendees due to their Job Tittle</h2>
                            <ul class="list-inline">
                                <li *ngFor="let jobTittle of sys.sponsoring.attendesByJobTittle; let incremental = index" style="width: 100%;text-align: left;vertical-align: top;">
                                    <span class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></span> &nbsp; {{jobTittle.title}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4  col-md-4 col-sm-12 col-xs-12">
                            <app-piechart
                                    [type]="'donut'"
                                    [width]="'220'"
                                    [height]="'220'"
                                    [data]="{legend:this.service.orderBy(only(sys.sponsoring.attendesByJobTittle,'title'), 'desc','title'),data:this.service.orderBy(only(sys.sponsoring.attendesByJobTittle,'estimates'),'desc','estimates')}">
                            </app-piechart>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                </div>
            </div>
        </div>
        <div class="android-customized-section mdl-layout" [ngStyle]="{'background-color':'rgb(255,255,255)','padding-top':'0px'}">
            <div class="android-customized-section-image" [ngStyle]="{'height':'auto'}">
                <carousel [cellsToScroll]="1"
                          [cellsToShow]="1"
                          [height]="300"
                          [cellWidth]="service.getWidth()"
                          [autoplay]="true"
                          [arrows]="true"
                          [dots]="false"
                          [loop]="true">
                    <div class="carousel-cell" *ngFor="let banner of sys.banners">
                        <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                             [src]="banner.path" #banner />
                    </div>
                </carousel>
                <div class="" [ngStyle]="{'position': 'relative','top': '-10px','z-index': '9999999'}">
                    <center>
                        <ul class="list-inline">
                            <li *ngFor="let statistic of sys.statistics">
                                <div class="post-box" style="height: auto !important;width: 200px">
                                    <div class="text-box">
                                        <center>
                                            <h1 class="mdl-color-text--grey">
                                                {{statistic.counter}}
                                            </h1>
                                            <div clas="">
                                                <h4 class="mdl-color-text--grey">
                                                    {{statistic.title}}
                                                </h4>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </center>
                </div>
            </div>
        </div>
        <div class="android-customized-section mdl-layout" [ngStyle]="{'background-color':'#fff'}" id="programId">
            <div class="android-customized-section-text">
                <div class="mdl-typography--font-light">
                    <!--                    <div class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1 text-left">TPSW Program</div><br>-->
                    <div class="mdl-typography--display-1-color-contrast mdl-typography--font-light text-left">
                        Start planning for your time at the Tanzania Private Sector Week with this high-level agenda.
                    </div>
                    <div>
                        <mat-tab-group mat-align-tabs="start">
                            <mat-tab label="{{session.day}}" *ngFor="let session of sys.sessions">
                                <br><br>
                                <div style="align-content: start" class="pull-left">
                                    <h2>{{session.date}}</h2> <br>
                                    <mat-accordion [multi]="true">
                                        <mat-expansion-panel [expanded]="false" *ngFor="let s of session.sessions">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    {{s.time}}
                                                </mat-panel-title>
                                                <mat-panel-description class="text-left">
                                                    {{s.title}}
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div [innerHTML]="s.overview"
                                                 class="text-left">
                                            </div>
                                            <table>
                                                <table>
                                                    <tr>
                                                        <td>Keynote Speech: </td>
                                                        <td>{{s.keynoteSpeech.name + ', ' + s.keynoteSpeech.title + ' ' + s.keynoteSpeech.organization}}</td>
                                                    </tr>
                                                </table>
                                            </table>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="mdl-layout" [ngStyle]="{'background-color':'#37474f'}">
            <div class="android-customized-section"  id="aboutCongressId" class="txtWhite">
                <div class="android-customized-section-text">
                    <div class="mdl-typography--font-light mdl-typography--display-1-color-contrast"></div>
                    <p class="mdl-typography--font-light"></p>
                </div>
                <div class="android-customized-section-image">
                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <center>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                                <h1 class="android-section-title mdl-typography--display-1-color-contrast text-left" [ngStyle]="{'padding-left':'0px'}">
                                    Welcome to TPSF Private Sector Week 2024
                                </h1>
                                Following the fantastic success of the 2023 edition of the Tanzania Energy Congress, the co-organisers dmg events and Ocean Business Partners are delighted to announce that the 6th edition of the Tanzania Energy Congress will be returning to the JNICC in Dar Es Salaam from 11 - 12 September 2024.

                                The Congress, held under the patronage of Hon. Dr Doto Mashaka Biteko (MP), Deputy Prime Minister and Minister of Energy, will highlight the plans and priorities of the Ministry of Energy regarding the next strategic steps in both the energy and hydrocarbons sectors in Tanzania.

                                Join us and all the key local and international industry stakeholders at the 6th annual Congress to discover the full range of strategic business opportunities and meaningful value that recent developments will bring to the local and national economy and community.

                                <br><br>
                                <center>
                                    <a class="mdl-button
                            mdl-js-button mdl-button--raised
                            mdl-js-ripple-effect
                            mdl-color--white
                            " (click)="registerAttendee()">Book your participation</a>
                                </center>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <video [autoplay]="false"
                                       [height]="500"
                                       [controls]="true"
                                       [width]="400"
                                       [ngStyle]="{'display':'block', 'object-fit': 'cover'}"
                                       [muted]="true"
                                       [playbackRate]="0.5"
                                       [loop]="true"
                                       ngClass="">
                                    <source src="assets/video/meetings.mp4" >
                                </video>
                                <br><br>
                            </div>
                        </center>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                </div>
                <div class="android-customized-section-text">
                    <ul class="list-inline">
                        <li>
                            <h2 class="">Main Features</h2>
                            <div class="mdl-typography--font-light">
                                <ul class="list-inline">
                                    <li *ngFor="let feature of sys.congress.features" style="width: 100%;text-align: left;vertical-align: top;">
                                        <i class="material-icons" style="font-size: small">check</i> {{feature}}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li><br><br>
                            <h2 class="">Key Topics</h2>
                            <div class="mdl-typography--font-light">
                                <ul class="list-inline">
                                    <li *ngFor="let topic of sys.congress.keyTopics" style="width: 100%;text-align: left;vertical-align: top;">
                                        <i class="material-icons" style="font-size: small">check</i> {{topic}}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <br><br>
                            <h2 class="" id="delegateRegistrationId">LOCAL REGISTRATION</h2>
                            <div class="mdl-typography--font-light">
                                <div>
                                    If your company is a Tanzanian local small-medium enterprise (SME) or a Tanzanian local large enterprise (LLE) headquartered in Tanzania, please contact us using the corresponding button below.
                                </div>
                                <div><br>
                                    Ikiwa kampuni yako ni ya biashara ndogo ya kati ndani ya Tanzania (SME) au biashara kubwa ya ndani ya Tanzania (LLE) yenye makao yake makuu nchini Tanzania, tafadhali wasiliana nasi kwa kutumia kitufe kinacholingana hapa chini.
                                </div>
                                <div>
                                    <br>
                                    <ul class="list-inline">
                                        <li>
                                            <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white"
                                               (click)="registerAttendee()">Register to Attend</a>
                                        </li>
                                        <li>
                                            <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white"
                                               (click)="registerExhibitor()">Exhibit with us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mdl-layout" [ngStyle]="{'background-color':'#fff'}">
            <div class="android-more-section">
                <div class="android-section-title mdl-typography--display-1-color-contrast">Dignitaries & Industry Leaders Included</div>
                <div class="android-card-container mdl-grid">
                    <div class="mdl-cell mdl-cell--3-col mdl-cell--4-col-tablet mdl-cell--4-col-phone mdl-card mdl-shadow--3dp"
                         *ngFor="let leader of sys.leaders">
                        <div class="mdl-card__media">
                            <img src="{{leader.img}}">
                        </div>
                        <div class="mdl-card__title">
                            <h4 class="mdl-card__title-text">{{leader.name}}</h4>
                        </div>
                        <div class="mdl-card__supporting-text">
                            <span class="mdl-typography--font-light mdl-typography--subhead">
                                {{leader.company}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mdl-layout" [ngStyle]="{'background-color':'rgba(250,246,246,0.89)'}">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mdl-shadow--24dp text-left">
                <h2 class="mdl-typography--display-0 mdl-typography--font-thin">
                    TPSF Private Sector Week 2024 At a Glance<br>
                    <hr style="width: 350px"/>
                </h2>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                     *ngFor="let glance of sys.glance">
                    <mat-card class="example-card" [ngStyle]="{'margin-bottom':'50px'}">
                        <mat-card-content>
                            <h2 class="">{{glance.title}}</h2>
                        </mat-card-content>
                        <img mat-card-image src="{{glance.cover}}" alt="{{glance.title}}">
                    </mat-card>
                </div>
            </div>
        </div>

        <div class="android-wear-section"
             id="exhibitionId"
             [ngStyle]="{ 'display':'block', 'object-fit': 'contain','background-image':'url(assets/images/tpsf/exhibition/exh-3.jpg)'}">
            <div class="android-wear-band">
                <div class="android-wear-band-text">
                    <h1 class="mdl-typography--font-thin">Why Exhibit At TPSF Private Sector Week</h1>
                    <div class="mdl-typography--headline mdl-typography--font-thin">
                        <ul class="list-inline">
                            <li *ngFor="let interestedIn of sys.interestedIn" style="max-width: 200px;text-align: left;vertical-align: top;">
                                <i class="material-icons" style="font-size: small">check</i> {{interestedIn}}
                            </li>
                        </ul>
                    </div>
                    <div class="mdl-typography--headline mdl-typography--font-thin" [ngStyle]="{'padding-top':'20px'}">
                        <h2>Who you will meet At TPSF Private Sector Week</h2>
                        <div class="col-lg-8  col-md-8 col-sm-12 col-xs-12">
                            <ul class="list-inline">
                                <li *ngFor="let audience of sys.audiences; let incremental = index" style="width: 100%;text-align: left;vertical-align: top;">
                                    <span class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></span> &nbsp; {{audience.title}}
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4  col-md-4 col-sm-12 col-xs-12">
                            <app-piechart
                                    *ngIf="sys.hasOwnProperty('audiences')"
                                    [type]="'donut'"
                                    [width]="'220'"
                                    [height]="'220'"
                                    [data]="{legend:this.service.orderBy(only(sys.audiences,'title'), 'desc','title'),data:this.service.orderBy(only(sys.audiences,'estimates'),'desc','estimates')}">
                            </app-piechart>
                        </div>
                    </div><br>
                    <p>
                        <a class="mdl-typography--font-regular mdl-typography--text-uppercase android-alt-link"
                           (click)="registerExhibitor()">
                            Register to exhibit with us&nbsp;<i class="material-icons">chevron_right</i>
                        </a>
                        <a class="mdl-typography--font-regular mdl-typography--text-uppercase android-alt-link">
                            View the exhibition floorplan&nbsp;<i class="material-icons">chevron_right</i>
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="mdl-layout" [ngStyle]="{'background-color':'#fff'}" id="networkingId">
            <div class="android-customized-section">
                <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/tpsf/networking/network.jpg)','background-size': 'cover'}"></div>
                <div class="android-customized-section-text">
                    <div class="mdl-typography--font-light mdl-typography--display-1-color-contrast">Unrivalled Networking Opportunities</div>
                    <p class="mdl-typography--font-light text-left">
                        TPSF Private Sector Week 2024 will consist of 2 days of quality networking with key
                        decision makers. The Industrial Tech Event will once again provide an unparalleled platform for strategic networking,
                        connecting you with the right people to add value to your business.
                        The Congress offers extensive networking functions, providing attendees with ample opportunities to build, develop and continue
                        business partnerships with old and new players from across the entire global value chain.
                        <br><br>
                        <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white bottomSpaceX20"
                           (click)="registerExhibitor()">Want to Sponsor A Function 2024 &nbsp;<i class="material-icons">chevron_right</i></a>
                    </p>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12"><br><br>
                    <ul class="list-inline bottomSpaceX20">
                        <li class="" style="vertical-align: text-top">
                            <img src="assets/images/tpsf/networking/network.jpg"
                                 class="mdl-shadow--3dp"
                                 [ngStyle]="{'width':'300px','height':'auto'}"/>
                        </li>
                        <li [ngStyle.lg]="{'max-width':'15%'}"
                            [ngStyle.md]="{'max-width':'15%'}"
                            style="vertical-align: text-top"
                            class="text-left">
                            <h1 class="mdl-typography--font-light mdl-typography--display-1-color-contrast bottomSpaceX20">
                                Coffee Breaks & Lunches
                            </h1>
                            <div class="text-left">
                                Throughout the Congress programme there are extended coffee breaks and
                                lunches to maximise your networking time without missing
                                out on any key sessions.
                                23 - 24 April 2024
                            </div>
                        </li>
                    </ul>
                    <div>
                        <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white bottomSpaceX20"
                           (click)="registerExhibitor()">Sponsor Coffee Breaks & Lunches &nbsp;<i class="material-icons">chevron_right</i></a>
                    </div>
                    <br><br><br><br>
                    <ul class="list-inline bottomSpaceX20">
                        <li [ngStyle.lg]="{'max-width':'25%'}"
                            [ngStyle.md]="{'max-width':'25%'}"
                            style="vertical-align: text-top"
                            class="text-left">
                            <div class="mdl-typography--font-light mdl-typography--display-1-color-contrast bottomSpaceX20">
                                Networking Roundtables
                            </div>
                            <div class="text-left">
                                TEC is committed to fostering in-depth discussions and promoting networking opportunities.
                                Participants will engage in practical roundtable discussions in a relaxed and informal environment.
                                Each table will be led by an industry expert to delve into Tanzania’s hot industrial sector topics.
                                Participants will be able to discuss key topics,
                                exchange business cards,
                                forge partnerships and have the opportunity to sit at two different 30-minute roundtable sessions
                                of their choice.
                            </div>
                        </li>
                        <li class="" style="vertical-align: text-top">
                            <img src="assets/images/tpsf/networking/sessions-1.jpeg"
                                 class="mdl-shadow--3dp"
                                 [ngStyle]="{'width':'300px','height':'auto'}"/>
                        </li>
                    </ul>
                    <div>
                        <a class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-color--white bottomSpaceX20"
                           (click)="registerExhibitor()">Sponsor Networking Roundtables &nbsp;<i class="material-icons">chevron_right</i></a>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
            </div>
        </div>

        <div class="mdl-layout txtWhite" [ngStyle]="{'background-color': 'rgb(16,30,81)'}">
            <div class="android-customized-section">
                <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0"></div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left">
                    <ul class="list-inline">
                        <li style="vertical-align: top">
                            <h2 class="bottomSpaceX20 text-uppercase">Exhibition Opening Times</h2>
                            <div>
                                Tuesday 23 April: 8AM - 7:30
                            </div>
                            <div>
                                Wednesday 24 April: 8AM - 7:30
                            </div>
                        </li>
                        <li style="vertical-align: top">
                            <h2 class="bottomSpaceX20 text-uppercase">Quick links</h2>
                            <div>
                                <table>
                                    <tr>
                                        <td (click)="scrollIntoView('aboutCongressId')">
                                            Congress
                                        </td>
                                    </tr>
                                    <tr>
                                        <td (click)="scrollIntoView('benefitOfSponsoringId')">
                                            Sponsors
                                        </td>
                                    </tr>
                                    <tr>
                                        <td (click)="scrollIntoView('networkingId')">
                                            Networking
                                        </td>
                                    </tr>
                                    <tr>
                                        <td (click)="scrollIntoView('exhibitionId')">
                                            Exhibition
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </li>
                        <li style="vertical-align: top">
                            <h2 class="bottomSpaceX20 text-uppercase">Contact us</h2>
                            <h3>Sponsorship & Exhibition Opportunities</h3>
                            <div></div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0"></div>
            </div>
        </div>


        <!--                <div class="android-more-section" id="restaurants.bars">-->
        <!--                    <h4 class="android-section-title mdl-typography&#45;&#45;display-1-color-contrastx X2 textWht" [ngStyle]="{'color':'rgb(255, 64, 129)'}">Restaurants & bars</h4>-->
        <!--                    <p class="18 textWht mdl-typography&#45;&#45;text-lowercase">-->
        <!--                        With hundreds of restaurants in Tanzania to choose from, you are going to be spoilt for choice. Why not try one of our recommendations.-->
        <!--                    </p>-->
        <!--                    <div>-->
        <!--                        <section class="post-section blog-post blog-medium" [ngStyle]="{'background':'transparent'}">-->
        <!--                            <div class="container">-->
        <!--                                <div class="col-md-11 col-sm-11">-->
        <!--                                    <div class="post-box">-->
        <!--                                        <div class="frame" [ngStyle]="{'max-height':'350px','max-width':'350px'}">-->
        <!--                                            <a href="#"><img src="assets/images/warehouse/img/379917541_864737445070182_6333753520672505327_n.jpg" alt="img"></a>-->
        <!--                                        </div>-->
        <!--                                        <div class="text-box">-->
        <!--                                            <h4 class="X2 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                <a>hard rock cafe spotlight Hard Rock Cafe</a>-->
        <!--                                            </h4>-->
        <!--                                            <p class="18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                Hard Rock Cafe is coming at you live from Newcastle, with a new layout paying tribute to the past by keeping in line with the former grandeur and arches.-->
        <!--                                            </p>-->
        <!--                                            <strong class="name"><a mat-flat-button class="X2 textWht">more info.. <i class="glyphicon glyphicon-chevron-rightx"></i></a></strong>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                    <div class="post-box">-->
        <!--                                        <div class="frame" [ngStyle]="{'max-height':'350px','max-width':'350px'}">-->
        <!--                                            <a href="#"><img src="assets/images/warehouse/img/380025568_10315629614555192784_863046233335_n.jpg" alt="img"></a>-->
        <!--                                        </div>-->
        <!--                                        <div class="text-box">-->
        <!--                                            <h4 class="X2 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                <a>The Botanist</a>-->
        <!--                                            </h4>-->
        <!--                                            <p class="18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                Home of timeless experiences… step inside to enjoy botanical cocktails, finest ales, a food menu full of the flavours of home all served by our passionate team and a backdrop of your favourite music.-->
        <!--                                            </p>-->
        <!--                                            <strong class="name"><a mat-flat-button class="X2 textWht">more info.. <i class="glyphicon glyphicon-chevron-rightx"></i></a></strong>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                    <div class="post-box">-->
        <!--                                        <div class="frame" [ngStyle]="{'max-height':'350px','max-width':'350px'}">-->
        <!--                                            <a href="#"><img src="assets/images/warehouse/img/380025568_10315629614555192784_863046233335_n.jpg" alt="img"></a>-->
        <!--                                        </div>-->
        <!--                                        <div class="text-box">-->
        <!--                                            <h4 class="X2 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                <a>Victors </a>-->
        <!--                                            </h4>-->
        <!--                                            <p class="18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                Enjoy everyday luxury and escape to Victors Newcastle, in the heart of the city along the Quayside.-->
        <!--                                                Experience our modern Asian and Hamptons, New York inspired menu and show stopping cocktails served under our iconic wisteria trees.-->
        <!--                                            </p>-->
        <!--                                            <strong class="name"><a mat-flat-button class="X2 textWht">more info.. <i class="glyphicon glyphicon-chevron-rightx"></i></a></strong>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </section>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" id="conference">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class=" X2 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            Conference & meetings<br>-->
        <!--                            <a mat-flat-button class=" mdl-typography&#45;&#45;text-lowercasex mdl-typography&#45;&#45;font-bold">-->
        <!--                                Hire venue-->
        <!--                                <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                            </a>-->
        <!--                        </h4>-->
        <!--                        <h4 class=" mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            warehouse Arena has welcomed many high profile events, meetings and conferences.-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            No matter how big or small your event is, the venue will give you a successful and secure event. We can cater for Conferences, AGMs, Gala Dinners, Private Speaker events or Intimate Meetings, just to name a few. With a theatre style space catering up to 9,000 people and private lounges, there isn't much this venue can't do.-->
        <!--                            If you are looking for a venue to hold all elements of your conference under one roof then look no further.-->
        <!--                            <br>-->
        <!--                        </p>-->
        <!--                        <p>-->
        <!--                        <span>-->
        <!--                            <div [ngStyle]="{'width':'350px','padding':'10px'}" class="mdl-shadow&#45;&#45;2dp">-->
        <!--                                <h4 class="X3 textWht text-left">-->
        <!--                                    Talk to us<br>-->
        <!--                                </h4>-->
        <!--                                <div class="18 textWht text-left">-->
        <!--                                    Tell us about your event requirements and then let us prepare a detailed proposal excusively for you<br><br>-->
        <!--                                </div>-->
        <!--                                <div class="text-left">-->
        <!--                                    <a mat-flat-button class="mitraX2 textBlack">-->
        <!--                                        request proposal-->
        <!--                                        <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                    </a>-->

        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </span>-->
        <!--                        </p>-->
        <!--                        <p>-->
        <!--                        <span>-->
        <!--                            <div [ngStyle]="{'width':'350px','padding':'10px'}" class="mdl-shadow&#45;&#45;2dp">-->
        <!--                                <h4 class="X3 textWht text-left">-->
        <!--                                    Collect conference feedback<br>-->
        <!--                                </h4>-->
        <!--                                <div class="18 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                    Know attendees opinions during and after a conference by setting a-->
        <!--                                    checklist of questionnaires for collecting responses that are valuable,-->
        <!--                                    honest and insightful. It is simple as that attendees feedback surveys can-->
        <!--                                    provide you with a complete picture of how your user base feels about, and-->
        <!--                                    the appropriate changes can be made based on this information. By listening-->
        <!--                                    to your attendees, you are also showing them that they are valuable-->
        <!--                                    to you and your business.-->
        <!--                                    <br><br>-->
        <!--                                </div>-->
        <!--                                <div class="text-left">-->
        <!--                                    <a mat-flat-button class="mitraX2 textBlack">-->
        <!--                                        enquiry service-->
        <!--                                        <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                    </a>-->

        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </span>-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/meetings/meeting-1.jpg)'}"></div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'#fff'}">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        &lt;!&ndash;        <div class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrast textBlack">Our clients</div>&ndash;&gt;-->
        <!--                    </div>-->
        <!--                    <div>-->
        <!--                        &lt;!&ndash;RESUME PROCESS SECTION START&ndash;&gt;-->
        <!--                        <section class="resume-process" [ngStyle]="{'float':'initial','padding':'0px','margin':'0px'}">-->
        <!--                            <div class="container">-->
        <!--                                <div class="row">-->
        <!--                                    <carousel [height]="100"-->
        <!--                                              [autoplay]="true"-->
        <!--                                              [arrows]="true"-->
        <!--                                              [dots]="true"-->
        <!--                                              [loop]="true"-->
        <!--                                              *ngIf="clients.length!=0">-->
        <!--                                        <div class="carousel-cell"-->
        <!--                                             *ngFor="let client of clients">-->
        <!--                                            <img src="{{client.logo}}" class="img-thumbnail" [ngStyle]="{'width':'70px','height':'70px'}">-->
        <!--                                        </div>-->
        <!--                                    </carousel>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </section>-->
        <!--                        &lt;!&ndash;RESUME PROCESS SECTION END&ndash;&gt;-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" id="exhibitions">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class=" X3 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            Exhibitions<br>-->
        <!--                            <a mat-flat-button class="mitraX4 mdl-typography&#45;&#45;text-lowercase mdl-typography&#45;&#45;font-bold">-->
        <!--                                Enquiry now-->
        <!--                                <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                            </a>-->
        <!--                        </h4>-->
        <!--                        <h4 class=" mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            warehouse Arena has the largest exhibition facilities in Tanzania.-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            An uninterupted roof span and a total floor area of 3960 square metres plus an external exhibition area completes the available of the 36,000 square metre complex.-->
        <!--                            Flexibility is the key word, with many possibilities for various types and sizes of exhibitions.-->
        <!--                            Whether it be a wedding show with over 100 stands, the award winning Manufacturing and Engineering North East Exhibition or the UCAS higher education showcase, Utilita Arena Newcastle can do it all.-->
        <!--                            <br>-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                    &lt;!&ndash;    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/meetings/meeting-1.jpg)'}"></div>&ndash;&gt;-->
        <!--                </div>-->
        <!--                <div class="android-more-section" id="hotels">-->
        <!--                    <h4 class="android-section-title mdl-typography&#45;&#45;display-1-color-contrastx X2 textWht">Hotels</h4>-->
        <!--                    <p class="18 textWht">-->
        <!--                        Looking for a place to stay? warehouse Arena has teamed up with a range of hotels to make your stay in Tanzania as enjoyable as your visit to the Arena.-->
        <!--                    </p>-->
        <!--                    <div class="android-card-container mdl-grid">-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Crown Plaza Newcastle </h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            Hawthorn House, Forth Street, Newcastle upon Tyne, NE1 3SA-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercasex">-->
        <!--                                    more info ..-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/380025568_10315629614555192784_863046233335_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Malmaison</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            Malmaison-->
        <!--                            104 Quayside-->
        <!--                            Newcastle upon Tyne-->
        <!--                            NE1 3DX-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    more info..-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/379917541_864737445070182_6333753520672505327_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Hotel Du Vin</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            Hotel Du Vin-->
        <!--                            Allan House-->
        <!--                            City Rd-->
        <!--                            Byker-->
        <!--                            Newcastle upon Tyne-->
        <!--                            NE1 2BE-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Download now-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/379836749_224860807228265_7746673976262836220_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Hilton Gateshead</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            Bottle Bank, Gateshead, Tyne and Wear, NE8 2AR-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Find apps-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/380025568_1031562961192784_8630462333332396635_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Hotel Indigo</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            2-8 Fenkle Street, Newcastle upon Tyne, Tyne & Wear, NE1 5XN-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Find apps-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="assets/images/warehouse/img/380025568_10315629614555192784_863046233335_n.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Maldron Hotel</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">-->
        <!--                            17 Newgate Street, Newcastle upon Tyne, NE1 5RE-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Find apps-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="android-more-section" id="visitors.entry.guide">-->
        <!--                    <h4 class="textWht X2">Rules and regulations</h4>-->
        <!--                    <p class="18 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                        Warehouse Arena is committed to creating a safe and memorable experience for all its visitors-->
        <!--                        By entering the venue, visitors acknowledge the following rules and regulations-->
        <!--                    </p>-->
        <!--                    <mat-accordion [multi]="true">-->
        <!--                        <mat-expansion-panel [expanded]="false">-->
        <!--                            <mat-expansion-panel-header>-->
        <!--                                <mat-panel-title class="18">-->
        <!--                                    Entry and Attendance rules and regulations-->
        <!--                                </mat-panel-title>-->
        <!--                            </mat-expansion-panel-header>-->
        <!--                            <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <p class="18">The-->
        <!--                                    Arena Kuwait has an extensive list of entry and attendance rules and-->
        <!--                                    regulations (the “Entry and Attendance Rules and Regulations”) for the-->
        <!--                                    attendees/spectators (the “Attendees”) to attend events held at the-->
        <!--                                    Arena Kuwait. The General Entry and Attendance Rules and Regulations-->
        <!--                                    must be adhered to for every event.-->
        <!--                                </p>-->
        <!--                                <p class="18">Nevertheless, other rules-->
        <!--                                    and regulations that are event specific and agreed upon between The-->
        <!--                                    Arena Kuwait and the event’s organizer may also be part of the Entry and-->
        <!--                                    Attendance Rules and Regulations for such events. The Entry and-->
        <!--                                    Attendance Rules and Regulations may be displayed at the entrances of-->
        <!--                                    the Arena during the events’ days and may change at any time at the-->
        <!--                                    discretion of The Arena Kuwait’s management.-->
        <!--                                </p>-->
        <!--                                <p class="18">The General Entry and Attendance Rules and Regulations are as follows: </p>-->
        <!--                                <p class="18"><strong>A. Event Entry Rules &amp; Regulations</strong></p>-->
        <!--                                <ul class="18">-->
        <!--                                    <li>-->
        <!--                                        The-->
        <!--                                        Arena Kuwait hours of operations are based on scheduled event timings-->
        <!--                                        and Attendees may gain entry generally 60 - 90 minutes before the start-->
        <!--                                        of an event (please check the ticket for exact event timings).</li><li>Entry to The Arena Kuwait is only permitted to those who are in possession of a valid ticket or accreditation pass.</li><li>Attendees-->
        <!--                                    under the age of 16 must be accompanied by adults who shall be deemed-->
        <!--                                    to be fully responsible for the presence of these Attendees at the Arena-->
        <!--                                    Kuwait. For some events, different age requirements may apply. Please-->
        <!--                                    refer to the specific event website for further information.</li><li>Attendees shall follow the local dress code appropriate to Kuwait’s culture and traditional values.</li><li>Entry-->
        <!--                                    to The Arena Kuwait could be subject to certain security and/or health-->
        <!--                                    restrictions and entry and attendance measures imposed by the formal-->
        <!--                                    authorities. </li><li>Small bags of the Attendees will be screened at the point of entry.</li><li>After-->
        <!--                                    entry, Attendees are required to head directly to their designated-->
        <!--                                    seats and remain seated therein during the event time except for the-->
        <!--                                    purpose of purchasing food and beverages, comfort breaks, etc. </li><li>In case of leaving the Arena Kuwait premises, re-entry will not be permitted.</li></ul>-->
        <!--                                <p class="18"><strong>B. Prohibited Conduct &amp; Items</strong></p>-->
        <!--                                <p class="18">For-->
        <!--                                    a safe and enjoyable presence at the Arena Kuwait during an event, no-->
        <!--                                    disorderly/inappropriate conduct by any Attendee will be tolerated or-->
        <!--                                    any action or material posing a potential threat to the safety,-->
        <!--                                    security, or enjoyment of any of the Attendees, or the proper-->
        <!--                                    presentation of the event, will be allowed inside the Arena Kuwait. </p>-->
        <!--                                <p class="18">Accordingly, the following actions – among others – will be absolutely prohibited: </p>-->
        <!--                                <ul><li>Standing or dancing during the event.</li><li>Smoking, including e-cigarettes.</li><li>Use of lighters and matches.</li><li>Use-->
        <!--                                    of Flash photography or video recording. (Exceptions could only be made-->
        <!--                                    to accredited members of the press by the Arena Kuwait management)</li><li>Bringing in the Arena Kuwait: </li><li>Strollers, prams, and other forms of baby capsules.</li><li>Animals.</li><li>Skateboard, rollerblading, bicycle, or other sports apparatus</li><li>Weapons or sharp tools or any other item that could cause injury to others</li><li>Sel­fie sticks, flags, banners </li><li>Suitcases, backpacks, stroller bags, large bags (more than the size of 30cm x 30cm x 15cm.), and other storage items </li></ul>-->
        <!--                                <p class="18"><strong>C. Filming &amp; Security</strong></p>-->
        <!--                                <p class="18">There-->
        <!--                                    could be fi­lming, photography, and recording during the events hosted-->
        <!--                                    at the Arena Kuwait. By entering the Arena Kuwait, Attendees confirm-->
        <!--                                    their consent to interviews, photography, audio recording, video-->
        <!--                                    recording and its/their release, publication, exhibition, or-->
        <!--                                    reproduction to be used for news, webcasts, promotional purposes,-->
        <!--                                    advertising, inclusion on websites, social media or for other purposes-->
        <!--                                    seen ­by the Arena Kuwait team, its affiliates, and its representatives.-->
        <!--                                </p>-->
        <!--                                <p class="18">The-->
        <!--                                    Arena Kuwait Management advises that Attendees may be recorded on-->
        <!--                                    either commercial broadcast or Closed-Circuit Television upon entry and-->
        <!--                                    throughout the Arena Kuwait and surrounding areas for safety and-->
        <!--                                    security purposes. The Arena Kuwait is equipped with 24/7 video-->
        <!--                                    surveillance systems to ensure the safety of its visitors at all times.-->
        <!--                                </p>-->
        <!--                                <p class="18">Accordingly,-->
        <!--                                    attendees waive all rights they may have to any claims in connection-->
        <!--                                    with any sort of legitimate use of the above films, photographs, or-->
        <!--                                    recordings by The Arena Kuwait or otherwise.-->
        <!--                                </p>-->
        <!--                                <p class="18"><strong>D. Food &amp; Beverage</strong></p>-->
        <!--                                <p class="18">Food-->
        <!--                                    and beverages are not allowed from outside the Arena Kuwait. Only food-->
        <!--                                    and beverage purchased within The Arena Kuwait can be consumed-->
        <!--                                    on-premises at an event.</p>-->
        <!--                            </div>-->
        <!--                        </mat-expansion-panel>-->
        <!--                        <mat-expansion-panel [expanded]="false">-->
        <!--                            <mat-expansion-panel-header>-->
        <!--                                <mat-panel-title class=" 18">-->
        <!--                                    Reservation of right-->
        <!--                                </mat-panel-title>-->
        <!--                            </mat-expansion-panel-header>-->
        <!--                            <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <p class="18">-->
        <!--                                    The arena kuwait management reserves the absolute right, without refund or compensation, and at its sole discretion to refuse admission or evict any person who does not abide by the entry and attendance rules and regulations-->
        <!--                                </p>-->
        <!--                            </div>-->
        <!--                        </mat-expansion-panel>-->
        <!--                        <mat-expansion-panel [expanded]="false">-->
        <!--                            <mat-expansion-panel-header>-->
        <!--                                <mat-panel-title class="18">-->
        <!--                                    Attendees’ acknowledgment-->
        <!--                                </mat-panel-title>-->
        <!--                            </mat-expansion-panel-header>-->
        <!--                            <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <p class="18">-->
        <!--                                    The attendee (or the attendee’s legal guardian whenever the attendee is minor) confirms his/her awareness of (i) the nature of the event and voluntarily assumes all risk and danger incidental to the event whether occurring prior to, during or after the actual event (ii) the arena kuwait reservation (iii) the event organizer’s right to add, withdraw, reschedule, or substitute artists. And/or vary advertised programmes, prices, seating arrangements and audience capacity.-->
        <!--                                </p>-->
        <!--                            </div>-->
        <!--                        </mat-expansion-panel>-->
        <!--                        <mat-expansion-panel [expanded]="false">-->
        <!--                            <mat-expansion-panel-header>-->
        <!--                                <mat-panel-title class="18">-->
        <!--                                    Ticket rules and regulations-->
        <!--                                </mat-panel-title>-->
        <!--                            </mat-expansion-panel-header>-->
        <!--                            <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <p class="18">-->
        <!--                                    The attendee (or the attendee’s legal guardian whenever the attendee is minor) confirms his/her awareness of (i) the nature of the event and voluntarily assumes all risk and danger incidental to the event whether occurring prior to, during or after the actual event (ii) the arena kuwait reservation (iii) the event organizer’s right to add, withdraw, reschedule, or substitute artists. And/or vary advertised programmes, prices, seating arrangements and audience capacity.-->
        <!--                                </p>-->
        <!--                            </div>-->
        <!--                        </mat-expansion-panel>-->
        <!--                    </mat-accordion>-->
        <!--                    <p class="18 textWht text-left">-->
        <!--                        Always remember, your safety and enjoyment is our priority-->
        <!--                    </p>-->
        <!--                </div>-->

        <!--                <div class="android-more-section" id="event.calender" [ngStyle]="{'background-color':'#fff'}">-->
        <!--                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
        <!--                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" [ngStyle]="{'padding':'0px','margin':'0px'}">-->
        <!--                            <h4 class="X3 text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                                event calender-->
        <!--                            </h4>-->
        <!--                            <mat-calendar [selected]="getDateSelected()" class="20"></mat-calendar>-->
        <!--                        </div>-->
        <!--                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" [ngStyle]="{'padding':'0px','margin':'0px'}">-->
        <!--                    <span class="18 textBlack mdl-typography&#45;&#45;font-light">-->
        <!--                        add your event date, and wait will contact you soon..-->
        <!--                        <a mat-mini-fab color="warn"><i class="glyphicon glyphicon-plus"></i></a>-->
        <!--                    </span>-->
        <!--                            <br><br>-->
        <!--                            <mat-accordion [multi]="true">-->
        <!--                                <mat-expansion-panel [expanded]="true">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="mdl-typography&#45;&#45;text-lowercase ">-->
        <!--                                            Matchroom Boxing-->
        <!--                                        </mat-panel-title>-->
        <!--                                        <mat-panel-description class=" mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                            11 Nov, 2023-->
        <!--                                        </mat-panel-description>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        McCormack vs Dobson-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="mdl-typography&#45;&#45;text-lowercase ">-->
        <!--                                            Disney On Ice-->
        <!--                                        </mat-panel-title>-->
        <!--                                        <mat-panel-description class=" mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                            11 Nov, 2023-->
        <!--                                        </mat-panel-description>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        presents 100 Years of Wonder-->
        <!--                                        Located on the banks of the River Tyne, warehouse Arena is ten minutes away from Central Station and the Tyne and Wear Metro-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                            </mat-accordion>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" id="getting.to.the.venue" [ngStyle]="{'background-color':'#fff'}">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class=" X3 text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            Getting to the venue<br>-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;text-lowercase 18 mdl-color-text&#45;&#45;grey-700 text-left">-->
        <!--                            Located on the banks of the River Tyne, warehouse Arena is ten minutes away from Central Station and-->
        <!--                            the Tyne and Wear Metro.-->
        <!--                        </p>-->
        <!--                        <h4 class=" mdl-typography&#45;&#45;font-light text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            How to get there by car or coach-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-color-text&#45;&#45;grey-700 mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            warehouse Arena is only 3 minutes from the A1 North - South route. Take the A184 signposted 'City Centre',-->
        <!--                            then follow the A189 over the Redheugh Bridge. When in the vicinity of the Arena, follow the signs to the-->
        <!--                            appropriate car parks.-->
        <!--                            Our full address for your sat nav is - Utilita Arena Newcastle, Arena Way, Newcastle upon Tyne, NE4 7NA-->
        <!--                            There are two different ways to pay at Utilita Arena Newcastle with the quickest and easiest option being to download the Just Park app.-->
        <!--                        </p>-->
        <!--                        <h4 class=" X3 text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            Public transport-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;text-lowercase 18 mdl-color-text&#45;&#45;grey-700 text-left">-->
        <!--                            Many local bus services, Tyne& Wear Metro and rail services stop at Central Station, which is only around a 5 minute walk away from the Arena. Buses from Hexham, Stanley, Consett and many parts of Gateshead and Newcastle all stop at Central Station.-->

        <!--                            Eldon Square and Haymarket Bus Stations are around a 10-minute walk from Utilita Arena Newcastle.-->

        <!--                            Public Transport information can be found at www.nexus.org.uk or by calling Traveline on 0871 200 2233.-->

        <!--                            Newcastle Central Train Station, situated on Neville Street is around a 15min walk from Utilita Arena Newcastle providing connections to the local Metro Service. Newcastle Central Station is the busiest station on the East Coast Main Line service connecting people to regional and national destinations including Newcastle International Airport.-->
        <!--                        </p>-->
        <!--                        <h4 class=" X3 text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            warehouse Arena Event Car Parking-->
        <!--                        </h4>-->
        <!--                        <div class="mdl-typography&#45;&#45;text-lowercase 18 mdl-color-text&#45;&#45;grey-700 text-left">-->
        <!--                            There are over 600 convenient Official Car Park spaces available on site at the rear of the building that offer;-->
        <!--                            <ul class="text-left 18" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                <li>CCTV cameras</li>-->
        <!--                                <li>Regular security patrols</li>-->
        <!--                            </ul>-->
        <!--                            Alternatively, when our Car Park is full, we recommend using the NCP Car Park located next to the Arena, with its entrance on Redheugh Bridge Road.-->
        <!--                            Cars with wheelchair patrons can park at the Arena subject to availability on a first-come first-served basis. Pre-booking is not available.-->
        <!--                        </div>-->
        <!--                        <h4 class=" X3 text-left mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            Dropping Off & picking up-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;text-lowercase 18 mdl-color-text&#45;&#45;grey-700 text-left">-->
        <!--                            Dropping off can be made along Railway Street. You are advised to collect people from an area away from the Arena as it is likely that you will become involved in heavy traffic.-->
        <!--                            For many shows the road at the front of the building, Redheugh Bridge Road, will be closed for pedestrian safety and approach should be made via Pottery Lane from the east, or up Shot Factory Lane from Skinnerburn Road next to the river.-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                    &lt;!&ndash;    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/meetings/meeting-1.jpg)'}"></div>&ndash;&gt;-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" id="unique.events">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class=" X2 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            Unique Events<br>-->
        <!--                            <a mat-flat-button class="X2 mdl-typography&#45;&#45;font-thin mdl-typography&#45;&#45;text-lowercase mdl-typography&#45;&#45;font-bold">-->
        <!--                                Enquiry for venue hiring and more..-->
        <!--                                <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                            </a>-->
        <!--                        </h4>-->
        <!--                        <h4 class=" mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            warehouse Arena is an exceptionally unique venue that can deliver a bespoke experience.-->
        <!--                            Dinners, award ceremonies and one of a kind events are our speciality at making memories for you and your guests.-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            Set your event apart and wow guests by inviting them in to the same room global superstars have played,-->
        <!--                            not many venues can give you that experience. Present on the same stage that has seen the likes of Lady J Dee, Diamond Platnum and Alkiba entertain the crowds.-->
        <!--                            Our banqueting space can cater for 1500 guests, with casual dining options for up to 4000 guests.-->
        <!--                            <br>-->
        <!--                        </p>-->
        <!--                        <p>-->
        <!--                        <span>-->
        <!--                            <div [ngStyle]="{'width':'350px','padding':'10px'}" class="mdl-shadow&#45;&#45;2dp">-->
        <!--                                <h4 class="X2 textWht text-left">-->
        <!--                                    Talk to us<br>-->
        <!--                                </h4>-->
        <!--                                <div class="18 textWht text-left">-->
        <!--                                    Tell us about your event requirements and then let us prepare a detailed proposal excusively for you<br><br>-->
        <!--                                </div>-->
        <!--                                <div class="text-left">-->
        <!--                                    <a mat-flat-button class="X2 mdl-typography&#45;&#45;font-bold">-->
        <!--                                        request proposal..-->
        <!--                                        <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                    </a>-->

        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </span>-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/meetings/meeting-1.jpg)'}"></div>-->
        <!--                </div>-->

        <!--                <div class="android-more-section" id="gallery">-->
        <!--                    <h4 class="android-section-title mdl-typography&#45;&#45;display-1-color-contrastx X2 textWht">Gallery</h4>-->
        <!--                    <p class="18 textWht mdl-typography&#45;&#45;text-lowercase">-->
        <!--                        Check out the previous events proudly hosted at warehouse arena-->
        <!--                    </p>-->
        <!--                    <div class="android-card-container mdl-grid">-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp" [ngStyle]="{'background-color':'#000'}">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="http://localhost/warehouse/images/IMG_9711.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead textWht mdl-typography&#45;&#45;font-bold 14 X3 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            Watheefti 2, 19-21 October 2023-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a mat-flat-button class="mdl-typography&#45;&#45;text-uppercase pull-right mitra">-->
        <!--                                    View..-->
        <!--                                    <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp" [ngStyle]="{'background-color':'#000'}">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="http://localhost/warehouse/images/IMG_9655.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead  14 X3 mdl-typography&#45;&#45;font-bold textWht mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            FERA Championship, 21 Sept 2023-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a mat-flat-button class="mdl-typography&#45;&#45;text-uppercase pull-right mitra">-->
        <!--                                    View..-->
        <!--                                    <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp" [ngStyle]="{'background-color':'#000'}">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="http://localhost/warehouse/images/navas_foto-88-MS.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead  textWht mdl-typography&#45;&#45;font-bold 12 X2 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            eykom Tarab? A Night with Miami, 2-21 June 2023-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a mat-flat-button class="mdl-typography&#45;&#45;text-uppercase pull-right mitra">-->
        <!--                                    View..-->
        <!--                                    <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp" [ngStyle]="{'background-color':'#000'}">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="http://localhost/warehouse/images/navas_foto-BAR.jpg">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                        <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead  textWht 12 X2  mdl-typography&#45;&#45;text-lowercase mdl-typography&#45;&#45;font-bold">-->
        <!--                            Ramy Sabry & Ahmed Saad & Bader Alshuaibi, 3 July 2023-->
        <!--                        </span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a mat-flat-button class="mdl-typography&#45;&#45;text-uppercase pull-right mitra">-->
        <!--                                    View..-->
        <!--                                    <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" id="external.events.filming">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class=" X2 textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            External Events & Filming<br>-->
        <!--                            <a mat-flat-button class="mitraX4 mdl-typography&#45;&#45;text-lowercase mdl-typography&#45;&#45;font-bold">-->
        <!--                                Enquiry-->
        <!--                                <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                            </a>-->
        <!--                        </h4>-->
        <!--                        <h4 class=" mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase">-->
        <!--                            We have the optimum environment to deliver your external event successfully.-->
        <!--                        </h4>-->
        <!--                        <p class="mdl-typography&#45;&#45;font-light textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            Bring your event to life making use of our full exterior footprint.-->

        <!--                            From X-Factor to car shows and all in-between, nothing is too extravagant for us here at warehouse.-->

        <!--                            Having had many TV/Film both internal and external, we are fully equipped to handle anything you may require.-->

        <!--                            We pride ourselves in being fully versatile.-->
        <!--                            <br>-->
        <!--                        </p>-->
        <!--                        <p>-->
        <!--                        <span>-->
        <!--                            <div [ngStyle]="{'width':'350px','padding':'10px'}" class="mdl-shadow&#45;&#45;2dp">-->
        <!--                                <h4 class="X3 textWht text-left">-->
        <!--                                    Talk to us<br>-->
        <!--                                </h4>-->
        <!--                                <div class="18 textWht text-left">-->
        <!--                                    Tell us about your event requirements and then let us prepare a detailed proposal excusively for you<br><br>-->
        <!--                                </div>-->
        <!--                                <div class="text-left">-->
        <!--                                    <a mat-flat-button class="mitraX2 textBlack">-->
        <!--                                        request proposal-->
        <!--                                        <i class="glyphicon glyphicon-chevron-right"></i>-->
        <!--                                    </a>-->

        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </span>-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/meetings/meeting-1.jpg)'}"></div>-->
        <!--                </div>-->

        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'#fff'}" id="contact">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <div>-->
        <!--                            <table>-->
        <!--                                <tr>-->
        <!--                                    <td colspan="2" [ngStyle]="{'height':'250px'}">-->
        <!--                                        <agm-map-->
        <!--                                            #gm-->
        <!--                                            [latitude]="lat"-->
        <!--                                            [longitude]="lng"-->
        <!--                                            [zoom]="10"-->
        <!--                                            [disableDefaultUI]="false"-->
        <!--                                            [ngStyle]="{'width':'100%','height':'100%'}"-->
        <!--                                            [styles]="service.app.settings.map.styles.style1">-->
        <!--                                            <agm-marker-->
        <!--                                                    [latitude]="lat"-->
        <!--                                                    [longitude]="lng"-->
        <!--                                                    [agmFitBounds]="true"-->
        <!--                                                    [openInfoWindow]="true"-->
        <!--                                            >-->
        <!--                                            </agm-marker>-->
        <!--                                        </agm-map>-->
        <!--                                        <br><br>-->
        <!--                                    </td>-->
        <!--                                </tr>-->
        <!--                                <tr>-->
        <!--                                    <td [ngStyle]="{'padding':'0px 50px 0px 0px'}">-->
        <!--                                        <table>-->
        <!--                                            <td valign="top">-->
        <!--                                                <div class="mdl-typography&#45;&#45;font-light">-->
        <!--                                                    <dl class="mdl-color-text&#45;&#45;grey-700 text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                                        <dt>-->
        <!--                                                            Telephone: +255 744 07 20 75-->
        <!--                                                        </dt>-->
        <!--                                                        <dt>-->
        <!--                                                            Open: Saturday - Thursday from 11:00 am until 7:00 pm-->
        <!--                                                        </dt>-->
        <!--                                                        <dd>-->
        <!--                                                            Email: tak-tickets@warehouse.com-->
        <!--                                                        </dd>-->
        <!--                                                    </dl>-->
        <!--                                                </div>-->
        <!--                                            </td>-->
        <!--                                            <td valign="top">-->
        <!--                                                <img src="http://localhost/nexus6-on.jpg">-->
        <!--                                            </td>-->
        <!--                                        </table>-->
        <!--                                    </td>-->
        <!--                                    <td>-->
        <!--                                        <table>-->
        <!--                                            <tr>-->
        <!--                                                <td>-->
        <!--                                                    <mat-form-field  class="example-full-width" appearance='outline'>-->
        <!--                                                        <input type="text"-->
        <!--                                                               class=""-->
        <!--                                                               placeholder="email eg. example@yahoo.com"-->
        <!--                                                               aria-label = "Number"-->
        <!--                                                               matInput/>-->
        <!--                                                    </mat-form-field>-->
        <!--                                                </td>-->
        <!--                                                <td>-->
        <!--                                                    <mat-form-field  class="example-full-width" appearance='outline'>-->
        <!--                                                        <input type="text"-->
        <!--                                                               placeholder=""-->
        <!--                                                               placeholder="phone"-->
        <!--                                                               aria-label = "Number"-->
        <!--                                                               matInput/>-->
        <!--                                                    </mat-form-field>-->
        <!--                                                </td>-->
        <!--                                            </tr>-->
        <!--                                            <tr>-->
        <!--                                                <td colspan="2">-->
        <!--                                                    <mat-form-field  class="example-full-width" appearance='outline'>-->
        <!--                                               <textarea-->
        <!--                                                       type="text"-->
        <!--                                                       placeholder=""-->
        <!--                                                       [ngStyle]="{'height':'100px'}"-->
        <!--                                                       placeholder="comments"-->
        <!--                                                       aria-label = "Number"-->
        <!--                                                       matInput>-->
        <!--                                               </textarea>-->
        <!--                                                    </mat-form-field>-->
        <!--                                                </td>-->
        <!--                                            </tr>-->
        <!--                                            <tr>-->
        <!--                                                <td colspan="2">-->
        <!--                                                    <button class="" mat-flat-button color="warn">Submit</button>-->
        <!--                                                </td>-->
        <!--                                            </tr>-->
        <!--                                        </table>-->
        <!--                                    </td>-->
        <!--                                </tr>-->
        <!--                                <tr>-->
        <!--                                    <td class=""></td>-->
        <!--                                </tr>-->
        <!--                            </table>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" id="venueInfo">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx textWht mdl-typography&#45;&#45;text-lowercase text-left">-->
        <!--                            venue features and solutions-->
        <!--                        </h4>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht 18">-->
        <!--                            <div class="">-->
        <!--                                <h4 class="text-left ">-->
        <!--                                    <a class="textWht text-left">The warehouse Arena is a world-class indoor multi-purpose events venue which will drive the growth of the live entertainment, sport and events industry to new levels of achievement in Tanzania.</a>-->
        <!--                                </h4>-->
        <!--                                <h6 class=" text-left ">-->
        <!--                                    {{service.upperStartingCharacter("The warehouse Arena is set to become Tanzania’s leading entertainment venue, while enhancing Tanzanian's position as a global entertainment destination offering the ultimate options for any event and our business clients.",true)}}-->
        <!--                                    <i class="">The Arena can host a multitude of events with varying seating capacities from 1,200 to 5,800 persons</i>.-->
        <!--                                </h6>-->
        <!--                                <h6 class=" text-left">-->
        <!--                                    {{service.upperStartingCharacter('Proudly positioned within the 360 Mall entertainment, leisure and shopping destination, and adjacent to the Rafa Nadal Academy and the Grand Hyatt Kuwait, The Arena is destined to become a key event hub and visitor attraction in Tanzania.',true)}}-->
        <!--                                </h6>-->
        <!--                                <h4 class=" text-left">-->
        <!--                                    <a class="text-left textWht">-->
        <!--                                        The Arena has a range of key features and attributes which will provide unique benefits for hosting your event within the venue.-->
        <!--                                    </a>-->
        <!--                                </h4>-->
        <!--                                <div class="" [ngStyle]="{'padding-top':'50px'}">-->
        <!--                                    <mat-tab-group>-->
        <!--                                        <mat-tab mat-align-tabs="center">-->
        <!--                                            <ng-template mat-tab-label>-->
        <!--                                                <div>-->
        <!--                                                    Key Features & Attributes-->
        <!--                                                </div>-->
        <!--                                            </ng-template>-->
        <!--                                            <br><br>-->
        <!--                                            <div>-->
        <!--                                                <ul class="text-left 18" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                                    <li>The largest indoor multipurpose venue in Tanzania built to host a wide range of event types,-->
        <!--                                                        while delivering complete live entertainment on an international scale.</li>-->
        <!--                                                    <li>Transformable “Blank Canvas” to customize the venue for commercial events or corporate functions such as product launches and brand activations</li>-->
        <!--                                                    <li>Flexible setting that can be altered to suit a range of event modes with desired seating configurations for the Arena Bowl and flat floor</li>-->
        <!--                                                    <li>Seating capacity between 1,200 to 5,800 guests depending on event configuration</li>-->
        <!--                                                    <li>A 20-meter floor to ceiling clearance to make great use of the venue’s 300-ton rigging grid</li>-->
        <!--                                                    <li>Well-designed “Back of House” with extensive support facilities and services</li>-->
        <!--                                                    <li>We provide a world class range of suppliers to support any event</li>-->
        <!--                                                    <li>Exceptional catering and hospitality options</li>-->
        <!--                                                    <li>Customer-centric venue management team operating to the highest international standards</li>-->
        <!--                                                </ul>-->
        <!--                                            </div>-->
        <!--                                        </mat-tab>-->
        <!--                                        <mat-tab>-->
        <!--                                            <ng-template mat-tab-label>-->
        <!--                                                <div>-->
        <!--                                                    Event types we can proudly host-->
        <!--                                                </div>-->
        <!--                                            </ng-template>-->
        <!--                                            <br><br>-->
        <!--                                            <ul class="text-left 18 mdl-typography&#45;&#45;text-lowercase" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                                <li>Music concerts and festivals</li>-->
        <!--                                                <li>Family Shows</li>-->
        <!--                                                <li>Theatre, Musicals, Ballet & Classical Shows</li>-->
        <!--                                                <li>Comedy Shows</li>-->
        <!--                                                <li>Thrill and Fun activity events</li>-->
        <!--                                                <li>Sports events</li>-->
        <!--                                                <li>Corporate events, functions & product launches</li>-->
        <!--                                                <li>Cultural events</li>-->
        <!--                                                <li>Exhibitions and international Expos</li>-->
        <!--                                                <li>Community Based functions</li>-->
        <!--                                            </ul>-->
        <!--                                            <h4 class="text-left "><a class="textWht">With our Business Events Partner – warehouse Arena, we can host:</a></h4>-->
        <!--                                            <ul class="text-left 18" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                                <li><a [routerLink]="'/app/meetings'">Summits, Conferences, Motivational Speakers etc..</a></li>-->
        <!--                                                <li><a [routerLink]="'/app/weddings'">Weddings</a></li>-->
        <!--                                                <li>Gala Dinners & Banquets</li>-->
        <!--                                            </ul>-->
        <!--                                        </mat-tab>-->
        <!--                                        <mat-tab>-->
        <!--                                            <ng-template mat-tab-label>-->
        <!--                                                <div>-->
        <!--                                                    Client Support-->
        <!--                                                </div>-->
        <!--                                            </ng-template>-->
        <!--                                            <br><br>-->
        <!--                                            <div class="text-left textWht 18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                <div class="">-->
        <!--                                                    Our highly professional staff can assist with initial planning stages through to the-->
        <!--                                                    seamless execution of your event. We are committed to providing the highest-->
        <!--                                                    standards of service and to ensuring the best possible experience for both-->
        <!--                                                    you and your guests.-->
        <!--                                                </div>-->
        <!--                                                <div class="">-->
        <!--                                                    To support promoters and event organizers we have developed a list of-->
        <!--                                                    “frequently asked questions”, Technical specification fact sheet and-->
        <!--                                                    numerous other guides to assist every organiser in planning and-->
        <!--                                                    organising their event in warehouse Arena.-->
        <!--                                                </div>-->
        <!--                                                <div class="">-->
        <!--                                                    The warehouse Arena team will work with all promoters and authorities-->
        <!--                                                    to support the event licensing process and ensure a challenge free process is at place.-->
        <!--                                                </div>-->
        <!--                                            </div>-->
        <!--                                        </mat-tab>-->
        <!--                                    </mat-tab-group>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <br>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'#fff'}" id="our.services">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h3 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx">Our services</h3>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                            <h6 class="text-left"><a class="mdl-color-text&#45;&#45;grey-700 text-left mdl-typography&#45;&#45;text-lowercase  18">We operate with international standards to serve you with excellence</a></h6>-->
        <!--                            <h6 class="text-left"><a class="mdl-color-text&#45;&#45;grey-700 text-left mdl-typography&#45;&#45;text-lowercase 18">When attending an event at the warehouse Arena, visitors can expect the following range of services</a></h6>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div>-->
        <!--                        &lt;!&ndash;RESUME PROCESS SECTION START&ndash;&gt;-->
        <!--                        <section class="resume-process" [ngStyle]="{'float':'initial'}">-->
        <!--                            <div class="container">-->
        <!--                                <div class="row">-->
        <!--                                    <div class="col-md-4 col-sm-6">-->
        <!--                                        <div class="option-box">-->
        <!--                                            <div class="icon-box icon-colo-4"><i class="fa fa-wifi"></i></div>-->
        <!--                                            <h4 class="X2">WI-FI</h4>-->
        <!--                                            <p class="ext-left mdl-color-text&#45;&#45;grey-700 18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                We offer complimentary Wi-Fi access throughout the venue.-->
        <!--                                            </p>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                    <div class="col-md-4 col-sm-6">-->
        <!--                                        <div class="option-box">-->
        <!--                                            <div class="icon-box icon-colo-2"><i class="fa fa-hotel"></i></div>-->
        <!--                                            <h4 class="X2">Food & Beverage</h4>-->
        <!--                                            <ul class="mdl-color-text&#45;&#45;grey-700 text-left 18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                <li>-->
        <!--                                                    The warehouse Arena offers a range of food and beverage choices operated by our partner.-->
        <!--                                                    Guests can enjoy a wide selection of their preferred food and beverage at various locations-->
        <!--                                                    throughout our venue.-->
        <!--                                                </li>-->
        <!--                                                <li>-->
        <!--                                                    Food and beverages from outside The Arena are not permitted, and any items brought will regrettably be confiscated at the security check points, except for prescribed medication.-->
        <!--                                                </li>-->
        <!--                                            </ul>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                    <div class="col-md-4 col-sm-6">-->
        <!--                                        <div class="option-box">-->
        <!--                                            <div class="icon-box icon-colo-2"><i class="fa fa-shopping-basket"></i></div>-->
        <!--                                            <h4 class="X2">collect attende's feedback</h4>-->
        <!--                                            <p class="ext-left mdl-color-text&#45;&#45;grey-700 18 mdl-typography&#45;&#45;text-lowercase text-left">-->
        <!--                                                Do you launch a new product, or run some activations or do you want to know your sponsors insights or you have conference  ?-->
        <!--                                                Know attendees opinions during and after event by setting a checklist of questionnaires for collecting responses that are valuable, honest and insightful. It is simple as that attendees feedback surveys can provide you with a complete picture of how your user base feels about, and the appropriate changes can be made based on this information. By listening to your attendees, you are also showing them that they are valuable to you and your business.-->
        <!--                                            </p>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                    <div class="col-md-4 col-sm-6">-->
        <!--                                        <div class="option-box">-->
        <!--                                            <div class="icon-box icon-colo-3"><i class="fa fa-medkit"></i></div>-->
        <!--                                            <h4 class="X2">medical services</h4>-->
        <!--                                            <p class="mdl-color-text&#45;&#45;grey-700 text-left 18 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                                Medical and first-aid provisions are available at our first-aid room located at the ground level.-->
        <!--                                                In case of urgent medical assistance, reach out to any of The Arena staff who will contact the-->
        <!--                                                onsite medical team.-->
        <!--                                            </p>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </section>-->
        <!--                        &lt;!&ndash;RESUME PROCESS SECTION END&ndash;&gt;-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-more-section" id="seating.plans">-->
        <!--                    <div class="">-->
        <!--                        <h4 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx textWht">-->
        <!--                            Seating Plans-->
        <!--                        </h4>-->
        <!--                        <p class="18 textWht text-left">-->
        <!--                            The versatility of the modern warehouse Arena means that we can change the inside layout to suit the appropriate event. View the various layouts available below.-->
        <!--                        </p>-->
        <!--                        <div>-->
        <!--                            <mat-accordion [multi]="true">-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="18">-->
        <!--                                            End-Stage Setup (Live entertainment)-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/Endstage-English.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="18">-->
        <!--                                            Centre Stage Setup-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/center-English-2.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="18">-->
        <!--                                            Sports Setup-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/Tennis-English-2.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="18">-->
        <!--                                            Three Quarter Layout Standing-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-1.JPG" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Full Seating-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-2.JPG" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Theatre Standing-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-3.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Half Seated-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-4.JPG" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Theatre Style Seated-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-5.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Large Theatre Standing-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-6.jpg" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Half Hall Standing-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <img src="assets/images/warehouse/setup/s-7.JPG" [ngStyle]="{'width':'100%'}">-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                            </mat-accordion>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'#fff'}" id="sportsbar">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx textBlack">The sportsBar</h4>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                            <h4 class="text-left">-->
        <!--                                <a class="mdl-color-text&#45;&#45;blue-grey-600 text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                    The sportsbar at warehouse Arena is open during major sporting events.-->
        <!--                                </a>-->
        <!--                            </h4>-->
        <!--                            <a mat-flat-button class="" color="">more..</a>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="android-customized-section-image" [ngStyle]="{'background-image':'url(assets/images/warehouse/canucks_sportsbar_18021017-52-A31T8800.jpg)'}"></div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'#fff'}" id="faq">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <h4 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx text-left textBlack mdl-typography&#45;&#45;text-lowercasex">-->
        <!--                            warehouse Arena FAQ-->
        <!--                        </h4>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                            <h4 class="text-left">-->
        <!--                                <a class="mdl-color-text&#45;&#45;grey-700 text-left 20 mdl-typography&#45;&#45;text-lowercase">-->
        <!--                                    Get the information you need in order to enjoy a memorable live entertainment experience at Warehouse Arena-->
        <!--                                </a>-->
        <!--                            </h4>-->
        <!--                            <div>-->
        <!--                                <carousel [cellsToScroll]="1"-->
        <!--                                          [cellsToShow]="1"-->
        <!--                                          [autoplay]="true"-->
        <!--                                          [arrows]="true"-->
        <!--                                          [dots]="true"-->
        <!--                                          [loop]="true"-->
        <!--                                          *ngIf="faq.length!=0">-->
        <!--                                    <div class="carousel-cell"-->
        <!--                                         *ngFor="let fq of faq">-->
        <!--                                        <h4 class="text-left mdl-color-text&#45;&#45;grey-700  20 mdl-typography&#45;&#45;text-lowercase mdl-typography&#45;&#45;font-bold"><span>"{{fq.Qn}}"</span></h4>-->
        <!--                                        <div class="text-left mdl-color-text&#45;&#45;grey-700 18 mdl-typography&#45;&#45;text-lowercase">{{fq.ans}}</div>-->
        <!--                                    </div>-->
        <!--                                </carousel>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'transparent'}" id="accessibility">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrast textWht text-left">-->
        <!--                            It is our priority to ensure that the venue is welcoming and accessible to as many people as possible. Our facilities are equipped to suit the needs of people with mobility difficulties and specific accessibility needs.-->
        <!--                        </div>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                            <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                                <ul class="mdl-typography&#45;&#45;text-lowercase 18 text-left textWht" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                    <li>-->
        <!--                                        Designated disabled parking spaces with ease of access-->
        <!--                                    </li>-->
        <!--                                    <li>-->
        <!--                                        40-wheel chair platforms with 40 aider seats located on the Mezzanine Floor-->
        <!--                                    </li>-->
        <!--                                    <li>-->
        <!--                                        An accessible elevator for visitors with physical disabilities (weight limit of 2000kg)-->
        <!--                                    </li>-->
        <!--                                    <li>-->
        <!--                                        Wheelchair accessible restrooms-->
        <!--                                    </li>-->
        <!--                                </ul>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-more-section" [ngStyle]="{'background-color':'transparent'}" id="ticketsInfo">-->
        <!--                    <div class="">-->
        <!--                        <h4 class="X2 mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrastx textWht text-left">-->
        <!--                            For any ticket-related matters, please contact us at-->
        <!--                        </h4>-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                            <div class="mdl-typography&#45;&#45;font-light textWht">-->
        <!--                                <dl class="textWht text-left mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                    <dt>-->
        <!--                                        Telephone: +255 744 07 20 75-->
        <!--                                    </dt>-->
        <!--                                    <dt>-->
        <!--                                        Saturday - Thursday from 11:00 am until 7:00 pm-->
        <!--                                    </dt>-->
        <!--                                    <dd>-->
        <!--                                        Email: tak-tickets@warehouse.com-->
        <!--                                    </dd>-->
        <!--                                    &lt;!&ndash;                <dd>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                    <a [routerLink]="'/app/tickets'" mat-flat-button color="" class="mdl-typography&#45;&#45;text-uppercase">learn more</a>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                </dd>&ndash;&gt;-->
        <!--                                </dl>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div>-->
        <!--                            <mat-accordion [multi]="true">-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class="18">-->
        <!--                                            Ticketing terms & conditions-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <p><strong><u>A. General</u></strong></p> <p>The sold ticket (the “<strong>Ticket</strong>”) allows the holder to attend the event associated with the Ticket (the “<strong>Event</strong>”) in accordance with the following terms and conditions:</p> <ol><li>The-->
        <!--                                        Arena Kuwait is the exclusive and sole authorized provider of-->
        <!--                                        ticket-selling services for all events at the Arena Kuwait unless-->
        <!--                                        otherwise notified officially by The Arena Kuwait. </li></ol> <p>Tickets-->
        <!--                                        purchased through any other sources are invalid and will immediately be-->
        <!--                                        deemed canceled upon detection. So, it is highly recommended to buy the-->
        <!--                                        Tickets from The Arena Kuwait being the official ticket seller for the-->
        <!--                                        Events. </p> <ol><li>A Ticket is only valid for the date, time and Event-->
        <!--                                        specified and should be presented at the time of admission and kept-->
        <!--                                        until the end of the Event.</li><li>Tickets are scanned at the entrance-->
        <!--                                        for validity and only the first scan of the bar code on the Ticket will-->
        <!--                                        grant entry. If there are multiple copies of the same Ticket, the-->
        <!--                                        scanner at the door will detect that the Ticket has already been scanned-->
        <!--                                        and no further entry will be allowed. The buyer's name is indicated on-->
        <!--                                        the Ticket.</li><li>The Ticket is not resalable, waivable, or transferable to others.</li><li>Ticket(s)-->
        <!--                                        that are duplicated, resold, waived transferred to others, or used for-->
        <!--                                        advertising, promotion or other commercial purposes will be deemed-->
        <!--                                        invalid and subject to immediate cancellation.</li><li>In case a Ticket-->
        <!--                                        was subject to any change alteration or defacing, the Ticket will-->
        <!--                                        immediately be invalid, it could only be exchanged with another valid-->
        <!--                                        Ticket if submitted within a sufficient period of time prior to the-->
        <!--                                        Event date in accordance with the terms and conditions for exchanging-->
        <!--                                        tickets as detailed in our website. </li><li>If the Ticket has been-->
        <!--                                        purchased at the Arena Kuwait Box Office, the buyer may receive a-->
        <!--                                        re-printed duplicate ticket by visiting the Box Office at the 360 Mall-->
        <!--                                        and providing the necessary proof of purchase.</li><li>The Ticket refund and exchange are limited to certain occurrences based on the Ticket Refund and Exchange Policy. </li></ol> <p><strong><u>B. Ticket Refund &amp; Exchange Policy</u></strong></p> <p>Due to the special nature of the activities related to the Event, all Ticket sales are, in general, final and non-refundable. </p> <p>However, Tickets could be canceled and the amounts paid for will be refunded in any of the following exceptional instances:<br></p> <ul><li>Cancellation of the Event. </li><li>Reschedule the Event if the Ticket holder refuses to attend the Event at the rescheduled time.</li><li>Material-->
        <!--                                        Change in the Nature of the Event: if the nature of the Event is-->
        <!--                                        changed significantly, the Ticket holder will have the option of either-->
        <!--                                        confirming his/her acceptance of the change to the nature of the Event-->
        <!--                                        or request for a refund in response to the Arena Kuwait specified-->
        <!--                                        conditions/deadline for such an option. If the Ticket holder fails to-->
        <!--                                        inform the Arena Kuwait of his/her request for a refund by this-->
        <!--                                        deadline, it will be considered a confirmation from the Ticket holder-->
        <!--                                        for the modified Event and he/her will not be entitled to request a-->
        <!--                                        refund. <u>It is not considered to be a material change in the nature of the Event,</u>-->
        <!--                                        any change of (i) a part of the content of the Event, (ii) the members-->
        <!--                                        of the group/team performing the Event, and/or (iii) the line-up of any-->
        <!--                                        multi-artist Event (for example a festival).</li></ul> <p>The Arena-->
        <!--                                        Kuwait will refrain from issuing exchanges or refunds after a Ticket-->
        <!--                                        purchase has been made in the following instances: </p> <ul><li>Ticket is lost, stolen, damaged, or destroyed tickets</li><li>The ticket has been resold, waived, or transferred</li><li>The ticket has been changed, altered, or defaced</li></ul> <p>If-->
        <!--                                        a refund or exchange is to be issued, then it will be the Arena Kuwait-->
        <!--                                        management who will process the refund or exchange for the Ticket sold-->
        <!--                                        through authorized online channels, box offices, call center, or retail-->
        <!--                                        outlets. </p> <p>A full refund for Tickets purchased prior to the-->
        <!--                                        original date of the Event will be given up to the face value of this-->
        <!--                                        Ticket. Refund of any transaction amount against any purchase through-->
        <!--                                        this website would be refunded to the original buyer and with the same-->
        <!--                                        mode of payment. Delivery and order processing fees, if any, will not be-->
        <!--                                        refunded.</p> <p>In order to make a refund for the canceled or-->
        <!--                                        postponed Events, the physically purchased tickets should be physically-->
        <!--                                        delivered to the Arena Kuwait Box Office located at the 360 Mall.</p> <p><strong><u>C. Making A Claim For A Ticket </u></strong></p> <p>All claims for a Ticket refund must be made at the Arena Kuwait Box Office at the 360 Mall or by emailing:<br><a href="mailto:tak-tickets@thearenakuwait.com"></a><a href="mailto:tak-tickets@thearenakuwait.com">tak-tickets@thearenakuwait.com</a></p> <p>The claimer will be given/sent a form to complete. </p> <p>The-->
        <!--                                        claimer should fill in the form and send it to The Arena Kuwait as soon-->
        <!--                                        as possible with all the information and documents asked for. It is-->
        <!--                                        essential that the Claimer provide as much detail as possible to enable-->
        <!--                                        handling his/her claim quickly. It is recommended to keep photocopies of-->
        <!--                                        all information the claimer sends to The Arena Kuwait.</p> <p>Below is a list of the documents needed in order to deal with a claim:</p> <p>• Claimer’s full personal details </p> <p>• Ticket purchase details </p> <p>• Original unused Ticket</p> <p>• Ticket purchase receipt</p> <p>• As much evidence as possible to support the claim.</p> <p><strong><u>Making A Complaint</u></strong></p> <p>The-->
        <!--                                        Arena Kuwait aims to provide first-class policies and services.-->
        <!--                                        However, there may be times when you feel we have not done so. If this-->
        <!--                                        is the case, please tell us about it so that we can do our best to solve-->
        <!--                                        the problem by, among others, raising the matter to the organizer-->
        <!--                                        whenever it is required. If you make a complaint your legal rights will-->
        <!--                                        not be affected.</p> <p>In the first instance, please email: <a href="mailto:wecanhelp@thearenakuwait.com">wecanhelp@thearenakuwait.com</a></p>-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                                <mat-expansion-panel [expanded]="false">-->
        <!--                                    <mat-expansion-panel-header>-->
        <!--                                        <mat-panel-title class=" 18">-->
        <!--                                            Ticketing FAQs-->
        <!--                                        </mat-panel-title>-->
        <!--                                    </mat-expansion-panel-header>-->
        <!--                                    <div class="mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                        <p class="18">The Arena Kuwait has an exclusive ticketing solution called <strong>TAK TICKETS</strong> which sells tickets for all our events. Please visit our <a href="https://thearenakuwait.com/whats-on">“Whats On”</a> page for a list of all the events which are selling tickets.</p> <p class="18"><strong>How can I purchase tickets?<br></strong>The Arena Kuwait wishes to encourage everyone to purchase tickets online. Please visit the <a href="https://thearenakuwait.com/whats-on">“Whats On” </a>page,-->
        <!--                                        click the event you wish to purchase tickets for and then you should-->
        <!--                                        proceed to our TAK Tickets platform and continue by signing up to your-->
        <!--                                        dedicated account or you may continue your purchase as a guest.</p> <p class="18"><strong>How can I buy tickets for the dedicated Accessibility Seating Areas designed for visitors with disabilities?<br></strong>Customers-->
        <!--                                        who wish to buy tickets for the dedicated Accessibility Areas must call-->
        <!--                                        the dedicated ticketing telephone number +965 25 362 453.</p> <p class="18">Please note the following when booking online:</p> <ul><li>Wheelchair positions are for Wheelchair users</li><li>Proof-->
        <!--                                        of eligibility/disability must be provided before the event. Our-->
        <!--                                        ticketing team will connect with you in advance of the event.</li></ul> <p class="18"><strong>How can I see a seating plan?<br></strong>Please visit <a href="#"></a><a href="https://thearenakuwait.com/whats-on">“Whats On”</a>-->
        <!--                                        page on The Arena Kuwait’s website and select the event you would like-->
        <!--                                        to attend. Upon clicking the Buy tickets tab, you will be diverted to-->
        <!--                                        ‘TAK Ticketing’ where you will be able to view the selected event’s-->
        <!--                                        seating plan.</p> <p class="18"><strong>Why is there a limit to how many tickets I can buy?<br></strong>In-->
        <!--                                        short, we want to make sure everyone gets a fair chance to buy tickets.-->
        <!--                                        The ticket limit will be decided by the Promoter and TAK Tickets and-->
        <!--                                        they will make sure they advertise this at the beginning of the booking-->
        <!--                                        process on the event website.</p> <p class="18"><strong>Why is there a time limit for online purchases?<br></strong>We-->
        <!--                                        want to make sure that as many fans as possible get the chance to book.-->
        <!--                                        When you select your seats, you are actually holding those tickets-->
        <!--                                        while you go through the checkout process, so no one else can access-->
        <!--                                        them. The amount of time you can hold those seats/tickets should be-->
        <!--                                        sufficient to complete the application process before paying for your-->
        <!--                                        tickets.</p> <p class="18"><strong>Does my child need a ticket if they sit on my lap?<br></strong>Children-->
        <!--                                        aged two and under attending family shows and sitting on an adult’s lap-->
        <!--                                        do not need a ticket. Please check specific event details on the <a href="https://thearenakuwait.com/whats-on">“Whats on” </a>page before booking.</p> <p class="18">Due-->
        <!--                                        to the sound levels experienced during an event and the long-term-->
        <!--                                        hearing damage this could cause, we strongly recommend that children-->
        <!--                                        under five should not attend concerts or events other than those-->
        <!--                                        specifically aimed at younger visitors, i.e., children's shows. Please-->
        <!--                                        note Any interruption caused by young children/babies that directly-->
        <!--                                        affects other members of the audience may result in you being asked to-->
        <!--                                        leave the venue immediately.<br></p> <p class="18"><strong>Where is the Box Office/Ticketing Office?<br></strong>The-->
        <!--                                        Arena Kuwait box office is located on the M2 level of the new 360 Mall-->
        <!--                                        expansion. A second box office is located at the public drop-off area-->
        <!--                                        and is open on event days only.</p> <p class="18"><strong>What times does the Box Office open on event days?<br></strong>The-->
        <!--                                        box office is usually open on show days at approx. 12pm. On certain-->
        <!--                                        show days, the box office may be open earlier or later depending on the-->
        <!--                                        nature of the event. To confirm the opening times before arriving,-->
        <!--                                        please check out the event page for your event or call +965 25 362 453.-->
        <!--                                        Please note, the box office is closed on non-show days.</p> <p class="18"><strong>How do I collect my tickets from the Box Office?<br></strong>Check-->
        <!--                                        what time the box office opens for your event, and head down with your-->
        <!--                                        ticket booking confirmation and photo ID. Our box office team will take-->
        <!--                                        it from there.</p> <p class="18"><strong>Do I need to present my ID before entering my event?<br></strong>If-->
        <!--                                        there is a strict ID policy in place for your event, we will notify you-->
        <!--                                        at the time of booking and in subsequent event communications. However,-->
        <!--                                        we suggest you have your Civil ID card with you when attending an-->
        <!--                                        event.</p> <p class="18"><strong>Can you scan my ticket on my phone?<br></strong>We can scan tickets on smartphones.</p> <p class="18"><strong>I bought tickets as a gift and my name is on the ticket.<br></strong>We-->
        <!--                                        appreciate tickets are sometimes bought as gifts, after all, tickets-->
        <!--                                        are one of the best gifts you can give. The name on the tickets will not-->
        <!--                                        affect the use of the tickets unless stated in the terms and conditions-->
        <!--                                        at the time of booking.</p> <p class="18"><strong>Where is my seat?<br></strong>Your-->
        <!--                                        ticket will clearly state the block, row, and seat number for your-->
        <!--                                        personal seat. Please note: Seating varies for every event, and the-->
        <!--                                        seating layout can be subject to change before the event takes place.-->
        <!--                                        Seating plans can be viewed online while purchasing your tickets for the-->
        <!--                                        event. </p> <p class="18"><strong>My e-ticket will not print / I have lost my e-ticket, what do I do?<br></strong>Don’t-->
        <!--                                        worry, we’ve got you covered. Just call our TAK TICKETS customer-->
        <!--                                        service number at +965 25 362 453, and they will be able to help you.</p> <p class="18"><strong>I cannot attend my event anymore; how do I get a refund?</strong><br>In-->
        <!--                                        a majority of cases, tickets cannot be refunded or exchanged after-->
        <!--                                        purchase. If you purchased your tickets via our website, then you would-->
        <!--                                        be able to view ticket terms and conditions.</p> <p class="18"><strong>How are ticket prices set?<br></strong>Ticket prices are set by the Promoters and Organisers of the events hosted at The Arena Kuwait.</p>-->
        <!--                                    </div>-->
        <!--                                </mat-expansion-panel>-->
        <!--                            </mat-accordion>-->
        <!--                            <br>-->
        <!--                            <a class="13X2 btn btn-lg btn-default">check out our upcoming events..-->
        <!--                                <i class="glyphicon glyphicon-chevron-right mdl-color-text&#45;&#45;grey-700"></i>-->
        <!--                            </a>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-customized-section" [ngStyle]="{'background-color':'transparent'}" id="security">-->
        <!--                    <div class="android-customized-section-text">-->
        <!--                        <div class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;display-1-color-contrast textWht text-left">-->
        <!--                            Safety & Security-->
        <!--                        </div>-->
        <!--                        <h6 class="text-left textWht mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                            <div id="content_3211" class="content clearfix textWht mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <h5 class="mdl-typography&#45;&#45;text-lowercase 18" style="margin-right: 0cm; margin-left: 0cm;"><b>Venue Safety and Security Information</b></h5>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18" style="margin-right:0cm; margin-left:0cm">At Utilita Arena Newcastle we do lots to keep you safe with some security measures that you can see and some that you can’t.&nbsp;Please be assured that&nbsp;safety continues to be our priority at all times.</h6>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18" style="margin-right:0cm; margin-left:0cm">Our event and security teams continue to remain vigilant and are working together with local and national authorities to ensure our visitors have the safest and most enjoyable experience while&nbsp;visiting Utilita Arena Newcastle.</h6>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">We have a number of security measures in place visible and non-visible, with personnel internal and external for all events.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18" style="">When arriving at the arena there are security checks to pass through to gain access to the building with a valid ticket. We advise to give yourself extra time to pass through venue security checks, especially for comedy acts and sold out shows.&nbsp;</h6>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">We encourage you to only bring essential items and stress&nbsp;<strong>NO LARGE BAGS OR BACK PACKS. All bags must be smaller than 40cm x 35cm x 19cm which is approx A3 size.</strong></span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="mdl-typography&#45;&#45;text-lowercase 18">There will be no temporary storage facilities for such items within the venue and it will be left to the patron to find a safe temporary facility to hold the item. The venue will not be responsible for late admission or non-admission as a result of this policy.</h6>-->
        <!--                            </div>-->
        <!--                            <div id="content_3213" class="content clearfix textWht mdl-typography&#45;&#45;text-lowercase 18">-->
        <!--                                <h5 style=""><span style="font-weight:bold">Safety</span></h5>-->

        <!--                                <h6 class="textWht 18 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">We take the safety and security of all our fans and visitors very seriously. When coming to the Arena we ask you arrive early to allow time to pass through security checks.&nbsp;</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="textWht 18 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">Our security and customer services team are there for your safety.&nbsp; Please cooperate fully with all security checks and support these vital members of our team as they carry out important work.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="textWht 18 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">Utiltia Arena&nbsp;Newcastle is&nbsp;delighted to announce the introduction of walk through metal detectors to replace and improve the current manual scanning procedure.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="textWht 18 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">All entry constitutes consent to search.</span></span></span></span></span></span></span></span></h6>-->
        <!--                            </div>-->
        <!--                            <div id="content_3214" class="content clearfix textWht">-->
        <!--                                <h5 style=""><span style="font-weight:bold">Our Bag Policy</span></h5>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">We encourage you to only bring essential items and stress&nbsp;<strong>no large bags or backpacks</strong>&nbsp;at all will be permitted into venue.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px"><strong>NO LARGE BAGS OR BACK PACKS&nbsp;at all will be permitted into the venue. There is no storage or cloak room facility available so please make alternative arrangements before arriving to the venue.&nbsp;All bags must be smaller than 40cm x 35cm x 19cm which is approx A3 size.</strong></span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">If needed,&nbsp;Stasher have locations within a short walk to the arena where large bags and items can be stored -<a href="https://stasher.com/?ref=newcastleutilitaarena">&nbsp;Book online</a>&nbsp;- We do not have cloakroom or storage facilites at the arena. Prices start from £6 per item for 24 hour storeage.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">Exceptions will only be made for those with medical requirements and/or special needs, we ask this is communicated to venue ahead of visit for ease of entry. Please contact us on&nbsp;<a href="mailto:Newcastle.Accessibility@eu.asmglobal.com">Newcastle.</a><a href="mailto:Accessibility@smgnewcastle.co.uk">Accessibility</a><a href="mailto:UAcustomerservice@eu.asmglobal.com?subject=Customer%20Service">@</a><a href="mailto:Newcastle.Accessibility@eu.asmglobal.com">eu.asmglobal.com</a></span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">All entry constitutes consent to search.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px"><strong>Prohibited Items</strong></span></span></span></span></span></span></span></span></h6>-->

        <!--                                <ul class="textWht mdl-typography&#45;&#45;text-lowercase 18" [ngStyle]="{'margin-left':'-30px'}">-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Backpacks including holdalls, luggage, computer bags and cushions**</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Glass, cans, aluminium bottles or thermoses of any kind including any liquid products which can be consumed**</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Flammable liquids in any container</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Laser pens/pointers</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Video cameras, professional cameras and recording devices</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Laptop computers, Ipads and Go Pro’s</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Large/golf umbrellas</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Knives, firearms&nbsp;or weapons of any kind</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Illegal drugs or substances</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Alcoholic beverages</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Signs, banners or flags with or without&nbsp;poles, staffs or selfie sticks</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Animals (except service animals to aid persons with disabilities)</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Air horns, whistles, cowbells or other noise-making distractions</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Projectiles (Frisbees, beach balls, footballs etc)</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Aerosol cans (hairspray, mace pepper spray etc)</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Fireworks, confetti or glitter bombs or sprays</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Glow sticks</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Balloons</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Use of wheeled footwear, skateboards</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Food</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Helmets and full face masks excluding clear visors</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Large belt buckles, studs,&nbsp;chains and spiked collars, boots or jackets</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Glass bottles of perfume</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Anything else which we deem to ruin the enjoyment or viewing experience of others</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Any refreshments or refreshment containers</span></li>-->
        <!--                                    <li style="margin:0cm 0cm 0.0001pt"><span style="tab-stops:list 36.0pt">Cameras &amp; recording equipment including iPads and Tablets:&nbsp;the unauthorised use of cameras, video, iPads and Tablets and any other form of recording equipment is strictly prohibited. However, some of the shows do permit the use of small digital cameras including, in many cases, 'bridge' cameras (larger digital cameras with zoom lenses that cannot be removed). For the avoidance of any confusion 'professional' cameras with removable and interchangeable lenses are always prohibited. Unfortunately, the camera policy for a specific show is typically only confirmed on the day of the event. We would advise that if you choose to bring a small digital camera to the Arena you must be prepared not to use it if requested not to do so.</span></li>-->
        <!--                                </ul>-->

        <!--                                <h6 class="textWht 18 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">**Exceptions will be made for those with medical requirements and/or special needs, we ask this is communicated to venue ahead of visit for ease of entry.&nbsp;</span></span></span></span></span></span></span></span></h6>-->
        <!--                            </div>-->
        <!--                            <h6 id="content_3215" class="content clearfix textWht">-->
        <!--                                <h5 style=""><span style="font-weight:bold"><strong>MANAGING FOOD SAFETY &amp; ALLERGEN AWARENESS</strong></span></h5>-->

        <!--                                <h6 class="textWht 20 mdl-typography&#45;&#45;text-lowercase" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">Every aspect of food safety is of the highest importance to our organisation, and we take every care to ensure that you can eat safely whilst visiting our venues. In addition to strict hygiene procedures, we have embarked upon an extensive allergen awareness campaign. This includes high levels of staff training, and implementation of policies, procedures and processes, in line with the Food Information Regulations. We are continually reviewing and improving every aspect of our Food Safety Strategy, this includes ensuring up to date information on allergens is shared with our teams, reducing the possibility for allergen exposure.&nbsp;</span></span></span></span></span></span></span></span></h6>-->
        <!--                            </h6>-->
        <!--                            <div id="content_3216" class="textWht ">-->
        <!--                                <h5 class="18 mdl-typography&#45;&#45;text-lowercase textWht" style=""><span style="font-weight:bold">What Else Do We Do To Keep You Safe?</span></h5>-->

        <!--                                <h6 class="18 mdl-typography&#45;&#45;text-lowercase textWht" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">There are continuous security measures in place.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="18 mdl-typography&#45;&#45;text-lowercase textWht" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">We have detection dogs at our sites, if you see them say hello but ask you to remember they are working dogs.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="18 mdl-typography&#45;&#45;text-lowercase textWht" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">All our staff are trained to ensure all our guests have a safe, secure and happy time. We work very closely with the appropriate authorities including Northumbria Police to ensure our security procedures are always of best practice.</span></span></span></span></span></span></span></span></h6>-->

        <!--                                <h6 class="18 mdl-typography&#45;&#45;text-lowercase textWht" style=""><span style=""><span style=""><span style="orphans:2"><span style="widows:2"><span style="text-decoration-thickness:initial"><span style="text-decoration-style:initial"><span style="text-decoration-color:initial"><span style="word-spacing:0px">And please do not be alarmed if you see additional resource at the Arena with a very important job to do, please leave them to do what they do best.</span></span></span></span></span></span></span></span></h6>-->
        <!--                            </div>-->
        <!--                        </h6>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="android-more-section" id="buying.tickets">-->
        <!--                    <div class="android-section-title mdl-typography&#45;&#45;display-1-color-contrast">More from Android</div>-->
        <!--                    <div class="android-card-container mdl-grid">-->
        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="images/more-from-1.png">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Get going on Android</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                                <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">Four tips to make your switch to Android quick and easy</span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Make the switch-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="images/more-from-4.png">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Create your own Android character</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                                <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">Turn the little green Android mascot into you, your friends, anyone!</span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    androidify.com-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="images/more-from-2.png">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Get a clean customisable home screen</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                                <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">A clean, simple, customisable home screen that comes with the power of Google Now: Traffic alerts, weather and much more, just a swipe away.</span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Download now-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <div class="mdl-cell mdl-cell&#45;&#45;3-col mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-card mdl-shadow&#45;&#45;3dp">-->
        <!--                            <div class="mdl-card__media">-->
        <!--                                <img src="images/more-from-3.png">-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__title">-->
        <!--                                <h4 class="mdl-card__title-text">Millions to choose from</h4>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__supporting-text">-->
        <!--                                <span class="mdl-typography&#45;&#45;font-light mdl-typography&#45;&#45;subhead">Hail a taxi, find a recipe, run through a temple – Google Play has all the apps and games that let you make your Android device uniquely yours.</span>-->
        <!--                            </div>-->
        <!--                            <div class="mdl-card__actions">-->
        <!--                                <a class="android-link mdl-button mdl-js-button mdl-typography&#45;&#45;text-uppercase" href="">-->
        <!--                                    Find apps-->
        <!--                                    <i class="material-icons">chevron_right</i>-->
        <!--                                </a>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->

    </div>
</div>
