import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../app.service";
import {FormService} from "../../form.service";

@Component({
  selector: 'app-submit-controls',
  templateUrl: './submit-controls.component.html',
  styleUrls: ['./submit-controls.component.scss']
})
export class SubmitControlsComponent implements OnInit {
  @ViewChild('address')  submitButtonElement: any;
  constructor(public service: AppService,
              public formService: FormService) {
    this.submitButtonElement = ElementRef;
  }

  ngOnInit(): void {
  }

  disableButton(){
    this.submitButtonElement.nativeElement.disabled = !this.formService.submitButtonDisableStatus;
    return true;
  }

}
