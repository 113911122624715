<div class="page">
    <div class="page-title">
        <div class="title_left">
            <h3 layout="row">
                <ol class='breadcrumb'>
                    <li *ngFor="let module of service.modules"><a>
                        <span class="headline2" [ngStyle]="{'font-size':'15px'}">{{module.title}}</span></a>
                    </li>
                    <li class="hidden-md hidden-sm hidden-xs">
                        <span class="headline2" [ngStyle]="{'font-size':'12px'}" title="">
                            {{service.subHeader}}
                        </span>
                    </li>
                </ol>
            </h3>
        </div>
        <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search for..."
                           #searchTextId
                           (keyup)="onSearchTextChange(searchTextId.value)"
                           (keyup.enter)="enterKeyUp(searchTextId.value)"
                           [(ngModel)]="searchCntrl" />
                    <span class="input-group-btn">
                        <button class="btn btn-default"
                                type ="button"
                                (click)="handleSearch(searchTextId.value)"
                        >
                          Go!
                        </button>
                    </span>
                </div>
            </div>
            <div  class="pull-right btn-group">
        </div>
    </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
                <div class="x_title">
                    <!-- <h2>Button Example <small>Users</small></h2> -->
                    <h2 class="headline2">
                        {{app.settings.client.name|slice:0:10}}..
                        <small>
                            <ol class="breadcrumb pull-right">
<!--                                <li *ngFor="let module of modules()"><a class="headline2" routerLink="{{module.path}}">{{module.title}}</a></li>-->
                            <!--<li>-->
                                <!--<a href="#" ng-if="'datebetween' !== parms.report.time|lowercase">{{parms.report.time}} </a>-->
                                <!--<a href="#" ng-if="'datebetween' === parms.report.time|lowercase">{{parms.report.starting_at}} - {{parms.report.starting_at}}</a>-->
                            <!--</li>-->
                            </ol>
                            <a class='btn btn-xs' (click)="back()"><i class="fa fa-chevron-left"></i></a>
                            <a class='btn btn-xs' (click)="forward()"><i class="fa fa-chevron-right"></i></a>
                        </small>
                    </h2>
                    <app-toolbox [app]= 'app' (onOpenDialog)="openDialog()"></app-toolbox>
                    <div class="clearfix"></div>
                </div>
                <div class="x_content" relink-event="datatablex">
                    <p class="headline2">
                      Data collection is defined as the procedure of collecting, measuring and analyzing accurate insights for research using standard validated techniques. ... The approach of data collection is different for different fields of study, depending on the required information.
                    </p>
                    <p>
                        <app-tabs-teams          *ngIf="app.form.module.includes('teams')"></app-tabs-teams>
                        <app-tabs-clients        *ngIf="app.form.module.includes('clients')"></app-tabs-clients>
                        <app-tabs-forms          *ngIf="app.form.module.includes('forms')"></app-tabs-forms>
                        <app-tabs-campanies      *ngIf="app.form.module.includes('campanies')"></app-tabs-campanies>
                        <app-tabs-campaigns      *ngIf="app.form.module.includes('campaigns')"></app-tabs-campaigns>
                        <app-tabs-employees      *ngIf="app.form.module.includes('employees')"></app-tabs-employees>
                        <app-tabs-ambassadors    *ngIf="app.form.module.includes('ambassadors')"></app-tabs-ambassadors>
                        <app-tabs-responses-form *ngIf="app.form.module.includes('responses')"></app-tabs-responses-form>
                        <app-tabs-departmets     *ngIf="app.form.module.includes('departments')"></app-tabs-departmets>
                        <app-tabs-regional       *ngIf="app.form.module.includes('regions')"></app-tabs-regional>
                        <app-tab-invites         *ngIf="app.form.module.includes('invites')"></app-tab-invites>
                        <app-section-inventories *ngIf="app.form.module.includes('inventories')"></app-section-inventories>
                        <app-section-outlets     *ngIf="app.form.module.includes('outlets')"></app-section-outlets>
                        <app-sections-items      *ngIf="app.form.module.includes('items')"></app-sections-items>
                    </p>
                    <center>
                        <a  class=""
                            mat-mini-fab
                            (click) = 'requestMore()'
                            [ngStyle]="{'background': '#fff','color':'#000'}">
                            <i class="fa fa-chevron-down"></i>
                        </a>
                    </center>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngStyle]="{'height':iframeHeight() + 'px'}"></div>
<div id="form"> </div>