import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ambassador',
  templateUrl: './ambassador.component.html',
  styleUrls: ['./ambassador.component.scss']
})
export class AmbassadorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
