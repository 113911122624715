import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {AppService} from "../../app.service";
import {NotifierService} from "angular-notifier";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {FormService} from "../../forms/form.service";
import {CampaignService} from "../../campaigns/campaign.service";
import {Parameters} from "../../parameters";
import {map, startWith} from "rxjs/internal/operators";
import {Address} from "ngx-google-places-autocomplete/objects/address";

@Component({
  selector: 'app-dialog-email-invitations',
  templateUrl: './dialog-email-invitations.component.html',
  styleUrls: ['./dialog-email-invitations.component.scss']
})
export class DialogEmailInvitationsComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) trigger: any;
  @ViewChild(MatAutocompleteTrigger) roletAutocompleteTrigger: any;
  @ViewChild('userInput') userInput: any;
  questions: any;
  question: any;
  selectable = true;
  removable = true;
  label: any;
  userCtrl = new FormControl();
  allQuestions; any = [];
  filteredUsers: Observable<any>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  currentUsers: any = [];
  selectedOptional: any = {};
  removedQuestions: any;
  app: any;
  subscription: any;
  filteredOptionsOptions: any;
  parameters: any;
  optionControl = new FormControl();
  formValues: FormGroup = new FormGroup({});
  selectedSuggestionBox: any;
  outgoingRequestParams: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public formMetthods: FormService,
              public metthods: CampaignService,
              @Inject(MAT_DIALOG_DATA
              ) public data: any,
              public dialogRef: MatDialogRef<DialogEmailInvitationsComponent>) {
    this.selectedSuggestionBox = this.formMetthods.getSelected();
    this.question = this.data.question;
    this.questions = this.formMetthods.questions;
    this.userInput = ElementRef;
    this.app = this.service.app;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((question: string | null) => question ? this._filter(question) : this.allQuestions.slice()));
  }


  ngOnInit(): void {
    this.formValues = this.formBuilder.group({
      message: ['', Validators.compose([])],
    });
    this.allQuestions = this.questions.filter((question: any) => { return parseFloat(this.question.index) < parseFloat(question.index);});
    setTimeout(() => {
      this.filteredOptionsOptions = this.optionControl.valueChanges.pipe(
          startWith(),
          map(value => typeof value === 'string' ? value : value.label),
          map(name => name ? this._filterOption(name) : this.allQuestions.slice())
      );
    });
  }

  keys(){return Object.keys(this.selectedOptional).length;}

  add(event: any): void {
    const email = (event.value || '').trim();
    if(email.length === 0){}else{
      if(this.service.validateEmail(email) === true && this.currentUsers.findIndex((user: any) => { return user.email.toLowerCase().toString().includes(email.toLowerCase().toString());}) === -1) {
      this.currentUsers.push({email: email});
      this.userInput.nativeElement.value = '';
      this.userCtrl.setValue(null);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  handleQuestion(question: any){
    this.label = prompt('', question.label);
    if (this.label !== null) question = Object.assign(question, {label: this.label});
  }

  remove(question: any): void {
    const index = this.currentUsers.indexOf(question);
    if (index === -1){} else {
      this.currentUsers.splice(index, 1);
      if(question.hasOwnProperty('option_question_id')) {
        this.removedQuestions.push(question);
      }
    }
  }

  private _filter(value: any): any {
    const filterValue = value.toLowerCase();
    return this.allQuestions.filter(question => question.label.toLowerCase().includes(filterValue));
  }

  onoptionfocusMatAutocomplete(){
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let position = this.currentUsers.findIndex((question: any) => {return question.cntrl_num.toString() === event.option.value.cntrl_num.toString();});
    if(position === -1) {
      this.currentUsers.push(event.option.value);
      this.selectedOptional = Object.assign(this.selectedOptional, {questions: this.currentUsers});
    }
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  onrolefocusMatAutocomplete(){
    this.roletAutocompleteTrigger._onChange('');
    this.roletAutocompleteTrigger.openPanel();
  }

  private _filterOption(name: string) {
    const filterValue = name.toLowerCase();
    return this.allQuestions.filter((option: any) => option.label.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }



  onOptionSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.selectedOptional = event.option.value;
    this.currentUsers = event.option.value.hasOwnProperty('questions') ? event.option.value.questions : [];
  }

  displayFn(value: any){
    return value.label;
  }

  onSubmit(form: any) {
    if(this.currentUsers.length === 0){
      this.notifier.notify('success', 'no email attached'); } else {
      this.outgoingRequestParams = new Parameters();
      this.outgoingRequestParams.setAttributes(form);
      this.outgoingRequestParams.setSubject(this.selectedSuggestionBox.name + ' will like  your anonymous feedback.');
      this.outgoingRequestParams.setUrl(window.location.host + '/forms/' + this.selectedSuggestionBox.id + '/suggestions');
      this.outgoingRequestParams.setInvitees(JSON.stringify(this.currentUsers));
      this.service.httpService(
          'post', '/forms/' + this.selectedSuggestionBox.id + '/email/invitation', form, {}, (response: any) => {
            this.notifier.notify('success', 'successfull sent');
            this.closeDialog();
            }, (error: any) => {
          });
    }
  }


}
