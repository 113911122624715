import { Injectable } from '@angular/core';
import {Region} from './region';
import {AppService} from '../app.service';
import {Parameters} from '../parameters';

@Injectable({
  providedIn: 'root'
})
export class RegionsService implements Region{

  data: any = [];

  selected: any = {};

  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */

  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */


  setSelected(selected: any): void {
    this.selected = selected;
  }

  attributes: any;
  constructor(private service: AppService) {
    this.attributes = new Parameters();
    this.attributes.setAttributes(this.service.app.data);

  }

  requestRegionsByCountryId(countryId: any){
    this.attributes.setAttributes(this.service.app.data);
    if(this.attributes.attributes.hasOwnProperty('regions')){
      if(this.attributes.getRegions().data.length === 0 || this.attributes.getRegions().data.length > 0){
        this.service.httpService( 'post', this.service.app.routes.countries.regions.toString().replace(':id', 1), {}, {ignoreLoadingBar: 'true'},(result: any) => {
          this.attributes.setRegions(Object.assign({}, {data: result, trashed: {data: []}}));
        },  (error: any) => {
        });
      }
    }
  }
}
