import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {Options} from 'highcharts';
import {ChartService} from '../../../chart.service';

@Component({
  selector: 'app-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.scss']
})
export class SparklineComponent implements OnInit {
  @Input() data: any;
  @Input() legends: any;
  @Input() type: any;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = true;
  chartOptions: any;
  constructor(public chart: ChartService) {
  }

  ngOnInit(): void {
    this.chartOptions = this.chart.sparkline(this.type, this.data, this.legends);
  }

}
