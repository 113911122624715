<mat-checkbox ></mat-checkbox>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <!-- use a disabled button to provide padding for tree leaf -->
        <!--        <button mat-icon-button disabled></button>-->
        <!--        {{node.name}}-->
        <li  class="cursor">
            <div class="mat-tree-node" (click)="setCheckbox(node, checkbox, $event)">
                <mat-checkbox [checked]="false"
                              [id]="node.node[strings.getCntrlNum()]"
                              #checkbox></mat-checkbox>
                <span [ngStyle]="{'padding-left':'10px'}">{{node.name}}</span>
            </div>
        </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <!--        <button mat-icon-button matTreeNodeToggle-->
        <!--                [attr.aria-label]="'Toggle ' + node.name">-->
        <!--            <mat-icon class="mat-icon-rtl-mirror">-->
        <!--                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}-->
        <!--            </mat-icon>-->
        <!--        </button>-->
        <!--        {{node.name}}-->
        <li class="example-tree-container">
            <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle
                        [matTreeNodeToggleRecursive]="recursive"
                        (click)="onClick(node)"
                        [attr.aria-label]="'toggle ' + node.name">
                    <mat-icon>
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                {{node.name}}
            </div>
            <ul class="example-tree-nested-node">
                <div  *ngIf="treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </ul>
        </li>
    </mat-tree-node>
</mat-tree>
