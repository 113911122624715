<a class="pull-right"  [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<div class="">
    <center>
        <img  class="img-circle profile_img" src="assets/images/coat.png" alt="logo" style="margin-left: 0% !important;width: 40px; height: 40px; margin-top: 0px">
        <!--        <h2>Patten ! Get data you need at finguretips </h2>-->
    </center>
</div>
<section class="call-action-section">
    <div class="containerr">
        <div class="text-box" style="margin-left: 10px;margin-right: 10px">
            <h2 class="scolor">My Projects</h2>
            <p class="scolor">start creating a form for market research surveys, customer feedback and more</p>
        </div>
    </div>
</section>
<app-campaigns></app-campaigns>
