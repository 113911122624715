import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import PlaceResult = google.maps.places.PlaceResult;
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {RouteService} from "../route.service";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog-routes.component.html',
  styleUrls: ['./dialog-routes.component.scss']
})
export class DialogRoutesComponent implements OnInit {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  htmlContent: any;
  parameters: any;
  params: any;
  incomingRouteSelected: any;
  keys: any;
  addressValue: any;
  outgoingRequest: any;
  constructor(public  service: AppService,
              private notifier: NotifierService,
              private routeService: RouteService,
              public  broadcastChannel: BroadcastChannelService,
              public  formBuilder: FormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogRoutesComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.outgoingRequest = new Parameters();
    this.incomingRouteSelected = new Parameters();
    this.incomingRouteSelected.setAttributes(this.routeService.selected);
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => { });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:         ['', Validators.compose([])],
      stroke_color: ['', Validators.compose([])],
      description:  ['', Validators.compose([])],
    });

    if(Object.keys(this.incomingRouteSelected.getAttributes()).length === 0) this.routeService.setSelected({}); else{
      this.formValues.controls['name'].setValue(this.incomingRouteSelected.getName());
      this.formValues.controls['stroke_color'].setValue(this.incomingRouteSelected.getStrokeColor());
      for(var key in this.incomingRouteSelected.getAttributes()) {
        if(this.incomingRouteSelected.attributes[key] instanceof Object || this.incomingRouteSelected.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingRouteSelected.attributes[key]);
          }else {
            if(this.incomingRouteSelected.attributes[key] instanceof Object || this.incomingRouteSelected.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingRouteSelected.attributes[key], Validators.compose([])));
            }
          }
        }
      }
    }
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }


  onChangeRoleText(event: any){
    this.formValues.controls['role_id'].setValue('');
  }

  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }



  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){}



  closeDialog(){
    this.dialogRef.close(null);
  }


  displayFn(value: any){
    return value.display_name;
  }



  onSubmit(routeForm: any) {
    this.outgoingRequest.setAttributes(routeForm);
    this.service.httpService(
        'post', Object.keys(this.incomingRouteSelected.getAttributes()).length === 0 ?
            this.service.app.routes.routes.add :
            this.service.app.routes.routes.update,
        routeForm, {},
        (response: any) => {
          if(Object.keys(this.incomingRouteSelected.getAttributes()).length === 0) {
            this.service.app.data.routes.data.unshift(response);
          } else this.incomingRouteSelected.attributes = Object.assign(this.incomingRouteSelected.attributes, response);
          this.closeDialog();
          this.broadcastChannel.emitNavChangeEvent({route: response});
          this.notifier.notify('success', 'successfull ' + (Object.keys(this.incomingRouteSelected.getAttributes()).length === 0 ? 'added' : 'updated' ));
        }, (error: any) => {
        });

  }

}

