import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {AppService} from '../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../../form.service';
import {DomSanitizer} from '@angular/platform-browser';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatMenuTrigger} from '@angular/material/menu';
import {map, startWith} from 'rxjs/operators';
import * as $ from 'jquery';
import {Parameters} from '../../../../parameters';
import {StorageMap} from "@ngx-pwa/local-storage";
import {ProgressDialogComponent} from '../../../../main/progress-dialog/progress-dialog.component';

@Component({
  selector: 'app-chip-feedback-controls',
  templateUrl: './chip-feedback-controls.component.html',
  styleUrls: ['./chip-feedback-controls.component.scss']
})
export class ChipFeedbackControlsComponent implements AfterViewInit, OnChanges {
  @ViewChild('optionElement', {static: false}) optionElement: any;
  @ViewChild('auto') matAutocomplete: any;
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  @Input() status: any;
  contextMenu: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  questionControl = new FormControl();
  filteredOptions: Observable<any>;
  option: any;
  options: any = [];
  allOptions: any = [{label: 'fjfdf', code: 12, reference: 56}];
  label: any;
  controls: any;
  incomingResult: any;
  selectedOptional: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingQuestions: any;
  incomingQ: any;
  incomingOptions: any;
  incomingOpt: any;
  dialogRef: any;
  spinner: any = false;
  outgoingRequest: any;
  incomingFilteredQustion: any;
  positionItem: any = 0;
  serchText: any;
  incomingResults: any;
  incomingItem: any;
  incomingSettings: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              private storage: StorageMap,
              public formService: FormService,
              private sanitizer: DomSanitizer,
              public broadcastChannel: BroadcastChannelService) {
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingQuestions = new Parameters();
    this.incomingQ = new Parameters();
    this.incomingOptions = new Parameters();
    this.incomingOpt = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingFilteredQustion = new Parameters();
    this.incomingResults = new Parameters();
    this.matAutocomplete = MatAutocomplete;
    this.optionElement = ElementRef;
    this.contextMenu = MatMenuTrigger;
    this.controls = this.service.app.data.controls;
    this.filteredOptions = this.questionControl.valueChanges.pipe(
        startWith(),
        map((optional: string | null) => optional ? this._filter(optional) : (this.question.hasOwnProperty('reference') ? [] : this.allOptions.slice())));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.start(changes);
    this.ngStart(() => {
      this.optionElement.nativeElement.value = '';
    });
  }

  start(changes: any){
      this.incomingQuestion.setAttributes(Object.assign({},this.question));
      this.incomingQuestion.setData(this.incomingQuestion.attributes.hasOwnProperty('data') ? this.incomingQuestion.getData() : '');
      if(this.status !== undefined){
          this.incomingItem.setAttributes(this.incomingQuestion.getItem());
          if(this.incomingQuestion.attributes.hasOwnProperty('settings')){
            this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
            this.incomingQuestion.setOptions(this.incomingSettings.attributes.hasOwnProperty('options') ? this.incomingSettings.getOptions() : []);
          }
      }
    }

  ngStart(callback: any){
    setTimeout(() => {
      this.requestAllOptions((resp: any) => {
        this.options = [];
        this.incomingQuestion.setAttributes(this.question);
        this.allOptions = Array.prototype.concat([], this.incomingQuestion.getOptions());
        try {
          if(this.incomingQuestion.attributes.hasOwnProperty('reference')) {
            if(!this.service.isNull(this.incomingQuestion.getReference())){
              this.incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getReference()));
              if(Object.keys(this.incomingQ.getAttributes()).length !== 0){
                let options: any = this.incomingQ.getOptions().filter((option: any) => {
                  this.incomingOption.setAttributes(option);
                  return this.incomingOption.getLabel().toString() === this.incomingQ.getData();
                });
                if(options.length > 0){
                  this.incomingOpt.setAttributes(options[0]);
                  this.allOptions = this.allOptions.filter((option: any) => {
                    this.incomingOption.setAttributes(option);
                    return !isNaN(this.incomingOpt.getCode()) && !isNaN(this.incomingOption.getReference()) ?
                        parseFloat(this.incomingOpt.getCode()) === parseFloat(this.incomingOption.getReference()) :
                        this.incomingOpt.getCode().toString().trim() === this.incomingOption.getReference().toString().trim();
                  });
                }else this.allOptions =  Array.prototype.concat([], []);
              }
            }
          }
        }catch (e) {}

        // this.filteredOptions = this.questionControl.valueChanges.pipe(
        //     startWith(''),
        //     map(value => typeof value === 'string' ? value : value.label),
        //     map(option  => option ? this._filter(option) : (
        //         this.incomingQuestion.attributes.hasOwnProperty('reference') &&
        //         !this.service.empty(this.incomingQuestion.getReference()) ? [] : this.allOptions.slice(0, 20)))
        // );

        this.filteredOptions = this.questionControl.valueChanges.pipe(
            startWith(''),
            map((optional: string | null) => optional ? this._filter(optional) : (
                this.incomingQuestion.attributes.hasOwnProperty('reference') &&
                !this.service.isNull(this.incomingQuestion.getReference()) ? [] : this.allOptions.slice(0, 20))));

        if(!this.service.isNull(this.incomingQuestion.attributes.data)){
          this.incomingQuestion.attributes.data.toString().split(this.service.separator).map((opt: any) => {
            let position: any = this.service.findIndex(this.allOptions, 'label', opt);
            if(position !== -1){
              this.incomingOption.setAttributes(this.allOptions[position]);
              this.options.push(this.incomingOption.getAttributes());
            }
          });
        }
        // this.dialogRef.close(null);
        callback({});
      });
    });
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    if(this.status === undefined){
        setTimeout(()=> {
          if(this.optionElement.nativeElement){
            this.optionElement.nativeElement.focus();
          }
        });
    }
  }

  openprogressDialog(){
    this.dialogRef =  this.dialog.open(ProgressDialogComponent, {
      data  : {},
      width : '100%',
      disableClose: true,
    });
  }

  requestAllOptions(callback: any){
    setTimeout((params: any) => {
      this.incomingQuestion.setAttributes(params.q);
      if(parseFloat(this.incomingQuestion.getItems()) === 2){
        callback({});
        // this.openprogressDialog();
        // this.service.httpService('post', '/questions/' + this.incomingQuestion.getId() + '/options/allrequest', {}, {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
        //   this.incomingQuestion.setOptions(response);
        //   this.incomingQuestion.setItems(0);
        //   callback({});}, (error: any) =>  {
        //   callback({});
        // });
      }else callback({});
    }, 0, {q: this.question});
  }


  handleOption(option: any){
    setTimeout(() => {
      this.incomingOption.setAttributes(option);
      if(this.incomingOption.attributes.hasOwnProperty('questionCntrlNum')){
        this.label = prompt('', this.incomingOption.getLabel());
        if (this.label !== null) {
          option = Object.assign(option, {label: this.label});
          this.set();
          this.formService.newOptional = {};
          this.formService.newOptional = Object.assign(this.formService.newOptional, this.incomingOption.getAttributes());
          this.formService.updateOther(this.label);
        }
      }
    });
  }

  addOptionalEvent(){
    var text: any = prompt('', 'Write optional title');
    if (!this.service.isNull(text)) this.formService.addOptional(this.question.options, text);
  }

  add(event: any): void {
    this.incomingQuestion.setAttributes(this.question);
    if(parseFloat(this.incomingQuestion.getAddOthers()) === 0){
      this.optionElement.nativeElement.value = ''; return;
    }
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    if (value) {
      this.incomingOption  = new Parameters();
      this.incomingOption.setAttributes({});
      this.incomingOption.setLabel(value);
      this.incomingOption.setUnknown('cntrl_num', this.service.random());
      this.incomingOption.setUnknown('questionCntrlNum', this.incomingQuestion.getCntrlNum());
      this.incomingOption.setCode('');
      this.incomingOption.setQuestions([]);
      this.formService.newOptional = {};
      this.formService.newOptional = Object.assign(this.formService.newOptional, this.incomingOption.getAttributes());
      this.options.push(this.incomingOption.getAttributes());
      this.allOptions.push(this.incomingOption.getAttributes());
      this.incomingQuestion.getOptions().push(this.incomingOption.getAttributes());
      this.formService.addOther();
      this.set();
    }

    // Clear the input value
    // event.chipInput!.clear();
    this.optionElement.nativeElement.value = '';
    this.questionControl.setValue(null);
  }


  onChangeOptionalText(event: any){
    if(this.status === undefined){
     setTimeout(() => {
         if(!this.service.isNull(event.target.value)){
            this.incomingQuestion.setAttributes(this.question);
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setQuestionId(this.incomingQuestion.getId());
            this.outgoingRequest.setSearchText(event.target.value);
            if(this.incomingQuestion.attributes.hasOwnProperty('reference')) {
              if(!this.service.isNull(this.incomingQuestion.getReference())){
                this.incomingFilteredQustion.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getReference()));
                if(Object.keys(this.incomingFilteredQustion.getAttributes()).length !== 0) {
                  if(!this.service.isNull(this.incomingFilteredQustion.getCode())){
                    this.outgoingRequest.setReference(this.incomingFilteredQustion.getCode());
                  }
                }
              }
            }
            this.spinner = true;
            this.service.httpService('post', '/options/searchrequest',
                this.outgoingRequest.getAttributes(), {
                  ignoreLoadingBar: 'true', notify: false}, (response: any) => {
                  this.incomingQuestion.setOptions(response);
                  this.incomingQuestion.setItems(0);
                  this.spinner = false;
                  let options: any = Array.prototype.concat([], this.options);
                  this.serchText  = event.target.value;
                  this.ngStart(() => {
                    this.options  = Array.prototype.concat([], options);
                    options.map((option: any) => {
                      this.incomingOption.setAttributes(option);
                      this.positionItem = this.service.findIndex(
                          this.incomingQuestion.getOptions(), 'cntrl_num',
                          this.incomingOption.getCntrlNum());
                      if(this.positionItem === -1)this.incomingQuestion.getOptions().unshift(this.incomingOption.getAttributes());
                    });
                  });
                }, (error: any) =>  {
                  this.spinner = false;
                });
          }
        }, this.spinnerTimeout());
    }
  }
  spinnerTimeout(){return 112;}
  filter(){
    this.filteredOptions = this.questionControl.valueChanges.pipe(
        startWith(),
        map((optional: string | null) => optional ? this._filter(optional) : (this.question.hasOwnProperty('reference') ? [] : this.allOptions.slice())));
  }

  remove(option: any): void {
    setTimeout(() => {
      this.incomingOption.setAttributes(option);
      const index = this.options.indexOf(this.incomingOption.getAttributes());
      if (index !== -1){
        this.options.splice(index, 1);
        this.set();
        this.formService.newOptional = this.incomingOption.getAttributes();
      //this.formServce.removeOther();
      }
    });
  }

  set(){
    this.incomingQuestion.setData('');
    this.incomingResults.setAttributes({});
    this.options.map((option: any) => {
      this.incomingOption.setAttributes(option);
      this.incomingResult.setAttributes({});
      this.incomingResult.setLabel(this.incomingOption.getLabel());
      this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
      this.incomingResults.setUnknown(this.incomingOption.getCntrlNum(), this.incomingResult.getAttributes());
      this.incomingQuestion.setData(this.service.isNull(this.incomingQuestion.getData()) ?
        this.incomingOption.getLabel() :
        this.incomingQuestion.getData() + this.service.separator + this.incomingOption.getLabel());
    });
    this.incomingQuestion.setResult(this.incomingResults.getAttributes());
    if(this.status !== undefined){
      this.incomingItem.setAttributes(this.incomingQuestion.getItem());
      this.incomingItem.setResult(this.incomingResults.getAttributes());
      this.incomingItem.setData(this.incomingQuestion.getData());
      this.incomingQuestion.setResult(this.incomingResults.getAttributes());
    }
    this.ngStart(() => {});
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(event.option.value);
    let position: any = this.service.findIndex(this.options, 'cntrl_num', this.incomingOption.getCntrlNum());
    if(position === -1) this.options.push(this.incomingOption.getAttributes());
    this.optionElement.nativeElement.value = '';
    this.questionControl.setValue(null);
    this.set();
  }

  private _filter(value: any): any {
    const args = value.toString().toLowerCase();
    try {this.incomingQuestion.setAttributes(this.question);
      if(this.incomingQuestion.attributes.hasOwnProperty('reference')) {
        if(!this.service.isNull(this.incomingQuestion.getReference())){
          this.incomingQ.setAttributes(this.formService.getQuestionByCntrlNum(this.incomingQuestion.getReference()));
          if(Object.keys(this.incomingQ.getAttributes()).length !== 0){
            let options: any = this.incomingQ.getOptions().filter((option: any) => {
              this.incomingOption.setAttributes(option); return this.incomingOption.getLabel().toString() === this.incomingQ.getData();});
              if(options.length > 0){
                this.incomingOpt.setAttributes(options[0]);
                return this.allOptions.filter((option: any) => {
                  this.incomingOption.setAttributes(option);
                  return this.incomingOpt.getCode().toString().trim() === this.incomingOption.getReference().toString().trim() &&  JSON.stringify(option).toString().toLowerCase().includes(args);
                }).slice(0, 20);
              }
          }
        }
      }
    }catch (e) {}
    return this.allOptions.filter((option: any) => JSON.stringify(option).toString().toLowerCase().includes(args)).slice(0, 20);
  }

}
