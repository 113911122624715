import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RoutesInviteeComponent} from './routes/routes-invitee.component';
import {Parameters} from "../parameters";
import {AppService} from "../app.service";

@Injectable({
  providedIn: 'root'
})
export class InviteeService {
  strings: any;
  constructor(private dialog: MatDialog, public service: AppService) {
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
  }

  routes(){
    this.dialog.open(RoutesInviteeComponent, {data  : {}, width : '100%', height: '100%', });
  }

  search(outgoingRequest: Parameters, call: any) {
    this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
    this.service.httpService(
        'POST',
        this.service.app.routes.invitees.search,
        outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
        (response: any) => {
          call(response);
        }, (error: any) => {call(null);});
  }
}
