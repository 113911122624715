import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {ClientService} from '../../clients/client.service';
import {FormService} from '../form.service';
import {MatDialog} from '@angular/material/dialog';
import {AddQuestionComponent} from '../questions/add/add-question.component';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {orderBy } from 'lodash';
import {NotifierService} from 'angular-notifier';
@Component({
  selector: 'app-build-form',
  templateUrl: './build-form.component.html',
  styleUrls: ['./build-form.component.scss']
})
export class BuildFormComponent implements OnInit, OnDestroy {
  formValues: any = {};
  start: any = true;
  end: any = false;
  position: any = 0;
  panel: any = null;
  question: any = {control: {id: -1}};
  subscription: any;
  label: any;
  options: any = [];
  mainOptionals: any = [];
  constructor(public formService: FormService,
              private clientService: ClientService,
              private notifierService: NotifierService,
              private broadcastChannelService: BroadcastChannelService,
              public dialog: MatDialog,
              private service: AppService,
              private campaignService: CampaignService) {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#ebebeb';
  }

  ngOnInit(): void {
    this.formValues = Object.assign(this.formValues, {
      name: '',
      cntrl_num  : this.service.random(),
      campaign_id: this.campaignService.campaignSelectedId,
      description: '',
      category_id: 5,
      source     : '',
      controls   : [],
    });
    this.formService.selected  = this.formValues;
    this.formService.questions = this.formValues.controls;
    this.formService.start = true;
    this.main(() =>{});
    this.service.playSound();
    this.subscription =  this.broadcastChannelService.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if (item.hasOwnProperty('questional') === true) {
              if(item.questional.toString().includes('add')){
                this.moveNextQuestion();
              }else if(item.questional.toString().includes('delete')){
                if(this.formService.questions.length === 0){
                  this.position = -1;
                }
              }
            }else if (item.hasOwnProperty('action') === true) {
              if(item.action.toString().includes('next')){
                let opt: any = this.question.options.filter((optional: any) => {return parseFloat(optional.cntrl_num) === parseFloat(item.cntrlNum); })[0];
                let options = opt.questions.map((questional: any) => {
                  let quizze: any = this.formService.questions.filter((q: any) => {return q.cntrl_num.toString().includes(questional.cntrl_num.toString()); })[0];
                  quizze = Object.assign(quizze, {visible: 1});
                  return quizze;
                });
                if(options.length === 0) {
                  //this.next();
                } else{
                  this.options = options;
                  this.panel = this.question.cntrl_num;
                  this.question = this.options[0];
                  this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
                }
              }
            }
          }
        });
  }

  addOptionalQuestion() {
    this.getQuestionalSelcectedOptional(this.question, (rs: any) => {
      rs.selected.questions.push(this.formService.newQuestion);
      this.panel = this.question.cntrl_num;
      this.options = rs.selected.questions.map((questional: any) => {return questional;});
      this.options = orderBy(this.options, 'index', 'asc');
      this.question = this.formService.newQuestion;
      this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
      this.service.playSound();
      this.x();
    });
  }

  getQuestionalSelcectedOptional(questional, callback: any){
    questional.options.map((option: any) => {
       if(option.label.toString() === questional.data.toString()){
        callback({selected: option});
      }
    });
  }

  moveNextQuestion(){
    setTimeout(() => {
        this.formService.start = false;
        this.formService.end   = false;
        if(this.formService.isOptionalClicked){
          this.addOptionalQuestion();
        } else {
          this.options.push(this.formService.newQuestion);
          this.options = orderBy(this.options, 'index', 'asc');
          this.question = this.formService.getQuestionByCntrlNum(this.formService.newQuestion.cntrl_num);
          this.position = this.service.findIndex(this.options, 'cntrl_num', this.formService.newQuestion.cntrl_num);
          this.service.playSound();
          if(this.panel === null){} else{
            this.getQuestionalSelcectedOptional(this.formService.getQuestionByCntrlNum(this.panel), (rs: any) => {
              rs.selected.questions.push(this.formService.newQuestion);
              this.x();
            });
          }
        }
    });
  }

  x(){
    setTimeout(() => {
      this.mainOptionals  = this.formService.questions.map((questional: any) => {return questional; });
      this.formService.questions.map((question: any) => {
        this.formService.getQuestionalParent(question, (rs) =>{
          const position: any = this.service.findIndex(this.mainOptionals, 'cntrl_num', rs.child);
          this.mainOptionals.splice(position, 1);
        });
      });
    });
  }


  main(callback){
    setTimeout(() => {
      this.options = this.formService.questions.filter((question: any) => { return question.visible === 1; });
      this.formService.questions.map((questional: any) => {
        this.formService.offQuestionOptionsWithoutClearData(questional, (rs) => {
          this.options = this.options.filter((quizze: any) => {
            return !quizze.cntrl_num.toString().includes(rs.item);
          });
        });
      });
    });
  }

  timeout(){
  //tslint:disable-next-line:radix
    return parseInt('2020');
  }

  setQuestionalTitle(){
// this.label = prompt('', this.service.empty(this.question.label) ? 'Untitled Question' : this.question.label);
// if (this.label !== null) this.question = Object.assign(this.question, {label: this.label});
  }

  getHeight(){return window.innerHeight; }

  next(){
    this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
    if(this.options[this.position + 1]){
      this.position += 1;
      this.question = this.options[this.position];
      this.service.playSound();
    }else{
      if(this.panel === null){
        if(this.mainOptionals.length === 0){
          this.formService.start = false;
          this.formService.end = true;
        }
      }else{
        if(this.options.length === 0) {
          this.options = this.mainOptionals.map((questional: any) => { return questional;});
          this.options = orderBy(this.options, 'index', 'asc');
          this.question = this.options.reverse()[0];
          this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
          this.panel = null;
        //this.next();
        }else {
          this.options = [];
          this.question = this.formService.questions.filter((questional: any) => { return questional.cntrl_num.toString().includes(this.panel);
          })[0];
          this.service.playSound();
          this.formService.getQuestionalParent(this.question, (item: any) => {
            let question: any = this.formService.questions.filter((questional: any) => { return questional.cntrl_num.toString().includes(item.main.toString());
            })[0];
            this.panel = question.cntrl_num;
            question.options.map((optional: any) => {
              if (optional.label.toString() === question.data.toString()) {
                this.options = optional.questions.map((questional: any) => {
                  let quizze: any = this.formService.questions.filter((q: any) => { return q.cntrl_num.toString().includes(questional.cntrl_num.toString());
                  })[0];
                  quizze = Object.assign(quizze, {visible: 1});
                  return quizze;
                });
                this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
                if (this.question.control.id !== 18) this.next();
              }
            });
          });
        }
      }
    }
  }



  addQuestion(){
    this.formService.isOptionalClicked = false;
    setTimeout(() => {
      const dialogRef = this.dialog.open(AddQuestionComponent, {
        disableClose: false,
        data: {position: this.formService.questions.length === 0 ? -1 : this.service.findIndex(this.formService.questions, 'cntrl_num', this.question.cntrl_num)},
        width: '70%',
        height: '100%',
        autoFocus: true,
      });
      dialogRef.afterClosed().subscribe(() => {
      });
    });
  }

  getQuestion(){return this.question;}

  rollback(){
    this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
    if(this.options[this.position - 1]){
      this.position -= 1;
      this.question = this.options[this.position];
      this.service.playSound();
    }else{
      if(this.panel === null){
        if(this.mainOptionals.length === 0){
          this.formService.start = true;
          this.formService.end = false;
        }
      } else{
        if(this.options.length === 0) {
          this.options = this.mainOptionals.map((questional: any) => { return questional;});
          this.options = orderBy(this.options, 'index', 'asc');
          this.question = this.mainOptionals[0];
          this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
          this.panel = null;
          // this.rollback();
        }else {
          this.options = [];
          this.question = this.formService.questions.filter((questional: any) => { return questional.cntrl_num.toString().includes(this.panel);
          })[0];
          this.service.playSound();
          this.formService.getQuestionalParent(this.question, (item: any) => {
            let question: any = this.formService.questions.filter((questional: any) => {  return questional.cntrl_num.toString().includes(item.main.toString());
            })[0];
            this.panel = question.cntrl_num;
            question.options.map((optional: any) => {
              if (optional.label.toString() === question.data.toString()) {
                this.options = optional.questions.map((questional: any) => {
                  let quizze: any = this.formService.questions.filter((q: any) => { return q.cntrl_num.toString().includes(questional.cntrl_num.toString());
                  })[0];
                  quizze = Object.assign(quizze, {visible: 1});
                  return quizze;
                });
                this.position = this.service.findIndex(this.options, 'cntrl_num', this.question.cntrl_num);
                if (this.question.control.id !== 18) this.rollback();
              }
            });
          });
        }
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
