import { Component, OnInit } from '@angular/core';
import {RegionsService} from '../regions.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-tabs-regional',
  templateUrl: './tabs-regional.component.html',
  styleUrls: ['./tabs-regional.component.scss']
})
export class TabsRegionalComponent implements OnInit {

  regionals: any;
  searchCntrl: any;
  constructor(public regionalImpl: RegionsService,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService) {
    this.regionals = this.service.app.data.regions;
  }

  ngOnInit(): void {
    this.broadcastChannel.getNavChangeEmitter()
      .subscribe((item: any) => this.handleSearchCntrl(item));
  }

  handleSearchCntrl(item: any){
      this.searchCntrl = item.searchText;
  }

}
