import {Component, Input, OnInit,OnChanges,SimpleChanges} from '@angular/core';
import {AppService} from '../../../app.service';
import {Parameters} from "../../../parameters";

@Component({
  selector: 'app-rate-preview-controls',
  templateUrl: './rate-preview-controls.component.html',
  styleUrls: ['./rate-preview-controls.component.scss']
})
export class RatePreviewControlsComponent implements OnInit, OnChanges {

  @Input() question: any;
  @Input() position: any;
  data: any;
  iterator: any = [];
  incomingQuestion: any;
  constructor(private service: AppService) {
    this.incomingQuestion = new Parameters();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.onload();
  }

  onload(){
    this.iterator = [];
    this.incomingQuestion.setAttributes(Object.assign({}, this.question));
    if(this.service.isNull(this.incomingQuestion.getData()) === true){
      this.incomingQuestion.setData(parseFloat('0'));
      for (let l = 0; l < parseFloat('5'); l++)
        this.iterator.push({checked: 0});
    }else {
      for (let l = 0; l < (parseFloat('5') - parseFloat(this.incomingQuestion.getData())); l++)
         this.iterator.push({checked: 0});
         for (let i = 0; i < parseFloat(this.incomingQuestion.getData()); i++) {
            this.iterator.push({checked: parseFloat('1')});
          }
    }
  }

  asnull(){
    if(this.service.isNull(this.question.data)){
      this.onload();
    }
  }


  handleStar(l: any){
    if(this.service.isNull(this.question.data) === true || parseFloat(this.question.data) === 0){
    this.question.data = parseFloat('0');
    this.iterator.map((l: any) => {l.checked = 0;}); }
    l.checked = l.checked === parseFloat('0') ? parseFloat('1') : parseFloat('0');
    this.question.data = l.checked === 0 ? (parseFloat(this.question.data) - 1) : (parseFloat(this.question.data) + 1);
  }

}
