import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-user-activity-chartjs',
  templateUrl: './user-activity-chartjs.component.html',
  styleUrls: ['./user-activity-chartjs.component.scss']
})
export class UserActivityChartjsComponent implements OnInit {

  @Input() element: any;
  @Input() color1: any;
  @Input() color2: any;
  @Input() labels: any;
  @Input() d1: any;
  @Input() d2: any;
  @Input() label: any;
  @Input() label2: any;
  @Input() fill1: any;
  @Input() fill2: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() type1: any;
  @Input() type2: any;
  @Input() xGridlines: any;
  @Input() xAxis: any;
  transparentColor1: any;
  transparentColor2: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
  }

  ngOnInit(): void {
  }

  getColor1(){
    this.color1 = this.color1.toString().includes('red')   ? '#ec250d' : this.color1;
    this.color1 = this.color1.toString().includes('blue')  ? '#1f8ef1' : this.color1;
    this.color1 = this.color1.toString().includes('green') ? '#00d6b4' : this.color1;
    this.color1 = this.color1.toString().includes('yellow')? 'rgb(255, 199, 15)' : this.color1;
    return this.color1;
  }
  
  getColor2(){
      this.color2 = this.color2.toString().includes('red')   ? '#ec250d' : this.color2;
      this.color2 = this.color2.toString().includes('blue')  ? '#1f8ef1' : this.color2;
      this.color2 = this.color2.toString().includes('green') ? '#00d6b4' : this.color2;
      this.color2 = this.color2.toString().includes('yellow')? 'rgb(255, 199, 15)' : this.color2;
      return this.color2;
  }

  getColor12(){
    return 'rgb(255, 199, 15)';
  }
  

  getTransparentColor1(){
    this.transparentColor1 = this.color1.toString().includes('red')    ? 'rgba(233,32,16,0.2)' : this.transparentColor1;
    this.transparentColor1 = this.color1.toString().includes('blue')   ? 'rgba(29,140,248,0.2)' : this.transparentColor1;
    this.transparentColor1 = this.color1.toString().includes('green')  ? '#96CA59' : this.transparentColor1;
    this.transparentColor1 = this.color1.toString().includes('yellow')  ? 'rgba(255,199,15,0.26)' : this.transparentColor1;
    return this.transparentColor1;
  }
  
  getTransparentColor2(){
      this.transparentColor2 = this.color2.toString().includes('red')    ? 'rgba(233,32,16,0.2)' : this.transparentColor2;
      this.transparentColor2 = this.color2.toString().includes('blue')   ? 'rgba(29,140,248,0.2)' : this.transparentColor2;
      this.transparentColor2 = this.color2.toString().includes('green')  ? '#96CA59' : this.transparentColor2;
      this.transparentColor2 = this.color2.toString().includes('yellow') ? 'rgba(255,199,15,0.26)' : this.transparentColor2;
      return this.transparentColor2;
  }

  getTransparentColor12(){
    return 'rgba(255,199,15,0.26)';
  }


  getLabels(){
    this.labels = this.labels.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString(), 3).replace('..', '') :
          this.service.limitTo(label.toString(), this.limit)); });
    return this.labels;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke1: any = this.ctx.createLinearGradient(0, 230, 0, 50);
      const gradientStroke2: any = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, this.getTransparentColor1());
      gradientStroke1.addColorStop(0.4, this.getTransparentColor1());
      gradientStroke1.addColorStop(0, this.getTransparentColor1()); //blue color1s

      gradientStroke2.addColorStop(1, this.getTransparentColor2());
      gradientStroke2.addColorStop(0.4, this.getTransparentColor2());
      gradientStroke2.addColorStop(0, this.getTransparentColor2()); //blue color1s

      const opt: any = {
        type: this.type,
        responsive: true,
        legend: {
          display: false,
          show: false
        },
        data: {
          labels: this.getLabels(),
          datasets: [
            {
              label: this.label,
              fill: this.fill1 === undefined ? false : this.fill1,
              backgroundColor: gradientStroke1,
              hoverBackgroundColor: gradientStroke1,
              type: this.type1 === undefined ? 'line' : this.type1,
              borderColor: this.getColor1(),
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: this.d1,
              lineTension: 0,
            },
            {
              label: this.label2,
              fill: this.fill2 === undefined ? false : this.fill2,
              backgroundColor: gradientStroke2,
              hoverBackgroundColor: gradientStroke2,
              type: this.type2 === undefined ? 'line' : this.type2,
              borderColor: this.getColor2(),
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: this.d2,
              lineTension: 0,
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },

          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: 'nearest',
            intersect: 0,
            position: 'nearest'
          },
          responsive: true,
          scales: {
            yAxes: [{
              display: false,
              gridLines: {
                drawBorder: true,
                color1: this.getTransparentColor1(),
                zeroLineColor: 'transparent',
              },
              ticks: {
//                 stepSize: 1,
//                 suggestedMin: 0,
                // suggestedMax: 60,
                padding: 20,
                fontColor: '#9e9e9e'
              }
            }],

            xAxes: [{
              display: this.xAxis === undefined,
              gridLines: {
                display: this.xGridlines === undefined,
                drawBorder: false,
                color1: this.getTransparentColor1(),
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#5f6368',
                fontFamily: 'Google Sans',
                fontSize: 10,
              }
            }]
          }
        }
      };

      if(!this.type1 === undefined) opt.data.datasets[0].type = this.type1;
      if(!this.type2 === undefined) opt.data.datasets[1].type = this.type2;
      const myChart: any = new Chart(this.ctx, opt);
    });
  }

  ngAfterContentInit(): void {
  }
}
