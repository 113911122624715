import { Injectable } from '@angular/core';
import { Options } from 'highcharts';
@Injectable({
    providedIn: 'root'
})
export class ChartService {
    serie: any;
    echartBarLine: any;
    constructor() { }

    morrisJs(labelss: Array<string>, yKeys: Array<string>, xKey: string){
        var options = {
            xkey: xKey,
            barColors: ['#4c84ff', '#29cc97', '#8061ef', '#fec402', '#26B99A', '#34495E', '#ACADAC', '#3498DB'],
            ykeys: yKeys,
            labels: labelss,
            hideHover: 'auto',
            xLabelAngle: 60,
            labelTop: true,
            resize: true
        };
        return options;
    }

    getSerie(){
        return this.serie;
    }


    setSerie(type: string, data: Array<number>){
        this.serie = {
            name: '',
            type: type,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#26B99A',
                    label: {
                        show: true,
                        position: 'top',
                        formatter :  (params: any) => {
                            return '' +
                                params.value
                                // data.params.y3[parseFloat(params.dataIndex)] + "\n" +
                                // data.params.y5[parseFloat(params.dataIndex)] + ' %'
                                ;
                        },
                        textStyle: {
                            // fontWeight: 'bold',
                        },

                    },
                    areaStyle: {
//                         type: 'default'
                    }
                }
            },
            data: data
        };
    }



    echartPie(legend: Array<string>, values: any){
        var options = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                x: 'center',
                y: 'bottom',
                show: false,
                data: legend,
            },
            // toolbox: {
            //     show: true,
            //     feature: {
            //         magicType: {
            //             show: true,
            //             type: ['pie', 'funnel'],
            //             question: {
            //                 funnel: {
            //                     x: '25%',
            //                     width: '50%',
            //                     funnelAlign: 'left',
            //                     max: 1548
            //                 }
            //             }
            //         },
            //         restore: {
            //             show: true,
            //             title: "Restore"
            //         },
            //         saveAsImage: {
            //             show: true,
            //             title: "Save Image"
            //         }
            //     }
            // },
            calculable: true,
            series: [{
                name: '',
                type: 'pie',
                radius: '55%',
                center: ['50%', '48%'],
                itemStyle: {
                    normal: {
                        label: {
                            formatter :  '{d}% : {b}'
                        }
                    }
                },
                data: values
            }]


        };

        var dataStyle = {
            normal: {
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                }
            }
        };

        var placeHolderStyle = {
            normal: {
                color: 'rgba(0,0,0,0)',
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                }
            },
            emphasis: {
                color: 'rgba(0,0,0,0)'
            }
        };
        return options;
    }


    theme(){
        var theme = {
            color: [
                '#007bff','#ffc107', '#28a745', '#4c84ff', '#29cc97', '#8061ef', '#fec402', '#26B99A', '#263238',  '#4caf50', '#ff9800', '#f44336', '#1dc7ea', '#172b4d', '#34495E', '#BDC3C7', '#3498DB',
                '#9B59B6', '#8abb6f', '#759c6a', '#bfd3b7'
            ],
            title: {
                itemGap: 8,
                textStyle: {
                    fontWeight: 'normal',
                    color: '#408829'
                }
            },

            dataRange: {
                color: ['#1f610a', '#97b58d']
            },

            toolbox: {
                color: ['#408829', '#408829', '#408829', '#408829']
            },

            tooltip: {
                backgroundColor: 'rgba(0,0,0,0.5)',
                axisPointer: {
                    type: 'line',
                    lineStyle: {
                        color: '#408829',
                        type: 'dashed'
                    },
                    crossStyle: {
                        color: '#408829'
                    },
                    shadowStyle: {
                        color: 'rgba(200,200,200,0.3)'
                    }
                }
            },

            dataZoom: {
                dataBackgroundColor: '#eee',
                fillerColor: 'rgba(64,136,41,0.2)',
                handleColor: '#408829'
            },
            grid: {
                borderWidth: 0
            },

            categoryAxis: {
                axisLine: {
                    lineStyle: {
                        color: '#408829'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: ['#eee']
                    }
                }
            },

            valueAxis: {
                axisLine: {
                    lineStyle: {
                        color: '#408829'
                    }
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ['rgba(250,250,250,0.1)', 'rgba(200,200,200,0.1)']
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: ['#eee']
                    }
                }
            },
            timeline: {
                lineStyle: {
                    color: '#408829'
                },
                controlStyle: {
                    normal: { color: '#408829' },
                    emphasis: { color: '#408829' }
                }
            },

            k: {
                itemStyle: {
                    normal: {
                        color: '#68a54a',
                        color0: '#a9cba2',
                        lineStyle: {
                            width: 1,
                            color: '#408829',
                            color0: '#86b379'
                        }
                    }
                }
            },
            map: {
                itemStyle: {
                    normal: {
                        areaStyle: {
                            color: '#ddd'
                        },
                        label: {
                            textStyle: {
                                color: '#c12e34'
                            }
                        }
                    },
                    emphasis: {
                        areaStyle: {
                            color: '#99d2dd'
                        },
                        label: {
                            textStyle: {
                                color: '#c12e34'
                            }
                        }
                    }
                }
            },
            force: {
                itemStyle: {
                    normal: {
                        linkStyle: {
                            strokeColor: '#408829'
                        }
                    }
                }
            },
            chord: {
                padding: 4,
                itemStyle: {
                    normal: {
                        lineStyle: {
                            width: 1,
                            color: 'rgba(128, 128, 128, 0.5)'
                        },
                        chordStyle: {
                            lineStyle: {
                                width: 1,
                                color: 'rgba(128, 128, 128, 0.5)'
                            }
                        }
                    },
                    emphasis: {
                        lineStyle: {
                            width: 1,
                            color: 'rgba(128, 128, 128, 0.5)'
                        },
                        chordStyle: {
                            lineStyle: {
                                width: 1,
                                color: 'rgba(128, 128, 128, 0.5)'
                            }
                        }
                    }
                }
            },
            gauge: {
                startAngle: 225,
                endAngle: -45,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: [[0.2, '#86b379'], [0.8, '#68a54a'], [1, '#408829']],
                        width: 8
                    }
                },
                axisTick: {
                    splitNumber: 10,
                    length: 12,
                    lineStyle: {
                        color: 'auto'
                    }
                },
                axisLabel: {
                    textStyle: {
                        color: 'auto'
                    }
                },
                splitLine: {
                    length: 18,
                    lineStyle: {
                        color: 'auto'
                    }
                },
                pointer: {
                    length: '90%',
                    color: 'auto'
                },
                title: {
                    textStyle: {
                        color: '#333'
                    }
                },
                detail: {
                    textStyle: {
                        color: 'auto'
                    }
                }
            },
            textStyle: {
                fontFamily: 'Arial, Verdana, sans-serif'
            }
        };
        return theme;
    }


    sparkline( type: any, data: Array<number>, legends: Array<string>){
        const  options: Options = {
            chart: {
                backgroundColor: 'transparent',
                borderWidth: 0,
                type: type,
                margin: [2, 0, 2, 0],
                width: 120,
                height: 20,
                style: {
                    overflow: "visible"
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                startOnTick: false,
                endOnTick: false,
                tickPositions: []
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tickPositions: [0]
            },
            legend: {
                enabled: false
            },
            tooltip: {
                hideDelay: 0,
                outside: true,
                shared: true,
                formatter(){
                    return '<span style=font-size: "10px"><center>' + legends[this.x] + '</center></span><br/><span><center>' + this.y + '</center></span>';
                }

            },
            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                },
                column: {
                    negativeColor: "#910000",
                    borderColor: "silver"
                }
            },
            series: [
                {
                    name: '',
                    type: type,
                    data: data
                }
            ]
        };
        return options;

    }


    echartGraph(legends: Array<string>, xAxis: Array<string>, data: any){
        let options = {
            title: {
                text: 'Line Graph',
                subtext: 'Subtitle'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                x: 220,
                y: 40,
                data: legends
            },
            toolbox: {
                show: true,
                feature: {
                    magicType: {
                        show: true,
                        title: {
                            line: 'Line',
                            bar: 'Bar',
                            stack: 'Stack',
                            tiled: 'Tiled'
                        },
                        type: ['line', 'bar', 'stack', 'tiled']
                    },
                    restore: {
                        show: true,
                        title: 'Restore'
                    },
                    saveAsImage: {
                        show: true,
                        title: 'Save Image'
                    }
                }
            },
            calculable: true,
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: xAxis
            }],
            yAxis: [{
                type: 'value'
            }],
            series: [
                {
                    name: legends[0],
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#26B99A',
                            label: {
                                show: true,
                                position: 'top',
                                formatter :  (params: any) => {
                                    return '' +
                                        params.value
                                        // data.params.y3[parseFloat(params.dataIndex)] + "\n" +
                                        // data.params.y5[parseFloat(params.dataIndex)] + ' %'
                                        ;
                                },
                                textStyle: {
                                    // fontWeight: 'bold',
                                },
                            },
                            areaStyle: {
                                type: 'default'
                            }
                        }
                    },
                    data: data.y1
                },
                {
                    name: legends[1],
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#26B99A',
                            label: {
                                show: true,
                                position: 'top',
                                formatter :  (params: any) => {
                                    return '' +
                                        params.value
                                        // data.params.y3[parseFloat(params.dataIndex)] + "\n" +
                                        // data.params.y5[parseFloat(params.dataIndex)] + ' %'
                                        ;
                                },
                                textStyle: {
                                    // fontWeight: 'bold',
                                },

                            },
                            areaStyle: {
                                type: 'default'
                            }
                        }
                    },
                    data: data.y2
                },
                {
                    name: legends[2],
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#26B99A',
                            label: {
                                show: true,
                                position: 'top',
                                formatter :  (params: any) => {
                                    return '' +
                                        params.value
                                        // data.params.y3[parseFloat(params.dataIndex)] + "\n" +
                                        // data.params.y5[parseFloat(params.dataIndex)] + ' %'
                                        ;
                                },
                                textStyle: {
                                    // fontWeight: 'bold',
                                },

                            },
                            areaStyle: {
                                type: 'default'
                            }
                        }
                    },
                    data: data.y3
                }
            ]
        };

        return options;

    }
}
