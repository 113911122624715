<mat-tab-group>
  <mat-tab label="All({{responses.data.length}})">
    <br><br><app-search ></app-search><br><br>
    <app-table-responses-form [flag]="'all'"></app-table-responses-form>
  </mat-tab>
  <mat-tab label="Deleted({{responses.trashed.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-responses-form [flag]="'restore'"></app-table-responses-form>
  </mat-tab>
</mat-tab-group>
