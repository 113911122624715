import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../app.service';
import * as CryptoJS from 'crypto-js';
import {Parameters} from '../parameters';
import {ResponseService} from '../forms/responses/data/response.service';
import {FormService} from '../forms/form.service';
import {CampaignService} from '../campaigns/campaign.service';

@Component({
  selector: 'app-forms-ambassador',
  templateUrl: './forms-ambassador.component.html',
  styleUrls: ['./forms-ambassador.component.scss']
})
export class FormsAmbassadorComponent implements OnInit {
  incomingForm: any;
  incomingUser: any;
  constructor(private activateRoute: ActivatedRoute,
              private router: Router,
              private formService: FormService,
              private campaignService: CampaignService,
              private responseServce: ResponseService,
              public service: AppService){
    this.incomingForm = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingForm.setAttributes({});
    this.incomingUser.setAttributes(this.service.app.data.user);
  }

  ngOnInit(): void {
    setTimeout(() => {
      document.title = this.service.app.data.user.name + ', ' + 'Ambassador';
      document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
      document.getElementsByTagName( 'body')[0].style.color = '#73879C';
      this.activateRoute.paramMap.subscribe(params => {
        let bytes: any = CryptoJS.AES.decrypt('' + params.get('name'), this.service.app.settings.encryptKey);
        bytes = decodeURIComponent(bytes.toString(CryptoJS.enc.Utf8));
      });
      if(this.service.app.data.forms.data.length === 1){
        this.onSelectedForm(this.service.app.data.forms.data[0]);
      }
    });
  }

  onSelectedForm(form: any){
    setTimeout(() => {
      this.incomingForm.setAttributes(form);
      this.responseServce.setUserId(this.incomingUser.getId());
      this.formService.setSelected(this.incomingForm.getAttributes());
      this.formService.formSelectedId = this.incomingForm.attributes.id;
//    this.router.navigateByUrl('/app/forms/' +  this.service.encrypt(this.incomingForm.getId()) + '/ambassadors/list');
      this.router.navigateByUrl('/app/respondents/' +  this.service.encrypt(this.service.app.data.user.id) + '/responses');
    });
  }

}
