import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {AppService} from "../../../app.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {FormService} from "../../form.service";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-add-controls',
  templateUrl: './add-controls.component.html',
  styleUrls: ['./add-controls.component.scss']
})
export class AddControlsComponent implements OnInit {
  @ViewChild('controlElement')  controlElement: any;
  @Input() question: any;
  @Input() questions: any;
  @Input() position: any;
  controls: any;
  filteredControlsOptions: any;
  search: any;
  controlControl = new FormControl();
  selectedOptional: any;
  constructor(public service: AppService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService) {
    this.controlElement = ElementRef;
    this.controls = this.service.app.data.controls;
    this.selectedOptional = this.data.optional;
    this.questions = this.formService.questions;

  }

  ngOnInit(): void {
    setTimeout(() =>{
      this.filteredControlsOptions = this.controlControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterCampany(name) : this.controls.slice())
      );
    });
  }



  onControlSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.controlElement.nativeElement.value = '';
    this.search = '';
    this.addQuestion(event.option.value);
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterCampany(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.controls.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  addQuestion(control: any){
    this.formService.addQuestion(this.questions, control, this.questions.length - 1);
    this.broadcastChannelService.emitNavChangeEvent({scroll: 'down'});
    var text: any = prompt('', 'Add Question Title');
    if (!this.service.isNull(text)) {
      this.formService.questions[this.service.findIndex(this.questions, 'cntrl_num', this.formService.newQuestion.cntrl_num)].label = text;
      this.selectedOptional.questions.push(this.formService.questions[this.service.findIndex(this.questions, 'cntrl_num', this.formService.newQuestion.cntrl_num)]);
    }
  }

  setQuestion(control: any){
    this.question.form_control_id = control.id;
    this.question.control_id = control.id;
    this.question.control = Object.assign(this.question.control, control);
  }
}
