import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {MemberService} from "../member.service";
import {Parameters} from "../../../parameters";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormService} from "../../../forms/form.service";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {NestedTreeControl} from "@angular/cdk/tree";

@Component({
  selector: 'app-dialog-member',
  templateUrl: './dialog-member.component.html',
  styleUrls: ['./dialog-member.component.scss']
})
export class DialogMemberComponent implements OnInit {
  dataSource: any = new MatTreeNestedDataSource<any>();
  treeControl: any = new NestedTreeControl<any>(node => node.children);
  incomingMember: any;
  incomingForm: any;
  constructor(public service: AppService,
              private memberService: MemberService,
              private formService: FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogMemberComponent>) {
    this.incomingMember = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingMember.setAttributes(this.memberService.getSelected());
    this.incomingForm.setAttributes(this.formService.getSelected());
  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close(null);
    this.memberService.setSelected({});
  }

}
