<a class="pull-left"><h4>Manage Teams</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<br>
<div style="margin-top: 20px">
    <mat-tab-group mat-align-tabs="{{tabAllign}}"
                   [selectedIndex]="incomingCampaign.attributes.teams.data.length === 0 ? 0 : selectedIndex">
        <mat-tab [disabled]="true">
            <ng-template mat-tab-label>
                <div (click)="addTeam()">
                    <center>
                        <br>
                        <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
<!--                    <div [ngStyle]="{'cursor':'pointer'}">Add Team</div>-->
                    </center>
                </div>
            </ng-template>
            <div>
            </div>
        </mat-tab>
        <mat-tab [disabled]="false"
                 *ngFor="let team of incomingCampaign.getTeams()[strings.getData()]">
            <div (mouseenter)="onSelectTeam(team)">
                <ng-template mat-tab-label>
                    <div>
                        <center>
                            {{team.name}}
                        </center>
                    </div>
                </ng-template>
                <div><br><br>
                    <mat-tab-group mat-align-tabs="end">
                        <mat-tab [disabled]="false">
                            <ng-template mat-tab-label>
                                <div class="headline2">
                                    <center>members</center>
                                </div>
                            </ng-template>
                            <div>
                                <app-manage-team-members [team]="team"></app-manage-team-members>
                            </div>
                        </mat-tab>
                        <!--                    <mat-tab [disabled]="false">-->
                        <!--                        <ng-template mat-tab-label>-->
                        <!--                            <div class="headline2">-->
                        <!--                                <center>Brunches</center>-->
                        <!--                            </div>-->
                        <!--                        </ng-template>-->
                        <!--                        <div class="normal"><br>-->
                        <!--                            <mat-tab-group mat-align-tabs="end">-->
                        <!--                                <mat-tab [disabled]="true">-->
                        <!--                                    <ng-template mat-tab-label>-->
                        <!--                                        <div (click)="addBrunch(team)">-->
                        <!--                                            <center>-->
                        <!--                                                <br>-->
                        <!--                                                <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>-->
                        <!--                                            </center>-->
                        <!--                                        </div>-->
                        <!--                                    </ng-template>-->
                        <!--                                    <div>-->
                        <!--                                    </div>-->
                        <!--                                </mat-tab>-->
                        <!--                                <mat-tab [disabled]="false" *ngFor="let branch of team.branches.data">-->
                        <!--                                    <ng-template mat-tab-label>-->
                        <!--                                        <div class="headline">-->
                        <!--                                            <center>-->
                        <!--                                                {{getBranchByBranch(branch).name}}-->
                        <!--                                            </center>-->
                        <!--                                        </div>-->
                        <!--                                    </ng-template>-->
                        <!--                                    <div>-->
                        <!--                                        <app-manage-team-brunch-members [team]="team" [branch]="branch"></app-manage-team-brunch-members>-->
                        <!--                                    </div>-->
                        <!--                                </mat-tab>-->
                        <!--                            </mat-tab-group>-->
                        <!--                        </div>-->
                        <!--                    </mat-tab>-->
                        <!--                    <mat-tab [disabled]="false">-->
                        <!--                        <ng-template mat-tab-label>-->
                        <!--                            <div class="headline2">-->
                        <!--                                <center>-->
                        <!--                                    Add Stock<br>-->
                        <!--                                </center>-->
                        <!--                            </div>-->
                        <!--                        </ng-template>-->
                        <!--                        <div><br><br>-->
                        <!--                            <table [ngStyle]="{'width':'100%'}">-->
                        <!--                                <td [ngStyle]="{'width':'100%'}"-->
                        <!--                                    valign="top">-->
                        <!--                                    <mat-form-field  class="example-full-width" appearance='outline'>-->
                        <!--                                        <mat-label class="headline2">Item</mat-label>-->
                        <!--                                        <input matInput-->
                        <!--                                               type="text"-->
                        <!--                                               #suggestions-->
                        <!--                                               #trigger="matAutocompleteTrigger"-->
                        <!--                                               [ngStyle]="{'text-align': 'left'}"-->
                        <!--                                               [formControl] = "suggestionControl"-->
                        <!--                                               [matAutocomplete] = "suggestion" />-->
                        <!--                                        <mat-autocomplete #suggestion = "matAutocomplete"-->
                        <!--                                                          (optionSelected)="onSuggestionSelectionChanged($event)"-->
                        <!--                                                          [displayWith]="displayFn">-->
                        <!--                                            <mat-option class="headline2" *ngFor="let suggestion of filteredSuggestions | async; let suggestionIncresemental = index" [value]= "suggestion" >-->
                        <!--                                                {{suggestion.label}}-->
                        <!--                                            </mat-option>-->
                        <!--                                        </mat-autocomplete>-->
                        <!--                                        <h4 [ngStyle]="{'color': 'red', 'font-weight': 'normal'}"-->
                        <!--                                            *ngIf="service.isNull(suggestions.value) === false &&-->
                        <!--                                             ((filteredSuggestions | async) | json).length === 2">-->
                        <!--                                            **-&#45;&#45;Not Found *-&#45;&#45;-->
                        <!--                                        </h4>-->
                        <!--                                    </mat-form-field>-->
                        <!--                                </td>-->
                        <!--                                <td [ngStyle]="{'padding-left':'10px'}"-->
                        <!--                                    valign="top">-->
                        <!--                                    <mat-form-field  class="example-full-widthh" appearance='outline'>-->
                        <!--                                        <mat-label class="headline2">Qty</mat-label>-->
                        <!--                                        <input matInput-->
                        <!--                                               #qty-->
                        <!--                                               (keydown)="service.validateNumber($event)"-->
                        <!--                                               [ngStyle]="{'text-align': 'center'}"-->
                        <!--                                               type="text"/>-->
                        <!--                                    </mat-form-field>-->
                        <!--                                </td>-->
                        <!--                                <td [ngStyle]="{'padding-left':'10px', 'padding-top':'0px'}"-->
                        <!--                                    valign="top">-->
                        <!--                                    <div class="panel panel-default cursor"-->
                        <!--                                         (click)="addStock(team, qty.value)">-->
                        <!--                                        <div class="panel-body">-->
                        <!--                                            <center><a class=''><i class="material-icons">add</i></a></center>-->
                        <!--                                        </div>-->
                        <!--                                    </div>-->
                        <!--                                </td>-->
                        <!--                            </table>-->
                        <!--                            <hr>-->
                        <!--                        </div>-->
                        <!--                    </mat-tab>-->
                    </mat-tab-group>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
