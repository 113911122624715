import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormService} from '../../forms/form.service';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ResponseService} from '../../forms/responses/data/response.service';
import {NgxQrcodeStylingService, Options} from 'ngx-qrcode-styling';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageService} from '../../images/image.service';
import {NotifierService} from 'angular-notifier';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-response',
  templateUrl: './dialog-response.component.html',
  styleUrls: ['./dialog-response.component.scss']
})
export class DialogResponseComponent implements OnInit {
  @ViewChild('recaptchaId') recaptchaId: any;
  formValues: FormGroup = new FormGroup({});
  incomingForm: any;
  incomingFormSettings: any;
  incomingClient: any;
  incomingCampaign: any;
  outgoingParams: any;
  pdfUrl: any = null;
  canvas: any = null;
  strings: any;
  questions: any = [];
  incomingQuestion: any;
  outgoingResponse: any;
  siteKey = '6Lcc-W0UAAAAAAdcFGL0d47jvGH5Eemf2DqBiOwK';
  recaptcha: any = '';
  formId: any;
  incomingInvitationCard: any;
  incomingNameQuestion: any;
  incomingPhoneQuestion: any;
  incomingCompanyQuestion: any;
  incomingEmailQuestion: any;
  incomingResponse: any;
  incomingInvitee: any;
  pg: any = 0;
  outgoingRequest: any;
  disabled: any = false;
  constructor(public service: AppService,
              private responseService: ResponseService,
              private qrcode: NgxQrcodeStylingService,
              public formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private imgService: ImageService,
              public zone: NgZone,
              private router: Router,
              private notifier: NotifierService,
              public formService: FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogResponseComponent>) {
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingInvitee = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingCompanyQuestion = new Parameters();
    this.incomingEmailQuestion = new Parameters();
    this.incomingNameQuestion = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingPhoneQuestion = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingQuestion = new Parameters();
    this.outgoingResponse = new Parameters();
    this.outgoingParams = new Parameters();
    this.incomingInvitationCard = new Parameters();
    this.strings = new Parameters();
    this.recaptchaId = InvisibleReCaptchaComponent;
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingFormSettings.setAttributes({});
    this.incomingCompanyQuestion.setAttributes({});
    this.outgoingParams.setAttributes({});
    this.strings.setAttributes(this.service.app.strings.params);
    this.incomingQuestion.setAttributes({});
    this.incomingInvitationCard.setAttributes({});
    this.incomingNameQuestion.setAttributes({});
    this.incomingPhoneQuestion.setAttributes({});
    this.incomingEmailQuestion.setAttributes({});
    this.incomingInvitee.setAttributes({});
    this.incomingInvitee.setAttendeeName('');
    this.incomingInvitee.setAttendeeCompany('');
    this.incomingResponse.setAttributes(this.responseService.getSelected());
  }

  ngOnInit(): void {
    this.formId = this.incomingForm.getId();
    if (this.incomingForm.hasAttribute(this.strings.getId())){
  // this.formValues = this.formBuilder.group({});
      this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
      this.incomingClient.setAttributes(this.incomingCampaign.getClient());
      this.pg = this.service.flag;
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.service.backgroundColor = this.incomingFormSettings.getBackgroundColor();
      this.incomingForm.getControls().map((question: any) => {
        this.incomingQuestion.setAttributes(Object.assign({}, question));
        let incomingQuestionSettings: any = new Parameters();
        incomingQuestionSettings.setAttributes(this.incomingQuestion.getSettings());
        incomingQuestionSettings.setHidden(incomingQuestionSettings.hasAttribute(this.strings.getHidden()) &&
            !this.service.isNull(incomingQuestionSettings.getHidden()) ? incomingQuestionSettings.getHidden() : 0);
        this.incomingQuestion.setData('');
        this.incomingQuestion.setResult({});
        if(!(this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getIsAttending() ||
            this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationCard())) {
          if(parseFloat(incomingQuestionSettings.getHidden()) === 0)
            this.questions.push(this.incomingQuestion.getAttributes());
        }if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName())
          this.incomingNameQuestion.setAttributes(this.incomingQuestion.getAttributes());
        if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany())
          this.incomingCompanyQuestion.setAttributes(this.incomingQuestion.getAttributes());
        if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePhone())
          this.incomingPhoneQuestion.setAttributes(this.incomingQuestion.getAttributes());
        if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeEmail())
          this.incomingEmailQuestion.setAttributes(this.incomingQuestion.getAttributes());
        if (this.incomingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationCard()) {
          this.incomingInvitationCard.setAttributes(this.incomingQuestion.getAttributes());
        }
      });
    }
  }

  closeDialog(){
    this.dialogRef.close(null);
  }


  ngOnDestroy(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgb(16,30,81)';
  }


  handleReset(){
  }

  handleLoad(){
  }

  handleSuccess(event: any){
    this.recaptcha = event;
  }

  handleExpire(){
    this.recaptchaId.reloadCaptcha();
    this.recaptcha = '';
  }

  onChecked(question: any, option: any, HTMLCheck: any){
    const incomingQuestion: any = new Parameters();
    const incomingOption: any = new Parameters();
    const incomingResult: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingOption.setAttributes(option);
    incomingResult.setAttributes(incomingQuestion.getResult());
    if (HTMLCheck.checked){
      incomingResult.setUnknown(incomingOption.getCntrlNum(), {label : incomingOption.getLabel()});
    }else { if (incomingResult.getAttributes().hasOwnProperty(incomingOption.getCntrlNum())) {
      delete incomingResult.getAttributes()[incomingOption.getCntrlNum()];
    }
    }
    incomingQuestion.setData('');
    for (const key in incomingResult.getAttributes()) {
      const incomingLabel: any = new Parameters();
      incomingLabel.setAttributes(incomingResult.getAttributes()[key]);
      incomingQuestion.setData(incomingQuestion.getData() + ':' +
          incomingLabel.getAttributes()[this.strings.getLabel()]);
    }
  }

  onInputTextChange(question: any, $event: any){
    const incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setData($event.target.value);
  }

  validate(call: any){
    const outgoingQuestion: any = new Parameters();
    outgoingQuestion.setAttributes({});
    this.questions.map((question: any) => {
      const incomingResponseResult = new Parameters();
      const incomingQuestionSettings = new Parameters();
      incomingResponseResult.setAttributes(question);
      incomingQuestionSettings.setAttributes(incomingResponseResult.getSettings());
      incomingResponseResult.setRequired(
          incomingResponseResult.hasAttribute(this.strings.getRequired()) &&
          !this.service.isNull(incomingResponseResult.getRequired()) ? incomingResponseResult.getRequired() : 0);
      if (parseFloat(incomingResponseResult.getRequired()) !== 0 && this.service.isNull(incomingResponseResult.getData())){
        if (!outgoingQuestion.hasAttribute(this.strings.getId()))
          outgoingQuestion.setAttributes(incomingResponseResult.getAttributes());
      }
    });
    if (outgoingQuestion.hasAttribute(this.strings.getId())) {
      this.notifier.notify(this.strings.getSuccess(), outgoingQuestion.getLabel() + ' ' + this.strings.getRequired());
      const elementId: any = document.getElementById(outgoingQuestion.getIndex());
      elementId.scrollIntoView({behavior: 'smooth'});
    }call(outgoingQuestion.hasAttribute(this.strings.getId()) ? 1 : 0);
  }

  ngSubmit(formValues: any){
    this.disabled = true;
    this.validate((result: any) => {
      if(parseFloat(result) === 0){
        this.responseService.setPost(this.incomingForm.getAttributes(), (response) => {
          this.outgoingResponse.setAttributes(response);
          const questions: any = [];
          this.questions.map((question: any) => {
            const $incomingQuestion: any = new Parameters();
            $incomingQuestion.setAttributes(Object.assign({}, question));
            $incomingQuestion.setResult($incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull($incomingQuestion.getResult()) ?
                JSON.stringify($incomingQuestion.getResult()) : '');
            $incomingQuestion.getAttributes()[this.strings.getFormControlId()] =
                $incomingQuestion.getAttributes().control.id;
            $incomingQuestion.attributes = Object.assign($incomingQuestion.getAttributes(), {
              data: this.service.isNull($incomingQuestion.getAttributes().data) ? '' :
                  $incomingQuestion.getAttributes().data});
            delete $incomingQuestion.getAttributes().options;
            questions.push($incomingQuestion.getAttributes());
          });
          this.outgoingResponse.setControls(questions);
          const posts: any = [];
          this.send(posts, (post) => {
            this.disabled = false;
          });
          // this.emailVerify((output: any) => {
          //   if(output !== null){
          //     let incomingOutput = new Parameters();
          //     incomingOutput.setAttributes(output);
          //     if(incomingOutput.hasAttribute(this.strings.getEmailStatus())){
          //       if(incomingOutput.getStatus().toLowerCase() === 'ok'){
          //         if(incomingOutput.getEmailStatus() !== this.strings.getInValid().toUpperCase()){
          //           this.send(posts);
          //         }else this.notifier.notify(this.strings.getSuccess(),
          //             this.strings.getEmailStatusIs() +
          //             incomingOutput.getEmailStatus());
          //       }
          //     }
          //   }else this.send(posts);
          // });
        });
      }
    });
  }

  send(posts: any, call: any){
    posts.push(this.outgoingResponse.getAttributes());
    this.post(posts, () => {
      call(null);
      setTimeout(() => {
        this.notifier.notify(
            this.strings.getSuccess(),
            this.incomingResponse.hasAttribute(this.strings.getId()) ? '' :
                this.strings.getIncluded());
        this.questions = [];
        this.ngOnInit();
      });
    });
  }

  emailVerify(call: any){
    if(this.incomingEmailQuestion.hasAttribute(this.strings.getId()) && !this.service.isNull(this.incomingEmailQuestion.getData())){
      this.service.emailVerifyByMailbite(this.incomingEmailQuestion.getData(), call);
    }else call(null);
  }

  requestForm(call: any){
    if (!this.formService.getSelected().hasOwnProperty(this.strings.getId())){
      this.router.navigateByUrl('/wait..');
      this.outgoingRequest.setAttributes({});
      this.outgoingRequest.setId(this.formId);
      this.outgoingRequest.setId(this.formId);
      this.service.httpService('POST',
          this.service.app.routes.forms.get.toString(),
          this.outgoingRequest.getAttributes(), { ignoreLoadingBar : 'true', notify: false}, (result: any) => {
            this.formService.setSelected(result);
            this.incomingForm.setAttributes(result);
            window.history.back();
            // this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
          }, (error: any) =>  {
            this.requestForm(call);
          });
    }
  }

  sendInvitationCard(request: any, callback: any){
    this.outgoingRequest.setAttributes(request);
    this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (result: any) => {callback();
        }, (error: any) => { callback(); });
  }
  download(call: any){
    this.imgService.download(this.incomingForm.getName(), this.canvas, (result: any) => {call(null);});
  }

  post(posts: any, call: any){
    this.service.httpService(
        'POST',
        this.service.app.routes.responses.post,
        {posts: JSON.stringify(posts)},
        {},
        (result: any) => {
          call(null);
        }, (error: any) => {
          this.post(posts, call);
        });
  }

  moveTo(){
    let url: any = window.location.href.split('?');
    url = url.length === 2 ? url[1] : '';
    this.router.navigateByUrl(
        this.strings.getSlash() +
        this.strings.getApp() +
        this.strings.getSlash() +
        this.strings.getEvents() +
        this.strings.getSlash() + this.service.encrypt(this.formId) + this.strings.getSlash() +
        this.strings.getAnnoucement() + '?' + url);
  }

  getQrcodeUrl(){
    return this.strings.getHost() +
        this.strings.getSlash() +
        this.strings.getAppForms() +
        this.strings.getSlash() +
        this.service.encrypt(this.outgoingResponse.getPostId() +
            this.strings.getSlash() +
            this.strings.getConfirmation() +
            this.strings.getAccess() + this.incomingClient.getLogo().split(this.strings.getClients() + this.strings.getSlash())[1] +
            this.strings.getExtension() + this.incomingFormSettings.getBackgroundColor()).toString();
  }

}
