import {AfterContentInit, AfterViewInit, Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {Parameters} from '../../../parameters';
import * as $ from "jquery";
import {FormService} from "../../form.service";

@Component({
  selector: 'app-description-controls',
  templateUrl: './description-controls.component.html',
  styleUrls: ['./description-controls.component.scss']
})
export class DescriptionControlsComponent implements AfterViewInit, OnInit, AfterContentInit, OnDestroy{
  @Input() question: any;
  @Input() position: any;
  configAngularEditor: any;
  htmlContent: any;
  label: any;
  incomingFormSettings: any;
  incomingForm: any;
  incomingIsConfirmQuestion: any;
  incomingQrcodeQuestion: any;
  incomingInvitationCardQuestion: any;
  constructor(private service: AppService,
    public formService: FormService) {
    this.htmlContent = null;
    this.incomingForm = new Parameters();
    this.incomingFormSettings = new Parameters();
    this.incomingIsConfirmQuestion = new Parameters();
    this.incomingQrcodeQuestion = new Parameters();
    this.incomingInvitationCardQuestion = new Parameters();
  }

//setReferenceQuestions(){
//        this.incomingIsConfirmQuestion.setAttributes({});
//        this.incomingQrcodeQuestion.setAttributes({});
//        this.incomingInvitationCardQuestion.setAttributes({});
//        this.incomingForm.getControls().map((question: any)=>{
//             this.outgoingQuestion.setAttributes(question);
//             this.incomingSettingsQuestion.setAttributes(Object.assign({},this.outgoingQuestion.getSettings()));
//             if(this.incomingFormSettings.getIsConfirm().toString().includes(this.outgoingQuestion.getCntrlNum()))
//              this.incomingIsConfirmQuestion.setAttributes(this.outgoingQuestion.getAttributes());
//             else if(parseFloat(this.outgoingQuestion.getControlId()) === 32)
//              this.incomingInvitationCardQuestion.setAttributes(this.outgoingQuestion.getAttributes());
//             else if(parseFloat(this.outgoingQuestion.getControlId()) === 33)
//               this.incomingQrcodeQuestion.setAttributes(this.outgoingQuestion.getAttributes());
//        });
//}


  ngOnInit(): void {
      this.incomingForm.setAttributes(this.formService.getSelected());
      this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
      this.configAngularEditor = Object.assign({},this.service.setAngularEditorConfig());
      this.incomingFormSettings.setVenue(this.incomingFormSettings.attributes.hasOwnProperty('venue') ?
          this.incomingFormSettings.getVenue() : '');
      this.incomingFormSettings.setRSVPDate(this.incomingFormSettings.attributes.hasOwnProperty('rsvp_date') ?
          this.incomingFormSettings.getRSVPDate() : '');
      this.incomingFormSettings.setAttendingDate(this.incomingFormSettings.attributes.hasOwnProperty('attending_date') ?
          this.incomingFormSettings.getAttendingDate() : '');
      this.incomingFormSettings.setJobPosition(this.incomingFormSettings.attributes.hasOwnProperty('job_position') ?
          this.incomingFormSettings.getJobPosition() : '');
      this.incomingFormSettings.setBackgroundColor(this.incomingFormSettings.attributes.hasOwnProperty('background_color') ?
           this.incomingFormSettings.getBackgroundColor() : '');
      this.incomingFormSettings.setContactPersonName(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_name') ?
           this.incomingFormSettings.getContactPersonName() : '');
      this.incomingFormSettings.setContactPersonPhone(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_phone') ?
           this.incomingFormSettings.getContactPersonPhone() : '');
      this.incomingFormSettings.setContactPersonTitle(this.incomingFormSettings.attributes.hasOwnProperty('contact_person_title') ?
           this.incomingFormSettings.getContactPersonTitle() : '');
      this.incomingFormSettings.setCompanyName(this.incomingFormSettings.attributes.hasOwnProperty('company_name') ?
           this.incomingFormSettings.getCompanyName() : '');
      this.incomingFormSettings.setAttendeeName(this.incomingFormSettings.attributes.hasOwnProperty('attendee_name') ?
           this.incomingFormSettings.getAttendeeName() : '');
      this.incomingFormSettings.setSalutation(this.incomingFormSettings.attributes.hasOwnProperty('salutation') ?
           this.incomingFormSettings.getSalutation() : '');
      this.incomingFormSettings.setLogoUrl(this.incomingFormSettings.attributes.hasOwnProperty('logo_url') ?
           this.incomingFormSettings.getLogoUrl() : '');
      this.incomingFormSettings.setLogoDataUrl(this.incomingFormSettings.attributes.hasOwnProperty('logo_data_url') ?
           this.incomingFormSettings.getLogoDataUrl() : '');
  }

  ngAfterViewInit(): void {
    // $.getScript( '../assets/js/text-editor.js', ( data, textStatus, jqxhr ) => {});
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {

  }

}
