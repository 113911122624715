<div class="page">
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div>
                <div class="fixed">
                    <div class="roww">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="row top_tiles">
                                <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="incomingCampaign.attributes.forms.data.length > 0">
                                    <div class="tile-stats" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
                                       <div class="pull-right">
                                                <table>
                                                    <td class='headline2 selectedMatTab2 cursor' (click)="matMenuStatus = 2; onContextMenu($event, {})"><i class="material-icons">view_comfy</i></td>
                                                    <td valign="top" class='headline2 selectedMatTab2'>{{incomingClient.getName().toString().toLowerCase()}}</td>
                                                    <td [hidden]='true'><img class ='img-thumbnailx' src="{{incomingClient.getLogo()}}" style="min-width: 60px; max-height: 25px; float: right;margin-left: 5px"/></td>
                                                </table>
                                        </div>
                                        <div class="pull-left">
                                            <table>
                                                <td class="{{incomingForm.getId() === form.id ? 'selectedMatTab': ''}} cursor"
                                                    style="margin-left: 10px; padding-right: 50px"
                                                    *ngFor="let form of incomingCampaign.attributes.forms.data"
                                                    (click)="onFormSelected(form)">
                                                    <div class="headline">{{form.name.toString().toUpperCase()}}</div>
                                                </td>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY {{navOverall !== null ? 'col-lg-10 col-md-10' : 'col-lg-12 col-md-12'}} col-sm-12 col-xs-12">
                                    <div class="tile-stats" style="min-height: auto">
                                        <table class="pull-right">
<!--                                        <td class="cursor headline2"-->
<!--                                                (click)="matMenuStatus = 1; onContextMenu($event, {})"-->
<!--                                                (mousedown)="incomingSelectedCustomReport.setAttributes(incomingReport.attributes.data)">-->
<!--                                                ..-->
<!--                                        </td>-->
                                            <td *ngFor="let time of durations" class="{{navTime === time.id ? 'selectedMatTab2' : ''}} cursor"
                                                (click)="navTime = time.id; onChangeDuration(time.tm)"
                                                style="margin-left: 5px; padding-right: 5px">
                                                <div class="headline2">{{time.name}}</div>
                                            </td>
                                        </table>
                                        <div >
                                            <div *ngFor="let time of durations">
                                                <table class="pull-left" *ngIf="navTime === time.id">
                                                    <td  class="{{navOutlets === 0 ? (incomingCampaign.attributes.forms.data.length > 1 ? 'selectedMatTab2' : 'selectedMatTab'): ''}} cursor"
                                                         style="margin-left: 10px; padding-right: 50px"
                                                         *ngIf='service.parseFloat(incomingSettings.getResponsesCounter()) === 0'
                                                         (click)="pState();navOutlets = 0;navOverall = 0; navSell = null;navCurrencies = null; navProducts = null">
                                                        <div class="headline">{{incomingSettings.getVistedOutletAliasName().toString().toUpperCase()}}</div>
                                                        <div class="counter">{{service.numberWithCommans(incomingReport.attributes.data[time.tm].outlets)}}</div>
                                                    </td>
                                                    <td  class="{{navSell === 0 ? (incomingCampaign.attributes.forms.data.length > 1 ? 'selectedMatTab2' : 'selectedMatTab'): ''}} cursor"
                                                         style="margin-left: 10px; padding-right: 50px"
                                                         *ngIf='service.parseFloat(incomingSettings.getResponsesCounter()) === 0'
                                                         (click)="pState();navSell = 0; navOverall = 0; navOutlets = null;navCurrencies = null; navProducts = null">
                                                        <div class="headline">OUTLETS <br>THAT <br>SELL</div>
                                                        <div class="counter">{{service.numberWithCommans(incomingReport.attributes.data.outlets[time.tm].outlets_that_sell)}}</div>
                                                    </td>
                                                    <td  class="{{navOverall === 0 ? (incomingCampaign.attributes.forms.data.length > 1 ? 'selectedMatTab2' : 'selectedMatTab'): ''}} cursor"
                                                         style="margin-left: 10px; padding-right: 50px"
                                                         *ngIf='service.parseFloat(incomingSettings.getResponsesCounter()) === 1'
                                                         (click)="pState();navOverall = 0; navOutlets = null; navSell = null;navCurrencies = null; navProducts = null">
                                                        <div class="headline">{{incomingForm.getResponseDisplayTitle().toString().toUpperCase()}}</div>
                                                        <div class="counter">{{service.numberWithCommans(incomingReport.attributes.data[time.tm].responses)}}</div>
                                                    </td>
                                                    <td class="{{navCurrencies === qenstionalIndex  ? 'selectedMatTab': ''}} cursor"
                                                        style="margin-left: 10px; padding-right: 50px"
                                                        *ngFor="let question of this.getQuestions(incomingReport.attributes.data.questions); let qenstionalIndex = index"
                                                        (click)="pState();incomingQuestion.setAttributes(question); navCurrencies = qenstionalIndex; navOutlets = null; navOverall = null; navSell = null;navProducts = null">
                                                        <div class="headline">{{question.label.toString().toUpperCase()}}</div>
                                                        <div>
                                                            <table>

                                                                <tr>
                                                                    <td>
                                                                        <span class="counter">{{service.numberWithCommans(question[time.tm].currency)}}</span>
                                                                    </td>
                                                                    <td *ngIf="service.parseFloat(question.settings.stock) === 1 && service.parseFloat(service.app.data.user.role.id) !== 2">
                                                                        <span class="counter">{{service.numberWithCommans(question[time.tm].packs)}}</span>
                                                                    </td>
                                                                    <td *ngIf="service.parseFloat(question.settings.stock) === 1 && service.parseFloat(service.app.data.user.role.id) !== 2">
                                                                        <span class="counter">
                                                                            {{service.numberWithCommans(service.parseFloat(question[time.tm].packs) - service.parseFloat(question[time.tm].currency))}}
<!--                                                                            <span> 18 %</span>-->
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="service.parseFloat(question.settings.stock) === 1 && service.parseFloat(service.app.data.user.role.id) !== 2">
                                                                    <td><span class="headline2" [ngStyle]="{'margin-left':'0px'}">SOLD</span></td>
                                                                    <td><span class="headline2" [ngStyle]="{'margin-left':'0px'}">OUT</span></td>
                                                                    <td><span class="headline2" [ngStyle]="{'margin-left':'0px'}">CLOSED</span></td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </td>
                                                    <td class="{{navProducts === productIndex  ? 'selectedMatTab': ''}} cursor"
                                                        style="margin-left: 10px; padding-right: 50px"
                                                        *ngFor="let product of incomingReport.attributes.data.products; let productIndex = index"
                                                        (click)="pState(); incomingQuestion.setAttributes(product); navProducts = productIndex; navOutlets = null; navSell = null;navCurrencies = null; navOverall = null">
                                                        <div class="headline">{{product.label.toString().toUpperCase()}}</div>
                                                        <div class="counter">{{service.numberWithCommans(product[time.tm].responses)}}</div>
                                                    </td>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <table style="width: 100%;">
                                                <td valign="top">
                                                    <div class="chartMargin" *ngIf='hiddenFlag === 1 && service.parseFloat(incomingSettings.getResponsesCounter()) === 1'>
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="navTime === time.id">
                                                                <div *ngIf="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses').length > 0">
                                                                    <div *ngIf="navOverall === 0">
                                                                        <div *ngIf="navFunc === 1">
                                                                            <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="incomingForm.getResponseDisplayTitle()"
                                                                                             [type]="'line'"
                                                                                             [limit]="100"
                                                                                             [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                             [element]="'overallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="incomingReport.attributes.data.chart === 2"
                                                                            >
                                                                            </app-bar-chartjs>
                                                                            <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="incomingForm.getResponseDisplayTitle()"
                                                                                             [type]="'bar'"
                                                                                             [limit]="100"
                                                                                             [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                             [element]="'overallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="incomingReport.attributes.data.chart === 4"
                                                                            >
                                                                            </app-bar-chartjs>
                                                                            <div *ngIf="incomingReport.attributes.data.chart === 0">
                                                                                <app-piechart-chartjs
                                                                                        [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                        [label]="incomingForm.getResponseDisplayTitle()"
                                                                                        [limit]="10"
                                                                                        [type]="'pie'"
                                                                                        [height]="'105'"
                                                                                        [showLabels]="true"
                                                                                        [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                        [element]="'overallElement'"
                                                                                        *ngIf="hiddenFlag === 1"
                                                                                        [color]="'blue'">
                                                                                </app-piechart-chartjs>
                                                                                <div style="position: absolute; top:50px; right: 5px" [hidden]='true'>
                                                                                    <div class="animated flipInY">
                                                                                        <div clfass="tile-stats" [ngStyle]="{'margin': '2px'}">
                                                                                            <table>
                                                                                                <tr *ngFor="let legend of only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label); let incremental=index">
                                                                                                    <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                                    <td class="headline2"> {{legend}}</td>
                                                                                                    <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')[incremental]}}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'line'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="navFunc === 2"
                                                                        >
                                                                        </app-bar-chartjs>
                                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'line'"
                                                                                         [limit]="100"
                                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'progress')"
                                                                                         [element]="'overallElement'"
                                                                                         [color]="'blue'"
                                                                                         *ngIf="navFunc === 3"
                                                                        >
                                                                        </app-bar-chartjs>
                                                                        <app-user-activity-chartjs
                                                                                [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                                [label2]="'kpi'"
                                                                                [fill1]="true"
                                                                                [type]="'line'"
                                                                                [height]="'175'"
                                                                                [limit]="100"
                                                                                [d1]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                [d2]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                [element]="'overallElement'"
                                                                                [color]="'blue'"
                                                                                *ngIf="navFunc === 4">
                                                                        </app-user-activity-chartjs>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chartMargin" *ngIf="navOutlets !== null && service.parseFloat(incomingSettings.getResponsesCounter()) === 0">
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="navTime === time.id">
                                                                <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                 [label]="'outlets'"
                                                                                 [type]="'line'"
                                                                                 [limit]="100"
                                                                                 [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'outlets')"
                                                                                 [element]="'outletElm'"
                                                                                 [color]="'blue'">
                                                                </app-bar-chartjs>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chartMargin" *ngIf="navCurrencies !== null">
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="navTime === time.id">
                                                                <div *ngFor="let question of getQuestions(incomingReport.attributes.data.questions); let questionalIndex = index">
                                                                    <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="question.label"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navCurrencies === questionalIndex"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="chartMargin" *ngIf="navProducts !== null">
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="navTime === time.id">
                                                                <div *ngFor="let product of incomingReport.attributes.data.products; let productIndex = index">
                                                                    <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                     [label]="product.label"
                                                                                     [type]="'line'"
                                                                                     [limit]="100"
                                                                                     [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                     [element]="'overallElement'"
                                                                                     [color]="'blue'"
                                                                                     *ngIf="navProducts === productIndex"
                                                                    >
                                                                    </app-bar-chartjs>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </table>
                                            <div *ngIf="navOverall !== null">
                                                <table class="pull-right">
<!--                                                    <td class="{{navTime === 10 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = 10"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">{{incomingFormValues.getRespondentAlias()}}</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navTime === 9 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = 9"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">teams</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navTime === 7 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = 7"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">Branch</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navTime === 6 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = 6"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">Regions</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navTime === 5 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = 5"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">Country</div>-->
<!--                                                    </td>-->
                                                    <td class="{{navFunc === 4 ? 'selectedMatTab3' : ''}} cursor"
                                                        (click)="navFunc = 4"
                                                        style="margin-left: 5px; padding-right: 5px">
                                                        <div class="headline2">{{incomingForm.getResponseDisplayTitle()}} VS Kpi</div>
                                                    </td>
                                                    <td class="{{navFunc === 3 ? 'selectedMatTab3' : ''}} cursor"
                                                        (click)="navFunc = 3"
                                                        style="margin-left: 5px; padding-right: 5px">
                                                        <div class="headline2">progress in %</div>
                                                    </td>
                                                    <td class="{{navFunc === 2 ? 'selectedMatTab3' : ''}} cursor"
                                                        (click)="navFunc = 2"
                                                        style="margin-left: 5px; padding-right: 5px">
                                                        <div class="headline2">kip</div>
                                                    </td>
                                                    <td class="{{navFunc === 1 ? 'selectedMatTab3' : ''}} cursor"
                                                        (click)="navFunc = 1"
                                                        style="margin-left: 5px; padding-right: 5px">
                                                        <div class="headline2">{{incomingForm.getResponseDisplayTitle()}}</div>
                                                    </td>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-1 col-md-1 col-sm-6 col-xs-12" *ngIf="navOverall !== null">
                                    <div class="tile-stats" style="height: 250px">
                                        <center><div class="headline">kpi on set</div></center>
                                        <div style="position: absolute;bottom: 5px;width: 100%" class="counter">
                                            <div *ngIf="this.service.isNull(incomingSettings.getTarget())">
                                                <center *ngIf="navTime === 2">{{incomingReport.attributes.data.monthly.target}}</center>
                                                <center *ngIf="navTime === 1">{{incomingReport.attributes.data.weekly.target}}</center>
                                                <center *ngIf="navTime === 3">{{incomingReport.attributes.data.yearly.target}}</center>
                                                <center *ngIf="navTime === 4">{{incomingReport.attributes.data.custom.target}}</center>
                                            </div>
                                            <div *ngIf="!this.service.isNull(incomingSettings.getTarget())">
                                                <center>{{service.numberWithCommans(incomingSettings.getTarget())}}</center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-1 col-md-1 col-sm-6 col-xs-12" *ngIf="navOverall !== null">
                                    <div class="tile-stats" style="height: 250px">
                                        <center>
                                            <div *ngIf="navTime === 1" class="counter" [innerHTML]="service.easyPieChart(incomingReport.attributes.data.weekly.progress) | safeUrl"></div>
                                            <div *ngIf="navTime === 2" class="counter" [innerHTML]="service.easyPieChart(incomingReport.attributes.data.monthly.progress) | safeUrl"></div>
                                            <div *ngIf="navTime === 3" class="counter" [innerHTML]="service.easyPieChart(incomingReport.attributes.data.yearly.progress) | safeUrl"></div>
                                            <div *ngIf="navTime === 4" class="counter" [innerHTML]="service.easyPieChart(incomingReport.attributes.data.custom.progress) | safeUrl"></div>
                                        </center>
                                        <div style="position: absolute;bottom: 5px;width: 100%" class="headline2">
                                            <center>progress in %</center>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                    <div class="tile-stats" style="height: 248px">
                                        <div *ngFor="let time of durations">
                                            <div *ngIf="navTime === time.id">
                                                <agm-map
                                                    #gm
                                                    [latitude]="incomingReport.attributes.data.markers[time.tm].markers.length  === 0 ? lat : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lat)"
                                                    [longitude]="incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? lng : service.parseFloat(incomingReport.attributes.data.markers[time.tm].markers[0].lng)"
                                                    [fitBounds]="false"
                                                    [zoom]="zoom"
                                                    (mapClick)="onMapClicked()"
                                                    [disableDefaultUI]="false"
                                                    [styles]="app.settings.map.styles.style1"
                                                    style="height : {{getHeight().height + 'px'}}">
                                                    <agm-marker
                                                            *ngFor="let marker of (incomingReport.attributes.data.markers[time.tm].markers.length === 0 ? [] :  incomingReport.attributes.data.markers[time.tm].markers)"
                                                            [latitude]="marker.lat"
                                                            [longitude]="marker.lng"
                                                            [agmFitBounds]="true"
                                                            [openInfoWindow]="true"
                                                            (markerClick)="onClickMarker($event, marker)"
                                                            [iconUrl]="icon">
                                                        <agm-info-window
                                                                (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"
                                                                #infoWindow>
                                                            <div>
                                                                <div class="sawasdee20">{{service.upperStartingCharacter(marker.postedBy.length === 0 ? marker.date : marker.postedBy.name, true)}}</div>
                                                                <mat-divider></mat-divider>
                                                                <div class="sawasdee20">{{service.upperStartingCharacter(marker.title, true)}}</div>
                                                                <div>
        <!--                                                                    <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">-->
        <!--                                                                        <div class="carousel-cell">-->
        <!--                                                                            <img style='width: 200px;max-height: 300px' src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
        <!--                                                                        </div>-->
        <!--                                                                        <div class="carousel-cell">-->
        <!--                                                                            <img src="https://image.scoopwhoop.com/w360/s3.scoopwhoop.com/anj/dead_poets_society_quotes/285731274.jpg.webp">-->
        <!--                                                                        </div>-->
        <!--                                                                    </carousel>-->
                                                                </div>
                                                                <div> <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner></div>
                                                                <div *ngIf="marker.hasOwnProperty('response')">
                                                                    <table>
                                                                        <tbody>
                                                                          <tr>
                                                                              <td class="sawasdee20">Address</td>
                                                                              <td class="sawasdee20">{{service.upperStartingCharacter(marker.response[0].address, true)}}</td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td class="sawasdee20">Date</td>
                                                                              <td class="sawasdee20">{{service.upperStartingCharacter(marker.response[0].date, true)}}</td>
                                                                          </tr>
                                                                          <tr *ngFor="let response of  marker.response[0].datas">
                                                                              <td class="sawasdee20" style="border-bottom-style: dotted;border-bottom: 1px solid #5f6368;padding-right: 20px;">{{service.upperStartingCharacter(response.formControl.label.toString(), true)}}</td>
                                                                              <td class="sawasdee20" style="border-bottom-style: dotted;border-left-style: dotted;border-bottom: 1px solid #5f6368;padding-left: 20px;border-left: 1px solid #5f6368;">{{service.upperStartingCharacter(response.data, true)}}</td>
                                                                          </tr>
                                                                        </tbody>
                                                                    </table>
                                                                 </div>
                                                            </div>
                                                        </agm-info-window>
                                                    </agm-marker>
                                                </agm-map>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div class="tile-stats" style="height: 248px;">
                                        <table class="pull-right">
                                            <td class="{{navAddress === 2 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navAddress = 2">
                                                <div class="headline2">Regions</div>
                                            </td>
                                            <td class="{{navAddress === 1 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navAddress = 1">
                                                <div class="headline2">Country</div>
                                            </td>
                                        </table>
                                        <span class="iconn" style="margin-right: 5px"><i class="fa fa-checkk"></i>
                                            <span>
                                                <span class="headline2">
                                                    {{incomingForm.getResponseDisplayTitle() + ' by locations'}}
                                                </span>
                                            </span>
                                        </span>
                                        <br><br>
                                        <div *ngFor="let time of durations">
                                            <div *ngIf="time.id === navTime">
                                                <div>
                                                    <app-piechart-chartjs
                                                            [labels]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'name')"
                                                            [label]="incomingForm.getResponseDisplayTitle()"
                                                            [limit]="10"
                                                            [type]="'pie'"
                                                            [height]="'275'"
                                                            [showLabels]="true"
                                                            [data]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'responses')"
                                                            [element]="'locationsElement'"
                                                            [color]="'blue'">
                                                    </app-piechart-chartjs>
                                                    <div style="position: absolute; top:50px; right: 2px" [hidden]='true'>
                                                        <div class="animated flipInY">
                                                            <div class="tile-stats" [ngStyle]="{'margin': '1px'}">
                                                                <table>
                                                                    <tr *ngFor="let legend of only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'name'); let incremental=index">
                                                                        <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                        <td class="headline2"> {{service.limitTo(legend, 8)}}</td>
                                                                        <td [ngStyle]="{'margin-left': '2px'}" class="headline2"> {{only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'responses')[incremental]}}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
<!--                                        <table [ngStyle]="{'width':'100%'}">-->
<!--                                            <td [ngStyle]="{'width':'50%'}">-->
<!--                                                <div class="hidden-sm hidden-xs hidden-md">-->
<!--                                                    <div *ngFor="let time of durations">-->
<!--                                                        <div *ngIf="time.id === navTime">-->
<!--                                                            <div>-->
<!--                                                                <app-piechart-chartjs-->
<!--                                                                        [limit]="10"-->
<!--                                                                        [element]="'locationsElement'"-->
<!--                                                                        [label]="incomingForm.getResponseDisplayTitle()"-->
<!--                                                                        [showLabels]="false"-->
<!--                                                                        [height]="'60'"-->
<!--                                                                        [type]="'doughnut'"-->
<!--                                                                        [color]="'blue'"-->
<!--                                                                        [labels]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'name')"-->
<!--                                                                        [data]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'responses')"-->
<!--                                                                        *ngIf="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses')), 'responses').length > 0"-->
<!--                                                                ></app-piechart-chartjs>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </td>-->
<!--                                            <td valign="top" [ngStyle]="{'width':'50%'}">-->
<!--                                                <div *ngFor="let time of durations">-->
<!--                                                    <div *ngIf="time.id === navTime">-->
<!--                                                        <table class="countries_list">-->
<!--                                                            <tbody>-->
<!--                                                            <tr *ngFor="let region of incomingReport.attributes.data.regions">-->
<!--                                                                <td class="headline2">{{region.name}}</td>-->
<!--                                                                <td class="fs15 fw700 text-right headline2">{{service.numberWithCommans(region[time.tm].responses)}}</td>-->
<!--                                                            </tr>-->
<!--                                                            </tbody>-->
<!--                                                        </table>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </td>-->
<!--                                        </table>-->
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div class="tile-stats" style="height: 260px">
                                        <span class="{{navTop5 === 3 ? 'selectedMatTab2' : ''}} headline2">Top 5</span>
                                        <table class="pull-right">
                                            <td class="{{navTop5 === 1 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navTop5 = 1">
                                                <div class="headline2">{{incomingForm.getRespondentAlias()}}</div>
                                            </td>
                                            <td class="{{navTop5 === 2 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navTop5 = 2">
                                                <div class="headline2">Branch</div>
                                            </td>
                                            <td class="{{navTop5 === 3 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navTop5 = 3">
                                                <div class="headline2">Teams</div>
                                            </td>
                                            <td class="{{navTop5 === 4 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navTop5 = 4">
                                                <div class="headline2">Regions</div>
                                            </td>
                                            <td class="{{navTop5 === 5 ? 'selectedMatTab2' : ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navTop5 = 5">
                                                <div class="headline2">Country</div>
                                            </td>
                                        </table>
                                        <div *ngIf="hiddenFlag === 1">
                                            <div *ngFor="let time of durations">
                                                <div *ngIf="time.id === navTime && incomingReport.attributes.data.users.length !== 0">
                                                    <dv>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(incomingReport.attributes.data.users, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(incomingReport.attributes.data.users, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 1 && navOverall !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.users, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.users, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'currency')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 1 && navCurrencies !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.users, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.users, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 1 && navProducts !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                    </dv>

                                                    <div>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(incomingReport.attributes.data.branches, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(incomingReport.attributes.data.branches, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 2"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.branches, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.branches, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'currency')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 2 && navCurrencies !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.branches, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.branches, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 2 && navProducts !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                    </div>


                                                    <div>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(incomingReport.attributes.data.teams, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(incomingReport.attributes.data.teams, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 3"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.teams, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.teams, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'currency')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 3 && navCurrencies !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.teams, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.teams, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 3 && navProducts !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                    </div>


                                                    <div>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(incomingReport.attributes.data.regions, time.tm, 'responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 4"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.regions, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.regions, 'questions', time.tm, 'name','currency') |sortBy: 'desc':'currency'| slice: 0 : 5), 'currency')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 4 && navCurrencies !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>

                                                        <app-bar-chartjs
                                                                [labels]="only((level(incomingReport.attributes.data.regions, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'name')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((level(incomingReport.attributes.data.regions, 'products', time.tm, 'name','responses') |sortBy: 'desc':'responses'| slice: 0 : 5), 'responses')"
                                                                [element]="'userTop5ResponsesElement'"
                                                                *ngIf="navTop5 === 4 && navProducts !== null"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 pull-left">-->
<!--                                    <div class="x_panel tile" style="height: 500px !important">-->
<!--                                        <div class="x_title">-->
<!--                                            <h2>-->
<!--                                                Device Usage-->
<!--                                            </h2>-->
<!--                                            <ul class="nav navbar-right panel_toolbox">-->
<!--                                                <li><a class="collapse-link"><i class="fa fa-chevron-up"></i></a>-->
<!--                                                </li>-->
<!--                                                <li><a class="close-link"><i class="fa fa-close"></i></a>-->
<!--                                                </li>-->
<!--                                            </ul>-->
<!--                                            <div class="clearfix"></div>-->
<!--                                        </div>-->
<!--                                        <div class="x_content">-->
<!--                                            <app-piechart [data]="{legend:getlabels(incomingReportValues.attributes.data.device_usage,'name'),-->
<!--                                              data: getValuesByLableAndValue(incomingReportValues.attributes.data.device_usage,'name','count')}">-->
<!--                                            </app-piechart>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="animated flipInY col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <div class="tile-stats" style="height: 260px">
                                        <span class="headline2">Recent</span>
                                        <table class="pull-right">
                                            <td class="{{navRecent === 1 ? 'selectedMatTab2': ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navRecent = 1">
                                                <div class="headline2">images</div>
                                            </td>
                                            <td class="{{navRecent === 2 ? 'selectedMatTab2': ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navRecent = 2">
                                                <div class="headline2">videos</div>
                                            </td>
                                            <td class="{{navRecent === 3 ? 'selectedMatTab2': ''}} cursor"
                                                style="margin-left: 5px; padding-right: 5px"
                                                (click)="navRecent = 3">
                                                <div class="headline2">{{incomingForm.getResponseDisplayTitle()}}</div>
                                            </td>
                                        </table><br><br><br>
                                        <div>
<!--                                        <div [innerHTML]="service.getImage(incomingReport.attributes.data.recent.images, 100,100) | safeUrl"></div>-->
                                            <div [hidden]="navRecent !== 1">
                                                <center>
                                                    <carousel [cellsToShow]=1 [height]="200" [width]="200" [cellWidth]="100">
                                                        <div class="carousel-cell" *ngFor="let image of incomingReport.attributes.data.recent.images">
                                                            <img style='width: 200px;max-height: 300px' src="{{image.data}}">
                                                        </div>
                                                    </carousel>
                                                </center>
                                            </div>
                                            <div *ngIf="navRecent === 3">
                                                <table class="countries_list" style="width:100%" *ngIf="incomingReport.attributes.data.recent.responses.length !== 0">
                                                    <thead>
                                                    <tr>
                                                        <th class="headline2" *ngFor="let response of (incomingReport.attributes.data.recent.responses.length === 0 ? [] : onlyProduct(incomingReport.attributes.data.recent.responses[0].datas))">
                                                        <span title="{{service.removeHtmlTags(response.formControl.label)}}"
                                                              [innerHTML]="service.limitTo(response.formControl.label.toString(), 60)">
                                                        </span>
                                                        </th>
                                                        <!--                                                    <th class="headline2">{{incomingFormValues.getRespondentAlias()}}</th>-->
                                                        <!--                                                <th class="headline2">Team</th>-->
                                                        <!--                                                <th class="headline2">Address</th>-->
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let response of incomingReport.attributes.data.recent.responses">
                                                        <td class="headline2" *ngFor="let resp of onlyProduct(response.datas)">
                                                            <center>
                                                                <div *ngIf="resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"> <span [innerHTML]="resp.data"></span></div>
                                                                <div *ngIf="resp.formControl.control.id === 2">{{resp.data}}</div>
                                                            </center>
                                                        </td>
                                                        <!--                                                    <td class="headline2">{{response.postedBy.name ? service.limitTo(response.postedBy.name, 10) : ''}}</td>-->
                                                        <!--                                                    <td class="headline2">{{response.postedBy.team ? service.limitTo(response.postedBy.team.name, 5) : ''}}</td>-->
                                                        <!--                                                    <td class="headline2 ">{{service.limitTo(response.address, 10)}}</td>-->
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                <div *ngFor="let customReport of incomingReport.attributes.data.custom_reports">
                                    <div *ngIf="customReport.result.status === 0">
                                        <div class="animated flipInY col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div class="tile-stats">
                                                <table>
                                                    <td class="selectedMatTab2 cursor" [ngStyle]="{'width':'100%', 'margin-left': '10px', 'padding-right': '50px'}">
                                                        <div class="headline2" title="{{customReport.title}}">{{service.limitTo(customReport.title, 20)}}</div>
                                                    </td>
                                                    <td class="selectedMatTab2 cursor">
                                                        <i class="fa fa-cog"
                                                              (click)="matMenuStatus = 1; onContextMenu($event, {})"
                                                              (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
<!--                                                          settings-->
                                                        </i>
                                                    </td>
                                                    <td class="selectedMatTab2">
                                                        <span class="cursor"
                                                              [ngStyle]="{'padding-left':'10px', 'padding-right':'10px'}"
                                                              (click)="matMenuStatus = 0; onContextMenu($event, {})"
                                                              (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
                                                            <i class="fa fa-angle-right"></i>
                                                        </span>
                                                    </td>
                                                </table>
                                                <div *ngFor="let time of durations">
                                                    <div *ngIf="time.id === navTime">
                                                        <div *ngIf="customReport.chart === 0">
                                                            <app-piechart-chartjs
                                                                    [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                    [label]="incomingForm.getResponseDisplayTitle()"
                                                                    [limit]="10"
                                                                    [type]="'pie'"
                                                                    [height]="'85'"
                                                                    [showLabels]="true"
                                                                    [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                    [element]="customReport.id + 'customReportStatus0'"
                                                                    *ngIf="hiddenFlag === 1"
                                                                    [color]="'blue'">
                                                            </app-piechart-chartjs>
                                                            <div style="position: absolute; top:50px; right: 5px">
                                                                <div class="animated flipInY">
                                                                    <div class="tile-stats" [ngStyle]="{'margin': '2px'}">
                                                                        <table>
                                                                            <tr *ngFor="let legend of only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label'); let incremental=index">
                                                                                <td><div class="round" [ngStyle]="{'background': service.pieChartColors()[incremental]}"></div></td>
                                                                                <td class="headline2"> {{legend}}</td>
                                                                                <td [ngStyle]="{'margin-left': '5px'}" class="headline2"> {{only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')[incremental]}}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'line'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                [element]="customReport.id + 'customReportStatus0'"
                                                                *ngIf="hiddenFlag === 1 && customReport.chart === 2"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'bar'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                [element]="customReport.id + 'customReportStatus0'"
                                                                *ngIf="hiddenFlag === 1 && customReport.chart === 4"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                        <app-bar-chartjs
                                                                [labels]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'label')"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'bar'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="only((extract(customReport.result.data[0].options, time.tm, 'responses')), 'responses')"
                                                                [element]="customReport.id + 'customReportStatus0'"
                                                                *ngIf="hiddenFlag === 1 && customReport.chart === 1"
                                                                [color]="'blue'">
                                                        </app-bar-chartjs>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="customReport.result.status === 1">
                                        <div class="animated flipInY col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div class="tile-stats">
                                                <table>
                                                    <td class="selectedMatTab cursor" [ngStyle]="{'width':'100%','margin-left': '10px', 'padding-right': '50px'}">
                                                        <div class="headline2">{{service.limitTo(customReport.title, 20)}}</div>
                                                    </td>
                                                    <td class="selectedMatTab">
                                                        <span class="cursor"
                                                              (click)="onContextMenu($event, {})"
                                                              (mousedown)="incomingSelectedCustomReport.setAttributes(customReport)">
                                                            <i class="material-icons">expand_more</i>
                                                        </span>
                                                    </td>
                                                </table>
                                                <div *ngFor="let time of durations">
                                                    <div *ngIf="time.id === navTime">
                                                        <app-mitiple-user-activity
                                                                [legends]="only(customReport.result.data.data, 'label')"
                                                                [options]="customReport.result.data.options"
                                                                [label]="incomingForm.getResponseDisplayTitle()"
                                                                [limit]="10"
                                                                [type]="'bar'"
                                                                [showYAxis]="'false'"
                                                                [height]="'175'"
                                                                [data]="customReport.result.data.data"
                                                                [element]="customReport.id + 'customReportStatus1'"
                                                                *ngIf="hiddenFlag === 1"
                                                                [color]="'blue'">
                                                        </app-mitiple-user-activity>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="animated flipInY col-lg-12 col-md-12 col-sm-6 col-xs-12" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
                                    <div class="tile-stats" style="min-height: auto">
                                        <div *ngFor="let time of durations">
                                            <div *ngIf="time.id === navTime">
                                                <table class="pull-right">
                                                    <td class="{{navRegion === regionIndexOf ? 'selectedMatTab': ''}} cursor"
                                                        style="margin-left: 10px; padding-right: 50px"
                                                        (click)="navRegion = regionIndexOf; incomingSelectedRegion.setAttributes(region); afterSelectRegion()"
                                                        *ngFor="let region of incomingReport.attributes.data.regions; let regionIndexOf = index">
                                                        <div class="headline">{{region.name}}</div>
                                                        <div class="counter" *ngIf="navOverall !== null">{{service.numberWithCommans(region[time.tm].responses)}}</div>
                                                        <div *ngIf="navCurrencies !== null">
                                                            <div *ngFor="let question of getQuestions(region.questions); let qenstionalIndex = index">
                                                                <div class="counter" *ngIf="navCurrencies === qenstionalIndex">{{service.numberWithCommans(question[time.tm].currency)}}</div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="navProducts !== null">
                                                            <div *ngFor="let product of region.products; let productIndex = index">
                                                                <div class="counter" *ngIf="navProducts === productIndex">{{service.numberWithCommans(product[time.tm].responses)}}</div>
                                                            </div>
                                                        </div>
<!--                                                    <div style="margin-left: 15px"><i class="fa fa-long-arrow-down text-primary" *ngIf="navRegion === regionIndexOf"></i></div>-->
                                                    </td>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;"><br><br>
                                            <div *ngFor="let time of durations">
                                                <div *ngIf="time.id === navTime">
                                                    <div *ngFor="let region of (incomingReport.attributes.data.regions | jsonPropertyFilter: incomingSelectedRegion.getId() : 'id')">
                                                        <div *ngIf="navOverall !== null">
                                                            <app-bar-chartjs [labels]="only(region[time.tm][region[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], region[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                             [label]="incomingForm.getResponseDisplayTitle()"
                                                                             [type]="'line'"
                                                                             [height]="'175'"
                                                                             [data]="only(region[time.tm][region[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                             [element]="'regionOverallElement'"
                                                                             [color]="'blue'">
                                                            </app-bar-chartjs>
                                                        </div>
                                                        <div *ngIf="navCurrencies !== null">
                                                            <div *ngFor="let question of getQuestions(region.questions); let questionalIndex = index">
                                                                <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                 [label]="question.label"
                                                                                 [type]="'line'"
                                                                                 [limit]="100"
                                                                                 [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                 [element]="'regionOverallElement'"
                                                                                 [color]="'blue'"
                                                                                 *ngIf="navCurrencies === questionalIndex" >
                                                                </app-bar-chartjs>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="navProducts !== null">
                                                            <div *ngFor="let product of region.products; let productIndex = index">
                                                                <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                 [label]="product.label"
                                                                                 [type]="'line'"
                                                                                 [limit]="100"
                                                                                 [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                 [element]="'regionOverallElement'"
                                                                                 [color]="'blue'"
                                                                                 *ngIf="navProducts === productIndex" >
                                                                </app-bar-chartjs>
                                                            </div>
                                                        </div>
<!--                                                        <app-bar-chartjs [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"-->
<!--                                                                         [label]="incomingForm.getResponseDisplayTitle()"-->
<!--                                                                         [type]="'line'"-->
<!--                                                                         [limit]="100"-->
<!--                                                                         [data]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'progress')"-->
<!--                                                                         [element]="'overallElement'"-->
<!--                                                                         [color]="'blue'"-->
<!--                                                                         *ngIf="navOverall === 0 && navTime === time.id && navFunc === 3"-->
<!--                                                        >-->
<!--                                                        </app-bar-chartjs>-->
<!--                                                        <app-user-activity-chartjs-->
<!--                                                                [labels]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"-->
<!--                                                                [label]="incomingForm.getResponseDisplayTitle()"-->
<!--                                                                [label2]="'kpi'"-->
<!--                                                                [fill1]="true"-->
<!--                                                                [type]="'line'"-->
<!--                                                                [height]="'175'"-->
<!--                                                                [limit]="100"-->
<!--                                                                [d1]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"-->
<!--                                                                [d2]="only(incomingReport.attributes.data[time.tm][incomingReport.attributes.data[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"-->
<!--                                                                [element]="'overallElement'"-->
<!--                                                                [color]="'blue'"-->
<!--                                                                *ngIf="navOverall === 0 && navTime === time.id && navFunc === 4">-->
<!--                                                        </app-user-activity-chartjs>-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 50px">
                                                <table class="pull-right">
                                                    <td style="width: 100px">&nbsp;</td>
                                                    <!--                                                <td class="{{navRegionActivity === 1 ? 'selectedMatTab3': ''}} cursor"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px"-->
<!--                                                        (click)="navRegionActivity = 1">-->
<!--                                                        <div class="headline2">{{incomingForm.getResponseDisplayTitle()}}</div>-->
<!--                                                </td>-->
                                                    <td class="{{navRegionSide === 2 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navRegionSide = 2">
                                                        <div class="headline2">Teams</div>
                                                    </td>
                                                    <td class="{{navRegionSide === 3 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navRegionSide = 3">
                                                        <div class="headline2">Districts</div>
                                                    </td>
                                                    <td class="{{navRegionSide === 4 ? 'selectedMatTab2': ''}} cursor"
                                                        style="margin-left: 5px; padding-right: 5px"
                                                        (click)="navRegionSide = 4">
                                                        <div class="headline2">Branch</div>
                                                    </td>
<!--                                                <td class="{{navRegionActivity === 5 ? 'selectedMatTab3': ''}} cursor"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px"-->
<!--                                                        (click)="navRegionActivity = 5">-->
<!--                                                        <div class="headline2">{{incomingForm.getRespondentAlias()}}</div>-->
<!--                                                </td>-->
                                                </table>
<!--                                            <table class="pull-right">-->
<!--                                                    <td *ngFor="let time of timeReferences" class="{{navTime === time.id ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navTime = time.id"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">{{time.name}}</div>-->
<!--                                                    </td>-->
<!--                                            </table>-->
                                                <div>
                                                    <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12" *ngIf="navRegionSide === 2">
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="time.id === navTime">
                                                                <div *ngFor="let team of (incomingReport.attributes.data.teams | jsonPropertyFilter: incomingSelectedTeam.getId() : 'id')">
                                                                    <div *ngIf="navOverall !== null">
                                                                        <app-bar-chartjs [labels]="only(team[time.tm][team[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], team[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                         [label]="incomingForm.getResponseDisplayTitle()"
                                                                                         [type]="'line'"
                                                                                         [height]="'175'"
                                                                                         [data]="only(team[time.tm][team[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                         [element]="'teamOverallElement'"
                                                                                         [color]="'blue'">
                                                                        </app-bar-chartjs>
                                                                    </div>
                                                                    <div *ngIf="navCurrencies !== null">
                                                                        <div *ngFor="let question of getQuestions(team.questions); let questionalIndex = index">
                                                                            <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="question.label"
                                                                                             [type]="'line'"
                                                                                             [limit]="100"
                                                                                             [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                             [element]="'teamOverallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="navCurrencies === questionalIndex" >
                                                                            </app-bar-chartjs>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="navProducts !== null">
                                                                        <div *ngFor="let product of team.products; let productIndex = index">
                                                                            <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                             [label]="product.label"
                                                                                             [type]="'line'"
                                                                                             [limit]="100"
                                                                                             [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                             [element]="'teamOverallElement'"
                                                                                             [color]="'blue'"
                                                                                             *ngIf="navProducts === productIndex" >
                                                                            </app-bar-chartjs>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12"*ngIf="navRegionSide === 2">
                                                        <div class="">
                                                            <div *ngFor="let time of durations">
                                                                <div *ngIf="time.id === navTime">
<!--                                                              <table class="pull-right">-->
<!--                                                                        <td style="width: 100px">&nbsp;</td>-->
<!--                                                                        <td class="cursor"-->
<!--                                                                            style="margin-left: 5px; padding-right: 5px">-->
<!--                                                                            <div class="counter">&nbsp;</div>-->
<!--                                                                            <div class="selectedMatTab2 headline">{{incomingForm.getRespondentAlias()}}</div>-->
<!--                                                                        </td>-->
<!--                                                                </table>-->
                                                                    <table class="pull-right">
                                                                        <td class="{{navTeam === teamIndex ? 'selectedMatTab3': ''}} cursor"
                                                                            style="margin-left: 5px; padding-right: 5px"
                                                                            (click)="navTeam = teamIndex; incomingSelectedTeam.setAttributes(team)"
                                                                            *ngFor="let team of getTeamsByRegionId(incomingSelectedRegion.getId()); let teamIndex = index">
                                                                            <div class="counter" *ngIf="navOverall !== null">{{service.numberWithCommans(team[time.tm].responses)}}</div>
                                                                            <div class="headline">{{service.limitTo(team.name, 20)}}</div>
                                                                            <div *ngIf="navCurrencies !== null">
                                                                                <div *ngFor="let question of getQuestions(team.questions); let qenstionalIndex = index">
                                                                                    <div class="headline" *ngIf="navCurrencies === qenstionalIndex">{{service.numberWithCommans(question[time.tm].currency)}}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="navProducts !== null">
                                                                                <div *ngFor="let product of team.products; let productIndex = index">
                                                                                    <div class="headline" *ngIf="navProducts === productIndex">{{service.numberWithCommans(product[time.tm].responses)}}</div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="navRegionSide === 2">
                                                        <div class="">
                                                            <div *ngFor="let time of durations">
                                                                <div *ngIf="time.id === navTime">
                                                                    <div *ngFor="let user of (incomingReport.attributes.data.users | jsonPropertyFilter: '' + incomingSelectedUser.getId():'id'); let userIndex = index">
                                                                        <div *ngIf="navOverall !== null">
                                                                            <app-user-activity-chartjs
                                                                                    [labels]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], user[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                    [label]="incomingForm.getResponseDisplayTitle()"
                                                                                    [label2]="'kpi'"
                                                                                    [fill1]="true"
                                                                                    [type]="'line'"
                                                                                    [height]="'175'"
                                                                                    [limit]="100"
                                                                                    [d1]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                    [d2]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                    [element]="'userOverallElement'"
                                                                                    [color]="'blue'"
                                                                                    *ngIf="navFunc === 4">
                                                                            </app-user-activity-chartjs>
                                                                            <app-horizontal-bar-chartjs
                                                                                    [labels]="only(user[time.tm][time.arr], time.label)"
                                                                                    [label]="incomingForm.getResponseDisplayTitle()"
                                                                                    [limit]="50"
                                                                                    [type]="'horizontalBar'"
                                                                                    [showYAxis]="'true'"
                                                                                    [height]="'175'"
                                                                                    [data]="only(user[time.tm][time.arr], 'progress')"
                                                                                    [element]="'userOverallElement'"
                                                                                    [color]="'blue'"
                                                                                    *ngIf="navFunc === 3"
                                                                            >
                                                                            </app-horizontal-bar-chartjs>
                                                                            <app-bar-chartjs     [labels]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], user[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="incomingForm.getResponseDisplayTitle()"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [height]="'175'"
                                                                                                 [data]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'target')"
                                                                                                 [element]="'userOverallElement'"
                                                                                                 [color]="'blue'"
                                                                                                 *ngIf="navFunc === 2"
                                                                            >
                                                                            </app-bar-chartjs>
                                                                            <app-bar-chartjs     [labels]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], user[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="incomingForm.getResponseDisplayTitle()"
                                                                                                 [type]="'line'"
                                                                                                 [height]="'175'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(user[time.tm][user[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                                 [element]="'userOverallElement'"
                                                                                                 [color]="'blue'"
                                                                                                 *ngIf="navFunc === 1"
                                                                            >
                                                                            </app-bar-chartjs>
                                                                        </div>
                                                                        <div *ngIf="navCurrencies !== null">
                                                                            <div *ngFor="let question of this.getQuestions(user.questions); let questionalIndex = index">
                                                                                <app-bar-chartjs [labels]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], question[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="question.label"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(question[time.tm][question[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'currency')"
                                                                                                 [element]="'userOverallElement'"
                                                                                                 [color]="'blue'"
                                                                                                 *ngIf="navCurrencies === questionalIndex" >
                                                                                </app-bar-chartjs>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="navProducts !== null">
                                                                            <div *ngFor="let product of user.products; let productIndex = index">
                                                                                <app-bar-chartjs [labels]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], product[time.tm].hasOwnProperty('hour') ? 'hour' : time.label)"
                                                                                                 [label]="product.label"
                                                                                                 [type]="'line'"
                                                                                                 [limit]="100"
                                                                                                 [data]="only(product[time.tm][product[time.tm].hasOwnProperty('hour') ? 'hours' : time.arr], 'responses')"
                                                                                                 [element]="'userOverallElement'"
                                                                                                 [color]="'blue'"
                                                                                                 *ngIf="navProducts === productIndex" >
                                                                                </app-bar-chartjs>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"*ngIf="navRegionSide === 2">
                                                        <div *ngFor="let time of durations">
                                                            <div *ngIf="time.id === navTime">
                                                                <table class="pull-right">
                                                                    <td class="{{navUser === userIndex ? 'selectedMatTab3': ''}} cursor"
                                                                        style="margin-left: 5px; padding-right: 5px"
                                                                        (click)="navUser = userIndex; incomingSelectedUser.setAttributes(user)"
                                                                        *ngFor="let user of (incomingReport.attributes.data.users | jsonPropertyFilter: '' + incomingSelectedTeam.getId():'team_id'); let userIndex = index">
                                                                        <div class="counter" *ngIf="navOverall !== null">{{service.numberWithCommans(user[time.tm].responses)}}</div>
                                                                        <div *ngIf="navCurrencies !== null">
                                                                            <div *ngFor="let question of this.getQuestions(user.questions); let qenstionalIndex = index">
                                                                                <div class="counter" *ngIf="navCurrencies === qenstionalIndex">{{service.numberWithCommans(question[time.tm].currency)}}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="navProducts !== null">
                                                                            <div *ngFor="let product of user.products; let productIndex = index">
                                                                                <div class="counter" *ngIf="navProducts === productIndex">{{service.numberWithCommans(product[time.tm].responses)}}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="headline">{{service.limitTo(user.name, 10)}}</div>
                                                                    </td>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
<!--                                <div class="animated flipInY col-lg-12 col-md-12 col-sm-6 col-xs-12" *ngIf="navRegionSide === 2" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">-->
<!--                                    <div class="tile-stats">-->
<!--                                        <div class="">-->
<!--                                            <br><br>-->
<!--
<!--                                            <div *ngIf="navOverall !== null">-->
<!--                                                <table class="pull-right">-->
<!--                                                    <td class="{{navFunc === 4 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navFunc = 4"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2"> {{incomingForm.getResponseDisplayTitle()}} VS Kpi</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navFunc === 3 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navFunc = 3"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">progress in %</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navFunc === 2 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navFunc = 2"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">kpi</div>-->
<!--                                                    </td>-->
<!--                                                    <td class="{{navFunc === 1 ? 'selectedMatTab3' : ''}} cursor"-->
<!--                                                        (click)="navFunc = 1"-->
<!--                                                        style="margin-left: 5px; padding-right: 5px">-->
<!--                                                        <div class="headline2">{{incomingForm.getResponseDisplayTitle()}}</div>-->
<!--                                                    </td>-->
<!--                                                </table>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item *ngIf="matMenuStatus === 0" (click)="exportCustomReport({})">Download</button>
        <div *ngIf="matMenuStatus === 1">
            <div style="min-width: 400px; margin: 5px">
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile *ngFor="let chart of charts; let chartIncremental = index">
                        <img class="cursor"
                             src="{{chart.icon}}"
                             (click)="incomingSelectedCustomReport.setChart(chart.id)"
                             [ngStyle]="{'width': 'max-width: 100px', 'max-height':'100px', 'margin': '5px'}" />
                    </mat-grid-tile>
                </mat-grid-list>cursor
            </div>
        </div>
        <div *ngIf="matMenuStatus === 2"
             class="sawasdee18"
             [ngStyle]="{'color': '#000', 'padding':'0px'}">
            <div style="min-width: 400px; margin: 5px">
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile class="cursor"  (click)="exportExcel = true; openDateMenu()">Export data to excel CSV for customized, in-depth analysis</mat-grid-tile>
                    <mat-grid-tile class="cursor"  (click)="gallery()">{{incomingForm.getResponseDisplayTitle()}} gallery</mat-grid-tile>
                    <mat-grid-tile class="cursor"  (click)="getResponses()">{{service.upperStartingCharacter(incomingForm.getResponseDisplayTitle(), true)}} in a table view</mat-grid-tile>
                    <mat-grid-tile class="cursor"  (click)="outletEngagementAndSales()"> Outlet engagement and sales report</mat-grid-tile>
                    <mat-grid-tile class="cursor"  (click)="dateRangeReport()"> Date range report</mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    </ng-template>
</mat-menu>

