import { Component, OnInit } from '@angular/core';
import {BroadcastChannelService} from '../../../broadcast-channel.service';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent implements OnInit {

  constructor(private broadcastChannel: BroadcastChannelService) { }

  ngOnInit(): void {
  }

  next(){
   this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
  }

}
