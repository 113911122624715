import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from "@angular/material/menu";
import {ResponseService} from "../forms/responses/data/response.service";

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
    @Input() app: any;
    @Output() onOpenDialog = new EventEmitter();
    @Output() extractEventEmitter = new EventEmitter();
    @ViewChild(MatMenuTrigger) contextMenu: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    constructor(public  dialog: MatDialog,
                public emitter: BroadcastChannelService,
                public responseServce: ResponseService,
                public broadcastChannel: BroadcastChannelService) {
        this.contextMenu = MatMenuTrigger;
    }

    ngOnInit(): void {
    }

    openDialog(){
      this.onOpenDialog.emit();
      this.broadcastChannel.emitNavChangeEvent('add');
    }

    sendInvitations(){
        this.responseServce.isDialogOpened = false;
        this.broadcastChannel.emitNavChangeEvent({action: '650e4e574f651'});
    }

    onContextMenu(event: MouseEvent, inspector: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'item': inspector };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    import(){
        this.broadcastChannel.emitNavChangeEvent({action: 'import'});
    }

    sendWhatsappChatboot(){
        this.responseServce.isDialogOpened = false;
        this.broadcastChannel.emitNavChangeEvent({action: '6547cc106038f'});
    }


    handledaterange() {

    }

    openDateMenu(){this.broadcastChannel.emitNavChangeEvent('openDateMenu');}

    extract(){
      this.broadcastChannel.emitNavChangeEvent('extract');
    }



}

