<table id="datatable" class="table table-striped table-bordered" style="width:100%">
    <thead>
    <tr>
        <th class="mdl-data-table__cell--non-numeric headline2">Business Name</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Status</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Email</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Open at</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Closed at</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Owner</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Frame</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Campany</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Photo</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
        <th class="text-right">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let outlet  of (flag.includes('all') ? (service.app.data.outlets.data | jsonFilter:searchCntrl) : (service.app.data.outlets.trashed.data | jsonFilter:searchCntrl))"
        (contextmenu)="incomingOutletSelected.setAttributes(outlet);onContextMenu($event, outlet)"
        (mousedown)="incomingOutletSelected.setAttributes(outlet)">
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.category.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.phone}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.email}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.opened_at}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.closed_at}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">{{outlet.owner}}</td>
        <td class="mdl-data-table__cell--non-numeric headline">
            <center><img src="{{outlet.frame}}" [ngStyle]="{'width':'400px','height':'400px'}" /> </center>
        </td>
        <!--<td class="mdl-data-table__cell&#45;&#45;non-numeric headline">{{getUser().phone}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline">{{getUser().email}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline">{{getUser().location}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline">{{getCampanyByEmployee(employee).name}}</td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline"><img class="img-thumbnail avatar"    src="{{getUser().avatar}}" *ngIf="getUser().avatar" /></td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline" [innerHTML]="employee.description"></td>-->
<!--        <td class="mdl-data-table__cell&#45;&#45;non-numeric headline">-->
<!--            <div class="dropdown pull-right">-->
<!--                <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>-->
<!--                    <span class="caret"></span>-->
<!--                </button>-->
<!--                <ul class="dropdown-menu">-->
<!--                    <li mat-menu-item *ngIf="flag === 'all'"     (click)="handlePassword(selectedEmployee)">Change Password</li>-->
<!--                    <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedEmployee)">Edit</li>-->
<!--                    <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedEmployee)">Delete</li>-->
<!--                    <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedEmployee)">Restore</li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div style="visibility: hidden; position: fixed"-->
<!--                 [style.left]="contextMenuPosition.x"-->
<!--                 [style.top]="contextMenuPosition.y"-->
<!--                 [matMenuTriggerFor]="contextMenu">-->
<!--            </div>-->
<!--            <mat-menu #contextMenu="matMenu">-->
<!--                <ng-template matMenuContent let-item="inspector">-->
<!--                    <button mat-menu-item *ngIf="flag === 'all'"     (click)="handlePassword(selectedEmployee)">Change Password</button>-->
<!--                    <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedEmployee)">Edit</button>-->
<!--                    <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedEmployee)">Delete</button>-->
<!--                    <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedEmployee)">Restore</button>-->
<!--                </ng-template>-->
<!--            </mat-menu>-->
<!--        </td>-->
    </tr>
    </tbody>
</table>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item *ngIf="flag === 'all'"     (click)="createFrame()">Generate Frame</button>
        <button mat-menu-item *ngIf="flag === 'all'"     (click)="openViewDialog()">View</button>
        <button mat-menu-item *ngIf="flag === 'all'"     (click)="sendQrcodeFrameThroughtWhatsapp()">Change Password</button>
    </ng-template>
</mat-menu>


