<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt"
             [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                </div>
                <div (contextmenu)="onContextMenu($event, {})">
                    <ngx-qrcode-styling
                            [ngStyle]="{'position':'fixed','left':((this.service.getWidth()-300)/2)+'px','top':((this.service.getHeight()-300)/2)+'px'}"
                            #qrcode
                            [config]="config"
                            [type]="'canvas'"
                            [shape]="'square'"
                            [width]="300"
                            [height]="300"
                            [margin]="5"
                            [data]="config.data"
                            *ngIf="config.data">
                    </ngx-qrcode-styling>
                    <div id="qrcode"
                         [ngStyle]="{'width':'300px','height':'300px','position':'fixed','left':((this.service.getWidth()-300)/2)+'px','top':((this.service.getHeight()-300)/2)+'px'}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
<!--  [image]="this.incomingSettingsForm.getLogoDataUrl()"-->
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="download()">Download</button>
    </ng-template>
</mat-menu>