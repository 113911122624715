import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-channel-payments',
  templateUrl: './channel-payments.component.html',
  styleUrls: ['./channel-payments.component.scss']
})
export class ChannelPaymentsComponent implements OnInit {

  constructor(public service: AppService) { }

  ngOnInit(): void {
  }

}
