import { Component, OnInit } from '@angular/core';
import {AmbassadorsService} from '../ambassadors.service';

@Component({
  selector: 'app-tabs-ambassadors',
  templateUrl: './tabs-ambassadors.component.html',
  styleUrls: ['./tabs-ambassadors.component.scss']
})
export class TabsAmbassadorsComponent implements OnInit {

  ambassadors: any;
  constructor(private ambassadorsService: AmbassadorsService) {
  }

  ngOnInit(): void {
    this.ambassadors = this.ambassadorsService.getData();
  }


}
