import { Injectable } from '@angular/core';
import {Parameters} from "../parameters";
import {AppService} from "../app.service";
import {UserService} from "../users/user.service";

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    isDialogOpened: any = false;
    selected: any = {};
    constructor(private service: AppService, private userService: UserService) {

    }

    getSelected(){return this.selected; }
    setSelected(selected: any){
        this.selected = selected;
    }

    addDelivery(outgoingRequest: Parameters, call: any){
        this.service.httpService('POST', this.service.app.routes.orders.delivery.add,
            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (output: any) => {
                call(output);
            }, (error: any) => {
                call(null);
            });
    }

    assignDeliveryUser(params: any, call: any){
        let outgoingRequest: any = new Parameters();
        outgoingRequest.setAttributes(params);
        this.service.httpService('POST', this.service.app.routes.entities.add,
            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (deliveryUser: any) => {
                let incomingEntity: any = new Parameters();
                incomingEntity.setAttributes(deliveryUser);
                outgoingRequest.setAttributes({});
                outgoingRequest.setId(incomingEntity.getEntity2());
                this.userService.search_(outgoingRequest, (user: any)=> {
                    if(user !== null) call(user); else call(null);
                });
            }, (error: any) => {
                call(null);
            });
    }


    tracking(outgoingRequest: Parameters, call: any){
        this.service.httpService('POST', this.service.app.routes.orders.tracking,
            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (output: any) => {
                call(output);
            }, (error: any) => {
                call(null);
            });
    }
}
