<div class="containerRelative"
     [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">
    <div class="centered mitra">
        <mat-spinner
                class=""
                color='warn'
                [diameter]="40">
        </mat-spinner>
        wait takes a minutes..
    </div>
</div>
