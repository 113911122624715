import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {FormService} from '../form.service';
import * as cryptoJS from 'crypto-js';
import {DialogFormsComponent} from "../dialog-forms.component";
import {DialogInviteFormsComponent} from "../invitees/dialog-invite-forms.component";
import {CampaignService} from "../../campaigns/campaign.service";
import {ClientService} from "../../clients/client.service";
import {CampaignsComponent} from "../../campaigns/campaigns.component";
@Component({
  selector: 'app-table-forms',
  templateUrl: './table-forms.component.html',
  styleUrls: ['./table-forms.component.scss']
})
export class TableFormsComponent implements OnInit, OnDestroy {

  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedForm: any;
  forms: any;
  incomingSelectedForm: any;
  subscription: any;
  searchCntrl: any;
  teams: any;
  conversionEncryptOutput: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public campaignService: CampaignService,
              public clientService: ClientService,
              private router: Router,
              public formService: FormService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.forms = this.formService.getData();
    this.incomingSelectedForm = new Parameters();
    this.incomingSelectedForm.setAttributes(this.formService.getSelected());
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('searchText') === true)
            this.searchCntrl = item.searchText;
        }else if(item.toString().includes('select')){
          if(item.select.toString().includes('campaign')){
            const dialogRef = this.dialog.open(CampaignsComponent, {
              disableClose: false,
              width: '100%',
              height: '99%',
            });
          }
        }else if(item.toString().includes('create')){
          this.formService.openDialog({});
        }else if(item.toString().toLowerCase().includes('assign respondent')){
          this.formService.manageRespondents(this.formService.getFormById(this.formService.formSelectedId));
        }
    });
  }



  handleDashboard(form: any){
    this.formService.setSelected(this.selectedForm);
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.router.navigateByUrl('/app/forms/'  + this.incomingSelectedForm.attributes.id + '/dashboard');
  }

  handleRespondents(form: any){
    this.formService.manageRespondents(form);
  }

  handleInvitees(form: any){
    this.formService.setSelected(form);
    const dialogRef = this.dialog.open(DialogInviteFormsComponent, { disableClose: false,  data  : {params: {}},  width : '55%', height: '100%',});
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  handleDestroy(inspector: any){
    this.incomingSelectedForm = new Parameters();
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.dialog.open(DeleteDialogComponent, {
      data   : {
        data : this.forms,
        model: this.selectedForm,
        title: this.incomingSelectedForm.getName(),
        url  : '/forms/' + parseFloat(this.incomingSelectedForm.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedForm.getId(),
      },
      width  : 'auto',
    });
  }

  getCampaignByForm(form: any){
    let campaign: any = this.campaignService.getCampaignById(form.campaign_id);
    let client: any = this.clientService.getClientById(campaign.client_id);
    campaign = Object.assign(campaign, {client: client});
    form = Object.assign(form, {campaign: campaign});
    return campaign;
  }


  getClientByCampaign(campaign: any){
    return this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
  }

  handleUpdate(inspector: any){
    this.incomingSelectedForm = new Parameters();
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.formService.setSelected(this.incomingSelectedForm.getAttributes());
    this.formService.openDialog({flag: 3});
  }

  handleRestore(inspector: any){
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.service.restore('/forms/' + parseFloat(this.incomingSelectedForm.getId()) + '/restore',
    this.forms, 'id', parseFloat(this.incomingSelectedForm.getId()), this.selectedForm);
  }

  handleSuggestions(form: any){
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.formService.setSelected(this.selectedForm);
    this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.selectedForm.id) + '/respond');
    this.conversionEncryptOutput = CryptoJS.AES.encrypt((this.selectedForm.id).toString(), this.app.settings.encPassword);
    this.conversionEncryptOutput = this.conversionEncryptOutput.toString().includes('/') ? this.conversionEncryptOutput.toString().replace(new RegExp('/', 'g'), this.app.settings.replaceCharacter) : this.conversionEncryptOutput;
    this.conversionEncryptOutput = this.conversionEncryptOutput.toString().replaceAll('/', this.app.settings.replaceCharacter);
  }


  handleResponses(form: any){
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.formService.setSelected(this.selectedForm);
    this.router.navigateByUrl('/app/forms/' + this.selectedForm.id + '/responses');
  }

  handleNavigations(form: any){
    this.incomingSelectedForm.setAttributes(this.selectedForm);
    this.formService.setSelected(this.selectedForm);
    this.router.navigateByUrl('/forms/' + this.selectedForm.id + '/navigations');
  }

  openDialog(inspector: any){
    this.formService.setSelected(this.incomingSelectedForm.getAttributes());
  //this.inspectorsComponent.openDialog();
  }


  showMessage(message: any) {}

}
