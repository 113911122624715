<a class="pull-left"><h4>{{keys === 0 ? 'Personnel ' : incomingSelectedUser.attributes.name}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
    <mat-list>
        <mat-list-item>
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Name *</mat-label>
                <input matInput type="text"  formControlName="name">
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Email *</mat-label>
            <input matInput type="text"  formControlName="email" [autocomplete]="''">
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Phone *</mat-label>
            <input matInput type="text"  formControlName="phone">
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item *ngIf="service.app.data.user.role.id !== 14 && service.app.data.user.role.id !== 8">
            <span class="" mat-line>
               <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Role *</mat-label>
                    <input matInput
                           #roleElement
                           (input)="onChangeRoleText($event)"
                           type="text"
                           [formControl] = "roleControl"
                           [matAutocomplete] = "optional">
                    <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</i>
                    <mat-autocomplete #optional = "matAutocomplete"
                                      (optionSelected)="onRoleSelectionChanged($event)"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let role of filteredRoles | async; let roleOf = index"
                                    [value]= "role">
                            {{role.display_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item [hidden]="true" *ngIf="keys === 0">
            <span class="" mat-line>
              <mat-form-field  class="example-full-width">
                <mat-label>Password *</mat-label>
                <input matInput type="password"  formControlName="password" autocomplete="false">
              </mat-form-field>
            </span>
        </mat-list-item>
        <mat-list-item>
            <span class="" mat-line>
              <small>Photo</small>
              <div class="upload-box">
                  <div class="hold">
                      <a href="">Maximum file size 20 MB <img src="{{photo}}" class="img-circle" width="40" height="40" alt="" *ngIf="photo"></a>
                      <span class="btn-file"> Browse File
                          <input type="file"
                                 (change)="handleFileInput($event)"
                          >
                      </span>
                  </div>
              </div>
            </span>
        </mat-list-item>
        <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address *</mat-label>
            <input type="text"
                   id="address"
                   matInput
                   formControlName="location"
                   placeholder=""
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onTextChangeAddress($event)"
                   matGoogleMapsAutocomplete
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                   (onLocationSelected)="onLocationSelected($event)"/>
          </mat-form-field>
        </span>
        </mat-list-item>
        <mat-list-item>
    <span class="" mat-line>
         <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
          </mat-form-field>
    </span>
        </mat-list-item>
    </mat-list>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <center>
                <div class="example-button-row">
                    <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Done'}}"/>
                </div>
            </center>
        </div>
    </div>
</form>
