import {Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {ResponseService} from "../../forms/responses/data/response.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../app.service";
import {NotifierService} from "angular-notifier";
import {FormService} from "../../forms/form.service";
import {Parameters} from "../../parameters";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: 'app-chatbott-responses',
  templateUrl: './chatbott-responses.component.html',
  styleUrls: ['./chatbott-responses.component.scss']
})
export class ChatbottResponsesComponent implements OnInit {

  constructor(private responseService: ResponseService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public zone: NgZone,
              public reponseService: ResponseService,
              public service: AppService,
              private notifier: NotifierService,
              private formService: FormService) {
  }

  ngOnInit(): void {
  }

}
