<div layout="row">
  <div class="col-lg-2 col-md-2"></div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
      <div class="panel-bodyy">
        <div> <app-label-create-control [question]="question"></app-label-create-control></div>
        <div>
            <ng-container *ngIf="this.service.parseFloat(incomingSettings.getIsDistributor()) === 1;else description">
                {{question.result | json}}
                <mat-form-field class="example-full-width" appearance="fill">
                    <input  class="sawasdee26"
                            matInput
                            placeholder="{{question.placeholder}}"
                            autocomplete="off"
                            #matAutocompleteTrigger="matAutocompleteTrigger"
                            [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                            (input)="onDistributorInputTextChange($event)"
                            (keydown)='validateTextLength($event)'
                            (keyup.enter)="onMouseEnter(element.value)"
                            [autofocus]="true"
                            [formControl] = "distributorControl"
                            [matAutocomplete] = "suggestions"
                            #element>
                    <mat-autocomplete #suggestions = "matAutocomplete"
                                      (optionSelected)="onDustributorSelected($event)"
                                      [displayWith]="distributorDisplayFn">
                        <mat-option *ngFor="let suggestion of filteredDistributors | async; let suggestionIncresemental = index" [value]= "suggestion" >
                            {{suggestion.name}}, <span style="font-size: small">{{suggestion.address}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
            <ng-template #description>
                <mat-form-field class="example-full-width" appearance="fill">
                    <input  class="sawasdee26"
                            matInput
                            placeholder="{{question.placeholder}}"
                            autocomplete="off"
                            #matAutocompleteTrigger="matAutocompleteTrigger"
                            [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                            (input)="onInputTextChange($event);getSuggestions($event)"
                            (keydown)='validateTextLength($event)'
                            (keyup.enter)="onMouseEnter(element.value)"
                            (blur)='addSuggestion($event)'
                            [autofocus]="true"
                            [formControl] = "suggestionControl"
                            [matAutocomplete] = "suggestions"
                            #element>
                    <mat-autocomplete #suggestions = "matAutocomplete"
                                      (optionSelected)="onSuggestionSelectionChanged($event)"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let suggestion of filteredSuggestions | async; let suggestionIncresemental = index" [value]= "suggestion" >
                            {{suggestion.description}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-template>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 row">
          <center>
            <app-next-button></app-next-button>
          </center>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-2 col-md-2"></div>
</div>
