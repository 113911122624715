import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as $ from "jquery";
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
@Component({
  selector: 'app-mixedchart-highchart',
  templateUrl: './mixedchart-highchart.component.html',
  styleUrls: ['./mixedchart-highchart.component.scss']
})
export class MixedchartHighchartComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() legends: any;
  @Input() type: any;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = true;
  chartOptions: any;

  constructor() {
  }

  ngOnInit(): void {
    this.chartOptions = this.options();
    // Activate the sliders
    // $('#sliders input').on('input change',  () => {
    //   // this.options().chart.options3d[this.id] = parseFloat(this.value);
    //   this.showValues();
    // });
  }

  options(){
    const opt: any = {
      chart: {
        zoomType: 'xy',
        width: 'auto',
        height: 500,
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: [{
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}°C',
          style: {
            color: '#000'
          }
        },
        opposite: true,
        title: {
          text: 'Temperature',
          style: {
            color: '#000'
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Rainfall',
          style: {
            color: 'rgb(82, 136, 255)'
          }
        },
        labels: {
          format: '{value} mm',
          style: {
            color: 'rgb(82, 136, 255)'
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor:
            'rgb(82, 136, 255)' || // theme
            'rgba(255,255,255,0.25)'
      },
      series: [{
        name: 'Rainfall',
        type: 'column',
        yAxis: 1,
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
        color: '#0084ff',
        tooltip: {
          valueSuffix: ' mm'
        }

      }, {
        name: 'Temperature',
        type: 'spline',
        data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
        color: '#00ca00',
        tooltip: {
          valueSuffix: '°C'
        }
      }]
    };
    return opt;
  }

  ngAfterViewInit(): void {
  }
}
