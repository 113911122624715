<div class="container body">
    <div class="main_container" style="background: #263238">
        <div class="col-md-3 left_col">
            <div class="left_col scroll-view">
                <div class="navbar nav_title" style="border: 0;">
                    <a href="../../index.html" class="site_title">
                        <img style="background-color: white" class="img-circle" id="logo" height="40" width="40" src="{{service.logo}}">
                        <span > {{service.host.hasOwnProperty('company') ? service.host.company : service.limitTo(app.settings.client.name, 17)}} </span>
                    </a>
                </div>

                <div class="clearfix"></div>
                <!-- menu profile quick info -->
                <div class="profile">
                    <div class="profile_pic">
                        <img src="{{(app.data.user.avatar) ?  app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="{{app.data.user.name}}" class="img-circle profile_img" width="56.34" height="56.34">
                    </div>
                    <div class="profile_info">
                        <span>Welcome,</span>
                        <h2>{{app.data.user.name|slice:0:7}}</h2>
                    </div>
                </div>
                <!-- /menu profile quick info -->
                <!-- sidebar menu -->
                <app-sidebar-header></app-sidebar-header>
                <!-- /sidebar menu -->
                <!-- /menu footer buttons -->
                <div class="sidebar-footer hidden-small">
                    <a data-toggle="tooltip" data-placement="top" title="Settings">
                        <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
                    </a>
                    <a data-toggle="tooltip" data-placement="top" title="FullScreen">
                        <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
                    </a>
                    <a data-toggle="tooltip" data-placement="top" title="Lock">
                        <span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span>
                    </a>
                    <a data-toggle="tooltip" data-placement="top" title="Logout" (click)="logout()">
                        <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
                    </a>
                </div>
                <!-- /menu footer buttons -->
            </div>
        </div>

        <!-- top navigation -->
        <div class="top_nav">
            <div class="nav_menu">
                <nav class="" role="navigation">
                    <div class="nav toggle">
                        <a id="menu_toggle"><i class="fa fa-bars"></i></a>
                    </div>
                    <ul class="nav navbar-nav navbar-left">
                        <li class="nav-item">
                            <a (dblclick)="onSelectCampaign()">{{getHeader()}}</a>
                        </li>
<!--                        <li class="nav-item">-->
<!--                            <a>-->
<!--                                <span class="form-group top_search">-->
<!--                                    <div class="input-group" style="width:300px !important;">-->
<!--                                        <input type="text" class="form-control" placeholder="Search for..."/>-->
<!--                                        <span class="input-group-btn">-->
<!--                                            <button class="btn btn-default"-->
<!--                                                    type ="button">-->
<!--                                              Go!-->
<!--                                            </button>-->
<!--                                        </span>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </a>-->
<!--                        </li>-->
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li class="nav-item dropdown">
                            <a  class="user-profile dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                <img src="{{(app.data.user.avatar) ? app.data.user.avatar : 'assets/images/avatar.jpg'}}" alt="{{app.data.user.name}}" width="29" height="29">
                                {{app.data.user.name|slice:0:7}}
                                <span class=" fa fa-angle-down"></span>
                            </a>
                            <ul class="dropdown-menu dropdown-usermenu pull-right">
                                <li (click)="setUser(app.data.user);openProfile()"><a > Profile</a></li>
                                <li>
                                    <a href="javascript:;">
                                        <span class="badge bg-red pull-right">50%</span>
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li><a href="javascript:;">Help</a></li>
                                <li (click)="logout()"><a href=""><i class="fa fa-sign-out pull-right"></i> Log Out</a></li>
                            </ul>
                        </li>
                        <li role="presentation"
                            [ngStyle]="{'cursor':'pointer'}"
                            (click)="openDateMenu()"
                            class="nav-item">
<!--                            <mat-form-field  class="example-matdate-width" appearance="fill">-->
<!--                                <input  #dateRangeElement-->
<!--                                        placeholder=""-->
<!--                                        value="{{dateRangeValues()}}"-->
<!--                                        matInput-->
<!--                                        type="text"-->
<!--                                        readonly-->
<!--                                >-->
<!--                            </mat-form-field>-->
<!--                            <div id="reportrange" class="" style="background: #fff; cursor: pointer; padding: 5px 10px; margin-right: 5px; border: 1px solid #ccc">-->
<!--                                <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>-->
<!--                                <span>December 30, 2014 - January 28, 2015</span> <b class="caret"></b>-->
<!--                            </div>-->
                            <a class="dropdown-toggle"  data-toggle="dropdown" aria-expanded="false">
                                <i class="material-icons">expand_more</i>
                            </a>
                        </li>
                        <li role="presentation"
                            [ngStyle]="{'cursor':'pointer'}"
                            class="nav-item dropdown hmenu">
                            <a  [routerLink]="'/app/projects/list'"
                                class="nav-item  dropdown-toggle"
                                data-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/option-icon.png" alt="">
                            </a>
                            <div style="visibility: hidden; position: fixed"
                                 [style.left]="contextMenuPosition.x"
                                 [style.top]="contextMenuPosition.y"
                                 [matMenuTriggerFor]="contextMenu">
                            </div>
                            <mat-menu #contextMenu="matMenu">
                                <ng-template matMenuContent let-item="inspector">
                                   <div>
                                       <app-menus></app-menus>
                                   </div>

                                </ng-template>
                            </mat-menu>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <!-- /top navigation -->

        <!-- page content -->
        <div class="right_col" role="main">
            <router-outlet></router-outlet>
        </div>
        <!-- /page content -->

        <!-- footer content -->
<!--        <footer>-->
<!--            <div class="pull-right">-->
<!--                Design and Developed By  <a href="am.mashindano.co">mashindanogear@gmail.com</a>-->
<!--            </div>-->
<!--            <div class="clearfix"></div>-->
<!--        </footer>-->
        <!-- /footer content -->
    </div>
</div>



