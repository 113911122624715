<mat-tab-group>
  <mat-tab label="All({{employees.data.length}})">
    <br><br><app-search ></app-search><br><br>
    <app-table-employees [flag]="'all'"></app-table-employees>
  </mat-tab>
  <mat-tab label="Deleted({{employees.trashed.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-employees [flag]="'restore'"></app-table-employees>
  </mat-tab>
</mat-tab-group>
