<ul class="nav navbar-right panel_toolbox">
    <li (click)="openDialog()"><a><i class="material-icons">add</i></a></li>
    <li class="dropdown" (click)="openDateMenu()">
      <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i class="material-icons">date_range</i></a>
    </li>
    <li (click)="extract()"><a class=""><i class="material-icons">description</i></a></li>
    <li (click)="import()"><a class=""><i class="material-icons">description</i></a></li>
    <li><a class="close-link"><i class="material-icons">close</i></a></li>
    <li (click)="onContextMenu($event,{})"><a class="close-link" id="hdrbtn"><i class="material-icons">more_vert</i></a></li>
</ul>


<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" class='sawasdee20'>
    <ng-template matMenuContent let-item="inspector" >
        <button mat-menu-item (click)="import()">Import</button>
        <button mat-menu-item (click)="sendWhatsappChatboot()">Chatboot</button>
        <button mat-menu-item (click)="sendInvitations()">Send invitations</button>
    </ng-template>
</mat-menu>