<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <table [ngStyle]="{'width': '100%'}">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text"
                                   class="sawasdee14"
                                   autocomplete="off"
                                   placeholder="Search for item.."
                                   matInput
                                   #elmCntrlSearch
                                   [ngStyle]="{'text-align': 'center','width': '100%'}"
                                   (input)="onInputTextChange($event)"
                                   [formControl] = "itemControl"
                                   [matAutocomplete] = "item">
                            <i class="materials-icon pull-right"
                               (click)="addItem()">add</i>
                            <mat-autocomplete #item = "matAutocomplete"
                                              (optionSelected)="onOptionSelected($event)"
                                              [displayWith]="displayFn">
                                <mat-option *ngFor="let item of filteredItemsOptions | async; let itemOf = index" [value]= "item" >
                                    {{item.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <h4 style="color: red; font-weight: normal"
                                *ngIf="service.isNull(elmCntrlSearch.value) === false &&
                                         ((filteredItemsOptions | async) | json).length === 2">
                                **---Not Found *---
                            </h4>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row top_tiles">
                            <div *ngFor="let option of this.incomingQuestion.getOptions()">
                                <div class="demo-card-image mdl-card mdl-shadow--2dp pull-left"
                                     style="background:url({{option.settings.item.avatar}}) center / cover">
                                    <div class="mdl-card__title mdl-card--expand"></div>
                                    <div class="mdl-card__actions">
                                                        <span class="demo-card-image__filename">
                                                            <table class="headline2">
                                                                <tr>
                                                                    <td [ngStyle]="{'color':'#fff'}">{{option.settings.item.name}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td> <center><i class="material-icons" style="color: #000000; cursor: pointer;" (click)="remove(option)">close</i></center></td>
                                                                </tr>
                                                            </table>
                                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div>
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
