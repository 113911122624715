import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {DialogAmbassadorsComponent} from "./dialog-ambassadors/dialog-ambassadors.component";
import {MatDialog} from "@angular/material/dialog";
import {TeamService} from "../teams/team.service";
import {AppService} from "../app.service";

@Injectable({
  providedIn: 'root'
})
export class AmbassadorsService {

  report: any;

  /**
   * @param mixed
   */

  strings: any;


  /**
   * @param mixed
   */

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }

  constructor(private dialog: MatDialog,
              private service: AppService,
              private teamSearvice: TeamService) {
    this.strings = new Parameters();
    /**
     * @param mixed report
     */

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);

    /**
     * @param mixed
     */
    // this.strings.setAttributes(this.service.app.strings.params);
  }

  getAmbassadorByAmbassador(ambassador: any){
    var ambassadors: any = [] = this.service.app.data.ambassadors.data.filter((item: any) => {
      return parseFloat(ambassador.id) === parseFloat(item.id); });
    return ambassadors.length === 0 ? this.addAmbassador(ambassador) : ambassadors[0];
  }

  addAmbassador(ambassador: any){
    this.service.app.data.ambassadors.data.unshift(ambassador);
    return ambassador;
  }

  getAmbassadorsByTeams(teams: any){
    let ambassadors: any = {};
    ambassadors = {data: teams.data.map((team: any) => { return team.members.data.map((ambassador: any) => { return Object.assign(ambassador, {campaign_id: team.campaign_id})}); }).flat(1),
      trashed:    {data: teams.data.map((team: any) => { return team.members.trashed.data.map((ambassador: any) => { return Object.assign(ambassador, {campaign_id: team.campaign_id})}); }).flat(1)}};
    return ambassadors;
  }

  getAmbassadorsByTeamId(teamId: any){
   return this.service.app.data.ambassadors.data.filter((ambassador: any) => {
      return parseFloat(ambassador.team_id) === parseFloat(teamId);});
  }

  addAmbassadorsByTeam(team: any){
    team.members.data.map((member: any) => {
      let incomingMember: any = new Parameters();
      incomingMember.setAttributes(member);

    });
  }

  openDialog(params: any){
    this.dialog.open(DialogAmbassadorsComponent, {data  : {}, width : '60%', height: '100%', });
  }
}
