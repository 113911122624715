import { Component, OnInit, Input } from '@angular/core';
import {ItemService} from "../../../../items/item.service";
import {Parameters} from '../../../../parameters';
import {FormService} from '../../../../forms/form.service';
import {AppService} from '../../../../app.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
@Component({
  selector: 'app-create-order-control',
  templateUrl: './create-order-control.component.html',
  styleUrls: ['./create-order-control.component.scss']
})
export class CreateOrderControlComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  outgoingRequest: any;
  itemControl = new FormControl();
  filteredItemsOptions: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingItem: any;
  itemPosition: any;
  incomingSettings: any;
  constructor(public itemService: ItemService,
              public formService: FormService,
              public service: AppService) {
      this.outgoingRequest = new Parameters();
      this.incomingQuestion = new Parameters();
      this.incomingOption = new Parameters();
      this.incomingItem = new Parameters();
      this.incomingSettings = new Parameters();
  }

  ngOnInit(): void {
     this.incomingQuestion.setAttributes(this.question);
     this.filteredItemsOptions = this.itemControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(label => label ? this._filterItem(label) : this.service.app.data.items.data.slice())
     );
  }

  onInputTextChange(event: any){
   this.outgoingRequest.setAttributes({});
   this.outgoingRequest.setSearchText(event.target.value);
   this.itemService.search(this.outgoingRequest.getAttributes(), (result: any)=>{
   });
  }

  addItem(){
  }

  onOptionSelected(event: any){
   this.incomingItem.setAttributes(event.option.value);
   this.itemPosition = this.service.findIndex(this.incomingQuestion.getOptions(),'reference',this.incomingItem.getCntrlNum());
   if(this.itemPosition === -1){
      this.formService.addOptional( this.incomingQuestion.getOptions(), this.incomingItem.getName());
      this.incomingOption.setAttributes(this.formService.newOptional);
      this.incomingSettings.setAttributes(Object.assign({},{item:this.incomingItem.getAttributes()}));
      this.incomingOption.setReference(this.incomingItem.getCntrlNum());
      this.incomingOption.setSettings(this.incomingSettings.getAttributes());
   }
  }

  remove(option: any): void {
      this.incomingOption.setAttributes(option);
      const index = this.incomingQuestion.getOptions().indexOf(this.incomingOption.getAttributes());
      if (index === -1){} else {
        this.incomingQuestion.getOptions().splice(index, 1);
        if(this.incomingOption.getAttributes().hasOwnProperty('id')) {
          this.deletedOptions.push(this.incomingOption.getAttributes());
        }
      }
  }

   displayFn(value: any){
     return value;
   }

   private _filterItem(label: string) {
      const filterValue = label.toLowerCase();
     return this.service.app.data.items.data.filter((item: any) => JSON.stringify(item).toLowerCase().includes(filterValue));
   }

}
