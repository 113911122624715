import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import * as Chart from 'chart.js';
import {Parameters} from '../../../../parameters';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-mitiple-user-activity',
  templateUrl: './mitiple-user-activity.component.html',
  styleUrls: ['./mitiple-user-activity.component.scss']
})
export class MitipleUserActivityComponent implements OnInit, AfterViewInit {
  @Input() element: any;
  @Input() color: any;
  @Input() legends: any;
  @Input() data: any;
  @Input() label: any;
  @Input() type: any;
  @Input() limit: any;
  @Input() height: any;
  @Input() grouped: any;
  @Input() flag: any;
  @Input() fill: any;
  @Input() xGridlines: any;
  @Input() options: any;
  @Input() barThickness: any;
  transparentColor: any;
  datasets: any = [];
  incomingOption: any;
  incomingPrimary: any;
  incomingPrimaryOption: any;
  dataset: any;
  public canvas: any;
  public ctx;
  constructor(private service: AppService) {
    this.incomingOption = new Parameters();
    this.incomingPrimary = new Parameters();
    this.dataset = new Parameters();
    this.incomingPrimaryOption = new Parameters();
  }

  ngOnInit(): void {
   this.xGridlines = this.xGridlines === undefined ? true : this.xGridlines;
   this.barThickness === undefined ? 1 : this.barThickness;
  }

  getColor(){
    if(this.color.toString() === 'red') this.color = '#ec250d';
    if(this.color.toString() === 'blue') this.color = 'rgb(82, 136, 255)';
    this.color = this.color.toString() === 'green' ? '#00d6b4' : this.color;
    return this.color;
  }
  // '#1f8ef1'
  getTransparentColor(){
    if(this.color.toString() === 'red')  this.transparentColor = 'rgba(233,32,16,0.2)';
    if(this.color.toString() === 'blue') this.transparentColor = this.type === 'line' ? 'rgba(29,140,248,0.2)' : 'rgb(82, 136, 255)';
    this.transparentColor = this.color.toString() === 'green' ? '#96CA59' : this.transparentColor;
    return this.transparentColor;
  }

  getLabels(){
    this.legends = this.legends.map((label: any) => {
      return (this.limit === undefined ?
          this.service.limitTo(label.toString(), 3).toUpperCase().replace('..', '') :
          this.service.limitTo(label.toString().toUpperCase(), this.limit)); });
    return this.legends;
  }


  ngAfterViewInit(): void {
    setTimeout(() =>{
      let options: any = this.getOptions();
      if(this.barThickness !== undefined)
        options.scales.xAxes[0].maxBarThickness = this.barThickness;
      this.canvas = document.getElementById(this.element);
      this.ctx  = this.canvas.getContext('2d');
      const gradientStroke: any = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, this.getTransparentColor());
      gradientStroke.addColorStop(0.4, this.getTransparentColor());
      gradientStroke.addColorStop(0, this.getTransparentColor()); //blue colors
      let opt: any = {
        type: this.type,
        data: {
          labels: this.getLabels(),
          datasets: this.getDatasets(gradientStroke)
        },
        plugins: [ChartDataLabels],
        options: options
      };
      let myChart: any = new Chart(this.ctx, opt);
    });
  }

  getOptions(){
    return {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor : '#333',
        bodyFontColor  : '#666',
        bodySpacing: 4,
        xPadding : 12,
        mode     : 'nearest',
        intersect: 0,
        position : 'nearest',
        // callbacks: {
        //   label: function(tooltipItem, data) {
        //     var allData = data.datasets[tooltipItem.datasetIndex].data;
        //     var tooltipLabel = data.labels[tooltipItem.index];
        //     var tooltipData = allData[tooltipItem.index];
        //     var total = 0;
        //     for (var i=0; i<allData.length; i++) {
        //       total += allData[i];
        //     }
        //     var tooltipPercentage = Math.round((tooltipData / total) * 100);
        //     return tooltipLabel + ': $ ' + tooltipData + ' (' + tooltipPercentage + '%)';
        //   }
        // }
      },
      responsive: true,
      options: {
        legend: {
          display  : false,
          position : 'right',
          align    : 'start',
          fontColor: '#5f6368',
          font     : 'Mitra',
        },
        plugins : {
          datalabels: {
            color: 'white',
            display: (context)  => {
              return context.dataset.data[context.dataIndex] > 15;
            },
            font: {
              weight: 'bold'
            },
            formatter: Math.round
          }
        },
      },
      scales: {
        yAxes: [{
          display: false,
          gridLines: {
            drawBorder: true,
            color: this.getTransparentColor(),
            zeroLineColor: 'transparent',
          },
          ticks: {
          //suggestedMin: 0,
          //suggestedMax: 120,
            padding: 20,
            fontColor: '#9e9e9e',
            callback: (value: any) => {
              return value;
            }
          }
        }],
        xAxes: [{
          maxBarThickness: this.barThickness, // number (pixels),
          display: true,
          gridLines: {
            display: this.xGridlines,
            drawBorder: this.xGridlines,
            color: this.getTransparentColor(),
            zeroLineColor: 'transparent',
          },
          ticks: {
            padding: 20,
            fontColor: '#5f6368',
            fontFamily: 'Mitra',
            fontSize: 10,
            callback: (value: any) => {
              return value;
            }
          }
        }]
      }
    };
  }

  getDatasets(gradientStroke: any){
    if(this.flag === undefined){
        this.options.map((legend: any, legendIncremental: any) =>{
          this.dataset.setAttributes({});
          this.dataset.setUnknown('backgroundColor', gradientStroke);
          this.dataset.setUnknown('hoverckgroundColor', gradientStroke);
          this.dataset.setUnknown('borderColor', gradientStroke);
          this.dataset.setUnknown('borderWidth', 2);
          this.dataset.setUnknown('fill', this.fill !== undefined);
          this.dataset.setUnknown('borderDash', []);
          this.dataset.setUnknown('borderDashOffset', 0.0);
          this.dataset.setUnknown('lineTension', 0);
          this.dataset.setUnknown('label', legend);
          this.dataset.setUnknown('data', []);
          this.data.map((option: any, optionIncremental: any) =>{
            this.incomingOption.setAttributes(option);
            this.dataset.getUnknown('data').push(parseFloat(this.incomingOption.getUnknown(legend)));
          }); this.datasets.push(this.dataset.getAttributes());
        });
    }else{
        this.options.map((option: any, optionIncremental: any) =>{
          this.incomingOption.setAttributes(Object.assign({}, option));
          this.dataset.setAttributes({});
          this.dataset.setUnknown('backgroundColor', this.service.pieChartColors()[optionIncremental]);
          this.dataset.setUnknown('hoverckgroundColor', this.service.pieChartColors()[optionIncremental]);
          this.dataset.setUnknown('borderColor', this.service.pieChartColors()[optionIncremental]);
          this.dataset.setUnknown('borderWidth', 2);
          this.dataset.setUnknown('fill', this.fill !== undefined);
          this.dataset.setUnknown('borderDash', []);
          this.dataset.setUnknown('label', this.incomingOption.getLabel());
          this.dataset.setUnknown('borderDashOffset', 0.0);
          this.dataset.setUnknown('lineTension', 0.1);
          this.dataset.setUnknown('borderSkipped', false);
          this.dataset.setUnknown('datalabels', {display : false});
          this.dataset.setUnknown('data', []);
          if(this.incomingOption.attributes.hasOwnProperty('chart') &&
           !this.service.isNull(this.incomingOption.getChart()))
          this.dataset.setUnknown('type', parseFloat(this.incomingOption.getChart()) === 4 ? 'bar' : 'line');
          if(this.incomingOption.attributes.hasOwnProperty('fill') &&
                     !this.service.isNull(this.incomingOption.getFill()))
                    this.dataset.setUnknown('fill', parseFloat(this.incomingOption.getFill()) === 1);
          this.data.map((primary: any, primaryIncremental: any) =>{
            this.incomingPrimary.setAttributes(Object.assign({}, primary));
            this.incomingPrimary.getOptions().map((primaryOption: any) => {
             this.incomingPrimaryOption.setAttributes(Object.assign({}, primaryOption));
             if(this.incomingPrimaryOption.getCntrlNum().toString().includes(this.incomingOption.getCntrlNum()))
                 this.dataset.getUnknown('data').push(parseFloat(this.incomingPrimaryOption.getValue()) === 0 ? null : parseFloat(this.incomingPrimaryOption.getValue()));
            });
         });
         // this.dataset.setUnknown('datalabels',  {
         //   display: true,
         //   formatter: (value) => {
         //     return value + ' %';
         //   }
         // });
         this.datasets.push(this.dataset.getAttributes());
        });
    }
     return this.datasets;
  }


  ngAfterContentInit(): void {
  }

}
