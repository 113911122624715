import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';

@Component({
  selector: 'app-dialog-password-user',
  templateUrl: './dialog-password-user.component.html',
  styleUrls: ['./dialog-password-user.component.scss']
})
export class DialogPasswordUserComponent implements OnInit {

  formValues: FormGroup = new FormGroup({});
  keys: any;
  incomingSelectedUser: any;
  configAngularEditor: any;
  selected: any;
  results: any = [];
  users: any = [];
  searchCntrl: any;
  parameters: any;
  constructor(public userMetthods: UserService,
              public formBuilder: FormBuilder,
              private notifier: NotifierService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogPasswordUserComponent>,
              public serviceMetthods: AppService) {
    this.selected = userMetthods.getSelected();
    this.parameters = new Parameters();
    this.keys = Object.keys(this.selected).length;
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedUser.setAttributes(this.selected);
    this.formValues = this.formBuilder.group({
      current:  ['', Validators.compose([])],
      user_id:  ['', Validators.compose([])],
      new:      ['', Validators.compose([])],
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.afterClosed().subscribe(result => {this.userMetthods.setSelected({}); });
      if(this.keys === 0) this.incomingSelectedUser.setAttributes({}); else{
        this.formValues.controls['user_id'].setValue(this.incomingSelectedUser.attributes.id);
        for(var key in this.incomingSelectedUser.getAttributes()){
          if(this.incomingSelectedUser.attributes[key] instanceof Object || this.incomingSelectedUser.attributes[key] instanceof Array){} else{
            if(this.formValues.contains(key))
              this.formValues.controls[key].setValue(this.incomingSelectedUser.attributes[key]);
            else this.formValues.controls[key].setValue(this.incomingSelectedUser.attributes[key]);
          }
        }
      }

    }, 200);
  }


  handleDestroy(disease: any){

  }

  closeDialog(){
    this.dialogRef.close(null);
  }


  onSubmit(diseaseForm: any) {
    this.serviceMetthods.httpService(
      'post',
      this.keys === 0 ? '/users' : '/users/' + this.incomingSelectedUser.getId() + '/change/password',
      diseaseForm, {},
      (response: any) => {
        if(this.keys === 0) {
          this.users.data.unshift(response); }else {
          this.selected = Object.assign(this.incomingSelectedUser.getAttributes(), response);
          this.closeDialog();
        }
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'changed' ));
      }, (error: any) => {
      });

  }



}
