import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
@Component({
  selector: 'app-list-items',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class ListItemsComponent implements OnInit {
  app: any;
  searchCntrl: any;
  outgoingRequest: any;
  constructor(public service: AppService) {
    this.app = this.service.app;
    this.service.app.form.module = 'items';
  }

  ngOnInit(): void {
  }

  openDialog() {
   //this.dialog.open(DialogDistrictalComponent, {data: {}, width: '55%', height: 'auto'});
  }

}
