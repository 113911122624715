import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnInit {
  campaignId;
  constructor(private activatedRoute: ActivatedRoute,
              public servce: AppService) {
  }

  ngOnInit(): void {
    this.campaignId = this.servce.decrypt(this.activatedRoute.snapshot.params.id);

  }

}
