import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from "../../app.service";
import {NotifierService} from "angular-notifier";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BroadcastChannelService} from "../../broadcast-channel.service";
import {Parameters} from "../../parameters";

@Component({
  selector: 'app-message-display',
  templateUrl: './message-display.component.html',
  styleUrls: ['./message-display.component.scss']
})
export class MessageDisplayComponent implements OnInit {

  data: any;
  title: any;
  action: any;
  strings: any;
  constructor(public service: AppService,
              private broadcastChaneel: BroadcastChannelService,
              private notifier: NotifierService,
              @Inject(MAT_DIALOG_DATA) public params: any, public dialogRef: MatDialogRef<MessageDisplayComponent>) {
    this.title = this.params.title;
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
  }

  ngOnInit(): void {
  }

  onAcceptable(){
    this.dialogRef.close({response: 'successfull'});
  }
}
