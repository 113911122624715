import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogInventoriesComponent} from '../../inventories/dialog-inventories/dialog-inventories.component';
import {Parameters} from '../../parameters';

@Component({
  selector: 'app-inventories',
  templateUrl: './inventories.component.html',
  styleUrls: ['./inventories.component.scss']
})
export class InventoriesComponent implements OnInit {
  app: any;
  parameters: any = {};
  incomingItem: any;
  constructor(
    public service: AppService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog) {
    this.app = service.app;
    this.incomingItem = new Parameters();
    this.service.app.form.module   = 'inventories';
    this.service.app.form.modules  = [{path: '', title: 'Inventories'}];
    this.incomingItem.setAttributes({});
  }

  ngOnInit(): void {
  }

  openDialog(){
    if(window.location.href.toString().includes('/questions'))
     this.incomingItem.setQuestionId(this.service.decrypt(this.activatedRoute.snapshot.params.questionId));
     if(window.location.href.toString().includes('/forms'))
          this.incomingItem.setFormId(this.service.decrypt(this.activatedRoute.snapshot.params.formId));
   this.dialog.open(DialogInventoriesComponent, {data  : this.incomingItem.getAttributes(), width : '55%', height: '100%', });
  }

}
