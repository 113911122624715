import {Component, Input, OnInit} from '@angular/core';
import {ChartService} from '../../../../chart.service';

@Component({
  selector: 'app-donut-piechart',
  templateUrl: './donut-piechart.component.html',
  styleUrls: ['./donut-piechart.component.scss']
})
export class DonutPiechartComponent implements OnInit {
  @Input() data: any;
  @Input() legends: any;
  element: any;
  theme: any;
  constructor(public chart: ChartService) {
    this.theme  = this.chart.theme();
  }


  ngOnInit(): void {
    setTimeout(() =>{
      this.element = this.options();
    }, 1000);
  }

  options(){
    const opt = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      calculable: true,
      legend: {
        x: 'center',
        y: 'bottom',
        show: false,
        data: this.legends
      },
      toolbox: {
        show: false,
        feature: {
          magicType: {
            show: true,
            type: ['pie', 'funnel'],
            option: {
              funnel: {
                x: '25%',
                width: '50%',
                funnelAlign: 'center',
                max: 1548
              }
            }
          },
          restore: {
            show: true,
            title: 'Restore'
          },
          saveAsImage: {
            show: true,
            title: 'Save Image'
          }
        }
      },
      series: [{
        name: 'Access to the resource',
        type: 'pie',
        radius: ['35%', '55%'],
        itemStyle: {
          normal: {
            label: {
              show: true,
              formatter :  '{d}% : {c} : {b}'
            },
            labelLine: {
              show: true
            }
          },
          emphasis: {
            label: {
              show: true,
              position: 'center',
              textStyle: {
                fontSize: '14',
                fontWeight: 'normal'
              }
            }
          }
        },
        data: this.data
      }]
    };

    return opt;
  }

}
