import {AfterContentInit, Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {map, startWith} from 'rxjs/internal/operators';
import {AppService} from "../../app.service";
@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent implements OnInit, AfterContentInit {

  constructor(public service: AppService) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    const matDialogContainers = document.getElementsByClassName('mat-dialog-container');
    for (let i = 0; i < matDialogContainers.length; i++) {
      const matDialogContainer = matDialogContainers[i] as HTMLElement;
      matDialogContainer.style.backgroundColor = 'transparent';
      matDialogContainer.style.boxShadow = '0px';
    }
  }

}
