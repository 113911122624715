<a class="pull-left"><h4>{{keys === 0 ? 'Team ' : incomingSelectedTeam.getName()}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="hidden" class="nullInput">
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width" appearance="fill">
             <mat-label> Project *</mat-label>
             <input type="text"
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "campaignControl"
                    [matAutocomplete] = "campaign">
                        <mat-autocomplete #campaign = "matAutocomplete" (optionSelected)="onCampaignSelectionChanged($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let campaign of filteredCampaignsOptions | async; let teamOf = index" [value]= "campaign" >
                                {{campaign.name}}
                            </mat-option>
                        </mat-autocomplete>
            <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addCampaign()">add</i>
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address *</mat-label>
            <input type="text"
                   matInput
                   formControlName="location"
                   placeholder=""
                   #teamElement
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onAddressTextChange($event)"
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event, teamElement.value)"
                   (onLocationSelected)="onLocationSelected($event)"
                   matGoogleMapsAutocomplete >
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
      <span class="" mat-line>
           <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
              <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
      </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Edit'}}"/>
        </div>
      </center>
    </div>
  </div>
</form>
