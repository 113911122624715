import { Component, OnInit } from '@angular/core';
import {CampaignService} from '../campaign.service';
import {AppService} from "../../app.service";

@Component({
  selector: 'app-tabs-campaigns',
  templateUrl: './tabs-campaigns.component.html',
  styleUrls: ['./tabs-campaigns.component.scss']
})
export class TabsCampaignsComponent implements OnInit {

  campaigns: any;
  constructor(public campaignService: CampaignService,
              public service: AppService) {
  }

  ngOnInit(): void {
    this.campaigns = this.service.app.data.campaigns;
  }

  openDialog(){
    this.campaignService.setSelected({});
    this.campaignService.openDialog({});
  }

  getData(){
    return this.campaignService.status === -1 ? this.service.app.data.campaigns.data :
        this.service.app.data.campaigns.data.filter((campaign: any) => { return campaign.status === this.campaignService.status;});
  }

}
