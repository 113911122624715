import {Component, Input, OnInit} from '@angular/core';
import {ChartService} from "../../../chart.service";

@Component({
  selector: 'app-echart-sonar',
  templateUrl: './echart-sonar.component.html',
  styleUrls: ['./echart-sonar.component.scss']
})
export class EchartSonarComponent implements OnInit {

  @Input() element;
  theme: any;
  opts: any;
  constructor(private chartService: ChartService ) { }

  ngOnInit(): void {
    this.theme = this.chartService.theme();
    this.opts = this.options();
  }

  options(){
    const opt: any = {
      title: {
        text: 'Budget vs spending',
        subtext: 'Subtitle'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        x: 'right',
        y: 'bottom',
        data: ['Allocated Budget', 'Actual Spending']
      },
      toolbox: {
        show: true,
        feature: {
          restore: {
            show: true,
            title: "Restore"
          },
          saveAsImage: {
            show: true,
            title: "Save Image"
          }
        }
      },
      polar: [{
        indicator: [{
          text: 'Sales',
          max: 6000
        }, {
          text: 'Administration',
          max: 16000
        }, {
          text: 'Information Techology',
          max: 30000
        }, {
          text: 'Customer Support',
          max: 38000
        }, {
          text: 'Development',
          max: 52000
        }, {
          text: 'Marketing',
          max: 25000
        }]
      }],
      calculable: true,
      series: [{
        name: 'Budget vs spending',
        type: 'radar',
        data: [{
          value: [4300, 10000, 28000, 35000, 50000, 19000],
          name: 'Allocated Budget'
        }, {
          value: [5000, 14000, 28000, 31000, 42000, 21000],
          name: 'Actual Spending'
        }]
      }]
    };
    return opt;
  }

}
