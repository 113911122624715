import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../app.service";
import {FormService} from "../form.service";

@Component({
  selector: 'app-view-form',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.scss']
})
export class ViewFormComponent implements OnInit {
  formId: any;
  form: any;
  constructor(private router: Router,
              private service: AppService,
              private formService: FormService,
              private activatedRoute: ActivatedRoute) {
    this.formId = this.activatedRoute.snapshot.params.id;
    this.formId = this.service.decrypt(this.formId);
    this.form = this.formService.getFormById(this.formId);

  }

  ngOnInit(): void {

  }

}
