import { Injectable } from '@angular/core';
import {Parameters} from "../../parameters";

@Injectable({
  providedIn: 'root'
})
export class FormDashboardService {
  incomingForms: any;
  dialogStatus: any = true;
  constructor() {
    this.incomingForms = new Parameters();
    this.incomingForms.setAttributes({});
  }
}
