<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'0px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <h4 id="example-radio-group-label">{{question.label}}</h4>
                </div>
                <div>
                    <center>
                        <div [innerHTML]="iframe(860,484, this.question.src) | safeUrl" (click)="file.click()"></div>
<!--                    <iframe-->
<!--                                width="860"-->
<!--                                height="484"-->
<!--                                src="{{question.src | safeUrl}}"-->
<!--                                title=""-->
<!--                                (click)="file.click()"-->
<!--                                frameborder="0"-->
<!--                                allow="accelerometer; autoplay; clipboard-whttps://www.youtube.com/embed/jGV-TO_Ez0Qrite; encrypted-media; gyroscope; picture-in-picture"-->
<!--                                allowfullscreen>-->

<!--                    </iframe>-->
<!--                    <a mat-fab class="" *ngIf="impl.isnull(question.data) === true" style="background-color:#ffff;color: #888888"><i class="material-icons" (click)="file.click()">play_circle_outline</i></a><br><br>-->
<!--                        <video  (click)="file.click()"-->
<!--                                    [ngStyle]="{'width': 'auto', 'max-height': '300px', 'cursor':'pointer'}">-->
<!--                                <source src="{{question.src}}" type="">-->
<!--                        </video>-->
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
<input type="file" (change)="getVideo($event)" [hidden]="true" accept="video/*" #file>

<!--<ngx-mat-timepicker-field color="warn" [defaultTime]="'11:11 AM'"></ngx-mat-timepicker-field>-->
