<a class="pull-left"><h4>{{selectedDistrictalStatus === 0 ? 'District' : incomingSelectedDistrictal.getName()}}</h4></a>
<a class="pull-right" [mat-dialog-close]="" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<p [ngStyle]="{'margin':'30px'}"></p>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
    <span class="" mat-line>
         <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
          </mat-form-field>
    </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{selectedDistrictalStatus === 0 ? 'Add' : 'Edit'}}"/>
        </div>
      </center>
    </div>
  </div>
</form>
