<a class="pull-left"><h4>{{keys === 0 ? 'Disease ' : incomingSelectedUser.getName()}} <i>Change Password</i></h4></a>
<a class="pull-right" [md-dialog-close]='' (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a>
<hr />
<p [ngStyle]="{'margin':'30px'}"></p>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
              <span class="" mat-line>
                <mat-form-field  class="example-full-width">
                  <mat-label>New *</mat-label>
                  <input matInput type="password"  formControlName="new">
                </mat-form-field>
              </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Change'}}"/>
        </div>
      </center>
  </div>
</form>
