import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  Sanitizer,
  ViewChild
} from '@angular/core';
import {AppService} from '../../../../app.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-video-preview-controls',
  templateUrl: './video-preview-controls.component.html',
  styleUrls: ['./video-preview-controls.component.scss']
})

export class VideoPreviewControlsComponent implements OnInit, AfterViewInit {
  @Input() question: any;
  @Input() position: any;
  htmlString: any;
  constructor(public impl: AppService, public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.question = Object.assign(this.question, {src : 'http://localhost/videos/I.Still.Believe.2020.720p.BluRay.x264.AAC-%5bYTS.MX%5d.mp4'});
  }

  iframe(width:any, height: any, src: any){
    // return '<iframe width="' + width + '" height="' + height + '" src=""></iframe>';
    return   `
        <iframe
          class="container"
          width="` + width + `"
          height="` + height + `"
          src ="` + src + `"
          >
        </iframe>`;
  }


  getVideo(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.question.data = this.impl.dataURItoBlob(reader.result);
      this.question.src = reader.result;
    };
  }

  ngAfterViewInit(): void {
    // this.iframeElement.nativeElement.setAttribute('src', this.sanitizer.bypassSecurityTrustUrl(this.question.src));
  }



}
