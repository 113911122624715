<div *ngIf="incomingCriteria.hasAttribute(strings.getTitle())">
    <app-responses-list-partial [responses]="incomingCriteria.getUnknown(incomingReport.getDuration())[strings.getData()]"></app-responses-list-partial>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngStyle]="{'margin-top':'50px'}">
        <center>
            <a mat-mini-fab class="defaultColor2 cursor">
                <mat-icon (click)="onStart(this.limit)">keyboard_arrow_down</mat-icon>
            </a>
        </center>
    </div>
</div>

