import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import * as $ from 'jquery';
import highcharts3D from 'highcharts/highcharts-3d.src';
// @ts-ignore
highcharts3D(Highcharts);
@Component({
  selector: 'app-highchart3-d-barchart',
  templateUrl: './highchart3-d-barchart.component.html',
  styleUrls: ['./highchart3-d-barchart.component.scss']
})
export class Highchart3DBarchartComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() legends: any;
  @Input() type: any;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = true;
  chartOptions: any;

  constructor() {
  }

  ngOnInit(): void {
    this.chartOptions = this.options();
    // Activate the sliders
    // $('#sliders input').on('input change',  () => {
    //   // this.options().chart.options3d[this.id] = parseFloat(this.value);
    //   this.showValues();
    // });
  }

  options(){
    const opt: any = {
      chart: {
        renderTo: 'container',
        type: 'column',
        margin: 75,
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          depth: 50,
          viewDistance: 25
        }
      },
      title : {
        text: 'Chart rotation demo'
      },
      plotOptions : {
        column: {
          depth: 25
        }
      },
      series : [{
        data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
          194.1, 95.6, 54.4]
      }]
    };
    // this.showValues();
    return opt;
  }


  showValues() {
    $('#alpha-value').html(this.options().chart.options3d.alpha);
    $('#beta-value').html(this.options().chart.options3d.beta);
    $('#depth-value').html(this.options().chart.options3d.depth);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showValues();
    }, 1020);
  }
}
