import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';

@Component({
  selector: 'app-dialg-progress',
  templateUrl: './dialg-progress.component.html',
  styleUrls: ['./dialg-progress.component.scss']
})
export class DialgProgressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
