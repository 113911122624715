import { Injectable } from '@angular/core';
import {Parameters} from '../../../parameters';
import {AppService} from '../../../app.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  question: any = {};
  incomingQuestion: any;
  incomingRow: any;
  incomingR: any;
  incomingOption: any;
  incomingOpt: any;
  default: any = [];
  incomingOptions: any;
  constructor(private service: AppService) {
    this.incomingQuestion = new Parameters();
    this.incomingRow = new Parameters();
    this.incomingR = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingOpt = new Parameters();
    this.incomingOptions = new Parameters();
  }

  setQuestion(question: any){
    this.question = question;
    this.incomingQuestion.setAttributes(this.question);
  }

  ngStart(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setRows(!this.service.isNull(this.incomingQuestion.getData()) ?
          JSON.parse(this.incomingQuestion.getData()) : []);
      this.default = !this.service.isNull(this.incomingQuestion.getDefault()) ?
          JSON.parse(this.incomingQuestion.getDefault()) : [];
      if(this.incomingQuestion.getOptions().length > 0){
        if(this.question.rows.length === 0 && this.default.length === 0){
          for (let l = 0; l < 5; l++) {
            this.incomingRow.setAttributes({});
            this.incomingQuestion.getOptions().map((optional: any) => {
              this.incomingOption.setAttributes(optional);
              this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: ''});
            });this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
          }this.incomingQuestion.setData(JSON.stringify(this.incomingQuestion.getRows()));
          this.update();
        }else if(this.question.rows.length === 0 && this.default.length > 0){
          this.incomingQuestion.setRows(this.default);
          this.incomingQuestion.setData(JSON.stringify(this.incomingQuestion.getRows()));
        }
        this.default.map((row: any) =>  {
          this.incomingRow.setAttributes(row);
          if(!this.hasRowByCntrlNum(this.incomingRow.getCntrlNum())){
            this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
          } else {
            let l: any = this.service.findIndex(this.incomingQuestion.getRows(), 'cntrl_num', this.incomingRow.getCntrlNum());
            let r: any = this.incomingQuestion.getRows()[l];
            let incomingR: any = new Parameters();
            incomingR.setAttributes(r);
            for (let key in this.incomingRow.attributes) {
              if (incomingR.attributes.hasOwnProperty(key)) {
                if(this.incomingRow.getUnknown(key).hasOwnProperty('default')) {
                  incomingR.getUnknown(key).data = this.incomingRow.getUnknown(key).data;
                  incomingR.getUnknown(key).default = this.incomingRow.getUnknown(key).default;
                }
              }else incomingR.attributes[key] = this.incomingRow.getUnknown(key);

            }
          }
        });
        this.update();
      }
    });
  }

  isChecked(checkbox: any, row: any, option: any){
    this.incomingRow.setAttributes(row);
    this.incomingOption.setAttributes(option);
    checkbox.checked = !this.service.isNull(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) &&
        parseFloat(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) === 1;
    return checkbox.checked;
  }

  isheaderShouldDislayed(){
    let l: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getOptions().map((option: any) => {
      this.incomingOption.setAttributes(option);
      if(!this.service.isNull(this.service.removeHtmlTags(this.incomingOption.getLabel()))) l = true;
    });return l;
  }

  hasRowByCntrlNum(cntrlNum: any){
    this.incomingQuestion.setAttributes(this.question);
    let rows: any  = this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getRows(), 'cntrl_num');
    return rows.hasOwnProperty(cntrlNum);
  }


  hasOutputsummationbox(){
    let output: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getOptions().map((option: any) => {
      this.incomingOpt.setAttributes(option);
      if (parseFloat(this.incomingOpt.getTypeId()) === 26) output = true;
    });return output;
  }

  update(){
    this.incomingQuestion.setAttributes(this.question);
    this.question.data = this.incomingQuestion.getRows().length === 0 ? '' : JSON.stringify(this.question.rows);
    this.incomingQuestion.setIsNull(this.istableNull());
    this.summationboxinsertValues();
  }

  outputSummationbox(row: any){
    this.incomingR.setAttributes(row);
    this.incomingQuestion.setAttributes(this.question);
    let output: any = 0;
    this.incomingQuestion.getOptions().filter((option: any) => {
      this.incomingOpt.setAttributes(option);
      if(parseFloat(this.incomingOpt.getTypeId()) !== 26){
        if(!this.service.isNull(this.incomingR.getUnknown(this.incomingOpt.getCntrlNum()).data) &&
            !isNaN(this.incomingR.getUnknown(this.incomingOpt.getCntrlNum()).data.replaceAll(',', '')))
          output += parseFloat(this.incomingR.getUnknown(this.incomingOpt.getCntrlNum()).data.replaceAll(',', ''));}
    }); this.incomingR.getUnknown(this.getSummationbox().cntrl_num).data = this.service.numberWithCommans(output);
  }

  summationboxinsertValues(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        if(this.hasOutputsummationbox()){
          this.outputSummationbox(this.incomingRow.getAttributes());
        }
      });
    });
  }

  getSummationbox(){
    let output: any = false;
    this.incomingQuestion.setAttributes(this.question);
    return this.incomingQuestion.getOptions().filter((option: any) => {
      this.incomingOpt.setAttributes(option);
      return parseFloat(this.incomingOpt.getTypeId()) === 26;
    })[0];
  }

  istableNull(){
    let results: any = false;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOptions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingQuestion.getOptions(), 'cntrl_num'));
    if(parseFloat(this.incomingQuestion.getRequired()) === 1){
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        for(var key in this.incomingRow.getAttributes()){
          if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
              !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
              this.incomingOptions.getUnknown(key).type_id !== 26 &&
              this.service.isNull(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data))
          )
            results = true;
        }
      });
    }else{
      results = true;
      this.incomingQuestion.getRows().map((row: any) => {
        this.incomingRow.setAttributes(row);
        for(var key in this.incomingRow.getAttributes()){
          if(this.incomingRow.getUnknown(key).hasOwnProperty('data') &&
              this.incomingOptions.getUnknown(key).type_id !== 26 &&
              !this.incomingRow.getAttributes()[key].hasOwnProperty('default') &&
              !this.service.isNull(this.service.removeHtmlTags(this.incomingRow.getAttributes()[key].data)))
            results = false;
        }
      });
    }
    return results;
  }
}
