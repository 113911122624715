<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div>
                <a mat-flat-button
                   class="defaultColor lg-fab"
                   (click)="addOptional()">Add Option</a>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="pull-left">
                        <table [ngStyle]="{'width':'100%','margin-top':'30px'}">
                            <tr *ngFor="let rateOption of incomingQuestion.getOptions()"
                                [ngStyle]="{'width':'100%'}">
                                <td class="">{{rateOption.label}}</td>
                                <td [ngStyle]="{'width':'100%'}">
                                    <mat-slider
                                            min="0"
                                            max="100"
                                            step="1"
                                            tickInterval="1"
                                            [thumbLabel] = "true"
                                            [value]="0"
                                            discrete
                                            [ngStyle]="{'width':'100%'}">
                                        <input matSliderThumb>
                                    </mat-slider>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
