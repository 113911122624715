import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonPropertyFilter'
})
export class JsonPropertyFilterPipe implements PipeTransform {

    transform(items: any[], searchText: any, fieldName: any): any[] {
        if(searchText.toString() === '-1') return items;
        // return empty array if array is falsy
        if (!items) { return []; }

        // return the original array if search text is empty
        if (!searchText) { return items; }

        // convert the searchText to lower case

        // searchText = searchText.toString().toLowerCase();

        // retrun the filtered array
        return items.filter(item => {
            const finalFieldName: any = fieldName instanceof Object ? item[Object.keys(fieldName)[0]][fieldName[Object.keys(fieldName)[0]]] : item[fieldName];
            if((/^\d+$/.test(searchText))){
                return parseFloat(finalFieldName) === parseFloat(searchText.toString().toLowerCase());
            }else {
                return finalFieldName.toString().toLowerCase().includes(searchText.toString().toLowerCase());
            }

            return false;
        });
    }

}
