import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {AmbassadorsService} from '../ambassadors.service';
import {UserService} from '../../users/user.service';
import {DialogPasswordUserComponent} from '../../users/dialog-password-user/dialog-password-user.component';
import {TeamService} from '../../teams/team.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {ClientService} from '../../clients/client.service';
import {CampanyService} from '../../campanies/campany.service';
import {InviteeService} from '../../invites/invitee.service';
import {FormService} from "../../forms/form.service";
import {FilterDashboardComponent} from "../../dashboard/filter-dashboard.component";
import {DashboardService} from "../../dashboard/dashboard.service";

@Component({
  selector: 'app-table-ambassadors',
  templateUrl: './table-ambassadors.component.html',
  styleUrls: ['./table-ambassadors.component.scss']
})
export class TableAmbassadorsComponent implements OnInit, OnDestroy {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedAmbassador: any;
  ambassadors: any = {data: [], trashed: {data: []}};
  incomingSelectedAmbassador: any;
  incomingAmbassadorIsAssigned: any;
  incomingAmbassadorAssign: any;
  incomingAmbassador: any;
  subscription: any;
  searchCntrl: any = '';
  teams: any;
  incomingTeam: any;
  incomingResult: any;
  incomingCampaign: any;
  incomingCampany: any;
  incomingClient: any;
  incomingUser: any;
  incomingForm: any;
  outgoingRequest: any;
  status: any;
  formId: any = 0;
  incomingItem: any;
  window: any;
  position: any;
  incomingInvitee: any;
  constructor(public  service: AppService,
              public  dialog: MatDialog,
              public  broadcastChannel: BroadcastChannelService,
              private dashboardService: DashboardService,
              private router: Router,
              public  activateRouter: ActivatedRoute,
              private inviteeService: InviteeService,
              private formService: FormService,
              public  userService: UserService,
              public  teamService: TeamService,
              public  clientService: ClientService,
              public  campanyService: CampanyService,
              public  campaignService: CampaignService,
              private ambassadorService: AmbassadorsService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.incomingSelectedAmbassador = new Parameters();
    this.incomingTeam = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingAmbassadorIsAssigned = new Parameters();
    this.incomingAmbassador = new Parameters();
    this.incomingAmbassadorAssign = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingItem = new Parameters();
    this.incomingInvitee = new Parameters();
    this.incomingCampany = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingUser = new Parameters();
    this.incomingForm = new Parameters();
    this.outgoingRequest = new Parameters();
    this.teams = this.app.data.teams.data;
  }

  ngOnInit(): void {
    this.window = window;
    if(window.location.href.toString().includes('/forms')){
      this.formId = this.activateRouter.snapshot.params.id;
      this.formId = this.service.decrypt(this.formId);
    }
    this.outgoingRequest.setAttributes(Object.assign({}, this.dashboardService.incomingReport.attributes));
    if(this.outgoingRequest.attributes.hasOwnProperty('data'))
      this.outgoingRequest.attributes.data;
    this.incomingForm.setAttributes(parseFloat(this.formId) === 0  ? {} : this.formService.getFormById(this.formId));
    this.ambassadors.data = this.getAmbassadors();
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('searchText')) {
            this.searchCntrl = item.searchText;
          }else if (item.hasOwnProperty('ambassador')) {
              this.incomingSelectedAmbassador.setAttributes(item.ambassador);
              this.position = this.service.findIndex(this.ambassadors.data, 'id', this.incomingSelectedAmbassador.getId());
              if(this.position === -1){
               this.ambassadors.data.unshift(this.incomingSelectedAmbassador.getAttributes());
//             this.service.app.data.ambassadors.data.unshift(this.incomingSelectedAmbassador.getAttributes());
               this.incomingTeam.setAttributes(this.teamService.getTeamById(this.incomingSelectedAmbassador.getTeamId()));
               if(this.service.findIntegerIndex(this.incomingTeam.getMembers().data,'id',this.incomingSelectedAmbassador.getId()) === -1)
               this.incomingTeam.getMembers().data.unshift(this.incomingSelectedAmbassador.getAttributes());
              }else {
               this.incomingAmbassador.setAttributes(this.ambassadors.data[this.position]);
               this.incomingAmbassador.setAttributes(Object.assign(this.incomingAmbassador.getAttributes(),this.incomingSelectedAmbassador.getAttributes()));
              }
          }
        }else if (item === 'report'){
          if(this.status === 1){
           this.requestAmbassadorReport();
          }
        }
      });

    if(this.incomingForm.getAttributes().hasOwnProperty('id')){
      this.getInvitees((invitees: any)=>{
        this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
        this.teams = this.incomingCampaign.getTeams().data;
        this.ambassadors.data = [];
        this.teams.map((team: any)=>{
         this.incomingTeam.setAttributes(team);
         this.incomingTeam.getMembers().data.map((member: any)=>{
          this.incomingAmbassador.setAttributes(member);
          this.incomingAmbassador.setActive(0);
          this.position = this.service.findIntegerIndex(this.incomingForm.getInvitees().data, 'user_id',this.incomingAmbassador.getId());
          if(this.position !== -1){
          this.incomingAmbassador.setAttributes(Object.assign(this.incomingAmbassador.getAttributes(),this.incomingForm.getInvitees().data[this.position]));
          }this.incomingAmbassador.setTeamId(this.incomingTeam.getId());
  //         this.incomingAmbassador.setTeam(Object.assign({},this.incomingTeam.getAttributes()));
          this.ambassadors.data.unshift(this.incomingAmbassador.getAttributes());
         });
        });
      });
    }
  }

  isAssigned(checkbox: any, ambassador: any){
    this.incomingAmbassadorIsAssigned.setAttributes(ambassador);
    return this.incomingAmbassadorIsAssigned.getAttributes().hasOwnProperty('active') &&
     parseFloat(this.incomingAmbassadorIsAssigned.getActive()) === 1;
  }

  getInvitees(callback: any){
   if(this.incomingForm.getInvitees().hasOwnProperty('flag')){
    callback(null);
   }else{
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    this.service.httpService('post', this.service.app.routes.invitees.get, this.outgoingRequest.getAttributes(), {}, (result: any) => {
          this.incomingForm.getInvitees().data = result;
          callback(null);
        }, (error: any) => {
        });
   }
  }

  getTeamById(id: any){
    this.incomingTeam.setAttributes(this.teamService.getTeamById(id));
    return this.incomingTeam.getAttributes();
  }

  getAmbassadors(){
    return Object.keys(this.incomingForm.getAttributes()).length === 0 ?
        this.service.app.data.ambassadors.data :
        this.incomingForm.getInvitees().data.filter((ambassador: any) =>{
          try{
            this.incomingAmbassador.setAttributes(this.getUser(ambassador));
                    return this.incomingAmbassador.attributes.hasOwnProperty('team_id') &&
                        this.incomingAmbassador.attributes.hasOwnProperty('user_id') &&
                        this.incomingAmbassador.attributes.hasOwnProperty('id') &&
                        parseFloat(this.incomingAmbassador.getFormId()) === parseFloat(this.incomingForm.getId());
          }catch (e) {return false;
          }
        });
  }

  requestAmbassadorReport(){
    this.outgoingRequest.setUserId(this.incomingSelectedAmbassador.getId());
    this.outgoingRequest.setFormId(this.incomingForm.getId());
    this.outgoingRequest.setType('responses.date.range');
    this.service.httpService('post', '/reports', this.outgoingRequest.getAttributes(), {}, (result: any) => {
      this.service.responses = result;
      this.service.user = this.incomingSelectedAmbassador.getAttributes();
      this.router.navigateByUrl('/app/supervisors/xxx/responses');
    }, (error: any) => {
    });

  }

  assignUser(ambassador: any){
      this.incomingInvitee.setAttributes(ambassador);
      this.outgoingRequest.setFormId(this.incomingForm.getId());
      this.outgoingRequest.setUserId(this.incomingInvitee.getId());
      if(this.incomingInvitee.attributes.hasOwnProperty('team_id')){
        this.outgoingRequest.setTeamId(this.incomingInvitee.getTeamId());
      } if(this.incomingInvitee.attributes.hasOwnProperty('target'))
        this.outgoingRequest.setTarget(this.incomingInvitee.getTarget());
      this.service.httpService('post', this.service.app.routes.forms.ambassadors.assign, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
       this.incomingResult.setAttributes(result);
       this.incomingInvitee.setActive(this.incomingResult.getActive());
       this.incomingInvitee.setAttributes(Object.assign(this.incomingInvitee.getAttributes(), result));
       this.position = this.service.findIntegerIndex(this.incomingForm.getInvitees().data, 'user_id',this.incomingInvitee.getId());
       if(this.position !== -1){
        this.incomingAmbassador.setAttributes(this.incomingForm.getInvitees().data[this.position]);
        this.incomingAmbassador.setActive(this.incomingInvitee.getActive());
       }else {
        this.incomingForm.getInvitees().data.unshift(Object.assign({},this.incomingInvitee.getAttributes()));
        this.service.app.data.invitees.data.unshift(Object.assign({},this.incomingInvitee.getAttributes()));
       }
      }, (error: any) => {
      });
  }

  getListingReport(){
    this.status = 1;
    this.service.responses = [];
    this.service.user = {};
    this.dialog.open(FilterDashboardComponent, { data: {report: this.outgoingRequest.getAttributes()} , width  : '55%', height : '100%'});
  }

  handlePassword(ambassador: any){
    this.incomingSelectedAmbassador = new Parameters();
    this.incomingSelectedAmbassador.setAttributes(this.selectedAmbassador);
    this.ambassadorService.setSelected(this.incomingSelectedAmbassador.getAttributes());
    this.userService.setSelected(this.incomingSelectedAmbassador.getAttributes());
    this.dialog.open(DialogPasswordUserComponent, {data  : {}, width : '55%', height: '100%', });
  }



  getClient(campaign: any){
    // return this.teams.filter((client: any)=> { return client.id === campaign.client_id; })[0];
  }

  getTeamByAmbassador(ambassador: any){
    let team: any = this.app.data.teams.data.filter((team: any) => { return team.id === ambassador.team_id; })[0];
    team = this.service.only(team);
    let campaign = this.service.only(this.getCampaignByTeam(team));
    let client = this.service.only(this.getClientByCampaign(campaign));
    campaign = Object.assign(campaign, {client: client});
    team = Object.assign(team, {campaign: campaign});
    ambassador = Object.assign(ambassador, {team: team});
    return team;
  }


  getUser(ambassador: any){
    this.incomingUser.setAttributes(this.userService.getUserByUser(ambassador));
    this.incomingUser.setAttributes(Object.assign(this.incomingUser.getAttributes(), ambassador));
    this.incomingTeam.setAttributes(this.teamService.getTeamById(this.incomingUser.getTeamId()));
    this.incomingCampaign.setAttributes(this.campaignService.getCampaignById(this.incomingTeam.getCampaignId()));
    this.incomingCampany.setAttributes(this.campanyService.getCampanyById(this.incomingCampaign.getCampanyId()));
    this.incomingClient.setAttributes(this.clientService.getClientById(this.incomingCampaign.getClientId()));
    this.incomingUser.setClient(Object.assign({}, this.service.getAttributesWithoutobject(this.incomingClient.getAttributes())));
    this.incomingUser.setCampany(Object.assign({}, this.service.getAttributesWithoutobject(this.incomingCampany.getAttributes())));
    this.incomingUser.setCampaign(Object.assign({}, this.service.getAttributesWithoutobject(this.incomingCampaign.getAttributes())));
    this.incomingUser.setTeam(Object.assign({}, this.service.getAttributesWithoutobject(this.incomingTeam.getAttributes())));
    return this.incomingUser.getAttributes();
  }

  getClientByCampaign(campaign: any){
    let client: any = this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
    campaign = Object.assign(campaign, {client: client});
    return client;
  }

  getCampaignByTeam(team: any){
    let campaign: any = this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === team.campaign_id; })[0];
    team = Object.assign(team, {campaign: campaign});
    return campaign;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  routes(ambassador: any){
    this.inviteeService.routes();
  }

  onContextMenu(event: MouseEvent, inspector: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': inspector };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }



  handleDestroy(inspector: any){
    this.incomingSelectedAmbassador = new Parameters();
    this.incomingSelectedAmbassador.setAttributes(this.selectedAmbassador);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.ambassadors,
        model: this.selectedAmbassador,
        title: this.incomingSelectedAmbassador.getName(),
        url  : '/members/' + parseFloat(this.incomingSelectedAmbassador.getTeamMemberId()) + '/delete',
        key  : 'team_member_id',
        id   : this.incomingSelectedAmbassador.getTeamMemberId(),
      },
      width : 'auto',
    });
  }

  handleUpdate(inspector: any){
    this.ambassadorService.setSelected(this.incomingSelectedAmbassador.getAttributes());
    this.ambassadorService.openDialog({});
  }

  handleRestore(inspector: any){
    this.incomingSelectedAmbassador.setAttributes(this.selectedAmbassador);
    this.service.restore('/members/'   + parseFloat(this.incomingSelectedAmbassador.getTeamMemberId()) + '/restore',
    this.ambassadors, 'team_member_id', parseFloat(this.incomingSelectedAmbassador.getTeamMemberId()), this.selectedAmbassador);
  }

  openDialog(inspector: any){
    this.ambassadorService.setSelected(this.incomingSelectedAmbassador.getAttributes());
  //this.inspectorsComponent.openDialog();
  }


  showMessage(message: any) {
  }

}
