<div class="row">
    <div *ngFor="let question of questions">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="question.control.id === 3 || question.control.id === 5">
            <div class="x_panel tille ">
                <div class="x_titlle">
                    <center><h2 class="pull-leftt">{{question.label}}</h2></center>
                    <div class="clearfix"></div>
                </div>
                <div class="x_content">
                    <div>
                      <app-morris-barchart [data]="{labels: ['count'], data: question.options,element:'#ff'+question.id, xKey: 'label'}" *ngIf="question.control.id === 3"></app-morris-barchart>
                    </div>
                    <app-piechart [data]="{legend:getlabels(question.options,'label'),data: getValues(question.options,'label','count')}" *ngIf="question.control.id === 5"></app-piechart>
                </div>
            </div>
        </div>
    </div>
</div>