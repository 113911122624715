<div layout="row">
<!--    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>-->
    <i class="material-icons pull-right"
       (click)="onContextMenu($event, {})">more_vert</i>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="sawasdee20">
              <div [ngStyle]="{'color': formService.color}"
                    [innerHTML]="incomingQuestion.getData() | safeUrl">
              </div>
        </div>
    </div>
<!--    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>-->
</div>

<input type="file"
       id="logoInputFile"
       (change)="getLogo($event)"
       accept="image/*"
       [hidden]="true"
       #logoElement />

<input type="file"
       (change)="getQrcode($event)"
       accept="image/*"
       [hidden]="true"
       #elmQrcode />


<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <button mat-menu-item (click)="logoElement.click()">Upload logo</button>
        <button mat-menu-item (click)="elmQrcode.click()">Upload qrcode</button>
    </ng-template>
</mat-menu>








