<a class="pull-left"><h4>{{keys === 0 ? 'Respondent'  : incomingSelectedAmbassador.getName()}}</h4></a>
<a class="pull-right" (click)="closeDialog()" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<hr />
<input type="hidden" class="nullInput"/>
<form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)">
  <mat-list>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Name *</mat-label>
            <input matInput type="text"  formControlName="name">
<!--        <input matInput-->
<!--                   type="text"-->
<!--                   [formControl] = "userControl"-->
<!--                   (keyup)="onUserNameChanged($event)"-->
<!--                   [matAutocomplete] = "user" />-->
<!--                     <mat-autocomplete #user = "matAutocomplete" (optionSelected)="onUserSelectionChanged($event)" [displayWith]="displayFn">-->
<!--                        <mat-option *ngFor="let user of filteredUsersOptions | async; let userOf = index" [value]= "user" >-->
<!--                          {{user.name}} <i>{{' ' + user.location + ','+ user.phone}}</i>-->
<!--                        </mat-option>-->
<!--                     </mat-autocomplete>-->
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
            <span class="" mat-line>
               <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Role *</mat-label>
                    <input matInput
                           #roleElement
                           (input)="onChangeRoleText($event)"
                           type="text"
                           [formControl] = "roleControl"
                           [matAutocomplete] = "optional">
                    <i class="material-icons pull-right"
                       [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</i>
                    <mat-autocomplete #optional = "matAutocomplete"
                                      (optionSelected)="onRoleSelectionChanged($event)"
                                      [displayWith]="displayFn">
                        <mat-option *ngFor="let role of filteredRoles | async; let roleOf = index"
                                    [value]= "role">
                            {{role.display_name}}
                        </mat-option>
                    </mat-autocomplete>
<!--                   <h4 style="color: red; font-weight: normal"-->
<!--                       *ngIf="service.empty(roleElement.value) === false &&-->
<!--                          ((filteredRoles | async) | json).length === 2">-->
<!--                          **-&#45;&#45;Not Found *-&#45;&#45;</h4>-->
                </mat-form-field>
            </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
             <mat-label> Team *</mat-label>
             <input type="text"
                    #teamId
                    placeholder=""
                    aria-label = "Number"
                    matInput
                    [formControl] = "teamControl"
                    [matAutocomplete] = "team"/>
                    <mat-autocomplete #team = "matAutocomplete"
                                          (optionSelected)="onTeamSelectionChanged($event)"
                                          [displayWith]="displayFn">
                            <mat-option *ngFor="let team of filteredTeamsOptions | async; let teamOf = index" [value]= "team" >
                                {{team.name}}
<!--                            {{getTeam(team).name}}, <i class='headline' style="color: #888">{{team.campaign.name}}, {{team.campaign.client.name}} </i>-->
                            </mat-option>
                        </mat-autocomplete>
            <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}" (click)="addTeam()">add</i>
            <h4 style="color: red; font-weight: normal"
                  *ngIf="service.isNull(teamId.value) === false &&
                          ((filteredTeamsOptions | async) | json).length === 2">
                          **---Not Found *---</h4>
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Email *</mat-label>
            <input matInput type="text"  formControlName="email">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Phone *</mat-label>
            <input matInput type="text"  formControlName="phone">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item *ngIf="keys === 0 && formValues.contains('user_id') === false">
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Password *</mat-label>
            <input matInput type="password"  formControlName="password">
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <small>Photo</small>
          <div class="upload-box">
              <div class="hold">
                  <a>Maximum file size 20 MB <img src="{{photo}}" class="img-circle" width="40" height="40" alt="" *ngIf="photo"></a>
                  <span class="btn-file"> Browse File
                      <input type="file"
                             (change)="handleFileInput($event)"
                      >
                  </span>
              </div>
          </div>
        </span>
    </mat-list-item>
    <mat-list-item>
        <span class="" mat-line>
          <mat-form-field  class="example-full-width">
            <mat-label>Address *</mat-label>
            <input type="text"
                   id="address"
                   matInput
                   formControlName="location"
                   placeholder=""
                   [value]=addressValue
                   [address]="addressValue"
                   (input)="onAddressTextChange($event)"
                   (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                   (onLocationSelected)="onLocationSelected($event)"
                   matGoogleMapsAutocomplete />
          </mat-form-field>
        </span>
    </mat-list-item>
    <mat-list-item>
    <span class="" mat-line>
         <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="" formControlName="description" #message rows="1"></textarea>
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
          </mat-form-field>
    </span>
    </mat-list-item>
  </mat-list>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <center>
        <div class="example-button-row">
          <input type="submit" class="btn btn-success btn-xs submit-btn" value="{{keys === 0 ? 'Add' : 'Edit'}}"/>
        </div>
      </center>
    </div>
  </div>
</form>
