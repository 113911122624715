import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from '../../form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../app.service';
import {Parameters} from '../../../parameters';
import * as moment from 'moment';
import {NotifierService} from 'angular-notifier';
import Pusher from 'pusher-js';
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ResponseService} from "../data/response.service";

@Component({
  selector: 'app-public-forms',
  templateUrl: './feedback-forms.component.html',
  styleUrls: ['./feedback-forms.component.scss']
})
export class PublicFormsComponent implements OnInit, OnDestroy {
  departmentControl = new FormControl();
  filteredDepartmentsOptions: any;
  formId: any;
  loginForm: any;
  outgoingPostParams: any;
  appsettingsUrl = '../assets/json/app.json';
  publicFormUrl = '/public/forms/';
  app: any = {settings: null};
  form: any = {};
  posts: any = [];
  questions: any = [];
  email: any = null;
  subscription: any;
  departmentId: any = 1;
  constructor(private formService: FormService,
              private router: Router,
              private notifier: NotifierService,
              private responseService: ResponseService,
              public service: AppService,
              public broadcastChannel: BroadcastChannelService,
              private activatedRouter: ActivatedRoute) {
  }

  ngOnInit(): void {
    if(this.responseService.getUserId() === null) {
      window.localStorage.setItem('routeName', this.getRouteName());
      window.localStorage.setItem('formId', this.activatedRouter.snapshot.params.id);
      this.formService.formSelectedId = this.activatedRouter.snapshot.params.id;
      this.service.routeName = this.getRouteName();
    }
    this.form = this.formService.getSelected();
    this.formService.questions = this.form.controls;
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
    document.getElementsByTagName( 'body')[0].style.color = '#000';
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe().subscribe((item: any) => {});
    Pusher.logToConsole = false;
    const pusher = new Pusher('5080e59ce7ce64s2b2831', {});
    const channel = pusher.subscribe('pusher');
    channel.bind('suggestionbox', (data: any) => {
      this.broadcastChannel.emitNavChangeEvent({response: data.message});
    });

    this.filteredDepartmentsOptions = this.departmentControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterDepartment(name) : this.form.departments.slice())
    );
    setTimeout(() => {
      this.departmentControl.setValue(this.form.departments[this.form.departments.length - 1]);
      this.departmentId = 1;
      this.formService.questions.map((question: any) => {
        question.options.map((opt: any) => {
          this.formService.hideOptionalQuestions(opt);
        });
      });

    });
    // this.pinQuestion();
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterDepartment(name: string) {
    const filterValue = name.toLowerCase();
    return this.form.departments.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  // pinQuestion(question: any){
  //   const questions = this.form.controls;
  //   const incomingQuestions = new Parameters();
  //   incomingQuestions.setAttributes(questions);
  //   const incomingQuestion = new Parameters();
  //   incomingQuestion.setAttributes(question);
  //   const l = false;
  //   if(parseFloat(question.control.id) === 5){
  //     question.options.map((question: any) => {
  //       if(question.label.toString() === question.data.toString()) {
  //         question.questions.map((optionQuestion: any) => {
  //           const incomingOptionQuestion = new Parameters();
  //           incomingOptionQuestion.setAttributes(optionQuestion);
  //           questions.map((q: any) => {
  //             const incomingQ = new Parameters();
  //             incomingQ.setAttributes(q);
  //             if (incomingQ.getCntrlNum().toString().includes(incomingOptionQuestion.getCntrlNum().toString())) {
  //               q.visible = 1;
  //             }
  //           });
  //         });
  //       }else{
  //         question.questions.map((optionQuestion: any) => {
  //           const incomingOptionQuestion = new Parameters();
  //           incomingOptionQuestion.setAttributes(optionQuestion);
  //           questions.map((q: any, questionOf: any) => {
  //             const incomingQ = new Parameters();
  //             incomingQ.setAttributes(q);
  //             if (incomingQ.getCntrlNum().toString().includes(incomingOptionQuestion.getCntrlNum().toString())) {
  //               q.visible = 0;
  //             }
  //           });
  //         });
  //       }
  //     });
  //   }
  // }

  getCampaignByFormId(){}

  keys(){return  Object.keys(this.form).length;}

  ngSubmit(){
    this.form.controls.map((question: any) => {
      if(question.control.id === 12){} else {
        question.form_control_id  = question.control.id;
        question.data = question.data.length === 0 ? this.service.app.settings.noValue : question.data;
        this.questions.push(question);
      }
    });
    this.outgoingPostParams = new Parameters();
    this.outgoingPostParams.setAttributes({});
    this.outgoingPostParams.attributes.post_id = Math.random().toString(16).slice(2);
    this.outgoingPostParams.attributes.form_id = this.form.id;
    this.outgoingPostParams.attributes.date = moment(new Date()).format('MM/DD/YYYY');
    this.outgoingPostParams.attributes.lat  = '';
    this.outgoingPostParams.attributes.lng  = '';
    this.outgoingPostParams.attributes.department_id = this.departmentId;
    this.outgoingPostParams.attributes.status  = '0';
    this.outgoingPostParams.attributes.address = '';
    this.outgoingPostParams.attributes.controls = this.questions;
    this.outgoingPostParams.attributes.device_usage = this.service.getBrowserName();
    if(this.service.isNull(this.email) === false)
      this.outgoingPostParams.attributes.email = this.email;
    this.posts.push(this.outgoingPostParams.attributes);
    this.service.httpService( 'post', '/public/form-posts', {posts: JSON.stringify(this.posts)}, {},
      (response: any) => {
        this.posts = [];
        this.questions = [];
        this.clear();
        this.notifier.notify('success', 'Thank you for your suggetions');
        this.router.navigateByUrl('/forms/suggestions/successfull');
      },  (error: any) => {
      });


  }

  onDepartmentSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.departmentId = event.option.value.id;
  }

  clear(){
    this.form.controls.map((question: any, key: any) => {
        if(question.control.id === 12){} else {
          this.form.controls[key] = Object.assign(this.form.controls[key],{data: ''});
        }
      });
  }

  getRouteName(){return this.activatedRouter.snapshot.data.routeName; }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
