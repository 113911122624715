import { NgModule, Component, OnInit } from '@angular/core';
import {HeaderComponent} from '../header/header.component';
import {AppService} from '../app.service';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import {jqxChartComponent} from 'jqwidgets-ng/jqxchart';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})



export class HomeComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  data: Array<number> = [2, 57, 89];
  legends: Array<string> = ['Juma', 'Neema', 'Ramadhani'];
  updateFlag = false;
  lat: number = 51.678418;
  lng: number = 7.809007;
  app: any;
  piechartseriesGroups: any;
  piechartlegendLayout: any;
  piechartdataAdapter: any;
  piecharttitlePadding: any;
  piechartpadding: any;
  piechartsource: any;
  pa: any;
  species: any;
  position: any;
  getWidth(): any {
      if (document.body.offsetWidth < 850) {
          return '90%';
      }

      return 850;
  }
  constructor(public headerComponent: HeaderComponent,
              public applicationservice: AppService) {
      this.app = this.applicationservice.app;
      this.pa = this.handlePiechart();
  }
  ngOnInit(): void {
    this.species = [];
    this.species.reduce((outgoing: any, specie: any) => {
      console.log(outgoing);
      this.position = outgoing.findIndex((incoming: any) => {
        return incoming.id === specie.id;
      });
      if (this.position === -1) outgoing.push(specie); else {
        outgoing[this.position].inspected += specie.inspected;
      }
      return outgoing;
    }, []);
    if(navigator){
      navigator.geolocation.getCurrentPosition(pos => {
          this.lat = +pos.coords.latitude;
          this.lng = +pos.coords.longitude;
      });
    }
  }

  window(){ return {height: window.innerHeight}; }

    handlePiechart(){
        var bigPieTobe = [
            {category:"A", percent: 20}
            , {category:"B", percent: 30}
            , {category:"C", percent: 40}
            , {category:"Unknown", percent: 10}
        ];


        var source =
          {
              datatype: 'csv',
              datafields: [
                  { name: 'Browser' },
                  { name: 'Share' }
              ],
              url: '../assets/sampledata/mobile_browsers_share_dec2011.txt'
          };

      return  Object.assign(this.applicationservice.handlePiechart(),{ dataAdapter: new jqx.dataAdapter(bigPieTobe, { async: false, autoBind: true, loadError: (xhr: any, status: any, error: any) => {
          alert('Error loading "' + source.url + '" : ' + error); } })});
  }
}
