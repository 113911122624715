import {Component, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {Router} from '@angular/router';
import {Parameters} from '../parameters';
import {DeleteDialogComponent} from '../main/delete-dialog.component';
import {CampanyService} from './campany.service';
import {CampaniesComponent} from './campanies.component';

@Component({
  selector: 'app-table-campanies',
  templateUrl: './table-campanies.component.html',
  styleUrls: ['./table-campanies.component.scss']
})
export class TableCampaniesComponent implements OnInit, OnDestroy {

  @Input() flag: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedCampany: any;
  campanies: any;
  incomingSelectedCampany: any;
  subscription: any;
  searchCntrl: any;
  strings: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              private router: Router,
              private campanyService: CampanyService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.campanies = this.service.app.data.campanies;
    this.incomingSelectedCampany = new Parameters();
    this.strings = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);

  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if(item.hasOwnProperty(this.strings.getSearchText())) {
            this.searchCntrl = item.searchText;
            if(!(item.hasOwnProperty(this.strings.getStatus()) && item[this.strings.getStatus()] === 0))
              this.campanyService.search(this.searchCntrl);
          }
        }

      });
  }

  getCompaniesBySearchText(searchText: any){
    return this.service.app.data.campanies.data.filter((company: any) => JSON.stringify(company).toLowerCase().includes(searchText));
  }

  onContextMenu(event: MouseEvent, campany: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': campany };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  getCompany(campany: any){
    campany = Object.assign(campany, this.campanyService.getCampanyByCampany(campany));
    return campany;
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }


  handleDestroy(inspector: any){
    this.incomingSelectedCampany = new Parameters();
    this.incomingSelectedCampany.setAttributes(this.selectedCampany);
    this.dialog.open(DeleteDialogComponent, {
      data : {
        data : this.service.app.data.campanies,
        model: this.incomingSelectedCampany.getAttributes(),
        title: this.incomingSelectedCampany.getName(),
        url  : '/campanies/' + parseFloat(this.incomingSelectedCampany.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedCampany.getId(),
      },
      width : 'auto',
    });
  }

  handleUpdate(inspector: any){
    this.incomingSelectedCampany = new Parameters();
    this.incomingSelectedCampany.setAttributes(this.selectedCampany);
    this.campanyService.setSelected(this.incomingSelectedCampany.getAttributes());
    this.campanyService.openDialog({});
  }
  handleRestore(inspector: any){
    this.incomingSelectedCampany.setAttributes(this.selectedCampany);
    this.service.restore('/campanies/' + parseFloat(this.incomingSelectedCampany.getId()) + '/restore',
        this.campanies, 'id', parseFloat(this.incomingSelectedCampany.getId()), this.selectedCampany);
  }

  openDialog(inspector: any){
    this.campanyService.setSelected(this.incomingSelectedCampany.getAttributes());
    // this.inspectorsComponent.openDialog();
  }


  showMessage(message: any) {
  }

}
