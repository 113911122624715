import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";
import {Router} from "@angular/router";
import {Parameters} from "../../parameters";
import {FormService} from "../../forms/form.service";

@Component({
  selector: 'app-registra',
  templateUrl: './home-registra.component.html',
  styleUrls: ['./home-registra.component.scss']
})
export class HomeRegistraComponent implements OnInit {

  incomingForm: any;
  constructor(public service: AppService,
              private formService: FormService,
              private router: Router) {
    this.incomingForm = new Parameters();
    this.service.app = Object.assign(this.service.app,
        this.service.app.hasOwnProperty('data') ? this.service.app.data : {data: {status: 0}});
  }

  ngOnInit(): void {
  }

  onFormSelect(form: any){
    this.incomingForm.setAttributes(form);
    this.formService.setSelected(this.incomingForm.getAttributes());
    if(this.service.hasToken()) {
      this.service.authenticateUser((result: any) => {
        if(Object.keys(result).length === 0){
          this.router.navigateByUrl('/login');
        }else{
          this.service.app = Object.assign(this.service.app, {data: result});
          this.service.app.data = Object.assign(this.service.app.data, {suggestions: []});
          this.router.navigateByUrl(this.service.app.routes.app.payments.channels);
        }
      });
    }else this.router.navigateByUrl(this.service.app.routes.app.registration);
    // this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId())+'/feedback');

  }

}
