<div class="">
    <center>
        <img  class="img-circle" src="assets/images/coat.png" alt="logo" style="width: 60px; height: 60px; margin-top: 10px">
        <!--        <h2>Patten ! Get data you need at finguretips </h2>-->
    </center>
</div>
<section class="call-action-section">
    <div class="containerr">
        <div class="text-box" style="margin-left: 10px;margin-right: 10px">
            <h2>Open Project</h2>
            <p>Welcome to patten survey tool start open new project and experience data collection easily than ever ! as whether you’re a small or large business owner navigating challenging times, or just someone who wants to support your community, our tool will  help you to collect data and analyze to get insights for what you needs.</p>
            <p>No matter the number of respondents you have, our survey tool as many features that will enable you to
                create variety of online surveys such as market research surveys and customer feedback surveys even on your mobile phone.
                Also offer several advanced survey questions, from multiple-choice questions to start rating and likert scale.
            </p>
            <a (click)="openCampaign()" class="btn-get pull-left cursor">Start opening new project now</a>
        </div>
        <div class="hidden-md hidden-sm hidden-xs pull-right" style="margin-right: 50px">
            <div style="width:500px;height:300px;background-color: #FFFFFF; border-radius: 3px">
            </div>
        </div>
    </div>
</section>
