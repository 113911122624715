import { Injectable } from '@angular/core';
import {Parameters} from '../parameters';
import * as moment from 'moment';
import {AppService} from "../app.service";
import {MatDialog} from '@angular/material/dialog';
import {DialogRoutesComponent} from "./dialog/dialog-routes.component";

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  report: any;

  /**
   * @param mixed Data
   */

  data: any = [];

  /**
   * @param mixed Data
   */


  selected: any = {};


  getData(): void {
    return this.data;
  }

  /**
   * @param mixed Data
   */


  setData(data: any): void {
    this.data = data;
  }

  getSelected(): void {
    return this.selected;
  }

  /**
   * @param mixed Selected
   */

  setSelected(selected: any): void {
    this.selected = selected;
  }


  constructor(private service: AppService,
              private dialog: MatDialog) {

    this.report = new Parameters();
    /**
     * @param mixed report
     */
    this.report.setAttributes({});

    /**
     * @param mixed report
     */

    this.report.setTime('datebetween');

    /**
     * @param mixed report
     */

    this.report.setTarget('all');

    /**
     * @param mixed report
     */

    this.report.setType('animal-identifications');

    /**
     * @param mixed report
     */

    this.report.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

    /**
     * @param mixed report
     */

    this.report.setDisplay('dashbaord');

    /**
     * @param mixed report
     */

    this.report.setData(null);
  }

  getUserByUser(user: any){
    var users: any = [] = this.service.app.data.users.data.filter((item: any) => {
      return parseFloat(item.id) === parseFloat(user.id); });
    return users.length === 0 ? this.appendRoute(user) : users[0];
  }

  appendRoute(route: any){
    this.service.app.data.users.data.unshift(route);
    return route;
  }

  addRoute(params: any, call: any){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes(params);
    this.service.httpService('POST', this.service.app.routes.routes.add,
        outgoingRequest.getAttributes(),
        {ignoreLoadingBar : 'true', notify: 'false'},
        (route: any) => {
          call(route);
        }, (error: any) => {
          call(null);
        });
  }

  getUserById(userId: any){
    var users: any = [] = this.service.app.data.users.data.filter((user: any) => {
      return parseFloat(user.id) === parseFloat(userId);});
    return users[0];
  }

  openDialog(route: any){
    this.dialog.open(
        DialogRoutesComponent, {
          data  : {route: route},
          width : '60%',
          height: '100%',
          disableClose: false,
        },
    );
  }

  openChangePasswordDialog(params: any){
    this.dialog.open(DialogRoutesComponent, {data  : {}, width : '55%', height: '100%', });
  }


  search(params: any){
    this.service.httpService('post', this.service.app.routes.routes.search, params, {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            response.map((item: any) => {
              result = [];
              result = this.service.app.data.routes.data.filter((itm: any) => {
                return parseFloat(item.id) === parseFloat(itm.id);
              });if(result.length === 0){
                this.service.app.data.routes.data.push(item);
              }
            });
          });
        }, (error: any) => {
        });
  }
}

