<mat-drawer-container
        class="example-container"
        [hasBackdrop]="false">
    <mat-drawer #drawer
                [mode]="'side'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <mat-icon (click)="drawer.toggle()"
                      class="pull-right">close</mat-icon>
            <div [ngStyle]="{'margin-top':'100px'}">
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <!--        <table [ngStyle]="{'position':'absolute','top':'5xp','left':'5px','background-color':'transparent','width':'100%','z-index': '999999999'}">-->
        <!--            <tr>-->
        <!--                <td valign="top"-->
        <!--                    [ngStyle]="{'width':'100%'}">-->
        <!--                    <a class="pull-left cursor" *ngIf="!drawer.opened"><img src="../../assets/images/option-icon.png" (click)="drawer.toggle()"></a>-->
        <!--                </td>-->
        <!--                <td valign="top" >-->
        <!--                    <div [ngStyle]="{'margin-right':'10px','border-radius': '25px','background':'#fff'}" class="mdl-shadow&#45;&#45;2dp">-->
        <!--                        <table [ngStyle]="{'min-width':'350px','margin':'5px'}">-->
        <!--                            <td [ngStyle]="{'width':'100%'}">-->
        <!--                                <input  [ngStyle]="{'scaleX':'1.37','color':'#000','text-align':'center','border-width':'0px','width':'100%'}"-->
        <!--                                        autocomplete="off"-->
        <!--                                        ngClass="sawasdee18 searchbar"-->
        <!--                                        #addressId-->
        <!--                                        placeholder=""-->
        <!--                                        (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"-->
        <!--                                        (onLocationSelected)="onLocationSelected($event)"-->
        <!--                                        matGoogleMapsAutocomplete>-->
        <!--                            </td>-->
        <!--                            <td>-->
        <!--                                <mat-icon matSuffix >search</mat-icon>-->
        <!--                            </td>-->
        <!--                        </table>-->
        <!--                    </div>-->
        <!--                </td>-->
        <!--            </tr>-->
        <!--        </table>-->
        <div class="agm">
            <agm-map
                #agm
                [latitude]="lat"
                [longitude]="lng"
                [fitBounds]="false"
                [zoom]="this.zoom"
                [disableDefaultUI]="true"
                [styles]="service.app.settings.map.styles.style1"
                [ngStyle]="{'height': this.service.getHeight() + 'px', 'width':this.service.getWidth() + 'px'}">
                <agm-map-type-control position="LEFT_BOTTOM"></agm-map-type-control>
                <agm-zoom-control position="RIGHT_BOTTOM"></agm-zoom-control>
                <ng-container *ngIf="this.incomingResponse.getAttributes().hasOwnProperty(strings.getId())">
                    <agm-circle [latitude]="service.parseFloat(incomingResponse.getLat())"
                                [longitude]="service.parseFloat(incomingResponse.getLng())"
                                [radius]="5000"
                                [fillColor]="'#2596be'"
                                [circleDraggable]="false"
                                [editable]="false">
                    </agm-circle>
                    <agm-marker
                            [latitude]="service.parseFloat(incomingResponse.getLat())"
                            [longitude]="service.parseFloat(incomingResponse.getLng())"
                            [agmFitBounds]="true"
                            [iconUrl]="this.pin">
                    </agm-marker>
                </ng-container>
                <ng-container *ngFor="let deliveryUser of deliveryUsers">
                    <agm-direction
                            [visible]="true"
                            [renderOptions] = "{suppressMarkers: true, polylineOptions: {strokeColor : '#ff8000'}}"
                            [provideRouteAlternatives]="false"
                            [waypoints]="deliveryUser.waypoints"
                            [origin]="deliveryUser.route.origin"
                            [destination]="deliveryUser.route.destination"
                            *ngIf="deliveryUser.hasOwnProperty(strings.getWaypoints()) && deliveryUser.waypoints.length !== 0">
                    </agm-direction>
                    <ng-container *ngIf="deliveryUser.hasOwnProperty(strings.getWaypoints()) && deliveryUser.waypoints.length !== 0">
                        <agm-marker
                                [latitude]="service.parseFloat(waypoint.location.lat)"
                                [longitude]="service.parseFloat(waypoint.location.lng)"
                                [agmFitBounds]="true"
                                [iconUrl]="l === 0 ? this.agmIcon : this.marker3"
                                *ngFor="let waypoint of deliveryUser.waypoints; let l = index">
                        </agm-marker>
                    </ng-container>
<!--                    <agm-marker-->
<!--                            [latitude]="service.parseFloat(deliveryUser.route.origin.lat)"-->
<!--                            [longitude]="service.parseFloat(deliveryUser.route.origin.lng)"-->
<!--                            [agmFitBounds]="false"-->
<!--                            [iconUrl]="this.originIcon">-->
<!--                    </agm-marker>-->
<!--                    <agm-marker-->
<!--                            [latitude]="service.parseFloat(deliveryUser.route.destination.lat)"-->
<!--                            [longitude]="service.parseFloat(deliveryUser.route.destination.lng)"-->
<!--                            [markerDraggable]="true"-->
<!--                            [agmFitBounds]="true"-->
<!--                            [openInfoWindow]="true"-->
<!--                            [iconUrl]="this.destinationIcon">-->
<!--                        <agm-info-window-->
<!--                                [isOpen]="false"-->
<!--                                #infoWindow>-->
<!--                            <div [ngStyle]="{'font-size':'14px','font-family':'Sawasdee','-webkit-transform': 'scale(1.15,0.77)','color':'rgb(90, 115, 142)','padding':'10px'}">-->
<!--                                {{deliveryUser.route.distance}},{{deliveryUser.route.duration}}-->
<!--                            </div>-->
<!--                        </agm-info-window>-->
<!--                    </agm-marker>-->
                </ng-container>
            </agm-map>
        </div>
    </mat-drawer-content>
</mat-drawer-container>



