import {AfterViewInit, Component, Inject, NgZone, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {ResponseService} from "../../../forms/responses/data/response.service";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {ClientService} from "../../../clients/client.service";
import {NgxQrcodeStylingService} from "ngx-qrcode-styling";
import {FormService} from "../../../forms/form.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Parameters} from "../../../parameters";
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-dialog-invitation-card',
  templateUrl: './dialog-invitation-card.component.html',
  styleUrls: ['./dialog-invitation-card.component.scss']
})

export class DialogInvitationCardComponent implements OnInit, AfterViewInit {
  incomingForm: any;
  incomingResponse: any;
  incomingSettingsForm: any;
  incomingNameQuestion: any;
  incomingPhoneQuestion: any;
  incomingQuestionResult: any;
  incomingEmailQuestion: any;
  incomingInvitationCardQuestion: any;
  incomingInvitationQuestion: any;
  incomingQrcodeQuestion: any;
  incomingSettingsQuestion: any;
  incomingAttendeePhoto: any;
  incomingSalutation: any;
  outgoingQuestion: any;
  outgoingRequest: any;
  incomingQuestion: any;
  incomingResponseResult: any;
  incomingClient: any;
  incomingCampaign: any;
  incomingResult: any;
  incomingStrings: any;
  url: any;
  logo: any;
  frame: any;
  config: any = {
    width: 300,
    height: 300,
    data: '',
    image: '',
    margin: 0,
    dotsOptions: {
      color: "#000",
      type: "dots"
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  incomingParams: any;
  constructor(public  service: AppService,
              public zone: NgZone,
              private responseService: ResponseService,
              public  broadcastChannelService: BroadcastChannelService,
              public clientService: ClientService,
              private qrcode: NgxQrcodeStylingService,
              private formService : FormService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogInvitationCardComponent>) {
    this.incomingForm = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingResponse = new Parameters();
    this.incomingSettingsForm = new Parameters();
    this.incomingStrings = new Parameters();
    this.incomingNameQuestion = new Parameters();
    this.incomingAttendeePhoto = new Parameters();
    this.incomingPhoneQuestion = new Parameters();
    this.incomingParams = new Parameters();
    this.incomingSalutation = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingEmailQuestion = new Parameters();
    this.incomingInvitationQuestion = new Parameters();
    this.incomingInvitationCardQuestion = new Parameters();
    this.incomingResponseResult = new Parameters();
    this.incomingQrcodeQuestion = new Parameters();
    this.outgoingQuestion = new Parameters();
    this.incomingSettingsQuestion = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingClient = new Parameters();
    this.incomingCampaign = new Parameters();
    this.incomingQuestionResult = new Parameters();
    this.incomingAttendeePhoto.setAttributes({});
    this.incomingParams.setAttributes({});
  }

  ngOnInit(): void {
    this.incomingStrings.setAttributes(this.service.app.strings.params);
    this.incomingForm.setAttributes(this.formService.getSelected());
    this.incomingClient.setAttributes(this.clientService.getSelected());
    this.incomingResponse.setAttributes(this.data.response);
    this.incomingParams.setAttributes(this.data.hasOwnProperty('params') ?
        this.data.params : this.incomingParams.getAttributes());
    this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
    this.incomingSettingsForm.setDateOnSet(this.incomingSettingsForm.attributes.hasOwnProperty('date_on_set') ?
        this.incomingSettingsForm.getDateOnSet() : '');
    this.incomingSettingsForm.setVenue(this.incomingSettingsForm.attributes.hasOwnProperty('venue') ?
        this.incomingSettingsForm.getVenue() : '');
    this.incomingSettingsForm.setTime(this.incomingSettingsForm.attributes.hasOwnProperty('time') ?
        this.incomingSettingsForm.getTime() : '');
    this.incomingSettingsForm.setAttendeePic(this.incomingSettingsForm.attributes.hasOwnProperty(
        this.service.app.strings.params.attendeePhoto) ?
        this.incomingSettingsForm.getAttendeePic() : '');
    this.incomingSettingsForm.setFrameUrl(this.incomingSettingsForm.attributes.hasOwnProperty('frame_url') ?
        this.incomingSettingsForm.getFrameUrl() : '');
    this.incomingSettingsForm.setBanner(this.incomingSettingsForm.attributes.hasOwnProperty('banner') ?
        this.incomingSettingsForm.getBanner() : '');
    this.incomingSettingsForm.setFrameDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('frame_data_url') ?
        this.incomingSettingsForm.getFrameDataUrl() : '');
    this.incomingSettingsForm.setContact(this.incomingSettingsForm.attributes.hasOwnProperty('contact') ?
        this.incomingSettingsForm.getContact() : '');
    this.incomingSettingsForm.setDeclinedLandingPageContents(this.incomingSettingsForm.attributes.hasOwnProperty('declined_landing_page_contents') ?
        this.incomingSettingsForm.getDeclinedLandingPageContents() : '');
    this.incomingSettingsForm.setRSVPDate(this.incomingSettingsForm.attributes.hasOwnProperty('rsvp_date') ?
        this.incomingSettingsForm.getRSVPDate() : '');
    this.incomingSettingsForm.setAttendingDate(this.incomingSettingsForm.attributes.hasOwnProperty('attending_date') ?
        this.incomingSettingsForm.getAttendingDate() : '');
    this.incomingSettingsForm.setJobPosition(this.incomingSettingsForm.attributes.hasOwnProperty('job_position') ?
        this.incomingSettingsForm.getJobPosition() : '');
    this.incomingSettingsForm.setResponseChannel(this.incomingSettingsForm.attributes.hasOwnProperty('response_channel') ?
        this.incomingSettingsForm.getResponseChannel() : '');
    this.incomingSettingsForm.setAdvertUrl(this.incomingSettingsForm.attributes.hasOwnProperty('advert_url') ?
        this.incomingSettingsForm.getAdvertUrl() : '');
    this.incomingSettingsForm.setInvitationCard(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_card') ?
        this.incomingSettingsForm.getInvitationCard() : '');
    this.incomingSettingsForm.setBackgroundColor(this.incomingSettingsForm.attributes.hasOwnProperty('background_color') ?
        this.incomingSettingsForm.getBackgroundColor() : '');
    this.incomingSettingsForm.setContactPersonName(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_name') ?
        this.incomingSettingsForm.getContactPersonName() : '');
    this.incomingSettingsForm.setContactPersonPhone(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_phone') ?
        this.incomingSettingsForm.getContactPersonPhone() : '');
    this.incomingSettingsForm.setInvitationContents(this.incomingSettingsForm.attributes.hasOwnProperty('invitation_contents') ?
        this.incomingSettingsForm.getInvitationContents() : '');
    this.incomingSettingsForm.setContactPersonTitle(this.incomingSettingsForm.attributes.hasOwnProperty('contact_person_title') ?
        this.incomingSettingsForm.getContactPersonTitle() : '');
    this.incomingSettingsForm.setCompanyName(this.incomingSettingsForm.attributes.hasOwnProperty('company_name') ?
        this.incomingSettingsForm.getCompanyName() : '');
    this.incomingSettingsForm.setAttendeeName(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_name') ?
        this.incomingSettingsForm.getAttendeeName() : '');
    this.incomingSettingsForm.setAttendeePhone(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_phone') ?
        this.incomingSettingsForm.getAttendeePhone() : '');
    this.incomingSettingsForm.setAttendeeEmail(this.incomingSettingsForm.attributes.hasOwnProperty('attendee_email') ?
        this.incomingSettingsForm.getAttendeeEmail() : '');
    this.incomingSettingsForm.setColor(this.incomingSettingsForm.attributes.hasOwnProperty('color') ?
        this.incomingSettingsForm.getColor() : '');
    this.incomingSettingsForm.setConfirmationContents(this.incomingSettingsForm.attributes.hasOwnProperty('confirmation_contents') ?
        this.incomingSettingsForm.getConfirmationContents() : '');
    this.incomingSettingsForm.setSalutation(this.incomingSettingsForm.attributes.hasOwnProperty('salutation') ?
        this.incomingSettingsForm.getSalutation() : '');
    this.incomingSettingsForm.setLogoUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_url') ?
        this.incomingSettingsForm.getLogoUrl() : '');
    this.incomingSettingsForm.setLogoDataUrl(this.incomingSettingsForm.attributes.hasOwnProperty('logo_data_url') ?
        this.incomingSettingsForm.getLogoDataUrl() : '');
    this.incomingForm.getControls().map((question: any) => {
      this.incomingQuestion.setAttributes(question);
      if(this.incomingResponse.getAttributes().hasOwnProperty('datas')){
        this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
          this.incomingResponseResult.setAttributes(responseResult);
          if(this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()){
            this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
            this.incomingQuestion.setData(this.incomingResponseResult.getData());
            this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
            this.incomingQuestion.setPostId(this.incomingResponse.getId());
          }
        });
      }else{
        this.incomingQuestion.setData(this.incomingQuestion.getAttributes().hasOwnProperty('data') ?
            this.incomingQuestion.getData():'');
        this.incomingQuestion.setResult(this.incomingQuestion.getAttributes().hasOwnProperty('result') ?
            this.incomingQuestion.getResult():{});
      }
    });
    this.setControls((result: any)=>{
      this.frame =  this.incomingSettingsForm.getFrameDataUrl();
      this.incomingInvitationCardQuestion.setData(
          this.incomingInvitationCardQuestion.getData().
          replaceAll(this.service.app.strings.params.event.logo,this.incomingSettingsForm.getLogoDataUrl()).
          replaceAll(this.service.app.strings.params.event.frame,this.frame).
          replaceAll(this.service.app.strings.params.event.name,this.incomingNameQuestion.getData()).
          replaceAll(this.service.app.strings.params.event.phone,this.incomingPhoneQuestion.getData()).
          replaceAll(this.service.app.strings.params.event.event,this.incomingForm.getName()).
          replaceAll(this.service.app.strings.params.event.salutation,this.incomingSalutation.getData()).
          replaceAll(this.service.app.strings.params.event.time,this.incomingSettingsForm.getTime()).
          replaceAll(this.service.app.strings.params.event.photo,this.incomingAttendeePhoto.getData())
      );
      this.incomingResult.setAttributes(
          this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('result') &&
          !this.service.isNull(this.incomingQrcodeQuestion.getResult() ? this.incomingQrcodeQuestion.getResult() : {}));
      if(!this.incomingResult.getAttributes().hasOwnProperty('src')){
        this.service.setCrypt(this.incomingResponse.getCntrlNum(),(result: any)=>{
          this.config.data = window.location.host +
              this.service.app.strings.params.appInvitations + result +
              this.service.app.strings.params.confirmationQ;
          this.config.data = this.config.data.toString().includes(this.service.app.strings.params.https) ? this.config.data : this.service.app.strings.params.https + this.config.data;
          if (!this.service.isNull(this.incomingSettingsForm.getBackgroundColor())) this.config.data = this.config.data + this.service.app.strings.params.extension + this.incomingSettingsForm.getBackgroundColor();
          if (!this.service.isNull(this.service.logo)) this.config.data = this.config.data + this.service.app.strings.params.access + this.incomingClient.getLogo().split('clients/')[1];
          this.incomingQrcodeQuestion.setUnknown('data', this.config.data);
          setTimeout((result)=>{
            let elementHtml:any = document.getElementById('qrcode');
            this.qrcode.create(this.config, elementHtml).subscribe((res) => {
              setTimeout(() => {
                html2canvas(elementHtml).then(canvas => {
                  this.zone.run(() =>{
                    this.incomingQrcodeQuestion.getResult().path = canvas.toDataURL("image/png");
                    this.incomingInvitationCardQuestion.setData(
                        this.incomingInvitationCardQuestion.getData().
                        replaceAll(this.service.app.strings.params.event.qrcode, this.incomingQrcodeQuestion.getResult().path));
                    let doc: any = document.getElementById('card');
                    if(!this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus())) {
                      this.getImageCanvas(doc, (blob: any) => { this.sendCanvas(blob);});
                    }else this.getPDFCanvas(doc, (blob: any) => { this.sendCanvas(blob);});
                  });
                });
              },this.service.timeout()*3);
            });
          });
        });
      }else{
        if(this.incomingResult.getAttributes().hasOwnProperty('src')){
          if(!this.service.isNull(this.incomingResult.getSrc())){
            this.incomingQrcodeQuestion.setData(
                this.incomingQrcodeQuestion.getData().replaceAll('*qrcode',
                    this.incomingResult.getPath()));
          }
        }
        // this.dialogRef.close();
        // setTimeout(() => {
        //   this.broadcastChannelService.emitNavChangeEvent({
        //         action: 'afterCloseDialog',
        //         response:this.incomingResponse.getAttributes()});
        // });
      }
    });
  }

  getImageCanvas(doc: any, callback: any){
    setTimeout(() => {
      html2canvas(doc).then(canvas => {
        let blob: any = this.service.dataURItoBlob(canvas.toDataURL('image/png'));
        callback(blob);
      });
    });
  }

  sendCanvas(blob: any){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setSrc(null);
    this.outgoingRequest.setFlag(0);
    this.outgoingRequest.setCaption('');
    this.outgoingRequest.setOrderBy('');
    this.outgoingRequest.setUrl('');
    this.outgoingRequest.setPath('');
    this.outgoingRequest.setAvatar(blob);
    if(this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus())) {
      this.outgoingRequest.setExtension('pdf');
    }else this.outgoingRequest.setStatus(1);
    this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},(result: any) => {
          this.incomingResult.setAttributes(result);
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setFlag('whatsapp');
          this.outgoingRequest.setResponseId(this.incomingResponse.getId());
          this.outgoingRequest.setFormId(this.incomingForm.getId());
          this.outgoingRequest.setData(this.service.app.strings.invitation.card.receive.toString());
          this.outgoingRequest.setData(
              this.outgoingRequest.getData().toString()
                  .replaceAll(this.service.app.strings.params.event.logo, '*logo')
                  .replaceAll(this.service.app.strings.params.event.event, this.incomingForm.getName())
                  .replaceAll(this.service.app.strings.params.event.venue, this.incomingSettingsForm.getVenue())
                  .replaceAll(this.service.app.strings.params.event.time, this.incomingSettingsForm.getTime())
                  .replaceAll(this.service.app.strings.params.event.name, this.incomingNameQuestion.getData())
                  .replaceAll(this.service.app.strings.params.event.attendingDate, this.incomingSettingsForm.getAttendingDate())
                  .replaceAll(this.service.app.strings.params.event.regards, this.incomingSettingsForm.getContact())
          );
          if(this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus())) {
            this.incomingInvitationCardQuestion.setResult(
                this.incomingInvitationCardQuestion.getAttributes().hasOwnProperty(this.incomingStrings.getResult()) &&
                !this.service.isNull(this.incomingInvitationCardQuestion.getResult()) ?
                    this.incomingInvitationCardQuestion.getResult() : {});
            let incomingResult = new Parameters();
            incomingResult.setAttributes(this.incomingInvitationCardQuestion.getResult());
            incomingResult.setSrc(this.incomingResult.getSrc());
            this.incomingInvitationCardQuestion.getResult().src = this.incomingResult.getSrc();
            this.updateInvitationCard(() => {});
          }
          this.outgoingRequest.setClientName(this.incomingClient.getName());
          this.outgoingRequest.setLogoDataUrl(this.logo);
          this.outgoingRequest.setEventName(this.incomingForm.getName() + ': Invitation card');
          this.outgoingRequest.setRecipientEmail(this.incomingEmailQuestion.getData());
          this.outgoingRequest.setSenderEmail(this.incomingClient.getEmail());
          this.outgoingRequest.setRecipientPhone(this.incomingPhoneQuestion.getData());
          this.outgoingRequest.setPdfUrl(this.incomingResult.getSrc());
          this.outgoingRequest.setClient(this.incomingClient.getName());
          this.outgoingRequest.setFlag('whatsapp');
          this.outgoingRequest.setStatus(1);
          this.dialogRef.close(null);
          this.sendInvitationCard(this.outgoingRequest.getAttributes(), () => {
            this.responseService.isDialogOpened = false;
            this.dialogRef.close(null);
            let incomingAction: any = new Parameters();
            incomingAction.setAttributes({});
            incomingAction.setAction('afterCloseDialog');
            incomingAction.setResponse(this.incomingResponse.getAttributes());
            if(this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus()))
              incomingAction.setStatus(0);
            setTimeout(() => {
              this.broadcastChannelService.emitNavChangeEvent(incomingAction.getAttributes());
            }, this.service.timeout());
          });
          if(!this.service.isNull(this.incomingEmailQuestion.getData())){
            this.outgoingRequest.setFlag('email');
            this.sendInvitationCard(this.outgoingRequest.getAttributes(), () =>{
              this.sendInvitationCard(this.outgoingRequest.getAttributes(), () => {});
            });
          }
        }, (error: any) => {
        });
  }

  getPDFCanvas(doc: any,callback: any){
    setTimeout(() => {
      html2pdf()
          .from(doc)
          .set(this.getOptions())
          .toPdf()
          .output('datauristring')
          .then((pdfBase64: any) => {
            let blob: any = this.service.dataURItoBlob(pdfBase64);
            callback(blob);
          });
    });
  }

  sendInvitationCard(request: any, callback: any){
    this.outgoingRequest.setAttributes(request);
    this.url = this.service.app.routes.invitations.card.delivery;
    this.url = this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus()) &&
        this.outgoingRequest.getFlag().includes('whatsapp') ? this.service.app.routes.invitations.whatsapp : this.url;
    this.url = this.incomingParams.getAttributes().hasOwnProperty(this.incomingStrings.getStatus()) &&
    this.outgoingRequest.getFlag().includes('email') ? this.service.app.routes.invitations.email : this.url;
    this.service.httpService('post', this.url,
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},(result: any) => {
          callback();
        }, (error: any) => {
          this.sendInvitationCard(this.outgoingRequest.getAttributes(), callback);
        });
  }

  getOptions(){
    const opt: any = {
      margin: 1,
      filename: 'invitation-card.pdf',
      image: { type: 'jpeg', quality: 0.20 },
      html2canvas: {scale: 7, logging: true},
//      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
    };
  }

  updateInvitation(callback: any){
    this.outgoingRequest.setAttributes({});
    if(this.incomingInvitationQuestion.getAttributes().hasOwnProperty('response_result'))
      this.outgoingRequest.setId(this.incomingInvitationQuestion.getResponseResultId());
    this.outgoingRequest.setData(this.incomingInvitationQuestion.getData());
    this.outgoingRequest.setFormControlId(this.incomingInvitationQuestion.getId());
    this.outgoingRequest.setPostId(this.incomingResponse.getId());
    this.outgoingRequest.setControlId(this.incomingInvitationQuestion.getControlId());
    this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingInvitationQuestion.getResult())));
    // this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
    //   callback(null);
    // }, (error: any) =>  {callback(null);});
  }

  updateInvitationCard(callback: any){
    let outgoingRequest: any = new Parameters();
    outgoingRequest.setAttributes({});
    if(this.incomingInvitationCardQuestion.getAttributes().hasOwnProperty(this.incomingStrings.getResponseResultId()) &&
        this.incomingInvitationCardQuestion.getResponseResultId() !== parseFloat('0'))
      outgoingRequest.setId(this.incomingInvitationCardQuestion.getResponseResultId());
    outgoingRequest.setData('null');
    outgoingRequest.setFormControlId(this.incomingInvitationCardQuestion.getId());
    outgoingRequest.setPostId(this.incomingResponse.getId());
    outgoingRequest.setControlId(this.incomingInvitationCardQuestion.getControlId());
    outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingInvitationCardQuestion.getResult())));
    this.service.httpService('post',
        this.service.app.routes.forms.responses.responseResults.update.toString(),
        outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
          callback(null);
        }, (error: any) =>  {callback(null);});
  }

  updateQrcode(callback: any){
    this.outgoingRequest.setAttributes({});
    if(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('response_result'))
      this.outgoingRequest.setId(this.incomingQrcodeQuestion.getResponseResultId());
    this.outgoingRequest.setData(this.incomingQrcodeQuestion.getData());
    this.outgoingRequest.setFormControlId(this.incomingQrcodeQuestion.getId());
    this.outgoingRequest.setPostId(this.incomingResponse.getId());
    this.outgoingRequest.setControlId(this.incomingQrcodeQuestion.getControlId());
    this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.incomingQrcodeQuestion.getResult())));
    // this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
    //   callback(null);
    // }, (error: any) =>  {callback(null);});
  }

  setControls(callback: any){
    this.incomingInvitationQuestion.setAttributes({});
    this.incomingInvitationCardQuestion.setAttributes({});
    this.incomingNameQuestion.setAttributes({});
    this.incomingPhoneQuestion.setAttributes({});
    this.incomingEmailQuestion.setAttributes({});
    this.incomingQrcodeQuestion.setAttributes({});
    this.incomingSalutation.setAttributes({});
    this.incomingForm.getControls().map((question: any)=>{
      this.outgoingQuestion.setAttributes(question);
      this.incomingSettingsQuestion.setAttributes(Object.assign({},this.outgoingQuestion.getSettings()));
      if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationCard()){
        this.incomingInvitationCardQuestion.setAttributes(this.outgoingQuestion.getAttributes());
        this.incomingInvitationCardQuestion.setData(this.incomingInvitationCardQuestion.getUnknown('label'));
      }else if(parseFloat(this.outgoingQuestion.getControlId()) === 33){
        this.incomingQrcodeQuestion.setAttributes(this.outgoingQuestion.getAttributes());
        this.incomingQrcodeQuestion.setResult(this.incomingQrcodeQuestion.getAttributes().hasOwnProperty('result') ?
            this.incomingQrcodeQuestion.getResult():{});
        this.incomingQrcodeQuestion.setResult(!this.service.isNull(this.incomingQrcodeQuestion.getResult()) ?
            this.incomingQrcodeQuestion.getResult():{});
        this.incomingQuestionResult.setAttributes(this.incomingQrcodeQuestion.getResult());
        this.incomingQuestionResult.setPath(this.incomingQuestionResult.getAttributes().hasOwnProperty('path') ?
            this.incomingQuestionResult.getPath():'');
      }else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getInvitationContents()){
        this.incomingInvitationQuestion.setAttributes(this.outgoingQuestion.getAttributes());
        this.incomingInvitationQuestion.setData(this.incomingInvitationQuestion.getUnknown('label'));
      }else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeName())
        this.incomingNameQuestion.setAttributes(this.outgoingQuestion.getAttributes());
      else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeePhone())
        this.incomingPhoneQuestion.setAttributes(this.outgoingQuestion.getAttributes());
      else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeeEmail())
        this.incomingEmailQuestion.setAttributes(this.outgoingQuestion.getAttributes());
      else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getSalutation())
        this.incomingSalutation.setAttributes(this.outgoingQuestion.getAttributes());
      else if(this.outgoingQuestion.getCntrlNum() === this.incomingSettingsForm.getAttendeePic())
        this.incomingAttendeePhoto.setAttributes(this.outgoingQuestion.getAttributes());
    });
    callback(null);
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
    });
  }

}
