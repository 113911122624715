import { Component, OnInit } from '@angular/core';
import {ItemService} from "../../items/item.service";

@Component({
  selector: 'app-sections-items',
  templateUrl: './sections-items.component.html',
  styleUrls: ['./sections-items.component.scss']
})
export class SectionsItemsComponent implements OnInit {

  constructor(public itemService: ItemService) { }

  ngOnInit(): void {
  }

}
