import {Component, OnDestroy,  OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {Parameters} from '../../parameters';
import {FormService} from '../form.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogAmbassadorsComponent} from '../../ambassadors/dialog-ambassadors/dialog-ambassadors.component';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';

@Component({
  selector: 'app-dialog-invite-forms',
  templateUrl: './dialog-invite-forms.component.html',
  styleUrls: ['./dialog-invite-forms.component.scss']
})
export class DialogInviteFormsComponent implements OnInit, OnDestroy {
  incomingInvitee: any;
  incomingSpecie: any;
  invitees: any;
  parameters: any;
  searchCntrl: any;
  form: any;
  subscription: any;
  position: any;
  filteredUsersOptions: any;
  userControl = new FormControl();
  formValues: FormGroup = new FormGroup({});
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public formMetthods: FormService) {
    this.incomingInvitee = new Parameters();
    this.incomingInvitee.setAttributes({});
    this.incomingSpecie = new Parameters();
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.invitees = this.formMetthods.invitees;
    this.form = this.formMetthods.getSelected();
  }

  formInvitees(){ return this.service.app.data.invitees.data.filter((invitee: any) => { return parseFloat(invitee.form_id) === parseFloat(this.form.id); });}
  ngOnDestroy(): void { this.subscription.unsubscribe(); }
  ngOnInit(): void {

      this.formValues = this.formBuilder.group({
          name: ['', Validators.compose([])],
      });

      this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
          .subscribe((item: any) => {
              if(item instanceof Object){
                  if (item.hasOwnProperty('searchText') === true) {
                      this.searchCntrl = item.searchText;
                  }else if (item.hasOwnProperty('ambassador') === true) {
                  }
              }

          });
      this.userControl.setValue({});
      setTimeout(() => {
          this.filteredUsersOptions = this.userControl.valueChanges.pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filterUser(name) : this.ambassadors().slice())
          );
      });
  }

  ambassadors(){
      return this.service.app.data.ambassadors.data.filter((ambassador: any) => { return parseFloat(ambassador.campaign_id) === parseFloat(this.form.campaign_id);});
  }

  displayFn(value: any){
        return value.name;
  }

  private _filterUser(name: string) {
        const filterValue = name.toLowerCase();
        return this.ambassadors().filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }


  onAmbassadorSelectionChanged(event: MatAutocompleteSelectedEvent, input: any){
      this.parameters = new Parameters();
      this.parameters.setAttributes({});
      this.parameters.setFormId(this.form.id);
      this.parameters.setMemberId(event.option.value.team_member_id);
      this.parameters.setUserId(event.option.value.id);
      this.service.httpService('post',
          '/form-users',
          this.parameters.getAttributes(), {ignoreLoadingBar: 'false', notify: 'false'}, (response: any) => {
             this.position = this.service.app.data.invitees.data.findIndex((invitee: any) => { return parseFloat(invitee.form_user_id) === parseFloat(response.form_user_id);});
             if(this.position === -1) {
                 this.service.app.data.invitees.data.unshift(response);
                 this.notifier.notify('success', 'successfull added');
                 input = '';
             }
          }, (error: any) => {});
  }




    removeInvitee(invitee: any){
      this.service.httpService('post',
          '/form-users/' + parseFloat(invitee.form_user_id) + '/delete',
          this.parameters.getAttributes(), {ignoreLoadingBar: 'false'}, (response: any) => {
          this.position = this.service.app.data.invitees.data.findIndex((invit: any) => { return parseFloat(invit.form_user_id) === parseFloat(invitee.form_user_id); });
              this.service.app.data.invitees.data.splice(this.position, 1);
              this.notifier.notify('success', 'successfull removed');
          }, (error: any) => {});
  }

  addInvitee(){
      this.notifier.notify('success', 'add User');
      this.dialog.open(DialogAmbassadorsComponent, {data  : {}, width : '55%', height: '100%', });
  }

  handleChecked(disease: any, checked: any){
    setTimeout((position: number) => {
      this.parameters.setAttributes({});
      this.incomingInvitee.setAttributes(disease);
      this.parameters.setSpecieId(this.incomingSpecie.getId());
      this.parameters.setDiseaseId(this.incomingInvitee.getId());
      if((position = this.service.findIndex(this.incomingSpecie.getDiseases().data, 'id', parseFloat(this.incomingInvitee.getId()))) === -1){
        this.service.httpService('post', '/specie-diseases',
            this.parameters.getAttributes(), {}, (response: any) => {
              this.incomingSpecie.getDiseases().data.unshift(response);
              this.notifier.notify('success', 'successfull assigned');
              checked.checked = true;
            }, (error: any) => {});
      }else{
        this.service.httpService('post',
            '/species/' + parseFloat(this.incomingSpecie.getId()) + '/diseases/' + this.incomingInvitee.getId() + '/delete',
            this.parameters.getAttributes(), {}, (response: any) => {
              this.incomingSpecie.getDiseases().data.splice(position, 1);
              this.notifier.notify('success', 'successfull removed');
              checked.checked = true;
            }, (error: any) => {});
      }
    });
  }

}
