import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-draft-address-controls',
  templateUrl: './draft-address-controls.component.html',
  styleUrls: ['./draft-address-controls.component.scss']
})
export class DraftAddressControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  addressValue: any;
  constructor(public service: AppService) {
  }

  ngOnInit(): void {
    this.addressValue = this.service.isNull(this.question.data) ? '' : this.question.data;
  }

  onAddressTextChange(event: any){
    this.question.data = event.target.value;
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.question.data = result.formatted_address;
  }

  onLocationSelected(location: any) {}
}
