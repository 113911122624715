<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <div>
                <app-label [question]="question"></app-label>
            </div>
            <div class="row">
                <a mat-flat-button class="defaultColor lg-fab pull-left" (click)="addOptionalEvent()">Add Table Header</a>
            </div>
            <div class="row">
                <table [ngStyle]="{'margin-top':'100px','width': '100%', 'background-color': '#FFFFFF'}" class="table table-striped table-bordered">
                    <thead>
                    <th *ngFor="let option of question.options" style="font-size: 17px; margin: 2px;min-width: 200px">
                        <table>
                            <td valign="top">
                                <span class='cursor headline2' (click)="setOption(option)" [innerHTML]="option.label"></span>
                            </td>
                            <td valign="top">
                                <i class="material-icons cursor" (click)="removeOption(option)" style="margin-left: 10px">close</i>
                            </td>
                            <td valign="top">
                                <i class="material-icons cursor"
                                   (click)="incomingSelectedOption.setAttributes(option);onContextMenu($event, option)"
                                   style="margin-left: 10px">keyboard_arrow_down</i>
                            </td>
                            <td>
                                <i class="material-icons pull-right"
                                   [ngStyle]="{'cursor':'pointer'}"
                                   *ngIf="option.type_id === 3 || option.type_id === 5 || option.type_id === 9 || option.type_id === 27"
                                   (click)="addOption(option)"
                                >add</i>
                                <i class="material-icons pull-right"
                                   [ngStyle]="{'cursor':'pointer'}"
                                   (click)="settings(option)"
                                >settings</i>
                            </td>
                        </table>
                    </th>
                    <th>Action</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of question.rows; let questionIncremental = index" style="height: 50px !important">
                        <td *ngFor="let option of question.options; let optionIncremental = index"
                            class="headline2"
                            valign="top"
                            #tdElement
                            style="font-size: 16px; margin: 2px;"
                            (blur)="onInputTextChange(option, tdElement, row);"
                            contentEditable="{{readOnly(option, row)}}"
                        >
                            <div *ngIf="row.hasOwnProperty(option.cntrl_num)">
                                <span *ngIf="option.type_id === 1">
                                    {{service.removeHtmlTags(row[option.cntrl_num].hasOwnProperty('data') ? row[option.cntrl_num].data : '')}}
                                </span>
                                <!--                            <center>-->
                                <!--                                    <mat-checkbox-->
                                <!--                                            *ngIf="option.type_id === 3"-->
                                <!--                                            #checkbox-->
                                <!--                                            [checked]="isChecked(checkbox, row, option)"-->
                                <!--                                            (change)="onClickedcheckbox(checkbox, row, option)"-->
                                <!--                                            class=""-->
                                <!--                                    >-->
                                <!--                                    </mat-checkbox>-->
                                <!--                            </center>-->
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 4">
                                    <div>
                                        <!--                                        <mat-form-field  class="example-full-width" appearance="fill">-->
                                        <!--                                            <mat-label></mat-label>-->
                                        <!--                                            <input matInput-->
                                        <!--                                                   #questionElement-->
                                        <!--                                                   (input)="onChangeOptionalText($event)"-->
                                        <!--                                                   type="text"-->
                                        <!--                                                   [ngStyle]="{'text-align':'left', 'font-size': '30px'}"-->
                                        <!--                                                   [formControl] = "optionalControl"-->
                                        <!--                                                   [matAutocomplete] = "optional">-->
                                        <!--                                            <i class="material-icons pull-right" [ngStyle]="{'cursor':'pointer'}">keyboard_arrow_down</i>-->
                                        <!--                                            <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner>-->
                                        <!--                                            <mat-autocomplete #optional = "matAutocomplete"-->
                                        <!--                                                              (optionSelected)="onOptionalSelectionChanged($event)"-->
                                        <!--                                                              [displayWith]="displayFn">-->
                                        <!--                                                <mat-option *ngFor="let optional of filteredOptions | async; let optionalOf = index"-->
                                        <!--                                                            [value]= "optional">-->
                                        <!--                                                    {{optional.label}}<span *ngIf="!service.empty(optional.code)"-->
                                        <!--                                                                            style="margin-left: 10px;">({{optional.code}})</span>-->
                                        <!--                                                </mat-option>-->
                                        <!--                                            </mat-autocomplete>-->
                                        <!--                                        </mat-form-field>-->
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 3">
                                    <div>
                                        <div *ngFor="let opt of (option.settings.hasOwnProperty('options') ? option.settings.options : [])">
                                            <mat-checkbox
                                                    class="pull-left"
                                                    #checked>
                                                <table>
                                                    <td valign="top">
                                                    <span class='headline2 cursor'
                                                          (click)='updateOpt(option, opt)'>
                                                        {{opt.label}}
                                                    </span>
                                                    </td>
                                                    <td valign="top">
                                                        <i class="material-icons cursor"
                                                           [ngStyle]="{'margin-left':'10px'}"
                                                           (click)='removeOpt(option, opt)'>close</i>
                                                    </td>
                                                </table>
                                            </mat-checkbox><br><br>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 5">
                                    <mat-radio-group
                                            aria-labelledby="example-radio-group-label"
                                            class="example-radio-group">
                                        <mat-radio-button *ngFor="let opt of (option.settings.hasOwnProperty('options') ? option.settings.options : [])"
                                                          class="pull-left"
                                                          [value]="opt"
                                                          #checked>
                                            <table>
                                                <td valign="top">
                                                    <span class='headline2 cursor'
                                                          (click)='updateOpt(option, opt)'>
                                                        {{opt.label}}
                                                    </span>
                                                </td>
                                                <td valign="top">
                                                    <i class="material-icons cursor"
                                                       [ngStyle]="{'margin-left':'10px'}"
                                                       (click)='removeOpt(option, opt)'>close</i>
                                                </td>
                                            </table>
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 6">
                                    <app-address-preview-controls [question]='option'
                                                                  [position]='1' [
                                                                  data]='row[option.cntrl_num]'
                                                                  [status]="'table'">
                                    </app-address-preview-controls>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 9">
                                    <app-respond-autocom-plete-controls
                                            [question]='option'
                                            [item]='row[option.cntrl_num]'
                                            [position]='1' [status]="1">
                                    </app-respond-autocom-plete-controls>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 10">
                                    <textarea
                                            autocomplete="off"
                                            type="text"
                                            [autofocus]="true"
                                            (blur)="service.decimalPlaces($event, option.hasOwnProperty('conditions') ? option.conditions : {})"
                                            (keyup)="onKeyUp($event);service.numberValidation($event, option.hasOwnProperty('conditions') ? option.conditions : {})"
                                            (keydown)="service.validateNumber($event)"
                                            [(ngModel)]="row[option.cntrl_num].data"
                                            [ngStyle]="{'max-height':'400px','resize': 'none', 'margin':'0px', 'background':'transparent', 'padding':'0px', 'border-width':'0px', 'height': '100%', 'width': '100%', 'min-width': '200px'}"
                                    ></textarea>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row"
                                     *ngIf="option.type_id === 27">
                                    <app-chip-feedback-controls [deletedOptions]='[]' [question]='getItem(option, row[option.cntrl_num])' [position]='1' [status]="1"></app-chip-feedback-controls>
                                </div>
                            </div>

                        </td>
                        <td class="cursor" (click)="removeTableRow(row)"><i class="material-icons">close</i></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" *ngIf="question.options.length > 0">
                <center>
                    <a class="defaultColor"
                       style="margin-top: 50px"
                       (click)="addRow()"
                       mat-mini-fab>
                        <i class="material-icons"> add </i>
                    </a>
                </center>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <div style="max-width: 60%">
            <i class="material-icons pull-left cursor"
               [ngStyle]="{'color':'#888','cursor':'pointer'}"
               (click)="onSelectControl(control)"
               *ngFor="let control of (controls); let controlOf = index"
               [matTooltip]="control.name" >{{control.icon}}</i>
        </div>
    </ng-template>
</mat-menu>
