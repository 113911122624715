import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {InventoriesService} from '../inventories.service';
@Component({
  selector: 'app-section-inventories',
  templateUrl: './section-inventories.component.html',
  styleUrls: ['./section-inventories.component.scss']
})
export class SectionInventoriesComponent implements OnInit {

  constructor(public service: AppService,
    public inventoryService: InventoriesService) {
  }

  ngOnInit(): void {
  }

}
