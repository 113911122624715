import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-brunch-teams',
  templateUrl: './nav-brunch-teams.component.html',
  styleUrls: ['./nav-brunch-teams.component.scss']
})
export class NavBrunchTeamsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //gh;glhfghl;g
  }

}
