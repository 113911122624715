import {Component, Input, OnInit} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {FormService} from "../forms/form.service";
import {Parameters} from "../parameters";
import {AppService} from "../app.service";

@Component({
  selector: 'app-ngx-treeview',
  templateUrl: './ngx-treeview.component.html',
  styleUrls: ['./ngx-treeview.component.scss']
})
export class NgxTreeviewComponent implements OnInit {
  @Input() questions: any;
  config: any;
  items: any = [];
  incomingForm: any;
  incomingQuestion: any;
  incomingOption: any;
  outgoingQuestion: any;
  outgoingQuestionn: any;
  outgoingOption: any;
  incomingNode: any;
  outgoingNode: any;
  outgoingNodee: any;
  nodes: any = [];
  flag: any = 0;
  flag2: any = 0;
  treeviewInterval: any;
  constructor(private formService: FormService,
              private service: AppService) {
    this.incomingForm = new Parameters();
    this.incomingQuestion = new Parameters();
    this.incomingOption = new Parameters();
    this.outgoingQuestion = new Parameters();
    this.outgoingQuestionn = new Parameters();
    this.outgoingOption = new Parameters();
    this.incomingNode = new Parameters();
    this.outgoingNode = new Parameters();
    this.outgoingNodee = new Parameters();
    this.formService.questions = this.questions;
  }

  ngOnInit(): void {
    this.config  = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: true,
      hasCollapseExpand: true,
      decoupleChildFromParent: true,
      maxHeight: window.innerHeight
    });

    setTimeout(() =>{
      this.formService.questions.map((question: any) => {
        this.incomingQuestion.setAttributes(question);
        if(this.incomingQuestion.getAttributes().hasOwnProperty('options')){
          this.incomingQuestion.getOptions().map((option: any) => {
            this.incomingOption.setAttributes(option);
            if(this.incomingOption.getLabel().toString().includes('null')){
              this.formService.showQuestions(this.incomingOption.getAttributes());
            }else this.formService.hideOptionalQuestions(this.incomingOption.getAttributes());
          });
        }
      });
      this.formService.loadHiddenQuestions();
      this.formService.questions.map((question: any) => {
        this.incomingQuestion.setAttributes(question);
        this.appendChildrenNode(this.incomingQuestion.getAttributes(), 0, (result)=>{
        });
      });

      this.treeviewInterval = setInterval(() => {
        if(this.flag === this.flag2){
          clearInterval(this.treeviewInterval);
          this.getNodes();
        }else this.flag2 = this.flag;
      }, this.service.timeout()*2);
    });
  }

  appendChildrenNode(question: any, flag: any, callback: any){
    const incomingQuestion: any = new Parameters();
    incomingQuestion.setAttributes(question);
    incomingQuestion.setAttributes(question);
    incomingQuestion.setName(incomingQuestion.getLabel());
    const incomingQuestionNode: any = new Parameters();
    incomingQuestionNode.setAttributes({});
    incomingQuestionNode.setText(incomingQuestion.getLabel());
    incomingQuestionNode.setCollapsed(true);
    incomingQuestionNode.setChecked(false);
    incomingQuestionNode.setChildren(incomingQuestion.getAttributes().hasOwnProperty('children') ? incomingQuestion.getChildren() : []);
    incomingQuestionNode.setCntrlNum(incomingQuestion.getCntrlNum());
    if(parseFloat(incomingQuestion.getVisible().toString()) === 1) {
      this.nodes.push(incomingQuestionNode.getAttributes());
      this.flag += parseFloat('1');
    } if(incomingQuestion.getAttributes().hasOwnProperty('options')){
      incomingQuestion.getOptions().map((optional: any) => {
        const incomingQuestionOpt: any = new Parameters();
        incomingQuestionOpt.setAttributes(optional);
        incomingQuestionOpt.setName(incomingQuestionOpt.getLabel());
        const incomingQuestionOptNode: any = new Parameters();
        incomingQuestionOptNode.setAttributes({});
        incomingQuestionOptNode.setText(incomingQuestionOpt.getLabel());
        incomingQuestionOptNode.setValue(incomingQuestionOpt.getCntrlNum());
        incomingQuestionOptNode.setCollapsed(true);
        incomingQuestionOptNode.setChecked(false);
        incomingQuestionOptNode.setChildren(incomingQuestionOpt.getAttributes().hasOwnProperty('children') ? incomingQuestionOpt.getChildren() : []);
        incomingQuestionOptNode.setCntrlNum(incomingQuestionOpt.getCntrlNum());
        incomingQuestionNode.getChildren().push(incomingQuestionOptNode.getAttributes());
        this.flag += parseFloat('1');
        incomingQuestionOpt.setChildren(incomingQuestionOptNode.getChildren());
        incomingQuestion.setChildren(incomingQuestionNode.getChildren());
        if(incomingQuestionOpt.getAttributes().hasOwnProperty('questions')){
          incomingQuestionOpt.getQuestions().map((q: any) => {
            const incomingOptQuestion: any = new Parameters();
            incomingOptQuestion.setAttributes(q);
            const incomingOptQuestionNode: any = new Parameters();
            incomingOptQuestionNode.setAttributes({});
            incomingOptQuestionNode.setText(incomingOptQuestion.getLabel());
            incomingOptQuestionNode.setValue(incomingOptQuestion.getCntrlNum());
            incomingOptQuestionNode.setCollapsed(true);
            incomingOptQuestionNode.setChecked(false);
            incomingOptQuestionNode.setChildren(incomingOptQuestion.getAttributes().hasOwnProperty('children') ? incomingOptQuestion.getChildren() : []);
            incomingOptQuestionNode.setCntrlNum(incomingOptQuestion.getCntrlNum());
            incomingQuestionOptNode.getChildren().push(incomingOptQuestionNode.getAttributes());
            incomingOptQuestion.setChildren(incomingOptQuestionNode.getChildren());
            this.flag += parseFloat('1');
            const finalQ = this.formService.questions.filter((questional: any) => {
              return questional.cntrl_num.toString().includes(incomingOptQuestion.getCntrlNum()); })[0];
            incomingOptQuestion.setAttributes(Object.assign(incomingOptQuestion.getAttributes(), finalQ));
            this.appendChildrenNode(incomingOptQuestion.getAttributes(), 1, callback);
          });
        }
      });
    }
  }

  getNodes(){
    this.nodes = this.nodes.map((node: any)=>{
      this.incomingNode.setAttributes(node);
      return new TreeviewItem(this.incomingNode.getAttributes());
    });
  }

  onSelectedChange(event: any){
    console.log(event);
  }

}
