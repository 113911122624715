import {Component, ElementRef, Input, OnChanges,SimpleChanges,OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {AppService} from "../../../../../app.service";
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../../../../form.service";
import {DomSanitizer} from "@angular/platform-browser";
import {BroadcastChannelService} from "../../../../../broadcast-channel.service";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {MatMenuTrigger} from "@angular/material/menu";
import {map, startWith} from "rxjs/operators";
import {Parameters} from "../../../../../parameters";
@Component({
  selector: 'app-image-choice-respond-control',
  templateUrl: './image-choice-respond-control.component.html',
  styleUrls: ['./image-choice-respond-control.component.scss']
})
export class ImageChoiceRespondControlComponent implements OnChanges {
  @Input() question: any;
  @Input() position: any;
  data: any;
  questions: any;
  form: any;
  incomingQuestion: any;
  incomingResult: any;
  incomingResults: any;
  incomingOption: any;
  constructor(
      public service: AppService,
      private broadcastChannelService: BroadcastChannelService,
      public broadcastChannel: BroadcastChannelService,
      public formService: FormService) {
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingQuestion = new Parameters();
    this.incomingResult = new Parameters();
    this.incomingResults = new Parameters();
    this.incomingOption = new Parameters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.incomingQuestion.setAttributes(this.question);
    this.incomingResult.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('result') ?
     this.incomingQuestion.getResult() : {});
     this.incomingResults.setAttributes({});
    this.incomingQuestion.setResult(this.incomingResult.getAttributes());
  }

  onImageSelected(option: any){
    this.incomingOption.setAttributes(option);
    this.incomingQuestion.setData(this.incomingOption.getLabel().toString());
    this.incomingResult.setImage(this.incomingOption.getMedia());
    this.incomingResult.setCntrlNum(this.incomingOption.getCntrlNum());
    this.incomingQuestion.setResult(this.incomingResult.getAttributes());
    this.broadcastChannelService.emitNavChangeEvent({action: 'nextQuestion'});
  }

}
