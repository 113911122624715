import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Parameters} from '../parameters';
import {AppService} from '../app.service';
import {BroadcastChannelService} from '../broadcast-channel.service';
import {jqxChartComponent} from 'jqwidgets-ng/jqxchart';
import {Chart} from 'chart.js';
import {ChartService} from '../chart.service';
import {FilterDashboardComponent} from './filter-dashboard.component';
import {MatDialog} from '@angular/material/dialog';
import {DashboardService} from './dashboard.service';
import {first, take} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  incomingDashbaord: any;
  subscription: any;
  metthods: any;
  constructor(private httpClient: HttpClient,
              public serviceImpl: AppService,
              public chart: ChartService,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public router: Router) {
    this.incomingDashbaord = new Parameters();
    this.incomingDashbaord.setAttributes(this.metthods.dashboard.attributes);
  }

  ngOnInit(): void {
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
    if(!(this.metthods.dashboard.attributes.data instanceof Object))
        this.handleDashboard('dashboard');
      this.subscribe();
  }

  handleHomeNavigator(){
    this.router.navigateByUrl('app/meat-inspections');
  }

  subscribe(){
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if (item === 'openDateMenu') {
          this.openMenuDate();
        } else if(item === 'report'){
          this.handleDashboard(item);
        }
      });
  }

  openMenuDate(){
    this.metthods.dashboard.setDisplay('dashboard');
    this.dialog.open(FilterDashboardComponent, { data: {report: this.metthods.dashboard.attributes}, width  : '55%', height : '100%'});
  }


  handleDashboard(item: any){
          setTimeout((outgoingRequest: any = {}) => {
                outgoingRequest = Object.assign(outgoingRequest, this.metthods.dashboard.attributes);
                delete outgoingRequest.data;
                this.router.navigateByUrl('/wait..');
                this.serviceImpl.httpService('post', '/reports', outgoingRequest, {}, (response: any) => {
                  this.metthods.dashboard.attributes.data = response;
                  this.router.navigateByUrl('/app/dashboard');
                }, (error: any) => {
                });
          });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
