import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-coordinate-draft-controls',
  templateUrl: './coordinate-draft-controls.component.html',
  styleUrls: ['./coordinate-draft-controls.component.scss']
})
export class CoordinateDraftControlsComponent implements OnInit {

  @Input() question: any;
  @Input() position: any;
  addressValue: any;
  constructor(public service: AppService) {
  }

  ngOnInit(): void {
    this.addressValue = this.service.isNull(this.question.data) ? '' : this.question.data;
  }

  onAddressTextChange(event: any){
    this.question.data = '';
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.question.data = result.formatted_address;
  }

  onLocationSelected(location: any) {
    this.question.data =
        'lat:' + location.latitude + ',' +
        'lng:' + location.longitude;
  }

}
