<table [ngStyle]="{'color':'#5A738E'}" class="table table-stripedx sawasdeeWithScale ">
    <thead class="sawasdee12">
      <tr>
        <th class="mdl-data-table__cell--non-numeric headline2x"></th>
        <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">
            <th class="mdl-data-table__cell--non-numeric headline2x ">Outlet</th>
            <th class="mdl-data-table__cell--non-numeric headline2x ">Address</th>
            <th class="mdl-data-table__cell--non-numeric headline2x ">Phone</th>
            <th class="mdl-data-table__cell--non-numeric headline2x ">Category</th>
        </ng-container>
        <th class="mdl-data-table__cell--non-numeric headline2x"
            *ngFor="let responseResult of (
                              responses.length === 0 ? [] :
                              responses[0].datas)">
            <ng-container>
                <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">
                </ng-container>
                <ng-template #qrcode>
                    <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else advert">
                    </ng-container>
                </ng-template>
                <ng-template #advert>
                    <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else description">
                        <span></span>
                    </ng-container>
                </ng-template>
                <ng-template #description>
                    <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else content">
                        <span></span>
                    </ng-container>
                </ng-template>
                <ng-template #content>
                                    <span title="{{this.service.removeHtmlTags(responseResult.formControl.label)}}"
                                          [innerHTML]="this.service.limitTo((responseResult.formControl.label), 40)">
                                    </span>
                </ng-template>
            </ng-container>
        </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let response of (responses | jsonFilter:this.service.searchText)">
        <tr (contextmenu)="incomingResponseSelected.setAttributes(response);onContextMenu($event, response)"
            (mousedown)="incomingResponseSelected.setAttributes(response)" class="sawasdee16">
            <td class="mdl-data-table__cell--non-numeric headline2x">{{response.reference2}}</td>
            <ng-container *ngIf="!service.isNull(incomingFormSettings.getOutlet())">
                <ng-container *ngIf="response.hasOwnProperty('outlet')">
                    <td class="mdl-data-table__cell--non-numeric headline2x ">{{response.outlet.name}}</td>
                    <td class="mdl-data-table__cell--non-numeric headline2x ">{{response.outlet.address}}</td>
                    <td class="mdl-data-table__cell--non-numeric headline2x ">{{response.outlet.phone}}</td>
                    <td class="mdl-data-table__cell--non-numeric headline2x ">{{response.outlet.category.name}}</td>
                </ng-container>
                <ng-container *ngIf="!response.hasOwnProperty('outlet')">
                    <td class="mdl-data-table__cell--non-numeric headline2x "></td>
                    <td class="mdl-data-table__cell--non-numeric headline2x "></td>
                    <td class="mdl-data-table__cell--non-numeric headline2x "></td>
                    <td class="mdl-data-table__cell--non-numeric headline2x "></td>
                </ng-container>
            </ng-container>
            <td title="{{service.removeHtmlTags(responseResult.formControl.label)}}"
                class="mdl-data-table__cell--non-numeric headline2x "
                *ngFor="let responseResult of response.datas; let responseResultIncrease = index">
                <ng-container>
                    <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 32;else qrcode">
                    </ng-container>
                    <ng-template #qrcode>
                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 33; else photo">
                            <ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                    <ng-template #photo>
                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 2; else description">
                            <img [src]="responseResult.data"
                                 [ngStyle]="{'max-width':'100px','max-height':'100px'}"/>
                        </ng-container>
                    </ng-template>
                    <ng-template #description>
                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 12; else checkbox">
                        </ng-container>
                    </ng-template>
                    <ng-template #checkbox>
                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 3; else advert">
                            <div>
                                <center>
                                    <table>
                                        <tr *ngFor="let option of responseResult.formControl.options">
                                            <td>
                                                {{service.upperStartingCharacter(option.label, true)}}
                                            </td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </ng-container>
                    </ng-template>
                    <ng-template #advert>
                        <ng-container *ngIf="this.service.parseFloat(responseResult.formControl.control_id) === 29; else content">
                            <div></div>
                        </ng-container>
                    </ng-template>
                    <ng-template #content><div [innerHTML]="responseResult.data|safeUrl"></div>
                    </ng-template>
                </ng-container>
            </td>
        </tr>
    </ng-container>
    </tbody>
</table>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
        <!--<button mat-menu-item *ngIf="flag === 'all'"     (click)="routes(selectedAmbassador)">Routes</button>-->
        <!--<button mat-menu-item *ngIf="flag === 'all'"     (click)="getListingReport()">Final Report</button>-->
        <button mat-menu-item>View</button>
<!--        <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedAmbassador)">Delete</button>-->
<!--        <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedAmbassador)">Restore</button>-->
    </ng-template>
</mat-menu>
