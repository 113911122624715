import {AfterContentInit, Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {CampanyService} from './campany.service';
import {DialogCampaniesComponent} from './dialog-campanies.component';

@Component({
  selector: 'app-campanies',
  templateUrl: './campanies.component.html',
  styleUrls: ['./campanies.component.scss']
})
export class CampaniesComponent implements OnInit, AfterContentInit {
  app: any;
  constructor(public service: AppService,
              private dialog: MatDialog,
              public metthods: CampanyService) {
    this.app = service.app;
    this.app.form.modules  = [{path: '/app/campanies', title: 'Accounts'}];
    this.app.form.subtitle = 'legal entity formed by a group of individuals to engage in and operate a business—commercial or industrial—enterprise. A company may be organized in various ways for tax and financial liability purposes depending on the corporate law of its jurisdiction. ';
    this.app.form.module   = 'campanies';
  }

  ngOnInit(): void {
      this.metthods.setData(this.app.data.campanies);
  }

  openDialog(){ this.dialog.open(DialogCampaniesComponent, {data  : {}, width : '55%', height: '100%', });}

  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Registration').toString().toUpperCase() + ' ,campanies';
      this.service.header = ('Company').toString().toUpperCase() + ' (' + this.app.data.campanies.data.length + ') ,registration';
    });
  }


}
