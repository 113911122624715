import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-respond-inputlists-controls',
  templateUrl: './respond-inputlists-controls.component.html',
  styleUrls: ['./respond-inputlists-controls.component.scss']
})
export class RespondInputlistsControlsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
