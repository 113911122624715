import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {TeamService} from '../../teams/team.service';
import {Parameters} from '../../parameters';
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-teams-form',
  templateUrl: './teams-form.component.html',
  styleUrls: ['./teams-form.component.scss']
})
export class TeamsFormComponent implements OnInit, OnDestroy {
  @Input() form: any;
  subscription: any;
  campaignId: any = null;
  teams: any = [];
  incomingTeam: any;
  incomingCapaign: any;
  selectedIndex: any = 0;
  constructor(private broadcastChannel: BroadcastChannelService,
              private notifier: NotifierService,
              private teamService: TeamService,
              private campaignService: CampaignService) {
    this.incomingTeam = new Parameters();
    this.incomingCapaign = new Parameters();
    this.incomingTeam.setAttributes({});
  }

  ngOnInit(): void {
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
        .subscribe((item: any) => {
          if(item instanceof Object){
            if(item.hasOwnProperty('campaignId')){
              this.campaignId = item.campaignId;
              this.onCampaignSelected();
            }else if(item.hasOwnProperty('team')){
              if(this.campaignId !== null)
                this.onCampaignSelected();
              this.selectedIndex = 1;
            }
          }
        });
  }

  onteamAddClicked(){
    this.campaignService.setSelected(this.campaignId === null ? {} : this.incomingCapaign.getAttributes());
    this.teamService.setSelected({});
    this.teamService.openDialog({});
  }

  onCampaignSelected() {
    this.teams = [];
    const campaign = this.campaignService.getCampaignById(this.campaignId);
    this.incomingCapaign.setAttributes(campaign);
    this.incomingCapaign.getTeams().data.map((team: any) => {
      this.incomingTeam.setAttributes(team);
      this.teams.push(this.teamService.getTeamById(this.incomingTeam.getId()));
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
