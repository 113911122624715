import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, NgZone, ViewChild} from '@angular/core';
import {BroadcastChannelService} from '../../../broadcast-channel.service';
import {ResponseService} from '../../../forms/responses/data/response.service';
import {CampaignService} from '../../../campaigns/campaign.service';
import {ClientService} from '../../../clients/client.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {Parameters} from '../../../parameters';
import {AppService} from '../../../app.service';
import {FormService} from '../../form.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {ImageService} from '../../../images/image.service';
import {NotifierService} from "angular-notifier";
@Component({
    selector: 'app-description-preview-controls',
    templateUrl: './description-preview-controls.component.html',
    styleUrls: ['./description-preview-controls.component.scss']
})
export class DescriptionPreviewControlsComponent implements  OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(MatMenuTrigger) contextMenu: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    @Input() question: any;
    @Input() position: any;
    incomingForm: any;
    outgoingRequest: any;
    incomingQuestion: any;
    incomingItem: any;
    incomingResponseResult: any;
    incomingQrcodeQuestion: any;
    incomingFormSettings: any;
    incomingResponse: any;
    incomingIsConfirmQuestion: any;
    incomingInvitationCard: any;
    incomingNameQuestion: any;
    incomingResult: any;
    flag: any = true;
    incomingEmailQuestion: any;
    incomingCampaign: any;
    incomingClient: any;
    incomingPicQuestion: any;
    incomingBannerQuestion: any;
    incomingQuestionSettings: any;
    incomingInvitee: any;
    strings: any;
    constructor(private broadcastChannel: BroadcastChannelService,
                public formService: FormService,
                public zone: NgZone,
                public campaignService: CampaignService,
                public clientService: ClientService,
                private imgService: ImageService,
                private notifier: NotifierService,
                public  router: Router,
                private activatedRoute: ActivatedRoute,
                public responseService: ResponseService,
                public service: AppService) {
        this.incomingQuestion = new Parameters();
        this.incomingItem = new Parameters();
        this.incomingNameQuestion = new Parameters();
        this.incomingQrcodeQuestion = new Parameters();
        this.incomingBannerQuestion = new Parameters();
        this.incomingResponseResult = new Parameters();
        this.incomingQuestionSettings = new Parameters();
        this.strings =  new Parameters();
        this.incomingResult = new Parameters();
        this.incomingPicQuestion = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingEmailQuestion = new Parameters();
        this.incomingCampaign = new Parameters();
        this.incomingInvitee = new Parameters();
        this.incomingClient = new Parameters();
        this.incomingForm = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.incomingResponse = new Parameters();
        this.incomingIsConfirmQuestion = new Parameters();
        this.incomingInvitationCard = new Parameters();
        this.contextMenu = MatMenuTrigger;
        this.incomingBannerQuestion.setAttributes({});
        this.incomingPicQuestion.setAttributes({});
        this.incomingNameQuestion.setAttributes({});
        this.incomingInvitationCard.setAttributes({});
    }


    next(){
        this.broadcastChannel.emitNavChangeEvent({action : 'nextQuestion'});
    }

    getDataUrl(url: any){
        const request = new XMLHttpRequest();
        request.open('GET', url);
        request.responseType = 'blob';
        request.onload = () => {
        };
        request.send();

    }

    ngAfterViewInit(): void {
    }

    getLogo(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            event.target.files = null;
            this.incomingFormSettings.setLogoDataUrl(reader.result);
            this.service.httpService('post', this.service.app.routes.images.add,
                this.outgoingRequest.getAttributes(), {}, (result: any) => {
                    this.incomingResult.setAttributes(result);
                    this.incomingFormSettings.setLogoUrl(this.incomingResult.getPath());
                    this.incomingForm.setSettings(this.incomingFormSettings.getAttributes());
                    this.updateSettingsForm();
                }, (error: any) => {
                });
        };
    }

    onFrameFileChange(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            event.target.files = null;
            this.incomingFormSettings.setFrameDataUrl(reader.result);
            this.service.httpService('post', this.service.app.routes.images.add,
                this.outgoingRequest.getAttributes(), {}, (result: any) => {
                    this.incomingResult.setAttributes(result);
                    this.incomingFormSettings.setFrameUrl(this.incomingResult.getPath());
                    this.incomingForm.setSettings(this.incomingFormSettings.getAttributes());
                    this.updateSettingsForm();
                }, (error: any) => {
                });
        };
    }

    updateSettingsForm(){
        setTimeout(() => {
            this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
            this.outgoingRequest.setSettings(JSON.stringify(this.outgoingRequest.getSettings()));
            this.outgoingRequest.setControls(JSON.stringify(this.outgoingRequest.getControls()));
            this.formService.update(this.outgoingRequest, (result: any) => {
                this.ngOnChanges({});
            });
        });

    }

    onContextMenu(event: MouseEvent, inspector: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { item: inspector };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();

    }

    download(){
        const pdfHtmlContents: any = document.getElementById('doc');
        setTimeout(() => {
//           let name: any = this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingFormSettings.getAttendeeName()).data));
            const name: any = 'doc';
            setTimeout(() => {
                html2pdf()
                    .from(pdfHtmlContents)
                    .set(this.getOptions())
                    .toImg()
                    .save(name + '.pdf').then(() => {
                });
            }, this.service.timeout());
        });
    }

    downloadImg(){
        const elementHtml: any = document.getElementById('doc');
        setTimeout(() => {
            html2canvas(elementHtml).then(canvas => {
                this.imgService.download(this.incomingNameQuestion.getData(), canvas.toDataURL('image/png'),(result: any) => {});
            });
        });
    }

    getOptions(){
        const opt: any = {
            margin: 1,
            filename: 'file.pdf',
            image: { type: 'jpeg', quality: 0.20 },
            html2canvas: {scale: 7, logging: true},
            //      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
        }; return opt;
    }



    getQrcode(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            this.zone.run(() => {
                this.incomingQrcodeQuestion.setResult(Object.assign(this.incomingQrcodeQuestion.getResult(), {path: reader.result}));
            }); event.target.files = null;
            this.service.httpService('post', this.service.app.routes.images.add,
                this.outgoingRequest.getAttributes(), {}, (result: any) => {
                    this.incomingResult.setAttributes(result);
                    this.incomingQrcodeQuestion.setResult(Object.assign(this.incomingQrcodeQuestion.getResult(), {src: this.incomingResult.getPath()}));
                    this.ngOnChanges({});
                }, (error: any) => {
                });
        };
    }

    setBanner(){
        setTimeout(() => {
            const doc: any = document.getElementById('*banner');
            if (doc !== null && this.incomingBannerQuestion.getAttributes().hasOwnProperty('id')){
                doc.innerHTML = this.incomingBannerQuestion.getUnknown('data');
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.strings.setAttributes(this.service.app.strings.params);
        this.incomingQuestion.setAttributes(this.question);
        this.incomingQuestion.setUnknown('content', this.incomingQuestion.getLabel());
        this.incomingQuestionSettings.setAttributes(this.incomingQuestion.getAttributes().hasOwnProperty('settings') ?
            this.incomingQuestion.getSettings() : {});
        this.incomingQuestionSettings.setHidden(this.incomingQuestionSettings.hasOwnProperty('hidden') ?
            this.incomingQuestionSettings.getHidden() : 0);
        this.incomingResponse.setAttributes(this.responseService.getSelected());
        this.incomingForm.setAttributes(this.incomingResponse.getForm());
        this.incomingCampaign.setAttributes(this.incomingForm.getAttributes().hasOwnProperty('campaign') ?
            this.incomingForm.getCampaign() : this.campaignService.getCampaignById(this.incomingForm.getCampaignId()));
        this.incomingClient.setAttributes(this.incomingCampaign.getAttributes().hasOwnProperty('client') ?
            this.incomingCampaign.getClient() : this.clientService.getClientById(this.incomingCampaign.getClientId()));
        this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
        this.setQuestions(() => {
            if(this.incomingFormSettings.getInvitationCard().includes(this.incomingQuestion.getCntrlNum())){
                this.broadcastChannel.emitNavChangeEvent({action: 'ngSubmit'});
                // if(this.service.isNull(this.incomingInvitationCard.getData())){
                //     if(!this.incomingResponse.getAttributes().hasOwnProperty(this.strings.getId())){
                //         this.responseService.addPostByFormIdAndControls(
                //             this.incomingForm.getId(),
                //             this.incomingForm.getControls(), (response: any) => {
                //             // this.incomingResponse.setId(response.id);
                //             // if(!this.service.isNull(this.incomingInvitee.getAttendeePhone())){
                //             //     let $outgoingRequest: any = new Parameters();
                //             //     $outgoingRequest.setAttributes({});
                //             //     $outgoingRequest.setResponseId(this.incomingResponse.getId());
                //             //     $outgoingRequest.setFlag(this.strings.getWhatsapp());
                //             //     // this.responseService.sendInvitationCard($outgoingRequest, (result) => {
                //             //     //     $outgoingRequest.setFlag(this.strings.getEmail());
                //             //     // });
                //             // }
                //         });
                //     }
                //     // this.flag = false;
                //     // this.outgoingRequest.setAttributes({});
                //     // this.outgoingRequest.setResponseId(this.incomingResponse.getId());
                //     // if(!this.service.isNull(this.incomingInvitee.getAttendeeEmail())){
                //     //     this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
                //     //         this.outgoingRequest.getAttributes(), {}, (result: any) => {
                //     //             this.flag=true;
                //     //             this.notifier.notify('success', this.service.app.strings.invitation.card.delivery);
                //     //             this.next();
                //     //         }, (error: any) => {
                //     //         });
                //     // }
                // }
            }

        });
    }

    getPdf(){
    }

    setQuestions(callback: any){
        this.incomingInvitationCard.setAttributes({});
        this.incomingInvitee.setAttributes({});
        this.incomingInvitee.setAttendeeName(this.service.upperStartingCharacter(this.strings.getGuest(), true));
        this.incomingInvitee.setAttendeeEmail('');
        this.incomingForm.getControls().map((question: any) => {
            this.incomingItem.setAttributes(Object.assign({}, question));
            if (!this.service.isNull(this.incomingItem.getData()) &&
                this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeeName())){
                this.incomingInvitee.setAttendeeName(this.incomingItem.getData());
            }if (!this.service.isNull(this.incomingItem.getData()) &&
                this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeeSalutation())){
                this.incomingInvitee.setAttendeeSalutation(this.incomingItem.getData());
            }if (!this.service.isNull(this.incomingItem.getData()) &&
                this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeePhone())){
                this.incomingInvitee.setAttendeePhone(this.incomingItem.getData());
            }if (this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getAttendeeEmail())){
                this.incomingInvitee.setAttendeeEmail(this.incomingItem.getData());
            }if (this.incomingItem.getCntrlNum().includes(this.incomingFormSettings.getInvitationCard())){
                this.incomingInvitationCard.setAttributes(this.incomingItem.getAttributes());
            }
        });callback(null);
    }

    ngOnDestroy(): void {
    }

    onBannerFileChange(event: any){
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSrc(reader.result);
            this.outgoingRequest.setFlag(0);
            this.outgoingRequest.setCaption('');
            this.outgoingRequest.setOrderBy('');
            this.outgoingRequest.setStatus(1);
            this.outgoingRequest.setUrl('');
            this.outgoingRequest.setPath('');
            this.outgoingRequest.setAvatar(this.service.dataURItoBlob(reader.result));
            event.target.files = null;
            this.service.httpService('post', this.service.app.routes.images.add,
                this.outgoingRequest.getAttributes(), {}, (result: any) => {
                    this.incomingResult.setAttributes(result);
                    this.incomingFormSettings.setBanner(this.incomingResult.getPath());
                    this.incomingForm.setSettings(this.incomingFormSettings.getAttributes());
                    this.updateSettingsForm();
                }, (error: any) => {
                });
        };
    }


}
