import { Component, OnInit } from '@angular/core';
import {FormService} from '../../../form.service';
import {ResponseService} from '../response.service';
import {ActivatedRoute} from "@angular/router";
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-tabs-responses-form',
  templateUrl: './tabs-responses-form.component.html',
  styleUrls: ['./tabs-responses-form.component.scss']
})
export class TabsResponsesFormComponent implements OnInit {

  responses: any;
  formId: any;
  form: any;
  constructor(
      private responseService: ResponseService,
      private service: AppService,
      private formService: FormService,
      private activatedRoute: ActivatedRoute) {
   this.formId = this.activatedRoute.snapshot.params.id;
   this.formId = this.service.decrypt(this.formId);
   this.form = this.formService.getFormById(this.formId);
  }

  ngOnInit(): void {
    this.responses = this.form.responses;
  }


}
