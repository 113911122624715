import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-outlets',
  templateUrl: './tabs-outlets.component.html',
  styleUrls: ['./tabs-outlets.component.scss']
})
export class TabsOutletsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
