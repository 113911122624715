import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {AppService} from '../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {TeamService} from '../team.service';
@Component({
  selector: 'app-table-teams',
  templateUrl: './table-teams.component.html',
  styleUrls: ['./table-teams.component.scss']
})
export class TableTeamsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = { x: '0px', y: '0px' };
  app: any;
  selectedTeam: any;
  teams: any;
  incomingSelectedTeam: any;
  subscription: any;
  searchCntrl: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              private broadcastChannel: BroadcastChannelService,
              private router: Router,
              private teamService: TeamService) {
    this.app = service.app;
    this.contextMenu = MatMenuTrigger;
    this.teams = this.teamService.getData();
    this.incomingSelectedTeam = new Parameters();

  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    if(this.teamService.isDialogOpen === false) {
      this.teamService.isDialogOpen = true;
      this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
          .subscribe((item: any) => {
            if (item instanceof Object) {
              if (item.hasOwnProperty('searchText') === true)
                this.searchCntrl = item.searchText;
            } else if (item.toString() === 'add') {
              this.teamService.openDialog({});
            }

          });
    }else this.teamService.isDialogOpen = false;
  }

  ngOnDestroy() {
    if(this.teamService.isDialogOpen === true){
      this.subscription.unsubscribe();
    }
  }

  navBrunches(team: any){
    this.incomingSelectedTeam.setAttributes(this.selectedTeam);
    this.teamService.teamSelectedId = this.incomingSelectedTeam.getId();
    this.teamService.setSelected(this.incomingSelectedTeam.getAttributes());
    this.router.navigateByUrl('/app/teams/' + this.incomingSelectedTeam.getName().toString().toLowerCase() + '/brunches');
  }

  onContextMenu(event: MouseEvent, team: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': team };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }


  handleDestroy(inspector: any){
    this.incomingSelectedTeam = new Parameters();
    this.incomingSelectedTeam.setAttributes(this.selectedTeam);
    this.dialog.open(DeleteDialogComponent, {
      data  : {
        data : this.teams,
        model: this.selectedTeam,
        title: this.incomingSelectedTeam.getName(),
        url  : '/teams/' + parseFloat(this.incomingSelectedTeam.getId()) + '/delete',
        key  : 'id',
        id   : this.incomingSelectedTeam.getId(),
      },
      width : 'auto',
    });
  }

  getCampaignByTeam(team: any){
    let campaign: any = this.app.data.campaigns.data.filter((campaign: any) => { return campaign.id === team.campaign_id;})[0];
    campaign = this.service.only(campaign);
    let client: any = this.getClientByCampaign(campaign);
    client = this.service.only(client);
    campaign = Object.assign(campaign, {client: client});
    team = Object.assign(team, {campaign:campaign});
    return campaign;
  }


  getClientByCampaign(campaign: any){
    let client: any = this.app.data.clients.data.filter((client: any) => { return client.id === campaign.client_id; })[0];
    campaign = Object.assign(campaign, {client: this.service.only(client)});
    return client;
  }

  handleUpdate(inspector: any){
    this.incomingSelectedTeam = new Parameters();
    this.incomingSelectedTeam.setAttributes(this.selectedTeam);
    this.teamService.setSelected(this.incomingSelectedTeam.getAttributes());
    this.teamService.openDialog({});
  }
  handleRestore(inspector: any){
    this.incomingSelectedTeam.setAttributes(this.selectedTeam);
    this.service.restore('/teams/' + parseFloat(this.incomingSelectedTeam.getId()) + '/restore',
      this.teams, 'id', parseFloat(this.incomingSelectedTeam.getId()), this.selectedTeam);
  }

  openDialog(team: any){
    this.teamService.setSelected(this.incomingSelectedTeam.getAttributes());
  }


  showMessage(message: any) {
  }


}
