import { Component, OnInit, Input,OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import {AppService} from '../../app.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {OutletService} from "../../outlets/outlet.service";
import {FormService} from "../../forms/form.service";
import {MatMenuTrigger} from '@angular/material/menu';
import {Parameters} from '../../parameters';
import {ActivatedRoute, Router} from '@angular/router';
import { NgxQrcodeStylingService, Options } from 'ngx-qrcode-styling';
import {MatDialog} from '@angular/material/dialog';
import {DialogViewOutletsComponent} from '../../outlets/dialog/dialog-view-outlets/dialog-view-outlets.component';
import {DialogFrameOutletsComponent} from '../../outlets/dialog-frame-outlets/dialog-frame-outlets.component';
@Component({
  selector: 'app-table-outlets',
  templateUrl: './table-outlets.component.html',
  styleUrls: ['./table-outlets.component.scss']
})
export class TableOutletsComponent implements OnInit,OnDestroy, AfterViewInit {
  @Input() flag: any;
  @ViewChild(MatMenuTrigger) contextMenu: any;
  contextMenuPosition: any = { x: '0px', y: '0px' };
  subscription: any;
  searchCntrl: any;
  incomingForm: any;
  incomingSettingsForm: any;
  formId: any;
  frame: any = '';
  config: Options = {
     width: 300,
     height: 300,
     data: "https://www.facebook.com/",
     image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
     margin: 5,
     dotsOptions: {
       color: "#1977f3",
       type: "dots"
     },
     backgroundOptions: {
       color: "#ffffff",
     },
     imageOptions: {
       crossOrigin: "anonymous",
       margin: 0
     }
  };
  incomingOutletSelected: any;
  constructor(public service: AppService,
              public  broadcastChannel: BroadcastChannelService,
              public dialog: MatDialog,
              private qrcode: NgxQrcodeStylingService,
              public formService: FormService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public outletService: OutletService) {
     this.contextMenu = MatMenuTrigger;
     this.incomingForm = new Parameters();
     this.incomingSettingsForm = new Parameters();
     this.incomingOutletSelected = new Parameters();
     this.incomingForm.setAttributes({});
     this.incomingSettingsForm.setAttributes({});
  }

  ngOnInit(): void {
   setTimeout(()=>{
       if(window.location.href.toString().includes('/forms')){
        this.formId = this.activatedRoute.snapshot.params.id;
        this.formId = this.service.decrypt(this.formId);
        this.incomingForm.setAttributes(this.formService.getFormById(this.formId));
        this.incomingSettingsForm.setAttributes(this.incomingForm.getSettings());
        this.incomingSettingsForm.setQrcodeFrame(this.incomingSettingsForm.getAttributes().hasOwnProperty('qrcode_frame') ? this.incomingSettingsForm.getQrcodeFrame() : '');
        this.frame = this.incomingSettingsForm.getQrcodeFrame();
       }
       this.subscription = this.broadcastChannel.getNavChangeEmitter().pipe()
         .subscribe((item: any) => {
           if (item instanceof Object) {
             if (item.hasOwnProperty('searchText') === true)
               this.searchCntrl = item.searchText;
           }else if (item.includes('search')){
             this.outletService.search(this.searchCntrl,(result: any)=>{
              this.service.app.data.outlets.data = result;
             });
           }else if (item.includes('more')) {
//             if(this.responses.data.length !== 0){
// //              this.responseService.reportValues.setDescendinglastAt(this.responses.data[this.responses.data.length - 1].id);
// //              this.report();
//             }
           }
         });
   });
  }

  setFrameId(outlet: any){
   return outlet.frame.replaceAll('id="'+outlet.cntrl_num+'"','id="'+outlet.id+'"');
  }

  openViewDialog(){
     this.outletService.setSelected(this.incomingOutletSelected.getAttributes());
     this.dialog.open(DialogViewOutletsComponent, {
           data  : {
            formId: this.incomingForm.getId()
           },
           width : '60%',
           height : '100%',
         });
  }

  createFrame(){
       this.dialog.open(DialogFrameOutletsComponent, {
         data  : {
          outlet: this.incomingOutletSelected.getAttributes(),
          frame: this.frame,
          formId: this.incomingForm.getId()
         },
         width : 'auto',
       });
  }

  generateQrcode(outlet: any){
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        this.qrcode.create(this.config, document.getElementById('canvas')).subscribe((res) => {
        });
      });
  }

  onContextMenu(event: MouseEvent, inspector: any) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': inspector };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  sendQrcodeFrameThroughtWhatsapp(){
  }

}
