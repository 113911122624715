import {Component, OnInit, ViewChild, OnDestroy, ElementRef} from '@angular/core';
import {AppService} from '../../app.service';
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/internal/operators";
import {FormService} from '../../forms/form.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Parameters} from '../../parameters';
import {ResponseService} from '../../forms/responses/data/response.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {DeleteDialogComponent} from '../../main/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {StorageMap } from '@ngx-pwa/local-storage';
import {PromptComponent} from '../../prompt/prompt.component';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {DashboardService} from "../../dashboard/dashboard.service";
import {FilterDashboardComponent} from "../../dashboard/filter-dashboard.component";
@Component({
    selector: 'app-ambassador-responses',
    templateUrl: './ambassador-responses.component.html',
    styleUrls: ['./ambassador-responses.component.scss']
})
export class AmbassadorResponsesComponent implements OnInit, OnDestroy {
    @ViewChild(MatMenuTrigger) contextMenu: any;
    @ViewChild('searchElement', {static: false})  searchElement: any;
    contextMenuPosition = { x: '0px', y: '0px' };
    responses: any = [];
    questions: any = [];
    publicFormUrl: any = '/public/forms/';
    incomingForm: any;
    incomingUser: any;
    incomingResponse: any;
    incomingSuggestion: any;
    selectedResponse: any;
    selectedTabIndex: any = 1;
    outgoingRequest: any;
    incomingItem: any;
    position: any;
    responseGetSavedStatus: any = true;
    responseControl = new FormControl();
    filteredResponses: any;
    suggestions: any = [];
    result: any;
    flag: any = 1;
    subscription: any;
    incomingQuestion: any;
    incomingSettings: any;
    incomingResult: any;
    incomingReport: any;
    incomingAmbassador: any;
    searchCntrl: any;
    incomingControl: any;
    more: any = true;
    searchIcon: any = false;
    searchInput: any = true;
    strings: any;
    constructor(public  service: AppService,
                public  router: Router,
                private dashboardService: DashboardService,
                private dialog: MatDialog,
                private storage: StorageMap,
                private responseService: ResponseService,
                private broadcastChannel: BroadcastChannelService,
                public  formService: FormService) {
        document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
        this.contextMenu      = MatMenuTrigger;
        this.incomingForm     = new Parameters();
        this.incomingResponse = new Parameters();
        this.strings          = new Parameters();
        this.incomingUser     = new Parameters();
        this.outgoingRequest  = new Parameters();
        this.incomingSettings = new Parameters();
        this.incomingResult   = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingItem     = new Parameters();
        this.incomingControl  = new Parameters();
        this.incomingReport   = new Parameters();
        this.incomingSuggestion = new Parameters();
        this.searchElement    = ElementRef;
        this.incomingAmbassador = new Parameters();
        this.incomingUser.setAttributes(this.service.app.data.user.hasOwnProperty('id') ? this.service.app.data.user : {});
        this.incomingForm.setAttributes(this.formService.getFormById(this.formService.formSelectedId));
        this.formService.setSelected(this.incomingForm.getAttributes());
        this.responseService.responseSelectedCntrlNum = null;
        this.incomingSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingReport.setAttributes(this.dashboardService.incomingReport.attributes);
        this.incomingAmbassador.setAttributes({});
        this.strings.setAttributes(this.service.app.strings.params);
    }

    ngOnInit(): void {
        setTimeout(() => {
            document.title = this.incomingForm.getName() + ', ' + this.incomingForm.getResponseDisplayTitle();
            this.storage.has('responses').subscribe((result: any) => {
                if(result){
                    // // this.responses = JSON.parse('' + window.localStorage.getItem('responses'));
                    this.storage.get('responses').subscribe((responses: any) => {
                        if(responses instanceof Array) {
                            this.responses = responses;
                            this.responseGetSaved(() => {});
                            // this.responses = this.responses.filter((response: any) => {
                            //     return parseFloat(response.form_id) === parseFloat(this.formService.formSelectedId);
                            // });
                        }
                    });
                }else{
                    this.responseGetSaved(() => {if(this.responses.length === 0) this.addResponse();});
                    // this.setPost(this.incomingFormValues.attributes, () => {
                    //     this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.formService.formSelectedId) + '/respond');
                    // });
                }
            });
            this.initializeResponseControl();
            this.subscription =  this.broadcastChannel
                      .getNavChangeEmitter()
                      .pipe()
                      .subscribe((item: any) => {
                          if (item instanceof Object){
                              if (item.hasOwnProperty('prompt')) {
                                  this.incomingResponse.setAttributes(Object.assign({}, item.prompt));
                                  this.incomingResponse.setStatus(0);
                                  this.questions = [];
                                  this.incomingForm.getControls().map((question: any) => {
                                      this.incomingQuestion.setAttributes(Object.assign({}, question));
                                      this.incomingResponse.attributes.datas.map((response: any) => {
                                          this.incomingItem.setAttributes(response);
                                          if(this.incomingItem.getUnknown('formControl').cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString())
                                              this.incomingQuestion.setData(this.incomingItem.getData());
                                      }); this.questions.push(Object.assign({}, this.incomingQuestion.getAttributes()));
                                  });
                                  this.incomingResponse.setForm(Object.assign({}, this.incomingForm.getAttributes()));
                                  this.incomingResponse.setPostedBy(this.service.app.data.user);
                                  this.incomingResponse.setDate(this.incomingResponse.getDate().toString().split(' ')[0]);
                                  this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
                                  this.incomingResponse.attributes.form.progress = this.incomingResponse.getProgress();
                                  this.incomingResponse.attributes.form = Object.assign(this.incomingResponse.attributes.form, {controls: this.questions});
                                  this.position = this.service.findIndex(this.responses, 'cntrl_num', this.incomingResponse.getCntrlNum());
                                  if(this.position === -1){ this.responses.unshift(this.incomingResponse.getAttributes());
                                  }this.autoSave();
                                  this.responseService.setSelected(this.incomingResponse.getAttributes());
                                  this.responseService.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
                                  this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.attributes.id) + '/respond');
                              }
                          }else if(item === 'report'){
                              this.incomingReport.setUserId(this.incomingUser.getId());
                              this.incomingReport.setFormId(this.incomingForm.getId());
                              this.incomingReport.setType('responses');
                              this.service.httpService('post', '/reports',
                                  this.incomingReport.getAttributes(), {}, (response: any) => {
                                   this.service.responses = response;
                                  }, (error: any) => {
                                  });
                          }
                      });
        });
        if(this.incomingUser.attributes.hasOwnProperty('id')){
            if(this.incomingForm.attributes.hasOwnProperty('invitees')){
                var ambassadors: any = this.incomingForm.getInvitees().data.filter((ambassador: any) => {return parseFloat(ambassador.user_id) === parseFloat(this.incomingUser.getId()); });
                if(ambassadors.length !== 0){
                    this.incomingAmbassador.setAttributes(ambassadors[0]);
                }
            }
        }
        // if(this.service.empty(window.localStorage.getItem('responses'))){
        // }else{
        //     // this.responses = JSON.parse('' + window.localStorage.getItem('responses'));
        //     this.storage.get('responses').subscribe((responses: any) => { this.responses = responses;});
        //     this.responses = this.responses.filter((response: any) => {return response.form_id === this.formService.formSelectedId ;});
        // }
    }

    setRoute(){
        this.router.navigateByUrl('/app/forms/'+this.service.encrypt(this.incomingForm.getId())+'/routes');
    }

    getResponsesByUserId(userId: any){
        return this.service.responses.filter((response: any) => {
            this.incomingUser.setAttributes(this.service.app.data.user);
            this.incomingSuggestion.setAttributes(response);
            return parseFloat(this.incomingSuggestion.getPostedBy().id) === parseFloat(this.incomingUser.getId());
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSearchIconClicked(){
        setTimeout(() => {
            if(this.searchElement.nativeElement){
                this.searchElement.nativeElement.focus();
            }
        });
    }

    getQuestions(suggestions: any){
        return suggestions = suggestions.filter((suggestion: any) => {
           this.incomingQuestion.setAttributes(Object.assign({}, suggestion.formControl));
           return parseFloat(this.incomingQuestion.getControlId()) === 26 ||
               parseFloat(this.incomingQuestion.getControlId()) === 30 ||
               parseFloat(this.incomingQuestion.getControlId()) === 31;
        });
    }

// this.getForm(() => {
//     this.router.navigateByUrl('/app/forms/' + this.service.ecrypt(this.formService.formSelectedId) + '/respond');
// });

    initializeResponseControl(){
        this.filteredResponses = this.responseControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterResponse(name) : this.suggestions.slice())
       );
    }

    onSelectedResponse(response: any){}

    getResponse(){
        this.dialog.open(PromptComponent, {
          data   : {flag: 'responses', form_id: this.incomingForm.getId()},
          width  : 'auto',
          height : 'auto',
        });
    }

    onResponseSelectionChanged(event: MatAutocompleteSelectedEvent){
      this.selectedResponse = event.option.value;
      this.incomingResponse.setAttributes(event.option.value);
      this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
      this.view();
    }

    onChangeInput(event: any){
       setTimeout(() => {
         if(!this.service.isNull(event.target.value)){
          this.outgoingRequest.setAttributes({});
          this.outgoingRequest.setFormId(this.incomingForm.getId());
          this.outgoingRequest.setUserId(this.service.app.data.user.id);
          this.outgoingRequest.setSearchText(event.target.value);
          this.outgoingRequest.setDisplay('dashboard');
          this.outgoingRequest.setTarget('all');
          this.service.httpService( 'post', this.service.app.settings.routes.responses.search, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
             this.suggestions = response;
             this.initializeResponseControl();
          },  (error: any) => {});
         }
       });
    }

    private _filterResponse(name: string) {
        const filterValue = name.toLowerCase();
        return  this.suggestions.filter((response: any) => JSON.stringify(response).toString().toLowerCase().includes(filterValue));
    }

    responseDisplayFn(value: any){
        return value.title;
    }

    getTitle(response: any){
        this.incomingResponse.setAttributes(Object.assign({}, response));
        this.result = '';
        try {this.result =  !this.service.isNull(this.incomingForm.getTitle()) ?
                (!this.service.isNull(this.incomingResponse.getForm().controls.filter((question: any) => question.cntrl_num.toString() === this.incomingForm.getTitle())[0].data) ?
                    this.incomingResponse.getForm().controls.filter((question: any) => question.cntrl_num.toString() === this.incomingForm.getTitle())[0].data :
                    this.incomingResponse.getTitle()) :
                this.incomingResponse.getTitle();
        }catch (e) {}
        if(this.incomingSettings.attributes.hasOwnProperty('outlet')){
            this.incomingResponse.getForm().controls.filter((question: any) => {
                this.incomingQuestion.setAttributes(question);
                if(parseFloat(this.incomingQuestion.getControlId()) === 14){
                    this.incomingResult.setAttributes(this.incomingQuestion.attributes.hasOwnProperty('result') ? Object.assign({},this.incomingQuestion.getResult()) : {});
                    this.result = this.incomingResult.getName();
                }
            });
        }
        return this.result;
    }

    responseGetSaved(callback: any){
        // setTimeout(() => {
        //     if(this.responseGetSavedStatus){
        //         this.outgoingRequest.setAttributes({});
        //         this.outgoingRequest.setFormId(this.incomingForm.getId());
        //         this.outgoingRequest.setUserId(this.service.app.data.user.id);
        //         this.service.httpService( 'post', this.service.app.settings.routes.responseGetSaved, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {
        //             response.map((item: any) => {
        //                 this.incomingItem.setAttributes(item);
        //                 this.position = this.service.findIndex(this.responses, 'cntrl_num', this.incomingItem.getCntrlNum());
        //                 if(this.position === -1)this.responses.unshift(this.incomingItem.getAttributes());
        //             });
        //             this.autoSave();
        //             callback();
        //         },  (error: any) => {});
        //     }
        // });
    }

    autoSave(){
        try{
            if(this.responses instanceof Array){
                // window.localStorage.removeItem('responses');
                // window.localStorage.setItem('responses', JSON.stringify(this.responses));
                this.storage.set('responses', this.responses).subscribe(() => {});
            }
        }catch (e) {}
    }

    onContextMenu(event: MouseEvent, inspector: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { 'item': inspector };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    addResponse(){
        setTimeout((form: any = {}) => {
            this.setPost(this.incomingForm.getAttributes(), () => {
                try{
                    // window.localStorage.setItem('responses', JSON.stringify(this.responses));
                    if(this.responses instanceof Array){
                        this.storage.set('responses', this.responses).subscribe((rs: any) => {
                            this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/respond');
                        });
                    }
                }catch (e) {
                }
            });
        });
    }

    setTitle(){
        setTimeout(() => {
            this.incomingResponse.setAttributes(this.selectedResponse);
            var text: any = prompt('', this.incomingResponse.attributes.title);
            if (!this.service.isNull(text)){
                this.incomingResponse.setTitle(text);
                this.autoSave();
            }
        });
    }

    view(){
        setTimeout(() => {
            this.incomingResponse.setAttributes(Object.assign({}, this.selectedResponse));
            this.responseService.setSelected(this.incomingResponse.getAttributes());
            this.responseService.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
            this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.formService.formSelectedId) + '/respond');
        });
    }

    openDateDialog(){
        this.dialog.open(FilterDashboardComponent, { data: {report: this.incomingReport.getAttributes()} , width  : '55%', height : '100%'});
    }

    getResonsesByStatus(status: any){
        return this.responses.filter((response: any) => {
            let incomingResponse = new Parameters();
            incomingResponse.setAttributes(response);
            if(parseFloat(this.incomingForm.getCategoryId()) !== 7){
                return incomingResponse.attributes.hasOwnProperty(this.strings.getPostedBy()) &&
                    parseFloat(incomingResponse.getFormId().toString()) === parseFloat(this.formService.selected.id) &&
                    parseFloat(incomingResponse.getPostedBy().id) === parseFloat(this.service.app.data.user.id) &&
                    parseFloat(incomingResponse.getStatus().toString()) === parseFloat(status.toString());
            }else{
                return incomingResponse.attributes.hasOwnProperty(this.strings.getPostedBy()) &&
                    parseFloat(incomingResponse.getStatus().toString()) === parseFloat(status.toString()) &&
                    ((parseFloat(incomingResponse.getStatus().toString()) === 0) &&
                        parseFloat(incomingResponse.getPostedBy().id)       === parseFloat(this.service.app.data.user.id) &&
                        parseFloat(incomingResponse.getFormId().toString()) === parseFloat(this.formService.selected.id) ||
                        (parseFloat(incomingResponse.getStatus().toString()) === 1 &&
                            incomingResponse.getDate() === moment(new Date()).format('MM/DD/YYYY')) &&
                        parseFloat(incomingResponse.getPostedBy().id)       === parseFloat(this.service.app.data.user.id) &&
                        parseFloat(incomingResponse.getFormId().toString()) === parseFloat(this.formService.selected.id));
            }
        });
    }

    remove(){
        setTimeout(() =>{
            this.incomingResponse.setAttributes(this.selectedResponse);
            this.dialog.open(DeleteDialogComponent, {
                data  : {
                    data : this.responses,
                    model: this.incomingResponse.attributes,
                    title: this.incomingResponse.attributes.title,
                    id   : this.incomingResponse.attributes.cntrl_num,
                    url  : '',
                    key  : 'cntrl_num',
                },
                width : 'auto',
            });
            this.dialog.afterAllClosed.subscribe(() => {
                this.autoSave();
            });
        });
    }

    getForm(callback: any){
        this.publicFormUrl = this.publicFormUrl + this.formService.formSelectedId;
        this.service.httpService(
            'post', this.publicFormUrl, {}, {}, (form: any) => {
                this.setPost(form, () => {
                    callback({});
                });
            }, (error: any) =>  {
            });
    }

    setPost(form: any, callback: any){
        setTimeout(() => {
            this.incomingResponse.setAttributes({});
            this.incomingResponse.setFormId(this.formService.formSelectedId);
            this.incomingResponse.setStartingAt(moment(new Date()).format('HH:mm:ss'));
            this.incomingResponse.setAltitude(0);
            this.incomingResponse.setDeviceUsage(this.service.getBrowserName());
            this.incomingResponse.setDepartmentId(1);
            this.incomingResponse.setAddress('');
            this.incomingResponse.setTitle('Untitled');
            this.incomingResponse.setStatus(0);
            this.incomingResponse.setLat(0);
            this.incomingResponse.setLng(0);
            this.incomingResponse.setPostId(this.service.random());
            this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
            this.incomingResponse.setForm(Object.assign({}, this.incomingForm.getAttributes()));
            this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY'));
            if(Object.keys(this.incomingUser.attributes).length === 0){
             window.localStorage.setItem(this.incomingForm.getId(), JSON.stringify(this.incomingForm.getAttributes()));
            }else {
                this.incomingResponse.setPostedBy(this.incomingUser.getAttributes());
                this.incomingResponse.setUserId(this.incomingUser.getId());
            }this.responses.unshift(this.incomingResponse.getAttributes());
            this.responseService.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
            callback({});
        });
    }
}
