import {Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {Parameters} from '../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {InventoriesService} from '../inventories.service';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormService} from '../../forms/form.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-dialog-inventories',
  templateUrl: './dialog-inventories.component.html',
  styleUrls: ['./dialog-inventories.component.scss']
})
export class DialogInventoriesComponent implements OnInit {
  @ViewChild('itemElm', {static: false})  itemElm: any;
  @ViewChild('itemMatAutocompleteTrigger', {static: false}) itemMatAutocompleteTrigger: any;
  formValues: FormGroup = new FormGroup({});
  incomingInventorySelected: any;
  incomingQuestionSelected: any;
  incomingItemSelected: any;
  incomingForm: any;
  keys: any;
  itemControl = new FormControl();
  filteredItems: any = [];
  outgoingRequest: any;
  position: any;
  incomingItem: any;
  incomingDialogItems: any;
  title: any;
  constructor(public  service: AppService,
     public  formBuilder: FormBuilder,
     private notifier: NotifierService,
     public  inventoryService: InventoriesService,
     public  formService: FormService,
     private activatedRoute: ActivatedRoute,
     public broadcastChannel: BroadcastChannelService,
     @Inject(MAT_DIALOG_DATA) public data: any,
     public dialogRef: MatDialogRef<DialogInventoriesComponent>) {
     this.itemElm = ElementRef;
     this.itemMatAutocompleteTrigger = MatAutocompleteTrigger;
     this.incomingInventorySelected = new Parameters();
     this.incomingQuestionSelected = new Parameters();
     this.outgoingRequest = new Parameters();
     this.incomingDialogItems = new Parameters();
     this.incomingItemSelected = new Parameters();
     this.incomingItem = new Parameters();
     this.incomingForm = new Parameters();
     this.incomingInventorySelected.setAttributes(this.inventoryService.getSelected());
     this.keys = Object.keys(this.incomingInventorySelected.getAttributes());
  }

  ngOnInit(): void {
  this.incomingDialogItems.setAttributes(this.data);
    if(window.location.href.toString().includes('/forms')){
      this.incomingForm.setAttributes(this.formService.getFormById(this.incomingDialogItems.getFormId()));
      if(window.location.href.toString().includes('/questions')){
       this.incomingQuestionSelected.setAttributes(this.getQuestionById(this.incomingDialogItems.getQuestionId()));
       this.requestItemByCntrlNum();
      }
    }

    this.formValues = this.formBuilder.group({
      in_out_qty:        ['', Validators.compose([])],
       item_id:          ['', Validators.compose([])],
       reference1:       ['', Validators.compose([])],
       reference2:       ['', Validators.compose([])],
       reference3:       ['', Validators.compose([])],
       reference4:       ['', Validators.compose([])],
       description:      ['', Validators.compose([])],
    });

    if(!this.incomingInventorySelected.attributes.hasOwnProperty('id')) this.incomingInventorySelected.setAttributes({}); else{
      for(var key in this.incomingInventorySelected.getAttributes()) {
        if(this.incomingInventorySelected.attributes[key] instanceof Object || this.incomingInventorySelected.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingInventorySelected.attributes[key]);}else {
            if(this.incomingInventorySelected.attributes[key] instanceof Object || this.incomingInventorySelected.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingInventorySelected.attributes[key], Validators.compose([])));
            }
          }
        }
      }
    }

    this.onItemValueChange();
  }

    getQuestionById(id: any){return this.incomingForm.getControls().filter((question: any) => {return parseFloat(question.id) === parseFloat(id);})[0];}


  onItemValueChange(){
    this.filteredItems = this.itemControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(name  => name ? this._filterItem(name) : this.service.app.data.items.data.slice())
     );
  }

  requestItemByCntrlNum(){
     this.search(this.incomingQuestionSelected.getCntrlNum(), (results: any)=>{
       this.position = this.service.findIndex(this.service.app.data.items.data, 'cntrl_num', this.incomingQuestionSelected.getCntrlNum());
       this.incomingItemSelected.setAttributes(this.service.app.data.items.data[this.position]);
       this.incomingItemSelected.setName(this.incomingQuestionSelected.getLabel());
       this.formValues.controls['item_id'].setValue(this.incomingItemSelected.getId());
       this.formValues.controls['reference4'].setValue(this.incomingQuestionSelected.getCntrlNum());
       this.title = this.incomingQuestionSelected.getLabel();
       this.formValues.controls['reference2'].setValue('question');
//     this.onItemValueChange();
       this.itemControl.setValue(this.incomingItemSelected.getAttributes());
       setTimeout(()=> {this.itemMatAutocompleteTrigger.closePanel();
       }, 212);
     });
  }


  onChangeItemText(event: any){
      this.formValues.controls['item_id'].setValue('');
      this.search(event.target.value, (results: any)=>{
      //  this.onItemValueChange();
      });
  }

  search(searchText:any, callback: any){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setSearchText(searchText);
    this.service.httpService( 'post', this.service.app.routes.items.search, this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'},
                (results: any) => {
                  results.map((result: any) => {
                    this.incomingItem.setAttributes(Object.assign({}, result));
                    this.position = this.service.findIndex(this.service.app.data.items.data, 'cntrl_num', this.incomingItem.getCntrlNum());
                    if(parseFloat(this.position) === -1)this.service.app.data.items.data.unshift(this.incomingItem.getAttributes());
                  });
                  callback(null);
                },  (error: any) => {
                });
  }

  displayFn(value: any){
     return value.name;
  }

  private _filterItem(label: string) {
     const args = label.toString().toLowerCase();
     return this.service.app.data.items.data.filter((item: any) => JSON.stringify(item).toString().toLowerCase().includes(args));
  }

  onItemSelectionChanged(event: MatAutocompleteSelectedEvent){
      this.formValues.controls['item_id'].setValue(event.option.value.id);
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  onSubmit(itemForm: any) {
      this.outgoingRequest.setAttributes(itemForm);
      this.service.httpService(
        'post', !this.incomingInventorySelected.attributes.hasOwnProperty('id') ? this.service.app.routes.inventories.add : this.service.app.routes.inventories.update,
        this.outgoingRequest.getAttributes(), {},(response: any) => {
          if(!this.incomingInventorySelected.attributes.hasOwnProperty('id')) {
            this.inventoryService.inventories.data.unshift(response);
          } else this.incomingInventorySelected.attributes = Object.assign(this.incomingInventorySelected.attributes, response);
          this.closeDialog();
          this.notifier.notify('success', 'successfull ' + (!this.incomingInventorySelected.attributes.hasOwnProperty('id') ? 'added' : 'updated' ));
          this.inventoryService.setSelected({});
//        this.broadcastChannel.emitNavChangeEvent({ambassador : response});
        }, (error: any) => {
        });
  }


}
