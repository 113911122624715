import { Component, OnInit } from '@angular/core';
import {AppService} from '../app.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogEmployeesComponent} from './dialog-employees/dialog-employees.component';
import {EmployeesService} from './employees.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  app: any;
  constructor(public service: AppService,
              private dialog: MatDialog,
              public metthods: EmployeesService) {
    this.app = service.app;
    this.app.form.modules  = [{path: '/app/employees', title: 'Employees'}];
    this.app.form.subtitle = 'the dead body of an animal ready for butchery, after removal of skin, visceral organs, head, feet etc..';
    this.app.form.module   = 'employees';
  }

  ngOnInit(): void {
    this.metthods.setData(this.app.data.employees);
  }

  openDialog(){
    this.dialog.open(DialogEmployeesComponent, {data  : {}, width : '55%', height: '100%', });
  }

}
