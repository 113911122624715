import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {AppService} from "../../../../app.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {StorageMap} from "@ngx-pwa/local-storage";
import {FormService} from "../../../form.service";
import {Parameters} from "../../../../parameters";
import {PromptComponent} from '../../../../prompt/prompt.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-other-option-questions',
  templateUrl: './other-option-questions.component.html',
  styleUrls: ['./other-option-questions.component.scss']
})
export class OtherOptionQuestionsComponent implements OnInit, OnDestroy {
  @Input() question: any;
  @Input() position: any;
  data: any;
  questions: any;
  form: any;
  label: any;
  incomingQuestion: any;
  subscription: any;
  constructor(
      public service: AppService,
      public broadcastChannel: BroadcastChannelService,
      private storage: StorageMap,
      private dialog: MatDialog,
      public formService: FormService) {
    this.incomingQuestion = new Parameters();
    this.form = formService.selected;
    this.questions = this.form.controls;
    this.incomingQuestion.setAttributes(this.question);
  }

  ngOnInit(): void {
    this.incomingQuestion.setAttributes(this.question);
    this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
      .subscribe((item: any) => {
        if(item instanceof Object){
          if (item.hasOwnProperty('prompt')) {
               if (!this.service.isNull(item.prompt)) {
                 this.formService.addOptional(this.question.options, item.prompt);
                 this.formService.newOptional = Object.assign(this.formService.newOptional, {questionCntrlNum: this.incomingQuestion.getCntrlNum()});
                 this.formService.addOther();
                 this.service.setSuggestion(item.prompt, ()=>{
                 });
               }
          }
        }
      });
  }

  ngOnDestroy(){
  this.subscription.unsubscribe();
  }

  addOptional(){
    this.incomingQuestion.setAttributes(this.question);
    this.dialog.open(PromptComponent, {
      data  : {},
      width  : 'auto',
      height : 'auto',
    });
  }

  addOther(){this.storage.set('others', [this.formService.newOptional]).subscribe(() => {});}
  radioChange(event: any, optional){
    this.question.data = optional.label;
    this.broadcastChannel.emitNavChangeEvent({action: 'next',  optionalId: optional.id, optionalCntrlNum: optional.cntrl_num, cntrlNum: optional.cntrl_num});
  }


  hideAddOthers(){
    this.incomingQuestion.setAttributes(this.question);
    return this.service.parseFloat(this.incomingQuestion.getAddOthers()) === 0;
  }

  ngAfterViewInit(): void {
    this.incomingQuestion.setAttributes(this.question);
  }

}
