import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AmbassadorsService} from '../../ambassadors/ambassadors.service';
import {Parameters} from '../../parameters';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {DialogTeamsComponent} from '../../teams/dialog/dialog-teams.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-dialog-profile',
  templateUrl: './dialog-profile.component.html',
  styleUrls: ['./dialog-profile.component.scss']
})
export class DialogProfileComponent implements OnInit {

  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  filteredTeamsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedUser: any;
  keys: any;
  selectedUser: any;
  addressValue: any;
  photo: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public metthods: AmbassadorsService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogProfileComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedUser.setAttributes(this.app.data.user);
    this.keys = Object.keys(this.incomingSelectedUser.getAttributes()).length;
    this.selectedUser = this.app.data.user;
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {this.metthods.setSelected({}); });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      avatar:      ['', Validators.compose([])],
      password:    ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
      team_id:     ['', Validators.compose([])],
      role_id:     ['8', Validators.compose([])],
      role_play:   ['8', Validators.compose([])],
    });

    if(this.keys === 0) this.selectedUser = {}; else{
      this.formValues.controls['name'].setValue(this.incomingSelectedUser.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedUser.getDescription());
      this.formValues.controls['phone'].setValue(this.incomingSelectedUser.getPhone());
      this.formValues.controls['email'].setValue(this.incomingSelectedUser.getEmail());
      this.formValues.controls['location'].setValue(this.incomingSelectedUser.getLocation());
      this.formValues.controls['role_id'].setValue(this.incomingSelectedUser.getRoleId());
      this.formValues.addControl('id', new FormControl(this.incomingSelectedUser.getId(), Validators.compose([])));
      this.formValues.removeControl('password');
      this.addressValue = this.incomingSelectedUser.getLocation();
      for(var key in this.incomingSelectedUser.getAttributes()) {
        if(this.incomingSelectedUser.attributes[key] instanceof Object || this.incomingSelectedUser.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedUser.attributes[key]);
          }else {
            if(this.incomingSelectedUser.attributes[key] instanceof Object || this.incomingSelectedUser.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingSelectedUser.attributes[key], Validators.compose([])));
            }
          }
        }
      }
    }
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  addTeam(){
    this.dialog.open(DialogTeamsComponent, {data  : {}, width : '55%', height: '100%', });
  }


  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//  onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//  }

  handlEditorFocus(){}

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['avatar'].setValue(this.service.dataURItoBlob(reader.result));
      this.photo = reader.result;
    };
  }



  private _filterTeam(name: string) {
    const filterValue = name.toLowerCase();
    return this.app.data.teams.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  handleAddressChanger(result: any){
  }

//  handleAddressChange(result: PlaceResult){
//  }


  onTeamSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['team_id'].setValue(event.option.value.id);
  }

  displayFn(value: any){
    return value.name;
  }

  onSubmit(userForm: any) {
    delete userForm.password;
    this.service.httpService(
      'post', this.keys === 0 ? '/users' : '/users/' + this.incomingSelectedUser.getId() + '/update',
      userForm, {},
      (response: any) => {
        if(this.keys === 0) {
          this.metthods.data.data.unshift(response);
        } else this.selectedUser = Object.assign(this.selectedUser, response);
        this.closeDialog();
        this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
      }, (error: any) => {
      });

  }

}
