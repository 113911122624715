import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {FormService} from '../../forms/form.service';
import {MatDialog} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {UserService} from '../../users/user.service';
import {InviteeService} from '../invitee.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-dashboard-invites',
  templateUrl: './dashboard-invites.component.html',
  styleUrls: ['./dashboard-invites.component.scss']
})
export class DashboardInvitesComponent implements OnInit, AfterContentInit, OnDestroy {
  app: any;
  incomingForm: any;
  incomingCampaign: any;
  map: any;
  mapClickListener: any;
  incomingInviteeSelected: any;
  invites: any;
  addressValue: any;
  lat: any;
  lng: any;
  zoom: any = 10;
  icon: any;
  constructor(public service: AppService,
              public formService: FormService,
              private dialog: MatDialog,
              private inviteeService: InviteeService,
              private userService: UserService) {
    this.app = service.app;
    this.incomingForm = new Parameters();
    this.incomingInviteeSelected = new Parameters();
    this.incomingForm.setAttributes(formService.getFormById(this.formService.formSelectedId));
    this.app.form.modules  = [{path: '/app/invites', title: 'Invites'}];
    this.app.form.subtitle = '';
    this.app.form.module   = 'invitees';
    this.incomingInviteeSelected.setAttributes({});
  }

  ngOnInit(): void {
    setTimeout(() =>{
      this.icon = {url: 'https://i.imgur.com/7teZKif.png', scaledSize: {height: 20, width: 20}};
      this.invites = this.service.app.data.invitees.data.filter((invitee: any) => {return invitee.form_id === this.incomingForm.getId();});
      this.incomingInviteeSelected.setAttributes(this.invites.length === 0 ? {} : this.invites[0]);
      this.incomingInviteeSelected.setRoutes([]);
    });
  }

  onAddressTextChange(event: any){
  }

  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    // this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onInfoWindowClose(gm: any, infoWIndow: any){}

  onClickMarker(event: any, marker: any){
  }

  onLocationSelected(location: any) {
    this.lat = parseFloat(location.latitude);
    this.lng = parseFloat(location.longitude);
    this.zoom = 16;
  }


  ngAfterContentInit(): void {
    setTimeout(() => {
      document.title = ('Register').toString().toUpperCase() + ' ,respodents';
      this.service.header = ('Respodent').toString().toUpperCase() + ' (' + this.app.data.ambassadors.data.length + ') ,registration';
    });
  }

  getHeight(){ return {height: window.innerHeight}; }

  mapReadyHandler(map: any){
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => { // @ts-ignore
      this.incomingInviteeSelected.getRoutes().push({lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng())});
    });
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }


}
