<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric">Name</th>
    <th class="mdl-data-table__cell--non-numeric">Campaign</th>
    <th class="mdl-data-table__cell--non-numeric">Client</th>
    <th class="mdl-data-table__cell--non-numeric">Address</th>
    <th class="mdl-data-table__cell--non-numeric">Description</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let team  of (flag.includes('all') ? (teams.data | jsonFilter:searchCntrl) : (teams.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, team)"
      (mousedown)="selectedTeam = team">
    <td class="mdl-data-table__cell--non-numeric">{{team.name}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{getCampaignByTeam(team).name}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{team.campaign.client.name}}</td>
    <td class="mdl-data-table__cell--non-numeric">{{team.address}}</td>
<!--<td><img class="img-thumbnail avatar"    src="{{team.campaign.client.logo}}" *ngIf="team.campaign.client.logo" /></td>-->
    <td class="mdl-data-table__cell--non-numeric" [innerHTML]="team.description"></td>
    <td>
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="navBrunches(selectedTeam)">Brunches</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedTeam)">Edit</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedTeam)">Delete</li>
          <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedTeam)">Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="navBrunches(selectedTeam)">Brunches</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedTeam)">Edit</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedTeam)">Delete</button>
          <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedTeam)">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
