<div layout="row">
  <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
    <div class="panel-body">
      <mat-list>
        <mat-list-item>
          <span class="" mat-line>
            <app-label [question]="question"></app-label>
          </span>
        </mat-list-item>
        <mat-list-item>
          <span class="" mat-line>
            <center><img (click)="image.click()"
                         class="cursor img-thumbnail"
                         src="{{question.hasOwnProperty('pic') ? question.pic : 'assets/images/categories-icon-1.png'}}"
                         style="width: auto; max-height: 100px">
            </center>
          </span>
        </mat-list-item>
      </mat-list>
      <div>
        <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
      </div>
    </div>
  </div>
</div>

<input type="file" (change)="getImage($event)" [ngStyle]="{'display':'none'}" accept="image/*" #image>

