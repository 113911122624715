import { Component, OnInit } from '@angular/core';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {AppService} from '../../app.service';
import {Router} from '@angular/router';
import {DialogProfileComponent} from '../../users/dialog-profile/dialog-profile.component';
import {MatDialog} from '@angular/material/dialog';
import {CampaignService} from '../../campaigns/campaign.service';
@Component({
  selector: 'app-right-header',
  templateUrl: './right-header.component.html',
  styleUrls: ['./right-header.component.scss']
})
export class RightHeaderComponent implements OnInit {
  app: any = {data: null};
  constructor(public broadcastChannel: BroadcastChannelService,
              public  dialog: MatDialog,
              private router: Router,
              public campaignService: CampaignService,
              public  service: AppService) {
    this.app = this.service.app;
  }

  ngOnInit(): void {
  }

  openDateMenu(){
      this.broadcastChannel.emitNavChangeEvent('openDateMenu');
  }

  setUser(user: any){
  }

  logout(){
      localStorage.setItem(this.service.app.settings.tokenName, '');
      this.service.setToken('');
      this.router.navigateByUrl('login');
  }

  openProfile(){
      this.dialog.open(DialogProfileComponent, { data:  { }, width  : '55%', height : '100%', });
  }

}
