<center>
    <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px" >
</center>
<div class="row plist" style="margin-top: 5%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <mat-tab-group  mat-align-tabs="center">
            <mat-tab label="Forms ({{service.app.data.forms.data.length}})">
                <div><br><br>
                    <section class="post-section blog-post" style="background-color: transparent !important;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6 col-xs-12"
                                     (click)="onSelectedForm(form)"
                                     *ngFor="let form of service.app.data.forms.data" style="cursor: pointer">
                                    <div *ngIf='form.active === 1'>
                                        <div class="post-box">
                                            <div class="text-box">
                                                <h4>
                                                    <a>{{form.name}}</a>
                                                </h4>
                                                <p [innerHTML]="form.description"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="col-md-2"></div>
</div>
