import {ElementRef, Injectable, ViewChild} from '@angular/core';
import {FormService} from '../form.service';
import * as html2pdf from 'html2pdf.js';
import {Parameters} from "../../parameters";
import {Router} from "@angular/router";
import {AppService} from "../../app.service";
import {timeout} from "rxjs/operators";
import {jsPDF} from 'jspdf';


@Injectable({
  providedIn: 'root'
})
export class PdfFormService {
  @ViewChild('tableElement') tableElement: any;
  questionsHtmlContents: any = '';
  questionalValues: any;
  constructor(private formServce: FormService,
              private router: Router,
              private service: AppService) {
    this.questionalValues = new Parameters();
    this.tableElement = ElementRef;
  }

  createFormHtmlContents(){
    this.createQuestionalsHtmlContents();
    return `
        <center><h4>` + this.formServce.selected.name        + `</h4></center>
        <center><p>`  + this.formServce.selected.description + `</p></center>
        <div>`        + this.questionsHtmlContents           + `</div>
        `;
  }

  createQuestionalsHtmlContents(){
    this.formServce.questions.map((questional: any) => {
      this.questionalValues.setAttributes(questional);
      this.questionsHtmlContents += (
          `<h4>`  + this.questionalValues.attributes.label + `</h4>
           <hr />
           <div>` + this.questionalValues.attributes.data  + `</div>
          `
      );
    });
  }

  dottedlines(){
    return  `-------------------------------------------------`;
  }

  downloadPdf(){
    // {
    //   orientation: "l",
    //       unit: "in",
    //     format: 'a4'
    // }
    // setTimeout(() => {
    //   alert(this.tableElement.nativeElement.innerHTML);
    // }, this.timeout());
    // this.router.navigateByUrl('/wait..');
    setTimeout((worker: any, contents: any) => {
      contents = '';
      if (typeof Worker !== 'undefined') {
        worker = new Worker('../../../assets/js/workers.js');
        worker.onmessage = ((evt: any) => {
          const opt: any = {
            margin: 1,
            filename: this.formServce.selected.name + '.pdf',
            image: {type: 'jpeg', quality: 10},
            html2canvas: {scale: 7, logging: true},
            // jsPDF: {unit: 'in', format: 'a4', orientation: 'l'}
          };
          contents = `<center>`  + this.createFormHtmlContents() + `</center>`;
          const doc = new jsPDF({});
          doc.html(contents, {
            callback: (docx) => {
              docx.save();
            },
            x: 5,
            y: 5
          });

          // html2pdf()
          //     .from(contents)
          //     .set(opt)
          //     .toPdf()
          //     .save(this.formServce.onSelectProduct.name.toString().toLowerCase() + '.pdf').then(() => {
          //       window.history.back();
          // });
        });
        worker.postMessage('null');
      }
    });
  }
  timeout(){return 4021;}
}
