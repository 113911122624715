<div>
    <div *ngIf="service.parseFloat(incomingForm.getCategoryId()) === 6"
         [ngStyle]="{'position':'fixed','bottom':'20%','right':'5%','z-index':'9999999'}" >
        <center>
            <div [ngStyle]="{'padding':'50px','background-color':'#000'}">
                <div class="sawasdee18X2"
                     [ngStyle]="{'color':'#fff'}">
                    {{service.upperStartingCharacter(incomingForm.getName(), true)}}
                    <div>[scan to view or <br>add photos]</div>
                </div>
            </div>
            <div id="qrcode"></div>
            <span class="mitra" [ngStyle]="{'color':'#fff','font-size':'22px'}">
              <!--  scan to view or add photo-->
            </span>
        </center>
    </div>
    <div class="page" [ngStyle]="{'height':this.service.getHeight()+'px'}">
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="">
                    <div class="fixed">
                        <div class="roww">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="row top_tiles" [ngStyle]="{'padding': '2px'}">
                                    <div [ngStyle]="{'width':this.service.getWidth()+'px'}">
                                        <mat-grid-list cols="{{service.getWidth()/175}}" rowHeight="1:1">
                                            <ng-container *ngFor="let response of (form.responses.data |slice:0:300| jsonFilter:searchCntrl);let incrementalResponse=index">
                                                <ng-container *ngFor="let responseResult of response.datas;let incrementalResponseResult=index">
                                                    <ng-container *ngIf="responseResult.formControl.control.id === 2 && !service.isNull(responseResult.data)">
                                                        <mat-grid-tile>
                                                            <div class="cursor demo-card-image mdl-card mdl-shadow--2dp"
                                                                 style="animation: deg20 5s infinite;background:url({{responseResult.data}}) center / cover">
                                                            </div>
                                                        </mat-grid-tile>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </mat-grid-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
