<div class="page">
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div>
                <div class="fixed">
                    <div class="roww">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="row top_tiles">
                                <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="tile-stats" style="min-height: auto; margin-bottom: 0px !important; padding-bottom: 0px !important; border: 0px !important;">
                                        <div class="pull-right">
                                            <table>
                                                <td valign="top">asafdff</td>
                                                <td><img class ='img-thumbnailx' src="sadsdsad" style="min-width: 60px; max-height: 25px; float: right;margin-left: 5px"/></td>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <agm-map
                                    #gm
                                    [fitBounds]="false"
                                    [zoom]="10"
                                    (mapReady)="mapReadyHandler($event)"
                                    [disableDefaultUI]="false"
                                    [styles]="service.app.settings.map.styles.style1"
                                    style="height :{{getHeight().height + 'px'}}">
                                </agm-map>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
