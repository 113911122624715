import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {AppService} from "../../../app.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {FormService} from "../../form.service";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {
  @ViewChild('controlElement')  controlElement: any;
  @Input() position: any;
  controls: any = [];
  filteredControlsOptions: any;
  search: any;
  controlControl = new FormControl();
  questions: any = [];
  constructor(public service: AppService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddQuestionComponent>,
              private broadcastChannelService: BroadcastChannelService,
              private formService: FormService) {
    this.controlElement = ElementRef;
    this.controls = this.service.app.data.controls;
    this.questions = this.formService.questions;
    this.position = this.data.position;
  }

  ngOnInit(): void {
    setTimeout(() =>{
      this.filteredControlsOptions = this.controlControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterCampany(name) : this.controls.slice())
      );
    });
  }

  onSelectControl(control: any){
    this.formService.addQuestion(this.formService.questions, control, this.position);
    this.addOptional();
    this.dialogRef.close();
    this.broadcastChannelService.emitNavChangeEvent({questional: 'add'});
  }



  onControlSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.controlElement.nativeElement.value = '';
    this.search = '';
    this.addQuestion(event.option.value);
  }

  timeout(){
    // tslint:disable-next-line:radix
    return parseInt('2020');
  }

  displayFn(value: any){
    return value.name;
  }

  private _filterCampany(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.controls.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  addOptional(){
    if(this.formService.newQuestion.control.id === 5){
      this.formService.addOptional(this.formService.newQuestion.options, 'untitled Option');
    }
  }

  addQuestion(control: any){
    this.formService.addQuestion(this.questions, control, this.position);
    this.broadcastChannelService.emitNavChangeEvent({scroll: 'down'});
    var text: any = prompt('', 'Add Question Title');
     if(!this.service.isNull(text)) {
      this.formService.questions[this.service.findIndex(this.questions, 'cntrl_num', this.formService.newQuestion.cntrl_num)].label = text;
    }
  }

  setQuestion(control: any){
    // this.question.form_control_id = control.id;
    // this.question.control_id = control.id;
    // this.question.control = Object.assign(this.question.control, control);
  }

}
