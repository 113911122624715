<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <mat-list>
                <mat-list-item>
                  <span class="" mat-line>
                    <app-label [question]="question"></app-label>
                  </span>
                </mat-list-item>
                <mat-list-item>
                  <span class="" mat-line>
                    <mat-form-field  class="example-full-width" appearance="fill">
                      <input type="text"
                             matInput
                             placeholder=""
                             [value]=addressValue
                             [address]="addressValue"
                             (input)="onAddressTextChange($event)"
                             (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                             (onLocationSelected)="onLocationSelected($event)"
                             matGoogleMapsAutocomplete >
                    </mat-form-field>
                  </span>
                </mat-list-item>
            </mat-list>
            <div>
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
