<table id="datatable" class="table table-striped table-bordered" style="width:100%">
    <thead>
    <tr>
        <th class="mdl-data-table__cell--non-numeric headline2">Item Name</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Pic</th>
        <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
        <th class="text-right">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item  of (flag.includes('all') ? (itemService.items.data | jsonFilter:searchCntrl) : (itemService.items.trashed.data | jsonFilter:searchCntrl))"
        (contextmenu)="incomingItemSelected.setAttributes(item);onContextMenu($event, item)"
        (mousedown)="incomingItemSelected.setAttributes(item)">
        <td class="mdl-data-table__cell--non-numeric headline">{{item.name}}</td>
        <td class="mdl-data-table__cell--non-numeric headline2">
            <center>
                <img class="img-thumbnail avatar" src="{{(item.avatar) ?  item.avatar : 'assets/images/avatarx.jpg'}}" />
            </center>
        </td>
        <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="item.description|safeUrl"></td>
    </tr>
    </tbody>
</table>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="inspector">
<!--        <button mat-menu-item *ngIf="flag === 'all'"     (click)="createFrame()">Generate Frame</button>-->
        <button mat-menu-item *ngIf="flag === 'all'"     (click)="openViewDialog()">View</button>
<!--        <button mat-menu-item *ngIf="flag === 'all'"     (click)="sendQrcodeFrameThroughtWhatsapp()">Change Password</button>-->
    </ng-template>
</mat-menu>