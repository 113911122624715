import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AppService} from './app.service';
import {throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class MethodsService {
    private httpHeaders: any = null;
    private httpParams: any = null;
    private formData: any = new FormData();
    constructor(private httpClient: HttpClient){}
}
