import {Component, Inject, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {UserService} from '../user.service';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Parameters} from '../../parameters';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import PlaceResult = google.maps.places.PlaceResult;
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/internal/operators";

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user.component.html',
  styleUrls: ['./dialog-user.component.scss']
})
export class DialogUserComponent implements OnInit {
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  teamControl = new FormControl();
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedUser: any;
  keys: any;
  selectedUser: any;
  addressValue: any;
  photo: any;
  submittedFormValues: any;
  roleControl = new FormControl();
  filteredRoles: any;
  constructor(public  service: AppService,
              private notifier: NotifierService,
              private userService: UserService,
              public  broadcastChannel: BroadcastChannelService,
              public  formBuilder: FormBuilder,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DialogUserComponent>) {
    this.app = this.service.app;
    this.configAngularEditor = this.service.setAngularEditorConfig();
    this.htmlContent = null;
    this.parameters = new Parameters();
    this.submittedFormValues = new Parameters();
    this.parameters.setAttributes({});
    this.incomingSelectedUser = new Parameters();
    this.incomingSelectedUser.setAttributes(this.userService.selected);
    this.keys = Object.keys(this.incomingSelectedUser.getAttributes()).length;
    this.selectedUser = this.userService.selected;
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(result => { });
    this.addressValue = '';
    this.formValues = this.formBuilder.group({
      name:        ['', Validators.compose([])],
      description: ['', Validators.compose([])],
      phone:       ['', Validators.compose([])],
      email:       ['', Validators.compose([])],
      avatar:      ['', Validators.compose([])],
      password:    ['', Validators.compose([])],
      location:    ['', Validators.compose([])],
      team_id:     ['', Validators.compose([])],
      role_id:     ['8', Validators.compose([])],
      role_play:   ['8', Validators.compose([])],
    });

    this.filteredRoles = this.roleControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(name  => name ? this._filterRole(name) : this.service.app.data.roles.slice())
    );

    if(this.keys === 0) this.selectedUser = {}; else{
      this.formValues.controls['name'].setValue(this.incomingSelectedUser.getName());
      this.formValues.controls['description'].setValue(this.incomingSelectedUser.getDescription());
      this.formValues.controls['phone'].setValue(this.incomingSelectedUser.getPhone());
      this.formValues.controls['email'].setValue(this.incomingSelectedUser.getEmail());
      this.formValues.controls['location'].setValue(this.incomingSelectedUser.getLocation());
      this.formValues.controls['role_id'].setValue(this.incomingSelectedUser.getRoleId());
      this.formValues.addControl('id', new FormControl(this.incomingSelectedUser.getId(), Validators.compose([])));
      this.formValues.removeControl('password');
      this.addressValue = this.incomingSelectedUser.getLocation();
      for(var key in this.incomingSelectedUser.getAttributes()) {
        if(this.incomingSelectedUser.attributes[key] instanceof Object || this.incomingSelectedUser.attributes[key] instanceof Array){} else{
          if(this.formValues.contains(key)) {
            this.formValues.controls[key].setValue(this.incomingSelectedUser.attributes[key]);
          }else {
            if(this.incomingSelectedUser.attributes[key] instanceof Object || this.incomingSelectedUser.attributes[key] instanceof Array){} else{
              this.formValues.addControl(key, new FormControl(this.incomingSelectedUser.attributes[key], Validators.compose([])));
            }
          }
        }
      }
    }
    this.formValues.controls['role_id'].setValue(this.data.hasOwnProperty('roleId') ? this.data.roleId :  this.formValues.controls['role_id'].value);
    this.roleControl.setValue(this.service.app.data.roles.filter((role: any) => parseFloat(role.id) === parseFloat(this.formValues.controls['role_id'].value))[0]);
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  private _filterRole(label: string) {
    const args = label.toString().toLowerCase();
    return this.service.app.data.roles.filter((role: any) => JSON.stringify(role).toString().toLowerCase().includes(args));
  }

  onChangeRoleText(event: any){
    this.formValues.controls['role_id'].setValue('');
  }

  onRoleSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.formValues.controls['role_id'].setValue(event.option.value.id);
  }



  onGoogleMapAutocompleteSelected(result: PlaceResult) {
    this.formValues.controls['location'].setValue(result.formatted_address);
  }

  onLocationSelected(location: any) {
    console.log('onLocationSelected: ', location);
  }

//onLocationSelected(location: Location) {
//     console.log('onLocationSelected: ', location);
//}

  handlEditorFocus(){}

  handleFileInput(event: any){
    const files: FileList = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.formValues.controls['avatar'].setValue(this.service.dataURItoBlob(reader.result));
      this.photo = reader.result;
    };
  }


  closeDialog(){
    this.dialogRef.close(null);
  }

  onTextChangeAddress(event: any){
    this.formValues.controls['location'].setValue(event.target.value);
  }


  displayFn(value: any){
    return value.display_name;
  }

  onSubmit(userForm: any) {
    this.submittedFormValues.setAttributes(userForm);
    if(this.keys === 0){} else{
      if(this.submittedFormValues.attributes.hasOwnProperty('password'))
        delete this.submittedFormValues.attributes.password;
    } this.service.httpService(
        'post', this.keys === 0 ? '/users' : '/users/' + this.incomingSelectedUser.attributes.id + '/update',
        userForm, {},
        (response: any) => {
          if(this.keys === 0) {
            this.service.app.data.users.data.unshift(response);
          } else this.incomingSelectedUser.attributes = Object.assign(this.incomingSelectedUser.attributes, response);
          this.closeDialog();
          this.broadcastChannel.emitNavChangeEvent({user: response});
          this.notifier.notify('success', 'successfull ' + (this.keys === 0 ? 'added' : 'updated' ));
        }, (error: any) => {
        });

  }

}
