<div class="pull-right" [hidden]="searchInput">
    <mat-form-field
            class="example-full-width"
            appearance='standard'
            style="width: 200px !important;">
        <input type="text"
               #searchElement
               aria-label = "Number"
               matInput
               (mouseleave)="searchIcon = false; searchInput = true"
               [(ngModel)]="search"
               >
    </mat-form-field>
</div>
<a style="margin-right: 10px" class="material-icons pull-right cursor" [hidden]="searchIcon" (click)="onSearchIconClicked();searchIcon = false; searchInput= false">search</a>
<div style="position: fixed; bottom: 10px; width: 100%; z-index: 99999999"><a mat-mini-fab class="defaultColor pull-right" (click)="add()"><i class="material-icons"> add </i></a></div>
<center>
    <img class="img-circle" src="assets/images/coat.png" style="width:40px;height:40px;margin-top: 10px" >
</center>
<div class="row plist" style="margin-top: 5%">
    <div class="col-md-2"></div>
    <div class="col-md-8">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-align-tabs="center">
<!--            <mat-tab label="My Projects">-->
<!--                <div><br><br>-->
<!--                    <section class="post-section blog-post" style="background-color: transparent !important;">-->
<!--                        <div class="container">-->
<!--                            <div class="row">-->
<!--                                <div class="col-md-4 col-sm-6"  *ngFor="let campaign of (service.app.data.campaigns.data | jsonFilter: search)" style="cursor: pointer">-->
<!--                                    <div class="post-box">-->
<!--                                        <div class="text-box">-->
<!--                                            <h4>-->
<!--                                                <a>{{campaign.name}}</a>-->
<!--                                            </h4>-->
<!--                                            <p>{{service.limitTo(service.removeHtmlTags(campaign.description), 50)}}</p>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </section>-->
<!--                </div>-->
<!--            </mat-tab>-->
            <mat-tab label="Projects">
                <div><br><br>
                    <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12 normal">
                        <div class="tile-statss">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <section class="post-section blog-post" style="background-color: transparent !important;">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-6 cursor"
                                                 (click)="onSelectedForm(form)"
                                                 *ngFor="let form of (service.app.data.forms.data | jsonFilter: search)">
                                                <div class="post-box">
                                                    <div class="text-box">
                                                        <h4>
                                                            <a>{{form.name}}</a>
                                                        </h4>
                                                        <p>{{service.limitTo(service.removeHtmlTags(form.description), 200)}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                         </div>
                   </div>
                </div>
            </mat-tab>
            <mat-tab label="Registration" *ngIf="service.app.data.user.role.display_name.toString().toLowerCase().includes('admin')">
                <div><br><br>
                    <section class="post-section blog-post" style="background-color: transparent !important;">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/clients'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Clients</a>
                                            </h4>
                                            <p>{{service.app.data.clients.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/campanies'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Campanies</a>
                                            </h4>
                                            <p>{{service.app.data.campanies.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/campaigns'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Campaigns</a>
                                            </h4>
                                            <p>{{service.app.data.campaigns.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/employees'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Staffs</a>
                                            </h4>
                                            <p>{{service.app.data.employees.data.length}}</p>
                                            <p>
                                                Accessibility of system is based on user privilege which allows organizations to secure their
                                                infrastructure and applications, run business efficiently and maintain the
                                                confidentiality of sensitive data and critical infrastructure
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/teams'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Teams</a>
                                            </h4>
                                            <p>{{service.app.data.teams.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/brunches'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Brunches</a>
                                            </h4>
                                            <p>{{service.app.data.brunches.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/ambassadors'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Respondents</a>
                                            </h4>
                                            <p>{{service.app.data.ambassadors.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6"
                                     [routerLink]="'/app/regions'"
                                     style="cursor: pointer">
                                    <div class="post-box">
                                        <div class="text-box">
                                            <h4>
                                                <a>Regions</a>
                                            </h4>
                                            <p>{{service.app.data.regions.data.length}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="col-md-2"></div>
</div>
