<a class="pull-right" [matDialogClose]="" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
<div style="overflow: scroll; height: 80%" id="scrollElement">
    <div class="talk-bubble tri-right round border right-top  pull-right">
        <div class="talktext">
            <div>
                <div class="container">
                    <p>Hello. How are you today?</p>
                    <span class="time-right">11:00</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let reply of response.replies" class="col-md-12 col-sm-12">
            <div class="talk-bubble tri-right border round btm-left-in">
                <div class="talktext">
                    <div>
                        <div class="container">
                            <p>{{reply.reply}}</p>
                            <span class="time-right">{{reply.date}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <br>
    </div>
</div>

<div class="child">
    <div class="bar">
        <input class="searchbar"
               #replyElement
               (keyup.enter)="reply(replyElement.value)"
               [ngStyle]="{'text-align': 'left'}"
               [placeholder]="'reply...'"
               type="text"
               title="Search"
        >
        <a> <i (click)="reply(replyElement.value)" class="material-icons voice">keyboard_arrow_right</i></a>
    </div>
</div>


<!--<div class="talk-bubble">-->
<!--    <div class="talktext">-->
<!--        <p>CSS Talk Bubble configured by classes. Defaults to square shape, no triangle. Height is auto-adjusting to the height of the text.</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right left-top">-->
<!--    <div class="talktext">-->
<!--        <p>This one adds a right triangle on the left, flush at the top by using .tri-right and .left-top to specify the location.</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right left-in">-->
<!--    <div class="talktext">-->
<!--        <p>This talk-bubble uses .left-in class to show a triangle on the left slightly indented. Still a blocky square.</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right round btm-left">-->
<!--    <div class="talktext">-->
<!--        <p>And now using .round we can smooth the sides down. Also uses .btm-left to show a triangle at the bottom flush to the left.</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right border round btm-left-in">-->
<!--    <div class="talktext">-->
<!--        <p>Now we add a border and it looks like a comic. Uses .border .round and .btm-left-in</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right border btm-right-in">-->
<!--    <div class="talktext">-->
<!--        <p>Now flipped the other way and square. Uses .border and .btm-right-in</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right btm-right">-->
<!--    <div class="talktext">-->
<!--        <p>Flush to the bottom right. Uses .btm-right only.</p>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="talk-bubble tri-right round right-in">-->
<!--    <div class="talktext">-->
<!--        <p>Moving our way back up the right side indented. Uses .round and .right-in</p>-->
<!--    </div>-->
<!--</div>-->