import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {AppService} from "../../../../app.service";
import {FormService} from "../../../../forms/form.service";
import {TeamService} from "../../../team.service";
import {AmbassadorsService} from "../../../../ambassadors/ambassadors.service";
import {CampaignService} from "../../../../campaigns/campaign.service";
import {BroadcastChannelService} from "../../../../broadcast-channel.service";
import {UserService} from "../../../../users/user.service";
import {Parameters} from "../../../../parameters";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-manage-team-brunch-members',
  templateUrl: './manage-team-brunch-members.component.html',
  styleUrls: ['./manage-team-brunch-members.component.scss']
})
export class ManageTeamBrunchMembersComponent implements OnInit {

  @ViewChild('userElement')  userElement: any;
  @Input() team;
  @Input() branch;
  searchCntrl: any;
  userControl = new FormControl();
  filteredUsersOptions: any;
  incomingTeam: any;
  outgoingRequest: any;
  incomingForm: any;
  incomingMember: any;
  position: any;
  incomingInvitee: any;
  constructor(public  service: AppService,
              private formService: FormService,
              private teamService: TeamService,
              private ambassadorService: AmbassadorsService,
              private campaignService: CampaignService,
              private broadcastChannelService: BroadcastChannelService,
              public  userService: UserService) {
    this.incomingTeam = new Parameters();
    this.outgoingRequest = new Parameters();
    this.incomingForm = new Parameters();
    this.incomingMember = new Parameters();
    this.incomingInvitee = new Parameters();
    this.outgoingRequest.setAttributes({});
    this.incomingForm.setAttributes({});
    this.incomingMember.setAttributes({});
    this.userElement = ElementRef;
  }

  ngOnInit(): void {
    this.incomingTeam.setAttributes(this.team);
    this.teamService.teamSelectedId = this.incomingTeam.attributes.id;
    this.userControl.setValue({});
    setTimeout(() => {
      this.filteredUsersOptions = this.userControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterUser(name) : this.service.app.data.users.data.slice())
      );
    });
  }

  openUserDialog(member: any){
    this.userService.setSelected(member);
    this.userService.openDialog(member);
  }

  onUserNameTextChanged(event: any){
    this.userService.search({searchText: event.target.value, role_id: 8});
  }
  private _filterUser(name: string) {
    const filterValue = name.toLowerCase();
    return this.service.app.data.users.data.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  getUserByMember(member: any){ member = Object.assign(member, this.userService.getUserByUser(member)); return member;}
  displayFn(value: any){
    return value ? value.name : null;
  }

  addUser(){
    this.teamService.teamSelectedId = this.incomingTeam.attributes.id;
    this.ambassadorService.openDialog({teamId: this.incomingTeam.attributes.id});
  }

  removeUser(user: any){}

  onUserChecked(event: any, member: any, checkboxElement: any){
    setTimeout(() => {
      let form: any = this.formService.getSelected();
      if(Object.keys(form).length === 0){}else{
        this.incomingForm.setAttributes(form);
        this.position = this.incomingForm.getInvitees().data.findIndex((invitee: any) => { return parseFloat(invitee.member_id) === parseFloat(member.team_member_id);});
        this.incomingMember.setAttributes(member);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setUserId(this.incomingMember.getId());
        this.outgoingRequest.setFormId(this.incomingForm.getId());
        this.outgoingRequest.setMemberId(this.incomingMember.getTeamMemberId());
        this.outgoingRequest.setTeamId(this.incomingTeam.getId());
        if(this.position === -1){
          this.service.httpService(
              'post', '/form/user/assignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                this.incomingForm.getInvitees().data.unshift(response);}, (error: any) => {});
        }else{
          if(event.checked){
            this.service.httpService(
                'post', '/form/user/assignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                  this.incomingForm.getInvitees().data.unshift(response);}, (error: any) => {}); }else{
            this.incomingInvitee.setAttributes(this.incomingForm.getInvitees().data[this.position]);
            this.outgoingRequest.setFormUserId(this.incomingInvitee.getFormUserId());
            this.service.httpService(
                'post', '/form/user/unassignrequest', this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'}, (response: any) => {
                  this.incomingForm.getInvitees().data.splice(this.position, 1);}, (error: any) => {
                });
          }
        }
      }
    });
  }


  onloadChecked(member, checkedElement){
    let form: any = this.formService.selected;
    if(Object.keys(form).length === 0){}else{
      this.incomingForm.setAttributes(form);
      this.position = this.incomingForm.getInvitees().data.findIndex((invitee: any) => { return parseFloat(invitee.member_id) === parseFloat(member.team_member_id);});
      return this.position === -1 ? false : true;
    }return true;
//  this.broadcastChannelService.emitNavChangeEvent({checkedUserElement: checkedElement, checkedUser: member});
  }

  onUserSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setBranchId(this.branch.id);
    this.outgoingRequest.attributes = Object.assign(this.outgoingRequest.attributes, event.option.value);
    this.outgoingRequest.setUserId(this.outgoingRequest.getId());
    delete this.outgoingRequest.attributes.id;
    this.service.httpService('post', '/branch/member/addrequest',
        this.outgoingRequest.getAttributes(), {ignoreLoadingBar : 'true', notify: 'false'},
        (response: any) => {
          setTimeout((result: any) =>{
            this.userElement.nativeElement.value = '';
            this.searchCntrl = '';
            if(this.branch.hasOwnProperty('members')){
              const position = this.branch.members.data.findIndex((member: any) => { return parseFloat(member.id) === parseFloat(event.option.value.id);});
              if(position === -1) this.branch.members.data.unshift(event.option.value); else{
                this.branch.members.data[position] = Object.assign(this.branch.getMembers().data[position], event.option.value);
              }
            }
          });
        }, (error: any) => {
        });
  }


}
