import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {AppService} from '../../../../app.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../../form.service';
import {DomSanitizer} from '@angular/platform-browser';
import {BroadcastChannelService} from '../../../../broadcast-channel.service';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatMenuTrigger} from '@angular/material/menu';
import {map, startWith} from 'rxjs/operators';
import {Parameters} from '../../../../parameters';
import {SettingsQuestionsComponent} from '../../../questions/settings/settings-questions.component';

@Component({
  selector: 'app-draft-table-controls',
  templateUrl: './draft-table-controls.component.html',
  styleUrls: ['./draft-table-controls.component.scss']
})
export class DraftTableControlsComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('fruitInput') fruitInput: any;
  @ViewChild('tdElement', {static: false})  element: any;
  @ViewChild('auto') matAutocomplete: any;
  @Input() question: any;
  @Input() position: any;
  @Input() deletedOptions: any;
  @ViewChild(MatMenuTrigger)
  contextMenu: any;
  contextMenuPosition = {x: '0px', y: '0px'};
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  questionCtrl = new FormControl();
  filteredOptions: Observable<any>;
  option: any;
  options: any = [];
  allOptions: any;
  label: any;
  controls: any;
  selectedOptional: any;
  incomingQuestion: any;
  incomingRow: any;
  incomingOption: any;
  incomingSelectedOption: any;
  incomingControl: any;
  incomingSettings: any;
  incomingItem: any;
  incomingRows: any;
  constructor(public service: AppService,
              public dialog: MatDialog,
              public formServce: FormService,
              private sanitizer: DomSanitizer,
              public broadcastChannel: BroadcastChannelService) {
    this.matAutocomplete = MatAutocomplete;
    this.fruitInput = ElementRef;
    this.allOptions = [];
    this.contextMenu = MatMenuTrigger;
    this.incomingQuestion = new Parameters();
    this.incomingSelectedOption = new Parameters();
    this.incomingControl = new Parameters();
    this.incomingRow = new Parameters();
    this.incomingOption = new Parameters();
    this.incomingSettings = new Parameters();
    this.incomingRows = new Parameters();
    this.incomingItem = new Parameters();
    this.element = ElementRef;
    this.controls = this.service.app.data.controls;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingSelectedOption.setAttributes({});
    this.filteredOptions = this.questionCtrl.valueChanges.pipe(
        startWith(null),
        map((optional: string | null) => optional ? this._filter(optional) : this.allOptions.slice()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngStart();
  }

  ngOnInit(): void {
  }

  ngStart(){
    setTimeout(() => {
      this.incomingRows.setAttributes({});
      this.options = this.question.options;
      this.formServce.question = this.question;
      this.incomingQuestion.setAttributes(this.question);
      this.incomingQuestion.setRows(!this.service.isNull(this.incomingQuestion.getDefault()) ? (this.incomingQuestion.getDefault() instanceof Object ? this.incomingQuestion.getDefault() : JSON.parse(this.incomingQuestion.getDefault())) : []);
      if(this.incomingQuestion.getRows().constructor.name === 'Array'){
        this.incomingQuestion.getRows().map((row: any) => {
          this.incomingRow.setAttributes(row);

        });
      }
      this.auto();
    });
  }

  ngAfterViewInit(): void {
  }

  auto(){
    if(this.incomingQuestion.getOptions().length > 0){
      if(this.question.rows.length === 0){
        for (let l = 0; l < 5; l++) {
          this.incomingRow.setAttributes({});
          this.incomingRow.setCntrlNum(this.service.random());
          this.incomingQuestion.getOptions().map((optional: any) => {
            this.incomingOption.setAttributes(optional);
            this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: ''});
          });
          this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
        }this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
      }this.fill();
    }
  }

  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  fill(){
    this.incomingQuestion.setAttributes(this.question);
    if(this.incomingQuestion.getOptions().length > 0){
      if(this.incomingQuestion.attributes.hasOwnProperty('rows')){
        this.incomingQuestion.attributes.rows.map((row: any) => {
          this.incomingRow.setAttributes(row);
          if(this.incomingRow.getAttributes() instanceof Object){
            this.incomingRow.setCntrlNum(this.incomingRow.attributes.hasOwnProperty('cntrl_num') ?
                this.incomingRow.getCntrlNum() : this.service.random());
            this.incomingQuestion.getOptions().map((option: any) => {
              this.incomingOption.setAttributes(option);
              if(!this.incomingRow.attributes.hasOwnProperty(this.incomingOption.getCntrlNum()))
                this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: ''});
            });
          }

        });this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
      }
    }
  }

  settings(option: any){this.dialog.open(SettingsQuestionsComponent, {data  : {question: option}, width : '40%', height: '97%', });}

  onKeyUp(event: any){
//      if(this.service.empty(event.target.value)){
//      }else event.target.value = this.service.numberWithCommans(parseFloat(event.target.value.toString().replace(/,/g, '')));
//
  }

  onSelectControl(control: any){
    this.incomingControl.setAttributes(control);
    this.incomingSelectedOption.setTypeId(this.incomingControl.getId());
    this.incomingSettings.setAttributes(this.incomingSelectedOption.getSettings());
    this.incomingSettings.setControlId(this.incomingControl.getId());
    this.incomingSelectedOption.setControlId(this.incomingControl.getId());
  }

  onInputTextChange(option: any, tdElement: any, row: any){
    this.incomingRow.setAttributes(row);
    this.incomingQuestion.setAttributes(this.question);
    this.incomingOption.setAttributes(option);
    if(this.incomingRow.attributes.hasOwnProperty(this.incomingOption.getCntrlNum())){
      this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: this.service.removeHtmlTags(tdElement.innerText), default: 1});
      if(this.service.isNull(this.service.removeHtmlTags(tdElement.innerText)))
        delete this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).default;
      this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
    }
  }

  getItem(option: any, item: any){
    this.incomingOption.setAttributes(option);
    this.incomingItem.setAttributes(item);
    this.incomingOption.setItem(this.incomingItem.getAttributes());
    return this.incomingOption.getAttributes();
  }

  removeTableRow(row: any){
    setTimeout(() => {
      this.incomingRow = new Parameters();
      this.incomingQuestion.setAttributes(this.question);
      this.incomingRow.setAttributes(row);
      let indexOf: any = this.incomingQuestion.getRows().indexOf(this.incomingRow.getAttributes());
      if(indexOf === -1){}else this.incomingQuestion.getRows().splice(indexOf, 1);
      this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
    });
  }

  hasDefault(row: any){
    let l: any = false;
    this.incomingRow.setAttributes(row);
    for(let key in this.incomingRow.attributes) {
      l = this.incomingRow.getUnknown(key).hasOwnProperty('default');
    }return l;
  }

  isChecked(checkbox: any, row: any, option: any){
    this.incomingRow.setAttributes(row);
    this.incomingOption.setAttributes(option);
    checkbox.checked = !this.service.isNull(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) &&
        parseFloat(this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).data) === 1;
    return checkbox.checked;
  }

  onClickedcheckbox(checkbox: any, row: any, option: any){
    this.incomingRow.setAttributes(row);
    this.incomingOption.setAttributes(option);
    this.incomingRow.setUnknown(this.incomingOption.getCntrlNum(), {data: checkbox.checked ? 1 : 0});
    this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
  }

  removeOpt(option: any, opt: any){
    this.incomingOption.setAttributes(option);
    this.incomingSettings.setAttributes(this.incomingOption.getSettings());
    this.incomingItem.setAttributes(opt);
    this.position = this.incomingSettings.getOptions().indexOf(this.incomingItem.getAttributes());
    if(this.position !== parseFloat('-1'))this.incomingSettings.getOptions().splice(this.position, 1);
  }

  addOption(option: any){
    this.incomingOption.setAttributes(option);
    this.incomingSettings.setAttributes(this.incomingOption.getSettings());
    this.label = prompt('', '');
    if (this.label !== null) {
      this.incomingItem.setAttributes({});
      this.incomingItem.setLabel(this.label);
      this.incomingItem.setCntrlNum(this.service.random());
      this.incomingSettings.setOptions(this.incomingSettings.attributes.hasOwnProperty('options') ? this.incomingSettings.getOptions() : []);
      this.formServce.addOptional(this.incomingSettings.getOptions(), this.label);
//       this.incomingSettings.getOptions().push(this.incomingItem.getAttributes());
    }
  }


  updateOpt(option: any, opt: any){
    this.incomingOption.setAttributes(option);
    this.incomingSettings.setAttributes(this.incomingOption.getSettings());
    this.incomingItem.setAttributes(opt);
    this.label = prompt('', '');
    this.position = this.incomingSettings.getOptions().indexOf(this.incomingItem.getAttributes());
    if(this.label !== null) {
      this.incomingItem.setLabel(this.label);
      this.incomingSettings.getOptions()[this.position] = Object.assign(this.incomingSettings.getOptions()[this.position], this.incomingItem.getAttributes());
    }
  }

  readOnly(option: any, row: any){
    this.incomingOption.setAttributes(option);
    this.incomingRow.setAttributes(row);
    let result: any = parseFloat(this.incomingOption.getTypeId()) === 1;
    //result = this.incomingRow.getUnknown(this.incomingOption.getCntrlNum()).hasOwnProperty('default') ? false : result;
    return result;
  }


  setOption(option: any){
    this.incomingQuestion.setAttributes(this.question);
    this.label = prompt('', option.label);
    if (this.label !== null) option = Object.assign(option, {label: this.label});
    this.auto();
  }

  addRow(){
    setTimeout(() => {
      this.incomingQuestion.setAttributes(this.question);
      this.incomingRow.setAttributes({});
      this.incomingRow.setCntrlNum(this.service.random());
      this.incomingQuestion.getOptions().map((optional: any) => {
        this.incomingOption.setAttributes(optional);
        this.incomingRow.attributes[this.incomingOption.getCntrlNum()] = {data: ''};
      });this.incomingQuestion.setRows(this.incomingQuestion.getAttributes().hasOwnProperty('rows') ? this.incomingQuestion.getRows() : []);
      this.incomingQuestion.getRows().push(this.incomingRow.getAttributes());
      this.service.playSound();
    });
  }



  addOptionalEvent(){
    var text: any = prompt('', '');
    if (!this.service.isNull(text)) {
      this.formServce.addOptional(this.question.options, text);
      this.auto();
    }
  }

  add(event: any): void {
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    if (value) {
      this.option  = new Parameters();
      this.option.setAttributes({});
      this.option.setLabel(value);
      this.option.setUnknown('cntrl_num', this.service.random());
      this.option.setQuestions([]);
      this.options.push(this.option.attributes);
    }

    // Clear the input value
    // event.chipInput!.clear();
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  removeOption(option: any): void {
    this.incomingOption.setAttributes(option);
    this.incomingQuestion.setAttributes(this.question);
    const index = this.options.indexOf(option);
    if (index === -1){} else {
      this.options.splice(index, 1);
      if(option.hasOwnProperty('id')) {
        this.deletedOptions.push(option);
      }
    }

    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.getRows().map((row: any) => {
      this.incomingRow.setAttributes(row);
      if(this.incomingRow.attributes.hasOwnProperty(this.incomingOption.getCntrlNum()))
        delete this.incomingRow.attributes[this.incomingOption.getCntrlNum()];
    });this.incomingQuestion.setDefault(this.incomingQuestion.getRows());
  }

  addOptional(): void {
    this.option  = new Parameters();
    this.option.setAttributes({});
    this.option.setLabel('Untitled');
    this.option.setUnknown('cntrl_num', this.service.random());
    this.option.setQuestions([]);
    this.options.unshift(this.option.attributes);
    //this.handleOption(this.options[0]);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.options.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.questionCtrl.setValue(null);
  }

  private _filter(value: any): any {
    const filterValue = value.toLowerCase();
    return this.allOptions.filter(fruit => fruit.label.toString().toLowerCase().includes(filterValue));
  }



}
