<mat-drawer-container class="example-container"
                      [ngStyle]="{'height':this.service.getHeight()+'px'}"
                      [hasBackdrop]="true">
    <mat-drawer #drawer
                [position]="'end'"
                [ngStyle.lg]="{'width':this.service.getWidth()/2+'px'}"
                [ngStyle.md]="{'width':this.service.getWidth()/2+'px'}"
                [ngStyle.sm]="{'width':this.service.getWidth()+'px'}"
                [ngStyle.xs]="{'width':this.service.getWidth()+'px'}"
                [mode]="'over'">
        <mat-icon (click)="drawer.opened = false" class="pull-right cursor" [ngStyle]="{'padding':'20px 50px 20 20px'}">close</mat-icon>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <section class="post-section blog-post"
                     [ngStyle]="{'background-color': 'transparent'}">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 cursor"
                             [routerLink]="module.path"
                             *ngFor="let module of this.modules">
                            <div class="post-box"
                                 [ngStyle]="{'height':'auto'}">
                                <div class="text-box">
                                    <h4>
                                        <a>
                                            <ul class="list-inline">
                                                <li style="vertical-align: top">{{module[strings.getName()]}}</li>
                                            </ul>
                                        </a>
                                    </h4>
                                    <p class="text-left"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div ngClass="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                 [ngStyle.sm]="{'display': 'none'}"
                 [ngStyle.xs]="{'display': 'none'}">
                <center>
                    <img class="img-circle mdl-shadow--2dp cursor"
                         (click)="drawer.toggle()"
                         [src]="service.logo"
                         [ngStyle]="{'width':'40px','height':'40px','margin-top': '10px'}">
                </center>
            </div>
            <div style="position: fixed; right:100px;bottom: 100px; width: 100%; z-index: 99999999">
                <div class="pull-right">
                    <a mat-mini-fab class="defaultColor2" (click)="add()"><i class="material-icons"> add </i></a>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <center></center>
                    </div>
                    <center>
                        <app-forms-list-partial></app-forms-list-partial>
                    </center>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
