<div layout="row">
    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
        <div class="panel-body">
            <mat-list>
                <mat-list-item>
                  <span class="" mat-line>
                    <mat-form-field  class="example-full-width">
                      <input matInput type="text" [(ngModel)]="question.label" placeholder="{{question.label.length === 0 ? 'Untitled Question' : ''}}">
                      <span *ngFor="let control of controls; let controlOf = index" class="pull-right">
                        <i class="material-icons pull-left" [ngStyle]="{'color':'#888','cursor':'pointer'}" *ngIf="control.id === question.control.id">{{control.icon}}</i>
                      </span>
                    </mat-form-field>
                  </span>
                </mat-list-item>
            </mat-list>
            <div [ngStyle]="{'margin-bottom': '50px'}">
                <center>
                    <i class="material-icons star" >grade</i>
                    <i class="material-icons star">grade</i>
                    <i class="material-icons star">grade</i>
                    <i class="material-icons star">grade</i>
                    <i class="material-icons">grade</i>
                </center>
            </div>
            <div>
                <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
            </div>
        </div>
    </div>
</div>
