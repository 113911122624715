import {AfterViewInit, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Parameters} from '../../parameters';
import {FormService} from '../../forms/form.service';
import * as moment from "moment/moment";

@Component({
    selector: 'app-annoucement-events',
    templateUrl: './annoucement-events.component.html',
    styleUrls: ['./annoucement-events.component.scss']
})
export class AnnoucementEventsComponent implements OnInit, AfterViewInit, OnDestroy {
    sponsors: any = [];
    formId: any;
    funcUrl = '../assets/json/sys.json';
    strings: any;
    sys: any;
    outgoingRequest: any;
    incomingForm: any;
    headerChangeColorStatus: any = 0;
    height: any = 0;
    mdl_navigation__link: any = 0;
    constructor(public service: AppService,
                private router: Router,
                public zone: NgZone,
                private formService: FormService,
                private activatedRoute: ActivatedRoute) {
        this.strings = new Parameters();
        this.sys = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingForm = new Parameters();
        this.sys.setAttributes({});
        this.incomingForm.setAttributes({});
        this.strings.setAttributes(this.service.app.strings.params);
    }

    ngOnDestroy(): void {
        document.getElementsByTagName( 'body')[0].style.overflow = 'scroll';
    }

    ngAfterViewInit(): void {
        setTimeout(() => {this.height = this.service.getHeight(); }, this.service.timeout());
        // document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
    }

    getHeight(){return window.outerHeight; }

    getDays(){
        let d1: any = moment(new Date()).format('MM/DD/YYYY');
        let d2: any = moment(new Date('4/22/2024')).format('MM/DD/YYYY');
        d1 = new Date(d1);
        d2 = new Date(d2);
        let Difference_In_Time =
            d2.getTime() - d1.getTime();

        // Calculating the no. of days between
        // two dates
        let Difference_In_Days =
            Math.round
            (Difference_In_Time / (1000 * 3600 * 24));
        return Difference_In_Days;
    }

    getSeconds(){
        return parseFloat(moment(new Date()).format('ss'));
    }

    getMinutess(){
        return parseFloat(moment(new Date()).format('mm'));
    }


    remainingTime() {
        let h: any = parseFloat(moment(new Date()).format('h'));
        let m: any = parseFloat(moment(new Date()).format('mm'));
        var totalMin, hoursRemaining, minRemaining;

        // Formula for total remaining minutes
        // = 1440 - 60h - m

        totalMin = 1440 - 60 * h - m;

        // Remaining hours

        hoursRemaining = totalMin / 60;

        // Remaining minutes

        minRemaining = totalMin % 60;

        return {hours: Math.trunc(hoursRemaining), minutes: minRemaining};
    }


    getMinutes(){
        let today: any = new Date();
        // let newYear: any = new Date(moment(new Date('4/22/2024')).format('MM/DD/YYYY'));
        let newYear: any = new Date();
// Calculate difference between two dates in minutes
        let dif = (newYear - today);
        dif = Math.round((dif / 1000) / 60);

        return dif;
    }


    ngOnInit(): void {
        window.addEventListener('scroll', (e: any) => {
            if (window.pageYOffset  > 200){
                this.zone.run(() => {this.headerChangeColorStatus = 1; });
            }else { this.zone.run(() => {this.headerChangeColorStatus = 0; }); }
        });
        document.getElementsByTagName( 'body')[0].style.backgroundColor = 'rgb(16,30,81)';
        this.formId = this.activatedRoute.snapshot.params.formId;
        this.formId = this.service.decrypt(this.formId);
        // this.requestForm();
        this.service.httpService( 'get', this.funcUrl, {}, {ignoreLoadingBar : 'true', notify: 'false'},
            (response: any) => {
                this.sys = response;
            },  (error: any) => {});
    }


    only(data, label){return data.map((l: any) => l[label]); }
    scrollIntoView(htmlId: any){
        const elementId: any = document.getElementById(htmlId);
        elementId.scrollIntoView({behavior: 'smooth'});
    }


    requestForm(){
        if (!this.formService.getSelected().hasOwnProperty(this.strings.getId())){
            this.router.navigateByUrl('/wait..');
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.formId);
            this.outgoingRequest.setId(this.formId);
            this.service.httpService('POST',
                this.service.app.routes.forms.get.toString(),
                this.outgoingRequest.getAttributes(), { ignoreLoadingBar : 'true', notify: false}, (result: any) => {
                    this.formService.setSelected(result);
                    this.incomingForm.setAttributes(result);
                    window.history.back();
                 // this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
                }, (error: any) =>  {
                    this.requestForm();
                });
        }else { this.incomingForm.setAttributes(this.formService.getSelected()); }
    }


    registerAttendee(){
        if (this.incomingForm.hasAttribute(this.strings.getId())){
            this.service.flag = 0;
            this.router.navigateByUrl('/app/events/' +
                this.service.encrypt(this.formId) +
                '/registrations/attendees?' + window.location.href.toString().split('?')[1]);
        }
    }

    registerExhibitor(){
        this.service.flag = 1;
        this.router.navigateByUrl('/app/events/' +
            this.service.encrypt(this.formId) +
            '/registrations/attendees?' + window.location.href.toString().split('?')[1]);
    }

    register(){
        this.router.navigateByUrl('/app/forms/' +
            this.service.encrypt(this.formId) +
            '/chatbot?' + window.location.href.toString().split('?')[1]);
    }

}
