import {Component, OnInit, Input, OnChanges,SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import {ItemService} from "../../../../items/item.service";
import {orderBy } from 'lodash';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDrawer } from '@angular/material/sidenav';
import {Parameters} from '../../../../parameters';
import {OutletService} from '../../../../outlets/outlet.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../../../forms/form.service';
import {BottomsheetOrderViewControlComponent} from '../../../../supplies/map/bottomsheet-order-view-control/bottomsheet-order-view-control.component';
import {AppService} from '../../../../app.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/internal/operators';
import {Geolocation, GeolocationPosition } from '@capacitor/geolocation';
import {DialogMapSuppliesComponent} from '../../../../supplies/map/dialog-map-supplies/dialog-map-supplies.component';
@Component({
  selector: 'app-view-order-control',
  templateUrl: './view-order-control.component.html',
  styleUrls: ['./view-order-control.component.scss']
})
export class ViewOrderControlComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('drawer', {static: false})  drawer: any;
  @Input() question: any;
  @Input() position: any;
  outgoingRequest: any;
  outgoingRequest2: any;
  itemControl = new FormControl();
  filteredItemsOptions: any;
  incomingQuestion: any;
  incomingOption: any;
  incomingItem: any;
  itemPosition: any;
  incomingSettings: any;
  outlets: any = [];
  lat: any = 0;
  lng: any = 0;
  currentLocationLat: any = 0;
  currentLocationLng: any = 0;
  zoom: any = 10;
  dialogDrawerRef: any;
  icon: any;
  incomingItemSelected: any;
  incomingCurrentAddress: any;
  incomingResult: any;
  incomingAddress1: any;
  itemCurrentPosition: any = 0;
  incomingOrigin: any;
  incomingDestination: any;
  incomingAddress2: any;
  incomingAddress3: any;
  incomingAddress4: any;
  incomingAddress5: any;
  incomingOptionSelected: any;
  positionOptions: any = {
      //timeout: 80000,
      //maximumAge: 60000,
        enableHighAccuracy: true,
  };
  constructor(  public itemService: ItemService,
                private dialog: MatDialog,
                private bottomSheet: MatBottomSheet,
                public formService: FormService,
                public outletService: OutletService,
                public service: AppService) {
        this.outgoingRequest = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingOption = new Parameters();
        this.incomingItem = new Parameters();
        this.incomingSettings = new Parameters();
        this.incomingCurrentAddress = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingAddress1 = new Parameters();
        this.incomingAddress2 = new Parameters();
        this.incomingAddress3 = new Parameters();
        this.incomingAddress4 = new Parameters();
        this.incomingAddress5 = new Parameters();
        this.incomingOrigin = new Parameters();
        this.incomingDestination = new Parameters();
        this.outgoingRequest2 = new Parameters();
        this.incomingItemSelected = new Parameters();
        this.incomingOptionSelected = new Parameters();
        this.drawer = MatDrawer;
        this.incomingOptionSelected.setAttributes({});
  }

  ngOnInit(): void {
  }

  openBottomSheet(): void {
    this.dialogDrawerRef = this.bottomSheet.open(BottomsheetOrderViewControlComponent, {
       panelClass: 'bottomSheetClass',
       hasBackdrop: false,
       disableClose: true,
       data:  {
        question: this.incomingQuestion.getAttributes(),
        drawer: this.drawer,
        item: {}
       } ,
    });
  }

  getWindow(){ return {height: window.innerHeight, width: window.innerWidth};}

  ngOnChanges(changes: SimpleChanges): void {
    this.icon = {url: 'https://i.imgur.com/7teZKiff.png', labelOrigin: { x: 0, y: 0 }, scaledSize: {height: 20, width: 20}};
    this.incomingCurrentAddress.setAttributes({});
    this.incomingOrigin.setAttributes({});
    this.incomingDestination.setAttributes({});
    this.outletService.isSearched = false;
    this.formService.isAdvert = true;
    this.incomingQuestion.setAttributes(this.question);
    this.incomingQuestion.setData(!this.service.isNull(this.incomingQuestion.getData()) ? this.incomingQuestion.getData() : []);
    this.outletService.incomingItemSelected.setAttributes(this.incomingQuestion.getOptions().length ===0 ?  {} : this.incomingQuestion.getOptions()[0]);
    this.filteredItemsOptions = this.itemControl.valueChanges.pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.label),
              map(label => label ? this._filterItem(label) : this.service.app.data.items.data.slice())
    );

    setTimeout(()=>{
     this.openBottomSheet();
     Geolocation.watchPosition(this.positionOptions, (pos: any, err: any) => {
        try{
            if(Object.keys(this.incomingOrigin.getAttributes()).length ===0){
              this.incomingOrigin.setLat(parseFloat(pos.coords.latitude.toString()));
              this.incomingOrigin.setLng(parseFloat(pos.coords.longitude.toString()));
            }this.currentLocationLat = parseFloat(pos.coords.latitude.toString());
            this.currentLocationLng = parseFloat(pos.coords.longitude.toString());
            this.incomingDestination.setLat(parseFloat(pos.coords.latitude.toString()));
            this.incomingDestination.setLng(parseFloat(pos.coords.longitude.toString()));
            // console.log(this.service.getDrivingDistanceBetweenTwoLatLong(this.incomingOrigin.getAttributes(), this.incomingDestination.getAttributes()));
            if(Object.keys(this.incomingCurrentAddress.getAttributes()).length ===0){
              if(!this.outletService.isSearched){
                this.outletService.isSearched = true;
                this.getCurrentAddress((result: any)=>{
                  this.outgoingRequest.setAttributes({});
                  this.outgoingRequest.setSearchText(this.incomingCurrentAddress.getAddress2());
                  this.outletService.search(this.incomingCurrentAddress.getAddress2(),(result:any)=>{
                    if(result.length ===0){
                     this.fetchDescAll();
                    }
                  });
                });
              }
            }
        }catch (e) { }
     });
    });
  }

  ngAfterViewInit(){
     setTimeout(() => {
         const nextSlides = document.getElementsByClassName('carousel-arrow-next');
         for (var i = 0; i < nextSlides.length; i++) {
           const slide = nextSlides[i];
           if (slide instanceof HTMLElement) {
               slide.addEventListener('click', () => {
                   this.itemCurrentPosition =
                    (this.itemCurrentPosition === this.incomingQuestion.getOptions().length -1) ?
                    this.itemCurrentPosition : this.itemCurrentPosition + 1;
                    this.outletService.incomingItemSelected.setAttributes(this.incomingQuestion.getOptions()[this.itemCurrentPosition]);
               });
          }
        }

         const prevSlides = document.getElementsByClassName('carousel-arrow-prev');
        for (var i = 0; i < prevSlides.length; i++) {
            const slide = prevSlides[i];
            if (slide instanceof HTMLElement) {
                slide.addEventListener('click', () => {
                    this.itemCurrentPosition =
                     this.itemCurrentPosition === 0 ? 0 :
                     this.itemCurrentPosition - 1;
                     this.outletService.incomingItemSelected.setAttributes(this.incomingQuestion.getOptions()[this.itemCurrentPosition]);
                });
            }
        }

     },1107);
  }

  fetchDescAll(){
   this.outgoingRequest2.setAttributes({});
   if(this.service.app.data.outlets.data.length !=0){
      const outlet: any = orderBy(this.service.app.data.outlets.data, 'id', 'desc')[0];
      this.outgoingRequest2.setDescendinglastAt(outlet.id);
      this.outletService.search(this.outgoingRequest2.getAttributes(),(result:any)=>{
          if(result.length !==0)
           this.fetchDescAll();
      });
   }else{
    this.outletService.search(this.outgoingRequest2.getAttributes(),(result:any)=>{
         if(result.length !==0) {
          this.fetchDescAll();
         }
    });
   }
  }

  getCurrentAddress(callback: any){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setLat(this.currentLocationLat);
    this.outgoingRequest.setLng(this.currentLocationLng);
    this.service.httpService('post', this.service.app.routes.home.address.get,
      this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: true}, (result: any) => {
      this.incomingResult.setAttributes(result);
      this.incomingAddress1.setAttributes(this.incomingResult.getAddress1());
      this.incomingAddress2.setAttributes(this.incomingResult.getAddress2());
      this.incomingAddress3.setAttributes(this.incomingResult.getAddress3());
      this.incomingAddress4.setAttributes(this.incomingResult.getAddress4());
      this.incomingCurrentAddress.setAddress1(this.incomingAddress1.getPlaceId());
      this.incomingCurrentAddress.setAddress2(this.incomingAddress2.getPlaceId());
      this.incomingCurrentAddress.setAddress3(this.incomingAddress3.getPlaceId());
      this.incomingCurrentAddress.setAddress4(this.incomingAddress4.getPlaceId());
      callback(null);
    }, (error: any) => {
    });
  }

  isDrawerOpened(drawer: any){
   setTimeout(()=>{
    this.drawer = drawer;
    if(drawer.opened){
      this.dialogDrawerRef.dismiss();
    }
   });
  }

  onInputTextChange(event: any){
     this.outgoingRequest.setAttributes({});
     this.outgoingRequest.setSearchText(event.target.value);
     this.itemService.search(this.outgoingRequest.getAttributes(), (result: any)=>{
     });
  }

  onCloseDrawer(drawer: any){
   setTimeout(()=>{
      this.drawer = drawer;
      if(drawer.opened){
      }
   });
  }

  openGeolocation(){
   this.dialog.open(DialogMapSuppliesComponent, {data  : {}, width : this.getWindow().width+'px', height: '100%' });
  }

  onOptionSelected(event: any){
     this.incomingItem.setAttributes(event.option.value);
     this.itemPosition = this.service.findIndex(this.incomingQuestion.getOptions(),'reference',this.incomingItem.getCntrlNum());
     if(this.itemPosition === -1){
        this.formService.addOptional( this.incomingQuestion.getOptions(), this.incomingItem.getName());
        this.incomingOption.setAttributes(this.formService.newOptional);
        this.incomingSettings.setAttributes(Object.assign({},this.incomingItem.getAttributes()));
        this.incomingOption.setReference(this.incomingItem.getCntrlNum());
        this.incomingOption.setSettings(this.incomingSettings.getAttributes());
     }
  }

  displayFn(value: any){
       return value.name;
  }

  private _filterItem(label: string) {
        const filterValue = label.toLowerCase();
       return this.service.app.data.items.data.filter((item: any) => JSON.stringify(item).toLowerCase().includes(filterValue));
  }


}
