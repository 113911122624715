<div layout="row" class="paint">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field class="example-chip-list" appearance="fill">
                        <mat-label>&nbsp;</mat-label>
                        <mat-chip-list #chipList aria-label="selection">
                            <mat-chip
                                    [ngStyle]="{'color': this.formService.color}"
                                    *ngFor="let option of options"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (click)="handleOption(option)"
                                    (removed)="remove(option)">
                                {{service.upperStartingCharacter(option.label, true)}}
                                <span *ngIf="option.hasOwnProperty('code') && !service.isNull(option.code)">
                                    ({{option.code}})
                                </span>
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input  placeholder="add {{question.label}}.."
                                    class="sawasdee26"
                                    place
                                    #optionElement
                                    (input)="onChangeOptionalText($event)"
                                    [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                                    [formControl]="questionControl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)">
                            <mat-spinner class="pull-left" *ngIf="spinner" [diameter]="20"></mat-spinner>
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete"
                                          (optionSelected)="selected($event)">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{service.upperStartingCharacter(option.label, true)}}
                                <span  style="margin-left: 10px;"
                                       *ngIf="option.hasOwnProperty('code') &&
                                       !service.isNull(option.code)">
                                    ({{option.code}})
                                </span>
                            </mat-option>
                        </mat-autocomplete>
                        <h4 class='headline' style="color: red !important; font-weight: normal"
                            *ngIf="service.isNull(optionElement.value) === false &&
                                 ((filteredOptions | async) | json).length === 2">
                            **---Not Found **---</h4>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
