import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  data: any;
  title: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              @Inject(MAT_DIALOG_DATA) public params: any, public dialogRef: MatDialogRef<DeleteDialogComponent>) {
    this.title = this.params.title;
    this.data  = this.params.data;
  }

  ngOnInit(): void {
  }

  handleAccept(){
    if(this.service.isNull(this.params.url)){
      this.params.data.splice(this.service.findIndex(this.params.data, this.params.key, this.params.id), 1);
      }else {
      this.service.httpService('post', this.params.url, {}, {}, (response: any) => {
        this.params.data.data.splice(this.service.findIndex(this.params.data.data, this.params.key, this.params.id), 1);
        this.params.data.trashed.data.unshift(this.params.model);
        this.notifier.notify('success', 'Successfull deleted');
      }, (error: any) => {
      });
    }
    this.dialogRef.close();
  }

}
