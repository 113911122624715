<div class="drawer-sd">
    <mat-drawer-container
            class="example-container"
            [hasBackdrop]="false">
        <mat-drawer
            #drawer
            [disableClose]="true"
            position="end"
            [ngStyle.sm]="{'width': this.service.getWidth()+'px'}"
            [ngStyle.xs]="{'width': this.service.getWidth()+'px'}"
            [mode]="'side'">
            <mat-icon (click)="drawer.opened = false" class="pull-right">close</mat-icon>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="incomingOrder.hasAttribute(strings.getId())">
                <mat-tab-group mat-align-tabs="end">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <center>
                                <span class="sawasdeeWithScale">Dispatch</span>
                            </center>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <center>
                                <span class="sawasdeeWithScale">Invoice</span>
                            </center>
                        </ng-template>
                        <div [innerHTML]="incomingOrder.getReference3() | safeUrl"></div>
                        <div>
                            {{incomingOrder.getReference3()}}
                            ////////////////////
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <center>
                                <span class="sawasdeeWithScale">Drivers</span>
                            </center>
                        </ng-template>
                        <div>
                            {{incomingOrder.getDeliveryUsers().length}}
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <center>
                                <span class="sawasdeeWithScale">Delivey to</span>
                            </center>
                        </ng-template>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <button mat-mini-fab class="defaultColor2 pull-right">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <app-list-orders></app-list-orders>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>


