import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppService} from '../app.service';
import {Parameters} from '../parameters';


@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.scss']
})
export class OutletsComponent implements OnInit {
  app: any;
  searchCntrl: any;
  outgoingRequest: any;
  constructor(public service: AppService) {
      this.outgoingRequest = new Parameters();
      this.app = this.service.app;
      this.app.form.module = 'outlets';
  }

  ngOnInit(): void {

  }

  openDialog() {
//       this.dialog.open(DialogDistrictalComponent, {data: {}, width: '55%', height: 'auto'});
  }


}
