import {Component, Input, OnInit} from '@angular/core';
import {Parameters} from '../../../../parameters';

@Component({
  selector: 'app-google-charts',
  templateUrl: './google-charts.component.html',
  styleUrls: ['./google-charts.component.scss']
})
export class GoogleChartsComponent implements OnInit {
  @Input() title: any;
  @Input() type: any;
  @Input() data: any;
  @Input() columnNames: any;
  @Input() options: any;
  incomingData: any;
  results: any = [];
  constructor() { }

  ngOnInit(): void {
    this.incomingData = new Parameters();
  }

  getData(){
    this.data.map((data: any, dataIncremental: any) => {
      this.incomingData.setAttributes();
      this.incomingData.setUnknown(this.columnNames[dataIncremental], data);
      this.results.push(this.incomingData.getAttributes());
    }); return this.results;
  }

}
