import { Component, OnInit } from '@angular/core';
import {ImplDistrictsService} from './impl-districts.service';

@Component({
  selector: 'app-tabs-districtal',
  templateUrl: './tabs-districtal.component.html',
  styleUrls: ['./tabs-districtal.component.scss']
})
export class TabsDistrictalComponent implements OnInit {
  districts: any;
  constructor(public districtalImpl: ImplDistrictsService) {
    this.districts = districtalImpl.getData();
  }

  ngOnInit(): void {
  }

}
