import { Injectable } from '@angular/core';
import {Parameters} from '../../../parameters';
import {AppService} from '../../../app.service';
import * as moment from 'moment';
import {DialogResponseComponent} from '../../../responses/dialog/dialog-response.component';
import {MatDialog} from "@angular/material/dialog";
import {FormService} from "../../form.service";

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

    constructor(private service: AppService,
                private formService: FormService,
                public  dialog: MatDialog) {

        this.incomingForm = new Parameters();
        /**
         * @param mixed report
         */
        this.strings = new Parameters();
        /**
         * @param mixed report
         */
        this.incomingUser = new Parameters();
        /**
         * @param mixed report
         */
        this.incomingResponse = new Parameters();
        /**
         * @param mixed report
         */

        this.reportValues = new Parameters();
        /**
         * @param mixed report
         */
        this.reportValues.setAttributes({});

        /**
         * @param mixed report
         */

        this.reportValues.setTime('datebetween');

        /**
         * @param mixed report
         */

        this.reportValues.setTarget('all');

        /**
         * @param mixed report
         */

        this.reportValues.setType('responses');

        /**
         * @param mixed report
         */

        this.reportValues.setStartingAt(moment(new Date()).format('MM/DD/YYYY'));

        /**
         * @param mixed report
         */

        this.reportValues.setEndingAt(moment(new Date()).format('MM/DD/YYYY'));

        /**
         * @param mixed report
         */

        this.reportValues.setDisplay('dashbaord');

        /**
         * @param mixed report
         */

        this.reportValues.setData(null);

        /**
         * @param mixed report
         */

    }

    film: any;
    /**
     * @param mixed
     */

    id: any = 0;

    strings: any;
    /**
     * @param mixed
     */
    isDialogOpened: any = false;
    /**
     * @param mixed
     */
    filmUrl: any = 'assets/audio/filmmusic2.mp3';
    /**
     * @param mixed
     */

    isConfirmed: any = false;
    /**
     * @param mixed
     */
    isImgDialogOpened: any = false;
    /**
     * @param mixed
     */


    response2: any = {};

    /**
     * @param mixed
     */


    broadCastedResponses: any = [];
    /**
     * @param mixed
     */
    responseSelectedCntrlNum: any = null;

    /**
     * @param mixed
     */
    incomingResponse: any;
    /**
     * @param mixed
     */
    incomingUser: any;
    /**
     * @param mixed
     */
    incomingForm;
    any;
    /**
     * @param mixed
     */
    searchText: any = '';
    /**
     * @param mixed
     */
    outletId: any = 0;
    /**
     * @param mixed
     */
    yScrollingPart: any = 0;
    /**
     * @param mixed
     */
    xScrollingPart: any = 0;
    /**
     * @param mixed
     */

    reportValues: any;

    /**
     * @param mixed
     */

    userId: any = null;

    /**
     */

    data: any = [];

    /**
     * @param mixed Data
     */

    isDialogOpen: any = false;

    /**
     * @param mixed Data
     */


    selected: any = {};

    /**
     * @param mixed
     */

    getUserId(): void {
        return this.userId;
    }

    /**
     * @param mixed
     */

    setUserId(userId: any): void {
        this.userId = userId;
    }


    getData(): void {
        return this.data;
    }

    /**
     * @param mixed Data
     */


    setData(data: any): void {
        this.data = data;
    }

    /**
     * @param mixed Data
     */

    getSelected(): any {
        return this.selected;
    }

    /**
     * @param mixed Selected
     */

    setSelected(selected: any): any {
        this.selected = selected;
    }

    setPost(form: any, callback: any) {
        setTimeout(() => {
            this.incomingForm.setAttributes(Object.assign({}, form));
            this.incomingResponse.setAttributes({});
            this.incomingResponse.setFormId(this.incomingForm.getId());
            this.incomingResponse.setStartingAt('15:35:06');
            this.incomingResponse.setAltitude(0);
            this.incomingResponse.setDeviceUsage('');
            this.incomingResponse.setDepartmentId(1);
            this.incomingResponse.setAddress('');
            this.incomingResponse.setTitle('');
            this.incomingResponse.setStatus(0);
            this.incomingResponse.setLat(0);
            this.incomingResponse.setLng(0);
            this.incomingResponse.setPostId(this.service.random());
            this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
            this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY'));
            this.incomingResponse.setUserId(1);
            this.incomingResponse.setProgress(0);
            this.incomingResponse.setEndingAt('15:35:06');
            this.incomingResponse.setOutletId(0);
            try {
                this.incomingUser.setAttributes(this.service.app.data.status === 0 ? {} : this.service.app.data.user);
                if (Object.keys(this.incomingUser.attributes).length === 0) {
                    window.localStorage.setItem(this.incomingForm.getId(), JSON.stringify(this.incomingForm.getAttributes()));
                } else {
                    this.incomingResponse.setPostedBy(this.incomingUser.getAttributes());
                    this.incomingResponse.setUserId(this.incomingUser.getId());
                }
            } catch (e) {
            }
            this.responseSelectedCntrlNum = this.incomingResponse.getCntrlNum();
            callback(this.incomingResponse.getAttributes());
        });
    }

    sendInvitationCard(outgoingRequest: Parameters, call) {
        this.service.httpService('POST', this.service.app.routes.invitations.card.delivery,
            outgoingRequest.getAttributes(), {}, (result: any) => {
                call({});
            }, (error: any) => {
                call(null);
            });
    }



    updateResponseResult(outgoingRequest: Parameters, call: any){
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        let incomingOptions: any = new Parameters();
        incomingOptions.setAttributes({});
        incomingOptions.setIgnoreLoadingBar('true');
        incomingOptions.setNotify(true);
        if(outgoingRequest.getAttributes().hasOwnProperty(this.strings.getIgnoreLoadingBar()) &&
            outgoingRequest.getIgnoreLoadingBar().includes('true'))
            delete incomingOptions.attributes[this.strings.getIgnoreLoadingBar()];
        this.service.httpService('POST',
            this.service.app.routes.forms.responses.responseResults.update.toString(),
            outgoingRequest.getAttributes(), incomingOptions.getAttributes(), (responseResult: any) => {
                call({});
            }, (error: any) =>  {call(null)});
    }

    search(outgoingRequest: Parameters, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        if(!(outgoingRequest.hasAttribute(this.strings.getFormId()) && outgoingRequest.getFormId() === null))
            outgoingRequest.setFormId(this.formService.getSelected()[this.strings.getId()]);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.search,
            outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {call(null);});
    }

    addPostByFormIdAndControls($formId: any, controls: any, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        const $incomingResponse: any = new Parameters();
        this.incomingResponse.setAttributes({});
        this.incomingResponse.setPostId(this.service.random());
        this.incomingResponse.setCntrlNum(this.incomingResponse.getPostId());
        this.incomingResponse.setRegisteredBy(1);
        this.incomingResponse.setTeamId(0);
        this.incomingResponse.setOutletTypeId(0);
        this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setCreatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setUpdatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setStartingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setEndingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setAltitude(0);
        this.incomingResponse.setDeviceUsage('');
        this.incomingResponse.setDepartmentId(0);
        this.incomingResponse.setLat(0);
        this.incomingResponse.setLng(0);
        this.incomingResponse.setFormId($formId);
        const $questions: any = [];
        controls.map((question: any) => {
            const $incomingQuestion: any = new Parameters();
            $incomingQuestion.setAttributes(Object.assign({}, question));
            $incomingQuestion.setResult($incomingQuestion.hasAttribute(this.strings.getResult()) &&
            !this.service.isNull($incomingQuestion.getResult()) ?
                JSON.stringify($incomingQuestion.getResult()) : '');
            $incomingQuestion.getAttributes()[this.strings.getFormControlId()] =
                $incomingQuestion.getAttributes().control.id;
            $incomingQuestion.attributes = Object.assign($incomingQuestion.getAttributes(), {
                data: this.service.isNull($incomingQuestion.getAttributes().data) ? '' :
                    $incomingQuestion.getAttributes().data
            });
            delete $incomingQuestion.getAttributes().options;
            $questions.push($incomingQuestion.attributes);
        });
        this.incomingResponse.setControls(JSON.stringify($questions));
        const posts: any = [];
        posts.push(this.incomingResponse.getAttributes());
        this.service.httpService(
            'POST',
            '/public/form-posts',
            {posts: JSON.stringify(posts)},
            {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
            });

    }


    addResponseByFormId($formId: any, call: any) {
        const $incomingResponse: any = new Parameters();
        this.incomingResponse.setAttributes({});
        this.incomingResponse.setRegisteredBy(1);
        this.incomingResponse.setTeamId(0);
        this.incomingResponse.setOutletTypeId(0);
        this.incomingResponse.setDate(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setCreatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setUpdatedAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setStartingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setEndingAt(moment(new Date()).format('MM/DD/YYYY H:i:s'));
        this.incomingResponse.setAltitude(0);
        this.incomingResponse.setDeviceUsage('');
        this.incomingResponse.setDepartmentId(0);
        this.incomingResponse.setFormId($formId);
        this.incomingResponse.setPostId(this.service.random());
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.add,
            this.incomingResponse.getAttributes(),
            {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
                call(null);
            });

    }

    getResponseByFormId($formId: any, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        const incomingRequest: any = new Parameters();
        incomingRequest.setAttributes({});
        incomingRequest.setFormId($formId);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.get,
            incomingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {
            });
    }


    requestResponseById($id: number, call: any) {
        this.strings.setAttributes(this.service.app.hasOwnProperty('strings') ? this.service.app.strings.params : {});
        let incomingRequest: any = new Parameters();
        incomingRequest.setAttributes({});
        incomingRequest.setId($id);
        incomingRequest.setFormId(this.formService.getSelected()[this.strings.getId()]);
        this.service.httpService(
            'POST',
            this.service.app.routes.responses.get,
            incomingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'},
            (response: any) => {
                call(response);
            }, (error: any) => {call(null);});
    }

    playFilm(callback: any) {
        this.film = new Audio(this.filmUrl);
        this.film.volume = 0.5;
        this.film.play();
        this.film.onended = () => {
            callback(null);
        };
    }

    openDialog() {
        setTimeout(() => {
            if (this.isDialogOpen === false) {
                this.isDialogOpen = true;
                const dialogRef = this.dialog.open(DialogResponseComponent, {
                    data: {},
                    width: '60%',
                    height: '100%',
                    disableClose: false,
                });
                dialogRef.afterClosed().subscribe(() => {
                    this.isDialogOpen = false;
                });
            }
        });


    }
}
