<!--INNER BANNER START-->
<section id="inner-banner">
    <div class="container">
        <h1>Register Your Account</h1>
    </div>
</section>
<!--INNER BANNER END-->
<!--MAIN START-->
<div id="main">
    <!--SIGNUP SECTION START-->
    <section class="signup-section">
        <div class="container">
            <div class="holder">
                <div class="thumb"><img class="img-circle" [ngStyle]="{'width':'74px','height':'75px'}"
                                        src="{{app.data.status === 1 && app.data.user.avatar ? app.data.user.avatar : '' }}" alt="img"></div>
                <form [formGroup]=formValues (ngSubmit)="onSubmit(formValues.value)" (keydown.enter)="$event.preventDefault()">
                    <div class="input-box"> <i class="fa fa-pencil"></i>
                        <input type="text" placeholder="You or company Name" formControlName="name" required>
                    </div>
                    <div class="input-box"> <i class="fa fa-phone"></i>
                        <input type="text"  placeholder="Phone" formControlName="phone" required>
                    </div>
                    <div class="input-box"> <i class="fa fa-envelope-o"></i>
                        <input type="text" placeholder="Email" formControlName="email">
                    </div>
                    <div class="input-box"> <i class="fa fa-unlock"></i>
                        <input class="passwordX" type="password" placeholder="Password" formControlName="password" required>
                    </div>-
                    <div class="check-box">
                        <input id="id3" type="checkbox" />
                        <strong>I’ve read <a href="">Terms</a> &amp; <a href="">Conditions</a></strong>
                    </div>
                    <input type="submit" value="Sign up" (click)="onSubmit(formValues)">
                    <a href="" class="login">Forgot your Password </a> <b>OR</b>
                    <div class="login-social">
                        <h4>Log in with Your Social Account</h4>
                        <ul>
                            <li fb-login type="button"> <a class="fb"><i class="fa fa-facebook"></i>Facebook Login</a> </li>
                            <li g-login type="button"> <a  class="gp"><i class="fa fa-google-plus"></i>Google Plus Login</a> </li>
                            <li> <a href="" class="tw"><i class="fa fa-twitter"></i>Twitter Login</a> </li>
                        </ul>
                        <em>Already a Member? <a href="app/login">LOG IN NOW</a></em>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!--SIGNUP SECTION END-->

</div>
<!--MAIN END-->
