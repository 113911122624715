import {Component, OnInit, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Parameters} from "../../parameters";
import {AppService} from "../../app.service";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/internal/operators";
import {ResponseService} from "../../forms/responses/data/response.service";
import {InviteeService} from "../invitee.service";

@Component({
  selector: 'app-register-attendee',
  templateUrl: './register-attendee.component.html',
  styleUrls: ['./register-attendee.component.scss']
})
export class RegisterAttendeeComponent implements OnInit {
  @ViewChild('matAutocompleteTrigger', {static: false}) matAutocompleteTrigger: any;
  strings: any;
  inviteeControl = new FormControl();
  invitees: any = [];
  filteredInvitees: any = [];
  incomingInviteeSelected: any;
  selectedIndex: any = 0;
  constructor(public service: AppService,
              private inviteeService: InviteeService,
              private responseService: ResponseService) {
    this.matAutocompleteTrigger = MatAutocompleteTrigger;
    this.strings = new Parameters();
    this.incomingInviteeSelected = new Parameters();
    this.strings.setAttributes(this.service.app.strings.params);
    document.getElementsByTagName( 'body')[0].style.backgroundColor = '#fff';
    this.incomingInviteeSelected.setAttributes({});
  }

  ngOnInit(): void {
    this.filteredInvitees = this.inviteeControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(name  => name ? this.filterInvitee(name) : this.invitees.slice())
    );
  }

  private filterInvitee(label: string) {
    const args = label.toString().toLowerCase();
    return this.invitees.filter((role: any) => JSON.stringify(role).toString().toLowerCase().includes(args));
  }

  onChangeInviteeText(event: any){
    if(event.target.value.length > 3) {
      let outgoingRequest: any = new Parameters();
      outgoingRequest.setAttributes({});
      outgoingRequest.setSearchText(event.target.value);
      outgoingRequest.setFormId(null);
      this.inviteeService.search(outgoingRequest, (invitees: any) => {
        invitees.map((invitee: any) => {
          let incomingInvitee = new Parameters();
          incomingInvitee.setAttributes(invitee);
          let position: any = this.service.findIndex(this.invitees, this.strings.getResponseId(), incomingInvitee.getResponseId());
          if(position === parseFloat('-1')) {
            this.invitees.push(incomingInvitee.getAttributes());
            this.ngOnInit();
            setTimeout(() => {this.matAutocompleteTrigger.openPanel();});
          }
        });
      });
    }
  }

  onInviteeSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.incomingInviteeSelected.setAttributes(event.option.value);
    alert(this.incomingInviteeSelected.getName());
  }

  displayFn(value: any){
    return value.name;
  }

}
