import {Component, Input, OnInit} from '@angular/core';
import {FormService} from "./form.service";

@Component({
  selector: 'app-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss']
})
export class PreviewFormComponent implements OnInit {
  @Input() questions: any;
  @Input() form: any;
  constructor(public formService: FormService) {
  }

  ngOnInit(): void {
    this.form = Object.assign(this.form, {controls : this.formService.questions});
  }

}
