import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../../app.service";

@Component({
  selector: 'app-draft-email-controls',
  templateUrl: './draft-emails.component.html',
  styleUrls: ['./draft-emails.component.scss']
})
export class DraftEmailsComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  label: any;
  controls: any;
  constructor(private service: AppService) { }
  ngOnInit(): void {
    this.controls = this.service.app.data.controls;
  }

}
