<table id="datatable" class="table table-striped table-bordered" style="width:100%">
  <thead>
  <tr>
    <th class="mdl-data-table__cell--non-numeric headline2">Name</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Status</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Department</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Phone</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Email</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Address</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Campany</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Photo</th>
    <th class="mdl-data-table__cell--non-numeric headline2">Description</th>
    <th class="text-right">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let employee  of (flag.includes('all') ? (getEmployeesByemployees(employees.data) | jsonFilter:searchCntrl) : (employees.trashed.data | jsonFilter:searchCntrl))"
      (contextmenu)="onContextMenu($event, employee)"
      (mousedown)="selectedEmployee = employee">
    <td class="mdl-data-table__cell--non-numeric headline">{{setUser(employee)}}{{getUser().name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{getUser().role.display_name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{employee.department.name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{getUser().phone}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{getUser().email}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{getUser().location}}</td>
    <td class="mdl-data-table__cell--non-numeric headline">{{getCampanyByEmployee(employee).name}}</td>
    <td class="mdl-data-table__cell--non-numeric headline"><img class="img-thumbnail avatar"    src="{{getUser().avatar}}" *ngIf="getUser().avatar" /></td>
    <td class="mdl-data-table__cell--non-numeric headline" [innerHTML]="employee.description"></td>
    <td class="mdl-data-table__cell--non-numeric headline">
      <div class="dropdown pull-right">
        <button class="btn btn-default dropdown-toggle btn-xs" type="button" data-toggle="dropdown"><i class="fa fa-cog"></i>
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu">
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handlePassword(selectedEmployee)">Change Password</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedEmployee)">Edit</li>
          <li mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedEmployee)">Delete</li>
          <li mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedEmployee)">Restore</li>
        </ul>
      </div>
      <div style="visibility: hidden; position: fixed"
           [style.left]="contextMenuPosition.x"
           [style.top]="contextMenuPosition.y"
           [matMenuTriggerFor]="contextMenu">
      </div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="inspector">
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handlePassword(selectedEmployee)">Change Password</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleUpdate(selectedEmployee)">Edit</button>
          <button mat-menu-item *ngIf="flag === 'all'"     (click)="handleDestroy(selectedEmployee)">Delete</button>
          <button mat-menu-item *ngIf="flag === 'restore'" (click)="handleRestore(selectedEmployee)">Restore</button>
        </ng-template>
      </mat-menu>
    </td>
  </tr>
  </tbody>
</table>
