import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-report-question',
  templateUrl: './report-question.component.html',
  styleUrls: ['./report-question.component.scss']
})
export class ReportQuestionComponent implements OnInit {
  @Input() questions: any;
  constructor() {
  }

  ngOnInit(): void {
  }

  getlabels(data: any, label: any){return data.map((l: any) => { return (l[label]).toString();})}
  getValues(data: any, label: any, value: string){return data.map((l: any) => { return {name: l[label], value: l[value]};})}


}
