<div>
    <section class="post-section blog-post" style="background-color: transparent !important;">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6"
                     style="cursor: pointer">
                    <div class="post-box">
                        <div class="text-box">
                            <h4>
                                <a>Accounts</a>
                            </h4>
                            <strong>45</strong>
                            <p>
                                <app-bar-chartjs [labels]="only(reportValues.attributes.data.teams, 'name')"
                                                 [label]="formValues.getResponseDisplayTitle()"
                                                 [type]="'bar'"
                                                 [data]="only(reportValues.attributes.data.teams, 'responses')"
                                                 [element]="'teamsResponsesElement'"
                                                 [color]="'blue'">
                                </app-bar-chartjs>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>
