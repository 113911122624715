<div class="clearfix"></div>
<div class="page">
    <div class="page-title">
        <div class="title_left">
            <h3 layout="row">
                <ol class='breadcrumb'>
                    <li><a href="/app/users"><span [ngStyle]="{'font-size':'15px'}">Mange users</span></a></li>
                    <li class="hidden-md hidden-sm hidden-xs"><span [ngStyle]="{'font-size':'12px'}" title="">cross check user asigned activities</span></li>
                </ol>
            </h3>
        </div>
        <div class="title_right">
            <div class="col-md-5 col-sm-5 col-xs-12 form-group pull-right top_search">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search for..."
                           (keyup)="onSearchTextChange(searchCntrl)"
                           (keyup.enter)="enterKeyUp(searchCntrl)"
                           [(ngModel)]="searchCntrl" />
                    <span class="input-group-btn">
                        <button class="btn btn-default"
                                type ="button"
                                (click)="handleSearch()"
                        >
                          Go!
                        </button>
                    </span>
                </div>
            </div>
            <div  class="pull-right"><a mat-mini-fab class="defaultColor"><i class="material-icons"> add </i></a></div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="x_panel">
                <div class="x_title">
                    <!-- <h2>Button Example <small>Users</small></h2> -->
                    <h2>
                        {{app.settings.client.name|slice:0:10}}..
                        <small>
                            <ol class="breadcrumb pull-right">
                                <li *ngFor="let module of modules()"><a routerLink="{{module.path}}">{{module.title}}</a></li>
                                <!--<li>-->
                                <!--<a href="#" ng-if="'datebetween' !== parms.report.time|lowercase">{{parms.report.time}} </a>-->
                                <!--<a href="#" ng-if="'datebetween' === parms.report.time|lowercase">{{parms.report.starting_at}} - {{parms.report.starting_at}}</a>-->
                                <!--</li>-->
                            </ol>
                            <a class='btn btn-xs' (click)="back()"><i class="fa fa-chevron-left"></i></a>
                            <a class='btn btn-xs' (click)="forward()"><i class="fa fa-chevron-right"></i></a>
                        </small>
                    </h2>
                    <app-toolbox [app]= 'app' (onOpenDialog)="openDialog()"></app-toolbox>
                    <div class="clearfix"></div>
                </div>
                <div class="x_content" relink-event="datatablex">
                    <p class="text-muted font-13 m-b-30">
                        Data collection is defined as the procedure of collecting, measuring and analyzing accurate insights for research using standard validated techniques. ... The approach of data collection is different for different fields of study, depending on the required information.
                    </p>
                    <div class="row og">
                        <mat-tab-group mat-align-tabs="end" [selectedIndex]="0">
                            <mat-tab [disabled]="false">
                                <ng-template mat-tab-label>
                                    <div>
                                        <center>
                                            <br>
                                            <div [ngStyle]="{'cursor':'pointer'}">Managers</div>
                                        </center>
                                    </div>
                                </ng-template>
                                <div>
                                    <div class="clearfix"></div>
                                    <mat-tab-group>
                                        <mat-tab label="All({{service.app.data.employees.data.length}})">
                                            <br><br><app-search ></app-search><br><br>
                                            <app-table-employees [flag]="'all'" [role]="'manager'"></app-table-employees>
                                        </mat-tab>
                                        <mat-tab label="Deleted({{service.app.data.employees.trashed.data.length}})">
                                            <br><br><app-search></app-search><br><br>
                                            <!--                        <app-table-employees [flag]="'restore'"></app-table-employees>-->
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </mat-tab>
                            <mat-tab [disabled]="false">
                                <ng-template mat-tab-label>
                                    <div>
                                        <center>
                                            <br>
                                            <div [ngStyle]="{'cursor':'pointer'}">Data collector</div>
                                        </center>
                                    </div>
                                </ng-template>
                                <div>
                                    <div class="clearfix"></div>
                                    <mat-tab-group>
                                        <mat-tab label="All({{service.app.data.employees.data.length}})">
                                            <br><br><app-search ></app-search><br><br>
<!--                                            <app-table-employees [flag]="'all'" [role]='4'></app-table-employees>-->
                                        </mat-tab>
                                        <mat-tab label="Deleted({{service.app.data.employees.trashed.data.length}})">
                                            <br><br><app-search></app-search><br><br>
                                            <!--                        <app-table-employees [flag]="'restore'"></app-table-employees>-->
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngStyle]='{"height":iframeHeight() + "px"}'></div>
<div id="form"> </div>







