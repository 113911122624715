<div class="page">
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div>
        <div class="fixed">
          <div class="roww">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="row top_tiles">
                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-comments-oo"></i></div>
                    <div class="count">{{service.numberWithCommans(incomingReport.attributes.data.responses)}}</div>
                    <h3>Responses</h3>
                    <p><app-bar-chartjs [labels]="only(incomingReport.attributes.data.teams, 'name')"
                                        [label]="'responses'"
                                        [type]="'bar'"
                                        [data]="only(incomingReport.attributes.data.teams, 'responses')"
                                        [element]="'responseElEment'" [color]="'blue'"></app-bar-chartjs></p>
                  </div>
                </div>
                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-check-square-oo"></i></div>
                    <div class="count">{{service.numberWithCommans(incomingReport.attributes.data.target)}}</div>
                    <h3>kpi on set</h3>
                    <p><app-bar-chartjs [labels]="only(incomingReport.attributes.data.teams, 'name')"
                                        [label]="'kpi'"
                                        [type]="'bar'"
                                        [data]="only(incomingReport.attributes.data.teams, 'target')"
                                        [element]="'targetElement'"
                                        [color]="'blue'"></app-bar-chartjs></p>
                  </div>
                </div>
                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-sort-amount-asc"></i></div>
                    <div class="count">{{incomingReport.attributes.data.progress}}</div>
                    <h3>Progress %</h3>
                    <p><app-bar-chartjs [labels]="only(incomingReport.attributes.data.teams, 'name')"
                                        [label]="'progress'"
                                        [type]="'line'"
                                        [data]="only(incomingReport.attributes.data.teams, 'progress')"
                                        [element]="'progressElement'" [color]="'red'"></app-bar-chartjs></p>
                  </div>
                </div>
                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-caret-square-o-right"></i></div>
                    <div class="count">{{incomingReport.attributes.data.teams.length}}</div>
                    <h3>Teams</h3>
<!--                <p class="pull-right"><app-sparkline [type]="'line'" [legends]="only(incomingReport.getData().users, 'name')" [data]="only(incomingReport.attributes.data.teams, 'progress')"></app-sparkline></p>-->
                    <p><app-bar-chartjs [labels]="only(incomingReport.attributes.data.teams, 'name')"
                                        [label]="'responses'"
                                        [type]="'bar'"
                                        [data]="only(incomingReport.attributes.data.teams, 'responses')"
                                        [element]="'teamElement'" [color]="'red'"></app-bar-chartjs></p>
                  </div>
                </div>
                <div class="animated flipInY col-lg-9 col-md-9 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-female"></i></div>
                    <div class="count">{{incomingReport.attributes.data.users.length}}</div>
                    <h3>Ambassadors</h3>
<!--                <p class="pull-right"><app-sparkline [type]="'line'" [legends]="only(incomingReport.getData().users, 'name')" [data]="only(incomingReport.attributes.data.users, 'progress')"></app-sparkline></p>-->
                    <p><app-bar-chartjs [labels]="only(incomingReport.attributes.data.users, 'name')"
                                        [label]="'responses'"
                                        [type]="'line'"
                                        [limit]="false"
                                        [data]="only(incomingReport.attributes.data.users, 'responses')"
                                        [element]="'ambassadorElement'" [color]="'red'"></app-bar-chartjs></p>
                  </div>
                </div>
                <div class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12">
                  <div class="tile-stats">
                    <div class="icon"><i class="fa fa-femalee"></i></div>
                    <div class="count"></div>
                    <h3></h3>
                    <p style="margin-top: 40px;">
                      <a mat-fab class="defaultColor lg-fab pull-right" style="margin-left: 10px;margin-right: 5px;margin-bottom: 100px" (click)="onextractFormButtonClicked()" title="Download csv file of responses from those users collecting data on ground">Extract CSV</a>
                      <a mat-fab class="defaultColor lg-fab pull-right" style="" (click)="oncreateFormButtonClicked()">Create Form</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div  class="animated flipInY col-lg-3 col-md-3 col-sm-6 col-xs-12" *ngFor="let question of filter(questions); let qenstionalIndex = index">
              <div class="tile-stats" style="height: 350px !important;">
                <!--                <div class="icon"><i class="fa fa-comments-ooo"></i></div>-->
                <!--                <div class="count">&nbsp;</div>-->
                <h3><small>{{service.limitTo(question.label, 45)}}</small></h3>
                <p>
                  <app-bar-chartjs [labels]="getlabels(question.options,'label')"
                                   [label]="'count'"
                                   [type]="'bar'"
                                   [limit]="10"
                                   [height]="315"
                                   [data]="only(question.options,'count')"
                                   [element]="'questionElement'+question.id"
                                   [color]="'blue'"
                                  *ngIf="question.control.id === 3">
                  </app-bar-chartjs>

<!--                <app-bar-chartjs [labels]="getlabels(question.options,'label')"-->
<!--                                   [label]="'count'"-->
<!--                                   [type]="'bar'"-->
<!--                                   [limit]="10"-->
<!--                                   [height]="315"-->
<!--                                   [data]="only(question.options,'count')"-->
<!--                                   [element]="'questionElement'+question.id"-->
<!--                                   [color]="'red'"-->
<!--                                  *ngIf="question.control.id === 5"></app-bar-chartjs>-->
<!--                <app-piechart-->
<!--                          [data]="{legend: getlabels(question.options,'label'),data: getValuesByLableAndValue(question.options,'label', 'count')}"-->
<!--                          *ngIf="question.control.id === 5" ></app-piechart>-->
                    <app-piechart-chartjs
                            [height]="315"
                            [element]="question.id + 'questionalElement'"
                            [labels]="service.getlegendsByDataAndlabel(question.options,'label')"
                            [data]="service.getValuesByDataAndLable(question.options,'count')"
                            *ngIf="question.control.id === 5 && qenstionalIndex !== 1">
                    </app-piechart-chartjs>
                  <!--<app-morris-barchart [data]="{labels: ['count'], data: question.options,element:'#ff'+question.id, xKey: 'label'}" *ngIf="question.control.id === 3"></app-morris-barchart>-->
                  <!--                      <app-donut-piechart [element]="question.id + 'questionElement'"-->
                  <!--                                          [height]="'215'"-->
                  <!--                                          [labels]="service.getlegendsByDataAndlabel(question.options,'label')"-->
                  <!--                                          [data]="service.getValuesByDataAndLable(question.options,'count')"-->
                  <!--                                          *ngIf="question.control.id === 5"></app-donut-piechart>-->
                  <app-donut-piechat-chatjs
                          [labels]="service.getlegendsByDataAndlabel(question.options,'label')"
                          [data]="service.getValuesByDataAndLable(question.options,'count')"
                          [height]="170"
                          [element]="'questionElement'+question.id"
                          *ngIf="question.control.id === 5 && qenstionalIndex === 1"></app-donut-piechat-chatjs>
                </p>
              </div>
            </div>
          </div>
          <div class="roww">
            <div class="animated flipInY col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="tile-stats">
                <div class="icon"><i class="fa fa-clock-o"></i></div>
                <div class="count"></div>
                <h3>Responses Summary <small>Monthly progress</small></h3>
                <p>&nbsp;</p>
                <div style="margin-bottom: 60px;margin-top: 40px;margin-left: 5px;margin-right: 0px">
                  <div class="x_panell">
                    <div class="x_titlle">
                      <div class="filterr">
                        <div id="reportrange" class="pull-right" style="background: #fff; cursor: pointer; padding: 5px 10px; margin-right: 5px; border: 1px solid #ccc">
                          <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
                          <span>December 30, 2014 - January 28, 2015</span> <b class="caret"></b>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="x_contentt">
                      <div class="demo-container" style="height:280px">
                        <div id="placeholder33x" class="demo-placeholder"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row col-md-12 col-sm-12 col-xs-12">
            <div class="dashboard_graph x_panel">
              <div class="row x_title">
                  <h3> <small>Weekly progress</small></h3>
              </div>
              <div class="x_content">
                <div class="demo-container">
                <div><app-bar-chartjs [labels]="only(incomingReport.attributes.data.weekly, 'day')"
                                      [label]="'responses'"
                                      [type]="'line'"
                                      [limit]="false"
                                      [height]="'370'"
                                      [data]="only(incomingReport.attributes.data.weekly, 'responses')"
                                      [element]="'weeklyResponsesElement'"
                                      [color]="'red'"></app-bar-chartjs></div>
<!--            <div id="placeholder3xx3" class="demo-placeholder" style="width: 100%; height:250px;"></div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 pull-left" [hidden]='true'>
            <div class="x_panel tile ">
              <div class="x_title">
                <h2>
                  Recent Responses
                </h2>
                <ul class="nav navbar-right panel_toolbox">
                  <li><a class="collapse-link"><i class="fa fa-chevron-up"></i></a>
                  </li>
                  <li><a class="close-link"><i class="fa fa-close"></i></a>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="x_content">
                <!--                  <table class="countries_list" style="width:100%">-->
                <!--                    <thead>-->
                <!--                      <tr>-->
                <!--                        <th class="mdl-data-table__cell&#45;&#45;non-numeric" *ngFor="let response of (incomingReport.getData().posts.length === 0 ? [] : incomingReport.getData().posts[0].datas)"><span [innerHTML]="response.formControl.label"></span></th>-->
                <!--                        <th class="mdl-data-table__cell&#45;&#45;non-numeric">Posted by</th>-->
                <!--                        <th class="mdl-data-table__cell&#45;&#45;non-numeric">Team</th>-->
                <!--                        <th class="mdl-data-table__cell&#45;&#45;non-numeric">Address</th>-->
                <!--                        <th class="mdl-data-table__cell&#45;&#45;non-numeric">Published</th>-->
                <!--                      </tr>-->
                <!--                    </thead>-->
                <!--                    <tbody>-->
                <!--                    <tr *ngFor="let response of (incomingReport.getData().posts | jsonFilter:searchCntrl)">-->
                <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric" *ngFor="let resp of response.datas"><center> <div *ngIf="resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"><span [innerHTML]="resp.data"></span></div></center></td>-->
                <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.postedBy.name ? response.postedBy.name : ''}}</td>-->
                <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.postedBy.team ? response.postedBy.team.name : ''}}</td>-->
                <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{(response.address)}}</td>-->
                <!--                      <td class="mdl-data-table__cell&#45;&#45;non-numeric">{{response.created_at}}</td>-->
                <!--                    </tr>-->
                <!--                    </tbody>-->
                <!--                  </table>-->
                <table  class="countries_list" style="width:100%" *ngIf="incomingReport.getData().posts.length !== 0">
                  <thead>
                  <tr>
                    <th class="mdl-data-table__cell--non-numeric" *ngFor="let response of (incomingReport.getData().posts.length === 0 ? [] : service.slice(incomingReport.getData().posts[0].datas,0,1))"><small [innerHTML]="service.limitTo(response.formControl.label, 20)"></small></th>
                    <th class="mdl-data-table__cell--non-numeric"><small>Respodent</small></th>
                    <th class="mdl-data-table__cell--non-numeric"><small>Analytic</small></th>
                    <th class="mdl-data-table__cell--non-numeric"><small><i>Team</i></small></th>
                    <th class="mdl-data-table__cell--non-numeric"><small><i>Address</i></small></th>
                    <th class="mdl-data-table__cell--non-numeric"><small><i>Published</i></small></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let response of service.slice(incomingReport.getData().posts,0,9)">
                    <td class="mdl-data-table__cell--non-numeric" *ngFor="let resp of service.slice(response.datas,0,1)"><div *ngIf="resp.formControl.control.id !== 2 && resp.formControl.control.id !== 7"><span [innerHTML]="service.limitTo(resp.data , 20)"></span></div></td>
                    <td class="mdl-data-table__cell--non-numeric"><small>{{service.limitTo((response.postedBy.name ? response.postedBy.name : ''), 20)}}</small></td>
                    <td class="mdl-data-table__cell--non-numeric"> <app-sparkline [type]="'line'" [legends]="['Juma','Mollel','Nowell']" [data]="[30,40,5]"></app-sparkline></td>
                    <td class="mdl-data-table__cell--non-numeric"><small><i>{{service.limitTo((response.postedBy.team ? response.postedBy.team.name : ''), 20)}}</i></small></td>
                    <td class="mdl-data-table__cell--non-numeric"><small><i>{{(response.address)}}</i></small></td>
                    <td class="mdl-data-table__cell--non-numeric"><small><i>{{response.time}}</i></small></td>
                  </tr>
                  <tr>
                    <td colspan="67" (click)="seeAllRsp()" style="cursor: pointer"><center><i>See All</i></center></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12 pull-left">
            <div class="x_panel tile" style="height: 500px !important">
              <div class="x_title">
                <h2>
                  Device Usage
                </h2>
                <ul class="nav navbar-right panel_toolbox">
                  <li><a class="collapse-link"><i class="fa fa-chevron-up"></i></a>
                  </li>
                  <li><a class="close-link"><i class="fa fa-close"></i></a>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="x_content">
                <app-piechart [data]="{legend:getlabels(incomingReport.getData().device_usage,'name'),data: getValuesByLableAndValue(incomingReport.getData().device_usage,'name','count')}"></app-piechart>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12" [hidden]="true">
            <div class="x_panel tile ">
              <div class="x_title">
                <h2>Performance Analytics</h2>
                <ul class="nav navbar-right panel_toolbox">
                  <li><a class="collapse-link"><i class="fa fa-chevron-up"></i></a>
                  </li>
                  <li><a class="close-link"><i class="fa fa-close"></i></a>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="x_content">
                <div class='col-md-6 col-sm-12 col-xs-12'>
                  <app-piechart [data]="{legend:getlabels(incomingReport.getData().teams,'name'),data: getValuesByLableAndValue(incomingReport.getData().teams,'name','responses')}"></app-piechart>
                </div>
                <div class='col-md-6 col-sm-12 col-xs-12'>
                  <app-morris-barchart [data]="{labels: ['responses'], data: incomingReport.getData().teams,element:'team-performance', xKey: 'name'}"></app-morris-barchart>
                </div>
                <div class='col-md-6 col-sm-12 col-xs-12'>
                  <app-piechart [data]="{legend:getlabels(incomingReport.getData().users,'name'),data: getValuesByLableAndValue(incomingReport.getData().users,'name','responses')}"></app-piechart>
                </div>
                <div class='col-md-12 col-sm-12 col-xs-12'>
                  <app-echart [data] = "{
                         title: '',
                         legends: ['response', 'target'],
                         yAxis: [{
                                    type: 'value',
                                    name: '',
                                    axisLabel: {
                                      formatter: '{value}'
                                    }
                                  }, {
                                    type: 'value',
                                    name: '',
                                    axisLabel: {
                                      formatter: '{value}'
                                    }
                         }],
                         xValues: getlabels(incomingReport.getData().users,'name'),
                         series: [{
                              name: 'response',
                              type: 'bar',
                              data: getlabels(incomingReport.getData().users,'responses')
                            }, {
                              name: 'target',
                              type: 'line',
                              data: getlabels(incomingReport.getData().users,'target')
                         }]
                       }">
                  </app-echart>
                </div>
                <div class='col-md-12 col-sm-12 col-xs-12'>
                  <app-echart [data] = "{
                         title: '',
                         legends: ['response', 'target'],
                         yAxis: [{
                                    type: 'value',
                                    name: '',
                                    axisLabel: {
                                      formatter: '{value}'
                                    }
                                  }, {
                                    type: 'value',
                                    name: '',
                                    axisLabel: {
                                      formatter: '{value}'
                                    }
                         }],
                         xValues: getlabels(incomingReport.getData().teams,'name'),
                         series: [{
                              name: 'response',
                              type: 'bar',
                              data: getlabels(incomingReport.getData().teams,'responses')
                            }, {
                              name: 'target',
                              type: 'line',
                              data: getlabels(incomingReport.getData().teams,'target')
                            },]
                       }">
                  </app-echart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <agm-map
                    #gm
                    [latitude]="lat"
                    [longitude]="lng"
                    [fitBounds]="false"
                    [zoom]="zoom"
                    [disableDefaultUI]="false"
                    [styles]="app.settings.map.styles.style1"
                    style="height : {{getHeight().height + 'px'}}">
              <agm-marker
                      *ngFor="let marker of (markers.length === 0 ? [] :  markers)"
                      [latitude]="marker.lat"
                      [longitude]="marker.lng"
                      [agmFitBounds]="true"
                      [openInfoWindow]="true"
              >
                <agm-info-window
                        (infoWindowClose)="onInfoWindowClose(gm,infoWindow)"
                        #infoWindow>
                  <div>
                    {{marker.postedBy.length === 0 ? marker.date : marker.postedBy.name}}
                    <hr>
                    <div>{{marker.address}}</div>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>
          <div class="row"></div>
          <div [ngStyle]='{"height":getHeight() + "px"}'></div>
        </div>
      </div>
<!--  <div class="toolbar">-->
        <!--  <span (click)="openMenuDate()"><i class="material-icons icon">access_time</i></span>-->
        <!--  <span (click)="handleHomeNavigator()"><i class="material-icons icon">home_filled</i></span>-->
<!--  </div>-->
    </div>
  </div>
</div>


