<center>
    <div id="pdfTable" #pdfTable style="color: #0f0f0f !important;">
        <div>
            <center>
                {{incomingForm.getName().toString().toUpperCase()}}<br>
                {{('individual performance').toUpperCase()}} <br>
                {{getDateFormat()}}<br>
                {{('final report').toUpperCase()}}
            </center>
        </div><br><br>
        <table>
            <thead>
                <tr style="" >
                    <ng-container *ngIf="service.responses.users.length > 0">
                        <ng-container *ngFor="let user of [service.responses.users[0]]">
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}"></td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                                {{('outlets visited').toString().toUpperCase()}}
                            </td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                                {{('outlets that sell').toString().toUpperCase()}}
                            </td>
                            <ng-container *ngIf="user.hasOwnProperty('questions')">
                                <td *ngFor="let question of user.questions"
                                    [ngStyle]="{'border-bottom': '1px solid #5f6368'}">
                                    {{question.label}}
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of service.responses.users">
                    <ng-container *ngIf="user.hasOwnProperty('role_id') && service.parseFloat(user.role_id) !== 14">
                        <ng-container *ngIf="user.hasOwnProperty('questions')">
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                            > {{user.name.toString().toUpperCase()}}</td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                            >{{user.outlets}}</td>
                            <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}"
                            >{{user.outlets_that_sell}}</td>
                            <ng-container>
                                <td *ngFor="let question of user.questions; let incremental = index"
                                    [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': (incremental===(user.questions.length -1) ? '' : '1px solid #5f6368')}">
                                    {{question.sum}}
                                </td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr style="" *ngIf="service.responses.users.length > 0">
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}"></td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}">
                        {{service.responses.outlets}}
                    </td>
                    <td [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368', 'border-right': '1px solid #5f6368'}">
                        {{service.responses.outlets_that_sell}}
                    </td>
                    <ng-container>
                        <td *ngFor="let question of service.responses.questions"
                            [ngStyle]="{'border-bottom': '1px solid #5f6368', 'border-top': '1px solid #5f6368'}">
                            {{question.sum}}
                        </td>
                    </ng-container>
                </tr>
            </tbody>

        </table>
    </div>
</center>
