import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';

@Component({
  selector: 'app-video-controls',
  templateUrl: './video-controls.component.html',
  styleUrls: ['./video-controls.component.scss']
})
export class VideoControlsComponent implements OnInit {
  @Input() question: any;
  @Input() position: any;
  controls: any;
  constructor(private metthodsService: AppService) { }

  ngOnInit(): void {
    this.controls = this.metthodsService.app.data.controls;
  }

}
