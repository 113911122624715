<div layout="row">
  <div class="panel panel-default" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'red'}">
    <div class="panel-body">
      <mat-list>
        <mat-list-item>
          <span class="" mat-line>
            <app-label [question]="question"></app-label>
          </span>
        </mat-list-item>
        <mat-list-item>
          <span class="" mat-line>
            <mat-form-field  class="example-full-width" appearance="fill">
              <textarea matInput type="text" placeholder="Description.."  [(ngModel)]="question.placeholder"></textarea>
            </mat-form-field>
          </span>
        </mat-list-item>
      </mat-list>
      <div>
        <app-bottom-button-controls [question]="question" [position]="position"></app-bottom-button-controls>
      </div>
    </div>
  </div>
</div>
