<mat-tab-group>
  <mat-tab label="All({{clients.data.length}})">
    <br><br><app-search></app-search><br><br>
    <app-table-clients [flag]="'all'"></app-table-clients>
  </mat-tab>
  <mat-tab label="Deleted({{clients.trashed.data.length}})">
    <br><br><app-search ></app-search><br><br>
    <app-table-clients [flag]="'restore'"></app-table-clients>
  </mat-tab>
</mat-tab-group>
