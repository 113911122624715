import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d.src';
// @ts-ignore
highcharts3D(Highcharts);
@Component({
  selector: 'app-chart3-dhighcharts',
  templateUrl: './chart3-dhighcharts.component.html',
  styleUrls: ['./chart3-dhighcharts.component.scss']
})
export class Chart3DhighchartsComponent {
  highcharts = Highcharts;
  chartOptions: any = {
    chart: {
      renderTo: 'container',
      type: 'column',
      margin: 75,
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25
      }
    },
    title : {
      text: 'Chart rotation demo'
    },
    plotOptions : {
      column: {
        depth: 25
      }
    },
    series : [{
      data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
        194.1, 95.6, 54.4]
    }]
  };

}
