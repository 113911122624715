<div class="" style="position: relative; top: -41px;">&nbsp;
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="0">
        <mat-tab [disabled]="false">
            <ng-template mat-tab-label>
                <div>
                    <center>
                        <br>
                        <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
                        <div [ngStyle]="{'cursor':'pointer'}">Dashboard</div>
                    </center>
                </div>
            </ng-template>
            <app-dashboard-form [formId]="formId"></app-dashboard-form>
        </mat-tab>
        <mat-tab [disabled]="false">
            <ng-template mat-tab-label>
                <div>
                    <center>
                        <br>
                        <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
                        <div [ngStyle]="{'cursor':'pointer'}">Responses</div>
                    </center>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="false">
            <ng-template mat-tab-label>
                <div>
                    <center>
                        <br>
                        <i class="material-icons" [ngStyle]="{'cursor':'pointer'}" >add</i><br>
                        <div [ngStyle]="{'cursor':'pointer'}">Settings</div>
                    </center>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
