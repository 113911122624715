<div id="main">
    <!--<div [ngStyle]="{'position':'fixed','top':'0px','background-color':'red','z-index':'9999999','height':'100%','width':'100%'}">dsfkdsf</div>-->
    <div class="banner-outer banner-2 home">
        <div class="banner-2">
            <div [ngStyle]="{'height': this.service.getHeight()+'px'}">
                <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"
                     src="assets/images/361/slide3-1600x900.jpg" />
            </div>
        </div>

        <div class="caption" [ngStyle]="{'background-color':'transparent'}">
            <div class="holder">
                <form>
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8">
                            <section class="post-section blog-post" [ngStyle]="{'padding':'0px','background-color':'transparent'}">
                                <div class="container">
                                    <div class="row">
                                        <h4 class="pull-right" [ngStyle]="{'padding':'15px'}"><a>Get your tickets for our next upcoming.. events !</a><br><br></h4>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                                        <table>
                                            <tr>
                                                <td [vAlign]="'bottom'" [ngStyle]="{'padding':'0px','margin':'0px'}">
                                                    <img src="assets/images/361/361.png" class="pull-left" [ngStyle]="{'max-width':'100px','max-height':'150px','margin-left':'10px'}"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td [vAlign]="'top'" [ngStyle]="{'padding':'0px','margin':'0px'}">
                                                    <div class="col-md-4 col-sm-6 cursor"
                                                         (click)="onFormSelect(form)"
                                                         *ngFor="let form of this.service.app.data.forms.data">
                                                        <div class="post-box" [ngStyle]="{'height':'400px'}">
                                                            <div class="text-box">
                                                                <h4 class="sawasdee20"><a>{{service.upperStartingCharacter(form.name.toLowerCase(), true)}}</a></h4>
                                                                <p>
                                                                    <span>
                                                                        <ul class="list-inline">
                                                                          <li *ngFor="let logo of form.settings.logo">
                                                                              <img src="{{logo.path}}"
                                                                                   class="img-circle mdl-shadow--2dp"
                                                                                   [ngStyle]="{'width':'30px', 'height':'30px'}"
                                                                                   alt="{{logo.caption}}">
                                                                          </li>
                                                                        </ul>
                                                                    </span>
                                                                </p>
                                                                <p [innerHTML]="form.description | safeUrl"></p>
                                                                <p class="sawasdee18">{{service.limitTo(service.removeHtmlTags('Procurement of Goods under public financed projects is carried out in accordance with policies and procedures laid down in the Public Procurement Act No. 7 of 2011 (hereinafter called PPA 2011) and the Public Procurement Regulations– Government Notice No. 446 of 2013 (hereinafter called Public Procurement Regulations) as amended in 2016.\n' +
                                                                    '\n' +
                                                                    '                                                                    This Standard Invitation for Quotation (SIFQ) for Procurement of Goods has been prepared by the Public Procurement Regulatory Authority (PPRA) in collaboration with Attorney General Chamber and other professional bodies for use by Procuring Entities (PEs) in the procurement of Goods.'),200).toLowerCase()}}</p>
<!--                                                                <p>-->
<!--                                                                    <center>-->
<!--                                                                        <a class="btn-style-2">Create Survey Form</a>-->
<!--                                                                    </center>-->
<!--                                                                </p>-->
                                                            </div>
                                                            <div class="frame"  style="margin-left: 10px; margin-right: 10px">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-md-2">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<!--<div id="main">-->
<!--    <div class="banner-outer banner-2 home">-->
<!--        <div class="banner-2" [ngStyle]="{'background':'red','height':this.service.getHeight()+'px'}">-->
<!--            <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'cover'}"-->
<!--                 src="assets/images/361/slide3-1600x900.jpg" />-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="caption">-->
<!--        <div class="holder">-->
<!--            <form>-->
<!--                <div class="row" style="margin-top: 50px">-->
<!--                    <div class="col-md-8">-->
<!--                        <section class="account-option" style="padding: 0px !important;background-color: transparent !important;">-->
<!--                            <div class="container">-->
<!--                                <div class="inner-box" style="background-color: transparent !important;">-->
<!--                                    <div class="text-box">-->
<!--                                        <h1 class="sawasdeeX7"><span [ngStyle]="{'color':'#ffc107'}" class="mitrax">coder Africa ! </span> we Help companies to digitalize their businesses</h1>-->
<!--                                        <p class="sawasdee20X3">-->
<!--                                            {{("We're software development company on a mission to empower businesses with cutting edge technology to thrive in the digital age and to create lasting value by solving the problems digitally. With our priority to innovation," +-->
<!--                                            "we are adept at developing high-quality, AI-enabled, smart, and robust software" +-->
<!--                                            "solutions for rapid business transformation. Start your journey with us to digitize your" +-->
<!--                                            "organization using state-of-art technology to build a better, bold, and" +-->
<!--                                            "futuristic business empire.").toString().toLowerCase()}}-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <a [routerLink]="'/app/contact'" class="btn-style-1 sawasdeeX3" style="margin-left: 30px"><i class="fa fa-sign-in"></i>{{("let's discuss your company digital solution needs").toString().toLowerCase()}}</a>-->
<!--                            </div>-->
<!--                        </section>-->
<!--                    </div>-->
<!--                    <div class="col-md-4" style="padding-left: 10px">-->

<!--                    </div>-->
<!--                </div>-->
<!--            </form>-->
<!--        </div>-->
<!--    </div>-->

<!--    &lt;!&ndash;POST AREA START&ndash;&gt;-->
<!--    <section class="post-section blog-post">-->
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-md-4 col-sm-6" style="cursor: pointer" >-->
<!--                    <div class="post-box">-->
<!--                        <div class="text-box">-->
<!--                            <h4><a>Research and statistics</a></h4>-->
<!--                            <p>Get data you need at your fingertips ! Experience data collection easily than ever</p>-->
<!--                            <p>No matter the number of respondents you have, we have survey tool called <a href="http://localhost:4200"><span>patten</span></a>, as many features that enable you to-->
<!--                                create variety of online surveys such as market research surveys and customer feedback surveys even on your mobile phone.-->
<!--                                Also offer several advanced survey questions, from multiple-choice questions to start rating and likert scale.-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div class="frame"  style="margin-left: 10px; margin-right: 10px">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <center>-->
<!--                        <a class="btn-style-2">Create Survey Form</a>-->
<!--                    </center>-->
<!--                </div>-->
<!--                <div class="col-md-4 col-sm-6" style="cursor: pointer" >-->
<!--                    <div class="post-box">-->
<!--                        <div class="text-box">-->
<!--                            <h4><a>Sales & Marketing</a></h4>-->
<!--                            <p>-->
<!--                                We have mobile sales-automation program that will empowers you to remotely access real-time sales reports,-->
<!--                                delivery reports, checking brand availability, competitor analytics, customer feedback and on-the-ground product health indicators.The technology is homegrown giving it the advantage of being relevant to this markets’.-->
<!--                                For those multi-national companies, and you need your products, to reach the furthest and deepest corners of the market patten is best tool for you.-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div class="frame">-->
<!--                            <center>-->
<!--                            </center>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <center>-->
<!--                        <a class="btn-style-2">Open Marketing Campaign Survey</a>-->
<!--                    </center>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
<!--    &lt;!&ndash;POST AREA END&ndash;&gt;-->
<!--</div>-->

<!--<div [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','z-index':'999999999'}">-->
<!--    <div [ngStyle]="{'position':'fixed','top':'20%','width':this.service.getWidth()+'px','z-index':'999999999'}">-->
<!--        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>-->
<!--        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--            <center>-->
<!--                <table>-->
<!--                    <tr>-->
<!--                        <td [vAlign]="'bottom'">-->
<!--                            <img src="assets/images/361/361.png" class="pull-left"/>-->
<!--                            -->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </table>-->
<!--            </center>-->
<!--        </div>-->
<!--        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>-->
<!--    </div>-->
<!--</div>-->
