import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../app.service';
import {NotifierService} from 'angular-notifier';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BroadcastChannelService} from '../../broadcast-channel.service';
import {CampaignService} from '../../campaigns/campaign.service';
import {Parameters} from '../../parameters';
import * as moment from 'moment';
import {map, startWith} from 'rxjs/internal/operators';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {DialogClientsComponent} from '../../clients/dialog-clients.component';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {FormService} from '../../forms/form.service';
import {Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-dialog-lock-questionnaires',
  templateUrl: './dialog-lock-questionnaires.component.html',
  styleUrls: ['./dialog-lock-questionnaires.component.scss']
})
export class DialogLockQuestionnairesComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) trigger: any;
  @ViewChild(MatAutocompleteTrigger) dominantAutocompleteTrigger: any;
  @ViewChild('questionalElement') questionalElement: any;
  dataSource = new MatTreeNestedDataSource<any>();
  treeControl = new NestedTreeControl<any>(node => node.children);
  questions: any;
  question: any;
  selectable = true;
  removable = true;
  label: any;
  questionalCtrl = new FormControl();
  visibleQuestionalControl = new FormControl();
  allQuestions; any = [];
  filteredQuestions: Observable<any>;
  filteredVisibleQuestions: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  currentQuestions: any = [];
  selectedOptional: any = {};
  removedQuestions: any;
  app: any;
  configAngularEditor: any;
  formValues: FormGroup = new FormGroup({});
  optionControl = new FormControl();
  filteredOptionsOptions: any;
  htmlContent: any;
  parameters: any;
  params: any;
  incomingSelectedCampaign: any;
  selectedCampaign: any;
  addressValue: any;
  logo: any;
  startingAt: any;
  endingAt: any;
  clients: any;
  position: any;
  subscription: any;
  incomingQuestion: any;
  constructor(public service: AppService,
              private notifier: NotifierService,
              public formBuilder: FormBuilder,
              private dialog: MatDialog,
              public broadcastChannel: BroadcastChannelService,
              public formService: FormService,
              public metthods: CampaignService,
              @Inject(MAT_DIALOG_DATA
              ) public data: any,
              public dialogRef: MatDialogRef<DialogLockQuestionnairesComponent>) {
    this.question = this.data.question;
    this.questions = this.formService.questions;
    this.questionalElement = ElementRef;
    this.app = this.service.app;
    this.parameters = new Parameters();
    this.parameters.setAttributes({});
    this.removedQuestions = this.formService.deletedOptionQuestions;
    this.setAllQuestions();
    this.filteredQuestions = this.questionalCtrl.valueChanges.pipe(
      startWith(''), map((question: string | null) => question ? this._filter(question) : this.allQuestions.slice()));
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.filteredOptionsOptions = this.optionControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.label),
        map(name => name ? this._filterOption(name) : this.question.options.slice())
      );
      this.filteredVisibleQuestions = this.visibleQuestionalControl.valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(name => name ? this._filterVisibleQuestions(name) : this.formService.questions.filter((questional: any) => { return parseFloat(questional.visible) === 1;}).slice())
      );
      this.broadcastChannel.emitNavChangeEvent('treenodes');
    });
  }


  setAllQuestions(){
    this.formService.questions.map((question: any) => {
      if(question.hasOwnProperty('options')){
        question.options.map((opt: any) => {
          if(opt.label.toString().includes('null')){
          this.formService.showQuestions(opt);}
          else this.formService.hideOptionalQuestions(opt);
        });
      }
    });
    this.formService.loadHiddenQuestions();
    this.allQuestions = this.questions.filter((question: any) => { return (parseFloat(this.question.index) < parseFloat(question.index) && (question.hasOwnProperty('visible') && parseFloat(question.visible) === 1));});
  }

  keys(){return Object.keys(this.selectedOptional).length;}

  add(event: any): void {
    const value = (event.value || '').trim();
    // Add our filteredJudgmentCondition
    // if (value) {
    // }

    // Clear the input value
    // event.chipInput!.clear();
    this.questionalElement.nativeElement.value = '';
    this.questionalCtrl.setValue(null);
  }

  onSelectedTabChange(event: MatTabChangeEvent){
    if(event.index === 1){
      this.broadcastChannel.emitNavChangeEvent('treenodes');
      // (new TreeviewFormComponent(this.formService, this.broadcastChannel)).nodes();
    }
  }



  handleQuestion(question: any){
    this.label = prompt('', question.label);
    if (this.label !== null) question = Object.assign(question, {label: this.label});
  }

  remove(question: any): void {
    let position: any = this.currentQuestions.indexOf(question);
    if (position === -1){} else {
      this.currentQuestions.splice(position, 1);
      position = this.formService.questions.findIndex((questional: any) => {return parseFloat(questional.cntrl_num) === parseFloat(question.cntrl_num);}); question.visible = 1;
      if(position === -1) {this.formService.questions.push(question); }else {this.formService.questions[position].visible = 1;};
      if(question.hasOwnProperty('option_question_id')) {this.formService.deletedOptionQuestions.push(question); }
    } this.allQuestions = this.questions.filter((questional: any) => { return (parseFloat(this.question.index) < parseFloat(questional.index) && (questional.hasOwnProperty('visible') && parseFloat(questional.visible) === 1));});
    this._filter('');
  }

  private _filter(value: any): any {
      let filterValue: any = value.toString().toLowerCase();
      return this.allQuestions.filter(question => (question.label.toLowerCase().includes(filterValue) && (question.hasOwnProperty('visible') && parseFloat(question.visible) === 1)));
  }

  optionalOpenDrawer(){
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  questionalOpenDrawer(){this.questionalOpenDrawer();}

  selected(event: MatAutocompleteSelectedEvent): void {
      let position: any = this.currentQuestions.findIndex((question: any) => {return parseFloat(question.id) === parseFloat(event.option.value.id);});
      event.option.value.visible = event.option.value.hasOwnProperty('visible') ? event.option.value.visible : 1;
      if(position === -1 && event.option.value.visible === 1) {
      this.incomingQuestion = new Parameters();
      this.incomingQuestion.setAttributes(event.option.value);
      this.incomingQuestion.setCntrlNum(this.service.random());
      this.incomingQuestion.setQuestionId(this.incomingQuestion.getId());
      this.incomingQuestion.setOptionId(this.selectedOptional.id);
      this.incomingQuestion.setVisible(0);
      this.currentQuestions.push(this.incomingQuestion.getAttributes());
        position = this.formService.questions.findIndex((question: any) => {return parseFloat(question.id) === parseFloat(event.option.value.id);});
      if(position === -1){} else {this.formService.questions[position].visible = 0;}
      this.selectedOptional = Object.assign(this.selectedOptional, {questions: this.currentQuestions});
    }
    this.broadcastChannel.emitNavChangeEvent('treenodes');
    this.questionalElement.nativeElement.value = '';
    this.questionalCtrl.setValue(null);
    this.allQuestions = this.questions.filter((questional: any) => { return (parseFloat(this.question.index) < parseFloat(questional.index) && (questional.hasOwnProperty('visible') && parseFloat(questional.visible) === 1));});
    this._filter('');
}

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  private _filterOption(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.question.options.filter((option: any) => option.label.toLowerCase().includes(filterValue));
  }

  private _filterVisibleQuestions(name: string) {
    const filterValue = name.toString().toLowerCase();
    return this.formService.questions.filter((questional: any) => { return parseFloat(questional.visible) === 1;}).filter((questional: any) => questional.label.toLowerCase().includes(filterValue));
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  visibleQuestionalOpenDrawer(){
    this.dominantAutocompleteTrigger._onChange('');
    this.dominantAutocompleteTrigger.openPanel();
  }



  onOptionSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.selectedOptional = event.option.value;
    this.currentQuestions = event.option.value.hasOwnProperty('questions') ? event.option.value.questions : [];
  }

  displayFn(value: any){
    return value.label;
  }


}
