<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-left-size':'50px','border-left-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
                </div>
                <div>
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label></mat-label>
<!--                    <i class="material-icons pull-right">location_on</i>-->
                        <input type="text"
                               class="sawasdee26"
                               matInput
                               #addressElement
                               autocomplete="off"
                               placeholder=""
                               [ngStyle]="{'text-align': 'center', 'color': this.formService.color}"
                               [value]='service.isNull(question.data) ? "" : question.data'
                               [address]="addressValue"
                               (input)="onInputTextChange($event)"
                               (onAutocompleteSelected)="onGoogleMapAutocompleteSelected($event)"
                               (onLocationSelected)="onLocationSelected($event)"
                               matGoogleMapsAutocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>