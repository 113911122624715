import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {FormControl} from "@angular/forms";
import {AppService} from "../../../app.service";
import {Parameters} from "../../../parameters";
import {FormService} from '../../../forms/form.service';
import {OutletService} from "../../../outlets/outlet.service";
import {map, startWith} from "rxjs/internal/operators";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import * as moment from 'moment';

@Component({
  selector: 'app-bottomsheet-order-view-control',
  templateUrl: './bottomsheet-order-view-control.component.html',
  styleUrls: ['./bottomsheet-order-view-control.component.scss']
})
export class BottomsheetOrderViewControlComponent implements OnInit, AfterViewInit {
  @ViewChild('supplierAutocompleteTrigger', {static: false}) supplierAutocompleteTrigger: any;
  @ViewChild('elementQuantity', {static: false})  elementNativeQuantity: any;
  @ViewChild('elementSupplers', {static: false})  elementNativeSupplers: any;
  supplierControl = new FormControl();
  filteredSuppliersOptions: any;
  outgoingRequest: any;
  drawer: any;
  incomingItemSelected: any;
  incomingSettingsItem: any;
  incomingItem: any;
  position: any;
  incomingSupplier: any;
  incomingQuestion: any;
  incomingForm: any;
  incomingResult: any;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public  service: AppService,
              public formService: FormService,
              public  outletService: OutletService,
              private _bottomSheetRef: MatBottomSheetRef<BottomsheetOrderViewControlComponent>) {
     this.outgoingRequest = new Parameters();
     this.incomingItemSelected = new Parameters();
     this.incomingSettingsItem = new Parameters();
     this.incomingItem = new Parameters();
     this.incomingSupplier = new Parameters();
     this.incomingQuestion = new Parameters();
     this.incomingForm = new Parameters();
     this.incomingResult = new Parameters();
     this.supplierAutocompleteTrigger = MatAutocompleteTrigger;
     this.elementNativeQuantity = ElementRef;
     this.elementNativeSupplers = ElementRef;
  }

  ngOnInit(): void {
    this.drawer = this.data.drawer;
    this.incomingQuestion.setAttributes(this.data.question);
    this.incomingItemSelected.setAttributes(this.outletService.incomingItemSelected.getAttributes());
    this.filteredSuppliersOptions = this.supplierControl.valueChanges.pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : value.label),
                  map(label => label ? this._filterSupplier(label) : this.service.app.data.outlets.data.slice())
    );

  }

  private _filterSupplier(label: string) {
     const filterValue = label.toLowerCase();
     return this.service.app.data.outlets.data.filter((outlet: any) => JSON.stringify(outlet).toLowerCase().includes(filterValue));
  }

  searchSupplier(){
   this.drawer.toggle();
   setTimeout(() =>{
     this.supplierAutocompleteTrigger.closePanel();
   });
  }

  addItem(){
  }

  ngAfterViewInit(): void {
      setTimeout(() =>{
          if(this.elementNativeQuantity.nativeElement){
              this.elementNativeQuantity.nativeElement.focus();
              this.elementNativeSupplers.nativeElement.focus();
          }
      });
  }

  onInputTextChange(event: any){
    this.incomingSettingsItem.setAttributes(this.outletService.incomingItemSelected.getSettings());
    this.incomingItem.setAttributes(this.incomingSettingsItem.getItem());
    this.position = this.service.findIndex(this.incomingQuestion.getUnknown('data'),'id',this.incomingItem.getId());
    this.incomingItem.setQuantity(event.target.value);
    if(this.position===-1){
     this.incomingQuestion.getUnknown('data').push(this.incomingItem.getAttributes());
    }
  }

  onOrderPlaced(){
   this.outgoingRequest.setAttributes({});
   this.outgoingRequest.setCustomerId(1);
   this.outgoingRequest.setStatus('requested');
   this.outgoingRequest.setTotal(0);
   this.outgoingRequest.setCntrlNum(this.service.random());
   this.outgoingRequest.setDelivery(moment(new Date()).format('MM/DD/YYYY'));
   this.outgoingRequest.setItems([]);
   this.outgoingRequest.setReference1('form');
   this.outgoingRequest.setReference2(1);
   this.service.httpService('post', this.service.app.routes.orders.add,
         this.outgoingRequest.getAttributes(), {}, (result: any) => {
         this.incomingResult.setAttributes(result);
       }, (error: any) => {
       });
  }

  displayFn(value: any){
    return value.name;
  }

  onSupplierSelectionChanged(event: MatAutocompleteSelectedEvent){
    this.incomingSupplier.setAttributes(event.option.value);
    this.incomingSettingsItem.setAttributes(this.outletService.incomingItemSelected.getSettings());
    this.incomingItem.setAttributes(this.incomingSettingsItem.getItem());
    this.position = this.service.findIndex(this.incomingQuestion.getUnknown('data'),'id',this.incomingItem.getId());
    this.incomingItem.setSupplierId(this.incomingSupplier.getId());
    if(this.position===-1){
     this.incomingItem.setQuantity(0);
     this.incomingQuestion.getUnknown('data').push(this.incomingItem.getAttributes());
    }
  }

  onInputTextChanged(event: any){
    this.outgoingRequest.setAttributes({});
    this.outgoingRequest.setSearchText(event.target.value);
    this.outletService.search(this.outgoingRequest.getAttributes(),(result: any)=>{
    });
  }


}
