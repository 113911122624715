<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <h4 id="example-radio-group-label">{{question.label}}</h4>
                </div>
                <div>
                    <center>
                        <ngx-mat-timepicker-field
                                color="warn"
                                [(ngModel)]="question.data"
                                [defaultTime]="'11:11 AM'">
                        </ngx-mat-timepicker-field>
                    </center>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
