<div layout="row">
    <div class="col-lg-2 col-md-2"></div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="panell panel-defaultt" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'transparent'}">
            <div class="panel-bodyy">
                <div>
                    <app-label-create-control [question]="question"></app-label-create-control>
<!--                <h4 id="example-radio-group-label"-->
<!--                        contenteditable="true"-->
<!--                        [contentEditable]="element.innerText"-->
<!--                        #element-->
<!--                        [(ngModel)]="question.label"-->
<!--                        (blur)="question.label = element.innerText">{{question.label}}</h4>-->
                </div>
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input  matInput
                                [(ngModel)]="service.isNull(question.data) === false && question.hasOwnProperty('default') ? question.default : question.data"
                                placeholder="{{question.placeholder}}"
                                style="text-align: center; font-size: 30px"
                                #message >
                                <i class="material-icons pull-right cursor" (click)="formService.deleteQuestion(question)">close</i>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2"></div>
</div>
